import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Veracode]: HandlerConfigVeracode;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigVeracode {}
}

export const VeracodeConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Veracode> = {
  name: Integration_Names_Enum.Veracode,

  orgConfig: z.object({
    apiKey: z.string().describe('API Key'),
    apiId: z.string().describe('API ID'),
    baseUrl: z.string().optional().describe('API Base URL'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.Vulnerability),
      businessUnit: z.string().optional().describe('Business Unit'),
      policy: z.string().optional().describe('Policy'),
      policyCompliance: z.string().optional().describe('The policy compliance status'),
      policyGuid: z.string().optional().describe('Policy GUID of the policy to change'),
      scanStatus: z.array(z.string()).optional().describe('The scan status of the application'),
      scanType: z.string().optional().describe('The scan type of the application scans'),
      tag: z.string().optional().describe('Tag'),
      team: z.string().optional().describe('Filter the results by team name'),
      buildId: z.string().optional().describe('ID of the build in which the scan ran'),
      context: z.string().optional().describe('GUID of the associated development sandbox'),
    }),
  ]),
};

// eslint-disable-next-line simple-import-sort/imports
import { Box, Button, Icon } from '@chakra-ui/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Table, NoPermissionPlaceholder } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../../utils/constants';
import { CSVInput } from '../../../utils/export-csv';
import { useOrgEvidenceTableQuery } from '../../evidence/evidence-query';
import { OrganizationEvidence } from '../../evidence/slice';
import { useEvidenceColumnHelper } from '../../evidence/table-columns';
import { useEvidenceTableSettings } from '../../evidence/table-settings';
import { useEvidenceDownloader } from '../../evidence/use-evidence-downloader';
import { getCurrentOrgUsersMap, getCurrentUserSelectedOrgRole } from '../../user/slice';
import { getReportById } from '../slice';
import { useUpdateReportHandler } from '../use-update-report-handler';
import { ReportCardBase } from './report-card-base';

export const EvidenceReportCard = ({
  reportId,
  onExportCSV,
}: {
  reportId: string;
  onExportCSV: (data: CSVInput[]) => void;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const viewEvidence = userRole.permissionMap?.read_evidence;

  const currentOrgUsersById = useAppSelector(getCurrentOrgUsersMap);
  const reportData = useAppSelector((state) => getReportById(state, reportId));

  const { columnVisibility, setColumnVisibility } = useEvidenceTableSettings(
    `reports:${reportId}:evidence-table:column-visibility`,
  );
  const columns = useEvidenceTableColumns();

  const {
    filters: [columnFilters, setColumnFilters],
    sorting: [sorting, setSorting],
  } = useUpdateReportHandler({ columns });
  const [globalFilter, setGlobalFilter] = useState('');
  const [filteredRows, setFilteredRows] = useState<OrganizationEvidence[]>([]);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.evidence').toLowerCase(),
      plural: t('entities.evidence').toLowerCase(),
    };
  }, [t]);

  const tableProps = useOrgEvidenceTableQuery({
    columns,
    globalFilter,
    columnFilters,
    sorting,
    forceClientSide: true,
  });

  const evidenceDownloader = useEvidenceDownloader({
    downloadId: `report-evidence-${reportId}`,
  });
  const evidenceFetcher = { fetchEvidence: async () => filteredRows };

  const handleExportCSV = () => {
    const filteredData = filteredRows.map((row) => {
      const currentVersionType = row.currentVersion.url ? 'Link' : 'File';
      const currentVersionName = row.currentVersion.url || row.currentVersion.file?.name;
      const currentVersionOwner = row.currentVersion.createdBy
        ? currentOrgUsersById[row.currentVersion.createdBy]
        : COMPLYANCE_USER;
      const owner = row.owner_id ? currentOrgUsersById[row.owner_id] : COMPLYANCE_USER;

      return {
        Name: row.name,
        Description: row.description,
        'Current version type': currentVersionType,
        'Current version name': currentVersionName,
        'Current version owner': currentVersionOwner?.displayName,
        Owner: owner?.displayName,
        Tags: row.tags.map(({ tag }) => tag.name),
        'Evidence date created': row.created_at,
        'Evidence date last updated': row.updated_at,
        'Current version last updated': row.currentVersion.updatedAt,
        'Current version validity start': row.currentVersion.validityStart,
        Programs: row.programs.map((program) => program.name),
      };
    });

    onExportCSV(filteredData);
  };

  if (!viewEvidence) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <ReportCardBase
      heading={t('entities.evidence')}
      subheading={t('evidences.subheading')}
      onExportCSV={filteredRows.length > 0 ? handleExportCSV : undefined}
      extraHeaderActions={
        <Button
          leftIcon={<Icon w={4} h={4} as={ArrowDownTrayIcon} />}
          variant="outline"
          height="40px"
          p="0px 16px"
          size="md"
          gap="8px"
          isDisabled={filteredRows.length === 0 || evidenceDownloader.isDownloading}
          onClick={() =>
            evidenceDownloader.start(`Evidence report ${reportData?.name}`, evidenceFetcher)
          }
        >
          {t('buttons.bulkDownload')}
        </Button>
      }
    >
      <Box borderRadius="inherit" overflowX="auto">
        <Table
          minW="900px"
          entity="evidence"
          columns={columns}
          itemName={tableItemName}
          columnFilters={columnFilters}
          onColumnFiltersChange={setColumnFilters}
          globalFilter={globalFilter}
          onGlobalFilterChange={setGlobalFilter}
          onFilteredDataChange={setFilteredRows}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={setColumnVisibility}
          sorting={sorting}
          onSortingChange={setSorting}
          {...tableProps}
        />
      </Box>
    </ReportCardBase>
  );
};

function useEvidenceTableColumns() {
  const evidenceColumnHelper = useEvidenceColumnHelper({ forceClientSide: true });
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewControls = userRole.permissionMap?.read_controls;

  return useMemo(
    () => [
      evidenceColumnHelper.status(),
      evidenceColumnHelper.internalId(),
      evidenceColumnHelper.name({ onClickOpenDrawer: true }),
      ...(canViewControls ? [evidenceColumnHelper.programs()] : []),
      evidenceColumnHelper.description(),
      evidenceColumnHelper.controlsCount(),
      evidenceColumnHelper.validityStart(),
      evidenceColumnHelper.tags(),
      evidenceColumnHelper.owner(),
    ],
    [canViewControls, evidenceColumnHelper],
  );
}

import { DownloadFile } from '@main/bulk-downloader';
import { safeFileName, useStableCallback } from '@main/shared/utils';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { AppDownloadFile } from '../bulk-downloader/app-stream-downloader';
import { AppFilesFetcher, useAppDownloader } from '../bulk-downloader/use-app-downloader';
import { getCurrentUserSelectedOrg } from '../user/slice';
import {
  EvidenceForDownloadFragment,
  useLazyGetOrganizationEvidencesForBulkDownloadQuery,
} from './use-evidence-downloader.generated';

export interface EvidenceDownloaderOptions {
  downloadId?: string;
}

export interface EvidenceFetcher {
  fetchEvidence(): Promise<EvidenceForDownloadFragment[]>;
}

export function useEvidenceDownloader({
  downloadId = 'all-evidence',
}: EvidenceDownloaderOptions = {}) {
  const { t } = useTranslation();
  const progressTitle = useStableCallback((progress: number) =>
    t('evidences.bulkDownloadToast.progressTitle', { progress: progress.toFixed(1) }),
  );
  const allEvidenceFetcher = useFetchAllEvidence();

  const downloader = useAppDownloader({
    downloadId,
    title: t('evidences.bulkDownloadToast.title'),
    progressTitle,
    successTitle: t('evidences.bulkDownloadToast.successTitle'),
    errorTitle: t('evidences.bulkDownloadToast.failTitle'),
  });

  return {
    ...downloader,
    start(fileName = 'all-evidence', evidenceFetcher = allEvidenceFetcher) {
      return downloader.start(fileName, appEvidenceFetcher(evidenceFetcher));
    },
  };
}

function useFetchAllEvidence(): EvidenceFetcher {
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const [getEvidencesForBulkDownload] = useLazyGetOrganizationEvidencesForBulkDownloadQuery();

  return {
    async fetchEvidence() {
      const evidenceRequest = await getEvidencesForBulkDownload({
        organizationId: organization.id,
      }).unwrap();
      return evidenceRequest.evidences;
    },
  };
}

function appEvidenceFetcher(evidenceFetcher: EvidenceFetcher): AppFilesFetcher {
  return {
    async fetchFiles() {
      const evidences = await evidenceFetcher.fetchEvidence();

      return evidences
        .filter((evidence) => evidence.evidence_versions.length > 0)
        .map((evidence) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const evidenceVersion = evidence.evidence_versions[0]!;
          const evidenceName = evidence.name ?? '';
          const fileName = `${evidenceVersion.evidence_version_file?.file.name ?? 'evidence'}${
            evidenceVersion.url ? '.txt' : ''
          }`;

          return {
            file: evidenceVersion.evidence_version_file
              ? { fileId: evidenceVersion.evidence_version_file.file.id }
              : { content: evidenceVersion.url ?? '' },
            name: `${evidence.internal_id} - ${safeFileName(evidenceName)}/${safeFileName(
              fileName,
            )}`,
          } satisfies DownloadFile<AppDownloadFile>;
        });
    },
  };
}

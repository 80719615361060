/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetEditClientQuestionnaireQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type GetEditClientQuestionnaireQuery = {
  __typename?: 'query_root';
  clientQuestionnaire?: {
    __typename?: 'client_questionnaires';
    id: string;
    name?: string;
    status: Types.Client_Questionnaire_Status_Enum;
    organization_id: string;
    export_file?: { __typename?: 'files'; id: string };
    questions: Array<{
      __typename?: 'client_questionnaire_questions';
      id: string;
      question: string;
      status: Types.Client_Questionnaire_Question_Status_Enum;
      answer: Array<{
        __typename?: 'client_questionnaire_answers';
        id: string;
        answer: string;
        is_ai_generated: boolean;
      }>;
    }>;
  };
};

export type EditClientQuestionnaireFragment = {
  __typename?: 'client_questionnaires';
  id: string;
  name?: string;
  status: Types.Client_Questionnaire_Status_Enum;
  organization_id: string;
  export_file?: { __typename?: 'files'; id: string };
  questions: Array<{
    __typename?: 'client_questionnaire_questions';
    id: string;
    question: string;
    status: Types.Client_Questionnaire_Question_Status_Enum;
    answer: Array<{
      __typename?: 'client_questionnaire_answers';
      id: string;
      answer: string;
      is_ai_generated: boolean;
    }>;
  }>;
};

export type EditClientQuestionnaireQuestionFragment = {
  __typename?: 'client_questionnaire_questions';
  id: string;
  question: string;
  status: Types.Client_Questionnaire_Question_Status_Enum;
  answer: Array<{
    __typename?: 'client_questionnaire_answers';
    id: string;
    answer: string;
    is_ai_generated: boolean;
  }>;
};

export const EditClientQuestionnaireQuestionFragmentDoc = `
    fragment EditClientQuestionnaireQuestion on client_questionnaire_questions {
  id
  question
  status
  answer: client_questionnaire_answers(order_by: {created_at: desc}, limit: 1) {
    id
    answer
    is_ai_generated
  }
}
    `;
export const EditClientQuestionnaireFragmentDoc = `
    fragment EditClientQuestionnaire on client_questionnaires {
  id
  name
  status
  organization_id
  export_file {
    id
  }
  questions: client_questionnaire_questions {
    ...EditClientQuestionnaireQuestion
  }
}
    `;
export const GetEditClientQuestionnaireDocument = `
    query GetEditClientQuestionnaire($id: uuid!) {
  clientQuestionnaire: client_questionnaires_by_pk(id: $id) {
    ...EditClientQuestionnaire
  }
}
    ${EditClientQuestionnaireFragmentDoc}
${EditClientQuestionnaireQuestionFragmentDoc}` as string &
  TypedDocumentNode<GetEditClientQuestionnaireQuery, GetEditClientQuestionnaireQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetEditClientQuestionnaire: build.query<
      GetEditClientQuestionnaireQuery,
      GetEditClientQuestionnaireQueryVariables
    >({
      query: (variables) => ({ document: GetEditClientQuestionnaireDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetEditClientQuestionnaireQuery, useLazyGetEditClientQuestionnaireQuery } =
  injectedRtkApi;

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowUpOnSquareIcon, PlusIcon, RectangleGroupIcon } from '@heroicons/react/24/outline';
import { useGetAvailableFrameworksForOrganizationQuery } from '@main/graphql/queries/GetAvailableFrameworksForOrganization.generated';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { OptionCard } from '../shared/option-card';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { CreateFrameworkAndProgram } from './create-framework-and-program';
import { CreateTemplateFrameworkProgram } from './create-program-from-template';
import { ImportCustomProgram } from './import-custom-program';

type CreateProgramDrawerProps = {
  onModalClose: () => void;
  isModalOpen: boolean;
};
export const CreateProgramModal: FC<CreateProgramDrawerProps> = ({ onModalClose, isModalOpen }) => {
  return (
    <Modal
      size="xl"
      isCentered
      autoFocus={false}
      isOpen={isModalOpen}
      onClose={onModalClose}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody pt={4} pb={6}>
          <CreateProgramOptions onModalClose={onModalClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

enum CreateOptions {
  CreateFramework = 'CREATE_FAMEWORK',
  CreateProgram = 'CREATE_PROGRAM',
  ImportProgram = 'IMPORT_PROGRAM',
}

const CreateProgramOptions = ({ onModalClose }: { onModalClose: () => void }) => {
  const { t } = useTranslation();
  const [createType, setCreateType] = useState<CreateOptions>();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  useGetAvailableFrameworksForOrganizationQuery(
    {
      orgId: organization.id,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const titleColor = useColorModeValue('gray.700', 'gray.300');

  if (createType === CreateOptions.CreateProgram)
    return <CreateTemplateFrameworkProgram closeModal={onModalClose} />;
  if (createType === CreateOptions.CreateFramework)
    return <CreateFrameworkAndProgram closeModal={onModalClose} />;
  if (createType === CreateOptions.ImportProgram)
    return <ImportCustomProgram closeModal={onModalClose} />;

  return (
    <Flex direction="column" gap={4}>
      <Text fontSize="lg" fontWeight="bold" color={titleColor}>
        {t('programs.create.heading')}
      </Text>
      <Flex direction="column" gap={4}>
        <OptionCard
          icon={RectangleGroupIcon}
          title={t('programs.create.template.heading')}
          subTitle={t('programs.create.template.subheading')}
          onClick={() => setCreateType(CreateOptions.CreateProgram)}
        />
        <OptionCard
          icon={PlusIcon}
          title={t('programs.create.custom.heading')}
          subTitle={t('programs.create.custom.subheading')}
          onClick={() => setCreateType(CreateOptions.CreateFramework)}
        />
        <OptionCard
          icon={ArrowUpOnSquareIcon}
          title={t('programs.create.importFramework.heading')}
          subTitle={t('programs.create.importFramework.subheading')}
          onClick={() => setCreateType(CreateOptions.ImportProgram)}
        />
      </Flex>
    </Flex>
  );
};

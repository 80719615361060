import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon } from '@heroicons/react/24/outline';
import { EvidenceStatus } from '@main/graphql/client-scalars';
import { useInsertEvidenceMutation } from '@main/graphql/mutations/InsertEvidence.generated';
import { api as getOrganizationEvidenceApi } from '@main/graphql/queries/GetOrganizationEvidences.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import {
  getCurrentUserId,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { EvidenceTable } from './table';

export const EvidenceCenter = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateEvidence = userRole.permissionMap?.write_evidence;

  const userId = useAppSelector(getCurrentUserId);
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  const [createEvidence, { isLoading: isCreatingEvidence }] = useInsertEvidenceMutation();

  const onCreateEvidence = async () => {
    const { undo } = dispatch(
      getOrganizationEvidenceApi.util.updateQueryData(
        'GetOrganizationEvidences',
        {
          organizationId: organization.id,
        },
        (draft) => {
          draft.evidences.push({
            id: '',
            updated_at: '',
            owner_id: userId,
            controls_aggregate_status: EvidenceStatus.PENDING,
            is_confidential: false,
            control_evidences: [],
            evidence_versions: [],
            tags: [],
            acl: [],
            created_at: new Date().toISOString(),
          });
        },
      ),
    );

    const evidenceDrawer = await drawer.openLoading({ entity: 'evidence' });

    try {
      const createResult = await createEvidence({
        object: {
          organization_id: organization.id,
        },
      }).unwrap();

      if (!createResult.insert_evidences_one?.id) {
        throw new Error('Could not get new evidence id from response');
      }

      evidenceDrawer.load({ entityId: createResult.insert_evidences_one.id });
      successToast(t('successMessages.createSucceeded', { entity: t('entities.evidence') }));
    } catch (error) {
      undo();
      drawer.close();
      errorToast(t('errorMessages.createFailed', { entity: t('entities.evidence') }));
      datadogLogs.logger.error('Creating new evidence failed', {}, toError(error));
    }
  };

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('evidences.center.heading')}
      </Text>

      <Card variant="table-styles">
        <CardHeader>
          <HStack w="full">
            <Box flexGrow="1">
              <Heading size="md">{t('entities.evidence')}</Heading>
              <Text variant="subheading">{t('evidences.subheading')}</Text>
            </Box>
            {canCreateEvidence && (
              <Box>
                <Button
                  colorScheme="blue"
                  onClick={onCreateEvidence}
                  leftIcon={<Icon as={PlusIcon} />}
                  isLoading={isCreatingEvidence}
                >
                  {t('evidences.buttons.addNewEvidence')}
                </Button>
              </Box>
            )}
          </HStack>
        </CardHeader>
        <CardBody>
          <EvidenceTable />
        </CardBody>
      </Card>
    </Stack>
  );
};

import { AnyAction } from '@reduxjs/toolkit';
import { Api, BaseQueryFn, EndpointDefinitions } from '@reduxjs/toolkit/dist/query';
import { CombinedState, QueryKeys } from '@reduxjs/toolkit/dist/query/core/apiState';
import { Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { ResultTypeFrom } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { useCallback } from 'react';
import { useStore } from 'react-redux';

/**
 * Hook, that provides same functionality as Redux Toolkit's `api.utils.updateQueryData`, except
 * it does not require query arguments.
 *
 * Works by locating all cached queries in the store for the provided endpoint name, and
 * executing `api.utils.updateQueryData` with each cached argument found.
 *
 * @example
 * const updateAllQueryData = useUpdateAllQueryData();
 * const undo = updateAllQueryData(getEntityApi, 'GetEntity', (draft) => {
 *   // ...update logic
 * });
 */
export function useUpdateAllQueryData<
  TState extends { api: CombinedState<EndpointDefinitions, never, 'api'> },
>() {
  const store = useStore<TState>();

  return useCallback(
    <
      BaseQuery extends BaseQueryFn,
      Definitions extends EndpointDefinitions,
      ReducerPath extends string,
      TagTypes extends string,
      EndpointName extends QueryKeys<Definitions>,
    >(
      api: Api<BaseQuery, Definitions, ReducerPath, TagTypes>,
      endpointName: EndpointName & string,
      updateRecipe: Recipe<ResultTypeFrom<Definitions[EndpointName]>>,
    ) => {
      const queries = store.getState().api.queries;
      const undos: VoidFunction[] = [];

      for (const [cacheKey, query] of Object.entries(queries)) {
        if (cacheKey.startsWith(endpointName)) {
          const action = api.util.updateQueryData(
            endpointName,
            query?.originalArgs as never,
            updateRecipe,
          );
          const { undo } = store.dispatch(action as unknown as AnyAction);
          undos.push(undo as VoidFunction);
        }
      }

      return () => {
        for (const undo of undos) {
          undo();
        }
      };
    },
    [store],
  );
}

import { isNonNullable } from '@main/shared/utils';
import { createColumnHelper, useDrawer } from '@main/ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { toDatePart } from '../../utils/date';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { POLICY_ACKNOWLEDGEMENT_STATUSES, POLICY_VERSION_STATUSES } from './constants';
import { useUpdatePolicyMutation } from './manage-policies.generated';
import { getMappedOrgPolicies } from './slice';
import { useDeletePolicyAction } from './use-drawer-actions';
import { useUpdatePolicyHandler } from './use-update-policy-handler';

type Policy = ReturnType<typeof getMappedOrgPolicies>[number];

export function usePolicyColumnHelper() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditPolicy = userRole.permissionMap?.write_policies;
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);

  const updatePolicyHandler = useUpdatePolicyHandler();
  const [updatePolicy] = useUpdatePolicyMutation();

  const deletePolicy = useDeletePolicyAction();
  const openPolicyDrawer = useCallback(
    (policyId: string) => {
      drawer.open({ entity: 'policy', entityId: policyId });
    },
    [drawer],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<Policy>();

    return {
      status() {
        return columnHelper.columns.status({
          id: 'currentVersionStatus',
          header: t('policies.tableColumns.status'),
          accessorFn: ({ currentPolicyVersion }) => {
            if (!currentPolicyVersion) {
              return;
            }

            const statusMeta = POLICY_VERSION_STATUSES[currentPolicyVersion.status];
            return {
              value: t(`policies.versions.status.${statusMeta.value}`),
              colorScheme: statusMeta.colorScheme,
            };
          },
          size: 160,
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
        });
      },

      type() {
        return columnHelper.columns.tag({
          id: 'type',
          header: t('policies.tableColumns.type'),
          accessorFn: ({ type }) => {
            return {
              value: t(`policies.type.${type}`),
              colorScheme: 'purple',
            };
          },
          size: 160,
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
        });
      },

      name() {
        return columnHelper.columns.text({
          id: 'name',
          header: t('policies.tableColumns.name'),
          accessorFn: ({ name }) => name,
          meta: {
            cell: {
              onClick: ({ row }) => openPolicyDrawer(row.original.id),
            },
          },
          enableGlobalFilter: true,
          enableSorting: true,
        });
      },

      internalId() {
        return columnHelper.columns.tag({
          id: 'internalId',
          header: t('policies.tableColumns.internalId'),
          accessorFn: ({ internal_id }) => ({
            value: internal_id ?? '',
            colorScheme: 'purple',
          }),
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 100,
        });
      },

      description() {
        return columnHelper.columns.text({
          id: 'description',
          header: t('policies.tableColumns.description'),
          accessorFn: ({ description }) => description,
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 300,
        });
      },

      approversCount() {
        return columnHelper.columns.text({
          id: 'approversCount',
          header: t('policies.tableColumns.approversCount'),
          accessorFn: ({ currentPolicyApprovalRatio }) => currentPolicyApprovalRatio,
          size: 105,
        });
      },

      acknowledgementsCount() {
        return columnHelper.columns.text({
          id: 'acknowledgementsCount',
          header: t('policies.tableColumns.acknowledgementsCount'),
          accessorFn: ({ currentPolicyAcknowledgementRatio }) => currentPolicyAcknowledgementRatio,
          size: 105,
        });
      },

      acknowledgementStatus() {
        return columnHelper.columns.status({
          id: 'acknowledgementStatus',
          header: t('policies.tableColumns.acknowledgementStatus'),
          accessorFn: ({ currentPolicyVersion }) => {
            return [
              ...new Set(
                currentPolicyVersion?.policy_acknowledgements?.map(({ status }) => status),
              ),
            ].map((status) => {
              const statusMeta = POLICY_ACKNOWLEDGEMENT_STATUSES[status];

              return {
                value: t(`policies.acknowledgement.status.${statusMeta.value}`),
                colorScheme: statusMeta?.colorScheme,
              };
            });
          },
          isMulti: true,
          size: 140,
        });
      },

      currentValidityStart(options?: { isEditable?: boolean }) {
        return columnHelper.columns.date({
          id: 'currentValidityStart',
          header: t('policies.tableColumns.currentValidityStart'),
          accessorFn: ({ currentPolicyVersion }) =>
            currentPolicyVersion?.validity_start
              ? toDatePart(currentPolicyVersion.validity_start)
              : undefined,
          size: 150,
          meta: {
            cell: {
              fontSize: 'xs',
              color: 'gray.500',
            },
          },
          enableSorting: true,
          enableColumnFilter: true,
        });
      },

      owner(options?: { isEditable?: boolean }) {
        return columnHelper.columns.avatar({
          id: 'owner',
          header: t('policies.tableColumns.owner'),
          accessorFn: ({ assignee_id }) => {
            if (!assignee_id) {
              return;
            }

            const user = currentOrgUsers[assignee_id];
            if (!user) {
              return;
            }

            return {
              id: user.id,
              displayName: user.displayName,
            };
          },
          size: 70,
          enableColumnFilter: true,
          enableSorting: true,
          edit:
            options?.isEditable && canEditPolicy
              ? {
                  options: currentOrgNonDisabledUsers.map((user) => ({
                    id: user.id,
                    displayName: user.displayName,
                  })),
                  onChange: (row, user) => {
                    return updatePolicyHandler(
                      updatePolicy({
                        id: row.original.id,
                        updatePayload: {
                          assignee_id: user?.id ?? null,
                        },
                      }),
                    );
                  },
                }
              : undefined,
        });
      },

      approvers() {
        return columnHelper.columns.avatar({
          id: 'approvers',
          isMulti: true,
          header: t('policies.tableColumns.approvers'),
          accessorFn: ({ policy_approvers }) => {
            if (!policy_approvers) {
              return;
            }

            return policy_approvers.map((approver) => ({
              id: approver.id,
              displayName: approver.user.displayName,
            }));
          },
          size: 100,
          enableColumnFilter: true,
          enableSorting: true,
        });
      },

      actions() {
        return columnHelper.columns.actions({
          size: 70,
          secondaryActions: ({ row }) =>
            [deletePolicy({ id: row.original.id })].filter(isNonNullable),
        });
      },
    };
  }, [
    t,
    openPolicyDrawer,
    canEditPolicy,
    currentOrgNonDisabledUsers,
    currentOrgUsers,
    updatePolicyHandler,
    updatePolicy,
    deletePolicy,
  ]);
}

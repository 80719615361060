import { Flex } from '@chakra-ui/react';
import { Route } from '@tanstack/react-router';

import Forgot from '../features/user/forgot-password';
import { rootRoute } from './__root';

const ForgotPage = () => {
  return (
    <Flex py="12" minH="100vh" align="center" justify="center">
      <Forgot />
    </Flex>
  );
};

export const forgotPasswordRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'forgot',
  component: ForgotPage,
});

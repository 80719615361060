import { ControlStatus } from '@main/graphql/client-scalars';
import { api as getOrgControlsApi } from '@main/graphql/queries/GetOrganizationControls.generated';
import { api as getOrgControlStatusesApi } from '@main/graphql/queries/GetOrganizationControlStatuses.generated';
import {
  Finding_Causes_Enum,
  Finding_Types_Enum,
  Integration_Names_Enum,
} from '@main/graphql/types.generated';
import { isNonNullable, mapByKey, Severity } from '@main/shared/utils';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import { findingTypeSeverity } from '../findings/finding-type';
import { getOrgProgramsMap, OrganizationProgram } from '../program/slice';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getControlApi } from './get-control.generated';

const getOrgControlsApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      getOrgControlsApi.endpoints.GetOrganizationControls.select({
        organizationId: orgId,
      })(state).data?.controls || []
    );
  },
);

const getOrgControlStatusesApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      getOrgControlStatusesApi.endpoints.GetOrganizationControlStatuses.select({
        organizationId: orgId,
      })(state).data?.controls || []
    );
  },
);

const getControlPrograms = (
  { programs }: { programs: { program_id: string }[] },
  programsMap: Map<string, OrganizationProgram>,
) => {
  return {
    programs: programs.map(({ program_id }) => programsMap.get(program_id)).filter(isNonNullable),
  };
};

export const getOrgControls = createSelector(
  [getOrgControlsApiData, getOrgControlStatusesApiData, getOrgProgramsMap],
  (controls, controlStatuses, programsMap) => {
    const controlStatusesById = controlStatuses.reduce((acc, { id, status }) => {
      acc.set(id, status);
      return acc;
    }, new Map<string, ControlStatus>());

    return controls.map((control) => ({
      ...control,
      ...getControlPrograms(control, programsMap),
      status: controlStatusesById.get(control.id) || control.status,
    }));
  },
);

export const getProgramControls = createSelector(
  [getOrgControls, (_: AppRootState, programId: string) => programId],
  (orgControls, programId) => {
    return orgControls.filter((control) => control.programs.some(({ id }) => id === programId));
  },
);

const getControlApiData = createSelector(
  (state: AppRootState, controlId?: string) => ({ state, controlId }),
  ({ state, controlId }) => {
    if (!controlId) {
      return undefined;
    }
    return getControlApi.endpoints.GetControl.select({
      controlId,
    })(state).data?.controls_by_pk;
  },
);

export const getMappedControl = createSelector(
  [getControlApiData, getOrgProgramsMap],
  (control, programsMap) => {
    if (!control) {
      return undefined;
    }

    const tags = {
      groups:
        control.groups.map(({ group }) => ({
          value: group.id,
          label: group.name,
          colorScheme: 'purple',
        })) || [],
      categories:
        control.categories.map(({ category }) => ({
          value: category.id,
          label: category.name,
          colorScheme: 'purple',
        })) || [],
      criteria:
        control.criterias.map(({ criteria }) => ({
          value: criteria.id,
          label: criteria.name,
          colorScheme: 'purple',
        })) || [],
      custom:
        control?.custom_tags.map(({ tag }) => ({
          value: tag.id,
          label: tag.name,
          colorScheme: 'purple',
        })) || [],
    };
    return { ...control, ...getControlPrograms(control, programsMap), tags };
  },
);

export const getMappedControlEvidences = createSelector([getControlApiData], (control) => {
  return (
    control?.control_evidences
      .map(({ id, evidence, status }) => {
        if (!evidence) {
          return undefined;
        }

        return {
          controlEvidenceId: id,
          ...evidence,
          name: evidence.name || '',
          status,
          currentVersion: evidence.evidence_versions[0]
            ? {
                id: evidence.evidence_versions[0].id,
                url: evidence.evidence_versions[0].url,
                fileId: evidence.evidence_versions[0].evidence_version_file?.file.id,
                validityStart: evidence.evidence_versions[0].validity_start,
              }
            : undefined,
        };
      })
      .filter(isNonNullable) || []
  );
});

const getGroupByControlEvidenceIdMap = createSelector(
  [getMappedControlEvidences],
  (mappedControlEvidences) => mapByKey(mappedControlEvidences, 'controlEvidenceId'),
);

export type ControlEvidence = ReturnType<typeof getMappedControlEvidences>[number];

type FindingAPIData = NonNullable<ReturnType<typeof getControlApiData>>['findings'][number];
export type ControlFindingsWithMetadata = {
  highestSeverity: Severity;
  findings: ControlFinding[];
  countWithoutIgnored: number;
  countWithoutRecommendationAndIgnored: number;
};

export type ControlFinding =
  | ControlFindingGeneric
  | ControlFindingEvidenceStatus
  | ControlFindingIntegrationError
  | ControlFindingIntegrationCheck
  | ControlFindingPolicyError;

type ControlFindingBase = {
  finding: FindingAPIData;
  severity: Severity;
};

export type ControlFindingGeneric = ControlFindingBase & {
  cause: Finding_Causes_Enum.AiRecommendation;
};

export type ControlFindingEvidenceStatus = ControlFindingBase & {
  cause: Finding_Causes_Enum.AtRiskEvidence | Finding_Causes_Enum.ExpiredEvidence;
  evidence: ControlEvidence;
};

export type ControlFindingIntegrationError = ControlFindingBase & {
  cause: Finding_Causes_Enum.IntegrationError;
  providerName: Integration_Names_Enum;
  errorMessage: string;
};

export type ControlFindingIntegrationCheck = ControlFindingBase & {
  cause: Finding_Causes_Enum.IntegrationCheck;
  integrationRunId: string;
  providerName: Integration_Names_Enum;
  checkFailureMessage: string;
};

export type ControlFindingPolicyError = ControlFindingBase & {
  cause: Finding_Causes_Enum.PolicyError;
  errorMessage: string;
};

export const getControlFindings = createSelector(
  [getControlApiData, getGroupByControlEvidenceIdMap],
  (control, controlEvidenceMap): ControlFindingsWithMetadata => {
    const controlFindings = (control?.findings || [])
      .map((finding): ControlFinding | null => {
        const severity = findingTypeSeverity(finding.type);

        switch (finding.cause) {
          case Finding_Causes_Enum.AiRecommendation: {
            return {
              cause: finding.cause,
              severity,
              finding,
            };
          }

          case Finding_Causes_Enum.AtRiskEvidence:
          case Finding_Causes_Enum.ExpiredEvidence: {
            if (!finding.control_evidence_id) return null;
            const evidence = controlEvidenceMap[finding.control_evidence_id];
            if (!evidence) return null;
            return {
              cause: finding.cause,
              severity,
              finding,
              evidence,
            };
          }

          case Finding_Causes_Enum.IntegrationError: {
            const providerName =
              finding.integration_run?.evidence_integration.organization_integration.integration
                .name;
            if (!providerName) return null;
            const errorMessage = finding.integration_run?.error_message;
            if (!errorMessage) return null;
            return {
              cause: finding.cause,
              severity,
              finding,
              providerName,
              errorMessage,
            };
          }

          case Finding_Causes_Enum.IntegrationCheck: {
            const integrationRunId = finding.integration_run?.id;
            const providerName =
              finding.integration_run?.evidence_integration.organization_integration.integration
                .name;
            const checkFailureMessage = finding.integration_run?.check_failure_message;
            if (!integrationRunId || !providerName || !checkFailureMessage) {
              return null;
            }
            return {
              cause: finding.cause,
              severity,
              finding,
              integrationRunId,
              providerName,
              checkFailureMessage,
            };
          }

          case Finding_Causes_Enum.PolicyError: {
            const errorMessage = finding.evidence_policy?.error_message;
            if (!errorMessage) return null;
            return {
              cause: finding.cause,
              severity,
              finding,
              errorMessage,
            };
          }

          // not supported finding yet
          default: {
            return null;
          }
        }
      })
      .filter(isNonNullable)
      .sort((a, b) => b.severity - a.severity);

    const evidenceWithHighestSeverity = controlFindings[0];

    const findingsWithoutIgnored = controlFindings.filter(
      ({ finding }) => finding.ignored_at === null,
    );
    const findingsWithoutRecommendationAndIgnored = findingsWithoutIgnored.filter(
      ({ finding }) => finding.type !== Finding_Types_Enum.Recommendation,
    );

    return {
      highestSeverity: evidenceWithHighestSeverity?.severity || Severity.LOW,
      findings: controlFindings,
      countWithoutIgnored: findingsWithoutIgnored.length,
      countWithoutRecommendationAndIgnored: findingsWithoutRecommendationAndIgnored.length,
    };
  },
);

import { Button, Card, HStack, Icon, Stack, useDisclosure } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowPathIcon, ArrowUpTrayIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { VendorAssessmentStatus } from '@main/graphql/client-scalars';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import { formatDate, isNonNullable, toError, useStableCallback } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  TableEmptyState,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { dateToMidnightUTC } from '../../utils/date';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import {
  useVQByIdDownloader,
  useVQByQuestionnaireDownloader,
} from '../vendors/vendor-questionnaires/use-vq-downloader';
import {
  useDeteleVendorQuestionnaireMutation,
  useUpdateVendorQuestionnaireMutation,
} from '../vendors/vendor-questionnaires/vendor-questionnaires.generated';
import { SendQuestionnaireModal } from './send-questionnaire-modal';
import { VendorsTabGetVendorsQuery } from './vendors-tab.generated';
import { useVendorsTabGetVendorsSubscription } from './vendors-tab.subs';

type VendorQuestionnaire = VendorsTabGetVendorsQuery['vendor_questionnaires'][number];

export function QuestionnairesVendorsTab({ questionnaireId }: { questionnaireId: string }) {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendors = !!userRole.permissionMap?.write_vendors;

  const { data, isLoading } = useVendorsTabGetVendorsSubscription({ questionnaireId });
  const questionnaires = data?.vendor_questionnaires ?? [];

  const columns = useQuestionnaireVendorsTableColumns();
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.vendor').toLowerCase(),
      plural: t('entities.plural.vendors').toLowerCase(),
    };
  }, [t]);

  const createVendorDisclosure = useDisclosure();

  const vqDownloader = useVQByQuestionnaireDownloader({ questionnaireId });
  const canExportAll =
    !!userRole.permissionMap.read_vendors &&
    questionnaires.some((vq) => vq.status === Vendor_Questionnaire_Statuses_Enum.Submitted);

  return (
    <Stack spacing={6}>
      <Card variant="table-styles" overflowY="hidden">
        <Table
          minW="700px"
          data={questionnaires}
          isLoading={isLoading}
          columns={columns}
          itemName={tableItemName}
          pageSize={15}
          renderEmptyState={(props) => (
            <TableEmptyState
              {...props}
              subHeading={
                canUpdateVendors
                  ? t('table.clickButtonBelowToAdd', { item: tableItemName.singular })
                  : undefined
              }
            />
          )}
        />
      </Card>
      <HStack alignSelf="end" mt="4">
        {canExportAll && (
          <Button
            leftIcon={<Icon w={4} h={4} as={ArrowUpTrayIcon} />}
            variant="outline"
            isDisabled={vqDownloader.isDownloading}
            onClick={vqDownloader.start}
          >
            {t('questionnaires.vendors.exportAllButton')}
          </Button>
        )}
        {canUpdateVendors && (
          <>
            <Button
              leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />}
              colorScheme="blue"
              variant="solid"
              onClick={createVendorDisclosure.onOpen}
            >
              {t('questionnaires.vendors.addButton')}
            </Button>
            <SendQuestionnaireModal
              questionnaireId={questionnaireId}
              disclosure={createVendorDisclosure}
            />
          </>
        )}
      </HStack>
    </Stack>
  );
}

function useQuestionnaireVendorsTableColumns() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendors = !!userRole.permissionMap?.write_vendors;
  const [updateVendorQuestionnaire] = useUpdateVendorQuestionnaireMutation();
  const [deteleVendorQuestionnaire] = useDeteleVendorQuestionnaireMutation();
  const vqDownloader = useVQByIdDownloader();

  const onDueDateUpdate = useStableCallback(
    async (vendorQuestionnaire: VendorQuestionnaire, dueDate: string) => {
      const adjustedDueDate = dateToMidnightUTC(dueDate);

      try {
        await updateVendorQuestionnaire({
          vendorQuestionnaireId: vendorQuestionnaire.id,
          input: {
            due_date: adjustedDueDate,
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.questionnaire') }));
        datadogLogs.logger.error('Vendor questionnaire update failed', {}, toError(error));
      }
    },
  );

  const deleteQuestionnaire = useStableCallback(async (vendorQuestionnaireId: string) => {
    try {
      await deteleVendorQuestionnaire({ vendorQuestionnaireId }).unwrap();
      successToast(t('vendors.questionnaires.toasts.deleteSuccess'));
    } catch (error) {
      errorToast(t('vendors.questionnaires.toasts.deleteFailed'));
      datadogLogs.logger.error(
        'Vendor questionnaire request deletion failed',
        { vendorQuestionnaireId },
        toError(error),
      );
    }
  });

  const resendQuestionnaire = useStableCallback(async (vendorQuestionnaireId: string) => {
    try {
      await updateVendorQuestionnaire({
        vendorQuestionnaireId,
        input: { status: Vendor_Questionnaire_Statuses_Enum.Created },
      }).unwrap();
      successToast(t('vendors.questionnaires.toasts.resendSuccess'));
    } catch (error) {
      errorToast(t('vendors.questionnaires.toasts.resendFailed'));
      datadogLogs.logger.error(
        'Vendor questionnaire re-send failed',
        { vendorQuestionnaireId },
        toError(error),
      );
    }
  });

  return useMemo(() => {
    const columnHelper = createColumnHelper<VendorQuestionnaire>();
    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('vendors.questionnaires.tableColumns.status'),
        accessorFn: (data) => {
          const statusMeta = QUESTIONNAIRE_STATUSES[data.status];

          return {
            value: t(statusMeta.value),
            colorScheme: statusMeta?.colorScheme,
          };
        },
        size: 120,
      }),
      columnHelper.columns.text({
        id: 'name',
        header: t('vendors.questionnaires.tableColumns.name'),
        accessorFn: (data) => data.vendor.name,
        enableGlobalFilter: true,
        meta: {
          cell: {
            onClick: ({ row }) =>
              drawer.open({
                entity: 'vendor-questionnaire',
                entityId: row.original.id,
                activeTab:
                  row.original.status === Vendor_Questionnaire_Statuses_Enum.Submitted
                    ? 'submissions'
                    : undefined,
              }),
          },
        },
      }),
      columnHelper.columns.date({
        id: 'dueDate',
        header: t('vendors.questionnaires.tableColumns.dueDate'),
        accessorFn: (data) => formatDate(data.due_date),
        edit: {
          onChange: (row, value) => onDueDateUpdate(row.original, value),
          canEditGuard: (row) =>
            canUpdateVendors &&
            row.original.vendor.assessment_status !== VendorAssessmentStatus.TERMINATED,
        },
        size: 150,
        meta: {
          cell: { fontSize: 'xs', color: 'gray.500' },
        },
      }),
      columnHelper.columns.avatar({
        id: 'sentBy',
        header: t('vendors.questionnaires.tableColumns.sentBy'),
        variant: 'short',
        accessorFn: (data) => ({
          id: data?.sent_by_user?.id ?? '',
          displayName: data?.sent_by_user?.displayName ?? 'Unknown',
        }),
        size: 80,
      }),
      canUpdateVendors
        ? columnHelper.columns.actions({
            size: 52,
            secondaryActions: ({ row }) =>
              [
                !!userRole.permissionMap.read_vendors &&
                row.original.status === Vendor_Questionnaire_Statuses_Enum.Submitted
                  ? {
                      icon: <Icon as={ArrowUpTrayIcon} />,
                      label: t('vendors.questionnaires.actions.exportAnswers'),
                      onClick: () => vqDownloader.start(row.original.id),
                    }
                  : undefined,
                !row.original.is_disabled
                  ? {
                      icon: <Icon as={ArrowPathIcon} />,
                      label: t('vendors.questionnaires.actions.resendQuestionnaire'),
                      onClick: () =>
                        openDialog({
                          dialogHeader: t('vendors.questionnaires.resendAlert.header'),
                          dialogContent: t('vendors.questionnaires.resendAlert.content'),
                          confirmAction: {
                            children: t('vendors.questionnaires.resendAlert.confirmLabel'),
                            colorScheme: 'blue',
                            onClick: () => resendQuestionnaire(row.original.id),
                          },
                        }),
                    }
                  : undefined,
                {
                  icon: <Icon as={TrashIcon} />,
                  label: t('vendors.questionnaires.actions.deleteQuestionnaire'),
                  onClick: () =>
                    openDialog({
                      dialogHeader: t('vendors.questionnaires.deleteAlert.header'),
                      dialogContent: t('vendors.questionnaires.deleteAlert.content'),
                      confirmAction: {
                        children: t('buttons.delete'),
                        onClick: () => deleteQuestionnaire(row.original.id),
                      },
                    }),
                },
              ].filter(isNonNullable),
          })
        : undefined,
    ].filter(isNonNullable);
  }, [
    t,
    canUpdateVendors,
    drawer,
    onDueDateUpdate,
    userRole.permissionMap.read_vendors,
    vqDownloader,
    openDialog,
    resendQuestionnaire,
    deleteQuestionnaire,
  ]);
}

import { Icon } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { UserMinusIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { OrganizationUserDetailsFragment } from '@main/graphql/fragments/OrganizationUserDetailsFragment.generated';
import { useUpdateOrganizationMemberMutation } from '@main/graphql/mutations/UpdateOrganizationMember.generated';
import { useGetOrganizationRolesQuery } from '@main/graphql/queries/GetOrganizationRoles.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  Table,
  useTableFiltersQuery,
  useTableSearchQuery,
} from '@main/ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import {
  getCurrentOrgUsersMap,
  getCurrentUserId,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../../../user/slice';
import { getRoleTagConfig } from '../../constants';

const defaultColumnVisibility = {
  name: false,
};

export const UsersTable = () => {
  const { t } = useTranslation();

  const currentUserSelectedOrg = useAppSelector(getCurrentUserSelectedOrg);
  const users = currentUserSelectedOrg.organization_users || [];

  const columns = useUsersTableColumns();
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'usersFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });

  return (
    <Table
      data={users}
      columns={columns}
      itemName={{
        singular: t('entities.user').toLowerCase(),
        plural: t('entities.plural.users').toLowerCase(),
      }}
      pageSize={15}
      defaultColumnVisibility={defaultColumnVisibility}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
    />
  );
};

const STATUSES = {
  active: { value: 'Active' as const, colorScheme: 'green' },
  disabled: { value: 'Disabled' as const, colorScheme: 'gray' },
};

function useUsersTableColumns() {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentUserId = useAppSelector(getCurrentUserId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canInviteUser = userRole.permissionMap?.write_roles;
  const { data: orgRoles } = useGetOrganizationRolesQuery({ organizationId });

  const [updateOrgUser] = useUpdateOrganizationMemberMutation();

  const onRoleChange = useCallback(
    async ({
      selectedUserId,
      selectedRoleId,
    }: {
      selectedUserId: string;
      selectedRoleId: string;
    }) => {
      try {
        await updateOrgUser({
          user_id: selectedUserId,
          organization_id: organizationId,
          organization_users_set_input: {
            role_id: selectedRoleId,
          },
        }).unwrap();
      } catch (error) {
        errorToast('Failed to update this user.');
        datadogLogs.logger.error('User role update failed', {}, toError(error));
      }
    },
    [organizationId, updateOrgUser],
  );

  const onDisableUser = useCallback(
    async ({ disable, selectedUserId }: { disable: boolean; selectedUserId: string }) => {
      try {
        await updateOrgUser({
          user_id: selectedUserId,
          organization_id: organizationId,
          organization_users_set_input: {
            disabled: disable,
          },
        }).unwrap();
      } catch (error) {
        errorToast('Failed to update this user.');
        datadogLogs.logger.error('User disable failed', {}, toError(error));
      }
    },
    [organizationId, updateOrgUser],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationUserDetailsFragment>();

    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('users.tableColumns.status'),
        accessorFn: ({ disabled }) => {
          const statusMeta = disabled ? STATUSES.disabled : STATUSES.active;

          return {
            value: statusMeta.value,
            colorScheme: statusMeta.colorScheme,
          };
        },
        size: 120,
      }),
      columnHelper.columns.avatar({
        id: 'avatar',
        header: t('users.tableColumns.name'),
        variant: 'detailed',
        accessorFn: ({ user }) => {
          if (!user) {
            return;
          }

          const member = currentOrgUsers[user.id];
          if (!member) {
            return;
          }

          return {
            id: member.id,
            displayName: member.displayName,
          };
        },
      }),
      columnHelper.columns.text({
        id: 'name',
        header: t('users.tableColumns.name'),
        accessorFn: ({ user }) => user.displayName,
        enableGlobalFilter: true,
      }),
      columnHelper.columns.text({
        id: 'email',
        header: t('users.tableColumns.email'),
        accessorFn: ({ user }) => user.email,
        enableGlobalFilter: true,
      }),
      columnHelper.columns.tag({
        id: 'role',
        header: t('users.tableColumns.role'),
        accessorFn: ({ role }) => getRoleTagConfig(role),
        size: 120,
        ...(canInviteUser && {
          edit: {
            isClearable: false,
            options: orgRoles?.roles?.map(getRoleTagConfig).filter(isNonNullable),
            onChange: async (row, role) => {
              if (!role || currentUserId === row.original.user.id) {
                errorToast(`You can't change your own role`);
                return null;
              }

              return onRoleChange({
                selectedUserId: row.original.user.id,
                selectedRoleId: role.value,
              });
            },
          },
        }),
        enableColumnFilter: true,
        enableGlobalFilter: true,
      }),
      columnHelper.columns.actions({
        size: 70,
        ...(canInviteUser && {
          secondaryActions: ({ row }) => {
            if (row.original.user.id === currentUserId) {
              return [];
            }

            return [
              {
                icon: <Icon as={row.original.disabled ? UserPlusIcon : UserMinusIcon} />,
                label: row.original.disabled ? t('buttons.enable') : t('buttons.disable'),
                onClick: () =>
                  onDisableUser({
                    disable: !row.original.disabled,
                    selectedUserId: row.original.user.id,
                  }),
              },
            ];
          },
        }),
      }),
    ];
  }, [
    t,
    canInviteUser,
    orgRoles?.roles,
    currentOrgUsers,
    currentUserId,
    onRoleChange,
    onDisableUser,
  ]);
}

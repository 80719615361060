import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { Promisable, UseFormWithViewReturn } from '@main/shared/types';
import { toError } from '@main/shared/utils';
import {
  errorToast,
  FileUpload,
  UploadedFile,
  useDownloadStorageFile,
  useLazyFileUpload,
} from '@main/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { dateToMidnightUTC, toDatePart } from '../../../utils/date';

export type SingleFileEvidenceFormSchema = z.infer<typeof formSchema>;

const formSchema = z.object({
  validityStart: z.string().min(1).transform(dateToMidnightUTC),
  fileId: z.string(),
});

type DefaultValues = {
  validityStart?: string;
  file?: UploadedFile;
};

const NEW_FILE_ID = 'new';

export function useSingleFileEvidenceForm({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: DefaultValues;
  onSubmit: (data: SingleFileEvidenceFormSchema) => Promisable<void>;
}): UseFormWithViewReturn {
  const { t } = useTranslation();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: normalizeDefaultValues(defaultValues),
  });

  const { register, setValue, formState } = form;

  const fileUpload = useLazyFileUpload({
    file: defaultValues?.file,
    onFileAdd: () => {
      setValue('fileId', NEW_FILE_ID, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    onFileDelete: () => {
      setValue('fileId', '', {
        shouldDirty: true,
      });
    },
    onFileDownload: useDownloadStorageFile(),
  });

  const reset = () => {
    form.reset(normalizeDefaultValues(defaultValues));
    fileUpload.clear();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    if (data.fileId === NEW_FILE_ID) {
      const { error, file } = await fileUpload.upload();
      if (error) {
        errorToast(
          t('errorMessages.uploadFailed', { entity: t('entities.evidence_version_file') }),
        );
        datadogLogs.logger.error('Failed uploading evidence version file', {}, toError(error));
        return;
      }

      await onSubmit({
        validityStart: data.validityStart,
        fileId: file.id,
      });
    } else {
      await onSubmit({
        validityStart: data.validityStart,
        fileId: data.fileId,
      });
    }
  });

  const view = (
    <Stack spacing={6}>
      <FormControl isInvalid={!!formState.errors.validityStart}>
        <FormLabel>{t('evidences.version.label.validityStart')}:</FormLabel>
        <Input type="date" {...register('validityStart')} />
        <FormErrorMessage>{formState.errors.validityStart?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!formState.errors.fileId} isReadOnly={!!defaultValues}>
        <FormLabel>{t('evidences.version.label.upload')}:</FormLabel>
        <FileUpload {...fileUpload.props}>
          <FileUpload.Dropzone
            topLabelText={t('evidences.version.dropzone.topLabel')}
            bottomLabelText={t('evidences.version.dropzone.bottomLabel')}
          />
        </FileUpload>
        <FormErrorMessage>{formState.errors.fileId?.message}</FormErrorMessage>
      </FormControl>
    </Stack>
  );

  return {
    formState,
    view,
    reset,
    handleSubmit,
  };
}

function normalizeDefaultValues(defaultValues?: DefaultValues): SingleFileEvidenceFormSchema {
  return {
    validityStart: toDatePart(defaultValues?.validityStart),
    fileId: defaultValues?.file?.id ?? '',
  };
}

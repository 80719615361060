import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { ReviewForm } from '../reviews/review-form';
import { ReviewsTable } from '../reviews/reviews-table';
import { useCreateRiskReview } from '../reviews/use-create-review';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useGetRiskDrawerData } from './use-get-risk-data';

export const RiskReviewsTab = ({ riskId }: { riskId: string }) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { risk, isLoading: isReviewLoading } = useGetRiskDrawerData({ riskId });
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateAndEdit = !!userRole.permissionMap?.write_risks;
  const createRiskReview = useCreateRiskReview();

  return (
    <Flex direction="column" gap="6">
      <ReviewsTable
        data={risk?.reviews ?? []}
        isLoading={isReviewLoading}
        canEdit={canCreateAndEdit}
      />
      {canCreateAndEdit && (
        <Button
          colorScheme="blue"
          leftIcon={<Icon as={PlusIcon} />}
          alignSelf="end"
          onClick={onOpen}
        >
          {t('reviews.newReview')}
        </Button>
      )}

      <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('reviews.createReview')}</ModalHeader>
          <ModalCloseButton />
          <ReviewForm
            onModalClose={onClose}
            onSubmit={(formData) => createRiskReview({ riskId, ...formData })}
            renderActionButton={(formState) => (
              <Button type="submit" colorScheme="blue" isLoading={formState.isSubmitting}>
                {t('buttons.create')}
              </Button>
            )}
          />
        </ModalContent>
      </Modal>
    </Flex>
  );
};

import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { DrawerProperty, EditableInput } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { dateToMidnightUTC, toDatePart } from '../../../utils/date';

export const DateFieldInput = ({
  name,
  value,
  onChange,
  isReadOnly,
}: {
  name: string;
  value?: string;
  onChange: (value: string) => void;
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation('ui');

  return (
    <DrawerProperty isReadOnly={isReadOnly}>
      <DrawerProperty.Label icon={CalendarDaysIcon}>{name}</DrawerProperty.Label>
      <DrawerProperty.Content>
        <EditableInput
          input={{ type: 'date' }}
          placeholder={t('customFields.placeholder.dateField')}
          editable={{
            defaultValue: value ? toDatePart(value) : undefined,
            onSubmit: (newValue) => {
              onChange(dateToMidnightUTC(newValue));
            },
          }}
        />
      </DrawerProperty.Content>
    </DrawerProperty>
  );
};

import { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import { FieldValueFragment } from '@main/graphql/fragments/FieldValueFragments.generated';
import { Field_Types_Enum } from '@main/graphql/types.generated';

import {
  getDateFieldValue,
  getEmailFieldValue,
  getNumberFieldValue,
  getSelectFieldValues,
  getTextFieldValue,
  getUrlFieldValue,
} from '../field-value';
import { useSetDateFieldValue } from '../use-set-date-field-value';
import { useSetEmailFieldValue } from '../use-set-email-field-value';
import { useSetNumberFieldValue } from '../use-set-number-field-value';
import { useSetSelectFieldValues } from '../use-set-select-field-values';
import { useSetTextFieldValue } from '../use-set-text-field-value';
import { useSetUrlFieldValue } from '../use-set-url-field-value';
import { DateFieldInput } from './date-field-input';
import { EmailFieldInput } from './email-field-input';
import { NumberFieldInput } from './number-field-input';
import { SelectFieldInput } from './select-field-input';
import { TextFieldInput } from './text-field-input';
import { UrlFieldInput } from './url-field-input';

export const CustomFieldInput = ({
  entityId,
  config,
  values = [],
  onChange,
  isReadOnly,
}: {
  entityId: string;
  config: FieldConfigFragment;
  values?: FieldValueFragment[];
  onChange?: () => void;
  isReadOnly?: boolean;
}) => {
  const setTextFieldValue = useSetTextFieldValue();
  const setUrlFieldValue = useSetUrlFieldValue();
  const setDateFieldValue = useSetDateFieldValue();
  const setNumberFieldValue = useSetNumberFieldValue();
  const setEmailFieldValue = useSetEmailFieldValue();
  const setSelectFieldValues = useSetSelectFieldValues();

  switch (config.field_type) {
    case Field_Types_Enum.Text: {
      const textFieldValue = getTextFieldValue(config, values);

      return (
        <TextFieldInput
          key={config.id}
          name={config.name}
          isReadOnly={isReadOnly}
          value={textFieldValue?.value}
          onChange={async (nextValue) => {
            await setTextFieldValue({
              fieldConfig: config,
              fieldValue: textFieldValue,
              entityId,
              nextValue,
            });

            onChange?.();
          }}
        />
      );
    }

    case Field_Types_Enum.Url: {
      const urlFieldValue = getUrlFieldValue(config, values);

      return (
        <UrlFieldInput
          key={config.id}
          name={config.name}
          isReadOnly={isReadOnly}
          value={urlFieldValue?.value}
          onChange={async (nextValue) => {
            await setUrlFieldValue({
              fieldConfig: config,
              fieldValue: urlFieldValue,
              entityId,
              nextValue,
            });

            onChange?.();
          }}
        />
      );
    }

    case Field_Types_Enum.Date: {
      const dateFieldValue = getDateFieldValue(config, values);

      return (
        <DateFieldInput
          key={config.id}
          name={config.name}
          isReadOnly={isReadOnly}
          value={dateFieldValue?.value}
          onChange={async (nextValue) => {
            await setDateFieldValue({
              fieldConfig: config,
              fieldValue: dateFieldValue,
              entityId,
              nextValue,
            });

            onChange?.();
          }}
        />
      );
    }

    case Field_Types_Enum.Number: {
      const numberFieldValue = getNumberFieldValue(config, values);

      return (
        <NumberFieldInput
          key={config.id}
          name={config.name}
          isReadOnly={isReadOnly}
          value={numberFieldValue?.value}
          onChange={async (nextValue) => {
            await setNumberFieldValue({
              fieldConfig: config,
              fieldValue: numberFieldValue,
              entityId,
              nextValue,
            });

            onChange?.();
          }}
        />
      );
    }

    case Field_Types_Enum.Email: {
      const emailFieldValue = getEmailFieldValue(config, values);

      return (
        <EmailFieldInput
          key={config.id}
          name={config.name}
          isReadOnly={isReadOnly}
          value={emailFieldValue?.value}
          onChange={async (nextValue) => {
            await setEmailFieldValue({
              fieldConfig: config,
              fieldValue: emailFieldValue,
              entityId,
              nextValue,
            });

            onChange?.();
          }}
        />
      );
    }

    case Field_Types_Enum.Select: {
      const options =
        config.select_field_config?.select_field_options.map(({ value }) => value) || [];
      const selectValues = getSelectFieldValues(config, values).map(({ value }) => value);

      return (
        <SelectFieldInput
          key={config.id}
          name={config.name}
          isReadOnly={isReadOnly}
          options={options}
          values={selectValues}
          isMulti={config.select_field_config?.is_multi}
          isCreatable={config.select_field_config?.is_creatable}
          onChange={async (nextValues) => {
            await setSelectFieldValues({
              fieldConfigId: config.id,
              entityId,
              values: nextValues,
            });

            onChange?.();
          }}
        />
      );
    }
  }
};

import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { TrashIcon } from '@heroicons/react/24/outline';
import { maxTextLength, toError, useStableCallback } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  useAlertDialog,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CLIENT_QESTIONNAIRES_STATUSES } from '../../utils/constants';
import { useTableSettings } from '../../utils/table-settings';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import {
  TableClientQuestionnaireFragment,
  useDeleteClientQuestionnaireTableMutation,
} from './table.generated';
import { useGetClientQuestionnairesTableSubscription } from './table.subs';

export function ClientQuestionnairesTable() {
  const { t } = useTranslation();
  const organization_id = useAppSelector(getCurrentUserSelectedOrgId);
  const { data, isFetching } = useGetClientQuestionnairesTableSubscription({ organization_id });

  const columns = useClientQuestionnaireColumns();
  const { columnVisibility, setColumnVisibility } = useTableSettings({
    storageKey: 'client-questionnaires:table:column-visibility',
  });
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'clientQuestionnairesFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: 'clientQuestionnairesSort' });

  const tableItemName = useMemo(
    () => ({
      singular: t('entities.clientQuestionnaire').toLowerCase(),
      plural: t('entities.plural.clientQuestionnaire').toLowerCase(),
    }),
    [t],
  );

  return (
    <Table
      minW="900px"
      columns={columns}
      data={data?.client_questionnaires || []}
      isLoading={isFetching}
      itemName={tableItemName}
      pageSize={15}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
}

function useClientQuestionnaireColumns() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDelete = !!userRole.permissionMap?.write_client_q;
  const [deleteClientQuestionnaire] = useDeleteClientQuestionnaireTableMutation();

  const deleteQuestionnaireHandler = useStableCallback(async (id: string) => {
    try {
      await deleteClientQuestionnaire({ id }).unwrap();
      successToast(
        t('successMessages.deleteSucceeded', { entity: t('entities.clientQuestionnaire') }),
      );
    } catch (error) {
      datadogLogs.logger.error('Failed to delete client questionnaire', { id }, toError(error));
      errorToast(t('errorMessages.deleteFailed', { entity: t('entities.clientQuestionnaire') }));
    }
  });

  return useMemo(() => {
    const columnHelper = createColumnHelper<TableClientQuestionnaireFragment>();

    return [
      columnHelper.columns.tag({
        id: 'status',
        header: t('clientQuestionnaires.tableColumns.status'),
        accessorFn: ({ status }) => {
          const metaType = CLIENT_QESTIONNAIRES_STATUSES[status];
          return {
            value: t(metaType.value),
            colorScheme: metaType.colorScheme,
          };
        },
        enableSorting: true,
        size: 120,
      }),
      columnHelper.columns.text({
        id: 'name',
        header: t('clientQuestionnaires.tableColumns.name'),
        accessorFn: ({ name }) => name,
        enableGlobalFilter: true,
        enableSorting: true,
        meta: {
          cell: {
            onClick: (cell) =>
              navigate({
                to: `/client-questionnaires/$questionnaireId`,
                params: { questionnaireId: cell.row.original.id },
              }),
          },
        },
      }),
      columnHelper.columns.text({
        id: 'company',
        header: t('clientQuestionnaires.tableColumns.company'),
        accessorFn: ({ company }) => company,
        enableGlobalFilter: true,
        enableSorting: true,
        size: 150,
      }),
      columnHelper.columns.date({
        id: 'dueDate',
        header: t('clientQuestionnaires.tableColumns.dueDate'),
        accessorFn: ({ due_date }) => due_date,
        enableSorting: true,
        size: 160,
      }),
      columnHelper.columns.text({
        id: 'progress',
        header: t('clientQuestionnaires.tableColumns.progress'),
        accessorFn: ({ questions_approved, questions_total }) =>
          `${questions_approved.aggregate?.count ?? 0}/${questions_total.aggregate?.count ?? 0}`,
        size: 90,
      }),
      columnHelper.columns.avatar({
        id: 'owner',
        header: t('clientQuestionnaires.tableColumns.owner'),
        accessorFn: ({ owner }) => owner,
        size: 70,
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.columns.date({
        id: 'updatedAt',
        header: t('clientQuestionnaires.tableColumns.updatedAt'),
        accessorFn: ({ updated_at }) => updated_at,
        enableSorting: true,
        size: 160,
      }),
      ...(canDelete
        ? [
            columnHelper.columns.actions({
              size: 50,
              PrimaryAction: (context) => (
                <Tooltip label={t('buttons.delete')} placement="top">
                  <IconButton
                    minW={4}
                    variant="link"
                    aria-label={t('buttons.delete')}
                    icon={<Icon as={TrashIcon} />}
                    onClick={() =>
                      openDialog({
                        dialogHeader: t('clientQuestionnaires.alert.delete.header'),
                        dialogContent: (
                          <Trans
                            i18nKey="clientQuestionnaires.alert.delete.content"
                            values={{
                              name: maxTextLength(
                                `${context.row.original.internal_id} - ${context.row.original.name}`,
                              ),
                            }}
                            components={{ bold: <strong /> }}
                          />
                        ),
                        confirmAction: {
                          children: t('buttons.delete'),
                          onClick: () => deleteQuestionnaireHandler(context.row.original.id),
                        },
                      })
                    }
                  />
                </Tooltip>
              ),
            }),
          ]
        : []),
    ];
  }, [canDelete, deleteQuestionnaireHandler, navigate, openDialog, t]);
}

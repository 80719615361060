import { Box, Button, Divider, Flex, Heading, Icon } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import { useGenerateWorkosAdminLinkMutation } from '@main/graphql/features/GenerateWorkosAdminLink.generated';
import { WorkosAdminPortalIntent } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { Route } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { getCurrentUserSelectedOrg } from '../../../features/user/slice';
import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { organizationSettingsLayoutRoute } from './layout';

const AuditTrailSettingsPage = () => {
  const { t } = useTranslation();
  const currentUserOrg = useAppSelector(getCurrentUserSelectedOrg);
  const [generateWorkOSLink, { isLoading: isGeneratingLink }] =
    useGenerateWorkosAdminLinkMutation();

  return (
    <>
      <Flex py="6" px={[4, 4, 8]} justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h2" size="md">
            {t('settings.organization.auditTrail.heading')}
          </Heading>
        </Box>
      </Flex>
      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <Button
          leftIcon={
            <Icon
              color="white"
              _dark={{ color: 'black' }}
              w={4}
              h={4}
              as={MagnifyingGlassCircleIcon}
            />
          }
          colorScheme="blue"
          variant="solid"
          isLoading={isGeneratingLink}
          onClick={async () => {
            try {
              const data = await generateWorkOSLink({
                orgId: currentUserOrg.id,
                intent: WorkosAdminPortalIntent.AuditLogs,
              }).unwrap();
              data.generate_workos_admin_link?.link &&
                window.open(data.generate_workos_admin_link.link, '_blank');
            } catch (e) {
              errorToast(t('settings.organization.auditTrail.error'));
              datadogLogs.logger.error('Unable to generate workos admin link', {}, toError(e));
            }
          }}
        >
          {t('settings.organization.auditTrail.viewLogs')}
        </Button>
      </Box>
    </>
  );
};

export const organizationAuditTrailSettingsRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: '/audit-trail',
  component: AuditTrailSettingsPage,
});

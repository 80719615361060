import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BulkDownloaderProgress, DownloadFile } from './bulk-downloader';
import { BulkDownloaderLogger } from './logger';
import { BulkDownload, BulkDownloaderSlice, getBulkDownload, startBulkDownload } from './slice';
import { StreamDownloader } from './stream-downloader';
import { StreamSink } from './stream-sink';

export interface BulkDownloaderOptions<TFiles> {
  downloadId?: string;
  streamDownloader: StreamDownloader<TFiles>;
  title?: ReactNode;
  progressTitle?(progress: number): ReactNode;
  successTitle?: ReactNode;
  errorTitle?: ReactNode;
  logger?: BulkDownloaderLogger;
}

export type UseBulkDownloader<TFiles> = ReturnType<typeof useBulkDownloader<TFiles>>;

let lastDownloadId = 0;

export function useBulkDownloader<TFiles>({
  downloadId,
  streamDownloader,
  title,
  progressTitle,
  successTitle,
  errorTitle,
  logger = console,
}: BulkDownloaderOptions<TFiles>) {
  const dispatch = useDispatch();
  const id = useMemo(() => downloadId ?? `${++lastDownloadId}`, [downloadId]);
  const download = useSelector<BulkDownloaderSlice>((state) => getBulkDownload(state, id)) as
    | BulkDownload<TFiles>
    | undefined;
  const [downloaderPromise, setDownloaderPromise] = useState(() =>
    Promise.withResolvers<BulkDownloaderProgress<TFiles>>(),
  );

  useEffect(
    () => download?.downloader && downloaderPromise.resolve(download.downloader),
    [download?.downloader, downloaderPromise],
  );

  return {
    ...download,
    id,
    logger,
    async start(files: DownloadFile<TFiles>[], streamSink: StreamSink) {
      if (download) {
        return download.downloader;
      }

      const downloader = Promise.withResolvers<BulkDownloaderProgress<TFiles>>();
      setDownloaderPromise(downloader);

      dispatch(
        startBulkDownload({
          downloadId: id,
          files,
          downloader: streamDownloader,
          sink: streamSink,
          title,
          progressTitle,
          successTitle,
          errorTitle,
          logger,
        }),
      );

      return downloader.promise;
    },
  };
}

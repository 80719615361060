/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type FieldConfigFragment = {
  __typename?: 'field_configs';
  id: string;
  organization_id: string;
  entity_name: Types.Field_Entities_Enum;
  field_type: Types.Field_Types_Enum;
  name: string;
  hidden: boolean;
  order?: string;
  select_field_config?: {
    __typename?: 'select_field_configs';
    field_config_id: string;
    is_multi: boolean;
    is_creatable: boolean;
    select_field_options: Array<{ __typename?: 'select_field_options'; id: string; value: string }>;
  };
  program_field_configs: Array<{
    __typename?: 'program_field_configs';
    id: string;
    program_id: string;
  }>;
};

export type SelectFieldConfigFragment = {
  __typename?: 'select_field_configs';
  field_config_id: string;
  is_multi: boolean;
  is_creatable: boolean;
  select_field_options: Array<{ __typename?: 'select_field_options'; id: string; value: string }>;
};

export type SelectFieldOptionFragment = {
  __typename?: 'select_field_options';
  id: string;
  value: string;
};

export const SelectFieldOptionFragmentDoc = `
    fragment SelectFieldOption on select_field_options {
  id
  value
}
    `;
export const SelectFieldConfigFragmentDoc = `
    fragment SelectFieldConfig on select_field_configs {
  field_config_id
  is_multi
  is_creatable
  select_field_options(order_by: {value: asc}) {
    ...SelectFieldOption
  }
}
    `;
export const FieldConfigFragmentDoc = `
    fragment FieldConfig on field_configs {
  id
  organization_id
  entity_name
  field_type
  name
  hidden
  order
  select_field_config {
    ...SelectFieldConfig
  }
  program_field_configs {
    id
    program_id
  }
}
    `;

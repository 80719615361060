import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Onelogin]: HandlerConfigOnelogin;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigOnelogin {}
}

export const OneloginConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Onelogin> = {
  name: Integration_Names_Enum.Onelogin,

  orgConfig: z.object({
    baseUrl: z.string().url().describe('API Base URL'),
    clientId: z.string().min(1).describe('Client ID'),
    clientSecret: z.string().min(1).describe('Client Secret'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.SuperUsers),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Mfa),
    }),
  ]),
};

import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { Promisable, UseFormWithViewReturn } from '@main/shared/types';
import { toError } from '@main/shared/utils';
import { errorToast, FileUpload, UploadedFile, useLazyMultipleFilesUpload } from '@main/ui';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { dateToMidnightUTC, toDatePart } from '../../../utils/date';

type FormSchema = z.infer<typeof formSchema>;

const formSchema = z.object({
  validityStart: z.string().min(1).transform(dateToMidnightUTC),
  fileIds: z.array(z.void()),
});

export type MultipleFilesEvidenceFormSchema = Omit<FormSchema, 'fileIds'> & {
  files: UploadedFile[];
};

export function useMultipleFilesEvidenceForm({
  onSubmit,
}: {
  onSubmit: (data: MultipleFilesEvidenceFormSchema) => Promisable<void>;
}): UseFormWithViewReturn {
  const { t } = useTranslation();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: getDefaultValues(),
  });

  const { register, getValues, setValue, formState } = form;

  const filesUpload = useLazyMultipleFilesUpload({
    onFilesAdd: (files) => {
      const newFiles = new Array<void>(files.length);
      const existingFiles = getValues('fileIds');
      const allFiles = [...existingFiles, ...newFiles];
      setValue('fileIds', allFiles, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    onFileDelete: () => {
      const oldFileIds = getValues('fileIds');
      const newFileIds = oldFileIds.slice(0, -1);
      setValue('fileIds', newFileIds, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
  });

  const reset = () => {
    form.reset(getDefaultValues());
    filesUpload.clear();
  };

  const handleSubmit = form.handleSubmit(async (data) => {
    const { errors, files } = await filesUpload.upload();
    if (errors.length) {
      errorToast(t('errorMessages.uploadFailed', { entity: t('entities.evidence_version_file') }));
      for (const error of errors) {
        datadogLogs.logger.error('Failed uploading evidence version files', {}, toError(error));
      }
      return;
    }

    await onSubmit({
      validityStart: data.validityStart,
      files,
    });
  });

  const view = (
    <Stack spacing={6}>
      <FormControl isInvalid={!!formState.errors.validityStart}>
        <FormLabel>{t('evidences.version.label.validityStart')}:</FormLabel>
        <Input type="date" {...register('validityStart')} />
        <FormErrorMessage>{formState.errors.validityStart?.message}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!formState.errors.fileIds}>
        <FormLabel>{t('evidences.version.label.upload')}:</FormLabel>
        <FileUpload {...filesUpload.props}>
          <FileUpload.Dropzone
            topLabelText={t('evidences.version.dropzone.topLabel')}
            bottomLabelText={t('evidences.version.dropzone.bottomLabel')}
          />
        </FileUpload>
        <FormErrorMessage>{formState.errors.fileIds?.message}</FormErrorMessage>
      </FormControl>
    </Stack>
  );

  return {
    formState,
    view,
    reset,
    handleSubmit,
  };
}

function getDefaultValues(): FormSchema {
  return {
    validityStart: toDatePart(),
    fileIds: [],
  };
}

import { Card, ThemingProps } from '@chakra-ui/react';
import { Policy_Approval_Statuses_Enum } from '@main/graphql/types.generated';
import { createColumnHelper, Table } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentOrgUsersMap } from '../user/slice';
import { SendReminder } from './approval-reminder';
import { getPolicyApprovalUsers } from './slice';

export const ApprovalUsers = ({
  policyId,
  approvalId,
}: {
  policyId: string;
  approvalId: string;
}) => {
  const { t } = useTranslation();
  const approvalUsers = useAppSelector((state) =>
    getPolicyApprovalUsers(state, policyId, approvalId),
  );

  const columns = useApproversTableColumns(policyId, approvalId);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.approval').toLowerCase(),
      plural: t('entities.plural.approvals').toLowerCase(),
    };
  }, [t]);

  return (
    <Card variant="table-styles">
      <Table minW="400px" data={approvalUsers} columns={columns} itemName={tableItemName} />
    </Card>
  );
};

export type ApprovalUser = ReturnType<typeof getPolicyApprovalUsers>[number];

function useApproversTableColumns(policyId: string, approvalId: string) {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  return useMemo(() => {
    const columnHelper = createColumnHelper<ApprovalUser>();
    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('policies.approval.details.tableColumns.status'),
        accessorFn: ({
          approvalStatus,
          approved_at,
        }): { value: string; colorScheme: ThemingProps['colorScheme'] } => {
          if (approved_at) {
            return {
              value: t('policies.approval.details.status.Approved'),
              colorScheme: 'green',
            };
          }

          if (approvalStatus === Policy_Approval_Statuses_Enum.Expiring) {
            return {
              value: t('policies.approval.details.status.Expiring'),
              colorScheme: 'orange',
            };
          }

          if (approvalStatus === Policy_Approval_Statuses_Enum.Overdue) {
            return {
              value: t('policies.approval.details.status.Overdue'),
              colorScheme: 'red',
            };
          }

          return {
            value: t('policies.approval.details.status.Pending'),
            colorScheme: 'gray',
          };
        },
        size: 200,
      }),

      columnHelper.columns.avatar({
        id: 'user',
        header: t('policies.approval.details.tableColumns.user'),
        accessorFn: ({ user_id }) => {
          const user = currentOrgUsers[user_id];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 400,
        variant: 'detailed',
      }),

      columnHelper.columns.date({
        id: 'approvedAt',
        header: t('policies.approval.details.tableColumns.approvedAt'),
        accessorFn: ({ approved_at }) => approved_at,
      }),
      columnHelper.columns.actions({
        size: 50,
        PrimaryAction: ({ row }) => <SendReminder row={row.original} />,
      }),
    ];
  }, [currentOrgUsers, t]);
}

import { Promisable } from '@main/shared/types';
import { createSorter, Sortable, useStableCallback } from '@main/shared/utils';
import { memo, Row, RowModel, Table } from '@tanstack/react-table';

import { TableProps } from './table';
import { updateRowsOrder } from './update-rows-order';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface OrderableTableData extends Sortable<string> {}

export interface UseTableRowReorderProps<TData extends OrderableTableData> {
  onRowsOrderUpdated(rows: TData[]): Promisable<void>;
}

export type UseTableRowReorderReturn<TData extends OrderableTableData> = Required<
  Pick<TableProps<TData>, 'getSortedRowModel' | 'onRowReorder'>
>;

export function useTableRowReorder<TData extends OrderableTableData>({
  onRowsOrderUpdated,
}: UseTableRowReorderProps<TData>): UseTableRowReorderReturn<TData> {
  const onRowReorder = useStableCallback(
    (fromRow: Row<TData>, toRow: Row<TData>, table: Table<TData>) =>
      void onRowsOrderUpdated(updateRowsOrder(fromRow, toRow, table.getSortedRowModel().flatRows)),
  );

  return { getSortedRowModel, onRowReorder };
}

function getSortedRowModel<TData extends OrderableTableData>(table: Table<TData>) {
  return memo(
    () => [table.getPreSortedRowModel()],
    (rowModel) => sortRowModel(rowModel),
    {
      key: 'useTableRowReorder.sortedRowModel',
      debug: () => table.options.debugAll || table.options.debugRows,
    },
  );
}

const _sorter = createSorter();
const sorter = <T extends OrderableTableData>(a: Row<T>, b: Row<T>) =>
  _sorter(a.original, b.original);

function sortRowModel<T extends OrderableTableData>(rowModel: RowModel<T>): RowModel<T> {
  return {
    ...rowModel,
    flatRows: rowModel.flatRows.toSorted(sorter),
    rows: sortRows(rowModel.rows),
  };
}

function sortRows<T extends OrderableTableData>(rows: Row<T>[]): Row<T>[] {
  return rows.toSorted(sorter).map((row) => {
    if (!row.subRows.length) {
      return row;
    }
    return { ...row, subRows: sortRows(row.subRows) };
  });
}

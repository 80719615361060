import { datadogLogs } from '@datadog/browser-logs';
import { api as getEvidenceApi } from '@main/graphql/queries/GetEvidenceById.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../hooks/redux-toolkit-hooks';

export function useUpdateEvidenceOnDrawer({ evidenceId }: { evidenceId?: string }) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const updateEvidenceHandler = useCallback(
    async <T>(resultPromise: { unwrap(): Promise<T> }) => {
      setIsLoading(true);

      try {
        const result = await resultPromise.unwrap();

        evidenceId &&
          (await dispatch(
            getEvidenceApi.endpoints.GetEvidenceById.initiate(
              { evidence_id: evidenceId },
              { subscribe: false, forceRefetch: true },
            ),
          ).unwrap());
        return result;
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.evidence') }));
        datadogLogs.logger.error('evidence update failed', {}, toError(error));
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, evidenceId, t],
  );

  return [updateEvidenceHandler, { isLoading }] as const;
}

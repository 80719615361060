import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { RoleDetailsFragment } from '@main/graphql/fragments/RoleDetailsFragment.generated';
import { useCreateOrganizationRoleMutation } from '@main/graphql/mutations/CreateOrganizationRole.generated';
import { useUpdateOrganizationRoleMutation } from '@main/graphql/mutations/UpdateOrganizationRole.generated';
import { useGetOrganizationRolesQuery } from '@main/graphql/queries/GetOrganizationRoles.generated';
import { useGetRoleByIdQuery } from '@main/graphql/queries/GetRoleById.generated';
import { toError } from '@main/shared/utils';
import {
  errorToast,
  successToast,
  Table,
  useTableFiltersQuery,
  useTableSearchQuery,
} from '@main/ui';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../../../user/slice';
import { useRolesTableColumns } from './use-role-table-columns';

const roleSchema = z.object({
  roleName: z.string().min(1),
});

type RoleSchema = z.infer<typeof roleSchema>;
type FormType = 'create' | 'edit';
const formDefaultValues = { name: '', id: '', organization_id: '' };

export const UserRolesPage = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateRole = userRole.permissionMap?.write_roles;
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const [formType, setFormType] = useState<FormType>('create');
  const [role, setRole] = useState<RoleDetailsFragment>(formDefaultValues);

  const { roles, isLoading: isLoadingRoles } = useGetOrganizationRolesQuery(
    { organizationId },
    {
      selectFromResult: ({ data, isLoading }) => ({ roles: data?.roles || [], isLoading }),
    },
  );

  const openCreateModal = () => {
    onOpen();
    setFormType('create');
  };

  const openEditModal = (selectedRole: RoleDetailsFragment) => {
    onOpen();
    setFormType('edit');
    setRole(selectedRole);
  };

  const columns = useRolesTableColumns({ openModal: openEditModal });
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'rolesFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.role').toLowerCase(),
      plural: t('entities.plural.roles').toLowerCase(),
    };
  }, [t]);

  return (
    <>
      <Flex py="6" px={[4, 4, 8]} justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h2" size="md">
            {t('settings.organization.roles.heading')}
          </Heading>
          <Text fontSize="14" color="gray.500">
            {t('settings.organization.roles.subheading')}
          </Text>
        </Box>
        {canCreateRole && (
          <Button
            leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />}
            colorScheme="blue"
            variant="solid"
            onClick={openCreateModal}
          >
            {t('settings.organization.roles.addButton')}
          </Button>
        )}
      </Flex>
      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <Card variant="table-styles">
          <Table
            minW="500px"
            data={roles}
            isLoading={isLoadingRoles}
            columns={columns}
            itemName={tableItemName}
            pageSize={15}
            columnFilters={columnFilters}
            onColumnFiltersChange={setColumnFilters}
            globalFilter={globalFilter}
            onGlobalFilterChange={setGlobalFilter}
          />
        </Card>

        <Modal
          size="xl"
          isCentered
          autoFocus={false}
          isOpen={isOpen}
          onClose={onClose}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            {formType === 'create' ? (
              <CreateRoleForm onClose={onClose} />
            ) : (
              <EditRoleForm onClose={onClose} role={role} />
            )}
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

function CreateRoleForm({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const {
    reset: resetForm,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<RoleSchema>({
    resolver: zodResolver(roleSchema),
    defaultValues: {
      roleName: undefined,
    },
  });

  const { refetch: refetchRoles } = useGetOrganizationRolesQuery({ organizationId });
  const [createRole] = useCreateOrganizationRoleMutation();

  const handleCreateRole: SubmitHandler<RoleSchema> = async (data) => {
    try {
      await createRole({
        organizationId,
        name: data.roleName,
      }).unwrap();

      onClose();
      refetchRoles();
      resetForm();
      successToast(t('successMessages.createSucceeded', { entity: t('entities.role') }));
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.role') }));
      datadogLogs.logger.error(
        `Failed to create new role.`,
        { organizationId, roleName: data.roleName },
        toError(error),
      );
    }
  };
  return (
    <>
      <ModalHeader fontSize="lg" color="gray.700">
        {t('settings.organization.roles.createModal.title')}
      </ModalHeader>
      <form onSubmit={handleSubmit(handleCreateRole)}>
        <ModalBody py={2}>
          <FormControl isInvalid={!!errors.roleName}>
            <FormLabel>{t('settings.organization.roles.createModal.inputLabel')}:</FormLabel>
            <Input
              type="text"
              {...register('roleName')}
              placeholder={t('settings.organization.roles.createModal.inputPlaceholder')}
            />
            <FormErrorMessage>{errors.roleName?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter py={4}>
          <ButtonGroup spacing={3}>
            <Button onClick={onClose}>{t('buttons.cancel')}</Button>
            <Button colorScheme="blue" type="submit" isLoading={isSubmitting} isDisabled={!isDirty}>
              {t('buttons.submit')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </>
  );
}

export function EditRoleForm({
  onClose,
  role,
}: {
  onClose: () => void;
  role: RoleDetailsFragment;
}) {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const {
    reset: resetForm,
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<RoleSchema>({
    resolver: zodResolver(roleSchema),
    defaultValues: {
      roleName: role.name,
    },
  });

  const { refetch: refetchAllRoles } = useGetOrganizationRolesQuery({ organizationId });
  const { refetch: refetchRole } = useGetRoleByIdQuery({ roleId: role.id });

  const [updateRole] = useUpdateOrganizationRoleMutation();

  const handleEditRole: SubmitHandler<RoleSchema> = async (data) => {
    try {
      await updateRole({
        roleId: role.id,
        name: data.roleName,
      }).unwrap();

      onClose();
      resetForm();
      refetchAllRoles();
      refetchRole();
      successToast(t('successMessages.editSucceeded', { entity: t('entities.role') }));
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.role') }));
      datadogLogs.logger.error(
        `Failed to update role.`,
        { organizationId, roleName: data.roleName },
        toError(error),
      );
    }
  };
  return (
    <>
      <ModalHeader fontSize="lg" color="gray.700">
        {t('settings.organization.roles.editModal.title')}
      </ModalHeader>
      <form onSubmit={handleSubmit(handleEditRole)}>
        <ModalBody py={2}>
          <FormControl isInvalid={!!errors.roleName}>
            <FormLabel>{t('settings.organization.roles.editModal.inputLabel')}:</FormLabel>
            <Input
              type="text"
              {...register('roleName')}
              placeholder={t('settings.organization.roles.editModal.inputPlaceholder')}
            />
            <FormErrorMessage>{errors.roleName?.message}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter py={4}>
          <ButtonGroup spacing={3}>
            <Button onClick={onClose}>{t('buttons.cancel')}</Button>
            <Button colorScheme="blue" type="submit" isLoading={isSubmitting} isDisabled={!isDirty}>
              {t('buttons.submit')}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </form>
    </>
  );
}

import { Th, Tr, useColorModeValue } from '@chakra-ui/react';
import { GetRiskClassificationsQuery } from '@main/graphql/features/RiskClassifications.generated';

type Impact = GetRiskClassificationsQuery['impacts'][number];
export const MatrixColumnHeaders = ({ impacts }: { impacts: Impact[] }) => {
  const headerColor = useColorModeValue('gray.500', 'gray.400');
  return (
    <Tr>
      <Th>{/* This is the header for the first column so should be empty  */}</Th>
      {impacts.map((impact) => {
        return (
          <Th
            key={impact.id}
            scope="col"
            color={headerColor}
            textAlign="center"
            fontWeight="normal"
            sx={{ textTransform: 'none' }}
            minW="120px"
          >
            {impact.name}
          </Th>
        );
      })}
    </Tr>
  );
};

export const MatrixRowHeader = ({ headerName }: { headerName: string }) => {
  const headerColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Th
      scope="row"
      color={headerColor}
      fontWeight="normal"
      pl={1}
      sx={{ textTransform: 'none' }}
      whiteSpace="nowrap"
    >
      {headerName}
    </Th>
  );
};

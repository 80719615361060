import { FormConfig } from '@main/graphql/client-scalars';
import {
  useAppendFormItemMutation,
  useUpdateFormConfigMutation,
} from '@main/graphql/features/Form.generated';
import { QuestionnaireFormFieldConfig } from '@main/questionnaires-form';

export function useCreateFormFieldConfig() {
  const [updateForm] = useAppendFormItemMutation();

  return (formId: string, data: QuestionnaireFormFieldConfig) =>
    updateForm({
      form_id: formId,
      config: data,
    });
}

export function useUpdateFormConfig() {
  const [updateFormConfig] = useUpdateFormConfigMutation();

  return (formId: string, config: FormConfig) => updateFormConfig({ form_id: formId, config });
}

import { Skeleton, useToast } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useAddControlCategoryTagMutation } from '@main/graphql/mutations/AddControlCategoryTag.generated';
import { useCreateCategoryTagMutation } from '@main/graphql/mutations/CreateCategoryTag.generated';
import { useRemoveControlCategoryTagMutation } from '@main/graphql/mutations/RemoveControlCategoryTag.generated';
import { useLazyGetCategoryTagsQuery } from '@main/graphql/queries/GetCategoryTags.generated';
import { toError } from '@main/shared/utils';
import { EditableTag } from '@main/ui';
import { ActionMeta, MultiValue } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../../user/slice';
import { api as getControlApi } from '../get-control.generated';
import { getMappedControl } from '../slice';

export const CategoryTags = ({ controlId }: { controlId?: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorToast = useToast({
    status: 'error',
    duration: 5000,
    isClosable: true,
  });

  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const control = useAppSelector((state) => getMappedControl(state, controlId));
  const [getCategoryTags] = useLazyGetCategoryTagsQuery();
  const [createCategoryTag] = useCreateCategoryTagMutation();
  const [addControlCategoryTag] = useAddControlCategoryTagMutation();
  const [removeControlCategoryTag] = useRemoveControlCategoryTagMutation();

  if (!control || !controlId) {
    return <Skeleton h={10} w="240px" rounded="md" mt={2} />;
  }

  const searchCustomTagsHandler = async (input: string) => {
    const { categories } = await getCategoryTags({
      orgId: organization.id,
      ...(input?.trim() ? { nameFilter: { _ilike: `%${input}%` } } : {}),
    }).unwrap();

    return categories.map((category) => ({
      value: category.id,
      label: category.name,
      colorScheme: 'purple',
    }));
  };

  const handler = async (
    resultPromise: ReturnType<
      ReturnType<
        | typeof useCreateCategoryTagMutation
        | typeof useAddControlCategoryTagMutation
        | typeof useRemoveControlCategoryTagMutation
      >[0]
    >,
  ) => {
    try {
      await resultPromise.unwrap();
    } catch (error) {
      errorToast({ title: t('errorMessages.tagFailed', { entity: t('entities.category') }) });
      datadogLogs.logger.error('Unable to perform operation on tags.', {}, toError(error));
      return;
    }
  };

  const handleChange = async (
    items: MultiValue<(typeof control.tags.groups)[number]>,
    actionMeta: ActionMeta<(typeof control.tags.groups)[number]>,
  ) => {
    switch (actionMeta.action) {
      case 'create-option': {
        dispatch(
          getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
            if (draft.controls_by_pk) {
              draft.controls_by_pk.categories.push({
                category: { id: 'new', name: actionMeta.option.label },
              });
            }
          }),
        );

        await handler(
          createCategoryTag({
            object: {
              control_id: controlId,
              category: {
                data: {
                  name: actionMeta.option.label,
                  organization_id: organization.id,
                },
              },
            },
          }),
        );
        break;
      }
      case 'select-option': {
        dispatch(
          getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
            if (draft.controls_by_pk) {
              draft.controls_by_pk.categories.push({
                category: {
                  id: actionMeta.option?.value as string,
                  name: actionMeta.option?.label as string,
                },
              });
            }
          }),
        );
        await handler(
          addControlCategoryTag({ categoryId: actionMeta.option?.value as string, controlId }),
        );
        break;
      }
      case 'remove-value':
      case 'pop-value': {
        dispatch(
          getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
            if (draft.controls_by_pk) {
              const remainingTags = draft.controls_by_pk.categories.filter(
                ({ category }) => category.id !== actionMeta.removedValue.value,
              );
              draft.controls_by_pk.categories = remainingTags;
            }
          }),
        );
        await handler(
          removeControlCategoryTag({
            categoryId: actionMeta.removedValue.value,
            controlId,
          }),
        );
        break;
      }
    }
    await dispatch(
      getControlApi.endpoints.GetControl.initiate(
        { controlId },
        { forceRefetch: true, subscribe: false },
      ),
    ).unwrap();
  };

  return (
    <EditableTag
      isMulti={true}
      debounceMs={500}
      value={control.tags.categories}
      loadOptions={searchCustomTagsHandler}
      onChange={handleChange}
      getNewOptionData={(label) => ({ label, value: 'new', colorScheme: 'purple' })}
      variant="inline"
      placeholder={t('controls.placeholder.category')}
    />
  );
};

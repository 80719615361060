import { DownloadFile } from '@main/bulk-downloader';
import { safeFileName } from '@main/shared/utils';

import { AppDownloadFile } from '../../../bulk-downloader/app-stream-downloader';
import { QuestionnaireExporter } from '../use-questionnaire-exporters';
import { VendorQuestionnaireForDownloadFragment } from '../vq-files-fetcher.generated';

export class FileQuestionnaireExporter implements QuestionnaireExporter {
  canExport(vq: VendorQuestionnaireForDownloadFragment): boolean {
    return !!vq.answer_upload_id;
  }

  exportQuestionnaire(vq: VendorQuestionnaireForDownloadFragment): DownloadFile<AppDownloadFile>[] {
    const answer = vq.uploads.find((upload) => upload.id === vq.answer_upload_id);

    if (!answer) {
      return [];
    }

    return [
      {
        name: `${safeFileName(`Answer file - ${answer.file.name ?? answer.file.id}`)}`,
        file: { fileId: answer.file.id },
      },
      ...vq.uploads
        .filter((upload) => upload.id !== vq.answer_upload_id)
        .map((upload) => ({
          name: `${safeFileName(`${upload.file.id} - ${upload.file.name ?? ''}`)}`,
          file: { fileId: upload.file.id },
        })),
    ];
  }
}

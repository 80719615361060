import { Flex, FormControl, FormLabel, useColorModeValue } from '@chakra-ui/react';
import { UserIcon, UsersIcon } from '@heroicons/react/24/outline';
import {
  Field_Entities_Enum,
  Policy_Types_Enum,
  Policy_Version_Statuses_Enum,
} from '@main/graphql/types.generated';
import {
  DrawerProperty,
  EditableAutoResizeTextarea,
  EditableAvatar,
  EditableMultiSelectAvatar,
  safeEditorState,
  WysiwygEditor,
} from '@main/ui';
import { BarsIcon } from '@main/ui/icons';
import useThrottleFn from 'ahooks/es/useThrottleFn';
import { ActionMeta, MultiValue } from 'chakra-react-select';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CustomFieldInput } from '../custom-fields/custom-field-input';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { PolicyUploadVersion } from './current-version';
import {
  api as policyApi,
  useAddPolicyApproverMutation,
  useDeletePolicyApproverMutation,
  useUpdatePolicyMutation,
  useUpdatePolicyVersionContentMutation,
} from './manage-policies.generated';
import { useGetPolicySubscription } from './policy-subscription';
import { ApproverOption, getPolicyApprovers } from './slice';
import { usePreferredPolicyVersion } from './use-preferred-policy-version';
import { useUpdatePolicyHandler } from './use-update-policy-handler';

export const PolicyDetailsTab = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const textColor = useColorModeValue('gray.600', 'gray.500');
  const currentVersionLabelColor = useColorModeValue('gray.800', 'gray.300');
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const canEditPolicy = userRole.permissionMap?.write_policies;

  const { data } = useGetPolicySubscription({
    id: policyId,
  });
  const policy = data?.policies_by_pk;
  const approvers = useAppSelector((state) => getPolicyApprovers(state, policyId));
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId,
    entityName: Field_Entities_Enum.Policy,
  });

  const updatePolicyHandler = useUpdatePolicyHandler();
  const [updatePolicy] = useUpdatePolicyMutation();
  const [addPolicyApprover] = useAddPolicyApproverMutation();
  const [removePolicyApprover] = useDeletePolicyApproverMutation();

  const getAssigneeValue = () => {
    if (!policy?.assignee_id) {
      return;
    }

    const user = currentOrgUsers[policy.assignee_id];
    if (!user) {
      return;
    }

    return {
      id: policy?.assignee_id,
      displayName: user.displayName,
    };
  };

  const handleApproverChange = async (
    _: MultiValue<ApproverOption>,
    actionMeta: ActionMeta<ApproverOption>,
  ) => {
    switch (actionMeta.action) {
      case 'select-option': {
        dispatch(
          policyApi.util.updateQueryData('GetPolicy', { id: policyId }, (draft) => {
            if (draft.policies_by_pk) {
              draft.policies_by_pk.policy_approvers.push({
                id: actionMeta.option?.id as string,
                user: {
                  id: actionMeta.option?.value as string,
                  displayName: actionMeta.option?.displayName as string,
                },
              });
            }
          }),
        );
        await updatePolicyHandler(
          addPolicyApprover({
            input: {
              policy_id: policyId,
              user_id: actionMeta.option?.id as string,
            },
          }),
        );
        break;
      }
      case 'remove-value':
      case 'pop-value': {
        dispatch(
          policyApi.util.updateQueryData('GetPolicy', { id: policyId }, (draft) => {
            if (draft.policies_by_pk) {
              const remainingTags = draft.policies_by_pk.policy_approvers.filter(
                ({ user }) => user.id !== actionMeta.removedValue.id,
              );
              draft.policies_by_pk.policy_approvers = remainingTags;
            }
          }),
        );
        await updatePolicyHandler(
          removePolicyApprover({
            approverId: actionMeta.removedValue.value as string,
          }),
        );
        break;
      }
    }
  };

  return (
    <Flex direction="column" gap={[6, 3]} flexGrow="1">
      <DrawerProperty isReadOnly={!canEditPolicy}>
        <DrawerProperty.Label icon={BarsIcon}>
          {t('policies.property.description')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAutoResizeTextarea
            defaultValue={policy?.description}
            placeholder={t('policies.placeholder.description')}
            color={textColor}
            onSubmit={(value) => {
              updatePolicyHandler(
                updatePolicy({
                  id: policyId,
                  updatePayload: { description: value },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditPolicy}>
        <DrawerProperty.Label icon={UserIcon}>{t('policies.owner')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAvatar
            options={getAssigneeOptions(currentOrgNonDisabledUsers)}
            value={getAssigneeValue()}
            placeholder={t('policies.placeholder.owner')}
            onChange={(option) => {
              updatePolicyHandler(
                updatePolicy({
                  id: policyId,
                  updatePayload: {
                    assignee_id: option ? option.id : null,
                  },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canEditPolicy}>
        <DrawerProperty.Label icon={UsersIcon}>
          {t('policies.property.approvers')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableMultiSelectAvatar
            options={getAssigneeOptions(currentOrgNonDisabledUsers)}
            value={approvers}
            placeholder={t('policies.placeholder.approvers')}
            onChange={handleApproverChange}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      {fieldConfigsQuery.data?.field_configs.map((config) => (
        <CustomFieldInput
          key={config.id}
          entityId={policyId}
          config={config}
          values={policy?.field_values}
          onChange={fieldConfigsQuery.refetch}
          isReadOnly={!canEditPolicy}
        />
      ))}

      {policy?.type === Policy_Types_Enum.Upload && (
        <FormControl mt={6}>
          <FormLabel color={currentVersionLabelColor}>
            {t('policies.property.currentVersion')}:
          </FormLabel>
          <PolicyUploadVersion />
        </FormControl>
      )}

      {policy?.type === Policy_Types_Enum.Custom && <PolicyEditor />}
    </Flex>
  );
};

const PolicyEditor = () => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const preferredVersionData = usePreferredPolicyVersion();
  const canEditPolicyVersion =
    !!userRole.permissionMap?.write_policies &&
    preferredVersionData?.status === Policy_Version_Statuses_Enum.Draft;

  /**
   * we are loading the initial policy text in the state and detach ourselves from the websocket updates, so that the editor keeps working
   * else undo, redo functionality will not work.
   * We use keyCounter to re-mount the component on status changes so that we can make sure the switch between read-only editor and read-write editor works.
   *
   * */
  const [editorState, setEditorState] = useState({
    text: preferredVersionData?.policy_text,
    keyCounter: 1,
  });
  const editorStateRef = useRef(preferredVersionData?.policy_text);
  const [updateVersion] = useUpdatePolicyVersionContentMutation();

  const { run: savePolicyText, flush } = useThrottleFn(
    async (policyText: string) => {
      if (
        !preferredVersionData?.id ||
        !(preferredVersionData?.status === Policy_Version_Statuses_Enum.Draft)
      ) {
        return;
      }

      editorStateRef.current = policyText;
      await updateVersion({
        policyVersionId: preferredVersionData?.id,
        policyText,
      }).unwrap();
    },
    { wait: 500 },
  );

  useEffect(() => {
    setEditorState((state) => ({
      text: editorStateRef.current,
      keyCounter: state.keyCounter + 1,
    }));
  }, [preferredVersionData?.status]);

  useEffect(() => {
    return () => {
      flush();
    };
  }, [flush]);

  return useMemo(() => {
    return (
      <WysiwygEditor
        boxProps={{ flexGrow: '1' }}
        toolbarProps={{ top: '-24px' }}
        key={editorState.keyCounter}
        config={{ editable: canEditPolicyVersion, editorState: safeEditorState(editorState.text) }}
        onStateChange={(state) => savePolicyText(JSON.stringify(state))}
      />
    );
  }, [canEditPolicyVersion, editorState, savePolicyText]);
};

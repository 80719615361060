import {
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  GetQuestionnairesQuery,
  useGetQuestionnairesQuery,
} from '@main/graphql/queries/GetQuestionnaires.generated';
import { getTodayDateString, toError } from '@main/shared/utils';
import { errorToast, Select, successToast } from '@main/ui';
import dayjs from 'dayjs';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import z from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { dateToMidnightUTC } from '../../utils/date';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { useCreateVenodorQuestionnaireMutation } from './create-questionnaire-modal.generated';
import { useGetVendorQuery } from './get-vendor.generated';

const vendorQuestionnaireSchema = z.object({
  email: z.string().email(),
  dueDate: z.string().min(1).transform(dateToMidnightUTC),
  questionnaireId: z.string().uuid(),
  subject: z.string(),
  message: z.string(),
});

type VendorQuestionnaireType = z.infer<typeof vendorQuestionnaireSchema>;
type Questionnaire = GetQuestionnairesQuery['questionnaires'][number];

interface CreateQuestionnaireModalProps {
  vendorId: string;
  isOpen: boolean;
  onClose: () => void;
  onCreated?: () => void;
}

export const CreateQuestionnaireModal = (props: CreateQuestionnaireModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('vendors.questionnaires.modal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Form {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

function Form(props: CreateQuestionnaireModalProps) {
  const { t } = useTranslation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const { data: vendorData, isLoading: isVendorLoading } = useGetVendorQuery({
    vendor_id: props.vendorId,
  });
  const { data: questionnaires, isLoading: isQuestionnaireLoading } = useGetQuestionnairesQuery({
    organization_id: organization.id,
  });
  const [createQuestionnaire, { isLoading: isCreating }] = useCreateVenodorQuestionnaireMutation();
  const isLoading = isVendorLoading || isQuestionnaireLoading;
  const vendorEmail = vendorData?.vendors_by_pk?.contact_email;
  const questionnaireOptions = getQuestionnaireOptions(questionnaires?.questionnaires ?? []);
  const defaultSubject = t('vendors.questionnaires.sendForm.subject.defaultValue', {
    orgName: organization.name,
  });

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    control,
  } = useForm<VendorQuestionnaireType>({
    shouldFocusError: false,
    resolver: zodResolver(vendorQuestionnaireSchema),
    defaultValues: {
      email: vendorEmail,
      dueDate: dayjs().add(7, 'days').format('YYYY-MM-DD'),
      subject: defaultSubject,
    },
  });

  const onSubmit: SubmitHandler<VendorQuestionnaireType> = async (data) => {
    if (!isValid) {
      return;
    }

    try {
      await createQuestionnaire({
        input: {
          questionnaireId: data.questionnaireId,
          vendors: [{ id: props.vendorId, email: data.email }],
          dueDate: data.dueDate,
          subject: data.subject || defaultSubject,
          customMessage: data.message || null,
        },
      }).unwrap();

      props.onClose();
      successToast(t('successMessages.vendorQuestionnaireSucceeded'));
      props.onCreated?.();
    } catch (error) {
      errorToast(t('errorMessages.vendorQuestionnaireFailed'));
      datadogLogs.logger.error('Failed to send vendor questionnaire', {}, toError(error));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={6} pb={4}>
        <FormControl isInvalid={!!errors.email}>
          <FormLabel>{t('vendors.questionnaires.sendForm.email.label')}</FormLabel>
          <Input
            {...register('email')}
            placeholder={t('vendors.questionnaires.sendForm.email.placeholder')}
            type="email"
          ></Input>
        </FormControl>
        <FormControl isInvalid={!!errors.dueDate}>
          <FormLabel>{t('vendors.questionnaires.sendForm.dueDate.label')}</FormLabel>
          <Input
            {...register('dueDate')}
            placeholder={t('vendors.questionnaires.sendForm.dueDate.placeholder')}
            type="date"
            min={getTodayDateString()}
          ></Input>
        </FormControl>
        <Controller
          name="questionnaireId"
          control={control}
          render={({ field, fieldState }) => (
            <FormControl isInvalid={!!fieldState.error}>
              <FormLabel>{t('vendors.questionnaires.sendForm.questionnaire.label')}</FormLabel>

              <Select
                {...field}
                options={questionnaireOptions}
                value={getCurrentOption(questionnaireOptions, field.value)}
                onChange={(option) => option && field.onChange(option.value)}
                placeholder={t('vendors.questionnaires.sendForm.questionnaire.placeholder')}
                useBasicStyles
                id="questionnaire"
              />
            </FormControl>
          )}
        />
        <FormControl isInvalid={!!errors.subject}>
          <FormLabel>{t('vendors.questionnaires.sendForm.subject.label')}:</FormLabel>
          <Input
            {...register('subject')}
            placeholder={t('vendors.questionnaires.sendForm.subject.placeholder')}
          />
        </FormControl>
        <FormControl isInvalid={!!errors.message}>
          <FormLabel>{t('vendors.questionnaires.sendForm.message.label')}:</FormLabel>
          <Textarea
            {...register('message')}
            placeholder={t('vendors.questionnaires.sendForm.message.placeholder')}
          />
        </FormControl>
        <ButtonGroup justifyContent="end">
          <Button colorScheme="gray" onClick={props.onClose}>
            {t('buttons.cancel')}
          </Button>
          <Button isLoading={isLoading || isCreating} type="submit" colorScheme="blue">
            {t('buttons.send')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
}

function getQuestionnaireOptions(questionnaires: Questionnaire[]) {
  return (
    questionnaires.map((questionnaire) => ({
      label: questionnaire.name,
      value: questionnaire.id,
      colorScheme: 'purple',
    })) ?? []
  );
}

function getCurrentOption(
  options: ReturnType<typeof getQuestionnaireOptions>,
  value: string | undefined,
) {
  return options.find((option) => option.value === value);
}

import { QuestionnaireComponentKind } from '@main/dynamic-form';
import { FormConfig } from '@main/graphql/client-scalars';
import { FormFieldCondition, QuestionnaireFormFieldConfig } from '@main/questionnaires-form';

export function isOptionsChanged(
  newField: QuestionnaireFormFieldConfig,
  oldField: QuestionnaireFormFieldConfig,
) {
  if (
    newField.kind === QuestionnaireComponentKind.Options &&
    oldField.kind !== QuestionnaireComponentKind.Options
  ) {
    return false;
  }

  if (
    newField.kind !== QuestionnaireComponentKind.Options &&
    oldField.kind === QuestionnaireComponentKind.Options
  ) {
    return true;
  }

  if (
    newField.kind !== QuestionnaireComponentKind.Options ||
    oldField.kind !== QuestionnaireComponentKind.Options
  ) {
    return false;
  }

  if (!newField.options || !oldField.options) {
    return false;
  }

  if (newField.options.length < oldField.options.length) {
    return true;
  }

  if (newField.isMultiOptions === false && oldField.isMultiOptions === true) {
    return true;
  }

  return oldField.options.some(
    (oldOption) =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      !newField.options!.some((newOption) => oldOption.value === newOption.value),
  );
}

export function getConditionalFields(
  field: QuestionnaireFormFieldConfig,
  config: FormConfig,
): QuestionnaireFormFieldConfig[] {
  return config.filter((fieldConfig) => {
    if (!fieldConfig.condition) {
      return false;
    }

    return hasCondition(fieldConfig.condition);
  });

  function hasCondition(condition: FormFieldCondition): boolean {
    if (condition.kind === 'parent' && condition.fieldName === field.name) {
      return true;
    }

    if (condition.kind === 'group') {
      return condition.conditions.some(hasCondition);
    }

    return false;
  }
}

export function resetConditionsOf(
  field: QuestionnaireFormFieldConfig,
  config: FormConfig,
): FormConfig {
  return config.map((filedConfig) => {
    const newFieldConfig = { ...filedConfig };
    newFieldConfig.condition = filterCondition(filedConfig.condition);

    if (!newFieldConfig.condition) {
      delete newFieldConfig.condition;
    }

    return newFieldConfig;
  });

  function filterCondition(condition?: FormFieldCondition): FormFieldCondition | undefined {
    if (!condition) {
      return;
    }

    if (condition.kind === 'parent' && condition.fieldName === field.name) {
      return;
    }

    if (condition.kind === 'group') {
      const filteredConditions = condition.conditions.filter(filterCondition);

      return filteredConditions.length > 0
        ? { ...condition, conditions: filteredConditions }
        : undefined;
    }

    return condition;
  }
}

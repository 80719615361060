import {
  Button,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon } from '@heroicons/react/24/outline';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../../user/slice';
import { useCreateTask } from '../use-create-task';
import { useUpdateAllTaskStatusesByOwnerMutation } from './manage-task-sidebar.generated';

export const SidebarFooterActions = ({
  onCreate,
  onMarkAllAsComplete,
  onMarkAllAsPending,
}: {
  onCreate: () => void;
  onMarkAllAsComplete?: () => void;
  onMarkAllAsPending?: () => void;
}) => {
  const { t } = useTranslation();

  const actionButtonColor = useColorModeValue('gray.800', 'gray.300');
  const bgColor = useColorModeValue('white', 'gray.700');
  const { onTaskCreate, isCreatingTask } = useCreateTask();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canWriteTasks = userRole.permissionMap?.write_tasks;

  if (!canWriteTasks) {
    return null;
  }

  return (
    <Flex
      borderTopWidth="1px"
      justifyContent="space-between"
      p={3}
      width="md"
      position="fixed"
      bottom={0}
      right={0}
      bgColor={bgColor}
    >
      <ButtonGroup spacing={3}>
        <Button
          variant="outline"
          onClick={onMarkAllAsComplete}
          textColor={actionButtonColor}
          fontSize="sm"
          fontWeight="medium"
        >
          {t('tasks.sidebar.buttons.markAllComplete')}
        </Button>
        <Button
          variant="outline"
          onClick={onMarkAllAsPending}
          textColor={actionButtonColor}
          fontSize="sm"
          fontWeight="medium"
        >
          {t('tasks.sidebar.buttons.markAllPending')}
        </Button>
      </ButtonGroup>

      <Tooltip label={t('tasks.addTask')} placement="left">
        <IconButton
          variant="outline"
          aria-label="Create new task"
          icon={<Icon boxSize={5} as={PlusIcon} />}
          isLoading={isCreatingTask}
          onClick={async () => {
            onCreate();
            await onTaskCreate();
          }}
        />
      </Tooltip>
    </Flex>
  );
};

export function useTaskBulkUpdateErrorHandler() {
  const { t } = useTranslation();

  return useStableCallback(
    async (
      resultPromise: ReturnType<ReturnType<typeof useUpdateAllTaskStatusesByOwnerMutation>[0]>,
    ) => {
      try {
        await resultPromise.unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.task') }));
        datadogLogs.logger.error('Task status bulk udpate failed', {}, toError(error));
      }
    },
  );
}

import { datadogLogs } from '@datadog/browser-logs';
import { api as getEvidenceApi } from '@main/graphql/queries/GetEvidenceById.generated';
import { toError } from '@main/shared/utils';
import { EditableTag, errorToast } from '@main/ui';
import { ActionMeta, MultiValue } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { useSearchEvidenceTags } from './evidence-hooks';
import {
  useAddEvidenceTagMutation,
  useCreateEvidenceTagMutation,
  useRemoveEvidenceTagMutation,
} from './EvidenceTags.generated';
import { getEvidenceTags, Option } from './slice';

export const EvidenceTags = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const tags = useAppSelector((state) => getEvidenceTags(state, evidenceId));
  const searchTags = useSearchEvidenceTags();
  const [createTag] = useCreateEvidenceTagMutation();
  const [addTag] = useAddEvidenceTagMutation();
  const [removeTag] = useRemoveEvidenceTagMutation();

  const searchCustomTagsHandler = async (inputValue: string) => {
    const tags = await searchTags(inputValue);
    return tags.map((tag) => ({
      value: tag.id,
      label: tag.name,
      colorScheme: 'purple',
      id: '',
    }));
  };

  const handler = async (
    resultPromise: ReturnType<
      ReturnType<
        | typeof useCreateEvidenceTagMutation
        | typeof useAddEvidenceTagMutation
        | typeof useRemoveEvidenceTagMutation
      >[0]
    >,
  ) => {
    try {
      await resultPromise.unwrap();
    } catch (error) {
      errorToast(t('errorMessages.tagFailed', { entity: t('entities.tag') }));
      datadogLogs.logger.error('Unable to perform operation on tags.', {}, toError(error));
      return;
    }
  };

  const handleChange = async (items: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    switch (actionMeta.action) {
      case 'create-option': {
        dispatch(
          getEvidenceApi.util.updateQueryData(
            'GetEvidenceById',
            { evidence_id: evidenceId },
            (draft) => {
              if (draft.evidences_by_pk) {
                draft.evidences_by_pk.tags.push({
                  id: 'new',
                  tag: { id: 'new', name: actionMeta.option.label },
                });
              }
            },
          ),
        );
        await handler(
          createTag({
            object: {
              evidence_id: evidenceId,
              tag: {
                data: {
                  name: actionMeta.option.label,
                  organization_id: organizationId,
                },
              },
            },
          }),
        );
        break;
      }
      case 'select-option': {
        dispatch(
          getEvidenceApi.util.updateQueryData(
            'GetEvidenceById',
            { evidence_id: evidenceId },
            (draft) => {
              if (draft.evidences_by_pk) {
                draft.evidences_by_pk.tags.push({
                  id: actionMeta.option?.value as string,
                  tag: {
                    id: actionMeta.option?.value as string,
                    name: actionMeta.option?.label as string,
                  },
                });
              }
            },
          ),
        );
        await handler(addTag({ tagId: actionMeta.option?.value as string, evidenceId }));
        break;
      }
      case 'remove-value':
      case 'pop-value': {
        dispatch(
          getEvidenceApi.util.updateQueryData(
            'GetEvidenceById',
            { evidence_id: evidenceId },
            (draft) => {
              if (draft.evidences_by_pk) {
                const remainingTags = draft.evidences_by_pk.tags.filter(
                  ({ tag }) => tag.id !== actionMeta.removedValue.value,
                );
                draft.evidences_by_pk.tags = remainingTags;
              }
            },
          ),
        );
        await handler(removeTag({ id: actionMeta.removedValue.id || '' }));
        break;
      }
    }
    await dispatch(
      getEvidenceApi.endpoints.GetEvidenceById.initiate(
        { evidence_id: evidenceId },
        { forceRefetch: true, subscribe: false },
      ),
    ).unwrap();
  };

  return (
    <EditableTag
      isMulti={true}
      debounceMs={500}
      value={tags}
      loadOptions={searchCustomTagsHandler}
      onChange={handleChange}
      getNewOptionData={(label) => ({ label, value: 'new', id: 'new', colorScheme: 'purple' })}
      variant="inline"
      placeholder={t('evidences.placeholder.tag')}
    />
  );
};

import { datadogLogs } from '@datadog/browser-logs';
import { underEntityIdKey } from '@main/custom-fields';
import {
  useDeleteFieldValueMutation,
  useInsertFieldValueMutation,
  useUpdateUrlFieldValueMutation,
} from '@main/graphql/features/CustomFields.generated';
import { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import { UrlFieldValueFragment } from '@main/graphql/fragments/FieldValueFragments.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

export function useSetUrlFieldValue() {
  const { t } = useTranslation();
  const [insertFieldValue] = useInsertFieldValueMutation();
  const [updateUrlFieldValue] = useUpdateUrlFieldValueMutation();
  const [deleteFieldValue] = useDeleteFieldValueMutation();

  return async ({
    fieldConfig,
    entityId,
    fieldValue,
    nextValue,
  }: {
    fieldConfig: FieldConfigFragment;
    entityId: string;
    fieldValue: UrlFieldValueFragment | undefined;
    nextValue: string | undefined;
  }) => {
    try {
      if (!fieldValue?.value) {
        if (!nextValue) {
          return;
        }

        await insertFieldValue({
          fieldValue: {
            field_config_id: fieldConfig.id,
            entity_name: fieldConfig.entity_name,
            field_type: fieldConfig.field_type,
            ...underEntityIdKey(fieldConfig.entity_name, entityId),
            url_field_value: { data: { value: nextValue } },
          },
        }).unwrap();
      } else {
        if (!nextValue) {
          await deleteFieldValue({
            id: fieldValue.field_value_id,
          }).unwrap();
        } else {
          await updateUrlFieldValue({
            id: fieldValue.field_value_id,
            value: nextValue,
          }).unwrap();
        }
      }
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.customField') }));
      datadogLogs.logger.error('Url field value update failed', {}, toError(error));
    }
  };
}

import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ModalBody,
  ModalFooter,
  Stack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { DeepPartial } from '@main/shared/types';
import { getTodayDateString } from '@main/shared/utils';
import {
  AutoResizeTextarea,
  EditableAvatar,
  FileUpload,
  UploadedFile,
  useDownloadStorageFile,
  useLazyMultipleFilesUpload,
} from '@main/ui';
import { ReactNode } from 'react';
import { Controller, FormState, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { dateToMidnightUTC, toDatePart } from '../../utils/date';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserId,
} from '../user/slice';

const formSchema = z.object({
  name: z.string().trim().min(1),
  notes: z.string().trim(),
  reviewDate: z.string().min(1).transform(dateToMidnightUTC),
  reviewedBy: z.object({
    id: z.string(),
    displayName: z.string(),
  }),
});

type Upload = UploadedFile & { reviewFileId: string };
export type ReviewSchema = z.infer<typeof formSchema>;
export type FileUpload = ReturnType<typeof useLazyMultipleFilesUpload<Upload>>;

export type DefaultValues = DeepPartial<
  ReviewSchema & {
    files?: Upload[];
  }
>;

export const ReviewForm = ({
  renderActionButton,
  onModalClose,
  defaultValues,
  onSubmit,
  canEdit = true,
}: {
  renderActionButton?: (formState: FormState<ReviewSchema>) => ReactNode;
  onModalClose: () => void;
  onSubmit: (props: { data: ReviewSchema; fileUpload: FileUpload }) => Promise<void>;
  defaultValues?: DefaultValues;
  canEdit?: boolean;
}) => {
  const { t } = useTranslation();
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentUserId = useAppSelector(getCurrentUserId);
  const user = currentOrgUsers[currentUserId];

  const rhf = useForm<ReviewSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reviewDate: toDatePart(defaultValues?.reviewDate),
      reviewedBy: defaultValues?.reviewedBy
        ? {
            id: defaultValues.reviewedBy.id ?? COMPLYANCE_USER.id, // Show "Complyance" if null
            displayName: defaultValues.reviewedBy.displayName ?? COMPLYANCE_USER.displayName,
          }
        : {
            id: user?.id, // Default to current user for new entries
            displayName: user?.displayName,
          },
      name: defaultValues?.name ?? t('entities.review'),
      notes: defaultValues?.notes ?? '',
    },
  });

  const fileUpload = useLazyMultipleFilesUpload({
    files: defaultValues?.files,
    onFileDownload: useDownloadStorageFile(),
  });

  const handleSubmit = rhf.handleSubmit(async (data) => {
    await onSubmit({ data, fileUpload });
    onModalClose();
  });

  return (
    <form onSubmit={handleSubmit}>
      <ModalBody as={Stack} spacing={6}>
        <FormControl isInvalid={!!rhf.formState.errors.reviewDate} isReadOnly={!canEdit}>
          <FormLabel>{t('reviews.form.label.reviewDate')}:</FormLabel>
          <Input type="date" max={getTodayDateString()} {...rhf.register('reviewDate')} />
          <FormErrorMessage>{rhf.formState.errors.reviewDate?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!rhf.formState.errors.reviewedBy} isReadOnly={!canEdit}>
          <FormLabel>{t('reviews.form.label.reviewedBy')}:</FormLabel>
          <Controller
            name="reviewedBy"
            control={rhf.control}
            render={({ field }) => (
              <EditableAvatar
                isClearable={false}
                options={getAssigneeOptions(currentOrgNonDisabledUsers)}
                value={{
                  id: field.value?.id ?? COMPLYANCE_USER.id,
                  displayName: field.value?.displayName ?? COMPLYANCE_USER.displayName,
                }}
                onChange={(option) => {
                  field.onChange(option);
                }}
              />
            )}
          />
        </FormControl>

        <FormControl isInvalid={!!rhf.formState.errors.name} isReadOnly={!canEdit}>
          <FormLabel>{t('reviews.form.label.reviewName')}:</FormLabel>
          <Input
            type="text"
            placeholder={t('reviews.form.placeholder.reviewName')}
            {...rhf.register('name')}
          />
          <FormErrorMessage>{t('reviews.form.error.reviewName')}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!rhf.formState.errors.notes} isReadOnly={!canEdit}>
          <FormLabel>{t('reviews.form.label.notes')}:</FormLabel>
          <AutoResizeTextarea
            minRows={3}
            placeholder={t('reviews.form.placeholder.notes')}
            {...rhf.register('notes')}
          />
          <FormErrorMessage>{rhf.formState.errors.notes?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isReadOnly={!canEdit}>
          <FormLabel>{t('reviews.form.label.upload')}:</FormLabel>
          <FileUpload {...fileUpload.props}>
            <FileUpload.Dropzone
              topLabelText={t('reviews.uploadTitle')}
              bottomLabelText={t('reviews.uploadSubtitle')}
            />
          </FileUpload>
        </FormControl>
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        {canEdit && (
          <ButtonGroup spacing={3}>
            <Button onClick={onModalClose}>{t('buttons.cancel')}</Button>
            {renderActionButton ? (
              renderActionButton(rhf.formState)
            ) : (
              <Button type="submit" colorScheme="blue" isLoading={rhf.formState.isSubmitting}>
                {t('buttons.submit')}
              </Button>
            )}
          </ButtonGroup>
        )}
      </ModalFooter>
    </form>
  );
};

import { useStableCallback } from '@main/shared/utils';
import { useEffect, useState } from 'react';

/**
 * Hook that calls the provided callback when the user scrolls on Y-axis to the bottom of the element.
 */
export function useInfiniteScrollY(callback: () => void) {
  const [scrollElem, setScrollElem] = useState<HTMLElement | null>(null);
  const onLoadMore = useStableCallback(callback);

  useEffect(() => {
    if (!scrollElem) {
      return;
    }

    const onScroll = () => {
      if (scrollElem.scrollTop + scrollElem.offsetHeight >= scrollElem.scrollHeight) {
        onLoadMore();
      }
    };

    scrollElem.addEventListener('scroll', onScroll, { passive: true });
    return () => scrollElem.removeEventListener('scroll', onScroll);
  }, [onLoadMore, scrollElem]);

  return { ref: setScrollElem };
}

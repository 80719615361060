import { useUpdateVendorMutation } from '@main/graphql/mutations/UpdateVendor.generated';
import { Vendors_Constraint, Vendors_Update_Column } from '@main/graphql/types.generated';
import { EditableTag } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { getVendorRiskLevels } from './slice';
import { useSelectHandler } from './use-udpate-vendor-handler';
import {
  useCreateVendorRiskLevelMutation,
  useLazyGetVendorRiskLevelsQuery,
} from './VendorRiskLevels.generated';

export const VendorRiskLevels = ({ vendorId }: { vendorId: string }) => {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const risk_levels = useAppSelector((state) => getVendorRiskLevels(state, vendorId));
  const [searchRiskLevels] = useLazyGetVendorRiskLevelsQuery();
  const getActions = useGetVendorRiskLevelActions();
  const getHandler = useSelectHandler();
  const searchCustomRiskLevelsHandler = async (inputValue: string) => {
    const { organization_vendor_risk_levels } = await searchRiskLevels({
      organizationId,
      ...(inputValue?.trim() ? { nameFilter: { _ilike: `%${inputValue}%` } } : {}),
    }).unwrap();

    return organization_vendor_risk_levels.map((riskLevel) => ({
      value: riskLevel.id,
      label: riskLevel.name,
      colorScheme: 'purple',
    }));
  };

  return (
    <EditableTag
      debounceMs={500}
      value={risk_levels}
      loadOptions={searchCustomRiskLevelsHandler}
      onChange={getHandler({
        actions: getActions({ vendorId }),
        vendorId,
      })}
      getNewOptionData={(label) => ({ label, value: 'new', colorScheme: 'purple' })}
      variant="inline"
      placeholder={t('vendors.props.placeholder.riskLevel')}
    />
  );
};

export const useGetVendorRiskLevelActions = () => {
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const [createVendorRiskLevel] = useCreateVendorRiskLevelMutation();
  const [updateVendor] = useUpdateVendorMutation();

  return useCallback(
    ({ vendorId }: { vendorId: string }) => ({
      create: (name: string) => {
        return createVendorRiskLevel({
          input: {
            name,
            organization_id: organizationId,
            vendors: {
              data: [{ id: vendorId, organization_id: organizationId }],
              on_conflict: {
                constraint: Vendors_Constraint.VendorsPkey,
                update_columns: [Vendors_Update_Column.RiskLevelId],
              },
            },
          },
        });
      },
      select: (id: string) => {
        return updateVendor({
          id: vendorId,
          vendor_input: {
            risk_level_id: id,
          },
        });
      },
      remove: () => {
        return updateVendor({
          id: vendorId,
          vendor_input: {
            risk_level_id: null,
          },
        });
      },
    }),
    [createVendorRiskLevel, organizationId, updateVendor],
  );
};

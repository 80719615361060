import { assertNotAborted } from '../promise-utils';
import { StreamPortalSink } from './sink';
import { StreamPortalSource } from './source';

export interface MessagableTarget {
  postMessage(message: unknown, transfer?: Transferable[]): void;
}

export interface MessageStreamPortalExtra {
  event: Event;
}

interface MessageStreamPortalMessage<TExtra> {
  type: 'message-stream-portal';
  stream: ReadableStream;
  extra: TExtra;
}

export class MessageStreamPortalSource<TExtra = void> implements StreamPortalSource<TExtra> {
  constructor(protected readonly target: MessagableTarget) {}

  async send(stream: ReadableStream, extra: TExtra): Promise<void> {
    this.target.postMessage(
      { type: 'message-stream-portal', stream, extra } as MessageStreamPortalMessage<TExtra>,
      [stream],
    );
  }
}

export class MessageStreamPortalSink<TExtra = void> implements StreamPortalSink<TExtra> {
  protected readonly disposeController = new AbortController();

  constructor(
    protected readonly target: EventTarget,
    protected readonly eventBus = new EventTarget(),
  ) {
    this.target.addEventListener('message', this.handleMessage.bind(this));
  }

  onStream(
    cb: (stream: ReadableStream, extra: TExtra & MessageStreamPortalExtra) => void,
  ): () => void {
    assertNotAborted(this.disposeController.signal);

    const listener = (event: Event) =>
      cb((event as WorkerStreamEvent<TExtra>).stream, {
        ...(event as WorkerStreamEvent<TExtra>).extra,
        event: (event as WorkerStreamEvent<TExtra>).event,
      });
    this.eventBus.addEventListener(WorkerStreamEvent.eventName, listener, {
      signal: this.disposeController.signal,
    });
    return () => this.eventBus.removeEventListener(WorkerStreamEvent.eventName, listener);
  }

  dispose() {
    this.disposeController.abort();
  }

  protected handleMessage(event: Event) {
    if ('data' in event === false) {
      return;
    }

    const data = event.data as MessageStreamPortalMessage<TExtra>;

    if (!data || data.type !== 'message-stream-portal') {
      return;
    }

    this.eventBus.dispatchEvent(new WorkerStreamEvent(data.stream, data.extra, event));
  }
}

export class WorkerStreamEvent<TExtra = void> extends Event {
  static readonly eventName = 'stream';
  constructor(
    readonly stream: ReadableStream,
    readonly extra: TExtra,
    readonly event: Event,
  ) {
    super(WorkerStreamEvent.eventName);
  }
}

export type EvidenceIntegrationConfig =
  | AwsRdsIntegrationConfig
  | CrowdstrikeAntiMalwareIntegrationConfig
  | GcpCloudSqlIntegrationConfig
  | JiraIssuesExportIntegrationConfig
  | QualysVulnerabilityIntegrationConfig
  | SharepointFileSyncIntegrationConfig
  | VeracodeVulnerabilityIntegrationConfig
  | OneloginSuperUsersIntegrationConfig
  | OneloginMfaIntegrationConfig
  | AwsObjectStorageIntegrationConfig
  | AwsGuarddutyIntegrationConfig
  | AwsInspectorIntegrationConfig
  | AwsIamIntegrationConfig
  | JumpcloudIntegrationConfig;

export enum EvidenceIntegrationType {
  AntiMalware = 'anti-malware',
  Database = 'database',
  FileSync = 'file-sync',
  IssuesExport = 'issues-export',
  OldestChange = 'oldest-change',
  PageExport = 'page-export',
  Vulnerability = 'vulnerability',
  SuperUsers = 'super-users',
  Mfa = 'mfa',
  ObjectStorage = 'object-storage',
  Guardduty = 'guardduty',
  Inspector = 'inspector',
  IdentityAccessManagement = 'identity-access-management',
  MobileDeviceManagement = 'mobile-device-management',
}

export type AwsRdsIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  region: string;
  dbInstanceIds?: string[];
  dbClusterIds?: string[];
  checks: DBIntegrationCheck[];
};

export enum DBIntegrationCheck {
  Encryption = 'encryption',
  MultiAZ = 'multiAZ',
}

export type AzureDatabaseIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  subscriptionId: string;
  checks: DBIntegrationCheck[];
  dbSelectors: AzureDatabaseSelector[];
};

export type AzureDatabaseSelector = {
  type: AzureDatabaseType;
  resourceGroup: string;
  name: string;
};

export enum AzureDatabaseType {
  SQLServer = 'azure.sql-server',
  SQLManagedInstance = 'azure.sql-managed-instance',
  MySQLFlexible = 'azure.mysql-flexible',
  PostgreSQLFlexible = 'azure.postgresql-flexible',
}

export type ConfluencePageExportIntegrationConfig = {
  type: EvidenceIntegrationType.PageExport;
  pageId: number;
};

export type CrowdstrikeAntiMalwareIntegrationConfig = {
  type: EvidenceIntegrationType.AntiMalware;
};

export type JiraIssuesExportIntegrationConfig = {
  type: EvidenceIntegrationType.IssuesExport;
  jqlQuery: string;
};

export type GcpCloudSqlIntegrationConfig = {
  type: EvidenceIntegrationType.Database;
  dbInstanceNames: string[];
  checks: DBIntegrationCheck[];
};

export type GcpFileSyncIntegrationConfig = {
  type: EvidenceIntegrationType.FileSync;
  fileId: string;
};

export type GithubOldestChangeIntegrationConfig = {
  type: EvidenceIntegrationType.OldestChange;
  repoName: string;
};

export type QualysVulnerabilityIntegrationConfig = {
  type: EvidenceIntegrationType.Vulnerability;
  webAppIds: number[];
};

export type SharepointFileSyncIntegrationConfig = {
  type: EvidenceIntegrationType.FileSync;
  driveId: string;
  filePath: string;
};

export type VeracodeVulnerabilityIntegrationConfig = {
  type: EvidenceIntegrationType.Vulnerability;
  businessUnit?: string;
  policy?: string;
  policyCompliance?: string;
  policyGuid?: string;
  scanStatus?: string[];
  scanType?: string;
  tag?: string;
  team?: string;
  buildId?: string;
  context?: string;
};

export type OneloginSuperUsersIntegrationConfig = {
  type: EvidenceIntegrationType.SuperUsers;
};

export type OneloginMfaIntegrationConfig = {
  type: EvidenceIntegrationType.Mfa;
};

export type AwsObjectStorageIntegrationConfig = {
  type: EvidenceIntegrationType.ObjectStorage;
  region: string;
  buckets: string[];
  check: AwsObjectStorageCheck;
};

export enum AwsObjectStorageCheck {
  BlockPublicAccess = 'block-public-access',
  RequireSSL = 'require-ssl',
  Replication = 'replication',
  Encryption = 'encryption',
  Versioning = 'versioning',
  MFADelete = 'mfa-delete',
  ObjectLock = 'object-lock',
  Logging = 'logging',
}

export type AwsGuarddutyIntegrationConfig = {
  type: EvidenceIntegrationType.Guardduty;
  region: string;
  checks: AwsGuarddutyCheck[];
};

export enum AwsGuarddutyCheckType {
  EnabledFeatures = 'enabled-features',
  SnsSubscription = 'sns-subscription',
  HighSeverityFindings = 'high-severity-findings',
}

export type AwsGuarddutyCheck = AGCGeneric | AGCEnabledFeatures;

export type AGCGeneric = {
  type: AwsGuarddutyCheckType.HighSeverityFindings | AwsGuarddutyCheckType.SnsSubscription;
};

export type AGCEnabledFeatures = {
  type: AwsGuarddutyCheckType.EnabledFeatures;
  features: Array<GuardDutyFeature>;
};

export enum GuardDutyFeature {
  S3DataEvents = 'S3_DATA_EVENTS',
  EksAuditLogs = 'EKS_AUDIT_LOGS',
  EbsMalwareProtection = 'EBS_MALWARE_PROTECTION',
  RdsLoginEvents = 'RDS_LOGIN_EVENTS',
  LambdaNetworkLogs = 'LAMBDA_NETWORK_LOGS',
  CloudTrail = 'CLOUD_TRAIL',
  DnsLogs = 'DNS_LOGS',
  FlowLogs = 'FLOW_LOGS',
  RmEksAddonManagement = 'RUNTIME_MONITORING.EKS_ADDON_MANAGEMENT',
  RmEcsFargateAgentManagement = 'RUNTIME_MONITORING.ECS_FARGATE_AGENT_MANAGEMENT',
  RmEc2AgentManagement = 'RUNTIME_MONITORING.EC2_AGENT_MANAGEMENT',
}

export type AwsInspectorIntegrationConfig = {
  type: EvidenceIntegrationType.Inspector;
  region: string;
  checks: AwsInspectorCheck[];
};

export enum AwsInspectorCheck {
  HighSeverityFindings = 'high-severity-findings',
  ServiceEC2 = 'service-ec2',
  ServiceECR = 'service-ecr',
  ServiceLambda = 'service-lambda',
  ServiceLambdaCode = 'service-lambda-code',
}

export type AwsIamIntegrationConfig = {
  type: EvidenceIntegrationType.IdentityAccessManagement;
  region: string;
  checks: AwsIamCheck[];
};

export type AwsIamCheck =
  | AICGeneric
  | AICAccessKeyRotation
  | AICPasswordRotation
  | AICUnusedCredentials;

export type AICGeneric = {
  type:
    | AwsIamCheckTypes.NoAdminPrivileges
    | AwsIamCheckTypes.NoWildcardActionsForServices
    | AwsIamCheckTypes.NoPoliciesAttached
    | AwsIamCheckTypes.MfaEnabledAllUsers
    | AwsIamCheckTypes.MfaEnabledConsoleAccess
    | AwsIamCheckTypes.NoCloudShellFullAccess
    | AwsIamCheckTypes.ExternalAccessAnalyzer
    | AwsIamCheckTypes.PasswordPolicyConfiguration
    | AwsIamCheckTypes.ExpiredServerCertificate
    | AwsIamCheckTypes.SupportRole;
};

export type AICAccessKeyRotation = {
  type: AwsIamCheckTypes.AccessKeyRotation;
  rotationDays: number;
};

export type AICPasswordRotation = {
  type: AwsIamCheckTypes.PasswordRotation;
  rotationDays: number;
};

export type AICUnusedCredentials = {
  type: AwsIamCheckTypes.UnusedCredentials;
  inactiveDays: number;
};

export enum AwsIamCheckTypes {
  NoAdminPrivileges = 'no-admin-privileges',
  NoWildcardActionsForServices = 'no-wildcard-actions-for-services',
  NoPoliciesAttached = 'no-policies-attached',
  MfaEnabledAllUsers = 'mfa-enabled-all-users',
  MfaEnabledConsoleAccess = 'mfa-enabled-console-access',
  NoCloudShellFullAccess = 'no-cloud-shell-full-access',
  ExternalAccessAnalyzer = 'external-access-analyzer',
  ExpiredServerCertificate = 'expired-server-certificate',
  SupportRole = 'support-role',
  AccessKeyRotation = 'access-key-rotation',
  PasswordRotation = 'password-rotation',
  UnusedCredentials = 'unused-credentials',
  PasswordPolicyConfiguration = 'password-policy-configuration',
}

export type JumpcloudIntegrationConfig = {
  type: EvidenceIntegrationType.MobileDeviceManagement;
  checks: JumpcloudMdmCheck[];
};

export type JumpcloudMdmCheck = {
  type: JumpcloudMdmCheckTypes;
};

export enum JumpcloudMdmCheckTypes {
  FullDiskEncryption = 'full-disk-encryption',
  PatchPolicy = 'patch-policy',
  MfaEnrollment = 'mfa-enrollment',
  ActiveFirewall = 'active-firewall',
}

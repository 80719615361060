import { OrganizationUserDetailsFragment } from '@main/graphql/fragments/OrganizationUserDetailsFragment.generated';
import { api as getUserByIdApi } from '@main/graphql/queries/GetUserById.generated';
import { api as getUserOrganizationsApi } from '@main/graphql/queries/GetUserOrganizations.generated';
import { System_Roles_Enum } from '@main/graphql/types.generated';
import { getMappedNotifications } from '@main/notifications';
import { getPermissionMap } from '@main/permissions';
import { isNonNullable } from '@main/shared/utils';
import { createAction, createReducer, createSelector, isAnyOf } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';

import { AppRootState } from '../../store';
import { getTFunction } from '../../utils/i18n';
import { api as userNotificationsApi } from './UserNotifications.generated';

type UserState = {
  appReadyToUseState: 'pending' | 'in progress' | 'finished';
  userId?: string;
  selectedProgramId?: string | null;
  selectedOrgId?: string | null;
  sso: {
    authStatus: 'UNKNOWN' | 'VERIFYING' | 'LOADED';
    error?:
      | 'MISSING_TEAM_DOMAIN'
      | 'ERROR_LOADING_USER'
      | 'ACCOUNT_CREATED_BUT_DISABLED'
      | (string & {});
  };
};

const initialState: UserState = {
  appReadyToUseState: 'pending',
  selectedProgramId: localStorage.getItem('user:preference:selectedProgramId'),
  selectedOrgId: localStorage.getItem('user:preference:selectedOrgId'),
  sso: {
    authStatus: 'UNKNOWN',
  },
};

// Actions
export const ssoPageLoaded = createAction('[sso page] SSO page loaded');

export const impersonatePageLoaded = createAction<{ token: string }>(
  '[impersonate page] Impersonate page loaded',
);

export const errorOnLoadingSSOUser = createAction<{ error: string } | undefined>(
  '[sso page] errored on loading user from idp',
);

export const samlSSOInitiated = createAction<{
  teamDomain: string;
}>('[with auth] team domain received for SSO');

export const authenticatedUserIdReceived = createAction<{
  userId: string;
}>('[with auth] authenticated userId received');

export const authenticatedUserSetupFinished = createAction(
  '[with auth] setup finished to use the app',
);

export const userSessionInvalidated = createAction('[with auth] user session invalidated');

export const userSignedOut = createAction('[with auth] user signed out');

export const currentUserSelectedProgramSwitched = createAction<{ programId: string }>(
  '[app sidebar] current user selected program switched',
);

export const orgSwitchRequested = createAction<{ orgId: string }>(
  '[app sidebar] org switch requested',
);
export const orgSwitchCompleted = createAction<{ orgId: string }>(
  '[user middleware] org switch completed',
);

export const userPreferenceOrgNotFound = createAction<{ orgId: string }>(
  '[user middleware] current user preference org not found',
);

export const orgIdFoundInQueryParam = createAction<{ orgId: string }>(
  '[user middleware] explicit organizationId found in query param',
);

export const userPreferenceOrgMismatched = createAction<{ orgId: string }>(
  '[middleware] current user preference org mismatched',
);

// Reducers
export const userReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(authenticatedUserIdReceived, (state, action) => {
      state.appReadyToUseState = 'in progress';
      state.userId = action.payload.userId;
    })
    .addCase(authenticatedUserSetupFinished, (state) => {
      state.appReadyToUseState = 'finished';
    })
    .addCase(currentUserSelectedProgramSwitched, (state, action) => {
      localStorage.setItem('user:preference:selectedProgramId', action.payload.programId);
      state.selectedProgramId = action.payload.programId;
    })
    .addCase(samlSSOInitiated, (state, { payload }) => {
      if (!payload.teamDomain.trim()) {
        state.sso.error = 'MISSING_TEAM_DOMAIN';

        return;
      }
      state.sso.error = undefined;
    })
    .addCase(ssoPageLoaded, (state) => {
      if (window.location.search.length > 0) {
        state.sso.authStatus = 'VERIFYING';
      }
      const urlParams = new URLSearchParams(window.location.search);
      const errorFromSSOAuth = urlParams.get('error') as UserState['sso']['error'];

      if (errorFromSSOAuth) {
        state.sso.error = errorFromSSOAuth;
        state.sso.authStatus = 'LOADED';
      }
    })
    .addCase(errorOnLoadingSSOUser, (state, action) => {
      state.sso.error = action?.payload?.error ?? 'ERROR_LOADING_USER';
      state.sso.authStatus = 'LOADED';
    })
    .addMatcher(
      isAnyOf(
        userPreferenceOrgNotFound,
        orgSwitchCompleted,
        userPreferenceOrgMismatched,
        orgIdFoundInQueryParam,
      ),
      (state, action) => {
        localStorage.setItem('user:preference:selectedOrgId', action.payload.orgId);
        state.selectedOrgId = action.payload.orgId;
      },
    );
});

// Selectors
/**
 * It's safe to assume that when the app is loaded we make sure that we always have the ids.
 * So using type casting here will not be a problem at runtime!
 **/
export const getAppReadyToUseState = (state: AppRootState) => state.me.appReadyToUseState;
export const getCurrentUserId = (state: AppRootState) => state.me.userId as string;
export const getCurrentUserSelectedOrgId = (state: AppRootState) =>
  state.me.selectedOrgId as string;
export const getCurrentUserSelectedProgramId = (state: AppRootState) => state.me.selectedProgramId;
export const getUserSSOState = (state: AppRootState) => state.me.sso;

export const getCurrentUserDetails = (state: AppRootState) => {
  if (!state.me.userId) {
    return;
  }
  return getUserByIdApi.endpoints.GetUserById.select({ id: state.me.userId })(state).data;
};

export const getCurrentUserOrgs = (state: AppRootState) => {
  const currentUserId = getCurrentUserId(state);

  const currentUserOrgs =
    getUserOrganizationsApi.endpoints.GetUserOrganizations.select({
      user_id: currentUserId,
    })(state).data?.organizations || [];

  return currentUserOrgs;
};

export const getCurrentUserOrgsMap = createSelector([getCurrentUserOrgs], (orgs) => {
  const orgMapObj = orgs.reduce(
    (map, org) => {
      const parentOrgId = org.parent_organization_id ?? org.id;
      let orgsById = map[parentOrgId];
      if (!orgsById) {
        orgsById = map[parentOrgId] = [];
      }
      orgsById.push(org);
      return map;
    },
    {} as Record<string, Organization[]>,
  );

  for (const orgs of Object.values(orgMapObj)) {
    // put the parent org at the beginning of each org list
    orgs.sort((a, b) => Number(!!a.parent_organization_id) - Number(!!b.parent_organization_id));
  }

  return orgMapObj;
});

type Organization = ReturnType<typeof getCurrentUserOrgs>[number];

export const getCurrentUserSelectedOrg = createSelector(
  [getCurrentUserOrgs, getCurrentUserSelectedOrgId],
  (orgs, selectedOrgId) => {
    /**
     * It's safe to assume that when this selector is used in the app we will always have an org for the user.
     * User middleware along with auth component handles when org not found and sets orgId if user preference is not found.
     * So using type casting here will not be a problem at runtime!
     **/
    return orgs.find((org) => org.id === selectedOrgId) as Organization;
  },
);

export const getCurrentUserSelectedOrgRole = createSelector(
  [getCurrentUserDetails, getCurrentUserSelectedOrgId],
  (currentUserDetails, selectedOrgId) => {
    const userRole = currentUserDetails?.user?.myOrgRoles?.find(
      (orgRole) => orgRole.organization_id === selectedOrgId,
    )?.role;

    const modifiedRole = {
      ...userRole,
      permissionMap: getPermissionMap(userRole?.role_permissions),
    };

    delete modifiedRole.role_permissions;

    return modifiedRole;
  },
);

export const getIsCurrentUserOrgMainOrg = createSelector(
  [getCurrentUserSelectedOrg],
  (currentUserOrg) => currentUserOrg?.parent_organization_id === null,
);

export const getCurrentOrgUsersMap = createSelector([getCurrentUserSelectedOrg], (currentOrg) => {
  const currentOrgUsers = currentOrg.organization_users.map((orgUser) => ({
    user: {
      ...orgUser.user,
      disabled: orgUser.disabled,
      role: orgUser.role?.system_role,
    },
  }));

  return currentOrgUsers.reduce<Record<string, (typeof currentOrgUsers)[number]['user']>>(
    (acc, data) => {
      acc[data.user.id] = data.user;

      return acc;
    },
    {},
  );
});

export const getOrgUsersByRole = createSelector(
  [getCurrentUserSelectedOrg, (state: AppRootState, roleId: string) => roleId],
  (organization, roleId) => {
    const users = organization.organization_users.filter((user) => user.role.id === roleId);
    return users;
  },
);

export const getNonDisabledUsersAndAuditors = createSelector(
  [getCurrentOrgUsersMap],
  (currentOrgUsers) =>
    Object.keys(currentOrgUsers)
      .filter((key) => !currentOrgUsers[key]?.disabled)
      .map((key) => currentOrgUsers[key])
      .filter(isNonNullable),
);

export const getCurrentOrgNonDisabledUsers = createSelector(
  [getNonDisabledUsersAndAuditors],
  (nonDisabledUsersAndAuditors) =>
    nonDisabledUsersAndAuditors.filter((user) => user.role !== System_Roles_Enum.Auditor),
);

export const getCurrentOrgUserMentionsData = createSelector(
  [getNonDisabledUsersAndAuditors, getCurrentUserId, getTFunction],
  (nonDisabledUsers, currentUserId, t) => {
    return nonDisabledUsers.map((user) => {
      const label =
        user.id === currentUserId
          ? t('commentInput.selfMentionLabel', {
              userName: user.displayName,
            })
          : user.displayName;

      return {
        id: user.id,
        name: user.displayName,
        label,
      };
    });
  },
);

const getDisabledNotificationsApiData = createSelector(
  (state: AppRootState, t: TFunction, userId: string) => ({ state, userId }),
  ({ state, userId }) => {
    return (
      userNotificationsApi.endpoints.GetDisabledNotifications.select({
        userId,
      })(state).data?.notification_disabled_settings || []
    );
  },
);

export const getUserNotifications = createSelector(
  [getDisabledNotificationsApiData, getTFunction],
  (disabledNotifications, t) => {
    const notifications = getMappedNotifications(t);
    disabledNotifications.forEach(({ type, delivery_type }) => {
      notifications[type][delivery_type].value = false;
    });
    return Object.values(notifications);
  },
);

export const getAllMembersOfOrgsCurrentUserJoined = createSelector([getCurrentUserOrgs], (orgs) => {
  const users = orgs.map((org) => org.organization_users).flat();

  return users.reduce<Record<string, OrganizationUserDetailsFragment['user']>>((acc, data) => {
    acc[data.user.id] = data.user;

    return acc;
  }, {});
});

import { Flex } from '@chakra-ui/react';
import { Route } from '@tanstack/react-router';
import { z } from 'zod';

import Signup from '../features/user/signup';
import { rootRoute } from './__root';

const SignUpPage = () => {
  return (
    <Flex py="12" minH="100vh" alignItems="center" justifyContent="center">
      <Signup />
    </Flex>
  );
};

export const signUpRoute = new Route({
  getParentRoute: () => rootRoute,
  path: 'signup',
  validateSearch: z.object({
    invitationId: z.string().optional(),
  }),
  component: SignUpPage,
});

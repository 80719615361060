/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as ClientTypes from './client-scalars';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AiAutocompleteEntities: {
    input: ClientTypes.AiAutocompleteEntities;
    output: ClientTypes.AiAutocompleteEntities;
  };
  AiCreateEntityInput: {
    input: ClientTypes.AiCreateEntityInput;
    output: ClientTypes.AiCreateEntityInput;
  };
  AuditTableActions: {
    input: ClientTypes.AuditTableActions;
    output: ClientTypes.AuditTableActions;
  };
  AuditTableHasuraUser: {
    input: ClientTypes.AuditTableHasuraUser;
    output: ClientTypes.AuditTableHasuraUser;
  };
  ColumnFiltersState: {
    input: ClientTypes.ColumnFiltersState;
    output: ClientTypes.ColumnFiltersState;
  };
  CommentsFragment: { input: any; output: any };
  ControlChangedFields: {
    input: ClientTypes.ControlChangedFields;
    output: ClientTypes.ControlChangedFields;
  };
  ControlDetails: { input: any; output: any };
  ControlEvidenceStatus: {
    input: ClientTypes.ControlEvidenceStatus;
    output: ClientTypes.ControlEvidenceStatus;
  };
  ControlHistoryTables: {
    input: ClientTypes.ControlHistoryTables;
    output: ClientTypes.ControlHistoryTables;
  };
  ControlRowData: { input: ClientTypes.ControlRowData; output: ClientTypes.ControlRowData };
  ControlStatus: { input: ClientTypes.ControlStatus; output: ClientTypes.ControlStatus };
  ControlTags: { input: ClientTypes.ControlTags; output: ClientTypes.ControlTags };
  DateFieldValue: { input: any; output: any };
  EmailFieldValue: { input: any; output: any };
  EvidenceIntegrationConfig: {
    input: ClientTypes.EvidenceIntegrationConfig;
    output: ClientTypes.EvidenceIntegrationConfig;
  };
  EvidenceProgram: { input: any; output: any };
  EvidenceStatus: { input: ClientTypes.EvidenceStatus; output: ClientTypes.EvidenceStatus };
  FieldConfig: { input: any; output: any };
  FieldValue: { input: any; output: any };
  FileInfoFragment: { input: any; output: any };
  FormConfig: { input: ClientTypes.FormConfig; output: ClientTypes.FormConfig };
  NotificationItem: { input: any; output: any };
  NotificationParams: {
    input: ClientTypes.NotificationParams;
    output: ClientTypes.NotificationParams;
  };
  NumberFieldValue: { input: any; output: any };
  OrgIntegrationConfig: {
    input: ClientTypes.OrgIntegrationConfig;
    output: ClientTypes.OrgIntegrationConfig;
  };
  OrganizationDetailsFragment: { input: any; output: any };
  OrganizationEvidence: { input: any; output: any };
  OrganizationUserDetails: { input: any; output: any };
  PolicyChangedFields: {
    input: ClientTypes.PolicyChangedFields;
    output: ClientTypes.PolicyChangedFields;
  };
  PolicyDrawerFragment: { input: any; output: any };
  PolicyHistoryTables: {
    input: ClientTypes.PolicyHistoryTables;
    output: ClientTypes.PolicyHistoryTables;
  };
  PolicyRowData: { input: ClientTypes.PolicyRowData; output: ClientTypes.PolicyRowData };
  ReviewDetails: { input: any; output: any };
  RiskAssessmentStatus: {
    input: ClientTypes.RiskAssessmentStatus;
    output: ClientTypes.RiskAssessmentStatus;
  };
  RiskChangedFields: {
    input: ClientTypes.RiskChangedFields;
    output: ClientTypes.RiskChangedFields;
  };
  RiskHistoryTables: {
    input: ClientTypes.RiskHistoryTables;
    output: ClientTypes.RiskHistoryTables;
  };
  RiskRowData: { input: ClientTypes.RiskRowData; output: ClientTypes.RiskRowData };
  RoleDetails: { input: any; output: any };
  SelectFieldConfig: { input: any; output: any };
  SelectFieldOption: { input: any; output: any };
  SelectFieldValue: { input: any; output: any };
  SortingState: { input: ClientTypes.SortingState; output: ClientTypes.SortingState };
  TaskDrawerFragment: { input: any; output: any };
  TaskStatus: { input: ClientTypes.TaskStatus; output: ClientTypes.TaskStatus };
  TaskTableFragment: { input: any; output: any };
  TextFieldValue: { input: any; output: any };
  UrlFieldValue: { input: any; output: any };
  UserMetaData: { input: ClientTypes.UserMetaData; output: ClientTypes.UserMetaData };
  UserNameEmailFragment: { input: any; output: any };
  VendorAssessmentStatus: {
    input: ClientTypes.VendorAssessmentStatus;
    output: ClientTypes.VendorAssessmentStatus;
  };
  VendorChangedFields: {
    input: ClientTypes.VendorChangedFields;
    output: ClientTypes.VendorChangedFields;
  };
  VendorHistoryTables: {
    input: ClientTypes.VendorHistoryTables;
    output: ClientTypes.VendorHistoryTables;
  };
  VendorQuestionnaireFormAnswerValue: {
    input: ClientTypes.VendorQuestionnaireFormAnswerValue;
    output: ClientTypes.VendorQuestionnaireFormAnswerValue;
  };
  VendorRowData: { input: ClientTypes.VendorRowData; output: ClientTypes.VendorRowData };
  bigint: { input: number; output: number };
  bytea: { input: any; output: any };
  citext: { input: string; output: string };
  float8: { input: number; output: number };
  inet: { input: any; output: any };
  json: { input: any; output: any };
  jsonb: { input: any; output: any };
  numeric: { input: number; output: number };
  oid: { input: any; output: any };
  sparsevec: { input: any; output: any };
  timestamp: { input: any; output: any };
  timestamptz: { input: string; output: string };
  uuid: { input: string; output: string };
  vector: { input: any; output: any };
};

export type AiAutocompleteEntityInput = {
  entity: Scalars['AiAutocompleteEntities']['input'];
  entityId: Scalars['String']['input'];
};

export type AiAutocompleteEntityOutput = {
  __typename?: 'AiAutocompleteEntityOutput';
  autocomplete: Scalars['String']['output'];
};

export type AiControlEvidenceSuggestion = {
  __typename?: 'AiControlEvidenceSuggestion';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AiControlEvidenceSuggestionsInput = {
  controlId: Scalars['uuid']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type AiControlEvidenceSuggestionsOutput = {
  __typename?: 'AiControlEvidenceSuggestionsOutput';
  evidences?: Maybe<Array<AiControlEvidenceSuggestion>>;
};

export type AiCreateEntityOutput = {
  __typename?: 'AiCreateEntityOutput';
  entityId: Scalars['String']['output'];
  internalId?: Maybe<Scalars['String']['output']>;
};

export type ApprovalReminderInput = {
  approvalId: Scalars['String']['input'];
  approverId: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
};

export type ApprovalReminderOutput = {
  __typename?: 'ApprovalReminderOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type ChangeEvidenceOwnerInput = {
  evidence_id: Scalars['String']['input'];
  new_owner_id: Scalars['String']['input'];
};

export type ControlAiReviewInput = {
  controlId: Scalars['String']['input'];
};

export type ControlAiReviewJob = {
  __typename?: 'ControlAiReviewJob';
  controlId: Scalars['String']['output'];
  workflowId: Scalars['String']['output'];
};

export type ControlAiReviewOutput = {
  __typename?: 'ControlAiReviewOutput';
  workflowId: Scalars['String']['output'];
};

export type CreateControlInput = {
  framework_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  program_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateControlOutput = {
  __typename?: 'CreateControlOutput';
  id: Scalars['String']['output'];
};

export type CreateCustomProgramRequestInput = {
  fileId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  programDescription: Scalars['String']['input'];
  programName: Scalars['String']['input'];
};

export type CreateCustomProgramRequestOutput = {
  __typename?: 'CreateCustomProgramRequestOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type CreateProgramInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  frameworkType: FrameworkType;
  framework_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type DeleteProgramInput = {
  programId: Scalars['String']['input'];
};

export type ExportPolicyInput = {
  policyId: Scalars['uuid']['input'];
  withAcknowledgementsChapter?: InputMaybe<Scalars['Boolean']['input']>;
  withApprovalsChapter?: InputMaybe<Scalars['Boolean']['input']>;
  withRevisionDetails?: InputMaybe<Scalars['Boolean']['input']>;
  withVersionsChapter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExportPolicyOutput = {
  __typename?: 'ExportPolicyOutput';
  fileId: Scalars['uuid']['output'];
};

export type FrameworkRequestInput = {
  additionalComments: Scalars['String']['input'];
  frameworkName: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type FrameworkRequestOutput = {
  __typename?: 'FrameworkRequestOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export enum FrameworkType {
  Custom = 'CUSTOM',
  Template = 'TEMPLATE',
}

export type GenIntegrationRunReportInput = {
  integrationRunId: Scalars['uuid']['input'];
};

export type GenIntegrationRunReportOutput = {
  __typename?: 'GenIntegrationRunReportOutput';
  reportFileId: Scalars['uuid']['output'];
};

export type GeneralActionOutput = {
  __typename?: 'GeneralActionOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type GenerateWorkosAdminLinkInput = {
  intent: WorkosAdminPortalIntent;
  orgId: Scalars['String']['input'];
};

export type GenerateWorkosAdminLinkOutput = {
  __typename?: 'GenerateWorkosAdminLinkOutput';
  link?: Maybe<Scalars['String']['output']>;
};

export type GrantEvidenceAccessInput = {
  evidence_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

export type GrantEvidenceAccessOutput = {
  __typename?: 'GrantEvidenceAccessOutput';
  evidence_acl_id: Scalars['uuid']['output'];
};

export type ImpersonateUserInput = {
  userId: Scalars['String']['input'];
};

export type ImpersonateUserOutput = {
  __typename?: 'ImpersonateUserOutput';
  refreshToken: Scalars['String']['output'];
};

export type ImportPoliciesInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  sharepointFolder?: InputMaybe<Scalars['String']['input']>;
};

export type ImportPoliciesOutput = {
  __typename?: 'ImportPoliciesOutput';
  actionId: Scalars['uuid']['output'];
};

export type ImportProgramInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  sharepointFolder?: InputMaybe<Scalars['String']['input']>;
};

export type ImportProgramOutput = {
  __typename?: 'ImportProgramOutput';
  actionId: Scalars['uuid']['output'];
};

export type ImportRisksInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type ImportRisksOutput = {
  __typename?: 'ImportRisksOutput';
  actionId: Scalars['uuid']['output'];
};

export type ImportVendorsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  fileId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type ImportVendorsOutput = {
  __typename?: 'ImportVendorsOutput';
  actionId: Scalars['uuid']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  invitingUserId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type InviteUserOutput = {
  __typename?: 'InviteUserOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  type?: Maybe<UserInviteResult>;
};

export type JoinOrganizationInput = {
  invitationId: Scalars['String']['input'];
};

export type OrgControlAiReviewInput = {
  organizationId: Scalars['String']['input'];
};

export type OrgControlAiReviewOutput = {
  __typename?: 'OrgControlAiReviewOutput';
  jobs?: Maybe<Array<ControlAiReviewJob>>;
  organizationId: Scalars['String']['output'];
};

export type ProgramOutput = {
  __typename?: 'ProgramOutput';
  id: Scalars['String']['output'];
};

export type RevokeEvidenceAccessInput = {
  evidence_acl_id: Scalars['uuid']['input'];
};

export type RevokeEvidenceAccessOutput = {
  __typename?: 'RevokeEvidenceAccessOutput';
  evidence_acl_id: Scalars['uuid']['output'];
};

export enum SearchEntitiesEnum {
  Controls = 'controls',
  Evidences = 'evidences',
  Policies = 'policies',
  Risks = 'risks',
  Tasks = 'tasks',
  Vendors = 'vendors',
}

export type SearchInput = {
  entities: Array<InputMaybe<SearchEntitiesEnum>>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  organization_id: Scalars['uuid']['input'];
  text: Scalars['String']['input'];
};

export type SearchObject = {
  __typename?: 'SearchObject';
  created_at?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  entity: SearchEntitiesEnum;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type SearchOutput = {
  __typename?: 'SearchOutput';
  data?: Maybe<Array<SearchObject>>;
};

export type SendVendorQuestionnaireInput = {
  customMessage?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['String']['input'];
  questionnaireId: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  vendors?: InputMaybe<Array<SendVendorQuestionnaireVendorInput>>;
};

export type SendVendorQuestionnaireOutput = {
  __typename?: 'SendVendorQuestionnaireOutput';
  workflowId: Scalars['String']['output'];
};

export type SendVendorQuestionnaireVendorInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type SetEvidenceConfidentialInput = {
  evidence_id: Scalars['uuid']['input'];
  is_confidential: Scalars['Boolean']['input'];
};

export type SetEvidenceConfidentialOutput = {
  __typename?: 'SetEvidenceConfidentialOutput';
  evidence_id: Scalars['uuid']['output'];
};

export type SetOrgIntegrationConfigInput = {
  config: Scalars['json']['input'];
  integration_id: Scalars['uuid']['input'];
  organization_id: Scalars['uuid']['input'];
};

export type SetOrgIntegrationConfigOutput = {
  __typename?: 'SetOrgIntegrationConfigOutput';
  organization_integration_id: Scalars['uuid']['output'];
};

export type SetSelectFieldValuesInput = {
  entityId: Scalars['String']['input'];
  fieldConfigId: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type SetSelectFieldValuesOutput = {
  __typename?: 'SetSelectFieldValuesOutput';
  deletedFieldValueIds: Array<Scalars['String']['output']>;
  insertedFieldValueIds: Array<Scalars['String']['output']>;
};

export type SparseVectorOutput = {
  __typename?: 'SparseVectorOutput';
  data: Scalars['json']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type ToggleAuditTrailInput = {
  orgId: Scalars['String']['input'];
};

export enum UserInviteResult {
  UserInvited = 'USER_INVITED',
  UserJoined = 'USER_JOINED',
}

export type VendorQuestionnaireAiReviewInput = {
  vendorQuestionnaireId: Scalars['String']['input'];
};

export type VendorQuestionnaireAiReviewOutput = {
  __typename?: 'VendorQuestionnaireAiReviewOutput';
  workflowId: Scalars['String']['output'];
};

export enum WorkosAdminPortalIntent {
  AuditLogs = 'audit_logs',
  LogStreams = 'log_streams',
}

export type Ai_Control_Evidences_Suggestions_Args = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

export type Ai_Controls_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store = {
  __typename?: 'ai_controls_vector_store';
  /** An object relationship */
  control: Controls;
  controls_id: Scalars['uuid']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.controls_vector_store" */
export type Ai_Controls_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Aggregate = {
  __typename?: 'ai_controls_vector_store_aggregate';
  aggregate?: Maybe<Ai_Controls_Vector_Store_Aggregate_Fields>;
  nodes: Array<Ai_Controls_Vector_Store>;
};

/** aggregate fields of "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Aggregate_Fields = {
  __typename?: 'ai_controls_vector_store_aggregate_fields';
  avg?: Maybe<Ai_Controls_Vector_Store_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Controls_Vector_Store_Max_Fields>;
  min?: Maybe<Ai_Controls_Vector_Store_Min_Fields>;
  stddev?: Maybe<Ai_Controls_Vector_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Ai_Controls_Vector_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ai_Controls_Vector_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Ai_Controls_Vector_Store_Sum_Fields>;
  var_pop?: Maybe<Ai_Controls_Vector_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Ai_Controls_Vector_Store_Var_Samp_Fields>;
  variance?: Maybe<Ai_Controls_Vector_Store_Variance_Fields>;
};

/** aggregate fields of "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Controls_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Controls_Vector_Store_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Ai_Controls_Vector_Store_Avg_Fields = {
  __typename?: 'ai_controls_vector_store_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ai.controls_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Controls_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Controls_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Controls_Vector_Store_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  controls_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.controls_vector_store" */
export enum Ai_Controls_Vector_Store_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlsVectorStorePkey = 'controls_vector_store_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Controls_Vector_Store_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Controls_Vector_Store_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Controls_Vector_Store_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  controls_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ai_Controls_Vector_Store_Max_Fields = {
  __typename?: 'ai_controls_vector_store_max_fields';
  controls_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ai_Controls_Vector_Store_Min_Fields = {
  __typename?: 'ai_controls_vector_store_min_fields';
  controls_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Mutation_Response = {
  __typename?: 'ai_controls_vector_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Controls_Vector_Store>;
};

/** input type for inserting object relation for remote table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Obj_Rel_Insert_Input = {
  data: Ai_Controls_Vector_Store_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Controls_Vector_Store_On_Conflict>;
};

/** on_conflict condition type for table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_On_Conflict = {
  constraint: Ai_Controls_Vector_Store_Constraint;
  update_columns?: Array<Ai_Controls_Vector_Store_Update_Column>;
  where?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.controls_vector_store". */
export type Ai_Controls_Vector_Store_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  controls_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.controls_vector_store */
export type Ai_Controls_Vector_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Controls_Vector_Store_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Ai_Controls_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.controls_vector_store" */
export enum Ai_Controls_Vector_Store_Select_Column {
  /** column name */
  ControlsId = 'controls_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** input type for updating data in table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Set_Input = {
  controls_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Ai_Controls_Vector_Store_Stddev_Fields = {
  __typename?: 'ai_controls_vector_store_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Controls_Vector_Store_Stddev_Pop_Fields = {
  __typename?: 'ai_controls_vector_store_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Controls_Vector_Store_Stddev_Samp_Fields = {
  __typename?: 'ai_controls_vector_store_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ai_controls_vector_store" */
export type Ai_Controls_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Controls_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Controls_Vector_Store_Stream_Cursor_Value_Input = {
  controls_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Ai_Controls_Vector_Store_Sum_Fields = {
  __typename?: 'ai_controls_vector_store_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "ai.controls_vector_store" */
export enum Ai_Controls_Vector_Store_Update_Column {
  /** column name */
  ControlsId = 'controls_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

export type Ai_Controls_Vector_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Controls_Vector_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Controls_Vector_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Controls_Vector_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Controls_Vector_Store_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Controls_Vector_Store_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Controls_Vector_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Controls_Vector_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Controls_Vector_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Controls_Vector_Store_Var_Pop_Fields = {
  __typename?: 'ai_controls_vector_store_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ai_Controls_Vector_Store_Var_Samp_Fields = {
  __typename?: 'ai_controls_vector_store_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ai_Controls_Vector_Store_Variance_Fields = {
  __typename?: 'ai_controls_vector_store_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

export type Ai_Evidences_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store = {
  __typename?: 'ai_evidences_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  /** An object relationship */
  evidence: Evidences;
  evidences_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Aggregate = {
  __typename?: 'ai_evidences_vector_store_aggregate';
  aggregate?: Maybe<Ai_Evidences_Vector_Store_Aggregate_Fields>;
  nodes: Array<Ai_Evidences_Vector_Store>;
};

/** aggregate fields of "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Aggregate_Fields = {
  __typename?: 'ai_evidences_vector_store_aggregate_fields';
  avg?: Maybe<Ai_Evidences_Vector_Store_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Evidences_Vector_Store_Max_Fields>;
  min?: Maybe<Ai_Evidences_Vector_Store_Min_Fields>;
  stddev?: Maybe<Ai_Evidences_Vector_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Ai_Evidences_Vector_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ai_Evidences_Vector_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Ai_Evidences_Vector_Store_Sum_Fields>;
  var_pop?: Maybe<Ai_Evidences_Vector_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Ai_Evidences_Vector_Store_Var_Samp_Fields>;
  variance?: Maybe<Ai_Evidences_Vector_Store_Variance_Fields>;
};

/** aggregate fields of "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Evidences_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Evidences_Vector_Store_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Ai_Evidences_Vector_Store_Avg_Fields = {
  __typename?: 'ai_evidences_vector_store_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ai.evidences_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Evidences_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Evidences_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Evidences_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidences_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.evidences_vector_store" */
export enum Ai_Evidences_Vector_Store_Constraint {
  /** unique or primary key constraint on columns "id" */
  EvidencesVectorStorePkey = 'evidences_vector_store_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Evidences_Vector_Store_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Evidences_Vector_Store_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Evidences_Vector_Store_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidences_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ai_Evidences_Vector_Store_Max_Fields = {
  __typename?: 'ai_evidences_vector_store_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidences_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ai_Evidences_Vector_Store_Min_Fields = {
  __typename?: 'ai_evidences_vector_store_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidences_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Mutation_Response = {
  __typename?: 'ai_evidences_vector_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Evidences_Vector_Store>;
};

/** input type for inserting object relation for remote table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Obj_Rel_Insert_Input = {
  data: Ai_Evidences_Vector_Store_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Evidences_Vector_Store_On_Conflict>;
};

/** on_conflict condition type for table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_On_Conflict = {
  constraint: Ai_Evidences_Vector_Store_Constraint;
  update_columns?: Array<Ai_Evidences_Vector_Store_Update_Column>;
  where?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.evidences_vector_store". */
export type Ai_Evidences_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidences_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.evidences_vector_store */
export type Ai_Evidences_Vector_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Evidences_Vector_Store_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Ai_Evidences_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.evidences_vector_store" */
export enum Ai_Evidences_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  EvidencesId = 'evidences_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** input type for updating data in table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  evidences_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Ai_Evidences_Vector_Store_Stddev_Fields = {
  __typename?: 'ai_evidences_vector_store_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Evidences_Vector_Store_Stddev_Pop_Fields = {
  __typename?: 'ai_evidences_vector_store_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Evidences_Vector_Store_Stddev_Samp_Fields = {
  __typename?: 'ai_evidences_vector_store_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ai_evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Evidences_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Evidences_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  evidences_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Ai_Evidences_Vector_Store_Sum_Fields = {
  __typename?: 'ai_evidences_vector_store_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "ai.evidences_vector_store" */
export enum Ai_Evidences_Vector_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  EvidencesId = 'evidences_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

export type Ai_Evidences_Vector_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Evidences_Vector_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Evidences_Vector_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Evidences_Vector_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Evidences_Vector_Store_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Evidences_Vector_Store_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Evidences_Vector_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Evidences_Vector_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Evidences_Vector_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Evidences_Vector_Store_Var_Pop_Fields = {
  __typename?: 'ai_evidences_vector_store_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ai_Evidences_Vector_Store_Var_Samp_Fields = {
  __typename?: 'ai_evidences_vector_store_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ai_Evidences_Vector_Store_Variance_Fields = {
  __typename?: 'ai_evidences_vector_store_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "ai.files_document_store" */
export type Ai_Files_Document_Store = {
  __typename?: 'ai_files_document_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  file: Files;
  files_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  key: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  value?: Maybe<Scalars['jsonb']['output']>;
};

/** columns and relationships of "ai.files_document_store" */
export type Ai_Files_Document_StoreValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.files_document_store" */
export type Ai_Files_Document_Store_Aggregate = {
  __typename?: 'ai_files_document_store_aggregate';
  aggregate?: Maybe<Ai_Files_Document_Store_Aggregate_Fields>;
  nodes: Array<Ai_Files_Document_Store>;
};

export type Ai_Files_Document_Store_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ai_Files_Document_Store_Aggregate_Bool_Exp_Count>;
};

export type Ai_Files_Document_Store_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ai_Files_Document_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ai.files_document_store" */
export type Ai_Files_Document_Store_Aggregate_Fields = {
  __typename?: 'ai_files_document_store_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Files_Document_Store_Max_Fields>;
  min?: Maybe<Ai_Files_Document_Store_Min_Fields>;
};

/** aggregate fields of "ai.files_document_store" */
export type Ai_Files_Document_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Files_Document_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ai.files_document_store" */
export type Ai_Files_Document_Store_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Files_Document_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Files_Document_Store_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Files_Document_Store_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "ai.files_document_store" */
export type Ai_Files_Document_Store_Arr_Rel_Insert_Input = {
  data: Array<Ai_Files_Document_Store_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Files_Document_Store_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ai.files_document_store". All fields are combined with a logical 'AND'. */
export type Ai_Files_Document_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Files_Document_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Files_Document_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  files_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  namespace?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.files_document_store" */
export enum Ai_Files_Document_Store_Constraint {
  /** unique or primary key constraint on columns "key", "namespace" */
  FilesDocumentStoreKeyNamespaceKey = 'files_document_store_key_namespace_key',
  /** unique or primary key constraint on columns "id" */
  FilesDocumentStorePkey = 'files_document_store_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Files_Document_Store_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Files_Document_Store_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Files_Document_Store_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "ai.files_document_store" */
export type Ai_Files_Document_Store_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  files_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Ai_Files_Document_Store_Max_Fields = {
  __typename?: 'ai_files_document_store_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  files_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  namespace?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ai.files_document_store" */
export type Ai_Files_Document_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  files_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  namespace?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ai_Files_Document_Store_Min_Fields = {
  __typename?: 'ai_files_document_store_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  files_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  namespace?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ai.files_document_store" */
export type Ai_Files_Document_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  files_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  namespace?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ai.files_document_store" */
export type Ai_Files_Document_Store_Mutation_Response = {
  __typename?: 'ai_files_document_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Files_Document_Store>;
};

/** on_conflict condition type for table "ai.files_document_store" */
export type Ai_Files_Document_Store_On_Conflict = {
  constraint: Ai_Files_Document_Store_Constraint;
  update_columns?: Array<Ai_Files_Document_Store_Update_Column>;
  where?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.files_document_store". */
export type Ai_Files_Document_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  files_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  namespace?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.files_document_store */
export type Ai_Files_Document_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Files_Document_Store_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "ai.files_document_store" */
export enum Ai_Files_Document_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilesId = 'files_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "ai.files_document_store" */
export type Ai_Files_Document_Store_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  files_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "ai_files_document_store" */
export type Ai_Files_Document_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Files_Document_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Files_Document_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  files_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "ai.files_document_store" */
export enum Ai_Files_Document_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FilesId = 'files_id',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Value = 'value',
}

export type Ai_Files_Document_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Files_Document_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Files_Document_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Files_Document_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Files_Document_Store_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Files_Document_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Files_Document_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Files_Document_Store_Bool_Exp;
};

/** columns and relationships of "ai.files_vector_store" */
export type Ai_Files_Vector_Store = {
  __typename?: 'ai_files_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  /** An object relationship */
  file: Files;
  files_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.files_vector_store" */
export type Ai_Files_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Aggregate = {
  __typename?: 'ai_files_vector_store_aggregate';
  aggregate?: Maybe<Ai_Files_Vector_Store_Aggregate_Fields>;
  nodes: Array<Ai_Files_Vector_Store>;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp_Var_Samp>;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Avg = {
  arguments: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Corr = {
  arguments: Ai_Files_Vector_Store_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Ai_Files_Vector_Store_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Max = {
  arguments: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Min = {
  arguments: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Sum = {
  arguments: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Ai_Files_Vector_Store_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Aggregate_Fields = {
  __typename?: 'ai_files_vector_store_aggregate_fields';
  avg?: Maybe<Ai_Files_Vector_Store_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Files_Vector_Store_Max_Fields>;
  min?: Maybe<Ai_Files_Vector_Store_Min_Fields>;
  stddev?: Maybe<Ai_Files_Vector_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Ai_Files_Vector_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ai_Files_Vector_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Ai_Files_Vector_Store_Sum_Fields>;
  var_pop?: Maybe<Ai_Files_Vector_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Ai_Files_Vector_Store_Var_Samp_Fields>;
  variance?: Maybe<Ai_Files_Vector_Store_Variance_Fields>;
};

/** aggregate fields of "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Files_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Files_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Files_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Files_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Files_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Files_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Files_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Files_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Files_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Files_Vector_Store_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Files_Vector_Store_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Arr_Rel_Insert_Input = {
  data: Array<Ai_Files_Vector_Store_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Files_Vector_Store_On_Conflict>;
};

/** aggregate avg on columns */
export type Ai_Files_Vector_Store_Avg_Fields = {
  __typename?: 'ai_files_vector_store_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.files_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Files_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Files_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Files_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  files_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesVectorStorePkey = 'files_vector_store_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Files_Vector_Store_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Files_Vector_Store_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Files_Vector_Store_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  files_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ai_Files_Vector_Store_Max_Fields = {
  __typename?: 'ai_files_vector_store_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  files_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  files_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ai_Files_Vector_Store_Min_Fields = {
  __typename?: 'ai_files_vector_store_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  files_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  files_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Mutation_Response = {
  __typename?: 'ai_files_vector_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Files_Vector_Store>;
};

/** on_conflict condition type for table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_On_Conflict = {
  constraint: Ai_Files_Vector_Store_Constraint;
  update_columns?: Array<Ai_Files_Vector_Store_Update_Column>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.files_vector_store". */
export type Ai_Files_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  files_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.files_vector_store */
export type Ai_Files_Vector_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Files_Vector_Store_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Ai_Files_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  FilesId = 'files_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** select "ai_files_vector_store_aggregate_bool_exp_avg_arguments_columns" columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "ai_files_vector_store_aggregate_bool_exp_corr_arguments_columns" columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "ai_files_vector_store_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "ai_files_vector_store_aggregate_bool_exp_max_arguments_columns" columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "ai_files_vector_store_aggregate_bool_exp_min_arguments_columns" columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "ai_files_vector_store_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "ai_files_vector_store_aggregate_bool_exp_sum_arguments_columns" columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "ai_files_vector_store_aggregate_bool_exp_var_samp_arguments_columns" columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Select_Column_Ai_Files_Vector_Store_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  files_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Ai_Files_Vector_Store_Stddev_Fields = {
  __typename?: 'ai_files_vector_store_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ai_Files_Vector_Store_Stddev_Pop_Fields = {
  __typename?: 'ai_files_vector_store_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ai_Files_Vector_Store_Stddev_Samp_Fields = {
  __typename?: 'ai_files_vector_store_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ai_files_vector_store" */
export type Ai_Files_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Files_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Files_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  files_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Ai_Files_Vector_Store_Sum_Fields = {
  __typename?: 'ai_files_vector_store_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "ai.files_vector_store" */
export enum Ai_Files_Vector_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  FilesId = 'files_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

export type Ai_Files_Vector_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Files_Vector_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Files_Vector_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Files_Vector_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Files_Vector_Store_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Files_Vector_Store_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Files_Vector_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Files_Vector_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Files_Vector_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Files_Vector_Store_Var_Pop_Fields = {
  __typename?: 'ai_files_vector_store_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ai_Files_Vector_Store_Var_Samp_Fields = {
  __typename?: 'ai_files_vector_store_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ai_Files_Vector_Store_Variance_Fields = {
  __typename?: 'ai_files_vector_store_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "ai.files_vector_store" */
export type Ai_Files_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Ai_Policies_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store = {
  __typename?: 'ai_policies_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  policies_id: Scalars['uuid']['output'];
  /** An object relationship */
  policy: Policies;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.policies_vector_store" */
export type Ai_Policies_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Aggregate = {
  __typename?: 'ai_policies_vector_store_aggregate';
  aggregate?: Maybe<Ai_Policies_Vector_Store_Aggregate_Fields>;
  nodes: Array<Ai_Policies_Vector_Store>;
};

/** aggregate fields of "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Aggregate_Fields = {
  __typename?: 'ai_policies_vector_store_aggregate_fields';
  avg?: Maybe<Ai_Policies_Vector_Store_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Policies_Vector_Store_Max_Fields>;
  min?: Maybe<Ai_Policies_Vector_Store_Min_Fields>;
  stddev?: Maybe<Ai_Policies_Vector_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Ai_Policies_Vector_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ai_Policies_Vector_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Ai_Policies_Vector_Store_Sum_Fields>;
  var_pop?: Maybe<Ai_Policies_Vector_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Ai_Policies_Vector_Store_Var_Samp_Fields>;
  variance?: Maybe<Ai_Policies_Vector_Store_Variance_Fields>;
};

/** aggregate fields of "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Policies_Vector_Store_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Ai_Policies_Vector_Store_Avg_Fields = {
  __typename?: 'ai_policies_vector_store_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ai.policies_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Policies_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Policies_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Policies_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  policies_id?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.policies_vector_store" */
export enum Ai_Policies_Vector_Store_Constraint {
  /** unique or primary key constraint on columns "id" */
  PoliciesVectorStorePkey = 'policies_vector_store_pkey',
  /** unique or primary key constraint on columns "policies_id" */
  PoliciesVectorStorePoliciesIdKey = 'policies_vector_store_policies_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Policies_Vector_Store_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Policies_Vector_Store_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Policies_Vector_Store_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  policies_id?: InputMaybe<Scalars['uuid']['input']>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ai_Policies_Vector_Store_Max_Fields = {
  __typename?: 'ai_policies_vector_store_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  policies_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ai_Policies_Vector_Store_Min_Fields = {
  __typename?: 'ai_policies_vector_store_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  policies_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Mutation_Response = {
  __typename?: 'ai_policies_vector_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Policies_Vector_Store>;
};

/** input type for inserting object relation for remote table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Obj_Rel_Insert_Input = {
  data: Ai_Policies_Vector_Store_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Policies_Vector_Store_On_Conflict>;
};

/** on_conflict condition type for table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_On_Conflict = {
  constraint: Ai_Policies_Vector_Store_Constraint;
  update_columns?: Array<Ai_Policies_Vector_Store_Update_Column>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.policies_vector_store". */
export type Ai_Policies_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  policies_id?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.policies_vector_store */
export type Ai_Policies_Vector_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Policies_Vector_Store_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Ai_Policies_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.policies_vector_store" */
export enum Ai_Policies_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  PoliciesId = 'policies_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** input type for updating data in table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  policies_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Ai_Policies_Vector_Store_Stddev_Fields = {
  __typename?: 'ai_policies_vector_store_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Policies_Vector_Store_Stddev_Pop_Fields = {
  __typename?: 'ai_policies_vector_store_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Policies_Vector_Store_Stddev_Samp_Fields = {
  __typename?: 'ai_policies_vector_store_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ai_policies_vector_store" */
export type Ai_Policies_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Policies_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Policies_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  policies_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Ai_Policies_Vector_Store_Sum_Fields = {
  __typename?: 'ai_policies_vector_store_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "ai.policies_vector_store" */
export enum Ai_Policies_Vector_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  PoliciesId = 'policies_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

export type Ai_Policies_Vector_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Policies_Vector_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Policies_Vector_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Policies_Vector_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Policies_Vector_Store_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Policies_Vector_Store_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Policies_Vector_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Policies_Vector_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Policies_Vector_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Policies_Vector_Store_Var_Pop_Fields = {
  __typename?: 'ai_policies_vector_store_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ai_Policies_Vector_Store_Var_Samp_Fields = {
  __typename?: 'ai_policies_vector_store_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ai_Policies_Vector_Store_Variance_Fields = {
  __typename?: 'ai_policies_vector_store_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

export type Ai_Programs_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store = {
  __typename?: 'ai_programs_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  program: Programs;
  programs_id: Scalars['uuid']['output'];
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.programs_vector_store" */
export type Ai_Programs_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Aggregate = {
  __typename?: 'ai_programs_vector_store_aggregate';
  aggregate?: Maybe<Ai_Programs_Vector_Store_Aggregate_Fields>;
  nodes: Array<Ai_Programs_Vector_Store>;
};

/** aggregate fields of "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Aggregate_Fields = {
  __typename?: 'ai_programs_vector_store_aggregate_fields';
  avg?: Maybe<Ai_Programs_Vector_Store_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Programs_Vector_Store_Max_Fields>;
  min?: Maybe<Ai_Programs_Vector_Store_Min_Fields>;
  stddev?: Maybe<Ai_Programs_Vector_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Ai_Programs_Vector_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ai_Programs_Vector_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Ai_Programs_Vector_Store_Sum_Fields>;
  var_pop?: Maybe<Ai_Programs_Vector_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Ai_Programs_Vector_Store_Var_Samp_Fields>;
  variance?: Maybe<Ai_Programs_Vector_Store_Variance_Fields>;
};

/** aggregate fields of "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Programs_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Programs_Vector_Store_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Ai_Programs_Vector_Store_Avg_Fields = {
  __typename?: 'ai_programs_vector_store_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ai.programs_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Programs_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Programs_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Programs_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  program?: InputMaybe<Programs_Bool_Exp>;
  programs_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.programs_vector_store" */
export enum Ai_Programs_Vector_Store_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramsVectorStorePkey = 'programs_vector_store_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Programs_Vector_Store_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Programs_Vector_Store_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Programs_Vector_Store_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  program?: InputMaybe<Programs_Obj_Rel_Insert_Input>;
  programs_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ai_Programs_Vector_Store_Max_Fields = {
  __typename?: 'ai_programs_vector_store_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  programs_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ai_Programs_Vector_Store_Min_Fields = {
  __typename?: 'ai_programs_vector_store_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  programs_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Mutation_Response = {
  __typename?: 'ai_programs_vector_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Programs_Vector_Store>;
};

/** input type for inserting object relation for remote table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Obj_Rel_Insert_Input = {
  data: Ai_Programs_Vector_Store_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Programs_Vector_Store_On_Conflict>;
};

/** on_conflict condition type for table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_On_Conflict = {
  constraint: Ai_Programs_Vector_Store_Constraint;
  update_columns?: Array<Ai_Programs_Vector_Store_Update_Column>;
  where?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.programs_vector_store". */
export type Ai_Programs_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  program?: InputMaybe<Programs_Order_By>;
  programs_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.programs_vector_store */
export type Ai_Programs_Vector_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Programs_Vector_Store_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Ai_Programs_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.programs_vector_store" */
export enum Ai_Programs_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  ProgramsId = 'programs_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** input type for updating data in table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  programs_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Ai_Programs_Vector_Store_Stddev_Fields = {
  __typename?: 'ai_programs_vector_store_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Programs_Vector_Store_Stddev_Pop_Fields = {
  __typename?: 'ai_programs_vector_store_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Programs_Vector_Store_Stddev_Samp_Fields = {
  __typename?: 'ai_programs_vector_store_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ai_programs_vector_store" */
export type Ai_Programs_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Programs_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Programs_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  programs_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Ai_Programs_Vector_Store_Sum_Fields = {
  __typename?: 'ai_programs_vector_store_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "ai.programs_vector_store" */
export enum Ai_Programs_Vector_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  ProgramsId = 'programs_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

export type Ai_Programs_Vector_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Programs_Vector_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Programs_Vector_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Programs_Vector_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Programs_Vector_Store_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Programs_Vector_Store_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Programs_Vector_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Programs_Vector_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Programs_Vector_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Programs_Vector_Store_Var_Pop_Fields = {
  __typename?: 'ai_programs_vector_store_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ai_Programs_Vector_Store_Var_Samp_Fields = {
  __typename?: 'ai_programs_vector_store_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ai_Programs_Vector_Store_Variance_Fields = {
  __typename?: 'ai_programs_vector_store_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

export type Ai_Risks_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store = {
  __typename?: 'ai_risks_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  risk: Risks;
  risks_id: Scalars['uuid']['output'];
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.risks_vector_store" */
export type Ai_Risks_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Aggregate = {
  __typename?: 'ai_risks_vector_store_aggregate';
  aggregate?: Maybe<Ai_Risks_Vector_Store_Aggregate_Fields>;
  nodes: Array<Ai_Risks_Vector_Store>;
};

/** aggregate fields of "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Aggregate_Fields = {
  __typename?: 'ai_risks_vector_store_aggregate_fields';
  avg?: Maybe<Ai_Risks_Vector_Store_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Risks_Vector_Store_Max_Fields>;
  min?: Maybe<Ai_Risks_Vector_Store_Min_Fields>;
  stddev?: Maybe<Ai_Risks_Vector_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Ai_Risks_Vector_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ai_Risks_Vector_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Ai_Risks_Vector_Store_Sum_Fields>;
  var_pop?: Maybe<Ai_Risks_Vector_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Ai_Risks_Vector_Store_Var_Samp_Fields>;
  variance?: Maybe<Ai_Risks_Vector_Store_Variance_Fields>;
};

/** aggregate fields of "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Risks_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Risks_Vector_Store_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Ai_Risks_Vector_Store_Avg_Fields = {
  __typename?: 'ai_risks_vector_store_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ai.risks_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Risks_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Risks_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Risks_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risks_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.risks_vector_store" */
export enum Ai_Risks_Vector_Store_Constraint {
  /** unique or primary key constraint on columns "id" */
  RisksVectorStorePkey = 'risks_vector_store_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Risks_Vector_Store_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Risks_Vector_Store_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Risks_Vector_Store_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risks_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ai_Risks_Vector_Store_Max_Fields = {
  __typename?: 'ai_risks_vector_store_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  risks_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ai_Risks_Vector_Store_Min_Fields = {
  __typename?: 'ai_risks_vector_store_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  risks_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Mutation_Response = {
  __typename?: 'ai_risks_vector_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Risks_Vector_Store>;
};

/** input type for inserting object relation for remote table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Obj_Rel_Insert_Input = {
  data: Ai_Risks_Vector_Store_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Risks_Vector_Store_On_Conflict>;
};

/** on_conflict condition type for table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_On_Conflict = {
  constraint: Ai_Risks_Vector_Store_Constraint;
  update_columns?: Array<Ai_Risks_Vector_Store_Update_Column>;
  where?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.risks_vector_store". */
export type Ai_Risks_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risks_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.risks_vector_store */
export type Ai_Risks_Vector_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Risks_Vector_Store_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Ai_Risks_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.risks_vector_store" */
export enum Ai_Risks_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  RisksId = 'risks_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** input type for updating data in table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  risks_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Ai_Risks_Vector_Store_Stddev_Fields = {
  __typename?: 'ai_risks_vector_store_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Risks_Vector_Store_Stddev_Pop_Fields = {
  __typename?: 'ai_risks_vector_store_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Risks_Vector_Store_Stddev_Samp_Fields = {
  __typename?: 'ai_risks_vector_store_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ai_risks_vector_store" */
export type Ai_Risks_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Risks_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Risks_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  risks_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Ai_Risks_Vector_Store_Sum_Fields = {
  __typename?: 'ai_risks_vector_store_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "ai.risks_vector_store" */
export enum Ai_Risks_Vector_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  RisksId = 'risks_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

export type Ai_Risks_Vector_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Risks_Vector_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Risks_Vector_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Risks_Vector_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Risks_Vector_Store_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Risks_Vector_Store_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Risks_Vector_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Risks_Vector_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Risks_Vector_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Risks_Vector_Store_Var_Pop_Fields = {
  __typename?: 'ai_risks_vector_store_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ai_Risks_Vector_Store_Var_Samp_Fields = {
  __typename?: 'ai_risks_vector_store_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ai_Risks_Vector_Store_Variance_Fields = {
  __typename?: 'ai_risks_vector_store_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

export type Ai_Tasks_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store = {
  __typename?: 'ai_tasks_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  /** An object relationship */
  task: Tasks;
  tasks_id: Scalars['uuid']['output'];
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Aggregate = {
  __typename?: 'ai_tasks_vector_store_aggregate';
  aggregate?: Maybe<Ai_Tasks_Vector_Store_Aggregate_Fields>;
  nodes: Array<Ai_Tasks_Vector_Store>;
};

/** aggregate fields of "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Aggregate_Fields = {
  __typename?: 'ai_tasks_vector_store_aggregate_fields';
  avg?: Maybe<Ai_Tasks_Vector_Store_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Tasks_Vector_Store_Max_Fields>;
  min?: Maybe<Ai_Tasks_Vector_Store_Min_Fields>;
  stddev?: Maybe<Ai_Tasks_Vector_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Ai_Tasks_Vector_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ai_Tasks_Vector_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Ai_Tasks_Vector_Store_Sum_Fields>;
  var_pop?: Maybe<Ai_Tasks_Vector_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Ai_Tasks_Vector_Store_Var_Samp_Fields>;
  variance?: Maybe<Ai_Tasks_Vector_Store_Variance_Fields>;
};

/** aggregate fields of "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Tasks_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Tasks_Vector_Store_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Ai_Tasks_Vector_Store_Avg_Fields = {
  __typename?: 'ai_tasks_vector_store_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ai.tasks_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Tasks_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Tasks_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Tasks_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  tasks_id?: InputMaybe<Uuid_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.tasks_vector_store" */
export enum Ai_Tasks_Vector_Store_Constraint {
  /** unique or primary key constraint on columns "id" */
  TasksVectorStorePkey = 'tasks_vector_store_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Tasks_Vector_Store_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Tasks_Vector_Store_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Tasks_Vector_Store_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  tasks_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Ai_Tasks_Vector_Store_Max_Fields = {
  __typename?: 'ai_tasks_vector_store_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  tasks_id?: Maybe<Scalars['uuid']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Ai_Tasks_Vector_Store_Min_Fields = {
  __typename?: 'ai_tasks_vector_store_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  tasks_id?: Maybe<Scalars['uuid']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Mutation_Response = {
  __typename?: 'ai_tasks_vector_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Tasks_Vector_Store>;
};

/** input type for inserting object relation for remote table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Obj_Rel_Insert_Input = {
  data: Ai_Tasks_Vector_Store_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Tasks_Vector_Store_On_Conflict>;
};

/** on_conflict condition type for table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_On_Conflict = {
  constraint: Ai_Tasks_Vector_Store_Constraint;
  update_columns?: Array<Ai_Tasks_Vector_Store_Update_Column>;
  where?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.tasks_vector_store". */
export type Ai_Tasks_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  tasks_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.tasks_vector_store */
export type Ai_Tasks_Vector_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Tasks_Vector_Store_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Ai_Tasks_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.tasks_vector_store" */
export enum Ai_Tasks_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  TasksId = 'tasks_id',
  /** column name */
  Text = 'text',
}

/** input type for updating data in table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  tasks_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Ai_Tasks_Vector_Store_Stddev_Fields = {
  __typename?: 'ai_tasks_vector_store_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Tasks_Vector_Store_Stddev_Pop_Fields = {
  __typename?: 'ai_tasks_vector_store_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Tasks_Vector_Store_Stddev_Samp_Fields = {
  __typename?: 'ai_tasks_vector_store_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ai_tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Tasks_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Tasks_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  tasks_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Ai_Tasks_Vector_Store_Sum_Fields = {
  __typename?: 'ai_tasks_vector_store_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "ai.tasks_vector_store" */
export enum Ai_Tasks_Vector_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  TasksId = 'tasks_id',
  /** column name */
  Text = 'text',
}

export type Ai_Tasks_Vector_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Tasks_Vector_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Tasks_Vector_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Tasks_Vector_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Tasks_Vector_Store_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Tasks_Vector_Store_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Tasks_Vector_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Tasks_Vector_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Tasks_Vector_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Tasks_Vector_Store_Var_Pop_Fields = {
  __typename?: 'ai_tasks_vector_store_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ai_Tasks_Vector_Store_Var_Samp_Fields = {
  __typename?: 'ai_tasks_vector_store_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ai_Tasks_Vector_Store_Variance_Fields = {
  __typename?: 'ai_tasks_vector_store_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

export type Ai_Vendors_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store = {
  __typename?: 'ai_vendors_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendors_id: Scalars['uuid']['output'];
};

/** columns and relationships of "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Aggregate = {
  __typename?: 'ai_vendors_vector_store_aggregate';
  aggregate?: Maybe<Ai_Vendors_Vector_Store_Aggregate_Fields>;
  nodes: Array<Ai_Vendors_Vector_Store>;
};

/** aggregate fields of "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Aggregate_Fields = {
  __typename?: 'ai_vendors_vector_store_aggregate_fields';
  avg?: Maybe<Ai_Vendors_Vector_Store_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Ai_Vendors_Vector_Store_Max_Fields>;
  min?: Maybe<Ai_Vendors_Vector_Store_Min_Fields>;
  stddev?: Maybe<Ai_Vendors_Vector_Store_Stddev_Fields>;
  stddev_pop?: Maybe<Ai_Vendors_Vector_Store_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ai_Vendors_Vector_Store_Stddev_Samp_Fields>;
  sum?: Maybe<Ai_Vendors_Vector_Store_Sum_Fields>;
  var_pop?: Maybe<Ai_Vendors_Vector_Store_Var_Pop_Fields>;
  var_samp?: Maybe<Ai_Vendors_Vector_Store_Var_Samp_Fields>;
  variance?: Maybe<Ai_Vendors_Vector_Store_Variance_Fields>;
};

/** aggregate fields of "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ai_Vendors_Vector_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ai_Vendors_Vector_Store_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Ai_Vendors_Vector_Store_Avg_Fields = {
  __typename?: 'ai_vendors_vector_store_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "ai.vendors_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Vendors_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Vendors_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Vendors_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendors_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ai.vendors_vector_store" */
export enum Ai_Vendors_Vector_Store_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorsVectorStorePkey = 'vendors_vector_store_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ai_Vendors_Vector_Store_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ai_Vendors_Vector_Store_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ai_Vendors_Vector_Store_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendors_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Ai_Vendors_Vector_Store_Max_Fields = {
  __typename?: 'ai_vendors_vector_store_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  vendors_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Ai_Vendors_Vector_Store_Min_Fields = {
  __typename?: 'ai_vendors_vector_store_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  vendors_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Mutation_Response = {
  __typename?: 'ai_vendors_vector_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Ai_Vendors_Vector_Store>;
};

/** input type for inserting object relation for remote table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Obj_Rel_Insert_Input = {
  data: Ai_Vendors_Vector_Store_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ai_Vendors_Vector_Store_On_Conflict>;
};

/** on_conflict condition type for table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_On_Conflict = {
  constraint: Ai_Vendors_Vector_Store_Constraint;
  update_columns?: Array<Ai_Vendors_Vector_Store_Update_Column>;
  where?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "ai.vendors_vector_store". */
export type Ai_Vendors_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendors_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ai.vendors_vector_store */
export type Ai_Vendors_Vector_Store_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ai_Vendors_Vector_Store_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Ai_Vendors_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.vendors_vector_store" */
export enum Ai_Vendors_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
  /** column name */
  VendorsId = 'vendors_id',
}

/** input type for updating data in table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vendors_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Ai_Vendors_Vector_Store_Stddev_Fields = {
  __typename?: 'ai_vendors_vector_store_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Ai_Vendors_Vector_Store_Stddev_Pop_Fields = {
  __typename?: 'ai_vendors_vector_store_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Ai_Vendors_Vector_Store_Stddev_Samp_Fields = {
  __typename?: 'ai_vendors_vector_store_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "ai_vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Vendors_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Vendors_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vendors_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Ai_Vendors_Vector_Store_Sum_Fields = {
  __typename?: 'ai_vendors_vector_store_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "ai.vendors_vector_store" */
export enum Ai_Vendors_Vector_Store_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
  /** column name */
  VendorsId = 'vendors_id',
}

export type Ai_Vendors_Vector_Store_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ai_Vendors_Vector_Store_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ai_Vendors_Vector_Store_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ai_Vendors_Vector_Store_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ai_Vendors_Vector_Store_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ai_Vendors_Vector_Store_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ai_Vendors_Vector_Store_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ai_Vendors_Vector_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ai_Vendors_Vector_Store_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ai_Vendors_Vector_Store_Var_Pop_Fields = {
  __typename?: 'ai_vendors_vector_store_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Ai_Vendors_Vector_Store_Var_Samp_Fields = {
  __typename?: 'ai_vendors_vector_store_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Ai_Vendors_Vector_Store_Variance_Fields = {
  __typename?: 'ai_vendors_vector_store_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "audit.control_history_view" */
export type Audit_Control_History_View = {
  __typename?: 'audit_control_history_view';
  action: Scalars['AuditTableActions']['output'];
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  changed_fields?: Maybe<Scalars['ControlChangedFields']['output']>;
  /** An object relationship */
  control?: Maybe<Controls>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  row_data: Scalars['ControlRowData']['output'];
  table_name: Scalars['ControlHistoryTables']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "audit.control_history_view" */
export type Audit_Control_History_ViewChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.control_history_view" */
export type Audit_Control_History_ViewHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.control_history_view" */
export type Audit_Control_History_ViewRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "audit.control_history_view" */
export type Audit_Control_History_View_Aggregate = {
  __typename?: 'audit_control_history_view_aggregate';
  aggregate?: Maybe<Audit_Control_History_View_Aggregate_Fields>;
  nodes: Array<Audit_Control_History_View>;
};

/** aggregate fields of "audit.control_history_view" */
export type Audit_Control_History_View_Aggregate_Fields = {
  __typename?: 'audit_control_history_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Audit_Control_History_View_Max_Fields>;
  min?: Maybe<Audit_Control_History_View_Min_Fields>;
};

/** aggregate fields of "audit.control_history_view" */
export type Audit_Control_History_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Control_History_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "audit.control_history_view". All fields are combined with a logical 'AND'. */
export type Audit_Control_History_View_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Control_History_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Control_History_View_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Audit_Control_History_View_Max_Fields = {
  __typename?: 'audit_control_history_view_max_fields';
  action?: Maybe<Scalars['String']['output']>;
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  table_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Audit_Control_History_View_Min_Fields = {
  __typename?: 'audit_control_history_view_min_fields';
  action?: Maybe<Scalars['String']['output']>;
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  table_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** Ordering options when selecting data from "audit.control_history_view". */
export type Audit_Control_History_View_Order_By = {
  action?: InputMaybe<Order_By>;
  action_timestamp?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "audit.control_history_view" */
export enum Audit_Control_History_View_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTimestamp = 'action_timestamp',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "audit_control_history_view" */
export type Audit_Control_History_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Control_History_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Control_History_View_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_Actions = {
  __typename?: 'audit_logged_actions';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action: Scalars['String']['output'];
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk: Scalars['timestamptz']['output'];
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm: Scalars['timestamptz']['output'];
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx: Scalars['timestamptz']['output'];
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: Maybe<Scalars['jsonb']['output']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: Maybe<Scalars['inet']['output']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint']['output'];
  hasura_user?: Maybe<Scalars['jsonb']['output']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid: Scalars['oid']['output'];
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: Maybe<Scalars['jsonb']['output']>;
  /** Database schema audited table for this event is in */
  schema_name: Scalars['String']['output'];
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only: Scalars['Boolean']['output'];
  /** Non-schema-qualified table name of table event occured in */
  table_name: Scalars['String']['output'];
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>;
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** History of auditable actions on audited tables, from audit.if_modified_func() */
export type Audit_Logged_ActionsRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate = {
  __typename?: 'audit_logged_actions_aggregate';
  aggregate?: Maybe<Audit_Logged_Actions_Aggregate_Fields>;
  nodes: Array<Audit_Logged_Actions>;
};

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_Fields = {
  __typename?: 'audit_logged_actions_aggregate_fields';
  avg?: Maybe<Audit_Logged_Actions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Audit_Logged_Actions_Max_Fields>;
  min?: Maybe<Audit_Logged_Actions_Min_Fields>;
  stddev?: Maybe<Audit_Logged_Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Audit_Logged_Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Audit_Logged_Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Audit_Logged_Actions_Sum_Fields>;
  var_pop?: Maybe<Audit_Logged_Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Audit_Logged_Actions_Var_Samp_Fields>;
  variance?: Maybe<Audit_Logged_Actions_Variance_Fields>;
};

/** aggregate fields of "audit.logged_actions" */
export type Audit_Logged_Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Append_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Audit_Logged_Actions_Avg_Fields = {
  __typename?: 'audit_logged_actions_avg_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "audit.logged_actions". All fields are combined with a logical 'AND'. */
export type Audit_Logged_Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  _not?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Logged_Actions_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_tstamp_clk?: InputMaybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: InputMaybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: InputMaybe<Timestamptz_Comparison_Exp>;
  application_name?: InputMaybe<String_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  client_addr?: InputMaybe<Inet_Comparison_Exp>;
  client_port?: InputMaybe<Int_Comparison_Exp>;
  client_query?: InputMaybe<String_Comparison_Exp>;
  event_id?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  relid?: InputMaybe<Oid_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  schema_name?: InputMaybe<String_Comparison_Exp>;
  session_user_name?: InputMaybe<String_Comparison_Exp>;
  statement_only?: InputMaybe<Boolean_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  transaction_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit.logged_actions" */
export enum Audit_Logged_Actions_Constraint {
  /** unique or primary key constraint on columns "event_id" */
  LoggedActionsPkey = 'logged_actions_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Logged_Actions_Delete_At_Path_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Array<Scalars['String']['input']>>;
  hasura_user?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Logged_Actions_Delete_Elem_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['Int']['input']>;
  hasura_user?: InputMaybe<Scalars['Int']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Logged_Actions_Delete_Key_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['String']['input']>;
  hasura_user?: InputMaybe<Scalars['String']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "audit.logged_actions" */
export type Audit_Logged_Actions_Inc_Input = {
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "audit.logged_actions" */
export type Audit_Logged_Actions_Insert_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']['input']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']['input']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']['input']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']['input']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']['input']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']['input']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']['input']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Audit_Logged_Actions_Max_Fields = {
  __typename?: 'audit_logged_actions_max_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']['output']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']['output']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']['output']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']['output']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>;
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']['output']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Audit_Logged_Actions_Min_Fields = {
  __typename?: 'audit_logged_actions_min_fields';
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: Maybe<Scalars['String']['output']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: Maybe<Scalars['timestamptz']['output']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: Maybe<Scalars['timestamptz']['output']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: Maybe<Scalars['timestamptz']['output']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: Maybe<Scalars['String']['output']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: Maybe<Scalars['String']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>;
  /** Database schema audited table for this event is in */
  schema_name?: Maybe<Scalars['String']['output']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: Maybe<Scalars['String']['output']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: Maybe<Scalars['String']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "audit.logged_actions" */
export type Audit_Logged_Actions_Mutation_Response = {
  __typename?: 'audit_logged_actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Logged_Actions>;
};

/** on_conflict condition type for table "audit.logged_actions" */
export type Audit_Logged_Actions_On_Conflict = {
  constraint: Audit_Logged_Actions_Constraint;
  update_columns?: Array<Audit_Logged_Actions_Update_Column>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "audit.logged_actions". */
export type Audit_Logged_Actions_Order_By = {
  action?: InputMaybe<Order_By>;
  action_tstamp_clk?: InputMaybe<Order_By>;
  action_tstamp_stm?: InputMaybe<Order_By>;
  action_tstamp_tx?: InputMaybe<Order_By>;
  application_name?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  client_addr?: InputMaybe<Order_By>;
  client_port?: InputMaybe<Order_By>;
  client_query?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  relid?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  schema_name?: InputMaybe<Order_By>;
  session_user_name?: InputMaybe<Order_By>;
  statement_only?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit.logged_actions */
export type Audit_Logged_Actions_Pk_Columns_Input = {
  /** Unique identifier for each auditable event */
  event_id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Logged_Actions_Prepend_Input = {
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id',
}

/** input type for updating data in table "audit.logged_actions" */
export type Audit_Logged_Actions_Set_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']['input']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']['input']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']['input']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']['input']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']['input']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']['input']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']['input']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Audit_Logged_Actions_Stddev_Fields = {
  __typename?: 'audit_logged_actions_stddev_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Audit_Logged_Actions_Stddev_Pop_Fields = {
  __typename?: 'audit_logged_actions_stddev_pop_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Audit_Logged_Actions_Stddev_Samp_Fields = {
  __typename?: 'audit_logged_actions_stddev_samp_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "audit_logged_actions" */
export type Audit_Logged_Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Logged_Actions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Logged_Actions_Stream_Cursor_Value_Input = {
  /** Action type; I = insert, D = delete, U = update, T = truncate */
  action?: InputMaybe<Scalars['String']['input']>;
  /** Wall clock time at which audited event's trigger call occurred */
  action_tstamp_clk?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Statement start timestamp for tx in which audited event occurred */
  action_tstamp_stm?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Transaction start timestamp for tx in which audited event occurred */
  action_tstamp_tx?: InputMaybe<Scalars['timestamptz']['input']>;
  /** Application name set when this audit event occurred. Can be changed in-session by client. */
  application_name?: InputMaybe<Scalars['String']['input']>;
  /** New values of fields changed by UPDATE. Null except for row-level UPDATE events. */
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  /** IP address of client that issued query. Null for unix domain socket. */
  client_addr?: InputMaybe<Scalars['inet']['input']>;
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: InputMaybe<Scalars['Int']['input']>;
  /** Top-level query that caused this auditable event. May be more than one statement. */
  client_query?: InputMaybe<Scalars['String']['input']>;
  /** Unique identifier for each auditable event */
  event_id?: InputMaybe<Scalars['bigint']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  /** Table OID. Changes with drop/create. Get with 'tablename'::regclass */
  relid?: InputMaybe<Scalars['oid']['input']>;
  /** Record value. Null for statement-level trigger. For INSERT this is the new tuple. For DELETE and UPDATE it is the old tuple. */
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  /** Database schema audited table for this event is in */
  schema_name?: InputMaybe<Scalars['String']['input']>;
  /** Login / session user whose statement caused the audited event */
  session_user_name?: InputMaybe<Scalars['String']['input']>;
  /** 't' if audit event is from an FOR EACH STATEMENT trigger, 'f' for FOR EACH ROW */
  statement_only?: InputMaybe<Scalars['Boolean']['input']>;
  /** Non-schema-qualified table name of table event occured in */
  table_name?: InputMaybe<Scalars['String']['input']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Audit_Logged_Actions_Sum_Fields = {
  __typename?: 'audit_logged_actions_sum_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Int']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['bigint']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "audit.logged_actions" */
export enum Audit_Logged_Actions_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id',
}

export type Audit_Logged_Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Logged_Actions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Audit_Logged_Actions_Var_Pop_Fields = {
  __typename?: 'audit_logged_actions_var_pop_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Audit_Logged_Actions_Var_Samp_Fields = {
  __typename?: 'audit_logged_actions_var_samp_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Audit_Logged_Actions_Variance_Fields = {
  __typename?: 'audit_logged_actions_variance_fields';
  /** Remote peer IP port address of client that issued query. Undefined for unix socket. */
  client_port?: Maybe<Scalars['Float']['output']>;
  /** Unique identifier for each auditable event */
  event_id?: Maybe<Scalars['Float']['output']>;
  /** Identifier of transaction that made the change. May wrap, but unique paired with action_tstamp_tx. */
  transaction_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "audit.policy_history_view" */
export type Audit_Policy_History_View = {
  __typename?: 'audit_policy_history_view';
  action: Scalars['AuditTableActions']['output'];
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  changed_fields?: Maybe<Scalars['PolicyChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  /** An object relationship */
  policy?: Maybe<Policies>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  row_data: Scalars['PolicyRowData']['output'];
  table_name: Scalars['PolicyHistoryTables']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "audit.policy_history_view" */
export type Audit_Policy_History_ViewChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.policy_history_view" */
export type Audit_Policy_History_ViewHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.policy_history_view" */
export type Audit_Policy_History_ViewRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "audit.policy_history_view" */
export type Audit_Policy_History_View_Aggregate = {
  __typename?: 'audit_policy_history_view_aggregate';
  aggregate?: Maybe<Audit_Policy_History_View_Aggregate_Fields>;
  nodes: Array<Audit_Policy_History_View>;
};

/** aggregate fields of "audit.policy_history_view" */
export type Audit_Policy_History_View_Aggregate_Fields = {
  __typename?: 'audit_policy_history_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Audit_Policy_History_View_Max_Fields>;
  min?: Maybe<Audit_Policy_History_View_Min_Fields>;
};

/** aggregate fields of "audit.policy_history_view" */
export type Audit_Policy_History_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Policy_History_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Policy_History_View_Append_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "audit.policy_history_view". All fields are combined with a logical 'AND'. */
export type Audit_Policy_History_View_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Policy_History_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Policy_History_View_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Policy_History_View_Delete_At_Path_Input = {
  changed_fields?: InputMaybe<Array<Scalars['String']['input']>>;
  hasura_user?: InputMaybe<Array<Scalars['String']['input']>>;
  row_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Policy_History_View_Delete_Elem_Input = {
  changed_fields?: InputMaybe<Scalars['Int']['input']>;
  hasura_user?: InputMaybe<Scalars['Int']['input']>;
  row_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Policy_History_View_Delete_Key_Input = {
  changed_fields?: InputMaybe<Scalars['String']['input']>;
  hasura_user?: InputMaybe<Scalars['String']['input']>;
  row_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "audit.policy_history_view" */
export type Audit_Policy_History_View_Insert_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Audit_Policy_History_View_Max_Fields = {
  __typename?: 'audit_policy_history_view_max_fields';
  action?: Maybe<Scalars['String']['output']>;
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  table_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Audit_Policy_History_View_Min_Fields = {
  __typename?: 'audit_policy_history_view_min_fields';
  action?: Maybe<Scalars['String']['output']>;
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  table_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "audit.policy_history_view" */
export type Audit_Policy_History_View_Mutation_Response = {
  __typename?: 'audit_policy_history_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Policy_History_View>;
};

/** Ordering options when selecting data from "audit.policy_history_view". */
export type Audit_Policy_History_View_Order_By = {
  action?: InputMaybe<Order_By>;
  action_timestamp?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Policy_History_View_Prepend_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "audit.policy_history_view" */
export enum Audit_Policy_History_View_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTimestamp = 'action_timestamp',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "audit.policy_history_view" */
export type Audit_Policy_History_View_Set_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "audit_policy_history_view" */
export type Audit_Policy_History_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Policy_History_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Policy_History_View_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type Audit_Policy_History_View_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Policy_History_View_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Policy_History_View_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Policy_History_View_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Policy_History_View_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Policy_History_View_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Policy_History_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Policy_History_View_Bool_Exp;
};

/** columns and relationships of "audit.risk_history_view" */
export type Audit_Risk_History_View = {
  __typename?: 'audit_risk_history_view';
  action: Scalars['AuditTableActions']['output'];
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  changed_fields?: Maybe<Scalars['RiskChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  row_data: Scalars['RiskRowData']['output'];
  table_name: Scalars['RiskHistoryTables']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "audit.risk_history_view" */
export type Audit_Risk_History_ViewChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.risk_history_view" */
export type Audit_Risk_History_ViewHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.risk_history_view" */
export type Audit_Risk_History_ViewRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "audit.risk_history_view" */
export type Audit_Risk_History_View_Aggregate = {
  __typename?: 'audit_risk_history_view_aggregate';
  aggregate?: Maybe<Audit_Risk_History_View_Aggregate_Fields>;
  nodes: Array<Audit_Risk_History_View>;
};

/** aggregate fields of "audit.risk_history_view" */
export type Audit_Risk_History_View_Aggregate_Fields = {
  __typename?: 'audit_risk_history_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Audit_Risk_History_View_Max_Fields>;
  min?: Maybe<Audit_Risk_History_View_Min_Fields>;
};

/** aggregate fields of "audit.risk_history_view" */
export type Audit_Risk_History_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Risk_History_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Risk_History_View_Append_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "audit.risk_history_view". All fields are combined with a logical 'AND'. */
export type Audit_Risk_History_View_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Risk_History_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Risk_History_View_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Risk_History_View_Delete_At_Path_Input = {
  changed_fields?: InputMaybe<Array<Scalars['String']['input']>>;
  hasura_user?: InputMaybe<Array<Scalars['String']['input']>>;
  row_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Risk_History_View_Delete_Elem_Input = {
  changed_fields?: InputMaybe<Scalars['Int']['input']>;
  hasura_user?: InputMaybe<Scalars['Int']['input']>;
  row_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Risk_History_View_Delete_Key_Input = {
  changed_fields?: InputMaybe<Scalars['String']['input']>;
  hasura_user?: InputMaybe<Scalars['String']['input']>;
  row_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "audit.risk_history_view" */
export type Audit_Risk_History_View_Insert_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Audit_Risk_History_View_Max_Fields = {
  __typename?: 'audit_risk_history_view_max_fields';
  action?: Maybe<Scalars['String']['output']>;
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  table_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Audit_Risk_History_View_Min_Fields = {
  __typename?: 'audit_risk_history_view_min_fields';
  action?: Maybe<Scalars['String']['output']>;
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  table_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "audit.risk_history_view" */
export type Audit_Risk_History_View_Mutation_Response = {
  __typename?: 'audit_risk_history_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Risk_History_View>;
};

/** Ordering options when selecting data from "audit.risk_history_view". */
export type Audit_Risk_History_View_Order_By = {
  action?: InputMaybe<Order_By>;
  action_timestamp?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Risk_History_View_Prepend_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "audit.risk_history_view" */
export enum Audit_Risk_History_View_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTimestamp = 'action_timestamp',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "audit.risk_history_view" */
export type Audit_Risk_History_View_Set_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "audit_risk_history_view" */
export type Audit_Risk_History_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Risk_History_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Risk_History_View_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

export type Audit_Risk_History_View_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Risk_History_View_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Risk_History_View_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Risk_History_View_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Risk_History_View_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Risk_History_View_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Risk_History_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Risk_History_View_Bool_Exp;
};

/** columns and relationships of "audit.vendor_history_view" */
export type Audit_Vendor_History_View = {
  __typename?: 'audit_vendor_history_view';
  action: Scalars['AuditTableActions']['output'];
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  changed_fields?: Maybe<Scalars['VendorChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  row_data: Scalars['VendorRowData']['output'];
  table_name: Scalars['VendorHistoryTables']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "audit.vendor_history_view" */
export type Audit_Vendor_History_ViewChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.vendor_history_view" */
export type Audit_Vendor_History_ViewHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.vendor_history_view" */
export type Audit_Vendor_History_ViewRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "audit.vendor_history_view" */
export type Audit_Vendor_History_View_Aggregate = {
  __typename?: 'audit_vendor_history_view_aggregate';
  aggregate?: Maybe<Audit_Vendor_History_View_Aggregate_Fields>;
  nodes: Array<Audit_Vendor_History_View>;
};

/** aggregate fields of "audit.vendor_history_view" */
export type Audit_Vendor_History_View_Aggregate_Fields = {
  __typename?: 'audit_vendor_history_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Audit_Vendor_History_View_Max_Fields>;
  min?: Maybe<Audit_Vendor_History_View_Min_Fields>;
};

/** aggregate fields of "audit.vendor_history_view" */
export type Audit_Vendor_History_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Vendor_History_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Audit_Vendor_History_View_Append_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "audit.vendor_history_view". All fields are combined with a logical 'AND'. */
export type Audit_Vendor_History_View_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Vendor_History_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Vendor_History_View_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Audit_Vendor_History_View_Delete_At_Path_Input = {
  changed_fields?: InputMaybe<Array<Scalars['String']['input']>>;
  hasura_user?: InputMaybe<Array<Scalars['String']['input']>>;
  row_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Audit_Vendor_History_View_Delete_Elem_Input = {
  changed_fields?: InputMaybe<Scalars['Int']['input']>;
  hasura_user?: InputMaybe<Scalars['Int']['input']>;
  row_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Audit_Vendor_History_View_Delete_Key_Input = {
  changed_fields?: InputMaybe<Scalars['String']['input']>;
  hasura_user?: InputMaybe<Scalars['String']['input']>;
  row_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "audit.vendor_history_view" */
export type Audit_Vendor_History_View_Insert_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Audit_Vendor_History_View_Max_Fields = {
  __typename?: 'audit_vendor_history_view_max_fields';
  action?: Maybe<Scalars['String']['output']>;
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  table_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Audit_Vendor_History_View_Min_Fields = {
  __typename?: 'audit_vendor_history_view_min_fields';
  action?: Maybe<Scalars['String']['output']>;
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  table_name?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "audit.vendor_history_view" */
export type Audit_Vendor_History_View_Mutation_Response = {
  __typename?: 'audit_vendor_history_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Vendor_History_View>;
};

/** Ordering options when selecting data from "audit.vendor_history_view". */
export type Audit_Vendor_History_View_Order_By = {
  action?: InputMaybe<Order_By>;
  action_timestamp?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Audit_Vendor_History_View_Prepend_Input = {
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "audit.vendor_history_view" */
export enum Audit_Vendor_History_View_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTimestamp = 'action_timestamp',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "audit.vendor_history_view" */
export type Audit_Vendor_History_View_Set_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "audit_vendor_history_view" */
export type Audit_Vendor_History_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Vendor_History_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Vendor_History_View_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Audit_Vendor_History_View_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Audit_Vendor_History_View_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Audit_Vendor_History_View_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Audit_Vendor_History_View_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Audit_Vendor_History_View_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Audit_Vendor_History_View_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Vendor_History_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Audit_Vendor_History_View_Bool_Exp;
};

/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviderRequests = {
  __typename?: 'authProviderRequests';
  id: Scalars['uuid']['output'];
  options?: Maybe<Scalars['jsonb']['output']>;
};

/** Oauth requests, inserted before redirecting to the provider's site. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviderRequestsOptionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate = {
  __typename?: 'authProviderRequests_aggregate';
  aggregate?: Maybe<AuthProviderRequests_Aggregate_Fields>;
  nodes: Array<AuthProviderRequests>;
};

/** aggregate fields of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate_Fields = {
  __typename?: 'authProviderRequests_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProviderRequests_Max_Fields>;
  min?: Maybe<AuthProviderRequests_Min_Fields>;
};

/** aggregate fields of "auth.provider_requests" */
export type AuthProviderRequests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthProviderRequests_Append_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "auth.provider_requests". All fields are combined with a logical 'AND'. */
export type AuthProviderRequests_Bool_Exp = {
  _and?: InputMaybe<Array<AuthProviderRequests_Bool_Exp>>;
  _not?: InputMaybe<AuthProviderRequests_Bool_Exp>;
  _or?: InputMaybe<Array<AuthProviderRequests_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  options?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.provider_requests" */
export enum AuthProviderRequests_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProviderRequestsPkey = 'provider_requests_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthProviderRequests_Delete_At_Path_Input = {
  options?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthProviderRequests_Delete_Elem_Input = {
  options?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthProviderRequests_Delete_Key_Input = {
  options?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.provider_requests" */
export type AuthProviderRequests_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type AuthProviderRequests_Max_Fields = {
  __typename?: 'authProviderRequests_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type AuthProviderRequests_Min_Fields = {
  __typename?: 'authProviderRequests_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "auth.provider_requests" */
export type AuthProviderRequests_Mutation_Response = {
  __typename?: 'authProviderRequests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviderRequests>;
};

/** on_conflict condition type for table "auth.provider_requests" */
export type AuthProviderRequests_On_Conflict = {
  constraint: AuthProviderRequests_Constraint;
  update_columns?: Array<AuthProviderRequests_Update_Column>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.provider_requests". */
export type AuthProviderRequests_Order_By = {
  id?: InputMaybe<Order_By>;
  options?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.provider_requests */
export type AuthProviderRequests_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthProviderRequests_Prepend_Input = {
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.provider_requests" */
export enum AuthProviderRequests_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options',
}

/** input type for updating data in table "auth.provider_requests" */
export type AuthProviderRequests_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "authProviderRequests" */
export type AuthProviderRequests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthProviderRequests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProviderRequests_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  options?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "auth.provider_requests" */
export enum AuthProviderRequests_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options',
}

export type AuthProviderRequests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthProviderRequests_Bool_Exp;
};

/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProviders = {
  __typename?: 'authProviders';
  id: Scalars['String']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProviders_Aggregate;
};

/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProvidersUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** List of available Oauth providers. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthProvidersUserProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** aggregated selection of "auth.providers" */
export type AuthProviders_Aggregate = {
  __typename?: 'authProviders_aggregate';
  aggregate?: Maybe<AuthProviders_Aggregate_Fields>;
  nodes: Array<AuthProviders>;
};

/** aggregate fields of "auth.providers" */
export type AuthProviders_Aggregate_Fields = {
  __typename?: 'authProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthProviders_Max_Fields>;
  min?: Maybe<AuthProviders_Min_Fields>;
};

/** aggregate fields of "auth.providers" */
export type AuthProviders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type AuthProviders_Bool_Exp = {
  _and?: InputMaybe<Array<AuthProviders_Bool_Exp>>;
  _not?: InputMaybe<AuthProviders_Bool_Exp>;
  _or?: InputMaybe<Array<AuthProviders_Bool_Exp>>;
  id?: InputMaybe<String_Comparison_Exp>;
  userProviders?: InputMaybe<AuthUserProviders_Bool_Exp>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum AuthProviders_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey',
}

/** input type for inserting data into table "auth.providers" */
export type AuthProviders_Insert_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
  userProviders?: InputMaybe<AuthUserProviders_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type AuthProviders_Max_Fields = {
  __typename?: 'authProviders_max_fields';
  id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthProviders_Min_Fields = {
  __typename?: 'authProviders_min_fields';
  id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.providers" */
export type AuthProviders_Mutation_Response = {
  __typename?: 'authProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthProviders>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type AuthProviders_Obj_Rel_Insert_Input = {
  data: AuthProviders_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};

/** on_conflict condition type for table "auth.providers" */
export type AuthProviders_On_Conflict = {
  constraint: AuthProviders_Constraint;
  update_columns?: Array<AuthProviders_Update_Column>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.providers". */
export type AuthProviders_Order_By = {
  id?: InputMaybe<Order_By>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.providers */
export type AuthProviders_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "auth.providers" */
export enum AuthProviders_Select_Column {
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "auth.providers" */
export type AuthProviders_Set_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authProviders" */
export type AuthProviders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthProviders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthProviders_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.providers" */
export enum AuthProviders_Update_Column {
  /** column name */
  Id = 'id',
}

export type AuthProviders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthProviders_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthProviders_Bool_Exp;
};

/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes = {
  __typename?: 'authRefreshTokenTypes';
  comment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokens_Aggregate;
  /** An array relationship */
  refresh_tokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refresh_tokens_aggregate: AuthRefreshTokens_Aggregate;
  value: Scalars['String']['output'];
};

/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefreshTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** columns and relationships of "auth.refresh_token_types" */
export type AuthRefreshTokenTypesRefresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** aggregated selection of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate = {
  __typename?: 'authRefreshTokenTypes_aggregate';
  aggregate?: Maybe<AuthRefreshTokenTypes_Aggregate_Fields>;
  nodes: Array<AuthRefreshTokenTypes>;
};

/** aggregate fields of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate_Fields = {
  __typename?: 'authRefreshTokenTypes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokenTypes_Max_Fields>;
  min?: Maybe<AuthRefreshTokenTypes_Min_Fields>;
};

/** aggregate fields of "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.refresh_token_types". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokenTypes_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRefreshTokenTypes_Bool_Exp>>;
  _not?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRefreshTokenTypes_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp>;
  refresh_tokens?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  refresh_tokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Constraint {
  /** unique or primary key constraint on columns "value" */
  RefreshTokenTypesPkey = 'refresh_token_types_pkey',
}

export enum AuthRefreshTokenTypes_Enum {
  /** Personal access token */
  Pat = 'pat',
  /** Regular refresh token */
  Regular = 'regular',
}

/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
export type AuthRefreshTokenTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  _in?: InputMaybe<Array<AuthRefreshTokenTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  _nin?: InputMaybe<Array<AuthRefreshTokenTypes_Enum>>;
};

/** input type for inserting data into table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Arr_Rel_Insert_Input>;
  refresh_tokens?: InputMaybe<AuthRefreshTokens_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthRefreshTokenTypes_Max_Fields = {
  __typename?: 'authRefreshTokenTypes_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthRefreshTokenTypes_Min_Fields = {
  __typename?: 'authRefreshTokenTypes_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Mutation_Response = {
  __typename?: 'authRefreshTokenTypes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokenTypes>;
};

/** input type for inserting object relation for remote table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Obj_Rel_Insert_Input = {
  data: AuthRefreshTokenTypes_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRefreshTokenTypes_On_Conflict>;
};

/** on_conflict condition type for table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_On_Conflict = {
  constraint: AuthRefreshTokenTypes_Constraint;
  update_columns?: Array<AuthRefreshTokenTypes_Update_Column>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_token_types". */
export type AuthRefreshTokenTypes_Order_By = {
  comment?: InputMaybe<Order_By>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Order_By>;
  refresh_tokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.refresh_token_types */
export type AuthRefreshTokenTypes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "auth.refresh_token_types" */
export type AuthRefreshTokenTypes_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authRefreshTokenTypes" */
export type AuthRefreshTokenTypes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokenTypes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokenTypes_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.refresh_token_types" */
export enum AuthRefreshTokenTypes_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type AuthRefreshTokenTypes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokenTypes_Bool_Exp;
};

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokens = {
  __typename?: 'authRefreshTokens';
  createdAt: Scalars['timestamptz']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  refresh_token_type: AuthRefreshTokenTypes;
  type: AuthRefreshTokenTypes_Enum;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate = {
  __typename?: 'authRefreshTokens_aggregate';
  aggregate?: Maybe<AuthRefreshTokens_Aggregate_Fields>;
  nodes: Array<AuthRefreshTokens>;
};

export type AuthRefreshTokens_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp_Count>;
};

export type AuthRefreshTokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_Fields = {
  __typename?: 'authRefreshTokens_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRefreshTokens_Max_Fields>;
  min?: Maybe<AuthRefreshTokens_Min_Fields>;
};

/** aggregate fields of "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthRefreshTokens_Max_Order_By>;
  min?: InputMaybe<AuthRefreshTokens_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AuthRefreshTokens_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type AuthRefreshTokens_Arr_Rel_Insert_Input = {
  data: Array<AuthRefreshTokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokens_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRefreshTokens_Bool_Exp>>;
  _not?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRefreshTokens_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  refreshTokenHash?: InputMaybe<String_Comparison_Exp>;
  refresh_token_type?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  RefreshTokensPkey = 'refresh_tokens_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AuthRefreshTokens_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AuthRefreshTokens_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AuthRefreshTokens_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.refresh_tokens" */
export type AuthRefreshTokens_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  refresh_token_type?: InputMaybe<AuthRefreshTokenTypes_Obj_Rel_Insert_Input>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthRefreshTokens_Max_Fields = {
  __typename?: 'authRefreshTokens_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthRefreshTokens_Min_Fields = {
  __typename?: 'authRefreshTokens_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  expiresAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  refreshTokenHash?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type AuthRefreshTokens_Mutation_Response = {
  __typename?: 'authRefreshTokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokens>;
};

/** on_conflict condition type for table "auth.refresh_tokens" */
export type AuthRefreshTokens_On_Conflict = {
  constraint: AuthRefreshTokens_Constraint;
  update_columns?: Array<AuthRefreshTokens_Update_Column>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type AuthRefreshTokens_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  refreshTokenHash?: InputMaybe<Order_By>;
  refresh_token_type?: InputMaybe<AuthRefreshTokenTypes_Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.refresh_tokens */
export type AuthRefreshTokens_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AuthRefreshTokens_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "auth.refresh_tokens" */
export type AuthRefreshTokens_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authRefreshTokens" */
export type AuthRefreshTokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokens_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  refreshTokenHash?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RefreshTokenHash = 'refreshTokenHash',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId',
}

export type AuthRefreshTokens_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRefreshTokens_Bool_Exp;
};

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRoles = {
  __typename?: 'authRoles';
  role: Scalars['String']['output'];
  /** An array relationship */
  userRoles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  userRoles_aggregate: AuthUserRoles_Aggregate;
  /** An array relationship */
  usersByDefaultRole: Array<Users>;
  /** An aggregate relationship */
  usersByDefaultRole_aggregate: Users_Aggregate;
};

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUserRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUsersByDefaultRoleArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Persistent Hasura roles for users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRolesUsersByDefaultRole_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "auth.roles" */
export type AuthRoles_Aggregate = {
  __typename?: 'authRoles_aggregate';
  aggregate?: Maybe<AuthRoles_Aggregate_Fields>;
  nodes: Array<AuthRoles>;
};

/** aggregate fields of "auth.roles" */
export type AuthRoles_Aggregate_Fields = {
  __typename?: 'authRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthRoles_Max_Fields>;
  min?: Maybe<AuthRoles_Min_Fields>;
};

/** aggregate fields of "auth.roles" */
export type AuthRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type AuthRoles_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRoles_Bool_Exp>>;
  _not?: InputMaybe<AuthRoles_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRoles_Bool_Exp>>;
  role?: InputMaybe<String_Comparison_Exp>;
  userRoles?: InputMaybe<AuthUserRoles_Bool_Exp>;
  userRoles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp>;
  usersByDefaultRole?: InputMaybe<Users_Bool_Exp>;
  usersByDefaultRole_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum AuthRoles_Constraint {
  /** unique or primary key constraint on columns "role" */
  RolesPkey = 'roles_pkey',
}

/** input type for inserting data into table "auth.roles" */
export type AuthRoles_Insert_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
  userRoles?: InputMaybe<AuthUserRoles_Arr_Rel_Insert_Input>;
  usersByDefaultRole?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type AuthRoles_Max_Fields = {
  __typename?: 'authRoles_max_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthRoles_Min_Fields = {
  __typename?: 'authRoles_min_fields';
  role?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.roles" */
export type AuthRoles_Mutation_Response = {
  __typename?: 'authRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRoles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type AuthRoles_Obj_Rel_Insert_Input = {
  data: AuthRoles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};

/** on_conflict condition type for table "auth.roles" */
export type AuthRoles_On_Conflict = {
  constraint: AuthRoles_Constraint;
  update_columns?: Array<AuthRoles_Update_Column>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.roles". */
export type AuthRoles_Order_By = {
  role?: InputMaybe<Order_By>;
  userRoles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Order_By>;
  usersByDefaultRole_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.roles */
export type AuthRoles_Pk_Columns_Input = {
  role: Scalars['String']['input'];
};

/** select columns of table "auth.roles" */
export enum AuthRoles_Select_Column {
  /** column name */
  Role = 'role',
}

/** input type for updating data in table "auth.roles" */
export type AuthRoles_Set_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "authRoles" */
export type AuthRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRoles_Stream_Cursor_Value_Input = {
  role?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.roles" */
export enum AuthRoles_Update_Column {
  /** column name */
  Role = 'role',
}

export type AuthRoles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthRoles_Bool_Exp;
};

/** Active providers for a given user. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserProviders = {
  __typename?: 'authUserProviders';
  accessToken: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  provider: AuthProviders;
  providerId: Scalars['String']['output'];
  providerUserId: Scalars['String']['output'];
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_providers" */
export type AuthUserProviders_Aggregate = {
  __typename?: 'authUserProviders_aggregate';
  aggregate?: Maybe<AuthUserProviders_Aggregate_Fields>;
  nodes: Array<AuthUserProviders>;
};

export type AuthUserProviders_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp_Count>;
};

export type AuthUserProviders_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserProviders_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_providers" */
export type AuthUserProviders_Aggregate_Fields = {
  __typename?: 'authUserProviders_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserProviders_Max_Fields>;
  min?: Maybe<AuthUserProviders_Min_Fields>;
};

/** aggregate fields of "auth.user_providers" */
export type AuthUserProviders_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_providers" */
export type AuthUserProviders_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserProviders_Max_Order_By>;
  min?: InputMaybe<AuthUserProviders_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_providers" */
export type AuthUserProviders_Arr_Rel_Insert_Input = {
  data: Array<AuthUserProviders_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.user_providers". All fields are combined with a logical 'AND'. */
export type AuthUserProviders_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserProviders_Bool_Exp>>;
  _not?: InputMaybe<AuthUserProviders_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserProviders_Bool_Exp>>;
  accessToken?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<AuthProviders_Bool_Exp>;
  providerId?: InputMaybe<String_Comparison_Exp>;
  providerUserId?: InputMaybe<String_Comparison_Exp>;
  refreshToken?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_providers" */
export enum AuthUserProviders_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserProvidersPkey = 'user_providers_pkey',
  /** unique or primary key constraint on columns "provider_user_id", "provider_id" */
  UserProvidersProviderIdProviderUserIdKey = 'user_providers_provider_id_provider_user_id_key',
  /** unique or primary key constraint on columns "user_id", "provider_id" */
  UserProvidersUserIdProviderIdKey = 'user_providers_user_id_provider_id_key',
}

/** input type for inserting data into table "auth.user_providers" */
export type AuthUserProviders_Insert_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  provider?: InputMaybe<AuthProviders_Obj_Rel_Insert_Input>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserProviders_Max_Fields = {
  __typename?: 'authUserProviders_max_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_providers" */
export type AuthUserProviders_Max_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserProviders_Min_Fields = {
  __typename?: 'authUserProviders_min_fields';
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  providerId?: Maybe<Scalars['String']['output']>;
  providerUserId?: Maybe<Scalars['String']['output']>;
  refreshToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_providers" */
export type AuthUserProviders_Min_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_providers" */
export type AuthUserProviders_Mutation_Response = {
  __typename?: 'authUserProviders_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserProviders>;
};

/** on_conflict condition type for table "auth.user_providers" */
export type AuthUserProviders_On_Conflict = {
  constraint: AuthUserProviders_Constraint;
  update_columns?: Array<AuthUserProviders_Update_Column>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_providers". */
export type AuthUserProviders_Order_By = {
  accessToken?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<AuthProviders_Order_By>;
  providerId?: InputMaybe<Order_By>;
  providerUserId?: InputMaybe<Order_By>;
  refreshToken?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_providers */
export type AuthUserProviders_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_providers" */
export enum AuthUserProviders_Select_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "auth.user_providers" */
export type AuthUserProviders_Set_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authUserProviders" */
export type AuthUserProviders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserProviders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserProviders_Stream_Cursor_Value_Input = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  providerUserId?: InputMaybe<Scalars['String']['input']>;
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user_providers" */
export enum AuthUserProviders_Update_Column {
  /** column name */
  AccessToken = 'accessToken',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  ProviderId = 'providerId',
  /** column name */
  ProviderUserId = 'providerUserId',
  /** column name */
  RefreshToken = 'refreshToken',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
}

export type AuthUserProviders_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserProviders_Bool_Exp;
};

/** Roles of users. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserRoles = {
  __typename?: 'authUserRoles';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  role: Scalars['String']['output'];
  /** An object relationship */
  roleByRole: AuthRoles;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_roles" */
export type AuthUserRoles_Aggregate = {
  __typename?: 'authUserRoles_aggregate';
  aggregate?: Maybe<AuthUserRoles_Aggregate_Fields>;
  nodes: Array<AuthUserRoles>;
};

export type AuthUserRoles_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp_Count>;
};

export type AuthUserRoles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserRoles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_roles" */
export type AuthUserRoles_Aggregate_Fields = {
  __typename?: 'authUserRoles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserRoles_Max_Fields>;
  min?: Maybe<AuthUserRoles_Min_Fields>;
};

/** aggregate fields of "auth.user_roles" */
export type AuthUserRoles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_roles" */
export type AuthUserRoles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserRoles_Max_Order_By>;
  min?: InputMaybe<AuthUserRoles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_roles" */
export type AuthUserRoles_Arr_Rel_Insert_Input = {
  data: Array<AuthUserRoles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.user_roles". All fields are combined with a logical 'AND'. */
export type AuthUserRoles_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserRoles_Bool_Exp>>;
  _not?: InputMaybe<AuthUserRoles_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserRoles_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  roleByRole?: InputMaybe<AuthRoles_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_roles" */
export enum AuthUserRoles_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  UserRolesUserIdRoleKey = 'user_roles_user_id_role_key',
}

/** input type for inserting data into table "auth.user_roles" */
export type AuthUserRoles_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  roleByRole?: InputMaybe<AuthRoles_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserRoles_Max_Fields = {
  __typename?: 'authUserRoles_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_roles" */
export type AuthUserRoles_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserRoles_Min_Fields = {
  __typename?: 'authUserRoles_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_roles" */
export type AuthUserRoles_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_roles" */
export type AuthUserRoles_Mutation_Response = {
  __typename?: 'authUserRoles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserRoles>;
};

/** on_conflict condition type for table "auth.user_roles" */
export type AuthUserRoles_On_Conflict = {
  constraint: AuthUserRoles_Constraint;
  update_columns?: Array<AuthUserRoles_Update_Column>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_roles". */
export type AuthUserRoles_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<AuthRoles_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_roles */
export type AuthUserRoles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_roles" */
export enum AuthUserRoles_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "auth.user_roles" */
export type AuthUserRoles_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "authUserRoles" */
export type AuthUserRoles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserRoles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserRoles_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "auth.user_roles" */
export enum AuthUserRoles_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId',
}

export type AuthUserRoles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserRoles_Bool_Exp;
};

/** User webauthn security keys. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthUserSecurityKeys = {
  __typename?: 'authUserSecurityKeys';
  counter: Scalars['bigint']['output'];
  credentialId: Scalars['String']['output'];
  credentialPublicKey?: Maybe<Scalars['bytea']['output']>;
  id: Scalars['uuid']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  transports: Scalars['String']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** aggregated selection of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate = {
  __typename?: 'authUserSecurityKeys_aggregate';
  aggregate?: Maybe<AuthUserSecurityKeys_Aggregate_Fields>;
  nodes: Array<AuthUserSecurityKeys>;
};

export type AuthUserSecurityKeys_Aggregate_Bool_Exp = {
  count?: InputMaybe<AuthUserSecurityKeys_Aggregate_Bool_Exp_Count>;
};

export type AuthUserSecurityKeys_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_Fields = {
  __typename?: 'authUserSecurityKeys_aggregate_fields';
  avg?: Maybe<AuthUserSecurityKeys_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserSecurityKeys_Max_Fields>;
  min?: Maybe<AuthUserSecurityKeys_Min_Fields>;
  stddev?: Maybe<AuthUserSecurityKeys_Stddev_Fields>;
  stddev_pop?: Maybe<AuthUserSecurityKeys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<AuthUserSecurityKeys_Stddev_Samp_Fields>;
  sum?: Maybe<AuthUserSecurityKeys_Sum_Fields>;
  var_pop?: Maybe<AuthUserSecurityKeys_Var_Pop_Fields>;
  var_samp?: Maybe<AuthUserSecurityKeys_Var_Samp_Fields>;
  variance?: Maybe<AuthUserSecurityKeys_Variance_Fields>;
};

/** aggregate fields of "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Aggregate_Order_By = {
  avg?: InputMaybe<AuthUserSecurityKeys_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthUserSecurityKeys_Max_Order_By>;
  min?: InputMaybe<AuthUserSecurityKeys_Min_Order_By>;
  stddev?: InputMaybe<AuthUserSecurityKeys_Stddev_Order_By>;
  stddev_pop?: InputMaybe<AuthUserSecurityKeys_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<AuthUserSecurityKeys_Stddev_Samp_Order_By>;
  sum?: InputMaybe<AuthUserSecurityKeys_Sum_Order_By>;
  var_pop?: InputMaybe<AuthUserSecurityKeys_Var_Pop_Order_By>;
  var_samp?: InputMaybe<AuthUserSecurityKeys_Var_Samp_Order_By>;
  variance?: InputMaybe<AuthUserSecurityKeys_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Arr_Rel_Insert_Input = {
  data: Array<AuthUserSecurityKeys_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};

/** aggregate avg on columns */
export type AuthUserSecurityKeys_Avg_Fields = {
  __typename?: 'authUserSecurityKeys_avg_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Avg_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth.user_security_keys". All fields are combined with a logical 'AND'. */
export type AuthUserSecurityKeys_Bool_Exp = {
  _and?: InputMaybe<Array<AuthUserSecurityKeys_Bool_Exp>>;
  _not?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  _or?: InputMaybe<Array<AuthUserSecurityKeys_Bool_Exp>>;
  counter?: InputMaybe<Bigint_Comparison_Exp>;
  credentialId?: InputMaybe<String_Comparison_Exp>;
  credentialPublicKey?: InputMaybe<Bytea_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  transports?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Constraint {
  /** unique or primary key constraint on columns "credential_id" */
  UserSecurityKeyCredentialIdKey = 'user_security_key_credential_id_key',
  /** unique or primary key constraint on columns "id" */
  UserSecurityKeysPkey = 'user_security_keys_pkey',
}

/** input type for incrementing numeric columns in table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Inc_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Insert_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AuthUserSecurityKeys_Max_Fields = {
  __typename?: 'authUserSecurityKeys_max_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Max_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type AuthUserSecurityKeys_Min_Fields = {
  __typename?: 'authUserSecurityKeys_min_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
  credentialId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  transports?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Min_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Mutation_Response = {
  __typename?: 'authUserSecurityKeys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserSecurityKeys>;
};

/** on_conflict condition type for table "auth.user_security_keys" */
export type AuthUserSecurityKeys_On_Conflict = {
  constraint: AuthUserSecurityKeys_Constraint;
  update_columns?: Array<AuthUserSecurityKeys_Update_Column>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.user_security_keys". */
export type AuthUserSecurityKeys_Order_By = {
  counter?: InputMaybe<Order_By>;
  credentialId?: InputMaybe<Order_By>;
  credentialPublicKey?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  transports?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.user_security_keys */
export type AuthUserSecurityKeys_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Select_Column {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId',
}

/** input type for updating data in table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Set_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type AuthUserSecurityKeys_Stddev_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type AuthUserSecurityKeys_Stddev_Pop_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Pop_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type AuthUserSecurityKeys_Stddev_Samp_Fields = {
  __typename?: 'authUserSecurityKeys_stddev_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Stddev_Samp_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "authUserSecurityKeys" */
export type AuthUserSecurityKeys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthUserSecurityKeys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserSecurityKeys_Stream_Cursor_Value_Input = {
  counter?: InputMaybe<Scalars['bigint']['input']>;
  credentialId?: InputMaybe<Scalars['String']['input']>;
  credentialPublicKey?: InputMaybe<Scalars['bytea']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  transports?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type AuthUserSecurityKeys_Sum_Fields = {
  __typename?: 'authUserSecurityKeys_sum_fields';
  counter?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Sum_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** update columns of table "auth.user_security_keys" */
export enum AuthUserSecurityKeys_Update_Column {
  /** column name */
  Counter = 'counter',
  /** column name */
  CredentialId = 'credentialId',
  /** column name */
  CredentialPublicKey = 'credentialPublicKey',
  /** column name */
  Id = 'id',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  Transports = 'transports',
  /** column name */
  UserId = 'userId',
}

export type AuthUserSecurityKeys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  /** filter the rows which have to be updated */
  where: AuthUserSecurityKeys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type AuthUserSecurityKeys_Var_Pop_Fields = {
  __typename?: 'authUserSecurityKeys_var_pop_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Var_Pop_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type AuthUserSecurityKeys_Var_Samp_Fields = {
  __typename?: 'authUserSecurityKeys_var_samp_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Var_Samp_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type AuthUserSecurityKeys_Variance_Fields = {
  __typename?: 'authUserSecurityKeys_variance_fields';
  counter?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "auth.user_security_keys" */
export type AuthUserSecurityKeys_Variance_Order_By = {
  counter?: InputMaybe<Order_By>;
};

/** columns and relationships of "auth.jackson_index" */
export type Auth_Jackson_Index = {
  __typename?: 'auth_jackson_index';
  id: Scalars['Int']['output'];
  /** An object relationship */
  jackson_store: Auth_Jackson_Store;
  key: Scalars['String']['output'];
  storeKey: Scalars['String']['output'];
};

/** aggregated selection of "auth.jackson_index" */
export type Auth_Jackson_Index_Aggregate = {
  __typename?: 'auth_jackson_index_aggregate';
  aggregate?: Maybe<Auth_Jackson_Index_Aggregate_Fields>;
  nodes: Array<Auth_Jackson_Index>;
};

export type Auth_Jackson_Index_Aggregate_Bool_Exp = {
  count?: InputMaybe<Auth_Jackson_Index_Aggregate_Bool_Exp_Count>;
};

export type Auth_Jackson_Index_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Auth_Jackson_Index_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.jackson_index" */
export type Auth_Jackson_Index_Aggregate_Fields = {
  __typename?: 'auth_jackson_index_aggregate_fields';
  avg?: Maybe<Auth_Jackson_Index_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Jackson_Index_Max_Fields>;
  min?: Maybe<Auth_Jackson_Index_Min_Fields>;
  stddev?: Maybe<Auth_Jackson_Index_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Jackson_Index_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Jackson_Index_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Jackson_Index_Sum_Fields>;
  var_pop?: Maybe<Auth_Jackson_Index_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Jackson_Index_Var_Samp_Fields>;
  variance?: Maybe<Auth_Jackson_Index_Variance_Fields>;
};

/** aggregate fields of "auth.jackson_index" */
export type Auth_Jackson_Index_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Jackson_Index_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.jackson_index" */
export type Auth_Jackson_Index_Aggregate_Order_By = {
  avg?: InputMaybe<Auth_Jackson_Index_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Jackson_Index_Max_Order_By>;
  min?: InputMaybe<Auth_Jackson_Index_Min_Order_By>;
  stddev?: InputMaybe<Auth_Jackson_Index_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Auth_Jackson_Index_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Auth_Jackson_Index_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Auth_Jackson_Index_Sum_Order_By>;
  var_pop?: InputMaybe<Auth_Jackson_Index_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Auth_Jackson_Index_Var_Samp_Order_By>;
  variance?: InputMaybe<Auth_Jackson_Index_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "auth.jackson_index" */
export type Auth_Jackson_Index_Arr_Rel_Insert_Input = {
  data: Array<Auth_Jackson_Index_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Jackson_Index_On_Conflict>;
};

/** aggregate avg on columns */
export type Auth_Jackson_Index_Avg_Fields = {
  __typename?: 'auth_jackson_index_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "auth.jackson_index". All fields are combined with a logical 'AND'. */
export type Auth_Jackson_Index_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Jackson_Index_Bool_Exp>>;
  _not?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Jackson_Index_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  jackson_store?: InputMaybe<Auth_Jackson_Store_Bool_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  storeKey?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.jackson_index" */
export enum Auth_Jackson_Index_Constraint {
  /** unique or primary key constraint on columns "id" */
  PkA95aa83f01e3c73e126856b7820 = 'PK_a95aa83f01e3c73e126856b7820',
}

/** input type for incrementing numeric columns in table "auth.jackson_index" */
export type Auth_Jackson_Index_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "auth.jackson_index" */
export type Auth_Jackson_Index_Insert_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  jackson_store?: InputMaybe<Auth_Jackson_Store_Obj_Rel_Insert_Input>;
  key?: InputMaybe<Scalars['String']['input']>;
  storeKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Auth_Jackson_Index_Max_Fields = {
  __typename?: 'auth_jackson_index_max_fields';
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  storeKey?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  storeKey?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Jackson_Index_Min_Fields = {
  __typename?: 'auth_jackson_index_min_fields';
  id?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  storeKey?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  storeKey?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.jackson_index" */
export type Auth_Jackson_Index_Mutation_Response = {
  __typename?: 'auth_jackson_index_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Jackson_Index>;
};

/** on_conflict condition type for table "auth.jackson_index" */
export type Auth_Jackson_Index_On_Conflict = {
  constraint: Auth_Jackson_Index_Constraint;
  update_columns?: Array<Auth_Jackson_Index_Update_Column>;
  where?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.jackson_index". */
export type Auth_Jackson_Index_Order_By = {
  id?: InputMaybe<Order_By>;
  jackson_store?: InputMaybe<Auth_Jackson_Store_Order_By>;
  key?: InputMaybe<Order_By>;
  storeKey?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.jackson_index */
export type Auth_Jackson_Index_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "auth.jackson_index" */
export enum Auth_Jackson_Index_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  StoreKey = 'storeKey',
}

/** input type for updating data in table "auth.jackson_index" */
export type Auth_Jackson_Index_Set_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  storeKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Auth_Jackson_Index_Stddev_Fields = {
  __typename?: 'auth_jackson_index_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Auth_Jackson_Index_Stddev_Pop_Fields = {
  __typename?: 'auth_jackson_index_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Auth_Jackson_Index_Stddev_Samp_Fields = {
  __typename?: 'auth_jackson_index_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "auth_jackson_index" */
export type Auth_Jackson_Index_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Jackson_Index_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Jackson_Index_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  storeKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Auth_Jackson_Index_Sum_Fields = {
  __typename?: 'auth_jackson_index_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "auth.jackson_index" */
export enum Auth_Jackson_Index_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  StoreKey = 'storeKey',
}

export type Auth_Jackson_Index_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Jackson_Index_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Jackson_Index_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Jackson_Index_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Jackson_Index_Var_Pop_Fields = {
  __typename?: 'auth_jackson_index_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Auth_Jackson_Index_Var_Samp_Fields = {
  __typename?: 'auth_jackson_index_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Auth_Jackson_Index_Variance_Fields = {
  __typename?: 'auth_jackson_index_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "auth.jackson_index" */
export type Auth_Jackson_Index_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "auth.jackson_store" */
export type Auth_Jackson_Store = {
  __typename?: 'auth_jackson_store';
  createdAt: Scalars['timestamp']['output'];
  iv?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  jackson_indices: Array<Auth_Jackson_Index>;
  /** An aggregate relationship */
  jackson_indices_aggregate: Auth_Jackson_Index_Aggregate;
  key: Scalars['String']['output'];
  modifiedAt?: Maybe<Scalars['timestamp']['output']>;
  namespace?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
};

/** columns and relationships of "auth.jackson_store" */
export type Auth_Jackson_StoreJackson_IndicesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Index_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
};

/** columns and relationships of "auth.jackson_store" */
export type Auth_Jackson_StoreJackson_Indices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Index_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
};

/** aggregated selection of "auth.jackson_store" */
export type Auth_Jackson_Store_Aggregate = {
  __typename?: 'auth_jackson_store_aggregate';
  aggregate?: Maybe<Auth_Jackson_Store_Aggregate_Fields>;
  nodes: Array<Auth_Jackson_Store>;
};

/** aggregate fields of "auth.jackson_store" */
export type Auth_Jackson_Store_Aggregate_Fields = {
  __typename?: 'auth_jackson_store_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Jackson_Store_Max_Fields>;
  min?: Maybe<Auth_Jackson_Store_Min_Fields>;
};

/** aggregate fields of "auth.jackson_store" */
export type Auth_Jackson_Store_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Jackson_Store_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.jackson_store". All fields are combined with a logical 'AND'. */
export type Auth_Jackson_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Jackson_Store_Bool_Exp>>;
  _not?: InputMaybe<Auth_Jackson_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Jackson_Store_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  iv?: InputMaybe<String_Comparison_Exp>;
  jackson_indices?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
  jackson_indices_aggregate?: InputMaybe<Auth_Jackson_Index_Aggregate_Bool_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  modifiedAt?: InputMaybe<Timestamp_Comparison_Exp>;
  namespace?: InputMaybe<String_Comparison_Exp>;
  tag?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.jackson_store" */
export enum Auth_Jackson_Store_Constraint {
  /** unique or primary key constraint on columns "key" */
  Pk_87b6fc1475fbd1228d2f53c6f4a = 'PK_87b6fc1475fbd1228d2f53c6f4a',
}

/** input type for inserting data into table "auth.jackson_store" */
export type Auth_Jackson_Store_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  iv?: InputMaybe<Scalars['String']['input']>;
  jackson_indices?: InputMaybe<Auth_Jackson_Index_Arr_Rel_Insert_Input>;
  key?: InputMaybe<Scalars['String']['input']>;
  modifiedAt?: InputMaybe<Scalars['timestamp']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Auth_Jackson_Store_Max_Fields = {
  __typename?: 'auth_jackson_store_max_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  iv?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['timestamp']['output']>;
  namespace?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Auth_Jackson_Store_Min_Fields = {
  __typename?: 'auth_jackson_store_min_fields';
  createdAt?: Maybe<Scalars['timestamp']['output']>;
  iv?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  modifiedAt?: Maybe<Scalars['timestamp']['output']>;
  namespace?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.jackson_store" */
export type Auth_Jackson_Store_Mutation_Response = {
  __typename?: 'auth_jackson_store_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Jackson_Store>;
};

/** input type for inserting object relation for remote table "auth.jackson_store" */
export type Auth_Jackson_Store_Obj_Rel_Insert_Input = {
  data: Auth_Jackson_Store_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Auth_Jackson_Store_On_Conflict>;
};

/** on_conflict condition type for table "auth.jackson_store" */
export type Auth_Jackson_Store_On_Conflict = {
  constraint: Auth_Jackson_Store_Constraint;
  update_columns?: Array<Auth_Jackson_Store_Update_Column>;
  where?: InputMaybe<Auth_Jackson_Store_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.jackson_store". */
export type Auth_Jackson_Store_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  iv?: InputMaybe<Order_By>;
  jackson_indices_aggregate?: InputMaybe<Auth_Jackson_Index_Aggregate_Order_By>;
  key?: InputMaybe<Order_By>;
  modifiedAt?: InputMaybe<Order_By>;
  namespace?: InputMaybe<Order_By>;
  tag?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.jackson_store */
export type Auth_Jackson_Store_Pk_Columns_Input = {
  key: Scalars['String']['input'];
};

/** select columns of table "auth.jackson_store" */
export enum Auth_Jackson_Store_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Iv = 'iv',
  /** column name */
  Key = 'key',
  /** column name */
  ModifiedAt = 'modifiedAt',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Tag = 'tag',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "auth.jackson_store" */
export type Auth_Jackson_Store_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  iv?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  modifiedAt?: InputMaybe<Scalars['timestamp']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_jackson_store" */
export type Auth_Jackson_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Jackson_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Jackson_Store_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  iv?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  modifiedAt?: InputMaybe<Scalars['timestamp']['input']>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.jackson_store" */
export enum Auth_Jackson_Store_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Iv = 'iv',
  /** column name */
  Key = 'key',
  /** column name */
  ModifiedAt = 'modifiedAt',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  Tag = 'tag',
  /** column name */
  Value = 'value',
}

export type Auth_Jackson_Store_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Jackson_Store_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Jackson_Store_Bool_Exp;
};

/** columns and relationships of "auth.jackson_ttl" */
export type Auth_Jackson_Ttl = {
  __typename?: 'auth_jackson_ttl';
  expiresAt: Scalars['bigint']['output'];
  key: Scalars['String']['output'];
};

/** aggregated selection of "auth.jackson_ttl" */
export type Auth_Jackson_Ttl_Aggregate = {
  __typename?: 'auth_jackson_ttl_aggregate';
  aggregate?: Maybe<Auth_Jackson_Ttl_Aggregate_Fields>;
  nodes: Array<Auth_Jackson_Ttl>;
};

/** aggregate fields of "auth.jackson_ttl" */
export type Auth_Jackson_Ttl_Aggregate_Fields = {
  __typename?: 'auth_jackson_ttl_aggregate_fields';
  avg?: Maybe<Auth_Jackson_Ttl_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Jackson_Ttl_Max_Fields>;
  min?: Maybe<Auth_Jackson_Ttl_Min_Fields>;
  stddev?: Maybe<Auth_Jackson_Ttl_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Jackson_Ttl_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Jackson_Ttl_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Jackson_Ttl_Sum_Fields>;
  var_pop?: Maybe<Auth_Jackson_Ttl_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Jackson_Ttl_Var_Samp_Fields>;
  variance?: Maybe<Auth_Jackson_Ttl_Variance_Fields>;
};

/** aggregate fields of "auth.jackson_ttl" */
export type Auth_Jackson_Ttl_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Jackson_Ttl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Auth_Jackson_Ttl_Avg_Fields = {
  __typename?: 'auth_jackson_ttl_avg_fields';
  expiresAt?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "auth.jackson_ttl". All fields are combined with a logical 'AND'. */
export type Auth_Jackson_Ttl_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Jackson_Ttl_Bool_Exp>>;
  _not?: InputMaybe<Auth_Jackson_Ttl_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Jackson_Ttl_Bool_Exp>>;
  expiresAt?: InputMaybe<Bigint_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.jackson_ttl" */
export enum Auth_Jackson_Ttl_Constraint {
  /** unique or primary key constraint on columns "key" */
  Pk_7c9bcdfb4d82e873e19935ec806 = 'PK_7c9bcdfb4d82e873e19935ec806',
}

/** input type for incrementing numeric columns in table "auth.jackson_ttl" */
export type Auth_Jackson_Ttl_Inc_Input = {
  expiresAt?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "auth.jackson_ttl" */
export type Auth_Jackson_Ttl_Insert_Input = {
  expiresAt?: InputMaybe<Scalars['bigint']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Auth_Jackson_Ttl_Max_Fields = {
  __typename?: 'auth_jackson_ttl_max_fields';
  expiresAt?: Maybe<Scalars['bigint']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Auth_Jackson_Ttl_Min_Fields = {
  __typename?: 'auth_jackson_ttl_min_fields';
  expiresAt?: Maybe<Scalars['bigint']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.jackson_ttl" */
export type Auth_Jackson_Ttl_Mutation_Response = {
  __typename?: 'auth_jackson_ttl_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Jackson_Ttl>;
};

/** on_conflict condition type for table "auth.jackson_ttl" */
export type Auth_Jackson_Ttl_On_Conflict = {
  constraint: Auth_Jackson_Ttl_Constraint;
  update_columns?: Array<Auth_Jackson_Ttl_Update_Column>;
  where?: InputMaybe<Auth_Jackson_Ttl_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.jackson_ttl". */
export type Auth_Jackson_Ttl_Order_By = {
  expiresAt?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.jackson_ttl */
export type Auth_Jackson_Ttl_Pk_Columns_Input = {
  key: Scalars['String']['input'];
};

/** select columns of table "auth.jackson_ttl" */
export enum Auth_Jackson_Ttl_Select_Column {
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Key = 'key',
}

/** input type for updating data in table "auth.jackson_ttl" */
export type Auth_Jackson_Ttl_Set_Input = {
  expiresAt?: InputMaybe<Scalars['bigint']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Auth_Jackson_Ttl_Stddev_Fields = {
  __typename?: 'auth_jackson_ttl_stddev_fields';
  expiresAt?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Jackson_Ttl_Stddev_Pop_Fields = {
  __typename?: 'auth_jackson_ttl_stddev_pop_fields';
  expiresAt?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Jackson_Ttl_Stddev_Samp_Fields = {
  __typename?: 'auth_jackson_ttl_stddev_samp_fields';
  expiresAt?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_jackson_ttl" */
export type Auth_Jackson_Ttl_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Jackson_Ttl_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Jackson_Ttl_Stream_Cursor_Value_Input = {
  expiresAt?: InputMaybe<Scalars['bigint']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Auth_Jackson_Ttl_Sum_Fields = {
  __typename?: 'auth_jackson_ttl_sum_fields';
  expiresAt?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "auth.jackson_ttl" */
export enum Auth_Jackson_Ttl_Update_Column {
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Key = 'key',
}

export type Auth_Jackson_Ttl_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Jackson_Ttl_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Jackson_Ttl_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Jackson_Ttl_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Jackson_Ttl_Var_Pop_Fields = {
  __typename?: 'auth_jackson_ttl_var_pop_fields';
  expiresAt?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Auth_Jackson_Ttl_Var_Samp_Fields = {
  __typename?: 'auth_jackson_ttl_var_samp_fields';
  expiresAt?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Auth_Jackson_Ttl_Variance_Fields = {
  __typename?: 'auth_jackson_ttl_variance_fields';
  expiresAt?: Maybe<Scalars['Float']['output']>;
};

/** Internal table for tracking migrations. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type Auth_Migrations = {
  __typename?: 'auth_migrations';
  executed_at?: Maybe<Scalars['timestamp']['output']>;
  hash: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** aggregated selection of "auth.migrations" */
export type Auth_Migrations_Aggregate = {
  __typename?: 'auth_migrations_aggregate';
  aggregate?: Maybe<Auth_Migrations_Aggregate_Fields>;
  nodes: Array<Auth_Migrations>;
};

/** aggregate fields of "auth.migrations" */
export type Auth_Migrations_Aggregate_Fields = {
  __typename?: 'auth_migrations_aggregate_fields';
  avg?: Maybe<Auth_Migrations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Auth_Migrations_Max_Fields>;
  min?: Maybe<Auth_Migrations_Min_Fields>;
  stddev?: Maybe<Auth_Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Migrations_Sum_Fields>;
  var_pop?: Maybe<Auth_Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Migrations_Var_Samp_Fields>;
  variance?: Maybe<Auth_Migrations_Variance_Fields>;
};

/** aggregate fields of "auth.migrations" */
export type Auth_Migrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Auth_Migrations_Avg_Fields = {
  __typename?: 'auth_migrations_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "auth.migrations". All fields are combined with a logical 'AND'. */
export type Auth_Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<Auth_Migrations_Bool_Exp>>;
  _not?: InputMaybe<Auth_Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<Auth_Migrations_Bool_Exp>>;
  executed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.migrations" */
export enum Auth_Migrations_Constraint {
  /** unique or primary key constraint on columns "name" */
  MigrationsNameKey = 'migrations_name_key',
  /** unique or primary key constraint on columns "id" */
  MigrationsPkey = 'migrations_pkey',
}

/** input type for incrementing numeric columns in table "auth.migrations" */
export type Auth_Migrations_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "auth.migrations" */
export type Auth_Migrations_Insert_Input = {
  executed_at?: InputMaybe<Scalars['timestamp']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Auth_Migrations_Max_Fields = {
  __typename?: 'auth_migrations_max_fields';
  executed_at?: Maybe<Scalars['timestamp']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Auth_Migrations_Min_Fields = {
  __typename?: 'auth_migrations_min_fields';
  executed_at?: Maybe<Scalars['timestamp']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.migrations" */
export type Auth_Migrations_Mutation_Response = {
  __typename?: 'auth_migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Migrations>;
};

/** on_conflict condition type for table "auth.migrations" */
export type Auth_Migrations_On_Conflict = {
  constraint: Auth_Migrations_Constraint;
  update_columns?: Array<Auth_Migrations_Update_Column>;
  where?: InputMaybe<Auth_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.migrations". */
export type Auth_Migrations_Order_By = {
  executed_at?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: auth.migrations */
export type Auth_Migrations_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "auth.migrations" */
export enum Auth_Migrations_Select_Column {
  /** column name */
  ExecutedAt = 'executed_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "auth.migrations" */
export type Auth_Migrations_Set_Input = {
  executed_at?: InputMaybe<Scalars['timestamp']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Auth_Migrations_Stddev_Fields = {
  __typename?: 'auth_migrations_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Migrations_Stddev_Pop_Fields = {
  __typename?: 'auth_migrations_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Migrations_Stddev_Samp_Fields = {
  __typename?: 'auth_migrations_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "auth_migrations" */
export type Auth_Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Auth_Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Auth_Migrations_Stream_Cursor_Value_Input = {
  executed_at?: InputMaybe<Scalars['timestamp']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Auth_Migrations_Sum_Fields = {
  __typename?: 'auth_migrations_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "auth.migrations" */
export enum Auth_Migrations_Update_Column {
  /** column name */
  ExecutedAt = 'executed_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type Auth_Migrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Auth_Migrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Auth_Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Auth_Migrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Auth_Migrations_Var_Pop_Fields = {
  __typename?: 'auth_migrations_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Auth_Migrations_Var_Samp_Fields = {
  __typename?: 'auth_migrations_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Auth_Migrations_Variance_Fields = {
  __typename?: 'auth_migrations_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "storage.buckets" */
export type Buckets = {
  __typename?: 'buckets';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  downloadExpiration: Scalars['Int']['output'];
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  id: Scalars['String']['output'];
  maxUploadFileSize: Scalars['Int']['output'];
  minUploadFileSize: Scalars['Int']['output'];
  presignedUrlsEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** columns and relationships of "storage.buckets" */
export type BucketsFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** columns and relationships of "storage.buckets" */
export type BucketsFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** aggregated selection of "storage.buckets" */
export type Buckets_Aggregate = {
  __typename?: 'buckets_aggregate';
  aggregate?: Maybe<Buckets_Aggregate_Fields>;
  nodes: Array<Buckets>;
};

/** aggregate fields of "storage.buckets" */
export type Buckets_Aggregate_Fields = {
  __typename?: 'buckets_aggregate_fields';
  avg?: Maybe<Buckets_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Buckets_Max_Fields>;
  min?: Maybe<Buckets_Min_Fields>;
  stddev?: Maybe<Buckets_Stddev_Fields>;
  stddev_pop?: Maybe<Buckets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Buckets_Stddev_Samp_Fields>;
  sum?: Maybe<Buckets_Sum_Fields>;
  var_pop?: Maybe<Buckets_Var_Pop_Fields>;
  var_samp?: Maybe<Buckets_Var_Samp_Fields>;
  variance?: Maybe<Buckets_Variance_Fields>;
};

/** aggregate fields of "storage.buckets" */
export type Buckets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Buckets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Buckets_Avg_Fields = {
  __typename?: 'buckets_avg_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "storage.buckets". All fields are combined with a logical 'AND'. */
export type Buckets_Bool_Exp = {
  _and?: InputMaybe<Array<Buckets_Bool_Exp>>;
  _not?: InputMaybe<Buckets_Bool_Exp>;
  _or?: InputMaybe<Array<Buckets_Bool_Exp>>;
  cacheControl?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  downloadExpiration?: InputMaybe<Int_Comparison_Exp>;
  files?: InputMaybe<Files_Bool_Exp>;
  files_aggregate?: InputMaybe<Files_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  maxUploadFileSize?: InputMaybe<Int_Comparison_Exp>;
  minUploadFileSize?: InputMaybe<Int_Comparison_Exp>;
  presignedUrlsEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "storage.buckets" */
export enum Buckets_Constraint {
  /** unique or primary key constraint on columns "id" */
  BucketsPkey = 'buckets_pkey',
}

/** input type for incrementing numeric columns in table "storage.buckets" */
export type Buckets_Inc_Input = {
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage.buckets" */
export type Buckets_Insert_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Buckets_Max_Fields = {
  __typename?: 'buckets_max_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Buckets_Min_Fields = {
  __typename?: 'buckets_min_fields';
  cacheControl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "storage.buckets" */
export type Buckets_Mutation_Response = {
  __typename?: 'buckets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Buckets>;
};

/** input type for inserting object relation for remote table "storage.buckets" */
export type Buckets_Obj_Rel_Insert_Input = {
  data: Buckets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};

/** on_conflict condition type for table "storage.buckets" */
export type Buckets_On_Conflict = {
  constraint: Buckets_Constraint;
  update_columns?: Array<Buckets_Update_Column>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.buckets". */
export type Buckets_Order_By = {
  cacheControl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  downloadExpiration?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  maxUploadFileSize?: InputMaybe<Order_By>;
  minUploadFileSize?: InputMaybe<Order_By>;
  presignedUrlsEnabled?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: storage.buckets */
export type Buckets_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "storage.buckets" */
export enum Buckets_Select_Column {
  /** column name */
  CacheControl = 'cacheControl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadExpiration = 'downloadExpiration',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUploadFileSize = 'maxUploadFileSize',
  /** column name */
  MinUploadFileSize = 'minUploadFileSize',
  /** column name */
  PresignedUrlsEnabled = 'presignedUrlsEnabled',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** input type for updating data in table "storage.buckets" */
export type Buckets_Set_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Buckets_Stddev_Fields = {
  __typename?: 'buckets_stddev_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Buckets_Stddev_Pop_Fields = {
  __typename?: 'buckets_stddev_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Buckets_Stddev_Samp_Fields = {
  __typename?: 'buckets_stddev_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "buckets" */
export type Buckets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Buckets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buckets_Stream_Cursor_Value_Input = {
  cacheControl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  downloadExpiration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  minUploadFileSize?: InputMaybe<Scalars['Int']['input']>;
  presignedUrlsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Buckets_Sum_Fields = {
  __typename?: 'buckets_sum_fields';
  downloadExpiration?: Maybe<Scalars['Int']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Int']['output']>;
  minUploadFileSize?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "storage.buckets" */
export enum Buckets_Update_Column {
  /** column name */
  CacheControl = 'cacheControl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DownloadExpiration = 'downloadExpiration',
  /** column name */
  Id = 'id',
  /** column name */
  MaxUploadFileSize = 'maxUploadFileSize',
  /** column name */
  MinUploadFileSize = 'minUploadFileSize',
  /** column name */
  PresignedUrlsEnabled = 'presignedUrlsEnabled',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Buckets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Buckets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Buckets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Buckets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Buckets_Var_Pop_Fields = {
  __typename?: 'buckets_var_pop_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Buckets_Var_Samp_Fields = {
  __typename?: 'buckets_var_samp_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Buckets_Variance_Fields = {
  __typename?: 'buckets_variance_fields';
  downloadExpiration?: Maybe<Scalars['Float']['output']>;
  maxUploadFileSize?: Maybe<Scalars['Float']['output']>;
  minUploadFileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bytea']['input']>;
  _gt?: InputMaybe<Scalars['bytea']['input']>;
  _gte?: InputMaybe<Scalars['bytea']['input']>;
  _in?: InputMaybe<Array<Scalars['bytea']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bytea']['input']>;
  _lte?: InputMaybe<Scalars['bytea']['input']>;
  _neq?: InputMaybe<Scalars['bytea']['input']>;
  _nin?: InputMaybe<Array<Scalars['bytea']['input']>>;
};

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories';
  /** An array relationship */
  control_categories: Array<Control_Categories>;
  /** An aggregate relationship */
  control_categories_aggregate: Control_Categories_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "categories" */
export type CategoriesControl_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

/** columns and relationships of "categories" */
export type CategoriesControl_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

/** aggregated selection of "categories" */
export type Categories_Aggregate = {
  __typename?: 'categories_aggregate';
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

export type Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Categories_Aggregate_Bool_Exp_Count>;
};

export type Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
};

/** aggregate fields of "categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "categories" */
export type Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Categories_Max_Order_By>;
  min?: InputMaybe<Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "categories" */
export type Categories_Arr_Rel_Insert_Input = {
  data: Array<Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Categories_Bool_Exp>>;
  _not?: InputMaybe<Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Categories_Bool_Exp>>;
  control_categories?: InputMaybe<Control_Categories_Bool_Exp>;
  control_categories_aggregate?: InputMaybe<Control_Categories_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  CategoriesNameOrganizationIdKey = 'categories_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey',
}

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  control_categories?: InputMaybe<Control_Categories_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'categories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "categories" */
export type Categories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'categories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "categories" */
export type Categories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  control_categories_aggregate?: InputMaybe<Control_Categories_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Categories_Bool_Exp;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

/** Set of sources used as context to justify an AI answer to a question */
export type Client_Questionnaire_Answer_Ai_Sources = {
  __typename?: 'client_questionnaire_answer_ai_sources';
  /** An object relationship */
  client_questionnaire_answer_ai_sources_client_questionnaire_answer: Client_Questionnaire_Answers;
  client_questionnaire_answer_id: Scalars['uuid']['output'];
  /** An object relationship */
  control?: Maybe<Controls>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata: Scalars['jsonb']['output'];
  similarity: Scalars['float8']['output'];
  text: Scalars['String']['output'];
};

/** Set of sources used as context to justify an AI answer to a question */
export type Client_Questionnaire_Answer_Ai_SourcesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Aggregate = {
  __typename?: 'client_questionnaire_answer_ai_sources_aggregate';
  aggregate?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Fields>;
  nodes: Array<Client_Questionnaire_Answer_Ai_Sources>;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Var_Samp>;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Avg = {
  arguments: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Corr = {
  arguments: Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Max = {
  arguments: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Min = {
  arguments: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Sum = {
  arguments: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_aggregate_fields';
  avg?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Max_Fields>;
  min?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Min_Fields>;
  stddev?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Sum_Fields>;
  var_pop?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Var_Samp_Fields>;
  variance?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Variance_Fields>;
};

/** aggregate fields of "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Aggregate_Order_By = {
  avg?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Max_Order_By>;
  min?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Min_Order_By>;
  stddev?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Sum_Order_By>;
  var_pop?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Var_Samp_Order_By>;
  variance?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Client_Questionnaire_Answer_Ai_Sources_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Arr_Rel_Insert_Input = {
  data: Array<Client_Questionnaire_Answer_Ai_Sources_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Avg_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_questionnaire_answer_ai_sources". All fields are combined with a logical 'AND'. */
export type Client_Questionnaire_Answer_Ai_Sources_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>>;
  client_questionnaire_answer_ai_sources_client_questionnaire_answer?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  client_questionnaire_answer_id?: InputMaybe<Uuid_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientQuestionnaireAnswerAiSourcesPkey = 'client_questionnaire_answer_ai_sources_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Client_Questionnaire_Answer_Ai_Sources_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Client_Questionnaire_Answer_Ai_Sources_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Client_Questionnaire_Answer_Ai_Sources_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Insert_Input = {
  client_questionnaire_answer_ai_sources_client_questionnaire_answer?: InputMaybe<Client_Questionnaire_Answers_Obj_Rel_Insert_Input>;
  client_questionnaire_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Max_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_max_fields';
  client_questionnaire_answer_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Max_Order_By = {
  client_questionnaire_answer_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Min_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_min_fields';
  client_questionnaire_answer_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Min_Order_By = {
  client_questionnaire_answer_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Mutation_Response = {
  __typename?: 'client_questionnaire_answer_ai_sources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaire_Answer_Ai_Sources>;
};

/** on_conflict condition type for table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_On_Conflict = {
  constraint: Client_Questionnaire_Answer_Ai_Sources_Constraint;
  update_columns?: Array<Client_Questionnaire_Answer_Ai_Sources_Update_Column>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

/** Ordering options when selecting data from "client_questionnaire_answer_ai_sources". */
export type Client_Questionnaire_Answer_Ai_Sources_Order_By = {
  client_questionnaire_answer_ai_sources_client_questionnaire_answer?: InputMaybe<Client_Questionnaire_Answers_Order_By>;
  client_questionnaire_answer_id?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_questionnaire_answer_ai_sources */
export type Client_Questionnaire_Answer_Ai_Sources_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Client_Questionnaire_Answer_Ai_Sources_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column {
  /** column name */
  ClientQuestionnaireAnswerId = 'client_questionnaire_answer_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Text = 'text',
}

/** select "client_questionnaire_answer_ai_sources_aggregate_bool_exp_avg_arguments_columns" columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "client_questionnaire_answer_ai_sources_aggregate_bool_exp_corr_arguments_columns" columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "client_questionnaire_answer_ai_sources_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "client_questionnaire_answer_ai_sources_aggregate_bool_exp_max_arguments_columns" columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "client_questionnaire_answer_ai_sources_aggregate_bool_exp_min_arguments_columns" columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "client_questionnaire_answer_ai_sources_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "client_questionnaire_answer_ai_sources_aggregate_bool_exp_sum_arguments_columns" columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "client_questionnaire_answer_ai_sources_aggregate_bool_exp_var_samp_arguments_columns" columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Select_Column_Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Set_Input = {
  client_questionnaire_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Stddev_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Stddev_Pop_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Stddev_Samp_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Questionnaire_Answer_Ai_Sources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Questionnaire_Answer_Ai_Sources_Stream_Cursor_Value_Input = {
  client_questionnaire_answer_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Sum_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "client_questionnaire_answer_ai_sources" */
export enum Client_Questionnaire_Answer_Ai_Sources_Update_Column {
  /** column name */
  ClientQuestionnaireAnswerId = 'client_questionnaire_answer_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Text = 'text',
}

export type Client_Questionnaire_Answer_Ai_Sources_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Questionnaire_Answer_Ai_Sources_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Var_Pop_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Var_Samp_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Questionnaire_Answer_Ai_Sources_Variance_Fields = {
  __typename?: 'client_questionnaire_answer_ai_sources_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "client_questionnaire_answer_ai_sources" */
export type Client_Questionnaire_Answer_Ai_Sources_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Answers to a given Client Questionnaire Question */
export type Client_Questionnaire_Answers = {
  __typename?: 'client_questionnaire_answers';
  answer: Scalars['String']['output'];
  /** An array relationship */
  client_questionnaire_answer_ai_sources: Array<Client_Questionnaire_Answer_Ai_Sources>;
  /** An aggregate relationship */
  client_questionnaire_answer_ai_sources_aggregate: Client_Questionnaire_Answer_Ai_Sources_Aggregate;
  /** An object relationship */
  client_questionnaire_question: Client_Questionnaire_Questions;
  client_questionnaire_question_id: Scalars['uuid']['output'];
  comment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  is_ai_generated: Scalars['Boolean']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** Answers to a given Client Questionnaire Question */
export type Client_Questionnaire_AnswersClient_Questionnaire_Answer_Ai_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

/** Answers to a given Client Questionnaire Question */
export type Client_Questionnaire_AnswersClient_Questionnaire_Answer_Ai_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

/** aggregated selection of "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Aggregate = {
  __typename?: 'client_questionnaire_answers_aggregate';
  aggregate?: Maybe<Client_Questionnaire_Answers_Aggregate_Fields>;
  nodes: Array<Client_Questionnaire_Answers>;
};

export type Client_Questionnaire_Answers_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Bool_Exp_Count>;
};

export type Client_Questionnaire_Answers_Aggregate_Bool_Exp_Bool_And = {
  arguments: Client_Questionnaire_Answers_Select_Column_Client_Questionnaire_Answers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Client_Questionnaire_Answers_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Client_Questionnaire_Answers_Select_Column_Client_Questionnaire_Answers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Client_Questionnaire_Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Aggregate_Fields = {
  __typename?: 'client_questionnaire_answers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Client_Questionnaire_Answers_Max_Fields>;
  min?: Maybe<Client_Questionnaire_Answers_Min_Fields>;
};

/** aggregate fields of "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Questionnaire_Answers_Max_Order_By>;
  min?: InputMaybe<Client_Questionnaire_Answers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Arr_Rel_Insert_Input = {
  data: Array<Client_Questionnaire_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaire_Answers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_questionnaire_answers". All fields are combined with a logical 'AND'. */
export type Client_Questionnaire_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaire_Answers_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaire_Answers_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  client_questionnaire_answer_ai_sources?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  client_questionnaire_answer_ai_sources_aggregate?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp>;
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  client_questionnaire_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_ai_generated?: InputMaybe<Boolean_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "client_questionnaire_answers" */
export enum Client_Questionnaire_Answers_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientQuestionnaireAnswersPkey = 'client_questionnaire_answers_pkey',
  /** unique or primary key constraint on columns "client_questionnaire_question_id", "created_at" */
  ClientQuestionnaireAnswersQuestionIdCreatedAtIdx = 'client_questionnaire_answers_question_id_created_at_idx',
}

/** input type for inserting data into table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Insert_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  client_questionnaire_answer_ai_sources?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Arr_Rel_Insert_Input>;
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Obj_Rel_Insert_Input>;
  client_questionnaire_question_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_ai_generated?: InputMaybe<Scalars['Boolean']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Client_Questionnaire_Answers_Max_Fields = {
  __typename?: 'client_questionnaire_answers_max_fields';
  answer?: Maybe<Scalars['String']['output']>;
  client_questionnaire_question_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Questionnaire_Answers_Min_Fields = {
  __typename?: 'client_questionnaire_answers_min_fields';
  answer?: Maybe<Scalars['String']['output']>;
  client_questionnaire_question_id?: Maybe<Scalars['uuid']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Mutation_Response = {
  __typename?: 'client_questionnaire_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaire_Answers>;
};

/** input type for inserting object relation for remote table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Obj_Rel_Insert_Input = {
  data: Client_Questionnaire_Answers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaire_Answers_On_Conflict>;
};

/** on_conflict condition type for table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_On_Conflict = {
  constraint: Client_Questionnaire_Answers_Constraint;
  update_columns?: Array<Client_Questionnaire_Answers_Update_Column>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "client_questionnaire_answers". */
export type Client_Questionnaire_Answers_Order_By = {
  answer?: InputMaybe<Order_By>;
  client_questionnaire_answer_ai_sources_aggregate?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Order_By>;
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Order_By>;
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_ai_generated?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: client_questionnaire_answers */
export type Client_Questionnaire_Answers_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "client_questionnaire_answers" */
export enum Client_Questionnaire_Answers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  ClientQuestionnaireQuestionId = 'client_questionnaire_question_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAiGenerated = 'is_ai_generated',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** select "client_questionnaire_answers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "client_questionnaire_answers" */
export enum Client_Questionnaire_Answers_Select_Column_Client_Questionnaire_Answers_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsAiGenerated = 'is_ai_generated',
}

/** select "client_questionnaire_answers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "client_questionnaire_answers" */
export enum Client_Questionnaire_Answers_Select_Column_Client_Questionnaire_Answers_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsAiGenerated = 'is_ai_generated',
}

/** input type for updating data in table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Set_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  client_questionnaire_question_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_ai_generated?: InputMaybe<Scalars['Boolean']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Questionnaire_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Questionnaire_Answers_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  client_questionnaire_question_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_ai_generated?: InputMaybe<Scalars['Boolean']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "client_questionnaire_answers" */
export enum Client_Questionnaire_Answers_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  ClientQuestionnaireQuestionId = 'client_questionnaire_question_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAiGenerated = 'is_ai_generated',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Client_Questionnaire_Answers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Questionnaire_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Questionnaire_Answers_Bool_Exp;
};

/** Set of possible status for a Client Questionnaire Question */
export type Client_Questionnaire_Question_Status = {
  __typename?: 'client_questionnaire_question_status';
  /** An array relationship */
  client_questionnaire_questions: Array<Client_Questionnaire_Questions>;
  /** An aggregate relationship */
  client_questionnaire_questions_aggregate: Client_Questionnaire_Questions_Aggregate;
  value: Scalars['String']['output'];
};

/** Set of possible status for a Client Questionnaire Question */
export type Client_Questionnaire_Question_StatusClient_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

/** Set of possible status for a Client Questionnaire Question */
export type Client_Questionnaire_Question_StatusClient_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

/** aggregated selection of "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_Aggregate = {
  __typename?: 'client_questionnaire_question_status_aggregate';
  aggregate?: Maybe<Client_Questionnaire_Question_Status_Aggregate_Fields>;
  nodes: Array<Client_Questionnaire_Question_Status>;
};

/** aggregate fields of "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_Aggregate_Fields = {
  __typename?: 'client_questionnaire_question_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Client_Questionnaire_Question_Status_Max_Fields>;
  min?: Maybe<Client_Questionnaire_Question_Status_Min_Fields>;
};

/** aggregate fields of "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Questionnaire_Question_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "client_questionnaire_question_status". All fields are combined with a logical 'AND'. */
export type Client_Questionnaire_Question_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaire_Question_Status_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaire_Question_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaire_Question_Status_Bool_Exp>>;
  client_questionnaire_questions?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  client_questionnaire_questions_aggregate?: InputMaybe<Client_Questionnaire_Questions_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_questionnaire_question_status" */
export enum Client_Questionnaire_Question_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ClientQuestionnaireQuestionStatusPkey = 'client_questionnaire_question_status_pkey',
}

export enum Client_Questionnaire_Question_Status_Enum {
  Approved = 'Approved',
  Pending = 'Pending',
  Processing = 'Processing',
  ProcessingFailed = 'Processing_failed',
  ReadyForReview = 'Ready_for_review',
}

/** Boolean expression to compare columns of type "client_questionnaire_question_status_enum". All fields are combined with logical 'AND'. */
export type Client_Questionnaire_Question_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
  _in?: InputMaybe<Array<Client_Questionnaire_Question_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
  _nin?: InputMaybe<Array<Client_Questionnaire_Question_Status_Enum>>;
};

/** input type for inserting data into table "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_Insert_Input = {
  client_questionnaire_questions?: InputMaybe<Client_Questionnaire_Questions_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Client_Questionnaire_Question_Status_Max_Fields = {
  __typename?: 'client_questionnaire_question_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Client_Questionnaire_Question_Status_Min_Fields = {
  __typename?: 'client_questionnaire_question_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_Mutation_Response = {
  __typename?: 'client_questionnaire_question_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaire_Question_Status>;
};

/** input type for inserting object relation for remote table "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_Obj_Rel_Insert_Input = {
  data: Client_Questionnaire_Question_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaire_Question_Status_On_Conflict>;
};

/** on_conflict condition type for table "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_On_Conflict = {
  constraint: Client_Questionnaire_Question_Status_Constraint;
  update_columns?: Array<Client_Questionnaire_Question_Status_Update_Column>;
  where?: InputMaybe<Client_Questionnaire_Question_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "client_questionnaire_question_status". */
export type Client_Questionnaire_Question_Status_Order_By = {
  client_questionnaire_questions_aggregate?: InputMaybe<Client_Questionnaire_Questions_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_questionnaire_question_status */
export type Client_Questionnaire_Question_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "client_questionnaire_question_status" */
export enum Client_Questionnaire_Question_Status_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "client_questionnaire_question_status" */
export type Client_Questionnaire_Question_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Questionnaire_Question_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Questionnaire_Question_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "client_questionnaire_question_status" */
export enum Client_Questionnaire_Question_Status_Update_Column {
  /** column name */
  Value = 'value',
}

export type Client_Questionnaire_Question_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Questionnaire_Question_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Questionnaire_Question_Status_Bool_Exp;
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_Questions = {
  __typename?: 'client_questionnaire_questions';
  /** An object relationship */
  client_questionnaire: Client_Questionnaires;
  /** An array relationship */
  client_questionnaire_answers: Array<Client_Questionnaire_Answers>;
  /** An aggregate relationship */
  client_questionnaire_answers_aggregate: Client_Questionnaire_Answers_Aggregate;
  client_questionnaire_id: Scalars['uuid']['output'];
  /** An object relationship */
  client_questionnaire_question_status: Client_Questionnaire_Question_Status;
  /** An array relationship */
  comments: Array<Comments>;
  /** An aggregate relationship */
  comments_aggregate: Comments_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  question: Scalars['String']['output'];
  status: Client_Questionnaire_Question_Status_Enum;
  updated_at: Scalars['timestamptz']['output'];
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_QuestionsClient_Questionnaire_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_QuestionsClient_Questionnaire_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_QuestionsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_QuestionsComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_QuestionsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Aggregate = {
  __typename?: 'client_questionnaire_questions_aggregate';
  aggregate?: Maybe<Client_Questionnaire_Questions_Aggregate_Fields>;
  nodes: Array<Client_Questionnaire_Questions>;
};

export type Client_Questionnaire_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Questionnaire_Questions_Aggregate_Bool_Exp_Count>;
};

export type Client_Questionnaire_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Aggregate_Fields = {
  __typename?: 'client_questionnaire_questions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Client_Questionnaire_Questions_Max_Fields>;
  min?: Maybe<Client_Questionnaire_Questions_Min_Fields>;
};

/** aggregate fields of "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Questionnaire_Questions_Max_Order_By>;
  min?: InputMaybe<Client_Questionnaire_Questions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Client_Questionnaire_Questions_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Arr_Rel_Insert_Input = {
  data: Array<Client_Questionnaire_Questions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaire_Questions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_questionnaire_questions". All fields are combined with a logical 'AND'. */
export type Client_Questionnaire_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaire_Questions_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaire_Questions_Bool_Exp>>;
  client_questionnaire?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  client_questionnaire_answers?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  client_questionnaire_answers_aggregate?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Bool_Exp>;
  client_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  client_questionnaire_question_status?: InputMaybe<Client_Questionnaire_Question_Status_Bool_Exp>;
  comments?: InputMaybe<Comments_Bool_Exp>;
  comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Client_Questionnaire_Question_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_questionnaire_questions" */
export enum Client_Questionnaire_Questions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientQuestionnaireQuestionsPkey = 'client_questionnaire_questions_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Client_Questionnaire_Questions_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Client_Questionnaire_Questions_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Client_Questionnaire_Questions_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Insert_Input = {
  client_questionnaire?: InputMaybe<Client_Questionnaires_Obj_Rel_Insert_Input>;
  client_questionnaire_answers?: InputMaybe<Client_Questionnaire_Answers_Arr_Rel_Insert_Input>;
  client_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  client_questionnaire_question_status?: InputMaybe<Client_Questionnaire_Question_Status_Obj_Rel_Insert_Input>;
  comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Client_Questionnaire_Questions_Max_Fields = {
  __typename?: 'client_questionnaire_questions_max_fields';
  client_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Max_Order_By = {
  client_questionnaire_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Questionnaire_Questions_Min_Fields = {
  __typename?: 'client_questionnaire_questions_min_fields';
  client_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Min_Order_By = {
  client_questionnaire_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Mutation_Response = {
  __typename?: 'client_questionnaire_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaire_Questions>;
};

/** input type for inserting object relation for remote table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Obj_Rel_Insert_Input = {
  data: Client_Questionnaire_Questions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaire_Questions_On_Conflict>;
};

/** on_conflict condition type for table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_On_Conflict = {
  constraint: Client_Questionnaire_Questions_Constraint;
  update_columns?: Array<Client_Questionnaire_Questions_Update_Column>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

/** Ordering options when selecting data from "client_questionnaire_questions". */
export type Client_Questionnaire_Questions_Order_By = {
  client_questionnaire?: InputMaybe<Client_Questionnaires_Order_By>;
  client_questionnaire_answers_aggregate?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Order_By>;
  client_questionnaire_id?: InputMaybe<Order_By>;
  client_questionnaire_question_status?: InputMaybe<Client_Questionnaire_Question_Status_Order_By>;
  comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_questionnaire_questions */
export type Client_Questionnaire_Questions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Client_Questionnaire_Questions_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "client_questionnaire_questions" */
export enum Client_Questionnaire_Questions_Select_Column {
  /** column name */
  ClientQuestionnaireId = 'client_questionnaire_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Question = 'question',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Set_Input = {
  client_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Questionnaire_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Questionnaire_Questions_Stream_Cursor_Value_Input = {
  client_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "client_questionnaire_questions" */
export enum Client_Questionnaire_Questions_Update_Column {
  /** column name */
  ClientQuestionnaireId = 'client_questionnaire_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Question = 'question',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Client_Questionnaire_Questions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Client_Questionnaire_Questions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Client_Questionnaire_Questions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Client_Questionnaire_Questions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Client_Questionnaire_Questions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Client_Questionnaire_Questions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Questionnaire_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Questionnaire_Questions_Bool_Exp;
};

/** Set of possible status for a Client Questionnaire */
export type Client_Questionnaire_Status = {
  __typename?: 'client_questionnaire_status';
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** An aggregate relationship */
  client_questionnaires_aggregate: Client_Questionnaires_Aggregate;
  value: Scalars['String']['output'];
};

/** Set of possible status for a Client Questionnaire */
export type Client_Questionnaire_StatusClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** Set of possible status for a Client Questionnaire */
export type Client_Questionnaire_StatusClient_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** aggregated selection of "client_questionnaire_status" */
export type Client_Questionnaire_Status_Aggregate = {
  __typename?: 'client_questionnaire_status_aggregate';
  aggregate?: Maybe<Client_Questionnaire_Status_Aggregate_Fields>;
  nodes: Array<Client_Questionnaire_Status>;
};

/** aggregate fields of "client_questionnaire_status" */
export type Client_Questionnaire_Status_Aggregate_Fields = {
  __typename?: 'client_questionnaire_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Client_Questionnaire_Status_Max_Fields>;
  min?: Maybe<Client_Questionnaire_Status_Min_Fields>;
};

/** aggregate fields of "client_questionnaire_status" */
export type Client_Questionnaire_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Questionnaire_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "client_questionnaire_status". All fields are combined with a logical 'AND'. */
export type Client_Questionnaire_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaire_Status_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaire_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaire_Status_Bool_Exp>>;
  client_questionnaires?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_questionnaire_status" */
export enum Client_Questionnaire_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ClientQuestionnaireStatusPkey = 'client_questionnaire_status_pkey',
}

export enum Client_Questionnaire_Status_Enum {
  Complete = 'Complete',
  ExtractingQuestions = 'Extracting_questions',
  ExtractionFailed = 'Extraction_failed',
  InProgress = 'In_progress',
  Overdue = 'Overdue',
  Pending = 'Pending',
}

/** Boolean expression to compare columns of type "client_questionnaire_status_enum". All fields are combined with logical 'AND'. */
export type Client_Questionnaire_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Client_Questionnaire_Status_Enum>;
  _in?: InputMaybe<Array<Client_Questionnaire_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Client_Questionnaire_Status_Enum>;
  _nin?: InputMaybe<Array<Client_Questionnaire_Status_Enum>>;
};

/** input type for inserting data into table "client_questionnaire_status" */
export type Client_Questionnaire_Status_Insert_Input = {
  client_questionnaires?: InputMaybe<Client_Questionnaires_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Client_Questionnaire_Status_Max_Fields = {
  __typename?: 'client_questionnaire_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Client_Questionnaire_Status_Min_Fields = {
  __typename?: 'client_questionnaire_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "client_questionnaire_status" */
export type Client_Questionnaire_Status_Mutation_Response = {
  __typename?: 'client_questionnaire_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaire_Status>;
};

/** input type for inserting object relation for remote table "client_questionnaire_status" */
export type Client_Questionnaire_Status_Obj_Rel_Insert_Input = {
  data: Client_Questionnaire_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaire_Status_On_Conflict>;
};

/** on_conflict condition type for table "client_questionnaire_status" */
export type Client_Questionnaire_Status_On_Conflict = {
  constraint: Client_Questionnaire_Status_Constraint;
  update_columns?: Array<Client_Questionnaire_Status_Update_Column>;
  where?: InputMaybe<Client_Questionnaire_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "client_questionnaire_status". */
export type Client_Questionnaire_Status_Order_By = {
  client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_questionnaire_status */
export type Client_Questionnaire_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "client_questionnaire_status" */
export enum Client_Questionnaire_Status_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "client_questionnaire_status" */
export type Client_Questionnaire_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "client_questionnaire_status" */
export type Client_Questionnaire_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Questionnaire_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Questionnaire_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "client_questionnaire_status" */
export enum Client_Questionnaire_Status_Update_Column {
  /** column name */
  Value = 'value',
}

export type Client_Questionnaire_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Questionnaire_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Questionnaire_Status_Bool_Exp;
};

/** Set of Security Questionnaires sent to businessess using Complyance for answering */
export type Client_Questionnaires = {
  __typename?: 'client_questionnaires';
  /** An array relationship */
  client_questionnaire_questions: Array<Client_Questionnaire_Questions>;
  /** An aggregate relationship */
  client_questionnaire_questions_aggregate: Client_Questionnaire_Questions_Aggregate;
  /** An object relationship */
  client_questionnaire_status: Client_Questionnaire_Status;
  company: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  due_date: Scalars['timestamptz']['output'];
  /** An object relationship */
  export_file?: Maybe<Files>;
  export_file_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  owner: Users;
  owner_id: Scalars['uuid']['output'];
  status: Client_Questionnaire_Status_Enum;
  updated_at: Scalars['timestamptz']['output'];
};

/** Set of Security Questionnaires sent to businessess using Complyance for answering */
export type Client_QuestionnairesClient_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

/** Set of Security Questionnaires sent to businessess using Complyance for answering */
export type Client_QuestionnairesClient_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

/** aggregated selection of "client_questionnaires" */
export type Client_Questionnaires_Aggregate = {
  __typename?: 'client_questionnaires_aggregate';
  aggregate?: Maybe<Client_Questionnaires_Aggregate_Fields>;
  nodes: Array<Client_Questionnaires>;
};

export type Client_Questionnaires_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Questionnaires_Aggregate_Bool_Exp_Count>;
};

export type Client_Questionnaires_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_questionnaires" */
export type Client_Questionnaires_Aggregate_Fields = {
  __typename?: 'client_questionnaires_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Client_Questionnaires_Max_Fields>;
  min?: Maybe<Client_Questionnaires_Min_Fields>;
};

/** aggregate fields of "client_questionnaires" */
export type Client_Questionnaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "client_questionnaires" */
export type Client_Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Questionnaires_Max_Order_By>;
  min?: InputMaybe<Client_Questionnaires_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client_questionnaires" */
export type Client_Questionnaires_Arr_Rel_Insert_Input = {
  data: Array<Client_Questionnaires_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaires_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_questionnaires". All fields are combined with a logical 'AND'. */
export type Client_Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaires_Bool_Exp>>;
  client_questionnaire_questions?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  client_questionnaire_questions_aggregate?: InputMaybe<Client_Questionnaire_Questions_Aggregate_Bool_Exp>;
  client_questionnaire_status?: InputMaybe<Client_Questionnaire_Status_Bool_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  export_file?: InputMaybe<Files_Bool_Exp>;
  export_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Client_Questionnaire_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_questionnaires" */
export enum Client_Questionnaires_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  ClientQuestionnairesFileIdKey = 'client_questionnaires_file_id_key',
  /** unique or primary key constraint on columns "id" */
  ClientQuestionnairesPkey = 'client_questionnaires_pkey',
}

/** input type for inserting data into table "client_questionnaires" */
export type Client_Questionnaires_Insert_Input = {
  client_questionnaire_questions?: InputMaybe<Client_Questionnaire_Questions_Arr_Rel_Insert_Input>;
  client_questionnaire_status?: InputMaybe<Client_Questionnaire_Status_Obj_Rel_Insert_Input>;
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  export_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  export_file_id?: InputMaybe<Scalars['uuid']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Client_Questionnaire_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Client_Questionnaires_Max_Fields = {
  __typename?: 'client_questionnaires_max_fields';
  company?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  export_file_id?: Maybe<Scalars['uuid']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "client_questionnaires" */
export type Client_Questionnaires_Max_Order_By = {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  export_file_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Questionnaires_Min_Fields = {
  __typename?: 'client_questionnaires_min_fields';
  company?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  export_file_id?: Maybe<Scalars['uuid']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "client_questionnaires" */
export type Client_Questionnaires_Min_Order_By = {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  export_file_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_questionnaires" */
export type Client_Questionnaires_Mutation_Response = {
  __typename?: 'client_questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaires>;
};

/** input type for inserting object relation for remote table "client_questionnaires" */
export type Client_Questionnaires_Obj_Rel_Insert_Input = {
  data: Client_Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "client_questionnaires" */
export type Client_Questionnaires_On_Conflict = {
  constraint: Client_Questionnaires_Constraint;
  update_columns?: Array<Client_Questionnaires_Update_Column>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "client_questionnaires". */
export type Client_Questionnaires_Order_By = {
  client_questionnaire_questions_aggregate?: InputMaybe<Client_Questionnaire_Questions_Aggregate_Order_By>;
  client_questionnaire_status?: InputMaybe<Client_Questionnaire_Status_Order_By>;
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  export_file?: InputMaybe<Files_Order_By>;
  export_file_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_questionnaires */
export type Client_Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "client_questionnaires" */
export enum Client_Questionnaires_Select_Column {
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  ExportFileId = 'export_file_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "client_questionnaires" */
export type Client_Questionnaires_Set_Input = {
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  export_file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Client_Questionnaire_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "client_questionnaires" */
export type Client_Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Questionnaires_Stream_Cursor_Value_Input = {
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  export_file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Client_Questionnaire_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "client_questionnaires" */
export enum Client_Questionnaires_Update_Column {
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  ExportFileId = 'export_file_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Client_Questionnaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Questionnaires_Bool_Exp;
};

/** columns and relationships of "comment_uploads" */
export type Comment_Uploads = {
  __typename?: 'comment_uploads';
  comment_id: Scalars['uuid']['output'];
  /** An object relationship */
  comment_uploads_comment: Comments;
  /** An object relationship */
  comment_uploads_file: Files;
  created_at: Scalars['timestamptz']['output'];
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "comment_uploads" */
export type Comment_Uploads_Aggregate = {
  __typename?: 'comment_uploads_aggregate';
  aggregate?: Maybe<Comment_Uploads_Aggregate_Fields>;
  nodes: Array<Comment_Uploads>;
};

export type Comment_Uploads_Aggregate_Bool_Exp = {
  count?: InputMaybe<Comment_Uploads_Aggregate_Bool_Exp_Count>;
};

export type Comment_Uploads_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comment_Uploads_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comment_uploads" */
export type Comment_Uploads_Aggregate_Fields = {
  __typename?: 'comment_uploads_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Comment_Uploads_Max_Fields>;
  min?: Maybe<Comment_Uploads_Min_Fields>;
};

/** aggregate fields of "comment_uploads" */
export type Comment_Uploads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comment_uploads" */
export type Comment_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Uploads_Max_Order_By>;
  min?: InputMaybe<Comment_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comment_uploads" */
export type Comment_Uploads_Arr_Rel_Insert_Input = {
  data: Array<Comment_Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comment_uploads". All fields are combined with a logical 'AND'. */
export type Comment_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Comment_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Uploads_Bool_Exp>>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  comment_uploads_comment?: InputMaybe<Comments_Bool_Exp>;
  comment_uploads_file?: InputMaybe<Files_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_uploads" */
export enum Comment_Uploads_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  CommentUploadsFileIdKey = 'comment_uploads_file_id_key',
  /** unique or primary key constraint on columns "id" */
  CommentUploadsPkey = 'comment_uploads_pkey',
}

/** input type for inserting data into table "comment_uploads" */
export type Comment_Uploads_Insert_Input = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  comment_uploads_comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  comment_uploads_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Comment_Uploads_Max_Fields = {
  __typename?: 'comment_uploads_max_fields';
  comment_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "comment_uploads" */
export type Comment_Uploads_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_Uploads_Min_Fields = {
  __typename?: 'comment_uploads_min_fields';
  comment_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "comment_uploads" */
export type Comment_Uploads_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment_uploads" */
export type Comment_Uploads_Mutation_Response = {
  __typename?: 'comment_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment_Uploads>;
};

/** input type for inserting object relation for remote table "comment_uploads" */
export type Comment_Uploads_Obj_Rel_Insert_Input = {
  data: Comment_Uploads_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Uploads_On_Conflict>;
};

/** on_conflict condition type for table "comment_uploads" */
export type Comment_Uploads_On_Conflict = {
  constraint: Comment_Uploads_Constraint;
  update_columns?: Array<Comment_Uploads_Update_Column>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_uploads". */
export type Comment_Uploads_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  comment_uploads_comment?: InputMaybe<Comments_Order_By>;
  comment_uploads_file?: InputMaybe<Files_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment_uploads */
export type Comment_Uploads_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "comment_uploads" */
export enum Comment_Uploads_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "comment_uploads" */
export type Comment_Uploads_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "comment_uploads" */
export type Comment_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Uploads_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "comment_uploads" */
export enum Comment_Uploads_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
}

export type Comment_Uploads_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Uploads_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comment_Uploads_Bool_Exp;
};

/** columns and relationships of "comments" */
export type Comments = {
  __typename?: 'comments';
  /** An object relationship */
  client_questionnaire_question?: Maybe<Client_Questionnaire_Questions>;
  client_questionnaire_question_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  comments_comment_uploads: Array<Comment_Uploads>;
  /** An aggregate relationship */
  comments_comment_uploads_aggregate: Comment_Uploads_Aggregate;
  /** An object relationship */
  comments_control?: Maybe<Controls>;
  /** An array relationship */
  comments_notifications: Array<Notifications>;
  /** An aggregate relationship */
  comments_notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  comments_policy?: Maybe<Policies>;
  /** An object relationship */
  comments_risk?: Maybe<Risks>;
  /** An object relationship */
  comments_task?: Maybe<Tasks>;
  /** An object relationship */
  comments_user: Users;
  /** An object relationship */
  comments_vendor?: Maybe<Vendors>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  policy_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  text: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "comments" */
export type CommentsComments_Comment_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Uploads_Order_By>>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

/** columns and relationships of "comments" */
export type CommentsComments_Comment_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Uploads_Order_By>>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

/** columns and relationships of "comments" */
export type CommentsComments_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "comments" */
export type CommentsComments_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** aggregated selection of "comments" */
export type Comments_Aggregate = {
  __typename?: 'comments_aggregate';
  aggregate?: Maybe<Comments_Aggregate_Fields>;
  nodes: Array<Comments>;
};

export type Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Comments_Aggregate_Bool_Exp_Count>;
};

export type Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "comments" */
export type Comments_Aggregate_Fields = {
  __typename?: 'comments_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Comments_Max_Fields>;
  min?: Maybe<Comments_Min_Fields>;
};

/** aggregate fields of "comments" */
export type Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "comments" */
export type Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comments_Max_Order_By>;
  min?: InputMaybe<Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comments" */
export type Comments_Arr_Rel_Insert_Input = {
  data: Array<Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comments". All fields are combined with a logical 'AND'. */
export type Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Comments_Bool_Exp>>;
  _not?: InputMaybe<Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Comments_Bool_Exp>>;
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  client_questionnaire_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  comments_comment_uploads?: InputMaybe<Comment_Uploads_Bool_Exp>;
  comments_comment_uploads_aggregate?: InputMaybe<Comment_Uploads_Aggregate_Bool_Exp>;
  comments_control?: InputMaybe<Controls_Bool_Exp>;
  comments_notifications?: InputMaybe<Notifications_Bool_Exp>;
  comments_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  comments_policy?: InputMaybe<Policies_Bool_Exp>;
  comments_risk?: InputMaybe<Risks_Bool_Exp>;
  comments_task?: InputMaybe<Tasks_Bool_Exp>;
  comments_user?: InputMaybe<Users_Bool_Exp>;
  comments_vendor?: InputMaybe<Vendors_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "comments" */
export enum Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentsPkey = 'comments_pkey',
}

/** input type for inserting data into table "comments" */
export type Comments_Insert_Input = {
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Obj_Rel_Insert_Input>;
  client_questionnaire_question_id?: InputMaybe<Scalars['uuid']['input']>;
  comments_comment_uploads?: InputMaybe<Comment_Uploads_Arr_Rel_Insert_Input>;
  comments_control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  comments_notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  comments_policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  comments_risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  comments_task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  comments_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  comments_vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Comments_Max_Fields = {
  __typename?: 'comments_max_fields';
  client_questionnaire_question_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "comments" */
export type Comments_Max_Order_By = {
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Min_Fields = {
  __typename?: 'comments_min_fields';
  client_questionnaire_question_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "comments" */
export type Comments_Min_Order_By = {
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comments" */
export type Comments_Mutation_Response = {
  __typename?: 'comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comments>;
};

/** input type for inserting object relation for remote table "comments" */
export type Comments_Obj_Rel_Insert_Input = {
  data: Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** on_conflict condition type for table "comments" */
export type Comments_On_Conflict = {
  constraint: Comments_Constraint;
  update_columns?: Array<Comments_Update_Column>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "comments". */
export type Comments_Order_By = {
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Order_By>;
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  comments_comment_uploads_aggregate?: InputMaybe<Comment_Uploads_Aggregate_Order_By>;
  comments_control?: InputMaybe<Controls_Order_By>;
  comments_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  comments_policy?: InputMaybe<Policies_Order_By>;
  comments_risk?: InputMaybe<Risks_Order_By>;
  comments_task?: InputMaybe<Tasks_Order_By>;
  comments_user?: InputMaybe<Users_Order_By>;
  comments_vendor?: InputMaybe<Vendors_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comments */
export type Comments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "comments" */
export enum Comments_Select_Column {
  /** column name */
  ClientQuestionnaireQuestionId = 'client_questionnaire_question_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "comments" */
export type Comments_Set_Input = {
  client_questionnaire_question_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "comments" */
export type Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_Stream_Cursor_Value_Input = {
  client_questionnaire_question_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "comments" */
export enum Comments_Update_Column {
  /** column name */
  ClientQuestionnaireQuestionId = 'client_questionnaire_question_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
}

export type Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comments_Bool_Exp;
};

/** Set of document sources retrieved and supporting a given assessment */
export type Control_Ai_Review_Assessment_Sources = {
  __typename?: 'control_ai_review_assessment_sources';
  assessment_id: Scalars['uuid']['output'];
  /** An object relationship */
  control_ai_review_assessment: Control_Ai_Review_Assessments;
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence_version: Evidence_Versions;
  evidence_version_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
};

/** aggregated selection of "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Aggregate = {
  __typename?: 'control_ai_review_assessment_sources_aggregate';
  aggregate?: Maybe<Control_Ai_Review_Assessment_Sources_Aggregate_Fields>;
  nodes: Array<Control_Ai_Review_Assessment_Sources>;
};

export type Control_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Count>;
};

export type Control_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Aggregate_Fields = {
  __typename?: 'control_ai_review_assessment_sources_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Ai_Review_Assessment_Sources_Max_Fields>;
  min?: Maybe<Control_Ai_Review_Assessment_Sources_Min_Fields>;
};

/** aggregate fields of "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Ai_Review_Assessment_Sources_Max_Order_By>;
  min?: InputMaybe<Control_Ai_Review_Assessment_Sources_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Arr_Rel_Insert_Input = {
  data: Array<Control_Ai_Review_Assessment_Sources_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Ai_Review_Assessment_Sources_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_ai_review_assessment_sources". All fields are combined with a logical 'AND'. */
export type Control_Ai_Review_Assessment_Sources_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Bool_Exp>>;
  _not?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Bool_Exp>>;
  assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  control_ai_review_assessment?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence_version?: InputMaybe<Evidence_Versions_Bool_Exp>;
  evidence_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_ai_review_assessment_sources" */
export enum Control_Ai_Review_Assessment_Sources_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlAiReviewAssessmentSourcesPkey = 'control_ai_review_assessment_sources_pkey',
}

/** input type for inserting data into table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Insert_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  control_ai_review_assessment?: InputMaybe<Control_Ai_Review_Assessments_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_version?: InputMaybe<Evidence_Versions_Obj_Rel_Insert_Input>;
  evidence_version_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Control_Ai_Review_Assessment_Sources_Max_Fields = {
  __typename?: 'control_ai_review_assessment_sources_max_fields';
  assessment_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_version_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Max_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Ai_Review_Assessment_Sources_Min_Fields = {
  __typename?: 'control_ai_review_assessment_sources_min_fields';
  assessment_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_version_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Min_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Mutation_Response = {
  __typename?: 'control_ai_review_assessment_sources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Ai_Review_Assessment_Sources>;
};

/** on_conflict condition type for table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_On_Conflict = {
  constraint: Control_Ai_Review_Assessment_Sources_Constraint;
  update_columns?: Array<Control_Ai_Review_Assessment_Sources_Update_Column>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

/** Ordering options when selecting data from "control_ai_review_assessment_sources". */
export type Control_Ai_Review_Assessment_Sources_Order_By = {
  assessment_id?: InputMaybe<Order_By>;
  control_ai_review_assessment?: InputMaybe<Control_Ai_Review_Assessments_Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_version?: InputMaybe<Evidence_Versions_Order_By>;
  evidence_version_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_ai_review_assessment_sources */
export type Control_Ai_Review_Assessment_Sources_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "control_ai_review_assessment_sources" */
export enum Control_Ai_Review_Assessment_Sources_Select_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceVersionId = 'evidence_version_id',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Set_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_version_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "control_ai_review_assessment_sources" */
export type Control_Ai_Review_Assessment_Sources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Ai_Review_Assessment_Sources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Ai_Review_Assessment_Sources_Stream_Cursor_Value_Input = {
  assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_version_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "control_ai_review_assessment_sources" */
export enum Control_Ai_Review_Assessment_Sources_Update_Column {
  /** column name */
  AssessmentId = 'assessment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceVersionId = 'evidence_version_id',
  /** column name */
  Id = 'id',
}

export type Control_Ai_Review_Assessment_Sources_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Ai_Review_Assessment_Sources_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Ai_Review_Assessment_Sources_Bool_Exp;
};

/** Set of assessments resulting for given criteria during the execution of AI review runs */
export type Control_Ai_Review_Assessments = {
  __typename?: 'control_ai_review_assessments';
  assessment: Scalars['String']['output'];
  /** An array relationship */
  control_ai_review_assessment_sources: Array<Control_Ai_Review_Assessment_Sources>;
  /** An aggregate relationship */
  control_ai_review_assessment_sources_aggregate: Control_Ai_Review_Assessment_Sources_Aggregate;
  control_ai_review_criteria_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  control_ai_review_run: Control_Ai_Review_Runs;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  /** An aggregate relationship */
  control_findings_aggregate: Control_Findings_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  criteria_content: Scalars['String']['output'];
  criteria_heading: Scalars['String']['output'];
  criteria_suggestion: Scalars['String']['output'];
  high_confidence: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  run_id: Scalars['uuid']['output'];
  template_control_ai_review_criteria_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** Set of assessments resulting for given criteria during the execution of AI review runs */
export type Control_Ai_Review_AssessmentsControl_Ai_Review_Assessment_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

/** Set of assessments resulting for given criteria during the execution of AI review runs */
export type Control_Ai_Review_AssessmentsControl_Ai_Review_Assessment_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

/** Set of assessments resulting for given criteria during the execution of AI review runs */
export type Control_Ai_Review_AssessmentsControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Set of assessments resulting for given criteria during the execution of AI review runs */
export type Control_Ai_Review_AssessmentsControl_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** aggregated selection of "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Aggregate = {
  __typename?: 'control_ai_review_assessments_aggregate';
  aggregate?: Maybe<Control_Ai_Review_Assessments_Aggregate_Fields>;
  nodes: Array<Control_Ai_Review_Assessments>;
};

export type Control_Ai_Review_Assessments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Count>;
};

export type Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Control_Ai_Review_Assessments_Select_Column_Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Control_Ai_Review_Assessments_Select_Column_Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Ai_Review_Assessments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Aggregate_Fields = {
  __typename?: 'control_ai_review_assessments_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Ai_Review_Assessments_Max_Fields>;
  min?: Maybe<Control_Ai_Review_Assessments_Min_Fields>;
};

/** aggregate fields of "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Ai_Review_Assessments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Ai_Review_Assessments_Max_Order_By>;
  min?: InputMaybe<Control_Ai_Review_Assessments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Arr_Rel_Insert_Input = {
  data: Array<Control_Ai_Review_Assessments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Ai_Review_Assessments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_ai_review_assessments". All fields are combined with a logical 'AND'. */
export type Control_Ai_Review_Assessments_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Ai_Review_Assessments_Bool_Exp>>;
  _not?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Ai_Review_Assessments_Bool_Exp>>;
  assessment?: InputMaybe<String_Comparison_Exp>;
  control_ai_review_assessment_sources?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
  control_ai_review_assessment_sources_aggregate?: InputMaybe<Control_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp>;
  control_ai_review_criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
  control_ai_review_run?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  criteria_content?: InputMaybe<String_Comparison_Exp>;
  criteria_heading?: InputMaybe<String_Comparison_Exp>;
  criteria_suggestion?: InputMaybe<String_Comparison_Exp>;
  high_confidence?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  run_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_control_ai_review_criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_ai_review_assessments" */
export enum Control_Ai_Review_Assessments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlAiReviewAssessmentsPkey = 'control_ai_review_assessments_pkey',
}

/** input type for inserting data into table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Insert_Input = {
  assessment?: InputMaybe<Scalars['String']['input']>;
  control_ai_review_assessment_sources?: InputMaybe<Control_Ai_Review_Assessment_Sources_Arr_Rel_Insert_Input>;
  control_ai_review_criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  control_ai_review_run?: InputMaybe<Control_Ai_Review_Runs_Obj_Rel_Insert_Input>;
  control_findings?: InputMaybe<Control_Findings_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  criteria_content?: InputMaybe<Scalars['String']['input']>;
  criteria_heading?: InputMaybe<Scalars['String']['input']>;
  criteria_suggestion?: InputMaybe<Scalars['String']['input']>;
  high_confidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  run_id?: InputMaybe<Scalars['uuid']['input']>;
  template_control_ai_review_criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Ai_Review_Assessments_Max_Fields = {
  __typename?: 'control_ai_review_assessments_max_fields';
  assessment?: Maybe<Scalars['String']['output']>;
  control_ai_review_criteria_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  criteria_content?: Maybe<Scalars['String']['output']>;
  criteria_heading?: Maybe<Scalars['String']['output']>;
  criteria_suggestion?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  run_id?: Maybe<Scalars['uuid']['output']>;
  template_control_ai_review_criteria_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Max_Order_By = {
  assessment?: InputMaybe<Order_By>;
  control_ai_review_criteria_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria_content?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  criteria_suggestion?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  run_id?: InputMaybe<Order_By>;
  template_control_ai_review_criteria_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Ai_Review_Assessments_Min_Fields = {
  __typename?: 'control_ai_review_assessments_min_fields';
  assessment?: Maybe<Scalars['String']['output']>;
  control_ai_review_criteria_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  criteria_content?: Maybe<Scalars['String']['output']>;
  criteria_heading?: Maybe<Scalars['String']['output']>;
  criteria_suggestion?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  run_id?: Maybe<Scalars['uuid']['output']>;
  template_control_ai_review_criteria_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Min_Order_By = {
  assessment?: InputMaybe<Order_By>;
  control_ai_review_criteria_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria_content?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  criteria_suggestion?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  run_id?: InputMaybe<Order_By>;
  template_control_ai_review_criteria_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Mutation_Response = {
  __typename?: 'control_ai_review_assessments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Ai_Review_Assessments>;
};

/** input type for inserting object relation for remote table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Obj_Rel_Insert_Input = {
  data: Control_Ai_Review_Assessments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Ai_Review_Assessments_On_Conflict>;
};

/** on_conflict condition type for table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_On_Conflict = {
  constraint: Control_Ai_Review_Assessments_Constraint;
  update_columns?: Array<Control_Ai_Review_Assessments_Update_Column>;
  where?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
};

/** Ordering options when selecting data from "control_ai_review_assessments". */
export type Control_Ai_Review_Assessments_Order_By = {
  assessment?: InputMaybe<Order_By>;
  control_ai_review_assessment_sources_aggregate?: InputMaybe<Control_Ai_Review_Assessment_Sources_Aggregate_Order_By>;
  control_ai_review_criteria_id?: InputMaybe<Order_By>;
  control_ai_review_run?: InputMaybe<Control_Ai_Review_Runs_Order_By>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria_content?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  criteria_suggestion?: InputMaybe<Order_By>;
  high_confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  run_id?: InputMaybe<Order_By>;
  template_control_ai_review_criteria_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_ai_review_assessments */
export type Control_Ai_Review_Assessments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "control_ai_review_assessments" */
export enum Control_Ai_Review_Assessments_Select_Column {
  /** column name */
  Assessment = 'assessment',
  /** column name */
  ControlAiReviewCriteriaId = 'control_ai_review_criteria_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CriteriaContent = 'criteria_content',
  /** column name */
  CriteriaHeading = 'criteria_heading',
  /** column name */
  CriteriaSuggestion = 'criteria_suggestion',
  /** column name */
  HighConfidence = 'high_confidence',
  /** column name */
  Id = 'id',
  /** column name */
  RunId = 'run_id',
  /** column name */
  TemplateControlAiReviewCriteriaId = 'template_control_ai_review_criteria_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "control_ai_review_assessments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "control_ai_review_assessments" */
export enum Control_Ai_Review_Assessments_Select_Column_Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HighConfidence = 'high_confidence',
}

/** select "control_ai_review_assessments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "control_ai_review_assessments" */
export enum Control_Ai_Review_Assessments_Select_Column_Control_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HighConfidence = 'high_confidence',
}

/** input type for updating data in table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Set_Input = {
  assessment?: InputMaybe<Scalars['String']['input']>;
  control_ai_review_criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  criteria_content?: InputMaybe<Scalars['String']['input']>;
  criteria_heading?: InputMaybe<Scalars['String']['input']>;
  criteria_suggestion?: InputMaybe<Scalars['String']['input']>;
  high_confidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  run_id?: InputMaybe<Scalars['uuid']['input']>;
  template_control_ai_review_criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_ai_review_assessments" */
export type Control_Ai_Review_Assessments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Ai_Review_Assessments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Ai_Review_Assessments_Stream_Cursor_Value_Input = {
  assessment?: InputMaybe<Scalars['String']['input']>;
  control_ai_review_criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  criteria_content?: InputMaybe<Scalars['String']['input']>;
  criteria_heading?: InputMaybe<Scalars['String']['input']>;
  criteria_suggestion?: InputMaybe<Scalars['String']['input']>;
  high_confidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  run_id?: InputMaybe<Scalars['uuid']['input']>;
  template_control_ai_review_criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_ai_review_assessments" */
export enum Control_Ai_Review_Assessments_Update_Column {
  /** column name */
  Assessment = 'assessment',
  /** column name */
  ControlAiReviewCriteriaId = 'control_ai_review_criteria_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CriteriaContent = 'criteria_content',
  /** column name */
  CriteriaHeading = 'criteria_heading',
  /** column name */
  CriteriaSuggestion = 'criteria_suggestion',
  /** column name */
  HighConfidence = 'high_confidence',
  /** column name */
  Id = 'id',
  /** column name */
  RunId = 'run_id',
  /** column name */
  TemplateControlAiReviewCriteriaId = 'template_control_ai_review_criteria_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Ai_Review_Assessments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Ai_Review_Assessments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Ai_Review_Assessments_Bool_Exp;
};

/** Set of criteria elements to be added to a control for AI review */
export type Control_Ai_Review_Criteria = {
  __typename?: 'control_ai_review_criteria';
  content: Scalars['String']['output'];
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  heading: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  suggestion: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Aggregate = {
  __typename?: 'control_ai_review_criteria_aggregate';
  aggregate?: Maybe<Control_Ai_Review_Criteria_Aggregate_Fields>;
  nodes: Array<Control_Ai_Review_Criteria>;
};

export type Control_Ai_Review_Criteria_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Ai_Review_Criteria_Aggregate_Bool_Exp_Count>;
};

export type Control_Ai_Review_Criteria_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Ai_Review_Criteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Aggregate_Fields = {
  __typename?: 'control_ai_review_criteria_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Ai_Review_Criteria_Max_Fields>;
  min?: Maybe<Control_Ai_Review_Criteria_Min_Fields>;
};

/** aggregate fields of "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Ai_Review_Criteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Ai_Review_Criteria_Max_Order_By>;
  min?: InputMaybe<Control_Ai_Review_Criteria_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Arr_Rel_Insert_Input = {
  data: Array<Control_Ai_Review_Criteria_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Ai_Review_Criteria_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_ai_review_criteria". All fields are combined with a logical 'AND'. */
export type Control_Ai_Review_Criteria_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Ai_Review_Criteria_Bool_Exp>>;
  _not?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Ai_Review_Criteria_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  heading?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  suggestion?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_ai_review_criteria" */
export enum Control_Ai_Review_Criteria_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlAiReviewCriteriaPkey = 'control_ai_review_criteria_pkey',
}

/** input type for inserting data into table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  suggestion?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Ai_Review_Criteria_Max_Fields = {
  __typename?: 'control_ai_review_criteria_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Ai_Review_Criteria_Min_Fields = {
  __typename?: 'control_ai_review_criteria_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Mutation_Response = {
  __typename?: 'control_ai_review_criteria_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Ai_Review_Criteria>;
};

/** on_conflict condition type for table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_On_Conflict = {
  constraint: Control_Ai_Review_Criteria_Constraint;
  update_columns?: Array<Control_Ai_Review_Criteria_Update_Column>;
  where?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
};

/** Ordering options when selecting data from "control_ai_review_criteria". */
export type Control_Ai_Review_Criteria_Order_By = {
  content?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_ai_review_criteria */
export type Control_Ai_Review_Criteria_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "control_ai_review_criteria" */
export enum Control_Ai_Review_Criteria_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Heading = 'heading',
  /** column name */
  Id = 'id',
  /** column name */
  Suggestion = 'suggestion',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  suggestion?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_ai_review_criteria" */
export type Control_Ai_Review_Criteria_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Ai_Review_Criteria_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Ai_Review_Criteria_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  suggestion?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_ai_review_criteria" */
export enum Control_Ai_Review_Criteria_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Heading = 'heading',
  /** column name */
  Id = 'id',
  /** column name */
  Suggestion = 'suggestion',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Ai_Review_Criteria_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Ai_Review_Criteria_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Ai_Review_Criteria_Bool_Exp;
};

/** Collection of AI Review runs executed for a given control */
export type Control_Ai_Review_Runs = {
  __typename?: 'control_ai_review_runs';
  /** An object relationship */
  control: Controls;
  /** An array relationship */
  control_ai_review_assessments: Array<Control_Ai_Review_Assessments>;
  /** An aggregate relationship */
  control_ai_review_assessments_aggregate: Control_Ai_Review_Assessments_Aggregate;
  /** An object relationship */
  control_ai_review_status: Control_Ai_Review_Status;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  status: Control_Ai_Review_Status_Enum;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** Collection of AI Review runs executed for a given control */
export type Control_Ai_Review_RunsControl_Ai_Review_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
};

/** Collection of AI Review runs executed for a given control */
export type Control_Ai_Review_RunsControl_Ai_Review_Assessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
};

/** aggregated selection of "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Aggregate = {
  __typename?: 'control_ai_review_runs_aggregate';
  aggregate?: Maybe<Control_Ai_Review_Runs_Aggregate_Fields>;
  nodes: Array<Control_Ai_Review_Runs>;
};

export type Control_Ai_Review_Runs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Ai_Review_Runs_Aggregate_Bool_Exp_Count>;
};

export type Control_Ai_Review_Runs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Aggregate_Fields = {
  __typename?: 'control_ai_review_runs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Ai_Review_Runs_Max_Fields>;
  min?: Maybe<Control_Ai_Review_Runs_Min_Fields>;
};

/** aggregate fields of "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Ai_Review_Runs_Max_Order_By>;
  min?: InputMaybe<Control_Ai_Review_Runs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Arr_Rel_Insert_Input = {
  data: Array<Control_Ai_Review_Runs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Ai_Review_Runs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_ai_review_runs". All fields are combined with a logical 'AND'. */
export type Control_Ai_Review_Runs_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Ai_Review_Runs_Bool_Exp>>;
  _not?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Ai_Review_Runs_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_ai_review_assessments?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  control_ai_review_assessments_aggregate?: InputMaybe<Control_Ai_Review_Assessments_Aggregate_Bool_Exp>;
  control_ai_review_status?: InputMaybe<Control_Ai_Review_Status_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Control_Ai_Review_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_ai_review_runs" */
export enum Control_Ai_Review_Runs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlAiReviewRunsPkey = 'control_ai_review_runs_pkey',
}

/** input type for inserting data into table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_ai_review_assessments?: InputMaybe<Control_Ai_Review_Assessments_Arr_Rel_Insert_Input>;
  control_ai_review_status?: InputMaybe<Control_Ai_Review_Status_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Control_Ai_Review_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Ai_Review_Runs_Max_Fields = {
  __typename?: 'control_ai_review_runs_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Ai_Review_Runs_Min_Fields = {
  __typename?: 'control_ai_review_runs_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Mutation_Response = {
  __typename?: 'control_ai_review_runs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Ai_Review_Runs>;
};

/** input type for inserting object relation for remote table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Obj_Rel_Insert_Input = {
  data: Control_Ai_Review_Runs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Ai_Review_Runs_On_Conflict>;
};

/** on_conflict condition type for table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_On_Conflict = {
  constraint: Control_Ai_Review_Runs_Constraint;
  update_columns?: Array<Control_Ai_Review_Runs_Update_Column>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

/** Ordering options when selecting data from "control_ai_review_runs". */
export type Control_Ai_Review_Runs_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_ai_review_assessments_aggregate?: InputMaybe<Control_Ai_Review_Assessments_Aggregate_Order_By>;
  control_ai_review_status?: InputMaybe<Control_Ai_Review_Status_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_ai_review_runs */
export type Control_Ai_Review_Runs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "control_ai_review_runs" */
export enum Control_Ai_Review_Runs_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Control_Ai_Review_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_ai_review_runs" */
export type Control_Ai_Review_Runs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Ai_Review_Runs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Ai_Review_Runs_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Control_Ai_Review_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_ai_review_runs" */
export enum Control_Ai_Review_Runs_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Ai_Review_Runs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Ai_Review_Runs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Ai_Review_Runs_Bool_Exp;
};

/** enum for status of ai review  */
export type Control_Ai_Review_Status = {
  __typename?: 'control_ai_review_status';
  /** An array relationship */
  control_ai_review_runs: Array<Control_Ai_Review_Runs>;
  /** An aggregate relationship */
  control_ai_review_runs_aggregate: Control_Ai_Review_Runs_Aggregate;
  value: Scalars['String']['output'];
};

/** enum for status of ai review  */
export type Control_Ai_Review_StatusControl_Ai_Review_RunsArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

/** enum for status of ai review  */
export type Control_Ai_Review_StatusControl_Ai_Review_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

/** aggregated selection of "control_ai_review_status" */
export type Control_Ai_Review_Status_Aggregate = {
  __typename?: 'control_ai_review_status_aggregate';
  aggregate?: Maybe<Control_Ai_Review_Status_Aggregate_Fields>;
  nodes: Array<Control_Ai_Review_Status>;
};

/** aggregate fields of "control_ai_review_status" */
export type Control_Ai_Review_Status_Aggregate_Fields = {
  __typename?: 'control_ai_review_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Ai_Review_Status_Max_Fields>;
  min?: Maybe<Control_Ai_Review_Status_Min_Fields>;
};

/** aggregate fields of "control_ai_review_status" */
export type Control_Ai_Review_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Ai_Review_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "control_ai_review_status". All fields are combined with a logical 'AND'. */
export type Control_Ai_Review_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Ai_Review_Status_Bool_Exp>>;
  _not?: InputMaybe<Control_Ai_Review_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Ai_Review_Status_Bool_Exp>>;
  control_ai_review_runs?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
  control_ai_review_runs_aggregate?: InputMaybe<Control_Ai_Review_Runs_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_ai_review_status" */
export enum Control_Ai_Review_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  ControlAiReviewStatusPkey = 'control_ai_review_status_pkey',
}

export enum Control_Ai_Review_Status_Enum {
  Failed = 'Failed',
  Pending = 'Pending',
  Succeeded = 'Succeeded',
}

/** Boolean expression to compare columns of type "control_ai_review_status_enum". All fields are combined with logical 'AND'. */
export type Control_Ai_Review_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Control_Ai_Review_Status_Enum>;
  _in?: InputMaybe<Array<Control_Ai_Review_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Control_Ai_Review_Status_Enum>;
  _nin?: InputMaybe<Array<Control_Ai_Review_Status_Enum>>;
};

/** input type for inserting data into table "control_ai_review_status" */
export type Control_Ai_Review_Status_Insert_Input = {
  control_ai_review_runs?: InputMaybe<Control_Ai_Review_Runs_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Control_Ai_Review_Status_Max_Fields = {
  __typename?: 'control_ai_review_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Control_Ai_Review_Status_Min_Fields = {
  __typename?: 'control_ai_review_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "control_ai_review_status" */
export type Control_Ai_Review_Status_Mutation_Response = {
  __typename?: 'control_ai_review_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Ai_Review_Status>;
};

/** input type for inserting object relation for remote table "control_ai_review_status" */
export type Control_Ai_Review_Status_Obj_Rel_Insert_Input = {
  data: Control_Ai_Review_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Ai_Review_Status_On_Conflict>;
};

/** on_conflict condition type for table "control_ai_review_status" */
export type Control_Ai_Review_Status_On_Conflict = {
  constraint: Control_Ai_Review_Status_Constraint;
  update_columns?: Array<Control_Ai_Review_Status_Update_Column>;
  where?: InputMaybe<Control_Ai_Review_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "control_ai_review_status". */
export type Control_Ai_Review_Status_Order_By = {
  control_ai_review_runs_aggregate?: InputMaybe<Control_Ai_Review_Runs_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_ai_review_status */
export type Control_Ai_Review_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "control_ai_review_status" */
export enum Control_Ai_Review_Status_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "control_ai_review_status" */
export type Control_Ai_Review_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "control_ai_review_status" */
export type Control_Ai_Review_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Ai_Review_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Ai_Review_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "control_ai_review_status" */
export enum Control_Ai_Review_Status_Update_Column {
  /** column name */
  Value = 'value',
}

export type Control_Ai_Review_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Ai_Review_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Ai_Review_Status_Bool_Exp;
};

/** columns and relationships of "control_categories" */
export type Control_Categories = {
  __typename?: 'control_categories';
  /** An object relationship */
  category: Categories;
  category_id: Scalars['uuid']['output'];
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "control_categories" */
export type Control_Categories_Aggregate = {
  __typename?: 'control_categories_aggregate';
  aggregate?: Maybe<Control_Categories_Aggregate_Fields>;
  nodes: Array<Control_Categories>;
};

export type Control_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Categories_Aggregate_Bool_Exp_Count>;
};

export type Control_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_categories" */
export type Control_Categories_Aggregate_Fields = {
  __typename?: 'control_categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Categories_Max_Fields>;
  min?: Maybe<Control_Categories_Min_Fields>;
};

/** aggregate fields of "control_categories" */
export type Control_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_categories" */
export type Control_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Categories_Max_Order_By>;
  min?: InputMaybe<Control_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_categories" */
export type Control_Categories_Arr_Rel_Insert_Input = {
  data: Array<Control_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_categories". All fields are combined with a logical 'AND'. */
export type Control_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Categories_Bool_Exp>>;
  _not?: InputMaybe<Control_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Categories_Bool_Exp>>;
  category?: InputMaybe<Categories_Bool_Exp>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_categories" */
export enum Control_Categories_Constraint {
  /** unique or primary key constraint on columns "control_id", "category_id" */
  ControlCategoriesPkey = 'control_categories_pkey',
}

/** input type for inserting data into table "control_categories" */
export type Control_Categories_Insert_Input = {
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Categories_Max_Fields = {
  __typename?: 'control_categories_max_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_categories" */
export type Control_Categories_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Categories_Min_Fields = {
  __typename?: 'control_categories_min_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_categories" */
export type Control_Categories_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_categories" */
export type Control_Categories_Mutation_Response = {
  __typename?: 'control_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Categories>;
};

/** on_conflict condition type for table "control_categories" */
export type Control_Categories_On_Conflict = {
  constraint: Control_Categories_Constraint;
  update_columns?: Array<Control_Categories_Update_Column>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "control_categories". */
export type Control_Categories_Order_By = {
  category?: InputMaybe<Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_categories */
export type Control_Categories_Pk_Columns_Input = {
  category_id: Scalars['uuid']['input'];
  control_id: Scalars['uuid']['input'];
};

/** select columns of table "control_categories" */
export enum Control_Categories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_categories" */
export type Control_Categories_Set_Input = {
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_categories" */
export type Control_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Categories_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_categories" */
export enum Control_Categories_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Categories_Bool_Exp;
};

/** columns and relationships of "control_criteria" */
export type Control_Criteria = {
  __typename?: 'control_criteria';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  criteria: Criteria;
  criteria_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "control_criteria" */
export type Control_Criteria_Aggregate = {
  __typename?: 'control_criteria_aggregate';
  aggregate?: Maybe<Control_Criteria_Aggregate_Fields>;
  nodes: Array<Control_Criteria>;
};

export type Control_Criteria_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Criteria_Aggregate_Bool_Exp_Count>;
};

export type Control_Criteria_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Criteria_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_criteria" */
export type Control_Criteria_Aggregate_Fields = {
  __typename?: 'control_criteria_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Criteria_Max_Fields>;
  min?: Maybe<Control_Criteria_Min_Fields>;
};

/** aggregate fields of "control_criteria" */
export type Control_Criteria_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_criteria" */
export type Control_Criteria_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Criteria_Max_Order_By>;
  min?: InputMaybe<Control_Criteria_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_criteria" */
export type Control_Criteria_Arr_Rel_Insert_Input = {
  data: Array<Control_Criteria_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Criteria_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_criteria". All fields are combined with a logical 'AND'. */
export type Control_Criteria_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Criteria_Bool_Exp>>;
  _not?: InputMaybe<Control_Criteria_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Criteria_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  criteria?: InputMaybe<Criteria_Bool_Exp>;
  criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_criteria" */
export enum Control_Criteria_Constraint {
  /** unique or primary key constraint on columns "criteria_id", "control_id" */
  ControlCriteriaPkey = 'control_criteria_pkey',
}

/** input type for inserting data into table "control_criteria" */
export type Control_Criteria_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  criteria?: InputMaybe<Criteria_Obj_Rel_Insert_Input>;
  criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Criteria_Max_Fields = {
  __typename?: 'control_criteria_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  criteria_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_criteria" */
export type Control_Criteria_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  criteria_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Criteria_Min_Fields = {
  __typename?: 'control_criteria_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  criteria_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_criteria" */
export type Control_Criteria_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  criteria_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_criteria" */
export type Control_Criteria_Mutation_Response = {
  __typename?: 'control_criteria_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Criteria>;
};

/** on_conflict condition type for table "control_criteria" */
export type Control_Criteria_On_Conflict = {
  constraint: Control_Criteria_Constraint;
  update_columns?: Array<Control_Criteria_Update_Column>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

/** Ordering options when selecting data from "control_criteria". */
export type Control_Criteria_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  criteria?: InputMaybe<Criteria_Order_By>;
  criteria_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_criteria */
export type Control_Criteria_Pk_Columns_Input = {
  control_id: Scalars['uuid']['input'];
  criteria_id: Scalars['uuid']['input'];
};

/** select columns of table "control_criteria" */
export enum Control_Criteria_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CriteriaId = 'criteria_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_criteria" */
export type Control_Criteria_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_criteria" */
export type Control_Criteria_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Criteria_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Criteria_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  criteria_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_criteria" */
export enum Control_Criteria_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CriteriaId = 'criteria_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Criteria_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Criteria_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Criteria_Bool_Exp;
};

/** columns and relationships of "control_evidences" */
export type Control_Evidences = {
  __typename?: 'control_evidences';
  /** An object relationship */
  control: Controls;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  /** An aggregate relationship */
  control_findings_aggregate: Control_Findings_Aggregate;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  status: Scalars['ControlEvidenceStatus']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "control_evidences" */
export type Control_EvidencesControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** columns and relationships of "control_evidences" */
export type Control_EvidencesControl_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** aggregated selection of "control_evidences" */
export type Control_Evidences_Aggregate = {
  __typename?: 'control_evidences_aggregate';
  aggregate?: Maybe<Control_Evidences_Aggregate_Fields>;
  nodes: Array<Control_Evidences>;
};

export type Control_Evidences_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Evidences_Aggregate_Bool_Exp_Count>;
};

export type Control_Evidences_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Evidences_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_evidences" */
export type Control_Evidences_Aggregate_Fields = {
  __typename?: 'control_evidences_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Evidences_Max_Fields>;
  min?: Maybe<Control_Evidences_Min_Fields>;
};

/** aggregate fields of "control_evidences" */
export type Control_Evidences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_evidences" */
export type Control_Evidences_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Evidences_Max_Order_By>;
  min?: InputMaybe<Control_Evidences_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_evidences" */
export type Control_Evidences_Arr_Rel_Insert_Input = {
  data: Array<Control_Evidences_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Evidences_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_evidences". All fields are combined with a logical 'AND'. */
export type Control_Evidences_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Evidences_Bool_Exp>>;
  _not?: InputMaybe<Control_Evidences_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Evidences_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_evidences" */
export enum Control_Evidences_Constraint {
  /** unique or primary key constraint on columns "control_id", "evidence_id" */
  ControlEvidencesControlIdEvidenceIdKey = 'control_evidences_control_id_evidence_id_key',
  /** unique or primary key constraint on columns "id" */
  ControlEvidencesPrimaryKey = 'control_evidences_primary_key',
}

/** input type for inserting data into table "control_evidences" */
export type Control_Evidences_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_findings?: InputMaybe<Control_Findings_Arr_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Evidences_Max_Fields = {
  __typename?: 'control_evidences_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_evidences" */
export type Control_Evidences_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Evidences_Min_Fields = {
  __typename?: 'control_evidences_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_evidences" */
export type Control_Evidences_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_evidences" */
export type Control_Evidences_Mutation_Response = {
  __typename?: 'control_evidences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Evidences>;
};

/** input type for inserting object relation for remote table "control_evidences" */
export type Control_Evidences_Obj_Rel_Insert_Input = {
  data: Control_Evidences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Evidences_On_Conflict>;
};

/** on_conflict condition type for table "control_evidences" */
export type Control_Evidences_On_Conflict = {
  constraint: Control_Evidences_Constraint;
  update_columns?: Array<Control_Evidences_Update_Column>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

/** Ordering options when selecting data from "control_evidences". */
export type Control_Evidences_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_evidences */
export type Control_Evidences_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "control_evidences" */
export enum Control_Evidences_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_evidences" */
export type Control_Evidences_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_evidences" */
export type Control_Evidences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Evidences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Evidences_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_evidences" */
export enum Control_Evidences_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Evidences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Evidences_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Evidences_Bool_Exp;
};

/** columns and relationships of "control_findings" */
export type Control_Findings = {
  __typename?: 'control_findings';
  cause: Finding_Causes_Enum;
  /** An object relationship */
  control: Controls;
  /** An object relationship */
  control_ai_review_assessment?: Maybe<Control_Ai_Review_Assessments>;
  control_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  control_evidence?: Maybe<Control_Evidences>;
  control_evidence_id?: Maybe<Scalars['uuid']['output']>;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence_policy?: Maybe<Evidence_Policies>;
  evidence_policy_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  ignored_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  integration_run?: Maybe<Integration_Runs>;
  integration_run_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  type: Finding_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "control_findings" */
export type Control_Findings_Aggregate = {
  __typename?: 'control_findings_aggregate';
  aggregate?: Maybe<Control_Findings_Aggregate_Fields>;
  nodes: Array<Control_Findings>;
};

export type Control_Findings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Findings_Aggregate_Bool_Exp_Count>;
};

export type Control_Findings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Findings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Findings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_findings" */
export type Control_Findings_Aggregate_Fields = {
  __typename?: 'control_findings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Findings_Max_Fields>;
  min?: Maybe<Control_Findings_Min_Fields>;
};

/** aggregate fields of "control_findings" */
export type Control_Findings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Findings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_findings" */
export type Control_Findings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Findings_Max_Order_By>;
  min?: InputMaybe<Control_Findings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_findings" */
export type Control_Findings_Arr_Rel_Insert_Input = {
  data: Array<Control_Findings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Findings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_findings". All fields are combined with a logical 'AND'. */
export type Control_Findings_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Findings_Bool_Exp>>;
  _not?: InputMaybe<Control_Findings_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Findings_Bool_Exp>>;
  cause?: InputMaybe<Finding_Causes_Enum_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_ai_review_assessment?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  control_ai_review_assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  control_evidence?: InputMaybe<Control_Evidences_Bool_Exp>;
  control_evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence_policy?: InputMaybe<Evidence_Policies_Bool_Exp>;
  evidence_policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ignored_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  integration_run?: InputMaybe<Integration_Runs_Bool_Exp>;
  integration_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Finding_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_findings" */
export enum Control_Findings_Constraint {
  /** unique or primary key constraint on columns "control_id", "control_ai_review_assessment_id" */
  ControlFindingsControlIdControlAiReviewAssessmentIdKey = 'control_findings_control_id_control_ai_review_assessment_id_key',
  /** unique or primary key constraint on columns "id" */
  ControlFindingsPkey = 'control_findings_pkey',
  /** unique or primary key constraint on columns "control_evidence_id" */
  ControlFindingsUniqueCauseEvidenceStatus = 'control_findings_unique_cause_evidence_status',
  /** unique or primary key constraint on columns "control_evidence_id" */
  ControlFindingsUniqueCauseIntegrationFailure = 'control_findings_unique_cause_integration_failure',
  /** unique or primary key constraint on columns "control_evidence_id" */
  ControlFindingsUniqueCausePolicyError = 'control_findings_unique_cause_policy_error',
}

/** input type for inserting data into table "control_findings" */
export type Control_Findings_Insert_Input = {
  cause?: InputMaybe<Finding_Causes_Enum>;
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_ai_review_assessment?: InputMaybe<Control_Ai_Review_Assessments_Obj_Rel_Insert_Input>;
  control_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  control_evidence?: InputMaybe<Control_Evidences_Obj_Rel_Insert_Input>;
  control_evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_policy?: InputMaybe<Evidence_Policies_Obj_Rel_Insert_Input>;
  evidence_policy_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ignored_at?: InputMaybe<Scalars['timestamptz']['input']>;
  integration_run?: InputMaybe<Integration_Runs_Obj_Rel_Insert_Input>;
  integration_run_id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Findings_Max_Fields = {
  __typename?: 'control_findings_max_fields';
  control_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
  control_evidence_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_policy_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ignored_at?: Maybe<Scalars['timestamptz']['output']>;
  integration_run_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_findings" */
export type Control_Findings_Max_Order_By = {
  control_ai_review_assessment_id?: InputMaybe<Order_By>;
  control_evidence_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_policy_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  integration_run_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Findings_Min_Fields = {
  __typename?: 'control_findings_min_fields';
  control_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
  control_evidence_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_policy_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ignored_at?: Maybe<Scalars['timestamptz']['output']>;
  integration_run_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_findings" */
export type Control_Findings_Min_Order_By = {
  control_ai_review_assessment_id?: InputMaybe<Order_By>;
  control_evidence_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_policy_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  integration_run_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_findings" */
export type Control_Findings_Mutation_Response = {
  __typename?: 'control_findings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Findings>;
};

/** on_conflict condition type for table "control_findings" */
export type Control_Findings_On_Conflict = {
  constraint: Control_Findings_Constraint;
  update_columns?: Array<Control_Findings_Update_Column>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Ordering options when selecting data from "control_findings". */
export type Control_Findings_Order_By = {
  cause?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_ai_review_assessment?: InputMaybe<Control_Ai_Review_Assessments_Order_By>;
  control_ai_review_assessment_id?: InputMaybe<Order_By>;
  control_evidence?: InputMaybe<Control_Evidences_Order_By>;
  control_evidence_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_policy?: InputMaybe<Evidence_Policies_Order_By>;
  evidence_policy_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  integration_run?: InputMaybe<Integration_Runs_Order_By>;
  integration_run_id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_findings */
export type Control_Findings_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "control_findings" */
export enum Control_Findings_Select_Column {
  /** column name */
  Cause = 'cause',
  /** column name */
  ControlAiReviewAssessmentId = 'control_ai_review_assessment_id',
  /** column name */
  ControlEvidenceId = 'control_evidence_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidencePolicyId = 'evidence_policy_id',
  /** column name */
  Id = 'id',
  /** column name */
  IgnoredAt = 'ignored_at',
  /** column name */
  IntegrationRunId = 'integration_run_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_findings" */
export type Control_Findings_Set_Input = {
  cause?: InputMaybe<Finding_Causes_Enum>;
  control_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  control_evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_policy_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ignored_at?: InputMaybe<Scalars['timestamptz']['input']>;
  integration_run_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_findings" */
export type Control_Findings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Findings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Findings_Stream_Cursor_Value_Input = {
  cause?: InputMaybe<Finding_Causes_Enum>;
  control_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  control_evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_policy_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ignored_at?: InputMaybe<Scalars['timestamptz']['input']>;
  integration_run_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Finding_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_findings" */
export enum Control_Findings_Update_Column {
  /** column name */
  Cause = 'cause',
  /** column name */
  ControlAiReviewAssessmentId = 'control_ai_review_assessment_id',
  /** column name */
  ControlEvidenceId = 'control_evidence_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidencePolicyId = 'evidence_policy_id',
  /** column name */
  Id = 'id',
  /** column name */
  IgnoredAt = 'ignored_at',
  /** column name */
  IntegrationRunId = 'integration_run_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Findings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Findings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Findings_Bool_Exp;
};

/** columns and relationships of "control_frequencies" */
export type Control_Frequencies = {
  __typename?: 'control_frequencies';
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  template_controls: Array<Template_Controls>;
  /** An aggregate relationship */
  template_controls_aggregate: Template_Controls_Aggregate;
};

/** columns and relationships of "control_frequencies" */
export type Control_FrequenciesControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** columns and relationships of "control_frequencies" */
export type Control_FrequenciesControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** columns and relationships of "control_frequencies" */
export type Control_FrequenciesTemplate_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

/** columns and relationships of "control_frequencies" */
export type Control_FrequenciesTemplate_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

/** aggregated selection of "control_frequencies" */
export type Control_Frequencies_Aggregate = {
  __typename?: 'control_frequencies_aggregate';
  aggregate?: Maybe<Control_Frequencies_Aggregate_Fields>;
  nodes: Array<Control_Frequencies>;
};

/** aggregate fields of "control_frequencies" */
export type Control_Frequencies_Aggregate_Fields = {
  __typename?: 'control_frequencies_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Frequencies_Max_Fields>;
  min?: Maybe<Control_Frequencies_Min_Fields>;
};

/** aggregate fields of "control_frequencies" */
export type Control_Frequencies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Frequencies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "control_frequencies". All fields are combined with a logical 'AND'. */
export type Control_Frequencies_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Frequencies_Bool_Exp>>;
  _not?: InputMaybe<Control_Frequencies_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Frequencies_Bool_Exp>>;
  controls?: InputMaybe<Controls_Bool_Exp>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  template_controls?: InputMaybe<Template_Controls_Bool_Exp>;
  template_controls_aggregate?: InputMaybe<Template_Controls_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "control_frequencies" */
export enum Control_Frequencies_Constraint {
  /** unique or primary key constraint on columns "name" */
  ControlFrequenciesPkey = 'control_frequencies_pkey',
}

export enum Control_Frequencies_Enum {
  Annual = 'Annual',
  Biannual = 'Biannual',
  Daily = 'Daily',
  Indefinite = 'Indefinite',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Weekly = 'Weekly',
}

/** Boolean expression to compare columns of type "control_frequencies_enum". All fields are combined with logical 'AND'. */
export type Control_Frequencies_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Control_Frequencies_Enum>;
  _in?: InputMaybe<Array<Control_Frequencies_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Control_Frequencies_Enum>;
  _nin?: InputMaybe<Array<Control_Frequencies_Enum>>;
};

/** input type for inserting data into table "control_frequencies" */
export type Control_Frequencies_Insert_Input = {
  controls?: InputMaybe<Controls_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  template_controls?: InputMaybe<Template_Controls_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Control_Frequencies_Max_Fields = {
  __typename?: 'control_frequencies_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Control_Frequencies_Min_Fields = {
  __typename?: 'control_frequencies_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "control_frequencies" */
export type Control_Frequencies_Mutation_Response = {
  __typename?: 'control_frequencies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Frequencies>;
};

/** input type for inserting object relation for remote table "control_frequencies" */
export type Control_Frequencies_Obj_Rel_Insert_Input = {
  data: Control_Frequencies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Frequencies_On_Conflict>;
};

/** on_conflict condition type for table "control_frequencies" */
export type Control_Frequencies_On_Conflict = {
  constraint: Control_Frequencies_Constraint;
  update_columns?: Array<Control_Frequencies_Update_Column>;
  where?: InputMaybe<Control_Frequencies_Bool_Exp>;
};

/** Ordering options when selecting data from "control_frequencies". */
export type Control_Frequencies_Order_By = {
  controls_aggregate?: InputMaybe<Controls_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  template_controls_aggregate?: InputMaybe<Template_Controls_Aggregate_Order_By>;
};

/** primary key columns input for table: control_frequencies */
export type Control_Frequencies_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "control_frequencies" */
export enum Control_Frequencies_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "control_frequencies" */
export type Control_Frequencies_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "control_frequencies" */
export type Control_Frequencies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Frequencies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Frequencies_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "control_frequencies" */
export enum Control_Frequencies_Update_Column {
  /** column name */
  Name = 'name',
}

export type Control_Frequencies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Frequencies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Frequencies_Bool_Exp;
};

/** columns and relationships of "control_groups" */
export type Control_Groups = {
  __typename?: 'control_groups';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "control_groups" */
export type Control_Groups_Aggregate = {
  __typename?: 'control_groups_aggregate';
  aggregate?: Maybe<Control_Groups_Aggregate_Fields>;
  nodes: Array<Control_Groups>;
};

export type Control_Groups_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Groups_Aggregate_Bool_Exp_Count>;
};

export type Control_Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_groups" */
export type Control_Groups_Aggregate_Fields = {
  __typename?: 'control_groups_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Groups_Max_Fields>;
  min?: Maybe<Control_Groups_Min_Fields>;
};

/** aggregate fields of "control_groups" */
export type Control_Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_groups" */
export type Control_Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Groups_Max_Order_By>;
  min?: InputMaybe<Control_Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_groups" */
export type Control_Groups_Arr_Rel_Insert_Input = {
  data: Array<Control_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_groups". All fields are combined with a logical 'AND'. */
export type Control_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Groups_Bool_Exp>>;
  _not?: InputMaybe<Control_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Groups_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_groups" */
export enum Control_Groups_Constraint {
  /** unique or primary key constraint on columns "control_id", "group_id" */
  ControlGroupsPkey = 'control_groups_pkey',
}

/** input type for inserting data into table "control_groups" */
export type Control_Groups_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Groups_Max_Fields = {
  __typename?: 'control_groups_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_groups" */
export type Control_Groups_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Groups_Min_Fields = {
  __typename?: 'control_groups_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  group_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_groups" */
export type Control_Groups_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_groups" */
export type Control_Groups_Mutation_Response = {
  __typename?: 'control_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Groups>;
};

/** on_conflict condition type for table "control_groups" */
export type Control_Groups_On_Conflict = {
  constraint: Control_Groups_Constraint;
  update_columns?: Array<Control_Groups_Update_Column>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "control_groups". */
export type Control_Groups_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_groups */
export type Control_Groups_Pk_Columns_Input = {
  control_id: Scalars['uuid']['input'];
  group_id: Scalars['uuid']['input'];
};

/** select columns of table "control_groups" */
export enum Control_Groups_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_groups" */
export type Control_Groups_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_groups" */
export type Control_Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Groups_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_groups" */
export enum Control_Groups_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Groups_Bool_Exp;
};

/** columns and relationships of "control_priorities" */
export type Control_Priorities = {
  __typename?: 'control_priorities';
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  template_controls: Array<Template_Controls>;
  /** An aggregate relationship */
  template_controls_aggregate: Template_Controls_Aggregate;
};

/** columns and relationships of "control_priorities" */
export type Control_PrioritiesControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** columns and relationships of "control_priorities" */
export type Control_PrioritiesControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** columns and relationships of "control_priorities" */
export type Control_PrioritiesTemplate_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

/** columns and relationships of "control_priorities" */
export type Control_PrioritiesTemplate_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

/** aggregated selection of "control_priorities" */
export type Control_Priorities_Aggregate = {
  __typename?: 'control_priorities_aggregate';
  aggregate?: Maybe<Control_Priorities_Aggregate_Fields>;
  nodes: Array<Control_Priorities>;
};

/** aggregate fields of "control_priorities" */
export type Control_Priorities_Aggregate_Fields = {
  __typename?: 'control_priorities_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Priorities_Max_Fields>;
  min?: Maybe<Control_Priorities_Min_Fields>;
};

/** aggregate fields of "control_priorities" */
export type Control_Priorities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Priorities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "control_priorities". All fields are combined with a logical 'AND'. */
export type Control_Priorities_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Priorities_Bool_Exp>>;
  _not?: InputMaybe<Control_Priorities_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Priorities_Bool_Exp>>;
  controls?: InputMaybe<Controls_Bool_Exp>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  template_controls?: InputMaybe<Template_Controls_Bool_Exp>;
  template_controls_aggregate?: InputMaybe<Template_Controls_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "control_priorities" */
export enum Control_Priorities_Constraint {
  /** unique or primary key constraint on columns "name" */
  ControlPrioritiesPkey = 'control_priorities_pkey',
}

export enum Control_Priorities_Enum {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
}

/** Boolean expression to compare columns of type "control_priorities_enum". All fields are combined with logical 'AND'. */
export type Control_Priorities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Control_Priorities_Enum>;
  _in?: InputMaybe<Array<Control_Priorities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Control_Priorities_Enum>;
  _nin?: InputMaybe<Array<Control_Priorities_Enum>>;
};

/** input type for inserting data into table "control_priorities" */
export type Control_Priorities_Insert_Input = {
  controls?: InputMaybe<Controls_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  template_controls?: InputMaybe<Template_Controls_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Control_Priorities_Max_Fields = {
  __typename?: 'control_priorities_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Control_Priorities_Min_Fields = {
  __typename?: 'control_priorities_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "control_priorities" */
export type Control_Priorities_Mutation_Response = {
  __typename?: 'control_priorities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Priorities>;
};

/** input type for inserting object relation for remote table "control_priorities" */
export type Control_Priorities_Obj_Rel_Insert_Input = {
  data: Control_Priorities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Priorities_On_Conflict>;
};

/** on_conflict condition type for table "control_priorities" */
export type Control_Priorities_On_Conflict = {
  constraint: Control_Priorities_Constraint;
  update_columns?: Array<Control_Priorities_Update_Column>;
  where?: InputMaybe<Control_Priorities_Bool_Exp>;
};

/** Ordering options when selecting data from "control_priorities". */
export type Control_Priorities_Order_By = {
  controls_aggregate?: InputMaybe<Controls_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  template_controls_aggregate?: InputMaybe<Template_Controls_Aggregate_Order_By>;
};

/** primary key columns input for table: control_priorities */
export type Control_Priorities_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "control_priorities" */
export enum Control_Priorities_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "control_priorities" */
export type Control_Priorities_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "control_priorities" */
export type Control_Priorities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Priorities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Priorities_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "control_priorities" */
export enum Control_Priorities_Update_Column {
  /** column name */
  Name = 'name',
}

export type Control_Priorities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Priorities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Priorities_Bool_Exp;
};

/** columns and relationships of "control_tags" */
export type Control_Tags = {
  __typename?: 'control_tags';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "control_tags" */
export type Control_Tags_Aggregate = {
  __typename?: 'control_tags_aggregate';
  aggregate?: Maybe<Control_Tags_Aggregate_Fields>;
  nodes: Array<Control_Tags>;
};

export type Control_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Tags_Aggregate_Bool_Exp_Count>;
};

export type Control_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_tags" */
export type Control_Tags_Aggregate_Fields = {
  __typename?: 'control_tags_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Tags_Max_Fields>;
  min?: Maybe<Control_Tags_Min_Fields>;
};

/** aggregate fields of "control_tags" */
export type Control_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_tags" */
export type Control_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Tags_Max_Order_By>;
  min?: InputMaybe<Control_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_tags" */
export type Control_Tags_Arr_Rel_Insert_Input = {
  data: Array<Control_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_tags". All fields are combined with a logical 'AND'. */
export type Control_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Tags_Bool_Exp>>;
  _not?: InputMaybe<Control_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Tags_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_tags" */
export enum Control_Tags_Constraint {
  /** unique or primary key constraint on columns "control_id", "tag_id" */
  ControlTagsPkey = 'control_tags_pkey',
}

/** input type for inserting data into table "control_tags" */
export type Control_Tags_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Control_Tags_Max_Fields = {
  __typename?: 'control_tags_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  tag_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "control_tags" */
export type Control_Tags_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Tags_Min_Fields = {
  __typename?: 'control_tags_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  tag_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "control_tags" */
export type Control_Tags_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_tags" */
export type Control_Tags_Mutation_Response = {
  __typename?: 'control_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Tags>;
};

/** on_conflict condition type for table "control_tags" */
export type Control_Tags_On_Conflict = {
  constraint: Control_Tags_Constraint;
  update_columns?: Array<Control_Tags_Update_Column>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "control_tags". */
export type Control_Tags_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_tags */
export type Control_Tags_Pk_Columns_Input = {
  control_id: Scalars['uuid']['input'];
  tag_id: Scalars['uuid']['input'];
};

/** select columns of table "control_tags" */
export enum Control_Tags_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_tags" */
export type Control_Tags_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "control_tags" */
export type Control_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Tags_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "control_tags" */
export enum Control_Tags_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Control_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Tags_Bool_Exp;
};

export type Control_Tags_View = {
  __typename?: 'control_tags_view';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** columns and relationships of "control_tasks" */
export type Control_Tasks = {
  __typename?: 'control_tasks';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid']['output'];
};

/** aggregated selection of "control_tasks" */
export type Control_Tasks_Aggregate = {
  __typename?: 'control_tasks_aggregate';
  aggregate?: Maybe<Control_Tasks_Aggregate_Fields>;
  nodes: Array<Control_Tasks>;
};

export type Control_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Control_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_tasks" */
export type Control_Tasks_Aggregate_Fields = {
  __typename?: 'control_tasks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Tasks_Max_Fields>;
  min?: Maybe<Control_Tasks_Min_Fields>;
};

/** aggregate fields of "control_tasks" */
export type Control_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_tasks" */
export type Control_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Tasks_Max_Order_By>;
  min?: InputMaybe<Control_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_tasks" */
export type Control_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Control_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_tasks". All fields are combined with a logical 'AND'. */
export type Control_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Control_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Tasks_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_tasks" */
export enum Control_Tasks_Constraint {
  /** unique or primary key constraint on columns "control_id", "task_id" */
  ControlTasksControlIdTaskIdKey = 'control_tasks_control_id_task_id_key',
  /** unique or primary key constraint on columns "id" */
  ControlTasksPkey = 'control_tasks_pkey',
}

/** input type for inserting data into table "control_tasks" */
export type Control_Tasks_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Control_Tasks_Max_Fields = {
  __typename?: 'control_tasks_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "control_tasks" */
export type Control_Tasks_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Tasks_Min_Fields = {
  __typename?: 'control_tasks_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "control_tasks" */
export type Control_Tasks_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_tasks" */
export type Control_Tasks_Mutation_Response = {
  __typename?: 'control_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Tasks>;
};

/** on_conflict condition type for table "control_tasks" */
export type Control_Tasks_On_Conflict = {
  constraint: Control_Tasks_Constraint;
  update_columns?: Array<Control_Tasks_Update_Column>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "control_tasks". */
export type Control_Tasks_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_tasks */
export type Control_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "control_tasks" */
export enum Control_Tasks_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "control_tasks" */
export type Control_Tasks_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "control_tasks" */
export type Control_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Control_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Control_Tasks_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "control_tasks" */
export enum Control_Tasks_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
}

export type Control_Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Tasks_Bool_Exp;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type Controls = {
  __typename?: 'controls';
  /** An object relationship */
  assignee?: Maybe<Users>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  categories: Array<Control_Categories>;
  /** An aggregate relationship */
  categories_aggregate: Control_Categories_Aggregate;
  /** An array relationship */
  client_questionnaire_answer_ai_sources: Array<Client_Questionnaire_Answer_Ai_Sources>;
  /** An aggregate relationship */
  client_questionnaire_answer_ai_sources_aggregate: Client_Questionnaire_Answer_Ai_Sources_Aggregate;
  /** An array relationship */
  control_ai_review_criteria: Array<Control_Ai_Review_Criteria>;
  /** An aggregate relationship */
  control_ai_review_criteria_aggregate: Control_Ai_Review_Criteria_Aggregate;
  /** An array relationship */
  control_ai_review_runs: Array<Control_Ai_Review_Runs>;
  /** An aggregate relationship */
  control_ai_review_runs_aggregate: Control_Ai_Review_Runs_Aggregate;
  /** An array relationship */
  control_evidences: Array<Control_Evidences>;
  /** An aggregate relationship */
  control_evidences_aggregate: Control_Evidences_Aggregate;
  /** An object relationship */
  control_frequency: Control_Frequencies;
  /** An object relationship */
  control_priority?: Maybe<Control_Priorities>;
  /** An array relationship */
  control_tasks: Array<Control_Tasks>;
  /** An aggregate relationship */
  control_tasks_aggregate: Control_Tasks_Aggregate;
  /** An array relationship */
  controls_comments: Array<Comments>;
  /** An aggregate relationship */
  controls_comments_aggregate: Comments_Aggregate;
  /** An object relationship */
  controls_vector_store?: Maybe<Ai_Controls_Vector_Store>;
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  criterias: Array<Control_Criteria>;
  /** An aggregate relationship */
  criterias_aggregate: Control_Criteria_Aggregate;
  /** An array relationship */
  custom_tags: Array<Control_Tags>;
  /** An aggregate relationship */
  custom_tags_aggregate: Control_Tags_Aggregate;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  /** An array relationship */
  findings: Array<Control_Findings>;
  /** An aggregate relationship */
  findings_aggregate: Control_Findings_Aggregate;
  /** An array relationship */
  frameworks_controls: Array<Frameworks_Controls>;
  /** An aggregate relationship */
  frameworks_controls_aggregate: Frameworks_Controls_Aggregate;
  frequency: Control_Frequencies_Enum;
  /** An array relationship */
  groups: Array<Control_Groups>;
  /** An aggregate relationship */
  groups_aggregate: Control_Groups_Aggregate;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  irrelevant: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  priority?: Maybe<Control_Priorities_Enum>;
  /** An array relationship */
  program_controls: Array<Program_Controls>;
  /** An aggregate relationship */
  program_controls_aggregate: Program_Controls_Aggregate;
  /** An array relationship */
  risk_controls: Array<Risk_Controls>;
  /** An aggregate relationship */
  risk_controls_aggregate: Risk_Controls_Aggregate;
  similarity?: Maybe<Scalars['float8']['output']>;
  status: Scalars['ControlStatus']['output'];
  tags: Scalars['ControlTags']['output'];
  /** An object relationship */
  template_control?: Maybe<Template_Controls>;
  template_control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsClient_Questionnaire_Answer_Ai_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsClient_Questionnaire_Answer_Ai_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_Ai_Review_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_Ai_Review_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_Ai_Review_RunsArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_Ai_Review_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_EvidencesArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_Evidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_TasksArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControls_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControls_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCriteriasArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCriterias_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCustom_TagsArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCustom_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsFindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsFindings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsFrameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsFrameworks_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsProgram_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsProgram_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsRisk_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsRisk_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

/** aggregated selection of "controls" */
export type Controls_Aggregate = {
  __typename?: 'controls_aggregate';
  aggregate?: Maybe<Controls_Aggregate_Fields>;
  nodes: Array<Controls>;
};

export type Controls_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Controls_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Controls_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Controls_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Controls_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Controls_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Controls_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Controls_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Controls_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Controls_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Controls_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Controls_Aggregate_Bool_Exp_Var_Samp>;
};

export type Controls_Aggregate_Bool_Exp_Avg = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Bool_And = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Corr = {
  arguments: Controls_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Controls_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Controls_Aggregate_Bool_Exp_Max = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Min = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Sum = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "controls" */
export type Controls_Aggregate_Fields = {
  __typename?: 'controls_aggregate_fields';
  avg?: Maybe<Controls_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Controls_Max_Fields>;
  min?: Maybe<Controls_Min_Fields>;
  stddev?: Maybe<Controls_Stddev_Fields>;
  stddev_pop?: Maybe<Controls_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Controls_Stddev_Samp_Fields>;
  sum?: Maybe<Controls_Sum_Fields>;
  var_pop?: Maybe<Controls_Var_Pop_Fields>;
  var_samp?: Maybe<Controls_Var_Samp_Fields>;
  variance?: Maybe<Controls_Variance_Fields>;
};

/** aggregate fields of "controls" */
export type Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "controls" */
export type Controls_Aggregate_Order_By = {
  avg?: InputMaybe<Controls_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Controls_Max_Order_By>;
  min?: InputMaybe<Controls_Min_Order_By>;
  stddev?: InputMaybe<Controls_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Controls_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Controls_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Controls_Sum_Order_By>;
  var_pop?: InputMaybe<Controls_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Controls_Var_Samp_Order_By>;
  variance?: InputMaybe<Controls_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "controls" */
export type Controls_Arr_Rel_Insert_Input = {
  data: Array<Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Controls_On_Conflict>;
};

/** aggregate avg on columns */
export type Controls_Avg_Fields = {
  __typename?: 'controls_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "controls" */
export type Controls_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "controls". All fields are combined with a logical 'AND'. */
export type Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Controls_Bool_Exp>>;
  _not?: InputMaybe<Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Controls_Bool_Exp>>;
  assignee?: InputMaybe<Users_Bool_Exp>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  categories?: InputMaybe<Control_Categories_Bool_Exp>;
  categories_aggregate?: InputMaybe<Control_Categories_Aggregate_Bool_Exp>;
  client_questionnaire_answer_ai_sources?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
  client_questionnaire_answer_ai_sources_aggregate?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Bool_Exp>;
  control_ai_review_criteria?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
  control_ai_review_criteria_aggregate?: InputMaybe<Control_Ai_Review_Criteria_Aggregate_Bool_Exp>;
  control_ai_review_runs?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
  control_ai_review_runs_aggregate?: InputMaybe<Control_Ai_Review_Runs_Aggregate_Bool_Exp>;
  control_evidences?: InputMaybe<Control_Evidences_Bool_Exp>;
  control_evidences_aggregate?: InputMaybe<Control_Evidences_Aggregate_Bool_Exp>;
  control_frequency?: InputMaybe<Control_Frequencies_Bool_Exp>;
  control_priority?: InputMaybe<Control_Priorities_Bool_Exp>;
  control_tasks?: InputMaybe<Control_Tasks_Bool_Exp>;
  control_tasks_aggregate?: InputMaybe<Control_Tasks_Aggregate_Bool_Exp>;
  controls_comments?: InputMaybe<Comments_Bool_Exp>;
  controls_comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  controls_vector_store?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  criterias?: InputMaybe<Control_Criteria_Bool_Exp>;
  criterias_aggregate?: InputMaybe<Control_Criteria_Aggregate_Bool_Exp>;
  custom_tags?: InputMaybe<Control_Tags_Bool_Exp>;
  custom_tags_aggregate?: InputMaybe<Control_Tags_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Bool_Exp>;
  findings?: InputMaybe<Control_Findings_Bool_Exp>;
  findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Bool_Exp>;
  frameworks_controls?: InputMaybe<Frameworks_Controls_Bool_Exp>;
  frameworks_controls_aggregate?: InputMaybe<Frameworks_Controls_Aggregate_Bool_Exp>;
  frequency?: InputMaybe<Control_Frequencies_Enum_Comparison_Exp>;
  groups?: InputMaybe<Control_Groups_Bool_Exp>;
  groups_aggregate?: InputMaybe<Control_Groups_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  irrelevant?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<Control_Priorities_Enum_Comparison_Exp>;
  program_controls?: InputMaybe<Program_Controls_Bool_Exp>;
  program_controls_aggregate?: InputMaybe<Program_Controls_Aggregate_Bool_Exp>;
  risk_controls?: InputMaybe<Risk_Controls_Bool_Exp>;
  risk_controls_aggregate?: InputMaybe<Risk_Controls_Aggregate_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  template_control?: InputMaybe<Template_Controls_Bool_Exp>;
  template_control_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "controls" */
export enum Controls_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramControlsPkey = 'program_controls_pkey',
}

/** columns and relationships of "controls_history_stats" */
export type Controls_History_Stats = {
  __typename?: 'controls_history_stats';
  /** An object relationship */
  assignee?: Maybe<Users>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  at_risk_count: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  failing_count: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  not_relevant_count: Scalars['Int']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  pending_count: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  valid_count: Scalars['Int']['output'];
};

/** aggregated selection of "controls_history_stats" */
export type Controls_History_Stats_Aggregate = {
  __typename?: 'controls_history_stats_aggregate';
  aggregate?: Maybe<Controls_History_Stats_Aggregate_Fields>;
  nodes: Array<Controls_History_Stats>;
};

export type Controls_History_Stats_Aggregate_Bool_Exp = {
  count?: InputMaybe<Controls_History_Stats_Aggregate_Bool_Exp_Count>;
};

export type Controls_History_Stats_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_History_Stats_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "controls_history_stats" */
export type Controls_History_Stats_Aggregate_Fields = {
  __typename?: 'controls_history_stats_aggregate_fields';
  avg?: Maybe<Controls_History_Stats_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Controls_History_Stats_Max_Fields>;
  min?: Maybe<Controls_History_Stats_Min_Fields>;
  stddev?: Maybe<Controls_History_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Controls_History_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Controls_History_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Controls_History_Stats_Sum_Fields>;
  var_pop?: Maybe<Controls_History_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Controls_History_Stats_Var_Samp_Fields>;
  variance?: Maybe<Controls_History_Stats_Variance_Fields>;
};

/** aggregate fields of "controls_history_stats" */
export type Controls_History_Stats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "controls_history_stats" */
export type Controls_History_Stats_Aggregate_Order_By = {
  avg?: InputMaybe<Controls_History_Stats_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Controls_History_Stats_Max_Order_By>;
  min?: InputMaybe<Controls_History_Stats_Min_Order_By>;
  stddev?: InputMaybe<Controls_History_Stats_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Controls_History_Stats_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Controls_History_Stats_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Controls_History_Stats_Sum_Order_By>;
  var_pop?: InputMaybe<Controls_History_Stats_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Controls_History_Stats_Var_Samp_Order_By>;
  variance?: InputMaybe<Controls_History_Stats_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "controls_history_stats" */
export type Controls_History_Stats_Arr_Rel_Insert_Input = {
  data: Array<Controls_History_Stats_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Controls_History_Stats_On_Conflict>;
};

/** aggregate avg on columns */
export type Controls_History_Stats_Avg_Fields = {
  __typename?: 'controls_history_stats_avg_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Avg_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "controls_history_stats". All fields are combined with a logical 'AND'. */
export type Controls_History_Stats_Bool_Exp = {
  _and?: InputMaybe<Array<Controls_History_Stats_Bool_Exp>>;
  _not?: InputMaybe<Controls_History_Stats_Bool_Exp>;
  _or?: InputMaybe<Array<Controls_History_Stats_Bool_Exp>>;
  assignee?: InputMaybe<Users_Bool_Exp>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  at_risk_count?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  failing_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  not_relevant_count?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  pending_count?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "controls_history_stats" */
export enum Controls_History_Stats_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlsHistoryStatsPkey = 'controls_history_stats_pkey',
}

/** input type for incrementing numeric columns in table "controls_history_stats" */
export type Controls_History_Stats_Inc_Input = {
  at_risk_count?: InputMaybe<Scalars['Int']['input']>;
  failing_count?: InputMaybe<Scalars['Int']['input']>;
  not_relevant_count?: InputMaybe<Scalars['Int']['input']>;
  pending_count?: InputMaybe<Scalars['Int']['input']>;
  valid_count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "controls_history_stats" */
export type Controls_History_Stats_Insert_Input = {
  assignee?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  at_risk_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failing_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  not_relevant_count?: InputMaybe<Scalars['Int']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  pending_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  valid_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Controls_History_Stats_Max_Fields = {
  __typename?: 'controls_history_stats_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  at_risk_count?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  failing_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  not_relevant_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  valid_count?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Max_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  at_risk_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Controls_History_Stats_Min_Fields = {
  __typename?: 'controls_history_stats_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  at_risk_count?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  failing_count?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  not_relevant_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  valid_count?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Min_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  at_risk_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "controls_history_stats" */
export type Controls_History_Stats_Mutation_Response = {
  __typename?: 'controls_history_stats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Controls_History_Stats>;
};

/** on_conflict condition type for table "controls_history_stats" */
export type Controls_History_Stats_On_Conflict = {
  constraint: Controls_History_Stats_Constraint;
  update_columns?: Array<Controls_History_Stats_Update_Column>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

/** Ordering options when selecting data from "controls_history_stats". */
export type Controls_History_Stats_Order_By = {
  assignee?: InputMaybe<Users_Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  at_risk_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: controls_history_stats */
export type Controls_History_Stats_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "controls_history_stats" */
export enum Controls_History_Stats_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  AtRiskCount = 'at_risk_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailingCount = 'failing_count',
  /** column name */
  Id = 'id',
  /** column name */
  NotRelevantCount = 'not_relevant_count',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PendingCount = 'pending_count',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidCount = 'valid_count',
}

/** input type for updating data in table "controls_history_stats" */
export type Controls_History_Stats_Set_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  at_risk_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failing_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  not_relevant_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  pending_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  valid_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Controls_History_Stats_Stddev_Fields = {
  __typename?: 'controls_history_stats_stddev_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Stddev_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Controls_History_Stats_Stddev_Pop_Fields = {
  __typename?: 'controls_history_stats_stddev_pop_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Stddev_Pop_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Controls_History_Stats_Stddev_Samp_Fields = {
  __typename?: 'controls_history_stats_stddev_samp_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Stddev_Samp_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "controls_history_stats" */
export type Controls_History_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Controls_History_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Controls_History_Stats_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  at_risk_count?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  failing_count?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  not_relevant_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  pending_count?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  valid_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Controls_History_Stats_Sum_Fields = {
  __typename?: 'controls_history_stats_sum_fields';
  at_risk_count?: Maybe<Scalars['Int']['output']>;
  failing_count?: Maybe<Scalars['Int']['output']>;
  not_relevant_count?: Maybe<Scalars['Int']['output']>;
  pending_count?: Maybe<Scalars['Int']['output']>;
  valid_count?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Sum_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** update columns of table "controls_history_stats" */
export enum Controls_History_Stats_Update_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  AtRiskCount = 'at_risk_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailingCount = 'failing_count',
  /** column name */
  Id = 'id',
  /** column name */
  NotRelevantCount = 'not_relevant_count',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PendingCount = 'pending_count',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidCount = 'valid_count',
}

export type Controls_History_Stats_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Controls_History_Stats_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Controls_History_Stats_Set_Input>;
  /** filter the rows which have to be updated */
  where: Controls_History_Stats_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Controls_History_Stats_Var_Pop_Fields = {
  __typename?: 'controls_history_stats_var_pop_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Var_Pop_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Controls_History_Stats_Var_Samp_Fields = {
  __typename?: 'controls_history_stats_var_samp_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Var_Samp_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Controls_History_Stats_Variance_Fields = {
  __typename?: 'controls_history_stats_variance_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Variance_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "controls" */
export type Controls_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "controls" */
export type Controls_Insert_Input = {
  assignee?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  categories?: InputMaybe<Control_Categories_Arr_Rel_Insert_Input>;
  client_questionnaire_answer_ai_sources?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Arr_Rel_Insert_Input>;
  control_ai_review_criteria?: InputMaybe<Control_Ai_Review_Criteria_Arr_Rel_Insert_Input>;
  control_ai_review_runs?: InputMaybe<Control_Ai_Review_Runs_Arr_Rel_Insert_Input>;
  control_evidences?: InputMaybe<Control_Evidences_Arr_Rel_Insert_Input>;
  control_frequency?: InputMaybe<Control_Frequencies_Obj_Rel_Insert_Input>;
  control_priority?: InputMaybe<Control_Priorities_Obj_Rel_Insert_Input>;
  control_tasks?: InputMaybe<Control_Tasks_Arr_Rel_Insert_Input>;
  controls_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  controls_vector_store?: InputMaybe<Ai_Controls_Vector_Store_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  criterias?: InputMaybe<Control_Criteria_Arr_Rel_Insert_Input>;
  custom_tags?: InputMaybe<Control_Tags_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  findings?: InputMaybe<Control_Findings_Arr_Rel_Insert_Input>;
  frameworks_controls?: InputMaybe<Frameworks_Controls_Arr_Rel_Insert_Input>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  groups?: InputMaybe<Control_Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  irrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  program_controls?: InputMaybe<Program_Controls_Arr_Rel_Insert_Input>;
  risk_controls?: InputMaybe<Risk_Controls_Arr_Rel_Insert_Input>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_control?: InputMaybe<Template_Controls_Obj_Rel_Insert_Input>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Controls_Max_Fields = {
  __typename?: 'controls_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "controls" */
export type Controls_Max_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Controls_Min_Fields = {
  __typename?: 'controls_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "controls" */
export type Controls_Min_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "controls" */
export type Controls_Mutation_Response = {
  __typename?: 'controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Controls>;
};

/** input type for inserting object relation for remote table "controls" */
export type Controls_Obj_Rel_Insert_Input = {
  data: Controls_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Controls_On_Conflict>;
};

/** on_conflict condition type for table "controls" */
export type Controls_On_Conflict = {
  constraint: Controls_Constraint;
  update_columns?: Array<Controls_Update_Column>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "controls". */
export type Controls_Order_By = {
  assignee?: InputMaybe<Users_Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  categories_aggregate?: InputMaybe<Control_Categories_Aggregate_Order_By>;
  client_questionnaire_answer_ai_sources_aggregate?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Aggregate_Order_By>;
  control_ai_review_criteria_aggregate?: InputMaybe<Control_Ai_Review_Criteria_Aggregate_Order_By>;
  control_ai_review_runs_aggregate?: InputMaybe<Control_Ai_Review_Runs_Aggregate_Order_By>;
  control_evidences_aggregate?: InputMaybe<Control_Evidences_Aggregate_Order_By>;
  control_frequency?: InputMaybe<Control_Frequencies_Order_By>;
  control_priority?: InputMaybe<Control_Priorities_Order_By>;
  control_tasks_aggregate?: InputMaybe<Control_Tasks_Aggregate_Order_By>;
  controls_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  controls_vector_store?: InputMaybe<Ai_Controls_Vector_Store_Order_By>;
  created_at?: InputMaybe<Order_By>;
  criterias_aggregate?: InputMaybe<Control_Criteria_Aggregate_Order_By>;
  custom_tags_aggregate?: InputMaybe<Control_Tags_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  frameworks_controls_aggregate?: InputMaybe<Frameworks_Controls_Aggregate_Order_By>;
  frequency?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Control_Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  irrelevant?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  program_controls_aggregate?: InputMaybe<Program_Controls_Aggregate_Order_By>;
  risk_controls_aggregate?: InputMaybe<Risk_Controls_Aggregate_Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_control?: InputMaybe<Template_Controls_Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: controls */
export type Controls_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "controls" */
export enum Controls_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Irrelevant = 'irrelevant',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateControlId = 'template_control_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "controls_aggregate_bool_exp_avg_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_bool_and_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Irrelevant = 'irrelevant',
}

/** select "controls_aggregate_bool_exp_bool_or_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Irrelevant = 'irrelevant',
}

/** select "controls_aggregate_bool_exp_corr_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_max_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_min_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_sum_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_var_samp_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "controls" */
export type Controls_Set_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  irrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "controls_stats_view" */
export type Controls_Stats_View = {
  __typename?: 'controls_stats_view';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  at_risk_count?: Maybe<Scalars['bigint']['output']>;
  failing_count?: Maybe<Scalars['bigint']['output']>;
  not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['bigint']['output']>;
  valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "controls_stats_view" */
export type Controls_Stats_View_Aggregate = {
  __typename?: 'controls_stats_view_aggregate';
  aggregate?: Maybe<Controls_Stats_View_Aggregate_Fields>;
  nodes: Array<Controls_Stats_View>;
};

/** aggregate fields of "controls_stats_view" */
export type Controls_Stats_View_Aggregate_Fields = {
  __typename?: 'controls_stats_view_aggregate_fields';
  avg?: Maybe<Controls_Stats_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Controls_Stats_View_Max_Fields>;
  min?: Maybe<Controls_Stats_View_Min_Fields>;
  stddev?: Maybe<Controls_Stats_View_Stddev_Fields>;
  stddev_pop?: Maybe<Controls_Stats_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Controls_Stats_View_Stddev_Samp_Fields>;
  sum?: Maybe<Controls_Stats_View_Sum_Fields>;
  var_pop?: Maybe<Controls_Stats_View_Var_Pop_Fields>;
  var_samp?: Maybe<Controls_Stats_View_Var_Samp_Fields>;
  variance?: Maybe<Controls_Stats_View_Variance_Fields>;
};

/** aggregate fields of "controls_stats_view" */
export type Controls_Stats_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Controls_Stats_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Controls_Stats_View_Avg_Fields = {
  __typename?: 'controls_stats_view_avg_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "controls_stats_view". All fields are combined with a logical 'AND'. */
export type Controls_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<Controls_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<Controls_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<Controls_Stats_View_Bool_Exp>>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  at_risk_count?: InputMaybe<Bigint_Comparison_Exp>;
  failing_count?: InputMaybe<Bigint_Comparison_Exp>;
  not_relevant_count?: InputMaybe<Bigint_Comparison_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  pending_count?: InputMaybe<Bigint_Comparison_Exp>;
  valid_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Controls_Stats_View_Max_Fields = {
  __typename?: 'controls_stats_view_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  at_risk_count?: Maybe<Scalars['bigint']['output']>;
  failing_count?: Maybe<Scalars['bigint']['output']>;
  not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['bigint']['output']>;
  valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Controls_Stats_View_Min_Fields = {
  __typename?: 'controls_stats_view_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  at_risk_count?: Maybe<Scalars['bigint']['output']>;
  failing_count?: Maybe<Scalars['bigint']['output']>;
  not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['bigint']['output']>;
  valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "controls_stats_view". */
export type Controls_Stats_View_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** select columns of table "controls_stats_view" */
export enum Controls_Stats_View_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  AtRiskCount = 'at_risk_count',
  /** column name */
  FailingCount = 'failing_count',
  /** column name */
  NotRelevantCount = 'not_relevant_count',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PendingCount = 'pending_count',
  /** column name */
  ValidCount = 'valid_count',
}

/** aggregate stddev on columns */
export type Controls_Stats_View_Stddev_Fields = {
  __typename?: 'controls_stats_view_stddev_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Controls_Stats_View_Stddev_Pop_Fields = {
  __typename?: 'controls_stats_view_stddev_pop_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Controls_Stats_View_Stddev_Samp_Fields = {
  __typename?: 'controls_stats_view_stddev_samp_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "controls_stats_view" */
export type Controls_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Controls_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Controls_Stats_View_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  at_risk_count?: InputMaybe<Scalars['bigint']['input']>;
  failing_count?: InputMaybe<Scalars['bigint']['input']>;
  not_relevant_count?: InputMaybe<Scalars['bigint']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  pending_count?: InputMaybe<Scalars['bigint']['input']>;
  valid_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Controls_Stats_View_Sum_Fields = {
  __typename?: 'controls_stats_view_sum_fields';
  at_risk_count?: Maybe<Scalars['bigint']['output']>;
  failing_count?: Maybe<Scalars['bigint']['output']>;
  not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  pending_count?: Maybe<Scalars['bigint']['output']>;
  valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type Controls_Stats_View_Var_Pop_Fields = {
  __typename?: 'controls_stats_view_var_pop_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Controls_Stats_View_Var_Samp_Fields = {
  __typename?: 'controls_stats_view_var_samp_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Controls_Stats_View_Variance_Fields = {
  __typename?: 'controls_stats_view_variance_fields';
  at_risk_count?: Maybe<Scalars['Float']['output']>;
  failing_count?: Maybe<Scalars['Float']['output']>;
  not_relevant_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev on columns */
export type Controls_Stddev_Fields = {
  __typename?: 'controls_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "controls" */
export type Controls_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Controls_Stddev_Pop_Fields = {
  __typename?: 'controls_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "controls" */
export type Controls_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Controls_Stddev_Samp_Fields = {
  __typename?: 'controls_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "controls" */
export type Controls_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "controls" */
export type Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Controls_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  irrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Controls_Sum_Fields = {
  __typename?: 'controls_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "controls" */
export type Controls_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "controls" */
export enum Controls_Update_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Irrelevant = 'irrelevant',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateControlId = 'template_control_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Controls_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Controls_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Controls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Controls_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Controls_Var_Pop_Fields = {
  __typename?: 'controls_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "controls" */
export type Controls_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Controls_Var_Samp_Fields = {
  __typename?: 'controls_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "controls" */
export type Controls_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Controls_Variance_Fields = {
  __typename?: 'controls_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "controls" */
export type Controls_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "criteria" */
export type Criteria = {
  __typename?: 'criteria';
  /** An array relationship */
  control_criteria: Array<Control_Criteria>;
  /** An aggregate relationship */
  control_criteria_aggregate: Control_Criteria_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "criteria" */
export type CriteriaControl_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

/** columns and relationships of "criteria" */
export type CriteriaControl_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

/** aggregated selection of "criteria" */
export type Criteria_Aggregate = {
  __typename?: 'criteria_aggregate';
  aggregate?: Maybe<Criteria_Aggregate_Fields>;
  nodes: Array<Criteria>;
};

export type Criteria_Aggregate_Bool_Exp = {
  count?: InputMaybe<Criteria_Aggregate_Bool_Exp_Count>;
};

export type Criteria_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Criteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Criteria_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "criteria" */
export type Criteria_Aggregate_Fields = {
  __typename?: 'criteria_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Criteria_Max_Fields>;
  min?: Maybe<Criteria_Min_Fields>;
};

/** aggregate fields of "criteria" */
export type Criteria_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Criteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "criteria" */
export type Criteria_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Criteria_Max_Order_By>;
  min?: InputMaybe<Criteria_Min_Order_By>;
};

/** input type for inserting array relation for remote table "criteria" */
export type Criteria_Arr_Rel_Insert_Input = {
  data: Array<Criteria_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Criteria_On_Conflict>;
};

/** Boolean expression to filter rows from the table "criteria". All fields are combined with a logical 'AND'. */
export type Criteria_Bool_Exp = {
  _and?: InputMaybe<Array<Criteria_Bool_Exp>>;
  _not?: InputMaybe<Criteria_Bool_Exp>;
  _or?: InputMaybe<Array<Criteria_Bool_Exp>>;
  control_criteria?: InputMaybe<Control_Criteria_Bool_Exp>;
  control_criteria_aggregate?: InputMaybe<Control_Criteria_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "criteria" */
export enum Criteria_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  CriteriaNameOrganizationIdKey = 'criteria_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  CriteriaPkey = 'criteria_pkey',
}

/** input type for inserting data into table "criteria" */
export type Criteria_Insert_Input = {
  control_criteria?: InputMaybe<Control_Criteria_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Criteria_Max_Fields = {
  __typename?: 'criteria_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "criteria" */
export type Criteria_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Criteria_Min_Fields = {
  __typename?: 'criteria_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "criteria" */
export type Criteria_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "criteria" */
export type Criteria_Mutation_Response = {
  __typename?: 'criteria_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Criteria>;
};

/** input type for inserting object relation for remote table "criteria" */
export type Criteria_Obj_Rel_Insert_Input = {
  data: Criteria_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Criteria_On_Conflict>;
};

/** on_conflict condition type for table "criteria" */
export type Criteria_On_Conflict = {
  constraint: Criteria_Constraint;
  update_columns?: Array<Criteria_Update_Column>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

/** Ordering options when selecting data from "criteria". */
export type Criteria_Order_By = {
  control_criteria_aggregate?: InputMaybe<Control_Criteria_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: criteria */
export type Criteria_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "criteria" */
export enum Criteria_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "criteria" */
export type Criteria_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "criteria" */
export type Criteria_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Criteria_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Criteria_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "criteria" */
export enum Criteria_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Criteria_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Criteria_Set_Input>;
  /** filter the rows which have to be updated */
  where: Criteria_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "daily_control_stats_view" */
export type Daily_Control_Stats_View = {
  __typename?: 'daily_control_stats_view';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  organisation?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "daily_control_stats_view" */
export type Daily_Control_Stats_View_Aggregate = {
  __typename?: 'daily_control_stats_view_aggregate';
  aggregate?: Maybe<Daily_Control_Stats_View_Aggregate_Fields>;
  nodes: Array<Daily_Control_Stats_View>;
};

/** aggregate fields of "daily_control_stats_view" */
export type Daily_Control_Stats_View_Aggregate_Fields = {
  __typename?: 'daily_control_stats_view_aggregate_fields';
  avg?: Maybe<Daily_Control_Stats_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Daily_Control_Stats_View_Max_Fields>;
  min?: Maybe<Daily_Control_Stats_View_Min_Fields>;
  stddev?: Maybe<Daily_Control_Stats_View_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_Control_Stats_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_Control_Stats_View_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_Control_Stats_View_Sum_Fields>;
  var_pop?: Maybe<Daily_Control_Stats_View_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_Control_Stats_View_Var_Samp_Fields>;
  variance?: Maybe<Daily_Control_Stats_View_Variance_Fields>;
};

/** aggregate fields of "daily_control_stats_view" */
export type Daily_Control_Stats_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_Control_Stats_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Daily_Control_Stats_View_Avg_Fields = {
  __typename?: 'daily_control_stats_view_avg_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "daily_control_stats_view". All fields are combined with a logical 'AND'. */
export type Daily_Control_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Control_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Control_Stats_View_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  organisation?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  total_at_risk_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_failing_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_not_relevant_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_pending_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_valid_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Daily_Control_Stats_View_Max_Fields = {
  __typename?: 'daily_control_stats_view_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Daily_Control_Stats_View_Min_Fields = {
  __typename?: 'daily_control_stats_view_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "daily_control_stats_view". */
export type Daily_Control_Stats_View_Order_By = {
  created_at?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  total_at_risk_count?: InputMaybe<Order_By>;
  total_failing_count?: InputMaybe<Order_By>;
  total_not_relevant_count?: InputMaybe<Order_By>;
  total_pending_count?: InputMaybe<Order_By>;
  total_valid_count?: InputMaybe<Order_By>;
};

/** select columns of table "daily_control_stats_view" */
export enum Daily_Control_Stats_View_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TotalAtRiskCount = 'total_at_risk_count',
  /** column name */
  TotalFailingCount = 'total_failing_count',
  /** column name */
  TotalNotRelevantCount = 'total_not_relevant_count',
  /** column name */
  TotalPendingCount = 'total_pending_count',
  /** column name */
  TotalValidCount = 'total_valid_count',
}

/** aggregate stddev on columns */
export type Daily_Control_Stats_View_Stddev_Fields = {
  __typename?: 'daily_control_stats_view_stddev_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Daily_Control_Stats_View_Stddev_Pop_Fields = {
  __typename?: 'daily_control_stats_view_stddev_pop_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Daily_Control_Stats_View_Stddev_Samp_Fields = {
  __typename?: 'daily_control_stats_view_stddev_samp_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "daily_control_stats_view" */
export type Daily_Control_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Control_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Control_Stats_View_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  total_at_risk_count?: InputMaybe<Scalars['bigint']['input']>;
  total_failing_count?: InputMaybe<Scalars['bigint']['input']>;
  total_not_relevant_count?: InputMaybe<Scalars['bigint']['input']>;
  total_pending_count?: InputMaybe<Scalars['bigint']['input']>;
  total_valid_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Daily_Control_Stats_View_Sum_Fields = {
  __typename?: 'daily_control_stats_view_sum_fields';
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type Daily_Control_Stats_View_Var_Pop_Fields = {
  __typename?: 'daily_control_stats_view_var_pop_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Daily_Control_Stats_View_Var_Samp_Fields = {
  __typename?: 'daily_control_stats_view_var_samp_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Daily_Control_Stats_View_Variance_Fields = {
  __typename?: 'daily_control_stats_view_variance_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "daily_control_user_stats_view" */
export type Daily_Control_User_Stats_View = {
  __typename?: 'daily_control_user_stats_view';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "daily_control_user_stats_view" */
export type Daily_Control_User_Stats_View_Aggregate = {
  __typename?: 'daily_control_user_stats_view_aggregate';
  aggregate?: Maybe<Daily_Control_User_Stats_View_Aggregate_Fields>;
  nodes: Array<Daily_Control_User_Stats_View>;
};

/** aggregate fields of "daily_control_user_stats_view" */
export type Daily_Control_User_Stats_View_Aggregate_Fields = {
  __typename?: 'daily_control_user_stats_view_aggregate_fields';
  avg?: Maybe<Daily_Control_User_Stats_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Daily_Control_User_Stats_View_Max_Fields>;
  min?: Maybe<Daily_Control_User_Stats_View_Min_Fields>;
  stddev?: Maybe<Daily_Control_User_Stats_View_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_Control_User_Stats_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_Control_User_Stats_View_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_Control_User_Stats_View_Sum_Fields>;
  var_pop?: Maybe<Daily_Control_User_Stats_View_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_Control_User_Stats_View_Var_Samp_Fields>;
  variance?: Maybe<Daily_Control_User_Stats_View_Variance_Fields>;
};

/** aggregate fields of "daily_control_user_stats_view" */
export type Daily_Control_User_Stats_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_Control_User_Stats_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Daily_Control_User_Stats_View_Avg_Fields = {
  __typename?: 'daily_control_user_stats_view_avg_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "daily_control_user_stats_view". All fields are combined with a logical 'AND'. */
export type Daily_Control_User_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Control_User_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Control_User_Stats_View_Bool_Exp>>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  total_at_risk_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_failing_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_not_relevant_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_pending_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_valid_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Daily_Control_User_Stats_View_Max_Fields = {
  __typename?: 'daily_control_user_stats_view_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Daily_Control_User_Stats_View_Min_Fields = {
  __typename?: 'daily_control_user_stats_view_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** Ordering options when selecting data from "daily_control_user_stats_view". */
export type Daily_Control_User_Stats_View_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  total_at_risk_count?: InputMaybe<Order_By>;
  total_failing_count?: InputMaybe<Order_By>;
  total_not_relevant_count?: InputMaybe<Order_By>;
  total_pending_count?: InputMaybe<Order_By>;
  total_valid_count?: InputMaybe<Order_By>;
};

/** select columns of table "daily_control_user_stats_view" */
export enum Daily_Control_User_Stats_View_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TotalAtRiskCount = 'total_at_risk_count',
  /** column name */
  TotalFailingCount = 'total_failing_count',
  /** column name */
  TotalNotRelevantCount = 'total_not_relevant_count',
  /** column name */
  TotalPendingCount = 'total_pending_count',
  /** column name */
  TotalValidCount = 'total_valid_count',
}

/** aggregate stddev on columns */
export type Daily_Control_User_Stats_View_Stddev_Fields = {
  __typename?: 'daily_control_user_stats_view_stddev_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Daily_Control_User_Stats_View_Stddev_Pop_Fields = {
  __typename?: 'daily_control_user_stats_view_stddev_pop_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Daily_Control_User_Stats_View_Stddev_Samp_Fields = {
  __typename?: 'daily_control_user_stats_view_stddev_samp_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "daily_control_user_stats_view" */
export type Daily_Control_User_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Control_User_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Control_User_Stats_View_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  total_at_risk_count?: InputMaybe<Scalars['bigint']['input']>;
  total_failing_count?: InputMaybe<Scalars['bigint']['input']>;
  total_not_relevant_count?: InputMaybe<Scalars['bigint']['input']>;
  total_pending_count?: InputMaybe<Scalars['bigint']['input']>;
  total_valid_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Daily_Control_User_Stats_View_Sum_Fields = {
  __typename?: 'daily_control_user_stats_view_sum_fields';
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type Daily_Control_User_Stats_View_Var_Pop_Fields = {
  __typename?: 'daily_control_user_stats_view_var_pop_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Daily_Control_User_Stats_View_Var_Samp_Fields = {
  __typename?: 'daily_control_user_stats_view_var_samp_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Daily_Control_User_Stats_View_Variance_Fields = {
  __typename?: 'daily_control_user_stats_view_variance_fields';
  total_at_risk_count?: Maybe<Scalars['Float']['output']>;
  total_failing_count?: Maybe<Scalars['Float']['output']>;
  total_not_relevant_count?: Maybe<Scalars['Float']['output']>;
  total_pending_count?: Maybe<Scalars['Float']['output']>;
  total_valid_count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "date_field_values" */
export type Date_Field_Values = {
  __typename?: 'date_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['timestamptz']['output'];
};

/** aggregated selection of "date_field_values" */
export type Date_Field_Values_Aggregate = {
  __typename?: 'date_field_values_aggregate';
  aggregate?: Maybe<Date_Field_Values_Aggregate_Fields>;
  nodes: Array<Date_Field_Values>;
};

export type Date_Field_Values_Aggregate_Bool_Exp = {
  count?: InputMaybe<Date_Field_Values_Aggregate_Bool_Exp_Count>;
};

export type Date_Field_Values_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Date_Field_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "date_field_values" */
export type Date_Field_Values_Aggregate_Fields = {
  __typename?: 'date_field_values_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Date_Field_Values_Max_Fields>;
  min?: Maybe<Date_Field_Values_Min_Fields>;
};

/** aggregate fields of "date_field_values" */
export type Date_Field_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "date_field_values" */
export type Date_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Date_Field_Values_Max_Order_By>;
  min?: InputMaybe<Date_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "date_field_values" */
export type Date_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Date_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Date_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "date_field_values". All fields are combined with a logical 'AND'. */
export type Date_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Date_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Date_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Date_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "date_field_values" */
export enum Date_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  DateFieldValuesPkey = 'date_field_values_pkey',
}

/** input type for inserting data into table "date_field_values" */
export type Date_Field_Values_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Date_Field_Values_Max_Fields = {
  __typename?: 'date_field_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "date_field_values" */
export type Date_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Date_Field_Values_Min_Fields = {
  __typename?: 'date_field_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "date_field_values" */
export type Date_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "date_field_values" */
export type Date_Field_Values_Mutation_Response = {
  __typename?: 'date_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Date_Field_Values>;
};

/** input type for inserting object relation for remote table "date_field_values" */
export type Date_Field_Values_Obj_Rel_Insert_Input = {
  data: Date_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Date_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "date_field_values" */
export type Date_Field_Values_On_Conflict = {
  constraint: Date_Field_Values_Constraint;
  update_columns?: Array<Date_Field_Values_Update_Column>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "date_field_values". */
export type Date_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: date_field_values */
export type Date_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "date_field_values" */
export enum Date_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "date_field_values" */
export type Date_Field_Values_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "date_field_values" */
export type Date_Field_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Date_Field_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Date_Field_Values_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "date_field_values" */
export enum Date_Field_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

export type Date_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Date_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Date_Field_Values_Bool_Exp;
};

/** columns and relationships of "email_field_values" */
export type Email_Field_Values = {
  __typename?: 'email_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['citext']['output'];
};

/** aggregated selection of "email_field_values" */
export type Email_Field_Values_Aggregate = {
  __typename?: 'email_field_values_aggregate';
  aggregate?: Maybe<Email_Field_Values_Aggregate_Fields>;
  nodes: Array<Email_Field_Values>;
};

export type Email_Field_Values_Aggregate_Bool_Exp = {
  count?: InputMaybe<Email_Field_Values_Aggregate_Bool_Exp_Count>;
};

export type Email_Field_Values_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Email_Field_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "email_field_values" */
export type Email_Field_Values_Aggregate_Fields = {
  __typename?: 'email_field_values_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Email_Field_Values_Max_Fields>;
  min?: Maybe<Email_Field_Values_Min_Fields>;
};

/** aggregate fields of "email_field_values" */
export type Email_Field_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "email_field_values" */
export type Email_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Email_Field_Values_Max_Order_By>;
  min?: InputMaybe<Email_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_field_values" */
export type Email_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Email_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_field_values". All fields are combined with a logical 'AND'. */
export type Email_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Email_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Citext_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_field_values" */
export enum Email_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  EmailFieldValuesPkey = 'email_field_values_pkey',
}

/** input type for inserting data into table "email_field_values" */
export type Email_Field_Values_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['citext']['input']>;
};

/** aggregate max on columns */
export type Email_Field_Values_Max_Fields = {
  __typename?: 'email_field_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['citext']['output']>;
};

/** order by max() on columns of table "email_field_values" */
export type Email_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Field_Values_Min_Fields = {
  __typename?: 'email_field_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['citext']['output']>;
};

/** order by min() on columns of table "email_field_values" */
export type Email_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "email_field_values" */
export type Email_Field_Values_Mutation_Response = {
  __typename?: 'email_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Field_Values>;
};

/** input type for inserting object relation for remote table "email_field_values" */
export type Email_Field_Values_Obj_Rel_Insert_Input = {
  data: Email_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "email_field_values" */
export type Email_Field_Values_On_Conflict = {
  constraint: Email_Field_Values_Constraint;
  update_columns?: Array<Email_Field_Values_Update_Column>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "email_field_values". */
export type Email_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_field_values */
export type Email_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "email_field_values" */
export enum Email_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "email_field_values" */
export type Email_Field_Values_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['citext']['input']>;
};

/** Streaming cursor of the table "email_field_values" */
export type Email_Field_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Field_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Field_Values_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['citext']['input']>;
};

/** update columns of table "email_field_values" */
export enum Email_Field_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

export type Email_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Field_Values_Bool_Exp;
};

/** columns and relationships of "evidence_acl" */
export type Evidence_Acl = {
  __typename?: 'evidence_acl';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "evidence_acl" */
export type Evidence_Acl_Aggregate = {
  __typename?: 'evidence_acl_aggregate';
  aggregate?: Maybe<Evidence_Acl_Aggregate_Fields>;
  nodes: Array<Evidence_Acl>;
};

export type Evidence_Acl_Aggregate_Bool_Exp = {
  count?: InputMaybe<Evidence_Acl_Aggregate_Bool_Exp_Count>;
};

export type Evidence_Acl_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Acl_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "evidence_acl" */
export type Evidence_Acl_Aggregate_Fields = {
  __typename?: 'evidence_acl_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Evidence_Acl_Max_Fields>;
  min?: Maybe<Evidence_Acl_Min_Fields>;
};

/** aggregate fields of "evidence_acl" */
export type Evidence_Acl_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "evidence_acl" */
export type Evidence_Acl_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Acl_Max_Order_By>;
  min?: InputMaybe<Evidence_Acl_Min_Order_By>;
};

/** input type for inserting array relation for remote table "evidence_acl" */
export type Evidence_Acl_Arr_Rel_Insert_Input = {
  data: Array<Evidence_Acl_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Acl_On_Conflict>;
};

/** Boolean expression to filter rows from the table "evidence_acl". All fields are combined with a logical 'AND'. */
export type Evidence_Acl_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Acl_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Acl_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Acl_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_acl" */
export enum Evidence_Acl_Constraint {
  /** unique or primary key constraint on columns "user_id", "evidence_id" */
  EvidenceAclEvidenceIdUserIdKey = 'evidence_acl_evidence_id_user_id_key',
  /** unique or primary key constraint on columns "id" */
  EvidenceAclPkey = 'evidence_acl_pkey',
}

/** input type for inserting data into table "evidence_acl" */
export type Evidence_Acl_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Evidence_Acl_Max_Fields = {
  __typename?: 'evidence_acl_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "evidence_acl" */
export type Evidence_Acl_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Evidence_Acl_Min_Fields = {
  __typename?: 'evidence_acl_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "evidence_acl" */
export type Evidence_Acl_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidence_acl" */
export type Evidence_Acl_Mutation_Response = {
  __typename?: 'evidence_acl_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Acl>;
};

/** on_conflict condition type for table "evidence_acl" */
export type Evidence_Acl_On_Conflict = {
  constraint: Evidence_Acl_Constraint;
  update_columns?: Array<Evidence_Acl_Update_Column>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_acl". */
export type Evidence_Acl_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evidence_acl */
export type Evidence_Acl_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "evidence_acl" */
export enum Evidence_Acl_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "evidence_acl" */
export type Evidence_Acl_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "evidence_acl" */
export type Evidence_Acl_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidence_Acl_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidence_Acl_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "evidence_acl" */
export enum Evidence_Acl_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Evidence_Acl_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidence_Acl_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidence_Acl_Bool_Exp;
};

/** columns and relationships of "evidence_integrations" */
export type Evidence_Integrations = {
  __typename?: 'evidence_integrations';
  config: Scalars['EvidenceIntegrationConfig']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  organization_integration: Organization_Integrations;
  organization_integration_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "evidence_integrations" */
export type Evidence_IntegrationsConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "evidence_integrations" */
export type Evidence_Integrations_Aggregate = {
  __typename?: 'evidence_integrations_aggregate';
  aggregate?: Maybe<Evidence_Integrations_Aggregate_Fields>;
  nodes: Array<Evidence_Integrations>;
};

export type Evidence_Integrations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Evidence_Integrations_Aggregate_Bool_Exp_Count>;
};

export type Evidence_Integrations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "evidence_integrations" */
export type Evidence_Integrations_Aggregate_Fields = {
  __typename?: 'evidence_integrations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Evidence_Integrations_Max_Fields>;
  min?: Maybe<Evidence_Integrations_Min_Fields>;
};

/** aggregate fields of "evidence_integrations" */
export type Evidence_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "evidence_integrations" */
export type Evidence_Integrations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Integrations_Max_Order_By>;
  min?: InputMaybe<Evidence_Integrations_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Evidence_Integrations_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "evidence_integrations" */
export type Evidence_Integrations_Arr_Rel_Insert_Input = {
  data: Array<Evidence_Integrations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Integrations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "evidence_integrations". All fields are combined with a logical 'AND'. */
export type Evidence_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Integrations_Bool_Exp>>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_integration?: InputMaybe<Organization_Integrations_Bool_Exp>;
  organization_integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_integrations" */
export enum Evidence_Integrations_Constraint {
  /** unique or primary key constraint on columns "evidence_id" */
  EvidenceIntegrationsEvidenceIdKey = 'evidence_integrations_evidence_id_key',
  /** unique or primary key constraint on columns "id" */
  EvidenceIntegrationsPkey = 'evidence_integrations_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Evidence_Integrations_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Evidence_Integrations_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Evidence_Integrations_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "evidence_integrations" */
export type Evidence_Integrations_Insert_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organization_integration?: InputMaybe<Organization_Integrations_Obj_Rel_Insert_Input>;
  organization_integration_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Evidence_Integrations_Max_Fields = {
  __typename?: 'evidence_integrations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organization_integration_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "evidence_integrations" */
export type Evidence_Integrations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_integration_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Evidence_Integrations_Min_Fields = {
  __typename?: 'evidence_integrations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organization_integration_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "evidence_integrations" */
export type Evidence_Integrations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_integration_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidence_integrations" */
export type Evidence_Integrations_Mutation_Response = {
  __typename?: 'evidence_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Integrations>;
};

/** input type for inserting object relation for remote table "evidence_integrations" */
export type Evidence_Integrations_Obj_Rel_Insert_Input = {
  data: Evidence_Integrations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Integrations_On_Conflict>;
};

/** on_conflict condition type for table "evidence_integrations" */
export type Evidence_Integrations_On_Conflict = {
  constraint: Evidence_Integrations_Constraint;
  update_columns?: Array<Evidence_Integrations_Update_Column>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_integrations". */
export type Evidence_Integrations_Order_By = {
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_integration?: InputMaybe<Organization_Integrations_Order_By>;
  organization_integration_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evidence_integrations */
export type Evidence_Integrations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Evidence_Integrations_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "evidence_integrations" */
export enum Evidence_Integrations_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationIntegrationId = 'organization_integration_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "evidence_integrations" */
export type Evidence_Integrations_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organization_integration_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "evidence_integrations" */
export type Evidence_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidence_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidence_Integrations_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organization_integration_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "evidence_integrations" */
export enum Evidence_Integrations_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationIntegrationId = 'organization_integration_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Evidence_Integrations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Evidence_Integrations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Evidence_Integrations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Evidence_Integrations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Evidence_Integrations_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Evidence_Integrations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidence_Integrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidence_Integrations_Bool_Exp;
};

/** columns and relationships of "evidence_policies" */
export type Evidence_Policies = {
  __typename?: 'evidence_policies';
  created_at: Scalars['timestamptz']['output'];
  error_message?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  policy: Policies;
  policy_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  with_acknowledgements_chapter: Scalars['Boolean']['output'];
  with_approvals_chapter: Scalars['Boolean']['output'];
  with_revision_details: Scalars['Boolean']['output'];
  with_versions_chapter: Scalars['Boolean']['output'];
};

/** aggregated selection of "evidence_policies" */
export type Evidence_Policies_Aggregate = {
  __typename?: 'evidence_policies_aggregate';
  aggregate?: Maybe<Evidence_Policies_Aggregate_Fields>;
  nodes: Array<Evidence_Policies>;
};

export type Evidence_Policies_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Evidence_Policies_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Evidence_Policies_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Evidence_Policies_Aggregate_Bool_Exp_Count>;
};

export type Evidence_Policies_Aggregate_Bool_Exp_Bool_And = {
  arguments: Evidence_Policies_Select_Column_Evidence_Policies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Policies_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Evidence_Policies_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Evidence_Policies_Select_Column_Evidence_Policies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Policies_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Evidence_Policies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Policies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "evidence_policies" */
export type Evidence_Policies_Aggregate_Fields = {
  __typename?: 'evidence_policies_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Evidence_Policies_Max_Fields>;
  min?: Maybe<Evidence_Policies_Min_Fields>;
};

/** aggregate fields of "evidence_policies" */
export type Evidence_Policies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "evidence_policies" */
export type Evidence_Policies_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Policies_Max_Order_By>;
  min?: InputMaybe<Evidence_Policies_Min_Order_By>;
};

/** input type for inserting array relation for remote table "evidence_policies" */
export type Evidence_Policies_Arr_Rel_Insert_Input = {
  data: Array<Evidence_Policies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Policies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "evidence_policies". All fields are combined with a logical 'AND'. */
export type Evidence_Policies_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Policies_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Policies_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Policies_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  with_acknowledgements_chapter?: InputMaybe<Boolean_Comparison_Exp>;
  with_approvals_chapter?: InputMaybe<Boolean_Comparison_Exp>;
  with_revision_details?: InputMaybe<Boolean_Comparison_Exp>;
  with_versions_chapter?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_policies" */
export enum Evidence_Policies_Constraint {
  /** unique or primary key constraint on columns "evidence_id" */
  EvidencePoliciesEvidenceIdKey = 'evidence_policies_evidence_id_key',
  /** unique or primary key constraint on columns "id" */
  EvidencePoliciesPkey = 'evidence_policies_pkey',
}

/** input type for inserting data into table "evidence_policies" */
export type Evidence_Policies_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  with_acknowledgements_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_approvals_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_revision_details?: InputMaybe<Scalars['Boolean']['input']>;
  with_versions_chapter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type Evidence_Policies_Max_Fields = {
  __typename?: 'evidence_policies_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "evidence_policies" */
export type Evidence_Policies_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Evidence_Policies_Min_Fields = {
  __typename?: 'evidence_policies_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "evidence_policies" */
export type Evidence_Policies_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidence_policies" */
export type Evidence_Policies_Mutation_Response = {
  __typename?: 'evidence_policies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Policies>;
};

/** input type for inserting object relation for remote table "evidence_policies" */
export type Evidence_Policies_Obj_Rel_Insert_Input = {
  data: Evidence_Policies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Policies_On_Conflict>;
};

/** on_conflict condition type for table "evidence_policies" */
export type Evidence_Policies_On_Conflict = {
  constraint: Evidence_Policies_Constraint;
  update_columns?: Array<Evidence_Policies_Update_Column>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_policies". */
export type Evidence_Policies_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  with_acknowledgements_chapter?: InputMaybe<Order_By>;
  with_approvals_chapter?: InputMaybe<Order_By>;
  with_revision_details?: InputMaybe<Order_By>;
  with_versions_chapter?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evidence_policies */
export type Evidence_Policies_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "evidence_policies" */
export enum Evidence_Policies_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WithAcknowledgementsChapter = 'with_acknowledgements_chapter',
  /** column name */
  WithApprovalsChapter = 'with_approvals_chapter',
  /** column name */
  WithRevisionDetails = 'with_revision_details',
  /** column name */
  WithVersionsChapter = 'with_versions_chapter',
}

/** select "evidence_policies_aggregate_bool_exp_bool_and_arguments_columns" columns of table "evidence_policies" */
export enum Evidence_Policies_Select_Column_Evidence_Policies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  WithAcknowledgementsChapter = 'with_acknowledgements_chapter',
  /** column name */
  WithApprovalsChapter = 'with_approvals_chapter',
  /** column name */
  WithRevisionDetails = 'with_revision_details',
  /** column name */
  WithVersionsChapter = 'with_versions_chapter',
}

/** select "evidence_policies_aggregate_bool_exp_bool_or_arguments_columns" columns of table "evidence_policies" */
export enum Evidence_Policies_Select_Column_Evidence_Policies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  WithAcknowledgementsChapter = 'with_acknowledgements_chapter',
  /** column name */
  WithApprovalsChapter = 'with_approvals_chapter',
  /** column name */
  WithRevisionDetails = 'with_revision_details',
  /** column name */
  WithVersionsChapter = 'with_versions_chapter',
}

/** input type for updating data in table "evidence_policies" */
export type Evidence_Policies_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  with_acknowledgements_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_approvals_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_revision_details?: InputMaybe<Scalars['Boolean']['input']>;
  with_versions_chapter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "evidence_policies" */
export type Evidence_Policies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidence_Policies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidence_Policies_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  with_acknowledgements_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_approvals_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_revision_details?: InputMaybe<Scalars['Boolean']['input']>;
  with_versions_chapter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "evidence_policies" */
export enum Evidence_Policies_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WithAcknowledgementsChapter = 'with_acknowledgements_chapter',
  /** column name */
  WithApprovalsChapter = 'with_approvals_chapter',
  /** column name */
  WithRevisionDetails = 'with_revision_details',
  /** column name */
  WithVersionsChapter = 'with_versions_chapter',
}

export type Evidence_Policies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidence_Policies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidence_Policies_Bool_Exp;
};

/** columns and relationships of "evidence_tags" */
export type Evidence_Tags = {
  __typename?: 'evidence_tags';
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  tag: Organization_Evidence_Tags;
  tag_id: Scalars['uuid']['output'];
};

/** aggregated selection of "evidence_tags" */
export type Evidence_Tags_Aggregate = {
  __typename?: 'evidence_tags_aggregate';
  aggregate?: Maybe<Evidence_Tags_Aggregate_Fields>;
  nodes: Array<Evidence_Tags>;
};

export type Evidence_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Evidence_Tags_Aggregate_Bool_Exp_Count>;
};

export type Evidence_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "evidence_tags" */
export type Evidence_Tags_Aggregate_Fields = {
  __typename?: 'evidence_tags_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Evidence_Tags_Max_Fields>;
  min?: Maybe<Evidence_Tags_Min_Fields>;
};

/** aggregate fields of "evidence_tags" */
export type Evidence_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "evidence_tags" */
export type Evidence_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Tags_Max_Order_By>;
  min?: InputMaybe<Evidence_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "evidence_tags" */
export type Evidence_Tags_Arr_Rel_Insert_Input = {
  data: Array<Evidence_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "evidence_tags". All fields are combined with a logical 'AND'. */
export type Evidence_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Tags_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Tags_Bool_Exp>>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_tags" */
export enum Evidence_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  EvidenceTagsPkey = 'evidence_tags_pkey',
  /** unique or primary key constraint on columns "tag_id", "evidence_id" */
  EvidenceTagsTagIdEvidenceIdKey = 'evidence_tags_tag_id_evidence_id_key',
}

/** input type for inserting data into table "evidence_tags" */
export type Evidence_Tags_Insert_Input = {
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Organization_Evidence_Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Evidence_Tags_Max_Fields = {
  __typename?: 'evidence_tags_max_fields';
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tag_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "evidence_tags" */
export type Evidence_Tags_Max_Order_By = {
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Evidence_Tags_Min_Fields = {
  __typename?: 'evidence_tags_min_fields';
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tag_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "evidence_tags" */
export type Evidence_Tags_Min_Order_By = {
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidence_tags" */
export type Evidence_Tags_Mutation_Response = {
  __typename?: 'evidence_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Tags>;
};

/** on_conflict condition type for table "evidence_tags" */
export type Evidence_Tags_On_Conflict = {
  constraint: Evidence_Tags_Constraint;
  update_columns?: Array<Evidence_Tags_Update_Column>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_tags". */
export type Evidence_Tags_Order_By = {
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Organization_Evidence_Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evidence_tags */
export type Evidence_Tags_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "evidence_tags" */
export enum Evidence_Tags_Select_Column {
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
}

/** input type for updating data in table "evidence_tags" */
export type Evidence_Tags_Set_Input = {
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "evidence_tags" */
export type Evidence_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidence_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidence_Tags_Stream_Cursor_Value_Input = {
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "evidence_tags" */
export enum Evidence_Tags_Update_Column {
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
}

export type Evidence_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidence_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidence_Tags_Bool_Exp;
};

/** columns and relationships of "evidence_version_file" */
export type Evidence_Version_File = {
  __typename?: 'evidence_version_file';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence_version: Evidence_Versions;
  evidence_version_id: Scalars['uuid']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
};

/** aggregated selection of "evidence_version_file" */
export type Evidence_Version_File_Aggregate = {
  __typename?: 'evidence_version_file_aggregate';
  aggregate?: Maybe<Evidence_Version_File_Aggregate_Fields>;
  nodes: Array<Evidence_Version_File>;
};

/** aggregate fields of "evidence_version_file" */
export type Evidence_Version_File_Aggregate_Fields = {
  __typename?: 'evidence_version_file_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Evidence_Version_File_Max_Fields>;
  min?: Maybe<Evidence_Version_File_Min_Fields>;
};

/** aggregate fields of "evidence_version_file" */
export type Evidence_Version_File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evidence_Version_File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "evidence_version_file". All fields are combined with a logical 'AND'. */
export type Evidence_Version_File_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Version_File_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Version_File_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Version_File_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence_version?: InputMaybe<Evidence_Versions_Bool_Exp>;
  evidence_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_version_file" */
export enum Evidence_Version_File_Constraint {
  /** unique or primary key constraint on columns "evidence_version_id" */
  EvidenceVersionFileEvidenceVersionIdKey = 'evidence_version_file_evidence_version_id_key',
  /** unique or primary key constraint on columns "file_id" */
  EvidenceVersionFileFileIdKey = 'evidence_version_file_file_id_key',
  /** unique or primary key constraint on columns "file_id", "evidence_version_id" */
  EvidenceVersionFilePkey = 'evidence_version_file_pkey',
}

/** input type for inserting data into table "evidence_version_file" */
export type Evidence_Version_File_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_version?: InputMaybe<Evidence_Versions_Obj_Rel_Insert_Input>;
  evidence_version_id?: InputMaybe<Scalars['uuid']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Evidence_Version_File_Max_Fields = {
  __typename?: 'evidence_version_file_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_version_id?: Maybe<Scalars['uuid']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Evidence_Version_File_Min_Fields = {
  __typename?: 'evidence_version_file_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_version_id?: Maybe<Scalars['uuid']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "evidence_version_file" */
export type Evidence_Version_File_Mutation_Response = {
  __typename?: 'evidence_version_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Version_File>;
};

/** input type for inserting object relation for remote table "evidence_version_file" */
export type Evidence_Version_File_Obj_Rel_Insert_Input = {
  data: Evidence_Version_File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Version_File_On_Conflict>;
};

/** on_conflict condition type for table "evidence_version_file" */
export type Evidence_Version_File_On_Conflict = {
  constraint: Evidence_Version_File_Constraint;
  update_columns?: Array<Evidence_Version_File_Update_Column>;
  where?: InputMaybe<Evidence_Version_File_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_version_file". */
export type Evidence_Version_File_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_version?: InputMaybe<Evidence_Versions_Order_By>;
  evidence_version_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evidence_version_file */
export type Evidence_Version_File_Pk_Columns_Input = {
  evidence_version_id: Scalars['uuid']['input'];
  file_id: Scalars['uuid']['input'];
};

/** select columns of table "evidence_version_file" */
export enum Evidence_Version_File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceVersionId = 'evidence_version_id',
  /** column name */
  FileId = 'file_id',
}

/** input type for updating data in table "evidence_version_file" */
export type Evidence_Version_File_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_version_id?: InputMaybe<Scalars['uuid']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "evidence_version_file" */
export type Evidence_Version_File_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidence_Version_File_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidence_Version_File_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_version_id?: InputMaybe<Scalars['uuid']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "evidence_version_file" */
export enum Evidence_Version_File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceVersionId = 'evidence_version_id',
  /** column name */
  FileId = 'file_id',
}

export type Evidence_Version_File_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidence_Version_File_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidence_Version_File_Bool_Exp;
};

/** columns and relationships of "evidence_versions" */
export type Evidence_Versions = {
  __typename?: 'evidence_versions';
  /** An array relationship */
  control_ai_review_assessment_sources: Array<Control_Ai_Review_Assessment_Sources>;
  /** An aggregate relationship */
  control_ai_review_assessment_sources_aggregate: Control_Ai_Review_Assessment_Sources_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  /** An object relationship */
  evidence_version_file?: Maybe<Evidence_Version_File>;
  id: Scalars['uuid']['output'];
  is_current: Scalars['Boolean']['output'];
  /** An object relationship */
  policy_version?: Maybe<Policy_Versions>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByUpdatedBy?: Maybe<Users>;
  validity_start: Scalars['timestamptz']['output'];
};

/** columns and relationships of "evidence_versions" */
export type Evidence_VersionsControl_Ai_Review_Assessment_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

/** columns and relationships of "evidence_versions" */
export type Evidence_VersionsControl_Ai_Review_Assessment_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

/** aggregated selection of "evidence_versions" */
export type Evidence_Versions_Aggregate = {
  __typename?: 'evidence_versions_aggregate';
  aggregate?: Maybe<Evidence_Versions_Aggregate_Fields>;
  nodes: Array<Evidence_Versions>;
};

export type Evidence_Versions_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Evidence_Versions_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Evidence_Versions_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Evidence_Versions_Aggregate_Bool_Exp_Count>;
};

export type Evidence_Versions_Aggregate_Bool_Exp_Bool_And = {
  arguments: Evidence_Versions_Select_Column_Evidence_Versions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Versions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Evidence_Versions_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Evidence_Versions_Select_Column_Evidence_Versions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Versions_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Evidence_Versions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidence_Versions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "evidence_versions" */
export type Evidence_Versions_Aggregate_Fields = {
  __typename?: 'evidence_versions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Evidence_Versions_Max_Fields>;
  min?: Maybe<Evidence_Versions_Min_Fields>;
};

/** aggregate fields of "evidence_versions" */
export type Evidence_Versions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "evidence_versions" */
export type Evidence_Versions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Versions_Max_Order_By>;
  min?: InputMaybe<Evidence_Versions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "evidence_versions" */
export type Evidence_Versions_Arr_Rel_Insert_Input = {
  data: Array<Evidence_Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Versions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "evidence_versions". All fields are combined with a logical 'AND'. */
export type Evidence_Versions_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Versions_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Versions_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Versions_Bool_Exp>>;
  control_ai_review_assessment_sources?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
  control_ai_review_assessment_sources_aggregate?: InputMaybe<Control_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  evidence_version_file?: InputMaybe<Evidence_Version_File_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_current?: InputMaybe<Boolean_Comparison_Exp>;
  policy_version?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
  validity_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_versions" */
export enum Evidence_Versions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlEvidenceVersionPkey = 'control_evidence_version_pkey',
  /** unique or primary key constraint on columns "is_current", "evidence_id" */
  EvidenceVersionsCurrentIdx = 'evidence_versions_current_idx',
}

/** input type for inserting data into table "evidence_versions" */
export type Evidence_Versions_Insert_Input = {
  control_ai_review_assessment_sources?: InputMaybe<Control_Ai_Review_Assessment_Sources_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  evidence_version_file?: InputMaybe<Evidence_Version_File_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  policy_version?: InputMaybe<Policy_Versions_Obj_Rel_Insert_Input>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByUpdatedBy?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Evidence_Versions_Max_Fields = {
  __typename?: 'evidence_versions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  validity_start?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "evidence_versions" */
export type Evidence_Versions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  validity_start?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Evidence_Versions_Min_Fields = {
  __typename?: 'evidence_versions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_by?: Maybe<Scalars['uuid']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  validity_start?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "evidence_versions" */
export type Evidence_Versions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  validity_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidence_versions" */
export type Evidence_Versions_Mutation_Response = {
  __typename?: 'evidence_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Versions>;
};

/** input type for inserting object relation for remote table "evidence_versions" */
export type Evidence_Versions_Obj_Rel_Insert_Input = {
  data: Evidence_Versions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Versions_On_Conflict>;
};

/** on_conflict condition type for table "evidence_versions" */
export type Evidence_Versions_On_Conflict = {
  constraint: Evidence_Versions_Constraint;
  update_columns?: Array<Evidence_Versions_Update_Column>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_versions". */
export type Evidence_Versions_Order_By = {
  control_ai_review_assessment_sources_aggregate?: InputMaybe<Control_Ai_Review_Assessment_Sources_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  evidence_version_file?: InputMaybe<Evidence_Version_File_Order_By>;
  id?: InputMaybe<Order_By>;
  is_current?: InputMaybe<Order_By>;
  policy_version?: InputMaybe<Policy_Versions_Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByUpdatedBy?: InputMaybe<Users_Order_By>;
  validity_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evidence_versions */
export type Evidence_Versions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "evidence_versions" */
export enum Evidence_Versions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrent = 'is_current',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Url = 'url',
  /** column name */
  ValidityStart = 'validity_start',
}

/** select "evidence_versions_aggregate_bool_exp_bool_and_arguments_columns" columns of table "evidence_versions" */
export enum Evidence_Versions_Select_Column_Evidence_Versions_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCurrent = 'is_current',
}

/** select "evidence_versions_aggregate_bool_exp_bool_or_arguments_columns" columns of table "evidence_versions" */
export enum Evidence_Versions_Select_Column_Evidence_Versions_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCurrent = 'is_current',
}

/** input type for updating data in table "evidence_versions" */
export type Evidence_Versions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "evidence_versions" */
export type Evidence_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidence_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidence_Versions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "evidence_versions" */
export enum Evidence_Versions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrent = 'is_current',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Url = 'url',
  /** column name */
  ValidityStart = 'validity_start',
}

export type Evidence_Versions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidence_Versions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidence_Versions_Bool_Exp;
};

/** columns and relationships of "evidences" */
export type Evidences = {
  __typename?: 'evidences';
  /** An array relationship */
  acl: Array<Evidence_Acl>;
  /** An aggregate relationship */
  acl_aggregate: Evidence_Acl_Aggregate;
  /** An array relationship */
  control_evidences: Array<Control_Evidences>;
  /** An aggregate relationship */
  control_evidences_aggregate: Control_Evidences_Aggregate;
  /** A computed field, executes function "get_evidence_status" */
  controls_aggregate_status: Scalars['EvidenceStatus']['output'];
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  evidence_integration?: Maybe<Evidence_Integrations>;
  /** An object relationship */
  evidence_policy?: Maybe<Evidence_Policies>;
  /** An array relationship */
  evidence_versions: Array<Evidence_Versions>;
  /** An aggregate relationship */
  evidence_versions_aggregate: Evidence_Versions_Aggregate;
  /** An array relationship */
  evidences_notifications: Array<Notifications>;
  /** An aggregate relationship */
  evidences_notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  evidences_vector_store?: Maybe<Ai_Evidences_Vector_Store>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  is_confidential: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  owner_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  tags: Array<Evidence_Tags>;
  /** An aggregate relationship */
  tags_aggregate: Evidence_Tags_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** columns and relationships of "evidences" */
export type EvidencesAclArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesAcl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesControl_EvidencesArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesControl_Evidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesEvidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesEvidence_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesEvidences_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesEvidences_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesTagsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

/** aggregated selection of "evidences" */
export type Evidences_Aggregate = {
  __typename?: 'evidences_aggregate';
  aggregate?: Maybe<Evidences_Aggregate_Fields>;
  nodes: Array<Evidences>;
};

export type Evidences_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Evidences_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Evidences_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Evidences_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Evidences_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Evidences_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Evidences_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Evidences_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Evidences_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Evidences_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Evidences_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Evidences_Aggregate_Bool_Exp_Var_Samp>;
};

export type Evidences_Aggregate_Bool_Exp_Avg = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Bool_And = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Corr = {
  arguments: Evidences_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Evidences_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Evidences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Evidences_Aggregate_Bool_Exp_Max = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Min = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Sum = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "evidences" */
export type Evidences_Aggregate_Fields = {
  __typename?: 'evidences_aggregate_fields';
  avg?: Maybe<Evidences_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Evidences_Max_Fields>;
  min?: Maybe<Evidences_Min_Fields>;
  stddev?: Maybe<Evidences_Stddev_Fields>;
  stddev_pop?: Maybe<Evidences_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Evidences_Stddev_Samp_Fields>;
  sum?: Maybe<Evidences_Sum_Fields>;
  var_pop?: Maybe<Evidences_Var_Pop_Fields>;
  var_samp?: Maybe<Evidences_Var_Samp_Fields>;
  variance?: Maybe<Evidences_Variance_Fields>;
};

/** aggregate fields of "evidences" */
export type Evidences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evidences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "evidences" */
export type Evidences_Aggregate_Order_By = {
  avg?: InputMaybe<Evidences_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidences_Max_Order_By>;
  min?: InputMaybe<Evidences_Min_Order_By>;
  stddev?: InputMaybe<Evidences_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Evidences_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Evidences_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Evidences_Sum_Order_By>;
  var_pop?: InputMaybe<Evidences_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Evidences_Var_Samp_Order_By>;
  variance?: InputMaybe<Evidences_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "evidences" */
export type Evidences_Arr_Rel_Insert_Input = {
  data: Array<Evidences_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidences_On_Conflict>;
};

/** aggregate avg on columns */
export type Evidences_Avg_Fields = {
  __typename?: 'evidences_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "evidences" */
export type Evidences_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "evidences". All fields are combined with a logical 'AND'. */
export type Evidences_Bool_Exp = {
  _and?: InputMaybe<Array<Evidences_Bool_Exp>>;
  _not?: InputMaybe<Evidences_Bool_Exp>;
  _or?: InputMaybe<Array<Evidences_Bool_Exp>>;
  acl?: InputMaybe<Evidence_Acl_Bool_Exp>;
  acl_aggregate?: InputMaybe<Evidence_Acl_Aggregate_Bool_Exp>;
  control_evidences?: InputMaybe<Control_Evidences_Bool_Exp>;
  control_evidences_aggregate?: InputMaybe<Control_Evidences_Aggregate_Bool_Exp>;
  controls_aggregate_status?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  evidence_policy?: InputMaybe<Evidence_Policies_Bool_Exp>;
  evidence_versions?: InputMaybe<Evidence_Versions_Bool_Exp>;
  evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Bool_Exp>;
  evidences_notifications?: InputMaybe<Notifications_Bool_Exp>;
  evidences_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  evidences_vector_store?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  is_confidential?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  tags?: InputMaybe<Evidence_Tags_Bool_Exp>;
  tags_aggregate?: InputMaybe<Evidence_Tags_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "evidences" */
export enum Evidences_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlEvidencesPkey = 'control_evidences_pkey',
}

/** input type for incrementing numeric columns in table "evidences" */
export type Evidences_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "evidences" */
export type Evidences_Insert_Input = {
  acl?: InputMaybe<Evidence_Acl_Arr_Rel_Insert_Input>;
  control_evidences?: InputMaybe<Control_Evidences_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Obj_Rel_Insert_Input>;
  evidence_policy?: InputMaybe<Evidence_Policies_Obj_Rel_Insert_Input>;
  evidence_versions?: InputMaybe<Evidence_Versions_Arr_Rel_Insert_Input>;
  evidences_notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  evidences_vector_store?: InputMaybe<Ai_Evidences_Vector_Store_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  is_confidential?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  tags?: InputMaybe<Evidence_Tags_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Evidences_Max_Fields = {
  __typename?: 'evidences_max_fields';
  /** A computed field, executes function "get_evidence_status" */
  controls_aggregate_status?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "evidences" */
export type Evidences_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Evidences_Min_Fields = {
  __typename?: 'evidences_min_fields';
  /** A computed field, executes function "get_evidence_status" */
  controls_aggregate_status?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "evidences" */
export type Evidences_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidences" */
export type Evidences_Mutation_Response = {
  __typename?: 'evidences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidences>;
};

/** input type for inserting object relation for remote table "evidences" */
export type Evidences_Obj_Rel_Insert_Input = {
  data: Evidences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidences_On_Conflict>;
};

/** on_conflict condition type for table "evidences" */
export type Evidences_On_Conflict = {
  constraint: Evidences_Constraint;
  update_columns?: Array<Evidences_Update_Column>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** Ordering options when selecting data from "evidences". */
export type Evidences_Order_By = {
  acl_aggregate?: InputMaybe<Evidence_Acl_Aggregate_Order_By>;
  control_evidences_aggregate?: InputMaybe<Control_Evidences_Aggregate_Order_By>;
  controls_aggregate_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Order_By>;
  evidence_policy?: InputMaybe<Evidence_Policies_Order_By>;
  evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Order_By>;
  evidences_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  evidences_vector_store?: InputMaybe<Ai_Evidences_Vector_Store_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  is_confidential?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Evidence_Tags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: evidences */
export type Evidences_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "evidences" */
export enum Evidences_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  IsConfidential = 'is_confidential',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "evidences_aggregate_bool_exp_avg_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_bool_and_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsConfidential = 'is_confidential',
}

/** select "evidences_aggregate_bool_exp_bool_or_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsConfidential = 'is_confidential',
}

/** select "evidences_aggregate_bool_exp_corr_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_max_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_min_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_sum_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_var_samp_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "evidences" */
export type Evidences_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  is_confidential?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Evidences_Stddev_Fields = {
  __typename?: 'evidences_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "evidences" */
export type Evidences_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Evidences_Stddev_Pop_Fields = {
  __typename?: 'evidences_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "evidences" */
export type Evidences_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Evidences_Stddev_Samp_Fields = {
  __typename?: 'evidences_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "evidences" */
export type Evidences_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "evidences" */
export type Evidences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidences_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  is_confidential?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Evidences_Sum_Fields = {
  __typename?: 'evidences_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "evidences" */
export type Evidences_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "evidences" */
export enum Evidences_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  IsConfidential = 'is_confidential',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Evidences_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Evidences_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidences_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidences_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Evidences_Var_Pop_Fields = {
  __typename?: 'evidences_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "evidences" */
export type Evidences_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Evidences_Var_Samp_Fields = {
  __typename?: 'evidences_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "evidences" */
export type Evidences_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Evidences_Variance_Fields = {
  __typename?: 'evidences_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "evidences" */
export type Evidences_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "field_configs" */
export type Field_Configs = {
  __typename?: 'field_configs';
  created_at: Scalars['timestamptz']['output'];
  entity_name: Field_Entities_Enum;
  /** An object relationship */
  fieldTypeByFieldType: Field_Types;
  /** An object relationship */
  field_entity: Field_Entities;
  field_type: Field_Types_Enum;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  program_field_configs: Array<Program_Field_Configs>;
  /** An aggregate relationship */
  program_field_configs_aggregate: Program_Field_Configs_Aggregate;
  /** An object relationship */
  select_field_config?: Maybe<Select_Field_Configs>;
  /** An array relationship */
  select_field_configs: Array<Select_Field_Configs>;
  /** An aggregate relationship */
  select_field_configs_aggregate: Select_Field_Configs_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsProgram_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsProgram_Field_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsSelect_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Configs_Order_By>>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsSelect_Field_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Configs_Order_By>>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

/** aggregated selection of "field_configs" */
export type Field_Configs_Aggregate = {
  __typename?: 'field_configs_aggregate';
  aggregate?: Maybe<Field_Configs_Aggregate_Fields>;
  nodes: Array<Field_Configs>;
};

export type Field_Configs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Field_Configs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Field_Configs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Field_Configs_Aggregate_Bool_Exp_Count>;
};

export type Field_Configs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Field_Configs_Select_Column_Field_Configs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Configs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Field_Configs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Field_Configs_Select_Column_Field_Configs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Configs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Field_Configs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Field_Configs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Configs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "field_configs" */
export type Field_Configs_Aggregate_Fields = {
  __typename?: 'field_configs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Field_Configs_Max_Fields>;
  min?: Maybe<Field_Configs_Min_Fields>;
};

/** aggregate fields of "field_configs" */
export type Field_Configs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Configs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "field_configs" */
export type Field_Configs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Configs_Max_Order_By>;
  min?: InputMaybe<Field_Configs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "field_configs" */
export type Field_Configs_Arr_Rel_Insert_Input = {
  data: Array<Field_Configs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Configs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "field_configs". All fields are combined with a logical 'AND'. */
export type Field_Configs_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Configs_Bool_Exp>>;
  _not?: InputMaybe<Field_Configs_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Configs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_name?: InputMaybe<Field_Entities_Enum_Comparison_Exp>;
  fieldTypeByFieldType?: InputMaybe<Field_Types_Bool_Exp>;
  field_entity?: InputMaybe<Field_Entities_Bool_Exp>;
  field_type?: InputMaybe<Field_Types_Enum_Comparison_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Bool_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  program_field_configs?: InputMaybe<Program_Field_Configs_Bool_Exp>;
  program_field_configs_aggregate?: InputMaybe<Program_Field_Configs_Aggregate_Bool_Exp>;
  select_field_config?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  select_field_configs?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  select_field_configs_aggregate?: InputMaybe<Select_Field_Configs_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_configs" */
export enum Field_Configs_Constraint {
  /** unique or primary key constraint on columns "entity_name", "id", "field_type" */
  FieldConfigsEntityNameFieldTypeIdKey = 'field_configs_entity_name_field_type_id_key',
  /** unique or primary key constraint on columns "id", "field_type" */
  FieldConfigsFieldTypeIdKey = 'field_configs_field_type_id_key',
  /** unique or primary key constraint on columns "entity_name", "id" */
  FieldConfigsIdEntityNameKey = 'field_configs_id_entity_name_key',
  /** unique or primary key constraint on columns "entity_name", "name", "organization_id" */
  FieldConfigsOrganizationIdEntityNameNameKey = 'field_configs_organization_id_entity_name_name_key',
  /** unique or primary key constraint on columns "order", "entity_name", "organization_id" */
  FieldConfigsOrganizationIdEntityNameOrderKey = 'field_configs_organization_id_entity_name_order_key',
  /** unique or primary key constraint on columns "id" */
  FieldConfigsPkey = 'field_configs_pkey',
}

/** input type for inserting data into table "field_configs" */
export type Field_Configs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_name?: InputMaybe<Field_Entities_Enum>;
  fieldTypeByFieldType?: InputMaybe<Field_Types_Obj_Rel_Insert_Input>;
  field_entity?: InputMaybe<Field_Entities_Obj_Rel_Insert_Input>;
  field_type?: InputMaybe<Field_Types_Enum>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  program_field_configs?: InputMaybe<Program_Field_Configs_Arr_Rel_Insert_Input>;
  select_field_config?: InputMaybe<Select_Field_Configs_Obj_Rel_Insert_Input>;
  select_field_configs?: InputMaybe<Select_Field_Configs_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Field_Configs_Max_Fields = {
  __typename?: 'field_configs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "field_configs" */
export type Field_Configs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Configs_Min_Fields = {
  __typename?: 'field_configs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "field_configs" */
export type Field_Configs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_configs" */
export type Field_Configs_Mutation_Response = {
  __typename?: 'field_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Configs>;
};

/** input type for inserting object relation for remote table "field_configs" */
export type Field_Configs_Obj_Rel_Insert_Input = {
  data: Field_Configs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Configs_On_Conflict>;
};

/** on_conflict condition type for table "field_configs" */
export type Field_Configs_On_Conflict = {
  constraint: Field_Configs_Constraint;
  update_columns?: Array<Field_Configs_Update_Column>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "field_configs". */
export type Field_Configs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  fieldTypeByFieldType?: InputMaybe<Field_Types_Order_By>;
  field_entity?: InputMaybe<Field_Entities_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  hidden?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  program_field_configs_aggregate?: InputMaybe<Program_Field_Configs_Aggregate_Order_By>;
  select_field_config?: InputMaybe<Select_Field_Configs_Order_By>;
  select_field_configs_aggregate?: InputMaybe<Select_Field_Configs_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: field_configs */
export type Field_Configs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "field_configs" */
export enum Field_Configs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "field_configs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "field_configs" */
export enum Field_Configs_Select_Column_Field_Configs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
}

/** select "field_configs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "field_configs" */
export enum Field_Configs_Select_Column_Field_Configs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
}

/** input type for updating data in table "field_configs" */
export type Field_Configs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_name?: InputMaybe<Field_Entities_Enum>;
  field_type?: InputMaybe<Field_Types_Enum>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "field_configs" */
export type Field_Configs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Configs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Configs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_name?: InputMaybe<Field_Entities_Enum>;
  field_type?: InputMaybe<Field_Types_Enum>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "field_configs" */
export enum Field_Configs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Field_Configs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Configs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Configs_Bool_Exp;
};

/** columns and relationships of "field_entities" */
export type Field_Entities = {
  __typename?: 'field_entities';
  /** An array relationship */
  field_configs: Array<Field_Configs>;
  /** An aggregate relationship */
  field_configs_aggregate: Field_Configs_Aggregate;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  name: Scalars['String']['output'];
};

/** columns and relationships of "field_entities" */
export type Field_EntitiesField_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** columns and relationships of "field_entities" */
export type Field_EntitiesField_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** columns and relationships of "field_entities" */
export type Field_EntitiesField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_entities" */
export type Field_EntitiesField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** aggregated selection of "field_entities" */
export type Field_Entities_Aggregate = {
  __typename?: 'field_entities_aggregate';
  aggregate?: Maybe<Field_Entities_Aggregate_Fields>;
  nodes: Array<Field_Entities>;
};

/** aggregate fields of "field_entities" */
export type Field_Entities_Aggregate_Fields = {
  __typename?: 'field_entities_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Field_Entities_Max_Fields>;
  min?: Maybe<Field_Entities_Min_Fields>;
};

/** aggregate fields of "field_entities" */
export type Field_Entities_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Entities_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "field_entities". All fields are combined with a logical 'AND'. */
export type Field_Entities_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Entities_Bool_Exp>>;
  _not?: InputMaybe<Field_Entities_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Entities_Bool_Exp>>;
  field_configs?: InputMaybe<Field_Configs_Bool_Exp>;
  field_configs_aggregate?: InputMaybe<Field_Configs_Aggregate_Bool_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_entities" */
export enum Field_Entities_Constraint {
  /** unique or primary key constraint on columns "name" */
  FieldEntitiesPkey = 'field_entities_pkey',
}

export enum Field_Entities_Enum {
  Control = 'control',
  Policy = 'policy',
  Risk = 'risk',
  Task = 'task',
  Vendor = 'vendor',
}

/** Boolean expression to compare columns of type "field_entities_enum". All fields are combined with logical 'AND'. */
export type Field_Entities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Field_Entities_Enum>;
  _in?: InputMaybe<Array<Field_Entities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Field_Entities_Enum>;
  _nin?: InputMaybe<Array<Field_Entities_Enum>>;
};

/** input type for inserting data into table "field_entities" */
export type Field_Entities_Insert_Input = {
  field_configs?: InputMaybe<Field_Configs_Arr_Rel_Insert_Input>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Field_Entities_Max_Fields = {
  __typename?: 'field_entities_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Field_Entities_Min_Fields = {
  __typename?: 'field_entities_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "field_entities" */
export type Field_Entities_Mutation_Response = {
  __typename?: 'field_entities_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Entities>;
};

/** input type for inserting object relation for remote table "field_entities" */
export type Field_Entities_Obj_Rel_Insert_Input = {
  data: Field_Entities_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Entities_On_Conflict>;
};

/** on_conflict condition type for table "field_entities" */
export type Field_Entities_On_Conflict = {
  constraint: Field_Entities_Constraint;
  update_columns?: Array<Field_Entities_Update_Column>;
  where?: InputMaybe<Field_Entities_Bool_Exp>;
};

/** Ordering options when selecting data from "field_entities". */
export type Field_Entities_Order_By = {
  field_configs_aggregate?: InputMaybe<Field_Configs_Aggregate_Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: field_entities */
export type Field_Entities_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "field_entities" */
export enum Field_Entities_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "field_entities" */
export type Field_Entities_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "field_entities" */
export type Field_Entities_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Entities_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Entities_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "field_entities" */
export enum Field_Entities_Update_Column {
  /** column name */
  Name = 'name',
}

export type Field_Entities_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Entities_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Entities_Bool_Exp;
};

/** columns and relationships of "field_types" */
export type Field_Types = {
  __typename?: 'field_types';
  /** An array relationship */
  field_configs: Array<Field_Configs>;
  /** An aggregate relationship */
  field_configs_aggregate: Field_Configs_Aggregate;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  name: Scalars['String']['output'];
};

/** columns and relationships of "field_types" */
export type Field_TypesField_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** columns and relationships of "field_types" */
export type Field_TypesField_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** columns and relationships of "field_types" */
export type Field_TypesField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_types" */
export type Field_TypesField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** aggregated selection of "field_types" */
export type Field_Types_Aggregate = {
  __typename?: 'field_types_aggregate';
  aggregate?: Maybe<Field_Types_Aggregate_Fields>;
  nodes: Array<Field_Types>;
};

/** aggregate fields of "field_types" */
export type Field_Types_Aggregate_Fields = {
  __typename?: 'field_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Field_Types_Max_Fields>;
  min?: Maybe<Field_Types_Min_Fields>;
};

/** aggregate fields of "field_types" */
export type Field_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "field_types". All fields are combined with a logical 'AND'. */
export type Field_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Types_Bool_Exp>>;
  _not?: InputMaybe<Field_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Types_Bool_Exp>>;
  field_configs?: InputMaybe<Field_Configs_Bool_Exp>;
  field_configs_aggregate?: InputMaybe<Field_Configs_Aggregate_Bool_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_types" */
export enum Field_Types_Constraint {
  /** unique or primary key constraint on columns "name" */
  FieldTypesPkey = 'field_types_pkey',
}

export enum Field_Types_Enum {
  Date = 'date',
  Email = 'email',
  Number = 'number',
  Select = 'select',
  Text = 'text',
  Url = 'url',
}

/** Boolean expression to compare columns of type "field_types_enum". All fields are combined with logical 'AND'. */
export type Field_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Field_Types_Enum>;
  _in?: InputMaybe<Array<Field_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Field_Types_Enum>;
  _nin?: InputMaybe<Array<Field_Types_Enum>>;
};

/** input type for inserting data into table "field_types" */
export type Field_Types_Insert_Input = {
  field_configs?: InputMaybe<Field_Configs_Arr_Rel_Insert_Input>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Field_Types_Max_Fields = {
  __typename?: 'field_types_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Field_Types_Min_Fields = {
  __typename?: 'field_types_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "field_types" */
export type Field_Types_Mutation_Response = {
  __typename?: 'field_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Types>;
};

/** input type for inserting object relation for remote table "field_types" */
export type Field_Types_Obj_Rel_Insert_Input = {
  data: Field_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Types_On_Conflict>;
};

/** on_conflict condition type for table "field_types" */
export type Field_Types_On_Conflict = {
  constraint: Field_Types_Constraint;
  update_columns?: Array<Field_Types_Update_Column>;
  where?: InputMaybe<Field_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "field_types". */
export type Field_Types_Order_By = {
  field_configs_aggregate?: InputMaybe<Field_Configs_Aggregate_Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: field_types */
export type Field_Types_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "field_types" */
export enum Field_Types_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "field_types" */
export type Field_Types_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "field_types" */
export type Field_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Types_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "field_types" */
export enum Field_Types_Update_Column {
  /** column name */
  Name = 'name',
}

export type Field_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Types_Bool_Exp;
};

/** columns and relationships of "field_values" */
export type Field_Values = {
  __typename?: 'field_values';
  /** An object relationship */
  control?: Maybe<Controls>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  date_field_value?: Maybe<Date_Field_Values>;
  /** An array relationship */
  date_field_values: Array<Date_Field_Values>;
  /** An aggregate relationship */
  date_field_values_aggregate: Date_Field_Values_Aggregate;
  /** An object relationship */
  email_field_value?: Maybe<Email_Field_Values>;
  /** An array relationship */
  email_field_values: Array<Email_Field_Values>;
  /** An aggregate relationship */
  email_field_values_aggregate: Email_Field_Values_Aggregate;
  entity_name: Field_Entities_Enum;
  /** An object relationship */
  fieldTypeByFieldType: Field_Types;
  /** An object relationship */
  field_config?: Maybe<Field_Configs>;
  field_config_id: Scalars['uuid']['output'];
  /** An object relationship */
  field_entity: Field_Entities;
  field_type: Field_Types_Enum;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  number_field_value?: Maybe<Number_Field_Values>;
  /** An array relationship */
  number_field_values: Array<Number_Field_Values>;
  /** An aggregate relationship */
  number_field_values_aggregate: Number_Field_Values_Aggregate;
  /** An object relationship */
  policy?: Maybe<Policies>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  select_field_value?: Maybe<Select_Field_Values>;
  /** An array relationship */
  select_field_values: Array<Select_Field_Values>;
  /** An aggregate relationship */
  select_field_values_aggregate: Select_Field_Values_Aggregate;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  text_field_value?: Maybe<Text_Field_Values>;
  /** An array relationship */
  text_field_values: Array<Text_Field_Values>;
  /** An aggregate relationship */
  text_field_values_aggregate: Text_Field_Values_Aggregate;
  /** An object relationship */
  url_field_value?: Maybe<Url_Field_Values>;
  /** An array relationship */
  url_field_values: Array<Url_Field_Values>;
  /** An aggregate relationship */
  url_field_values_aggregate: Url_Field_Values_Aggregate;
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesDate_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Date_Field_Values_Order_By>>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesDate_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Date_Field_Values_Order_By>>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesEmail_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Email_Field_Values_Order_By>>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesEmail_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Email_Field_Values_Order_By>>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesNumber_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Number_Field_Values_Order_By>>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesNumber_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Number_Field_Values_Order_By>>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesSelect_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesSelect_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesText_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Field_Values_Order_By>>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesText_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Field_Values_Order_By>>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesUrl_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Field_Values_Order_By>>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesUrl_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Field_Values_Order_By>>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

/** aggregated selection of "field_values" */
export type Field_Values_Aggregate = {
  __typename?: 'field_values_aggregate';
  aggregate?: Maybe<Field_Values_Aggregate_Fields>;
  nodes: Array<Field_Values>;
};

export type Field_Values_Aggregate_Bool_Exp = {
  count?: InputMaybe<Field_Values_Aggregate_Bool_Exp_Count>;
};

export type Field_Values_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "field_values" */
export type Field_Values_Aggregate_Fields = {
  __typename?: 'field_values_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Field_Values_Max_Fields>;
  min?: Maybe<Field_Values_Min_Fields>;
};

/** aggregate fields of "field_values" */
export type Field_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "field_values" */
export type Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Values_Max_Order_By>;
  min?: InputMaybe<Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "field_values" */
export type Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "field_values". All fields are combined with a logical 'AND'. */
export type Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Values_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  date_field_value?: InputMaybe<Date_Field_Values_Bool_Exp>;
  date_field_values?: InputMaybe<Date_Field_Values_Bool_Exp>;
  date_field_values_aggregate?: InputMaybe<Date_Field_Values_Aggregate_Bool_Exp>;
  email_field_value?: InputMaybe<Email_Field_Values_Bool_Exp>;
  email_field_values?: InputMaybe<Email_Field_Values_Bool_Exp>;
  email_field_values_aggregate?: InputMaybe<Email_Field_Values_Aggregate_Bool_Exp>;
  entity_name?: InputMaybe<Field_Entities_Enum_Comparison_Exp>;
  fieldTypeByFieldType?: InputMaybe<Field_Types_Bool_Exp>;
  field_config?: InputMaybe<Field_Configs_Bool_Exp>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_entity?: InputMaybe<Field_Entities_Bool_Exp>;
  field_type?: InputMaybe<Field_Types_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  number_field_value?: InputMaybe<Number_Field_Values_Bool_Exp>;
  number_field_values?: InputMaybe<Number_Field_Values_Bool_Exp>;
  number_field_values_aggregate?: InputMaybe<Number_Field_Values_Aggregate_Bool_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  select_field_value?: InputMaybe<Select_Field_Values_Bool_Exp>;
  select_field_values?: InputMaybe<Select_Field_Values_Bool_Exp>;
  select_field_values_aggregate?: InputMaybe<Select_Field_Values_Aggregate_Bool_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  text_field_value?: InputMaybe<Text_Field_Values_Bool_Exp>;
  text_field_values?: InputMaybe<Text_Field_Values_Bool_Exp>;
  text_field_values_aggregate?: InputMaybe<Text_Field_Values_Aggregate_Bool_Exp>;
  url_field_value?: InputMaybe<Url_Field_Values_Bool_Exp>;
  url_field_values?: InputMaybe<Url_Field_Values_Bool_Exp>;
  url_field_values_aggregate?: InputMaybe<Url_Field_Values_Aggregate_Bool_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_values" */
export enum Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_config_id", "id", "field_type" */
  FieldValuesFieldConfigIdFieldTypeIdKey = 'field_values_field_config_id_field_type_id_key',
  /** unique or primary key constraint on columns "id", "field_type" */
  FieldValuesFieldTypeIdKey = 'field_values_field_type_id_key',
  /** unique or primary key constraint on columns "id" */
  FieldValuesPkey = 'field_values_pkey',
}

/** input type for inserting data into table "field_values" */
export type Field_Values_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  date_field_value?: InputMaybe<Date_Field_Values_Obj_Rel_Insert_Input>;
  date_field_values?: InputMaybe<Date_Field_Values_Arr_Rel_Insert_Input>;
  email_field_value?: InputMaybe<Email_Field_Values_Obj_Rel_Insert_Input>;
  email_field_values?: InputMaybe<Email_Field_Values_Arr_Rel_Insert_Input>;
  entity_name?: InputMaybe<Field_Entities_Enum>;
  fieldTypeByFieldType?: InputMaybe<Field_Types_Obj_Rel_Insert_Input>;
  field_config?: InputMaybe<Field_Configs_Obj_Rel_Insert_Input>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_entity?: InputMaybe<Field_Entities_Obj_Rel_Insert_Input>;
  field_type?: InputMaybe<Field_Types_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  number_field_value?: InputMaybe<Number_Field_Values_Obj_Rel_Insert_Input>;
  number_field_values?: InputMaybe<Number_Field_Values_Arr_Rel_Insert_Input>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  select_field_value?: InputMaybe<Select_Field_Values_Obj_Rel_Insert_Input>;
  select_field_values?: InputMaybe<Select_Field_Values_Arr_Rel_Insert_Input>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  text_field_value?: InputMaybe<Text_Field_Values_Obj_Rel_Insert_Input>;
  text_field_values?: InputMaybe<Text_Field_Values_Arr_Rel_Insert_Input>;
  url_field_value?: InputMaybe<Url_Field_Values_Obj_Rel_Insert_Input>;
  url_field_values?: InputMaybe<Url_Field_Values_Arr_Rel_Insert_Input>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Field_Values_Max_Fields = {
  __typename?: 'field_values_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "field_values" */
export type Field_Values_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Values_Min_Fields = {
  __typename?: 'field_values_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "field_values" */
export type Field_Values_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_values" */
export type Field_Values_Mutation_Response = {
  __typename?: 'field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Values>;
};

/** input type for inserting object relation for remote table "field_values" */
export type Field_Values_Obj_Rel_Insert_Input = {
  data: Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "field_values" */
export type Field_Values_On_Conflict = {
  constraint: Field_Values_Constraint;
  update_columns?: Array<Field_Values_Update_Column>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "field_values". */
export type Field_Values_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  date_field_value?: InputMaybe<Date_Field_Values_Order_By>;
  date_field_values_aggregate?: InputMaybe<Date_Field_Values_Aggregate_Order_By>;
  email_field_value?: InputMaybe<Email_Field_Values_Order_By>;
  email_field_values_aggregate?: InputMaybe<Email_Field_Values_Aggregate_Order_By>;
  entity_name?: InputMaybe<Order_By>;
  fieldTypeByFieldType?: InputMaybe<Field_Types_Order_By>;
  field_config?: InputMaybe<Field_Configs_Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_entity?: InputMaybe<Field_Entities_Order_By>;
  field_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_field_value?: InputMaybe<Number_Field_Values_Order_By>;
  number_field_values_aggregate?: InputMaybe<Number_Field_Values_Aggregate_Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  select_field_value?: InputMaybe<Select_Field_Values_Order_By>;
  select_field_values_aggregate?: InputMaybe<Select_Field_Values_Aggregate_Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  text_field_value?: InputMaybe<Text_Field_Values_Order_By>;
  text_field_values_aggregate?: InputMaybe<Text_Field_Values_Aggregate_Order_By>;
  url_field_value?: InputMaybe<Url_Field_Values_Order_By>;
  url_field_values_aggregate?: InputMaybe<Url_Field_Values_Aggregate_Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: field_values */
export type Field_Values_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "field_values" */
export enum Field_Values_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "field_values" */
export type Field_Values_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_name?: InputMaybe<Field_Entities_Enum>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Field_Types_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "field_values" */
export type Field_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Values_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  entity_name?: InputMaybe<Field_Entities_Enum>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Field_Types_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "field_values" */
export enum Field_Values_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  VendorId = 'vendor_id',
}

export type Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Values_Bool_Exp;
};

/** columns and relationships of "storage.files" */
export type Files = {
  __typename?: 'files';
  /** An object relationship */
  bucket: Buckets;
  bucketId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  etag?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  export_client_questionnaires: Array<Client_Questionnaires>;
  /** An aggregate relationship */
  export_client_questionnaires_aggregate: Client_Questionnaires_Aggregate;
  /** An object relationship */
  files_client_questionnaire?: Maybe<Client_Questionnaires>;
  /** An object relationship */
  files_comment_upload?: Maybe<Comment_Uploads>;
  /** An array relationship */
  files_document_stores: Array<Ai_Files_Document_Store>;
  /** An aggregate relationship */
  files_document_stores_aggregate: Ai_Files_Document_Store_Aggregate;
  /** An object relationship */
  files_evidence_version_file?: Maybe<Evidence_Version_File>;
  /** An array relationship */
  files_vector_stores: Array<Ai_Files_Vector_Store>;
  /** An aggregate relationship */
  files_vector_stores_aggregate: Ai_Files_Vector_Store_Aggregate;
  id: Scalars['uuid']['output'];
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  /** An aggregate relationship */
  policy_versions_aggregate: Policy_Versions_Aggregate;
  /** An object relationship */
  questionnaire_file?: Maybe<Questionnaires>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  /** An aggregate relationship */
  questionnaire_uploads_aggregate: Questionnaire_Uploads_Aggregate;
  /** An object relationship */
  risk_document?: Maybe<Risk_Documents>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  vendor_document?: Maybe<Vendor_Documents>;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessment_sources: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_assessment_sources_aggregate: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** An aggregate relationship */
  vendor_questionnaire_form_uploads_aggregate: Vendor_Questionnaire_Form_Uploads_Aggregate;
  /** An array relationship */
  vendor_questionnaire_uploads: Array<Vendor_Questionnaire_Uploads>;
  /** An aggregate relationship */
  vendor_questionnaire_uploads_aggregate: Vendor_Questionnaire_Uploads_Aggregate;
};

/** columns and relationships of "storage.files" */
export type FilesExport_Client_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesExport_Client_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesFiles_Document_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Document_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Document_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesFiles_Document_Stores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Document_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Document_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesFiles_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesFiles_Vector_Stores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "storage.files" */
export type FilesPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesPolicy_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesQuestionnaire_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_Ai_Review_Assessment_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_Ai_Review_Assessment_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_Form_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** aggregated selection of "storage.files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

export type Files_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Files_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Files_Aggregate_Bool_Exp_Count>;
};

export type Files_Aggregate_Bool_Exp_Bool_And = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "storage.files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  avg?: Maybe<Files_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
  stddev?: Maybe<Files_Stddev_Fields>;
  stddev_pop?: Maybe<Files_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Files_Stddev_Samp_Fields>;
  sum?: Maybe<Files_Sum_Fields>;
  var_pop?: Maybe<Files_Var_Pop_Fields>;
  var_samp?: Maybe<Files_Var_Samp_Fields>;
  variance?: Maybe<Files_Variance_Fields>;
};

/** aggregate fields of "storage.files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "storage.files" */
export type Files_Aggregate_Order_By = {
  avg?: InputMaybe<Files_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
  stddev?: InputMaybe<Files_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Files_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Files_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Files_Sum_Order_By>;
  var_pop?: InputMaybe<Files_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Files_Var_Samp_Order_By>;
  variance?: InputMaybe<Files_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Files_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "storage.files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** aggregate avg on columns */
export type Files_Avg_Fields = {
  __typename?: 'files_avg_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "storage.files" */
export type Files_Avg_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  bucket?: InputMaybe<Buckets_Bool_Exp>;
  bucketId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  etag?: InputMaybe<String_Comparison_Exp>;
  export_client_questionnaires?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  export_client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Bool_Exp>;
  files_client_questionnaire?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  files_comment_upload?: InputMaybe<Comment_Uploads_Bool_Exp>;
  files_document_stores?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
  files_document_stores_aggregate?: InputMaybe<Ai_Files_Document_Store_Aggregate_Bool_Exp>;
  files_evidence_version_file?: InputMaybe<Evidence_Version_File_Bool_Exp>;
  files_vector_stores?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
  files_vector_stores_aggregate?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isUploaded?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  policy_versions?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Bool_Exp>;
  questionnaire_file?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  questionnaire_uploads_aggregate?: InputMaybe<Questionnaire_Uploads_Aggregate_Bool_Exp>;
  risk_document?: InputMaybe<Risk_Documents_Bool_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_document?: InputMaybe<Vendor_Documents_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment_sources?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment_sources_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp>;
  vendor_questionnaire_form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  vendor_questionnaire_form_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Bool_Exp>;
  vendor_questionnaire_uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  vendor_questionnaire_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "storage.files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Files_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Files_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Files_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "storage.files" */
export type Files_Inc_Input = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "storage.files" */
export type Files_Insert_Input = {
  bucket?: InputMaybe<Buckets_Obj_Rel_Insert_Input>;
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  export_client_questionnaires?: InputMaybe<Client_Questionnaires_Arr_Rel_Insert_Input>;
  files_client_questionnaire?: InputMaybe<Client_Questionnaires_Obj_Rel_Insert_Input>;
  files_comment_upload?: InputMaybe<Comment_Uploads_Obj_Rel_Insert_Input>;
  files_document_stores?: InputMaybe<Ai_Files_Document_Store_Arr_Rel_Insert_Input>;
  files_evidence_version_file?: InputMaybe<Evidence_Version_File_Obj_Rel_Insert_Input>;
  files_vector_stores?: InputMaybe<Ai_Files_Vector_Store_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  policy_versions?: InputMaybe<Policy_Versions_Arr_Rel_Insert_Input>;
  questionnaire_file?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Arr_Rel_Insert_Input>;
  risk_document?: InputMaybe<Risk_Documents_Obj_Rel_Insert_Input>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
  vendor_document?: InputMaybe<Vendor_Documents_Obj_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_assessment_sources?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Arr_Rel_Insert_Input>;
  vendor_questionnaire_form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Arr_Rel_Insert_Input>;
  vendor_questionnaire_uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "storage.files" */
export type Files_Max_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  bucketId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  etag?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "storage.files" */
export type Files_Min_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "storage.files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "storage.files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "storage.files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.files". */
export type Files_Order_By = {
  bucket?: InputMaybe<Buckets_Order_By>;
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  export_client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Order_By>;
  files_client_questionnaire?: InputMaybe<Client_Questionnaires_Order_By>;
  files_comment_upload?: InputMaybe<Comment_Uploads_Order_By>;
  files_document_stores_aggregate?: InputMaybe<Ai_Files_Document_Store_Aggregate_Order_By>;
  files_evidence_version_file?: InputMaybe<Evidence_Version_File_Order_By>;
  files_vector_stores_aggregate?: InputMaybe<Ai_Files_Vector_Store_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  isUploaded?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Order_By>;
  questionnaire_file?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_uploads_aggregate?: InputMaybe<Questionnaire_Uploads_Aggregate_Order_By>;
  risk_document?: InputMaybe<Risk_Documents_Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
  vendor_document?: InputMaybe<Vendor_Documents_Order_By>;
  vendor_questionnaire_ai_review_assessment_sources_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Order_By>;
  vendor_questionnaire_form_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By>;
  vendor_questionnaire_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Order_By>;
};

/** primary key columns input for table: storage.files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Files_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "storage.files" */
export enum Files_Select_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId',
}

/** select "files_aggregate_bool_exp_bool_and_arguments_columns" columns of table "storage.files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsUploaded = 'isUploaded',
}

/** select "files_aggregate_bool_exp_bool_or_arguments_columns" columns of table "storage.files" */
export enum Files_Select_Column_Files_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsUploaded = 'isUploaded',
}

/** input type for updating data in table "storage.files" */
export type Files_Set_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Files_Stddev_Fields = {
  __typename?: 'files_stddev_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "storage.files" */
export type Files_Stddev_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Files_Stddev_Pop_Fields = {
  __typename?: 'files_stddev_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "storage.files" */
export type Files_Stddev_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Files_Stddev_Samp_Fields = {
  __typename?: 'files_stddev_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "storage.files" */
export type Files_Stddev_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Files_Sum_Fields = {
  __typename?: 'files_sum_fields';
  size?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "storage.files" */
export type Files_Sum_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** update columns of table "storage.files" */
export enum Files_Update_Column {
  /** column name */
  BucketId = 'bucketId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Etag = 'etag',
  /** column name */
  Id = 'id',
  /** column name */
  IsUploaded = 'isUploaded',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MimeType = 'mimeType',
  /** column name */
  Name = 'name',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedByUserId = 'uploadedByUserId',
}

export type Files_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Files_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Files_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Files_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Files_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Files_Var_Pop_Fields = {
  __typename?: 'files_var_pop_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "storage.files" */
export type Files_Var_Pop_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Files_Var_Samp_Fields = {
  __typename?: 'files_var_samp_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "storage.files" */
export type Files_Var_Samp_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Files_Variance_Fields = {
  __typename?: 'files_variance_fields';
  size?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "storage.files" */
export type Files_Variance_Order_By = {
  size?: InputMaybe<Order_By>;
};

/** columns and relationships of "finding_causes" */
export type Finding_Causes = {
  __typename?: 'finding_causes';
  value: Scalars['String']['output'];
};

/** aggregated selection of "finding_causes" */
export type Finding_Causes_Aggregate = {
  __typename?: 'finding_causes_aggregate';
  aggregate?: Maybe<Finding_Causes_Aggregate_Fields>;
  nodes: Array<Finding_Causes>;
};

/** aggregate fields of "finding_causes" */
export type Finding_Causes_Aggregate_Fields = {
  __typename?: 'finding_causes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Finding_Causes_Max_Fields>;
  min?: Maybe<Finding_Causes_Min_Fields>;
};

/** aggregate fields of "finding_causes" */
export type Finding_Causes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Finding_Causes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "finding_causes". All fields are combined with a logical 'AND'. */
export type Finding_Causes_Bool_Exp = {
  _and?: InputMaybe<Array<Finding_Causes_Bool_Exp>>;
  _not?: InputMaybe<Finding_Causes_Bool_Exp>;
  _or?: InputMaybe<Array<Finding_Causes_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "finding_causes" */
export enum Finding_Causes_Constraint {
  /** unique or primary key constraint on columns "value" */
  FindingCausesPkey = 'finding_causes_pkey',
}

export enum Finding_Causes_Enum {
  AiRecommendation = 'ai_recommendation',
  AtRiskEvidence = 'at_risk_evidence',
  ExpiredEvidence = 'expired_evidence',
  IntegrationCheck = 'integration_check',
  IntegrationError = 'integration_error',
  ManualConsideration = 'manual_consideration',
  ManualIssue = 'manual_issue',
  ManualRecommendation = 'manual_recommendation',
  PolicyError = 'policy_error',
}

/** Boolean expression to compare columns of type "finding_causes_enum". All fields are combined with logical 'AND'. */
export type Finding_Causes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Finding_Causes_Enum>;
  _in?: InputMaybe<Array<Finding_Causes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Finding_Causes_Enum>;
  _nin?: InputMaybe<Array<Finding_Causes_Enum>>;
};

/** input type for inserting data into table "finding_causes" */
export type Finding_Causes_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Finding_Causes_Max_Fields = {
  __typename?: 'finding_causes_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Finding_Causes_Min_Fields = {
  __typename?: 'finding_causes_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "finding_causes" */
export type Finding_Causes_Mutation_Response = {
  __typename?: 'finding_causes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Finding_Causes>;
};

/** on_conflict condition type for table "finding_causes" */
export type Finding_Causes_On_Conflict = {
  constraint: Finding_Causes_Constraint;
  update_columns?: Array<Finding_Causes_Update_Column>;
  where?: InputMaybe<Finding_Causes_Bool_Exp>;
};

/** Ordering options when selecting data from "finding_causes". */
export type Finding_Causes_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: finding_causes */
export type Finding_Causes_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "finding_causes" */
export enum Finding_Causes_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "finding_causes" */
export type Finding_Causes_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "finding_causes" */
export type Finding_Causes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finding_Causes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finding_Causes_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "finding_causes" */
export enum Finding_Causes_Update_Column {
  /** column name */
  Value = 'value',
}

export type Finding_Causes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Finding_Causes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finding_Causes_Bool_Exp;
};

/** columns and relationships of "finding_types" */
export type Finding_Types = {
  __typename?: 'finding_types';
  value: Scalars['String']['output'];
};

/** aggregated selection of "finding_types" */
export type Finding_Types_Aggregate = {
  __typename?: 'finding_types_aggregate';
  aggregate?: Maybe<Finding_Types_Aggregate_Fields>;
  nodes: Array<Finding_Types>;
};

/** aggregate fields of "finding_types" */
export type Finding_Types_Aggregate_Fields = {
  __typename?: 'finding_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Finding_Types_Max_Fields>;
  min?: Maybe<Finding_Types_Min_Fields>;
};

/** aggregate fields of "finding_types" */
export type Finding_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Finding_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "finding_types". All fields are combined with a logical 'AND'. */
export type Finding_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Finding_Types_Bool_Exp>>;
  _not?: InputMaybe<Finding_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Finding_Types_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "finding_types" */
export enum Finding_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  FindingTypesPkey = 'finding_types_pkey',
}

export enum Finding_Types_Enum {
  Consideration = 'consideration',
  Issue = 'issue',
  Recommendation = 'recommendation',
}

/** Boolean expression to compare columns of type "finding_types_enum". All fields are combined with logical 'AND'. */
export type Finding_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Finding_Types_Enum>;
  _in?: InputMaybe<Array<Finding_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Finding_Types_Enum>;
  _nin?: InputMaybe<Array<Finding_Types_Enum>>;
};

/** input type for inserting data into table "finding_types" */
export type Finding_Types_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Finding_Types_Max_Fields = {
  __typename?: 'finding_types_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Finding_Types_Min_Fields = {
  __typename?: 'finding_types_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "finding_types" */
export type Finding_Types_Mutation_Response = {
  __typename?: 'finding_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Finding_Types>;
};

/** on_conflict condition type for table "finding_types" */
export type Finding_Types_On_Conflict = {
  constraint: Finding_Types_Constraint;
  update_columns?: Array<Finding_Types_Update_Column>;
  where?: InputMaybe<Finding_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "finding_types". */
export type Finding_Types_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: finding_types */
export type Finding_Types_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "finding_types" */
export enum Finding_Types_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "finding_types" */
export type Finding_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "finding_types" */
export type Finding_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Finding_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Finding_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "finding_types" */
export enum Finding_Types_Update_Column {
  /** column name */
  Value = 'value',
}

export type Finding_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Finding_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Finding_Types_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "forms" */
export type Forms = {
  __typename?: 'forms';
  config?: Maybe<Scalars['FormConfig']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire?: Maybe<Questionnaires>;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "forms" */
export type FormsConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "forms" */
export type Forms_Aggregate = {
  __typename?: 'forms_aggregate';
  aggregate?: Maybe<Forms_Aggregate_Fields>;
  nodes: Array<Forms>;
};

/** aggregate fields of "forms" */
export type Forms_Aggregate_Fields = {
  __typename?: 'forms_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Forms_Max_Fields>;
  min?: Maybe<Forms_Min_Fields>;
};

/** aggregate fields of "forms" */
export type Forms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Forms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Forms_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "forms". All fields are combined with a logical 'AND'. */
export type Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Forms_Bool_Exp>>;
  _not?: InputMaybe<Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Forms_Bool_Exp>>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "forms" */
export enum Forms_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormsPkey = 'forms_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Forms_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Forms_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Forms_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "forms" */
export type Forms_Insert_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Forms_Max_Fields = {
  __typename?: 'forms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Forms_Min_Fields = {
  __typename?: 'forms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "forms" */
export type Forms_Mutation_Response = {
  __typename?: 'forms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Forms>;
};

/** input type for inserting object relation for remote table "forms" */
export type Forms_Obj_Rel_Insert_Input = {
  data: Forms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** on_conflict condition type for table "forms" */
export type Forms_On_Conflict = {
  constraint: Forms_Constraint;
  update_columns?: Array<Forms_Update_Column>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "forms". */
export type Forms_Order_By = {
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: forms */
export type Forms_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Forms_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "forms" */
export enum Forms_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "forms" */
export type Forms_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "forms" */
export type Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Forms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Forms_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "forms" */
export enum Forms_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Forms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Forms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Forms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Forms_Bool_Exp;
};

/** columns and relationships of "frameworks" */
export type Frameworks = {
  __typename?: 'frameworks';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  frameworks_controls: Array<Frameworks_Controls>;
  /** An aggregate relationship */
  frameworks_controls_aggregate: Frameworks_Controls_Aggregate;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  programs: Array<Programs>;
  /** An aggregate relationship */
  programs_aggregate: Programs_Aggregate;
};

/** columns and relationships of "frameworks" */
export type FrameworksFrameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

/** columns and relationships of "frameworks" */
export type FrameworksFrameworks_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

/** columns and relationships of "frameworks" */
export type FrameworksProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** columns and relationships of "frameworks" */
export type FrameworksPrograms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** aggregated selection of "frameworks" */
export type Frameworks_Aggregate = {
  __typename?: 'frameworks_aggregate';
  aggregate?: Maybe<Frameworks_Aggregate_Fields>;
  nodes: Array<Frameworks>;
};

export type Frameworks_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Frameworks_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Frameworks_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Frameworks_Aggregate_Bool_Exp_Count>;
};

export type Frameworks_Aggregate_Bool_Exp_Bool_And = {
  arguments: Frameworks_Select_Column_Frameworks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Frameworks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Frameworks_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Frameworks_Select_Column_Frameworks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Frameworks_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Frameworks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Frameworks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Frameworks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "frameworks" */
export type Frameworks_Aggregate_Fields = {
  __typename?: 'frameworks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Frameworks_Max_Fields>;
  min?: Maybe<Frameworks_Min_Fields>;
};

/** aggregate fields of "frameworks" */
export type Frameworks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Frameworks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "frameworks" */
export type Frameworks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Frameworks_Max_Order_By>;
  min?: InputMaybe<Frameworks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "frameworks" */
export type Frameworks_Arr_Rel_Insert_Input = {
  data: Array<Frameworks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Frameworks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "frameworks". All fields are combined with a logical 'AND'. */
export type Frameworks_Bool_Exp = {
  _and?: InputMaybe<Array<Frameworks_Bool_Exp>>;
  _not?: InputMaybe<Frameworks_Bool_Exp>;
  _or?: InputMaybe<Array<Frameworks_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  frameworks_controls?: InputMaybe<Frameworks_Controls_Bool_Exp>;
  frameworks_controls_aggregate?: InputMaybe<Frameworks_Controls_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  programs?: InputMaybe<Programs_Bool_Exp>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "frameworks" */
export enum Frameworks_Constraint {
  /** unique or primary key constraint on columns "id" */
  FrameworksPkey1 = 'frameworks_pkey1',
}

/** columns and relationships of "frameworks_controls" */
export type Frameworks_Controls = {
  __typename?: 'frameworks_controls';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  framework: Frameworks;
  framework_id: Scalars['uuid']['output'];
};

/** aggregated selection of "frameworks_controls" */
export type Frameworks_Controls_Aggregate = {
  __typename?: 'frameworks_controls_aggregate';
  aggregate?: Maybe<Frameworks_Controls_Aggregate_Fields>;
  nodes: Array<Frameworks_Controls>;
};

export type Frameworks_Controls_Aggregate_Bool_Exp = {
  count?: InputMaybe<Frameworks_Controls_Aggregate_Bool_Exp_Count>;
};

export type Frameworks_Controls_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Frameworks_Controls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "frameworks_controls" */
export type Frameworks_Controls_Aggregate_Fields = {
  __typename?: 'frameworks_controls_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Frameworks_Controls_Max_Fields>;
  min?: Maybe<Frameworks_Controls_Min_Fields>;
};

/** aggregate fields of "frameworks_controls" */
export type Frameworks_Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "frameworks_controls" */
export type Frameworks_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Frameworks_Controls_Max_Order_By>;
  min?: InputMaybe<Frameworks_Controls_Min_Order_By>;
};

/** input type for inserting array relation for remote table "frameworks_controls" */
export type Frameworks_Controls_Arr_Rel_Insert_Input = {
  data: Array<Frameworks_Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Frameworks_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "frameworks_controls". All fields are combined with a logical 'AND'. */
export type Frameworks_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Frameworks_Controls_Bool_Exp>>;
  _not?: InputMaybe<Frameworks_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Frameworks_Controls_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  framework?: InputMaybe<Frameworks_Bool_Exp>;
  framework_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "frameworks_controls" */
export enum Frameworks_Controls_Constraint {
  /** unique or primary key constraint on columns "control_id", "framework_id" */
  FrameworksControlsPkey1 = 'frameworks_controls_pkey1',
}

/** input type for inserting data into table "frameworks_controls" */
export type Frameworks_Controls_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework?: InputMaybe<Frameworks_Obj_Rel_Insert_Input>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Frameworks_Controls_Max_Fields = {
  __typename?: 'frameworks_controls_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  framework_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "frameworks_controls" */
export type Frameworks_Controls_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Frameworks_Controls_Min_Fields = {
  __typename?: 'frameworks_controls_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  framework_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "frameworks_controls" */
export type Frameworks_Controls_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "frameworks_controls" */
export type Frameworks_Controls_Mutation_Response = {
  __typename?: 'frameworks_controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Frameworks_Controls>;
};

/** on_conflict condition type for table "frameworks_controls" */
export type Frameworks_Controls_On_Conflict = {
  constraint: Frameworks_Controls_Constraint;
  update_columns?: Array<Frameworks_Controls_Update_Column>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "frameworks_controls". */
export type Frameworks_Controls_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  framework?: InputMaybe<Frameworks_Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: frameworks_controls */
export type Frameworks_Controls_Pk_Columns_Input = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

/** select columns of table "frameworks_controls" */
export enum Frameworks_Controls_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  FrameworkId = 'framework_id',
}

/** input type for updating data in table "frameworks_controls" */
export type Frameworks_Controls_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "frameworks_controls" */
export type Frameworks_Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frameworks_Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frameworks_Controls_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "frameworks_controls" */
export enum Frameworks_Controls_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  FrameworkId = 'framework_id',
}

export type Frameworks_Controls_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Frameworks_Controls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Frameworks_Controls_Bool_Exp;
};

/** input type for inserting data into table "frameworks" */
export type Frameworks_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frameworks_controls?: InputMaybe<Frameworks_Controls_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  programs?: InputMaybe<Programs_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Frameworks_Max_Fields = {
  __typename?: 'frameworks_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "frameworks" */
export type Frameworks_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Frameworks_Min_Fields = {
  __typename?: 'frameworks_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "frameworks" */
export type Frameworks_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "frameworks" */
export type Frameworks_Mutation_Response = {
  __typename?: 'frameworks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Frameworks>;
};

/** input type for inserting object relation for remote table "frameworks" */
export type Frameworks_Obj_Rel_Insert_Input = {
  data: Frameworks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Frameworks_On_Conflict>;
};

/** on_conflict condition type for table "frameworks" */
export type Frameworks_On_Conflict = {
  constraint: Frameworks_Constraint;
  update_columns?: Array<Frameworks_Update_Column>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

/** Ordering options when selecting data from "frameworks". */
export type Frameworks_Order_By = {
  active?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  frameworks_controls_aggregate?: InputMaybe<Frameworks_Controls_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Order_By>;
};

/** primary key columns input for table: frameworks */
export type Frameworks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "frameworks" */
export enum Frameworks_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
}

/** select "frameworks_aggregate_bool_exp_bool_and_arguments_columns" columns of table "frameworks" */
export enum Frameworks_Select_Column_Frameworks_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** select "frameworks_aggregate_bool_exp_bool_or_arguments_columns" columns of table "frameworks" */
export enum Frameworks_Select_Column_Frameworks_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
}

/** input type for updating data in table "frameworks" */
export type Frameworks_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "frameworks" */
export type Frameworks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frameworks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frameworks_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "frameworks" */
export enum Frameworks_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
}

export type Frameworks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Frameworks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Frameworks_Bool_Exp;
};

/** Stores frequency values for the risks and vendors */
export type Frequencies = {
  __typename?: 'frequencies';
  name: Scalars['String']['output'];
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** An array relationship */
  vendors: Array<Vendors>;
  /** An aggregate relationship */
  vendors_aggregate: Vendors_Aggregate;
};

/** Stores frequency values for the risks and vendors */
export type FrequenciesRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** Stores frequency values for the risks and vendors */
export type FrequenciesRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** Stores frequency values for the risks and vendors */
export type FrequenciesVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** Stores frequency values for the risks and vendors */
export type FrequenciesVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** aggregated selection of "frequencies" */
export type Frequencies_Aggregate = {
  __typename?: 'frequencies_aggregate';
  aggregate?: Maybe<Frequencies_Aggregate_Fields>;
  nodes: Array<Frequencies>;
};

/** aggregate fields of "frequencies" */
export type Frequencies_Aggregate_Fields = {
  __typename?: 'frequencies_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Frequencies_Max_Fields>;
  min?: Maybe<Frequencies_Min_Fields>;
};

/** aggregate fields of "frequencies" */
export type Frequencies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Frequencies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "frequencies". All fields are combined with a logical 'AND'. */
export type Frequencies_Bool_Exp = {
  _and?: InputMaybe<Array<Frequencies_Bool_Exp>>;
  _not?: InputMaybe<Frequencies_Bool_Exp>;
  _or?: InputMaybe<Array<Frequencies_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  risks?: InputMaybe<Risks_Bool_Exp>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "frequencies" */
export enum Frequencies_Constraint {
  /** unique or primary key constraint on columns "name" */
  FrequenciesPkey = 'frequencies_pkey',
}

export enum Frequencies_Enum {
  Annual = 'Annual',
  Biannual = 'Biannual',
  Daily = 'Daily',
  Indefinite = 'Indefinite',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  ThreeYears = 'Three_years',
  TwoYears = 'Two_years',
  Weekly = 'Weekly',
}

/** Boolean expression to compare columns of type "frequencies_enum". All fields are combined with logical 'AND'. */
export type Frequencies_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Frequencies_Enum>;
  _in?: InputMaybe<Array<Frequencies_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Frequencies_Enum>;
  _nin?: InputMaybe<Array<Frequencies_Enum>>;
};

/** input type for inserting data into table "frequencies" */
export type Frequencies_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  risks?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  vendors?: InputMaybe<Vendors_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Frequencies_Max_Fields = {
  __typename?: 'frequencies_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Frequencies_Min_Fields = {
  __typename?: 'frequencies_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "frequencies" */
export type Frequencies_Mutation_Response = {
  __typename?: 'frequencies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Frequencies>;
};

/** input type for inserting object relation for remote table "frequencies" */
export type Frequencies_Obj_Rel_Insert_Input = {
  data: Frequencies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Frequencies_On_Conflict>;
};

/** on_conflict condition type for table "frequencies" */
export type Frequencies_On_Conflict = {
  constraint: Frequencies_Constraint;
  update_columns?: Array<Frequencies_Update_Column>;
  where?: InputMaybe<Frequencies_Bool_Exp>;
};

/** Ordering options when selecting data from "frequencies". */
export type Frequencies_Order_By = {
  name?: InputMaybe<Order_By>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
};

/** primary key columns input for table: frequencies */
export type Frequencies_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "frequencies" */
export enum Frequencies_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "frequencies" */
export type Frequencies_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "frequencies" */
export type Frequencies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frequencies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frequencies_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "frequencies" */
export enum Frequencies_Update_Column {
  /** column name */
  Name = 'name',
}

export type Frequencies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Frequencies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Frequencies_Bool_Exp;
};

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups';
  /** An array relationship */
  control_groups: Array<Control_Groups>;
  /** An aggregate relationship */
  control_groups_aggregate: Control_Groups_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "groups" */
export type GroupsControl_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

/** columns and relationships of "groups" */
export type GroupsControl_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Groups_Aggregate = {
  __typename?: 'groups_aggregate';
  aggregate?: Maybe<Groups_Aggregate_Fields>;
  nodes: Array<Groups>;
};

export type Groups_Aggregate_Bool_Exp = {
  count?: InputMaybe<Groups_Aggregate_Bool_Exp_Count>;
};

export type Groups_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Groups_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'groups_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Groups_Max_Fields>;
  min?: Maybe<Groups_Min_Fields>;
};

/** aggregate fields of "groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "groups" */
export type Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Max_Order_By>;
  min?: InputMaybe<Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "groups" */
export type Groups_Arr_Rel_Insert_Input = {
  data: Array<Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Bool_Exp>>;
  _not?: InputMaybe<Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Bool_Exp>>;
  control_groups?: InputMaybe<Control_Groups_Bool_Exp>;
  control_groups_aggregate?: InputMaybe<Control_Groups_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  GroupsOrganizationIdNameKey = 'groups_organization_id_name_key',
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey',
}

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  control_groups?: InputMaybe<Control_Groups_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'groups_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "groups" */
export type Groups_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'groups_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "groups" */
export type Groups_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  control_groups_aggregate?: InputMaybe<Control_Groups_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "groups" */
export type Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Bool_Exp;
};

/** fields of action: "import_policies" */
export type Import_Policies = {
  __typename?: 'import_policies';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz']['output'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']['output']>;
  /** the unique id of an action */
  id: Scalars['uuid']['output'];
  /** the output fields of this action */
  output?: Maybe<ImportPoliciesOutput>;
};

/** fields of action: "import_program" */
export type Import_Program = {
  __typename?: 'import_program';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz']['output'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']['output']>;
  /** the unique id of an action */
  id: Scalars['uuid']['output'];
  /** the output fields of this action */
  output?: Maybe<ImportProgramOutput>;
};

/** fields of action: "import_risks" */
export type Import_Risks = {
  __typename?: 'import_risks';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz']['output'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']['output']>;
  /** the unique id of an action */
  id: Scalars['uuid']['output'];
  /** the output fields of this action */
  output?: Maybe<ImportRisksOutput>;
};

/** fields of action: "import_vendors" */
export type Import_Vendors = {
  __typename?: 'import_vendors';
  /** the time at which this action was created */
  created_at: Scalars['timestamptz']['output'];
  /** errors related to the invocation */
  errors?: Maybe<Scalars['json']['output']>;
  /** the unique id of an action */
  id: Scalars['uuid']['output'];
  /** the output fields of this action */
  output?: Maybe<ImportVendorsOutput>;
};

/** Boolean expression to compare columns of type "inet". All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['inet']['input']>;
  _gt?: InputMaybe<Scalars['inet']['input']>;
  _gte?: InputMaybe<Scalars['inet']['input']>;
  _in?: InputMaybe<Array<Scalars['inet']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['inet']['input']>;
  _lte?: InputMaybe<Scalars['inet']['input']>;
  _neq?: InputMaybe<Scalars['inet']['input']>;
  _nin?: InputMaybe<Array<Scalars['inet']['input']>>;
};

/** columns and relationships of "integration_names" */
export type Integration_Names = {
  __typename?: 'integration_names';
  /** An object relationship */
  integration?: Maybe<Integrations>;
  name: Scalars['String']['output'];
};

/** aggregated selection of "integration_names" */
export type Integration_Names_Aggregate = {
  __typename?: 'integration_names_aggregate';
  aggregate?: Maybe<Integration_Names_Aggregate_Fields>;
  nodes: Array<Integration_Names>;
};

/** aggregate fields of "integration_names" */
export type Integration_Names_Aggregate_Fields = {
  __typename?: 'integration_names_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Integration_Names_Max_Fields>;
  min?: Maybe<Integration_Names_Min_Fields>;
};

/** aggregate fields of "integration_names" */
export type Integration_Names_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integration_Names_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "integration_names". All fields are combined with a logical 'AND'. */
export type Integration_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Integration_Names_Bool_Exp>>;
  _not?: InputMaybe<Integration_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Names_Bool_Exp>>;
  integration?: InputMaybe<Integrations_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_names" */
export enum Integration_Names_Constraint {
  /** unique or primary key constraint on columns "name" */
  IntegrationNamesPkey = 'integration_names_pkey',
}

export enum Integration_Names_Enum {
  Aws = 'aws',
  Azure = 'azure',
  Confluence = 'confluence',
  Crowdstrike = 'crowdstrike',
  Gcp = 'gcp',
  Github = 'github',
  Jira = 'jira',
  Jumpcloud = 'jumpcloud',
  Onelogin = 'onelogin',
  Qualys = 'qualys',
  Sharepoint = 'sharepoint',
  Veracode = 'veracode',
}

/** Boolean expression to compare columns of type "integration_names_enum". All fields are combined with logical 'AND'. */
export type Integration_Names_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Integration_Names_Enum>;
  _in?: InputMaybe<Array<Integration_Names_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Integration_Names_Enum>;
  _nin?: InputMaybe<Array<Integration_Names_Enum>>;
};

/** input type for inserting data into table "integration_names" */
export type Integration_Names_Insert_Input = {
  integration?: InputMaybe<Integrations_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Integration_Names_Max_Fields = {
  __typename?: 'integration_names_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Integration_Names_Min_Fields = {
  __typename?: 'integration_names_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "integration_names" */
export type Integration_Names_Mutation_Response = {
  __typename?: 'integration_names_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Names>;
};

/** input type for inserting object relation for remote table "integration_names" */
export type Integration_Names_Obj_Rel_Insert_Input = {
  data: Integration_Names_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Names_On_Conflict>;
};

/** on_conflict condition type for table "integration_names" */
export type Integration_Names_On_Conflict = {
  constraint: Integration_Names_Constraint;
  update_columns?: Array<Integration_Names_Update_Column>;
  where?: InputMaybe<Integration_Names_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_names". */
export type Integration_Names_Order_By = {
  integration?: InputMaybe<Integrations_Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: integration_names */
export type Integration_Names_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "integration_names" */
export enum Integration_Names_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "integration_names" */
export type Integration_Names_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "integration_names" */
export type Integration_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Names_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "integration_names" */
export enum Integration_Names_Update_Column {
  /** column name */
  Name = 'name',
}

export type Integration_Names_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integration_Names_Set_Input>;
  /** filter the rows which have to be updated */
  where: Integration_Names_Bool_Exp;
};

/** columns and relationships of "integration_runs" */
export type Integration_Runs = {
  __typename?: 'integration_runs';
  check_failure_message?: Maybe<Scalars['String']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  evidence_integration: Evidence_Integrations;
  evidence_integration_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  report_file_id?: Maybe<Scalars['uuid']['output']>;
  run_result?: Maybe<Scalars['jsonb']['output']>;
  temporal_workflow_id: Scalars['String']['output'];
  temporal_workflow_run_id: Scalars['uuid']['output'];
};

/** columns and relationships of "integration_runs" */
export type Integration_RunsRun_ResultArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "integration_runs" */
export type Integration_Runs_Aggregate = {
  __typename?: 'integration_runs_aggregate';
  aggregate?: Maybe<Integration_Runs_Aggregate_Fields>;
  nodes: Array<Integration_Runs>;
};

/** aggregate fields of "integration_runs" */
export type Integration_Runs_Aggregate_Fields = {
  __typename?: 'integration_runs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Integration_Runs_Max_Fields>;
  min?: Maybe<Integration_Runs_Min_Fields>;
};

/** aggregate fields of "integration_runs" */
export type Integration_Runs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integration_Runs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Integration_Runs_Append_Input = {
  run_result?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "integration_runs". All fields are combined with a logical 'AND'. */
export type Integration_Runs_Bool_Exp = {
  _and?: InputMaybe<Array<Integration_Runs_Bool_Exp>>;
  _not?: InputMaybe<Integration_Runs_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Runs_Bool_Exp>>;
  check_failure_message?: InputMaybe<String_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  evidence_integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  report_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  run_result?: InputMaybe<Jsonb_Comparison_Exp>;
  temporal_workflow_id?: InputMaybe<String_Comparison_Exp>;
  temporal_workflow_run_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "integration_runs" */
export enum Integration_Runs_Constraint {
  /** unique or primary key constraint on columns "id" */
  IntegrationRunsPkey = 'integration_runs_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Integration_Runs_Delete_At_Path_Input = {
  run_result?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Integration_Runs_Delete_Elem_Input = {
  run_result?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Integration_Runs_Delete_Key_Input = {
  run_result?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "integration_runs" */
export type Integration_Runs_Insert_Input = {
  check_failure_message?: InputMaybe<Scalars['String']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Obj_Rel_Insert_Input>;
  evidence_integration_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_file_id?: InputMaybe<Scalars['uuid']['input']>;
  run_result?: InputMaybe<Scalars['jsonb']['input']>;
  temporal_workflow_id?: InputMaybe<Scalars['String']['input']>;
  temporal_workflow_run_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Integration_Runs_Max_Fields = {
  __typename?: 'integration_runs_max_fields';
  check_failure_message?: Maybe<Scalars['String']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  evidence_integration_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_file_id?: Maybe<Scalars['uuid']['output']>;
  temporal_workflow_id?: Maybe<Scalars['String']['output']>;
  temporal_workflow_run_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Integration_Runs_Min_Fields = {
  __typename?: 'integration_runs_min_fields';
  check_failure_message?: Maybe<Scalars['String']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  evidence_integration_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  report_file_id?: Maybe<Scalars['uuid']['output']>;
  temporal_workflow_id?: Maybe<Scalars['String']['output']>;
  temporal_workflow_run_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "integration_runs" */
export type Integration_Runs_Mutation_Response = {
  __typename?: 'integration_runs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Integration_Runs>;
};

/** input type for inserting object relation for remote table "integration_runs" */
export type Integration_Runs_Obj_Rel_Insert_Input = {
  data: Integration_Runs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Integration_Runs_On_Conflict>;
};

/** on_conflict condition type for table "integration_runs" */
export type Integration_Runs_On_Conflict = {
  constraint: Integration_Runs_Constraint;
  update_columns?: Array<Integration_Runs_Update_Column>;
  where?: InputMaybe<Integration_Runs_Bool_Exp>;
};

/** Ordering options when selecting data from "integration_runs". */
export type Integration_Runs_Order_By = {
  check_failure_message?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Order_By>;
  evidence_integration_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  report_file_id?: InputMaybe<Order_By>;
  run_result?: InputMaybe<Order_By>;
  temporal_workflow_id?: InputMaybe<Order_By>;
  temporal_workflow_run_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: integration_runs */
export type Integration_Runs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Integration_Runs_Prepend_Input = {
  run_result?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "integration_runs" */
export enum Integration_Runs_Select_Column {
  /** column name */
  CheckFailureMessage = 'check_failure_message',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  EvidenceIntegrationId = 'evidence_integration_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportFileId = 'report_file_id',
  /** column name */
  RunResult = 'run_result',
  /** column name */
  TemporalWorkflowId = 'temporal_workflow_id',
  /** column name */
  TemporalWorkflowRunId = 'temporal_workflow_run_id',
}

/** input type for updating data in table "integration_runs" */
export type Integration_Runs_Set_Input = {
  check_failure_message?: InputMaybe<Scalars['String']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  evidence_integration_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_file_id?: InputMaybe<Scalars['uuid']['input']>;
  run_result?: InputMaybe<Scalars['jsonb']['input']>;
  temporal_workflow_id?: InputMaybe<Scalars['String']['input']>;
  temporal_workflow_run_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "integration_runs" */
export type Integration_Runs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Runs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Runs_Stream_Cursor_Value_Input = {
  check_failure_message?: InputMaybe<Scalars['String']['input']>;
  error_message?: InputMaybe<Scalars['String']['input']>;
  evidence_integration_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  report_file_id?: InputMaybe<Scalars['uuid']['input']>;
  run_result?: InputMaybe<Scalars['jsonb']['input']>;
  temporal_workflow_id?: InputMaybe<Scalars['String']['input']>;
  temporal_workflow_run_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "integration_runs" */
export enum Integration_Runs_Update_Column {
  /** column name */
  CheckFailureMessage = 'check_failure_message',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  EvidenceIntegrationId = 'evidence_integration_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReportFileId = 'report_file_id',
  /** column name */
  RunResult = 'run_result',
  /** column name */
  TemporalWorkflowId = 'temporal_workflow_id',
  /** column name */
  TemporalWorkflowRunId = 'temporal_workflow_run_id',
}

export type Integration_Runs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Integration_Runs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Integration_Runs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Integration_Runs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Integration_Runs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Integration_Runs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integration_Runs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Integration_Runs_Bool_Exp;
};

/** columns and relationships of "integration_usage_stats_view" */
export type Integration_Usage_Stats_View = {
  __typename?: 'integration_usage_stats_view';
  controls_count?: Maybe<Scalars['bigint']['output']>;
  organization_integration_id?: Maybe<Scalars['uuid']['output']>;
  programs_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregated selection of "integration_usage_stats_view" */
export type Integration_Usage_Stats_View_Aggregate = {
  __typename?: 'integration_usage_stats_view_aggregate';
  aggregate?: Maybe<Integration_Usage_Stats_View_Aggregate_Fields>;
  nodes: Array<Integration_Usage_Stats_View>;
};

/** aggregate fields of "integration_usage_stats_view" */
export type Integration_Usage_Stats_View_Aggregate_Fields = {
  __typename?: 'integration_usage_stats_view_aggregate_fields';
  avg?: Maybe<Integration_Usage_Stats_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Integration_Usage_Stats_View_Max_Fields>;
  min?: Maybe<Integration_Usage_Stats_View_Min_Fields>;
  stddev?: Maybe<Integration_Usage_Stats_View_Stddev_Fields>;
  stddev_pop?: Maybe<Integration_Usage_Stats_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Integration_Usage_Stats_View_Stddev_Samp_Fields>;
  sum?: Maybe<Integration_Usage_Stats_View_Sum_Fields>;
  var_pop?: Maybe<Integration_Usage_Stats_View_Var_Pop_Fields>;
  var_samp?: Maybe<Integration_Usage_Stats_View_Var_Samp_Fields>;
  variance?: Maybe<Integration_Usage_Stats_View_Variance_Fields>;
};

/** aggregate fields of "integration_usage_stats_view" */
export type Integration_Usage_Stats_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integration_Usage_Stats_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Integration_Usage_Stats_View_Avg_Fields = {
  __typename?: 'integration_usage_stats_view_avg_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  programs_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "integration_usage_stats_view". All fields are combined with a logical 'AND'. */
export type Integration_Usage_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<Integration_Usage_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<Integration_Usage_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Usage_Stats_View_Bool_Exp>>;
  controls_count?: InputMaybe<Bigint_Comparison_Exp>;
  organization_integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  programs_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "integration_usage_stats_view" */
export type Integration_Usage_Stats_View_Insert_Input = {
  controls_count?: InputMaybe<Scalars['bigint']['input']>;
  organization_integration_id?: InputMaybe<Scalars['uuid']['input']>;
  programs_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Integration_Usage_Stats_View_Max_Fields = {
  __typename?: 'integration_usage_stats_view_max_fields';
  controls_count?: Maybe<Scalars['bigint']['output']>;
  organization_integration_id?: Maybe<Scalars['uuid']['output']>;
  programs_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Integration_Usage_Stats_View_Min_Fields = {
  __typename?: 'integration_usage_stats_view_min_fields';
  controls_count?: Maybe<Scalars['bigint']['output']>;
  organization_integration_id?: Maybe<Scalars['uuid']['output']>;
  programs_count?: Maybe<Scalars['bigint']['output']>;
};

/** input type for inserting object relation for remote table "integration_usage_stats_view" */
export type Integration_Usage_Stats_View_Obj_Rel_Insert_Input = {
  data: Integration_Usage_Stats_View_Insert_Input;
};

/** Ordering options when selecting data from "integration_usage_stats_view". */
export type Integration_Usage_Stats_View_Order_By = {
  controls_count?: InputMaybe<Order_By>;
  organization_integration_id?: InputMaybe<Order_By>;
  programs_count?: InputMaybe<Order_By>;
};

/** select columns of table "integration_usage_stats_view" */
export enum Integration_Usage_Stats_View_Select_Column {
  /** column name */
  ControlsCount = 'controls_count',
  /** column name */
  OrganizationIntegrationId = 'organization_integration_id',
  /** column name */
  ProgramsCount = 'programs_count',
}

/** aggregate stddev on columns */
export type Integration_Usage_Stats_View_Stddev_Fields = {
  __typename?: 'integration_usage_stats_view_stddev_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  programs_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Integration_Usage_Stats_View_Stddev_Pop_Fields = {
  __typename?: 'integration_usage_stats_view_stddev_pop_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  programs_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Integration_Usage_Stats_View_Stddev_Samp_Fields = {
  __typename?: 'integration_usage_stats_view_stddev_samp_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  programs_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "integration_usage_stats_view" */
export type Integration_Usage_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integration_Usage_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integration_Usage_Stats_View_Stream_Cursor_Value_Input = {
  controls_count?: InputMaybe<Scalars['bigint']['input']>;
  organization_integration_id?: InputMaybe<Scalars['uuid']['input']>;
  programs_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Integration_Usage_Stats_View_Sum_Fields = {
  __typename?: 'integration_usage_stats_view_sum_fields';
  controls_count?: Maybe<Scalars['bigint']['output']>;
  programs_count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type Integration_Usage_Stats_View_Var_Pop_Fields = {
  __typename?: 'integration_usage_stats_view_var_pop_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  programs_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Integration_Usage_Stats_View_Var_Samp_Fields = {
  __typename?: 'integration_usage_stats_view_var_samp_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  programs_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Integration_Usage_Stats_View_Variance_Fields = {
  __typename?: 'integration_usage_stats_view_variance_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  programs_count?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "integrations" */
export type Integrations = {
  __typename?: 'integrations';
  disabled: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  integration_name: Integration_Names;
  name: Integration_Names_Enum;
  /** An array relationship */
  organization_integrations: Array<Organization_Integrations>;
  /** An aggregate relationship */
  organization_integrations_aggregate: Organization_Integrations_Aggregate;
};

/** columns and relationships of "integrations" */
export type IntegrationsOrganization_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** columns and relationships of "integrations" */
export type IntegrationsOrganization_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** aggregated selection of "integrations" */
export type Integrations_Aggregate = {
  __typename?: 'integrations_aggregate';
  aggregate?: Maybe<Integrations_Aggregate_Fields>;
  nodes: Array<Integrations>;
};

/** aggregate fields of "integrations" */
export type Integrations_Aggregate_Fields = {
  __typename?: 'integrations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Integrations_Max_Fields>;
  min?: Maybe<Integrations_Min_Fields>;
};

/** aggregate fields of "integrations" */
export type Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "integrations". All fields are combined with a logical 'AND'. */
export type Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Integrations_Bool_Exp>>;
  _not?: InputMaybe<Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Integrations_Bool_Exp>>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration_name?: InputMaybe<Integration_Names_Bool_Exp>;
  name?: InputMaybe<Integration_Names_Enum_Comparison_Exp>;
  organization_integrations?: InputMaybe<Organization_Integrations_Bool_Exp>;
  organization_integrations_aggregate?: InputMaybe<Organization_Integrations_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "integrations" */
export enum Integrations_Constraint {
  /** unique or primary key constraint on columns "name" */
  IntegrationsNameKey = 'integrations_name_key',
  /** unique or primary key constraint on columns "id" */
  IntegrationsPkey = 'integrations_pkey',
}

/** input type for inserting data into table "integrations" */
export type Integrations_Insert_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_name?: InputMaybe<Integration_Names_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Integration_Names_Enum>;
  organization_integrations?: InputMaybe<Organization_Integrations_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Integrations_Max_Fields = {
  __typename?: 'integrations_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Integrations_Min_Fields = {
  __typename?: 'integrations_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "integrations" */
export type Integrations_Mutation_Response = {
  __typename?: 'integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Integrations>;
};

/** input type for inserting object relation for remote table "integrations" */
export type Integrations_Obj_Rel_Insert_Input = {
  data: Integrations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Integrations_On_Conflict>;
};

/** on_conflict condition type for table "integrations" */
export type Integrations_On_Conflict = {
  constraint: Integrations_Constraint;
  update_columns?: Array<Integrations_Update_Column>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "integrations". */
export type Integrations_Order_By = {
  disabled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_name?: InputMaybe<Integration_Names_Order_By>;
  name?: InputMaybe<Order_By>;
  organization_integrations_aggregate?: InputMaybe<Organization_Integrations_Aggregate_Order_By>;
};

/** primary key columns input for table: integrations */
export type Integrations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "integrations" */
export enum Integrations_Select_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "integrations" */
export type Integrations_Set_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Integration_Names_Enum>;
};

/** Streaming cursor of the table "integrations" */
export type Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integrations_Stream_Cursor_Value_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Integration_Names_Enum>;
};

/** update columns of table "integrations" */
export enum Integrations_Update_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type Integrations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Integrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Integrations_Bool_Exp;
};

/** columns and relationships of "invitations" */
export type Invitations = {
  __typename?: 'invitations';
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  is_joined: Scalars['Boolean']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  role: Roles;
  role_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "invitations" */
export type Invitations_Aggregate = {
  __typename?: 'invitations_aggregate';
  aggregate?: Maybe<Invitations_Aggregate_Fields>;
  nodes: Array<Invitations>;
};

export type Invitations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Invitations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Invitations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Invitations_Aggregate_Bool_Exp_Count>;
};

export type Invitations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invitations_Select_Column_Invitations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invitations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invitations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invitations_Select_Column_Invitations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invitations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invitations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Invitations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invitations" */
export type Invitations_Aggregate_Fields = {
  __typename?: 'invitations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Invitations_Max_Fields>;
  min?: Maybe<Invitations_Min_Fields>;
};

/** aggregate fields of "invitations" */
export type Invitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "invitations" */
export type Invitations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invitations_Max_Order_By>;
  min?: InputMaybe<Invitations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "invitations" */
export type Invitations_Arr_Rel_Insert_Input = {
  data: Array<Invitations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invitations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "invitations". All fields are combined with a logical 'AND'. */
export type Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<Invitations_Bool_Exp>>;
  _not?: InputMaybe<Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<Invitations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_joined?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invitations" */
export enum Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvitationsPkey = 'invitations_pkey',
}

/** input type for inserting data into table "invitations" */
export type Invitations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_joined?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Invitations_Max_Fields = {
  __typename?: 'invitations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "invitations" */
export type Invitations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invitations_Min_Fields = {
  __typename?: 'invitations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "invitations" */
export type Invitations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invitations" */
export type Invitations_Mutation_Response = {
  __typename?: 'invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitations>;
};

/** on_conflict condition type for table "invitations" */
export type Invitations_On_Conflict = {
  constraint: Invitations_Constraint;
  update_columns?: Array<Invitations_Update_Column>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "invitations". */
export type Invitations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_joined?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invitations */
export type Invitations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "invitations" */
export enum Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsJoined = 'is_joined',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "invitations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invitations" */
export enum Invitations_Select_Column_Invitations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsJoined = 'is_joined',
}

/** select "invitations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invitations" */
export enum Invitations_Select_Column_Invitations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsJoined = 'is_joined',
}

/** input type for updating data in table "invitations" */
export type Invitations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_joined?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "invitations" */
export type Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invitations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_joined?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "invitations" */
export enum Invitations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsJoined = 'is_joined',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Invitations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invitations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invitations_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Use AI to autocomplete an entity */
  ai_autocomplete_entity?: Maybe<AiAutocompleteEntityOutput>;
  /** Use AI to create a new entity */
  ai_create_entity?: Maybe<AiCreateEntityOutput>;
  /** Generate a sparse vector representation for the text */
  ai_generate_sparse_vector: SparseVectorOutput;
  /** This action triggers the control AI review for a single control */
  ai_trigger_control_review?: Maybe<ControlAiReviewOutput>;
  /** This action triggers the control AI review for all controls of a given organization. */
  ai_trigger_org_control_review?: Maybe<OrgControlAiReviewOutput>;
  /** This action triggers the Vendor Questionnaire AI review for a single questionnaire submission */
  ai_trigger_vendor_questionnaire_review?: Maybe<VendorQuestionnaireAiReviewOutput>;
  /** This action is used to change evidence owner */
  change_evidence_owner?: Maybe<GeneralActionOutput>;
  /** This action is used to create custom control */
  create_control?: Maybe<CreateControlOutput>;
  create_custom_program_request?: Maybe<CreateCustomProgramRequestOutput>;
  create_framework_request?: Maybe<FrameworkRequestOutput>;
  create_program?: Maybe<ProgramOutput>;
  /** delete single row from the table: "auth.providers" */
  deleteAuthProvider?: Maybe<AuthProviders>;
  /** delete single row from the table: "auth.provider_requests" */
  deleteAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** delete data from the table: "auth.provider_requests" */
  deleteAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** delete data from the table: "auth.providers" */
  deleteAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  deleteAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** delete single row from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** delete data from the table: "auth.refresh_token_types" */
  deleteAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** delete data from the table: "auth.refresh_tokens" */
  deleteAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** delete single row from the table: "auth.roles" */
  deleteAuthRole?: Maybe<AuthRoles>;
  /** delete data from the table: "auth.roles" */
  deleteAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** delete single row from the table: "auth.user_providers" */
  deleteAuthUserProvider?: Maybe<AuthUserProviders>;
  /** delete data from the table: "auth.user_providers" */
  deleteAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** delete single row from the table: "auth.user_roles" */
  deleteAuthUserRole?: Maybe<AuthUserRoles>;
  /** delete data from the table: "auth.user_roles" */
  deleteAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** delete single row from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** delete data from the table: "auth.user_security_keys" */
  deleteAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** delete single row from the table: "storage.buckets" */
  deleteBucket?: Maybe<Buckets>;
  /** delete data from the table: "storage.buckets" */
  deleteBuckets?: Maybe<Buckets_Mutation_Response>;
  /** delete single row from the table: "storage.files" */
  deleteFile?: Maybe<Files>;
  /** delete data from the table: "storage.files" */
  deleteFiles?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "auth.users" */
  deleteUser?: Maybe<Users>;
  /** delete data from the table: "auth.users" */
  deleteUsers?: Maybe<Users_Mutation_Response>;
  /** delete data from the table: "ai.controls_vector_store" */
  delete_ai_controls_vector_store?: Maybe<Ai_Controls_Vector_Store_Mutation_Response>;
  /** delete single row from the table: "ai.controls_vector_store" */
  delete_ai_controls_vector_store_by_pk?: Maybe<Ai_Controls_Vector_Store>;
  /** delete data from the table: "ai.evidences_vector_store" */
  delete_ai_evidences_vector_store?: Maybe<Ai_Evidences_Vector_Store_Mutation_Response>;
  /** delete single row from the table: "ai.evidences_vector_store" */
  delete_ai_evidences_vector_store_by_pk?: Maybe<Ai_Evidences_Vector_Store>;
  /** delete data from the table: "ai.files_document_store" */
  delete_ai_files_document_store?: Maybe<Ai_Files_Document_Store_Mutation_Response>;
  /** delete single row from the table: "ai.files_document_store" */
  delete_ai_files_document_store_by_pk?: Maybe<Ai_Files_Document_Store>;
  /** delete data from the table: "ai.files_vector_store" */
  delete_ai_files_vector_store?: Maybe<Ai_Files_Vector_Store_Mutation_Response>;
  /** delete single row from the table: "ai.files_vector_store" */
  delete_ai_files_vector_store_by_pk?: Maybe<Ai_Files_Vector_Store>;
  /** delete data from the table: "ai.policies_vector_store" */
  delete_ai_policies_vector_store?: Maybe<Ai_Policies_Vector_Store_Mutation_Response>;
  /** delete single row from the table: "ai.policies_vector_store" */
  delete_ai_policies_vector_store_by_pk?: Maybe<Ai_Policies_Vector_Store>;
  /** delete data from the table: "ai.programs_vector_store" */
  delete_ai_programs_vector_store?: Maybe<Ai_Programs_Vector_Store_Mutation_Response>;
  /** delete single row from the table: "ai.programs_vector_store" */
  delete_ai_programs_vector_store_by_pk?: Maybe<Ai_Programs_Vector_Store>;
  /** delete data from the table: "ai.risks_vector_store" */
  delete_ai_risks_vector_store?: Maybe<Ai_Risks_Vector_Store_Mutation_Response>;
  /** delete single row from the table: "ai.risks_vector_store" */
  delete_ai_risks_vector_store_by_pk?: Maybe<Ai_Risks_Vector_Store>;
  /** delete data from the table: "ai.tasks_vector_store" */
  delete_ai_tasks_vector_store?: Maybe<Ai_Tasks_Vector_Store_Mutation_Response>;
  /** delete single row from the table: "ai.tasks_vector_store" */
  delete_ai_tasks_vector_store_by_pk?: Maybe<Ai_Tasks_Vector_Store>;
  /** delete data from the table: "ai.vendors_vector_store" */
  delete_ai_vendors_vector_store?: Maybe<Ai_Vendors_Vector_Store_Mutation_Response>;
  /** delete single row from the table: "ai.vendors_vector_store" */
  delete_ai_vendors_vector_store_by_pk?: Maybe<Ai_Vendors_Vector_Store>;
  /** delete data from the table: "audit.logged_actions" */
  delete_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** delete single row from the table: "audit.logged_actions" */
  delete_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** delete data from the table: "audit.policy_history_view" */
  delete_audit_policy_history_view?: Maybe<Audit_Policy_History_View_Mutation_Response>;
  /** delete data from the table: "audit.risk_history_view" */
  delete_audit_risk_history_view?: Maybe<Audit_Risk_History_View_Mutation_Response>;
  /** delete data from the table: "audit.vendor_history_view" */
  delete_audit_vendor_history_view?: Maybe<Audit_Vendor_History_View_Mutation_Response>;
  /** delete data from the table: "auth.jackson_index" */
  delete_auth_jackson_index?: Maybe<Auth_Jackson_Index_Mutation_Response>;
  /** delete single row from the table: "auth.jackson_index" */
  delete_auth_jackson_index_by_pk?: Maybe<Auth_Jackson_Index>;
  /** delete data from the table: "auth.jackson_store" */
  delete_auth_jackson_store?: Maybe<Auth_Jackson_Store_Mutation_Response>;
  /** delete single row from the table: "auth.jackson_store" */
  delete_auth_jackson_store_by_pk?: Maybe<Auth_Jackson_Store>;
  /** delete data from the table: "auth.jackson_ttl" */
  delete_auth_jackson_ttl?: Maybe<Auth_Jackson_Ttl_Mutation_Response>;
  /** delete single row from the table: "auth.jackson_ttl" */
  delete_auth_jackson_ttl_by_pk?: Maybe<Auth_Jackson_Ttl>;
  /** delete data from the table: "auth.migrations" */
  delete_auth_migrations?: Maybe<Auth_Migrations_Mutation_Response>;
  /** delete single row from the table: "auth.migrations" */
  delete_auth_migrations_by_pk?: Maybe<Auth_Migrations>;
  /** delete data from the table: "categories" */
  delete_categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "categories" */
  delete_categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "client_questionnaire_answer_ai_sources" */
  delete_client_questionnaire_answer_ai_sources?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Mutation_Response>;
  /** delete single row from the table: "client_questionnaire_answer_ai_sources" */
  delete_client_questionnaire_answer_ai_sources_by_pk?: Maybe<Client_Questionnaire_Answer_Ai_Sources>;
  /** delete data from the table: "client_questionnaire_answers" */
  delete_client_questionnaire_answers?: Maybe<Client_Questionnaire_Answers_Mutation_Response>;
  /** delete single row from the table: "client_questionnaire_answers" */
  delete_client_questionnaire_answers_by_pk?: Maybe<Client_Questionnaire_Answers>;
  /** delete data from the table: "client_questionnaire_question_status" */
  delete_client_questionnaire_question_status?: Maybe<Client_Questionnaire_Question_Status_Mutation_Response>;
  /** delete single row from the table: "client_questionnaire_question_status" */
  delete_client_questionnaire_question_status_by_pk?: Maybe<Client_Questionnaire_Question_Status>;
  /** delete data from the table: "client_questionnaire_questions" */
  delete_client_questionnaire_questions?: Maybe<Client_Questionnaire_Questions_Mutation_Response>;
  /** delete single row from the table: "client_questionnaire_questions" */
  delete_client_questionnaire_questions_by_pk?: Maybe<Client_Questionnaire_Questions>;
  /** delete data from the table: "client_questionnaire_status" */
  delete_client_questionnaire_status?: Maybe<Client_Questionnaire_Status_Mutation_Response>;
  /** delete single row from the table: "client_questionnaire_status" */
  delete_client_questionnaire_status_by_pk?: Maybe<Client_Questionnaire_Status>;
  /** delete data from the table: "client_questionnaires" */
  delete_client_questionnaires?: Maybe<Client_Questionnaires_Mutation_Response>;
  /** delete single row from the table: "client_questionnaires" */
  delete_client_questionnaires_by_pk?: Maybe<Client_Questionnaires>;
  /** delete data from the table: "comment_uploads" */
  delete_comment_uploads?: Maybe<Comment_Uploads_Mutation_Response>;
  /** delete single row from the table: "comment_uploads" */
  delete_comment_uploads_by_pk?: Maybe<Comment_Uploads>;
  /** delete data from the table: "comments" */
  delete_comments?: Maybe<Comments_Mutation_Response>;
  /** delete single row from the table: "comments" */
  delete_comments_by_pk?: Maybe<Comments>;
  /** delete data from the table: "control_ai_review_assessment_sources" */
  delete_control_ai_review_assessment_sources?: Maybe<Control_Ai_Review_Assessment_Sources_Mutation_Response>;
  /** delete single row from the table: "control_ai_review_assessment_sources" */
  delete_control_ai_review_assessment_sources_by_pk?: Maybe<Control_Ai_Review_Assessment_Sources>;
  /** delete data from the table: "control_ai_review_assessments" */
  delete_control_ai_review_assessments?: Maybe<Control_Ai_Review_Assessments_Mutation_Response>;
  /** delete single row from the table: "control_ai_review_assessments" */
  delete_control_ai_review_assessments_by_pk?: Maybe<Control_Ai_Review_Assessments>;
  /** delete data from the table: "control_ai_review_criteria" */
  delete_control_ai_review_criteria?: Maybe<Control_Ai_Review_Criteria_Mutation_Response>;
  /** delete single row from the table: "control_ai_review_criteria" */
  delete_control_ai_review_criteria_by_pk?: Maybe<Control_Ai_Review_Criteria>;
  /** delete data from the table: "control_ai_review_runs" */
  delete_control_ai_review_runs?: Maybe<Control_Ai_Review_Runs_Mutation_Response>;
  /** delete single row from the table: "control_ai_review_runs" */
  delete_control_ai_review_runs_by_pk?: Maybe<Control_Ai_Review_Runs>;
  /** delete data from the table: "control_ai_review_status" */
  delete_control_ai_review_status?: Maybe<Control_Ai_Review_Status_Mutation_Response>;
  /** delete single row from the table: "control_ai_review_status" */
  delete_control_ai_review_status_by_pk?: Maybe<Control_Ai_Review_Status>;
  /** delete data from the table: "control_categories" */
  delete_control_categories?: Maybe<Control_Categories_Mutation_Response>;
  /** delete single row from the table: "control_categories" */
  delete_control_categories_by_pk?: Maybe<Control_Categories>;
  /** delete data from the table: "control_criteria" */
  delete_control_criteria?: Maybe<Control_Criteria_Mutation_Response>;
  /** delete single row from the table: "control_criteria" */
  delete_control_criteria_by_pk?: Maybe<Control_Criteria>;
  /** delete data from the table: "control_evidences" */
  delete_control_evidences?: Maybe<Control_Evidences_Mutation_Response>;
  /** delete single row from the table: "control_evidences" */
  delete_control_evidences_by_pk?: Maybe<Control_Evidences>;
  /** delete data from the table: "control_findings" */
  delete_control_findings?: Maybe<Control_Findings_Mutation_Response>;
  /** delete single row from the table: "control_findings" */
  delete_control_findings_by_pk?: Maybe<Control_Findings>;
  /** delete data from the table: "control_frequencies" */
  delete_control_frequencies?: Maybe<Control_Frequencies_Mutation_Response>;
  /** delete single row from the table: "control_frequencies" */
  delete_control_frequencies_by_pk?: Maybe<Control_Frequencies>;
  /** delete data from the table: "control_groups" */
  delete_control_groups?: Maybe<Control_Groups_Mutation_Response>;
  /** delete single row from the table: "control_groups" */
  delete_control_groups_by_pk?: Maybe<Control_Groups>;
  /** delete data from the table: "control_priorities" */
  delete_control_priorities?: Maybe<Control_Priorities_Mutation_Response>;
  /** delete single row from the table: "control_priorities" */
  delete_control_priorities_by_pk?: Maybe<Control_Priorities>;
  /** delete data from the table: "control_tags" */
  delete_control_tags?: Maybe<Control_Tags_Mutation_Response>;
  /** delete single row from the table: "control_tags" */
  delete_control_tags_by_pk?: Maybe<Control_Tags>;
  /** delete data from the table: "control_tasks" */
  delete_control_tasks?: Maybe<Control_Tasks_Mutation_Response>;
  /** delete single row from the table: "control_tasks" */
  delete_control_tasks_by_pk?: Maybe<Control_Tasks>;
  /** delete data from the table: "controls" */
  delete_controls?: Maybe<Controls_Mutation_Response>;
  /** delete single row from the table: "controls" */
  delete_controls_by_pk?: Maybe<Controls>;
  /** delete data from the table: "controls_history_stats" */
  delete_controls_history_stats?: Maybe<Controls_History_Stats_Mutation_Response>;
  /** delete single row from the table: "controls_history_stats" */
  delete_controls_history_stats_by_pk?: Maybe<Controls_History_Stats>;
  /** delete data from the table: "criteria" */
  delete_criteria?: Maybe<Criteria_Mutation_Response>;
  /** delete single row from the table: "criteria" */
  delete_criteria_by_pk?: Maybe<Criteria>;
  /** delete data from the table: "date_field_values" */
  delete_date_field_values?: Maybe<Date_Field_Values_Mutation_Response>;
  /** delete single row from the table: "date_field_values" */
  delete_date_field_values_by_pk?: Maybe<Date_Field_Values>;
  /** delete data from the table: "email_field_values" */
  delete_email_field_values?: Maybe<Email_Field_Values_Mutation_Response>;
  /** delete single row from the table: "email_field_values" */
  delete_email_field_values_by_pk?: Maybe<Email_Field_Values>;
  /** delete data from the table: "evidence_acl" */
  delete_evidence_acl?: Maybe<Evidence_Acl_Mutation_Response>;
  /** delete single row from the table: "evidence_acl" */
  delete_evidence_acl_by_pk?: Maybe<Evidence_Acl>;
  /** delete data from the table: "evidence_integrations" */
  delete_evidence_integrations?: Maybe<Evidence_Integrations_Mutation_Response>;
  /** delete single row from the table: "evidence_integrations" */
  delete_evidence_integrations_by_pk?: Maybe<Evidence_Integrations>;
  /** delete data from the table: "evidence_policies" */
  delete_evidence_policies?: Maybe<Evidence_Policies_Mutation_Response>;
  /** delete single row from the table: "evidence_policies" */
  delete_evidence_policies_by_pk?: Maybe<Evidence_Policies>;
  /** delete data from the table: "evidence_tags" */
  delete_evidence_tags?: Maybe<Evidence_Tags_Mutation_Response>;
  /** delete single row from the table: "evidence_tags" */
  delete_evidence_tags_by_pk?: Maybe<Evidence_Tags>;
  /** delete data from the table: "evidence_version_file" */
  delete_evidence_version_file?: Maybe<Evidence_Version_File_Mutation_Response>;
  /** delete single row from the table: "evidence_version_file" */
  delete_evidence_version_file_by_pk?: Maybe<Evidence_Version_File>;
  /** delete data from the table: "evidence_versions" */
  delete_evidence_versions?: Maybe<Evidence_Versions_Mutation_Response>;
  /** delete single row from the table: "evidence_versions" */
  delete_evidence_versions_by_pk?: Maybe<Evidence_Versions>;
  /** delete data from the table: "evidences" */
  delete_evidences?: Maybe<Evidences_Mutation_Response>;
  /** delete single row from the table: "evidences" */
  delete_evidences_by_pk?: Maybe<Evidences>;
  /** delete data from the table: "field_configs" */
  delete_field_configs?: Maybe<Field_Configs_Mutation_Response>;
  /** delete single row from the table: "field_configs" */
  delete_field_configs_by_pk?: Maybe<Field_Configs>;
  /** delete data from the table: "field_entities" */
  delete_field_entities?: Maybe<Field_Entities_Mutation_Response>;
  /** delete single row from the table: "field_entities" */
  delete_field_entities_by_pk?: Maybe<Field_Entities>;
  /** delete data from the table: "field_types" */
  delete_field_types?: Maybe<Field_Types_Mutation_Response>;
  /** delete single row from the table: "field_types" */
  delete_field_types_by_pk?: Maybe<Field_Types>;
  /** delete data from the table: "field_values" */
  delete_field_values?: Maybe<Field_Values_Mutation_Response>;
  /** delete single row from the table: "field_values" */
  delete_field_values_by_pk?: Maybe<Field_Values>;
  /** delete data from the table: "finding_causes" */
  delete_finding_causes?: Maybe<Finding_Causes_Mutation_Response>;
  /** delete single row from the table: "finding_causes" */
  delete_finding_causes_by_pk?: Maybe<Finding_Causes>;
  /** delete data from the table: "finding_types" */
  delete_finding_types?: Maybe<Finding_Types_Mutation_Response>;
  /** delete single row from the table: "finding_types" */
  delete_finding_types_by_pk?: Maybe<Finding_Types>;
  /** delete data from the table: "forms" */
  delete_forms?: Maybe<Forms_Mutation_Response>;
  /** delete single row from the table: "forms" */
  delete_forms_by_pk?: Maybe<Forms>;
  /** delete data from the table: "frameworks" */
  delete_frameworks?: Maybe<Frameworks_Mutation_Response>;
  /** delete single row from the table: "frameworks" */
  delete_frameworks_by_pk?: Maybe<Frameworks>;
  /** delete data from the table: "frameworks_controls" */
  delete_frameworks_controls?: Maybe<Frameworks_Controls_Mutation_Response>;
  /** delete single row from the table: "frameworks_controls" */
  delete_frameworks_controls_by_pk?: Maybe<Frameworks_Controls>;
  /** delete data from the table: "frequencies" */
  delete_frequencies?: Maybe<Frequencies_Mutation_Response>;
  /** delete single row from the table: "frequencies" */
  delete_frequencies_by_pk?: Maybe<Frequencies>;
  /** delete data from the table: "groups" */
  delete_groups?: Maybe<Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_groups_by_pk?: Maybe<Groups>;
  /** delete data from the table: "integration_names" */
  delete_integration_names?: Maybe<Integration_Names_Mutation_Response>;
  /** delete single row from the table: "integration_names" */
  delete_integration_names_by_pk?: Maybe<Integration_Names>;
  /** delete data from the table: "integration_runs" */
  delete_integration_runs?: Maybe<Integration_Runs_Mutation_Response>;
  /** delete single row from the table: "integration_runs" */
  delete_integration_runs_by_pk?: Maybe<Integration_Runs>;
  /** delete data from the table: "integrations" */
  delete_integrations?: Maybe<Integrations_Mutation_Response>;
  /** delete single row from the table: "integrations" */
  delete_integrations_by_pk?: Maybe<Integrations>;
  /** delete data from the table: "invitations" */
  delete_invitations?: Maybe<Invitations_Mutation_Response>;
  /** delete single row from the table: "invitations" */
  delete_invitations_by_pk?: Maybe<Invitations>;
  /** delete data from the table: "notification_delivery_types" */
  delete_notification_delivery_types?: Maybe<Notification_Delivery_Types_Mutation_Response>;
  /** delete single row from the table: "notification_delivery_types" */
  delete_notification_delivery_types_by_pk?: Maybe<Notification_Delivery_Types>;
  /** delete data from the table: "notification_disabled_settings" */
  delete_notification_disabled_settings?: Maybe<Notification_Disabled_Settings_Mutation_Response>;
  /** delete single row from the table: "notification_disabled_settings" */
  delete_notification_disabled_settings_by_pk?: Maybe<Notification_Disabled_Settings>;
  /** delete data from the table: "notification_types" */
  delete_notification_types?: Maybe<Notification_Types_Mutation_Response>;
  /** delete single row from the table: "notification_types" */
  delete_notification_types_by_pk?: Maybe<Notification_Types>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "number_field_values" */
  delete_number_field_values?: Maybe<Number_Field_Values_Mutation_Response>;
  /** delete single row from the table: "number_field_values" */
  delete_number_field_values_by_pk?: Maybe<Number_Field_Values>;
  /** delete data from the table: "organization_evidence_tags" */
  delete_organization_evidence_tags?: Maybe<Organization_Evidence_Tags_Mutation_Response>;
  /** delete single row from the table: "organization_evidence_tags" */
  delete_organization_evidence_tags_by_pk?: Maybe<Organization_Evidence_Tags>;
  /** delete data from the table: "organization_integrations" */
  delete_organization_integrations?: Maybe<Organization_Integrations_Mutation_Response>;
  /** delete single row from the table: "organization_integrations" */
  delete_organization_integrations_by_pk?: Maybe<Organization_Integrations>;
  /** delete data from the table: "organization_risk_categories" */
  delete_organization_risk_categories?: Maybe<Organization_Risk_Categories_Mutation_Response>;
  /** delete single row from the table: "organization_risk_categories" */
  delete_organization_risk_categories_by_pk?: Maybe<Organization_Risk_Categories>;
  /** delete data from the table: "organization_risk_tags" */
  delete_organization_risk_tags?: Maybe<Organization_Risk_Tags_Mutation_Response>;
  /** delete single row from the table: "organization_risk_tags" */
  delete_organization_risk_tags_by_pk?: Maybe<Organization_Risk_Tags>;
  /** delete data from the table: "organization_template_frameworks" */
  delete_organization_template_frameworks?: Maybe<Organization_Template_Frameworks_Mutation_Response>;
  /** delete single row from the table: "organization_template_frameworks" */
  delete_organization_template_frameworks_by_pk?: Maybe<Organization_Template_Frameworks>;
  /** delete data from the table: "organization_tenants" */
  delete_organization_tenants?: Maybe<Organization_Tenants_Mutation_Response>;
  /** delete single row from the table: "organization_tenants" */
  delete_organization_tenants_by_pk?: Maybe<Organization_Tenants>;
  /** delete data from the table: "organization_users" */
  delete_organization_users?: Maybe<Organization_Users_Mutation_Response>;
  /** delete single row from the table: "organization_users" */
  delete_organization_users_by_pk?: Maybe<Organization_Users>;
  /** delete data from the table: "organization_vendor_risk_levels" */
  delete_organization_vendor_risk_levels?: Maybe<Organization_Vendor_Risk_Levels_Mutation_Response>;
  /** delete single row from the table: "organization_vendor_risk_levels" */
  delete_organization_vendor_risk_levels_by_pk?: Maybe<Organization_Vendor_Risk_Levels>;
  /** delete data from the table: "organization_vendor_tiers" */
  delete_organization_vendor_tiers?: Maybe<Organization_Vendor_Tiers_Mutation_Response>;
  /** delete single row from the table: "organization_vendor_tiers" */
  delete_organization_vendor_tiers_by_pk?: Maybe<Organization_Vendor_Tiers>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "permissions" */
  delete_permissions?: Maybe<Permissions_Mutation_Response>;
  /** delete single row from the table: "permissions" */
  delete_permissions_by_pk?: Maybe<Permissions>;
  /** delete data from the table: "policies" */
  delete_policies?: Maybe<Policies_Mutation_Response>;
  /** delete single row from the table: "policies" */
  delete_policies_by_pk?: Maybe<Policies>;
  /** delete data from the table: "policy_acknowledgement_statuses" */
  delete_policy_acknowledgement_statuses?: Maybe<Policy_Acknowledgement_Statuses_Mutation_Response>;
  /** delete single row from the table: "policy_acknowledgement_statuses" */
  delete_policy_acknowledgement_statuses_by_pk?: Maybe<Policy_Acknowledgement_Statuses>;
  /** delete data from the table: "policy_acknowledgement_users" */
  delete_policy_acknowledgement_users?: Maybe<Policy_Acknowledgement_Users_Mutation_Response>;
  /** delete single row from the table: "policy_acknowledgement_users" */
  delete_policy_acknowledgement_users_by_pk?: Maybe<Policy_Acknowledgement_Users>;
  /** delete data from the table: "policy_acknowledgements" */
  delete_policy_acknowledgements?: Maybe<Policy_Acknowledgements_Mutation_Response>;
  /** delete single row from the table: "policy_acknowledgements" */
  delete_policy_acknowledgements_by_pk?: Maybe<Policy_Acknowledgements>;
  /** delete data from the table: "policy_approval_statuses" */
  delete_policy_approval_statuses?: Maybe<Policy_Approval_Statuses_Mutation_Response>;
  /** delete single row from the table: "policy_approval_statuses" */
  delete_policy_approval_statuses_by_pk?: Maybe<Policy_Approval_Statuses>;
  /** delete data from the table: "policy_approval_users" */
  delete_policy_approval_users?: Maybe<Policy_Approval_Users_Mutation_Response>;
  /** delete single row from the table: "policy_approval_users" */
  delete_policy_approval_users_by_pk?: Maybe<Policy_Approval_Users>;
  /** delete data from the table: "policy_approvals" */
  delete_policy_approvals?: Maybe<Policy_Approvals_Mutation_Response>;
  /** delete single row from the table: "policy_approvals" */
  delete_policy_approvals_by_pk?: Maybe<Policy_Approvals>;
  /** delete data from the table: "policy_approvers" */
  delete_policy_approvers?: Maybe<Policy_Approvers_Mutation_Response>;
  /** delete single row from the table: "policy_approvers" */
  delete_policy_approvers_by_pk?: Maybe<Policy_Approvers>;
  /** delete data from the table: "policy_statuses" */
  delete_policy_statuses?: Maybe<Policy_Statuses_Mutation_Response>;
  /** delete single row from the table: "policy_statuses" */
  delete_policy_statuses_by_pk?: Maybe<Policy_Statuses>;
  /** delete data from the table: "policy_tasks" */
  delete_policy_tasks?: Maybe<Policy_Tasks_Mutation_Response>;
  /** delete single row from the table: "policy_tasks" */
  delete_policy_tasks_by_pk?: Maybe<Policy_Tasks>;
  /** delete data from the table: "policy_types" */
  delete_policy_types?: Maybe<Policy_Types_Mutation_Response>;
  /** delete single row from the table: "policy_types" */
  delete_policy_types_by_pk?: Maybe<Policy_Types>;
  /** delete data from the table: "policy_version_statuses" */
  delete_policy_version_statuses?: Maybe<Policy_Version_Statuses_Mutation_Response>;
  /** delete single row from the table: "policy_version_statuses" */
  delete_policy_version_statuses_by_pk?: Maybe<Policy_Version_Statuses>;
  /** delete data from the table: "policy_versions" */
  delete_policy_versions?: Maybe<Policy_Versions_Mutation_Response>;
  /** delete single row from the table: "policy_versions" */
  delete_policy_versions_by_pk?: Maybe<Policy_Versions>;
  delete_program_by_id?: Maybe<GeneralActionOutput>;
  /** delete data from the table: "program_controls" */
  delete_program_controls?: Maybe<Program_Controls_Mutation_Response>;
  /** delete single row from the table: "program_controls" */
  delete_program_controls_by_pk?: Maybe<Program_Controls>;
  /** delete data from the table: "program_field_configs" */
  delete_program_field_configs?: Maybe<Program_Field_Configs_Mutation_Response>;
  /** delete single row from the table: "program_field_configs" */
  delete_program_field_configs_by_pk?: Maybe<Program_Field_Configs>;
  /** delete data from the table: "programs" */
  delete_programs?: Maybe<Programs_Mutation_Response>;
  /** delete single row from the table: "programs" */
  delete_programs_by_pk?: Maybe<Programs>;
  /** delete data from the table: "questionnaire_uploads" */
  delete_questionnaire_uploads?: Maybe<Questionnaire_Uploads_Mutation_Response>;
  /** delete single row from the table: "questionnaire_uploads" */
  delete_questionnaire_uploads_by_pk?: Maybe<Questionnaire_Uploads>;
  /** delete data from the table: "questionnaires" */
  delete_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** delete single row from the table: "questionnaires" */
  delete_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** delete data from the table: "report_types" */
  delete_report_types?: Maybe<Report_Types_Mutation_Response>;
  /** delete single row from the table: "report_types" */
  delete_report_types_by_pk?: Maybe<Report_Types>;
  /** delete data from the table: "reports" */
  delete_reports?: Maybe<Reports_Mutation_Response>;
  /** delete single row from the table: "reports" */
  delete_reports_by_pk?: Maybe<Reports>;
  /** delete data from the table: "review_files" */
  delete_review_files?: Maybe<Review_Files_Mutation_Response>;
  /** delete single row from the table: "review_files" */
  delete_review_files_by_pk?: Maybe<Review_Files>;
  /** delete data from the table: "reviews" */
  delete_reviews?: Maybe<Reviews_Mutation_Response>;
  /** delete single row from the table: "reviews" */
  delete_reviews_by_pk?: Maybe<Reviews>;
  /** delete data from the table: "risk_categories" */
  delete_risk_categories?: Maybe<Risk_Categories_Mutation_Response>;
  /** delete single row from the table: "risk_categories" */
  delete_risk_categories_by_pk?: Maybe<Risk_Categories>;
  /** delete data from the table: "risk_controls" */
  delete_risk_controls?: Maybe<Risk_Controls_Mutation_Response>;
  /** delete single row from the table: "risk_controls" */
  delete_risk_controls_by_pk?: Maybe<Risk_Controls>;
  /** delete data from the table: "risk_documents" */
  delete_risk_documents?: Maybe<Risk_Documents_Mutation_Response>;
  /** delete single row from the table: "risk_documents" */
  delete_risk_documents_by_pk?: Maybe<Risk_Documents>;
  /** delete data from the table: "risk_impacts" */
  delete_risk_impacts?: Maybe<Risk_Impacts_Mutation_Response>;
  /** delete single row from the table: "risk_impacts" */
  delete_risk_impacts_by_pk?: Maybe<Risk_Impacts>;
  /** delete data from the table: "risk_inherent_levels" */
  delete_risk_inherent_levels?: Maybe<Risk_Inherent_Levels_Mutation_Response>;
  /** delete single row from the table: "risk_inherent_levels" */
  delete_risk_inherent_levels_by_pk?: Maybe<Risk_Inherent_Levels>;
  /** delete data from the table: "risk_levels" */
  delete_risk_levels?: Maybe<Risk_Levels_Mutation_Response>;
  /** delete single row from the table: "risk_levels" */
  delete_risk_levels_by_pk?: Maybe<Risk_Levels>;
  /** delete data from the table: "risk_likelihoods" */
  delete_risk_likelihoods?: Maybe<Risk_Likelihoods_Mutation_Response>;
  /** delete single row from the table: "risk_likelihoods" */
  delete_risk_likelihoods_by_pk?: Maybe<Risk_Likelihoods>;
  /** delete data from the table: "risk_residual_levels" */
  delete_risk_residual_levels?: Maybe<Risk_Residual_Levels_Mutation_Response>;
  /** delete single row from the table: "risk_residual_levels" */
  delete_risk_residual_levels_by_pk?: Maybe<Risk_Residual_Levels>;
  /** delete data from the table: "risk_tags" */
  delete_risk_tags?: Maybe<Risk_Tags_Mutation_Response>;
  /** delete single row from the table: "risk_tags" */
  delete_risk_tags_by_pk?: Maybe<Risk_Tags>;
  /** delete data from the table: "risk_tasks" */
  delete_risk_tasks?: Maybe<Risk_Tasks_Mutation_Response>;
  /** delete single row from the table: "risk_tasks" */
  delete_risk_tasks_by_pk?: Maybe<Risk_Tasks>;
  /** delete data from the table: "risks" */
  delete_risks?: Maybe<Risks_Mutation_Response>;
  /** delete single row from the table: "risks" */
  delete_risks_by_pk?: Maybe<Risks>;
  /** delete data from the table: "role_permissions" */
  delete_role_permissions?: Maybe<Role_Permissions_Mutation_Response>;
  /** delete single row from the table: "role_permissions" */
  delete_role_permissions_by_pk?: Maybe<Role_Permissions>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "select_field_configs" */
  delete_select_field_configs?: Maybe<Select_Field_Configs_Mutation_Response>;
  /** delete single row from the table: "select_field_configs" */
  delete_select_field_configs_by_pk?: Maybe<Select_Field_Configs>;
  /** delete data from the table: "select_field_options" */
  delete_select_field_options?: Maybe<Select_Field_Options_Mutation_Response>;
  /** delete single row from the table: "select_field_options" */
  delete_select_field_options_by_pk?: Maybe<Select_Field_Options>;
  /** delete data from the table: "select_field_values" */
  delete_select_field_values?: Maybe<Select_Field_Values_Mutation_Response>;
  /** delete single row from the table: "select_field_values" */
  delete_select_field_values_by_pk?: Maybe<Select_Field_Values>;
  /** delete data from the table: "system_roles" */
  delete_system_roles?: Maybe<System_Roles_Mutation_Response>;
  /** delete single row from the table: "system_roles" */
  delete_system_roles_by_pk?: Maybe<System_Roles>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "task_owners" */
  delete_task_owners?: Maybe<Task_Owners_Mutation_Response>;
  /** delete single row from the table: "task_owners" */
  delete_task_owners_by_pk?: Maybe<Task_Owners>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "template_control_ai_review_criteria" */
  delete_template_control_ai_review_criteria?: Maybe<Template_Control_Ai_Review_Criteria_Mutation_Response>;
  /** delete single row from the table: "template_control_ai_review_criteria" */
  delete_template_control_ai_review_criteria_by_pk?: Maybe<Template_Control_Ai_Review_Criteria>;
  /** delete data from the table: "template_controls" */
  delete_template_controls?: Maybe<Template_Controls_Mutation_Response>;
  /** delete single row from the table: "template_controls" */
  delete_template_controls_by_pk?: Maybe<Template_Controls>;
  /** delete data from the table: "template_frameworks" */
  delete_template_frameworks?: Maybe<Template_Frameworks_Mutation_Response>;
  /** delete single row from the table: "template_frameworks" */
  delete_template_frameworks_by_pk?: Maybe<Template_Frameworks>;
  /** delete data from the table: "template_frameworks_controls" */
  delete_template_frameworks_controls?: Maybe<Template_Frameworks_Controls_Mutation_Response>;
  /** delete single row from the table: "template_frameworks_controls" */
  delete_template_frameworks_controls_by_pk?: Maybe<Template_Frameworks_Controls>;
  /** delete data from the table: "template_tasks" */
  delete_template_tasks?: Maybe<Template_Tasks_Mutation_Response>;
  /** delete single row from the table: "template_tasks" */
  delete_template_tasks_by_pk?: Maybe<Template_Tasks>;
  /** delete data from the table: "text_field_values" */
  delete_text_field_values?: Maybe<Text_Field_Values_Mutation_Response>;
  /** delete single row from the table: "text_field_values" */
  delete_text_field_values_by_pk?: Maybe<Text_Field_Values>;
  /** delete data from the table: "treatment_plan" */
  delete_treatment_plan?: Maybe<Treatment_Plan_Mutation_Response>;
  /** delete single row from the table: "treatment_plan" */
  delete_treatment_plan_by_pk?: Maybe<Treatment_Plan>;
  /** delete data from the table: "url_field_values" */
  delete_url_field_values?: Maybe<Url_Field_Values_Mutation_Response>;
  /** delete single row from the table: "url_field_values" */
  delete_url_field_values_by_pk?: Maybe<Url_Field_Values>;
  /** delete data from the table: "user_notifications" */
  delete_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** delete single row from the table: "user_notifications" */
  delete_user_notifications_by_pk?: Maybe<User_Notifications>;
  /** delete data from the table: "vendor_approval_statuses" */
  delete_vendor_approval_statuses?: Maybe<Vendor_Approval_Statuses_Mutation_Response>;
  /** delete single row from the table: "vendor_approval_statuses" */
  delete_vendor_approval_statuses_by_pk?: Maybe<Vendor_Approval_Statuses>;
  /** delete data from the table: "vendor_documents" */
  delete_vendor_documents?: Maybe<Vendor_Documents_Mutation_Response>;
  /** delete single row from the table: "vendor_documents" */
  delete_vendor_documents_by_pk?: Maybe<Vendor_Documents>;
  /** delete data from the table: "vendor_findings" */
  delete_vendor_findings?: Maybe<Vendor_Findings_Mutation_Response>;
  /** delete single row from the table: "vendor_findings" */
  delete_vendor_findings_by_pk?: Maybe<Vendor_Findings>;
  /** delete data from the table: "vendor_questionnaire_ai_review_assessment_sources" */
  delete_vendor_questionnaire_ai_review_assessment_sources?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_ai_review_assessment_sources" */
  delete_vendor_questionnaire_ai_review_assessment_sources_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** delete data from the table: "vendor_questionnaire_ai_review_assessments" */
  delete_vendor_questionnaire_ai_review_assessments?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_ai_review_assessments" */
  delete_vendor_questionnaire_ai_review_assessments_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** delete data from the table: "vendor_questionnaire_ai_review_run_status" */
  delete_vendor_questionnaire_ai_review_run_status?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_ai_review_run_status" */
  delete_vendor_questionnaire_ai_review_run_status_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status>;
  /** delete data from the table: "vendor_questionnaire_ai_review_runs" */
  delete_vendor_questionnaire_ai_review_runs?: Maybe<Vendor_Questionnaire_Ai_Review_Runs_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_ai_review_runs" */
  delete_vendor_questionnaire_ai_review_runs_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Runs>;
  /** delete data from the table: "vendor_questionnaire_form_answer_statuses" */
  delete_vendor_questionnaire_form_answer_statuses?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_form_answer_statuses" */
  delete_vendor_questionnaire_form_answer_statuses_by_pk?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** delete data from the table: "vendor_questionnaire_form_answers" */
  delete_vendor_questionnaire_form_answers?: Maybe<Vendor_Questionnaire_Form_Answers_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_form_answers" */
  delete_vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** delete data from the table: "vendor_questionnaire_form_uploads" */
  delete_vendor_questionnaire_form_uploads?: Maybe<Vendor_Questionnaire_Form_Uploads_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_form_uploads" */
  delete_vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** delete data from the table: "vendor_questionnaire_forms" */
  delete_vendor_questionnaire_forms?: Maybe<Vendor_Questionnaire_Forms_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_forms" */
  delete_vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** delete data from the table: "vendor_questionnaire_statuses" */
  delete_vendor_questionnaire_statuses?: Maybe<Vendor_Questionnaire_Statuses_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_statuses" */
  delete_vendor_questionnaire_statuses_by_pk?: Maybe<Vendor_Questionnaire_Statuses>;
  /** delete data from the table: "vendor_questionnaire_uploads" */
  delete_vendor_questionnaire_uploads?: Maybe<Vendor_Questionnaire_Uploads_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_uploads" */
  delete_vendor_questionnaire_uploads_by_pk?: Maybe<Vendor_Questionnaire_Uploads>;
  /** delete data from the table: "vendor_questionnaires" */
  delete_vendor_questionnaires?: Maybe<Vendor_Questionnaires_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaires" */
  delete_vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** delete data from the table: "vendor_risks" */
  delete_vendor_risks?: Maybe<Vendor_Risks_Mutation_Response>;
  /** delete single row from the table: "vendor_risks" */
  delete_vendor_risks_by_pk?: Maybe<Vendor_Risks>;
  /** delete data from the table: "vendor_tasks" */
  delete_vendor_tasks?: Maybe<Vendor_Tasks_Mutation_Response>;
  /** delete single row from the table: "vendor_tasks" */
  delete_vendor_tasks_by_pk?: Maybe<Vendor_Tasks>;
  /** delete data from the table: "vendors" */
  delete_vendors?: Maybe<Vendors_Mutation_Response>;
  /** delete single row from the table: "vendors" */
  delete_vendors_by_pk?: Maybe<Vendors>;
  /** action to duplicate a control */
  duplicate_control?: Maybe<CreateControlOutput>;
  export_policy?: Maybe<ExportPolicyOutput>;
  gen_integration_run_report?: Maybe<GenIntegrationRunReportOutput>;
  /** This action is used to generate admin link to manage audit trail in workos */
  generate_workos_admin_link?: Maybe<GenerateWorkosAdminLinkOutput>;
  grant_evidence_access?: Maybe<GrantEvidenceAccessOutput>;
  /** This action is used to impersonate user */
  impersonate_user?: Maybe<ImpersonateUserOutput>;
  /** This action is used to import policies from csv */
  import_policies: Scalars['uuid']['output'];
  /** This action is used to import programs from csv */
  import_program: Scalars['uuid']['output'];
  /** This action is used to import risks from csv */
  import_risks: Scalars['uuid']['output'];
  /** This action is used to import vendors from csv */
  import_vendors: Scalars['uuid']['output'];
  /** insert a single row into the table: "auth.providers" */
  insertAuthProvider?: Maybe<AuthProviders>;
  /** insert a single row into the table: "auth.provider_requests" */
  insertAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** insert data into the table: "auth.provider_requests" */
  insertAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** insert data into the table: "auth.providers" */
  insertAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insertAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** insert a single row into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** insert data into the table: "auth.refresh_token_types" */
  insertAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** insert data into the table: "auth.refresh_tokens" */
  insertAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** insert a single row into the table: "auth.roles" */
  insertAuthRole?: Maybe<AuthRoles>;
  /** insert data into the table: "auth.roles" */
  insertAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** insert a single row into the table: "auth.user_providers" */
  insertAuthUserProvider?: Maybe<AuthUserProviders>;
  /** insert data into the table: "auth.user_providers" */
  insertAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** insert a single row into the table: "auth.user_roles" */
  insertAuthUserRole?: Maybe<AuthUserRoles>;
  /** insert data into the table: "auth.user_roles" */
  insertAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** insert a single row into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** insert data into the table: "auth.user_security_keys" */
  insertAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** insert a single row into the table: "storage.buckets" */
  insertBucket?: Maybe<Buckets>;
  /** insert data into the table: "storage.buckets" */
  insertBuckets?: Maybe<Buckets_Mutation_Response>;
  /** insert a single row into the table: "storage.files" */
  insertFile?: Maybe<Files>;
  /** insert data into the table: "storage.files" */
  insertFiles?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "auth.users" */
  insertUser?: Maybe<Users>;
  /** insert data into the table: "auth.users" */
  insertUsers?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "ai.controls_vector_store" */
  insert_ai_controls_vector_store?: Maybe<Ai_Controls_Vector_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.controls_vector_store" */
  insert_ai_controls_vector_store_one?: Maybe<Ai_Controls_Vector_Store>;
  /** insert data into the table: "ai.evidences_vector_store" */
  insert_ai_evidences_vector_store?: Maybe<Ai_Evidences_Vector_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.evidences_vector_store" */
  insert_ai_evidences_vector_store_one?: Maybe<Ai_Evidences_Vector_Store>;
  /** insert data into the table: "ai.files_document_store" */
  insert_ai_files_document_store?: Maybe<Ai_Files_Document_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.files_document_store" */
  insert_ai_files_document_store_one?: Maybe<Ai_Files_Document_Store>;
  /** insert data into the table: "ai.files_vector_store" */
  insert_ai_files_vector_store?: Maybe<Ai_Files_Vector_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.files_vector_store" */
  insert_ai_files_vector_store_one?: Maybe<Ai_Files_Vector_Store>;
  /** insert data into the table: "ai.policies_vector_store" */
  insert_ai_policies_vector_store?: Maybe<Ai_Policies_Vector_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.policies_vector_store" */
  insert_ai_policies_vector_store_one?: Maybe<Ai_Policies_Vector_Store>;
  /** insert data into the table: "ai.programs_vector_store" */
  insert_ai_programs_vector_store?: Maybe<Ai_Programs_Vector_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.programs_vector_store" */
  insert_ai_programs_vector_store_one?: Maybe<Ai_Programs_Vector_Store>;
  /** insert data into the table: "ai.risks_vector_store" */
  insert_ai_risks_vector_store?: Maybe<Ai_Risks_Vector_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.risks_vector_store" */
  insert_ai_risks_vector_store_one?: Maybe<Ai_Risks_Vector_Store>;
  /** insert data into the table: "ai.tasks_vector_store" */
  insert_ai_tasks_vector_store?: Maybe<Ai_Tasks_Vector_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.tasks_vector_store" */
  insert_ai_tasks_vector_store_one?: Maybe<Ai_Tasks_Vector_Store>;
  /** insert data into the table: "ai.vendors_vector_store" */
  insert_ai_vendors_vector_store?: Maybe<Ai_Vendors_Vector_Store_Mutation_Response>;
  /** insert a single row into the table: "ai.vendors_vector_store" */
  insert_ai_vendors_vector_store_one?: Maybe<Ai_Vendors_Vector_Store>;
  /** insert data into the table: "audit.logged_actions" */
  insert_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** insert a single row into the table: "audit.logged_actions" */
  insert_audit_logged_actions_one?: Maybe<Audit_Logged_Actions>;
  /** insert data into the table: "audit.policy_history_view" */
  insert_audit_policy_history_view?: Maybe<Audit_Policy_History_View_Mutation_Response>;
  /** insert a single row into the table: "audit.policy_history_view" */
  insert_audit_policy_history_view_one?: Maybe<Audit_Policy_History_View>;
  /** insert data into the table: "audit.risk_history_view" */
  insert_audit_risk_history_view?: Maybe<Audit_Risk_History_View_Mutation_Response>;
  /** insert a single row into the table: "audit.risk_history_view" */
  insert_audit_risk_history_view_one?: Maybe<Audit_Risk_History_View>;
  /** insert data into the table: "audit.vendor_history_view" */
  insert_audit_vendor_history_view?: Maybe<Audit_Vendor_History_View_Mutation_Response>;
  /** insert a single row into the table: "audit.vendor_history_view" */
  insert_audit_vendor_history_view_one?: Maybe<Audit_Vendor_History_View>;
  /** insert data into the table: "auth.jackson_index" */
  insert_auth_jackson_index?: Maybe<Auth_Jackson_Index_Mutation_Response>;
  /** insert a single row into the table: "auth.jackson_index" */
  insert_auth_jackson_index_one?: Maybe<Auth_Jackson_Index>;
  /** insert data into the table: "auth.jackson_store" */
  insert_auth_jackson_store?: Maybe<Auth_Jackson_Store_Mutation_Response>;
  /** insert a single row into the table: "auth.jackson_store" */
  insert_auth_jackson_store_one?: Maybe<Auth_Jackson_Store>;
  /** insert data into the table: "auth.jackson_ttl" */
  insert_auth_jackson_ttl?: Maybe<Auth_Jackson_Ttl_Mutation_Response>;
  /** insert a single row into the table: "auth.jackson_ttl" */
  insert_auth_jackson_ttl_one?: Maybe<Auth_Jackson_Ttl>;
  /** insert data into the table: "auth.migrations" */
  insert_auth_migrations?: Maybe<Auth_Migrations_Mutation_Response>;
  /** insert a single row into the table: "auth.migrations" */
  insert_auth_migrations_one?: Maybe<Auth_Migrations>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "client_questionnaire_answer_ai_sources" */
  insert_client_questionnaire_answer_ai_sources?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Mutation_Response>;
  /** insert a single row into the table: "client_questionnaire_answer_ai_sources" */
  insert_client_questionnaire_answer_ai_sources_one?: Maybe<Client_Questionnaire_Answer_Ai_Sources>;
  /** insert data into the table: "client_questionnaire_answers" */
  insert_client_questionnaire_answers?: Maybe<Client_Questionnaire_Answers_Mutation_Response>;
  /** insert a single row into the table: "client_questionnaire_answers" */
  insert_client_questionnaire_answers_one?: Maybe<Client_Questionnaire_Answers>;
  /** insert data into the table: "client_questionnaire_question_status" */
  insert_client_questionnaire_question_status?: Maybe<Client_Questionnaire_Question_Status_Mutation_Response>;
  /** insert a single row into the table: "client_questionnaire_question_status" */
  insert_client_questionnaire_question_status_one?: Maybe<Client_Questionnaire_Question_Status>;
  /** insert data into the table: "client_questionnaire_questions" */
  insert_client_questionnaire_questions?: Maybe<Client_Questionnaire_Questions_Mutation_Response>;
  /** insert a single row into the table: "client_questionnaire_questions" */
  insert_client_questionnaire_questions_one?: Maybe<Client_Questionnaire_Questions>;
  /** insert data into the table: "client_questionnaire_status" */
  insert_client_questionnaire_status?: Maybe<Client_Questionnaire_Status_Mutation_Response>;
  /** insert a single row into the table: "client_questionnaire_status" */
  insert_client_questionnaire_status_one?: Maybe<Client_Questionnaire_Status>;
  /** insert data into the table: "client_questionnaires" */
  insert_client_questionnaires?: Maybe<Client_Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "client_questionnaires" */
  insert_client_questionnaires_one?: Maybe<Client_Questionnaires>;
  /** insert data into the table: "comment_uploads" */
  insert_comment_uploads?: Maybe<Comment_Uploads_Mutation_Response>;
  /** insert a single row into the table: "comment_uploads" */
  insert_comment_uploads_one?: Maybe<Comment_Uploads>;
  /** insert data into the table: "comments" */
  insert_comments?: Maybe<Comments_Mutation_Response>;
  /** insert a single row into the table: "comments" */
  insert_comments_one?: Maybe<Comments>;
  /** insert data into the table: "control_ai_review_assessment_sources" */
  insert_control_ai_review_assessment_sources?: Maybe<Control_Ai_Review_Assessment_Sources_Mutation_Response>;
  /** insert a single row into the table: "control_ai_review_assessment_sources" */
  insert_control_ai_review_assessment_sources_one?: Maybe<Control_Ai_Review_Assessment_Sources>;
  /** insert data into the table: "control_ai_review_assessments" */
  insert_control_ai_review_assessments?: Maybe<Control_Ai_Review_Assessments_Mutation_Response>;
  /** insert a single row into the table: "control_ai_review_assessments" */
  insert_control_ai_review_assessments_one?: Maybe<Control_Ai_Review_Assessments>;
  /** insert data into the table: "control_ai_review_criteria" */
  insert_control_ai_review_criteria?: Maybe<Control_Ai_Review_Criteria_Mutation_Response>;
  /** insert a single row into the table: "control_ai_review_criteria" */
  insert_control_ai_review_criteria_one?: Maybe<Control_Ai_Review_Criteria>;
  /** insert data into the table: "control_ai_review_runs" */
  insert_control_ai_review_runs?: Maybe<Control_Ai_Review_Runs_Mutation_Response>;
  /** insert a single row into the table: "control_ai_review_runs" */
  insert_control_ai_review_runs_one?: Maybe<Control_Ai_Review_Runs>;
  /** insert data into the table: "control_ai_review_status" */
  insert_control_ai_review_status?: Maybe<Control_Ai_Review_Status_Mutation_Response>;
  /** insert a single row into the table: "control_ai_review_status" */
  insert_control_ai_review_status_one?: Maybe<Control_Ai_Review_Status>;
  /** insert data into the table: "control_categories" */
  insert_control_categories?: Maybe<Control_Categories_Mutation_Response>;
  /** insert a single row into the table: "control_categories" */
  insert_control_categories_one?: Maybe<Control_Categories>;
  /** insert data into the table: "control_criteria" */
  insert_control_criteria?: Maybe<Control_Criteria_Mutation_Response>;
  /** insert a single row into the table: "control_criteria" */
  insert_control_criteria_one?: Maybe<Control_Criteria>;
  /** insert data into the table: "control_evidences" */
  insert_control_evidences?: Maybe<Control_Evidences_Mutation_Response>;
  /** insert a single row into the table: "control_evidences" */
  insert_control_evidences_one?: Maybe<Control_Evidences>;
  /** insert data into the table: "control_findings" */
  insert_control_findings?: Maybe<Control_Findings_Mutation_Response>;
  /** insert a single row into the table: "control_findings" */
  insert_control_findings_one?: Maybe<Control_Findings>;
  /** insert data into the table: "control_frequencies" */
  insert_control_frequencies?: Maybe<Control_Frequencies_Mutation_Response>;
  /** insert a single row into the table: "control_frequencies" */
  insert_control_frequencies_one?: Maybe<Control_Frequencies>;
  /** insert data into the table: "control_groups" */
  insert_control_groups?: Maybe<Control_Groups_Mutation_Response>;
  /** insert a single row into the table: "control_groups" */
  insert_control_groups_one?: Maybe<Control_Groups>;
  /** insert data into the table: "control_priorities" */
  insert_control_priorities?: Maybe<Control_Priorities_Mutation_Response>;
  /** insert a single row into the table: "control_priorities" */
  insert_control_priorities_one?: Maybe<Control_Priorities>;
  /** insert data into the table: "control_tags" */
  insert_control_tags?: Maybe<Control_Tags_Mutation_Response>;
  /** insert a single row into the table: "control_tags" */
  insert_control_tags_one?: Maybe<Control_Tags>;
  /** insert data into the table: "control_tasks" */
  insert_control_tasks?: Maybe<Control_Tasks_Mutation_Response>;
  /** insert a single row into the table: "control_tasks" */
  insert_control_tasks_one?: Maybe<Control_Tasks>;
  /** insert data into the table: "controls" */
  insert_controls?: Maybe<Controls_Mutation_Response>;
  /** insert data into the table: "controls_history_stats" */
  insert_controls_history_stats?: Maybe<Controls_History_Stats_Mutation_Response>;
  /** insert a single row into the table: "controls_history_stats" */
  insert_controls_history_stats_one?: Maybe<Controls_History_Stats>;
  /** insert a single row into the table: "controls" */
  insert_controls_one?: Maybe<Controls>;
  /** insert data into the table: "criteria" */
  insert_criteria?: Maybe<Criteria_Mutation_Response>;
  /** insert a single row into the table: "criteria" */
  insert_criteria_one?: Maybe<Criteria>;
  /** insert data into the table: "date_field_values" */
  insert_date_field_values?: Maybe<Date_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "date_field_values" */
  insert_date_field_values_one?: Maybe<Date_Field_Values>;
  /** insert data into the table: "email_field_values" */
  insert_email_field_values?: Maybe<Email_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "email_field_values" */
  insert_email_field_values_one?: Maybe<Email_Field_Values>;
  /** insert data into the table: "evidence_acl" */
  insert_evidence_acl?: Maybe<Evidence_Acl_Mutation_Response>;
  /** insert a single row into the table: "evidence_acl" */
  insert_evidence_acl_one?: Maybe<Evidence_Acl>;
  /** insert data into the table: "evidence_integrations" */
  insert_evidence_integrations?: Maybe<Evidence_Integrations_Mutation_Response>;
  /** insert a single row into the table: "evidence_integrations" */
  insert_evidence_integrations_one?: Maybe<Evidence_Integrations>;
  /** insert data into the table: "evidence_policies" */
  insert_evidence_policies?: Maybe<Evidence_Policies_Mutation_Response>;
  /** insert a single row into the table: "evidence_policies" */
  insert_evidence_policies_one?: Maybe<Evidence_Policies>;
  /** insert data into the table: "evidence_tags" */
  insert_evidence_tags?: Maybe<Evidence_Tags_Mutation_Response>;
  /** insert a single row into the table: "evidence_tags" */
  insert_evidence_tags_one?: Maybe<Evidence_Tags>;
  /** insert data into the table: "evidence_version_file" */
  insert_evidence_version_file?: Maybe<Evidence_Version_File_Mutation_Response>;
  /** insert a single row into the table: "evidence_version_file" */
  insert_evidence_version_file_one?: Maybe<Evidence_Version_File>;
  /** insert data into the table: "evidence_versions" */
  insert_evidence_versions?: Maybe<Evidence_Versions_Mutation_Response>;
  /** insert a single row into the table: "evidence_versions" */
  insert_evidence_versions_one?: Maybe<Evidence_Versions>;
  /** insert data into the table: "evidences" */
  insert_evidences?: Maybe<Evidences_Mutation_Response>;
  /** insert a single row into the table: "evidences" */
  insert_evidences_one?: Maybe<Evidences>;
  /** insert data into the table: "field_configs" */
  insert_field_configs?: Maybe<Field_Configs_Mutation_Response>;
  /** insert a single row into the table: "field_configs" */
  insert_field_configs_one?: Maybe<Field_Configs>;
  /** insert data into the table: "field_entities" */
  insert_field_entities?: Maybe<Field_Entities_Mutation_Response>;
  /** insert a single row into the table: "field_entities" */
  insert_field_entities_one?: Maybe<Field_Entities>;
  /** insert data into the table: "field_types" */
  insert_field_types?: Maybe<Field_Types_Mutation_Response>;
  /** insert a single row into the table: "field_types" */
  insert_field_types_one?: Maybe<Field_Types>;
  /** insert data into the table: "field_values" */
  insert_field_values?: Maybe<Field_Values_Mutation_Response>;
  /** insert a single row into the table: "field_values" */
  insert_field_values_one?: Maybe<Field_Values>;
  /** insert data into the table: "finding_causes" */
  insert_finding_causes?: Maybe<Finding_Causes_Mutation_Response>;
  /** insert a single row into the table: "finding_causes" */
  insert_finding_causes_one?: Maybe<Finding_Causes>;
  /** insert data into the table: "finding_types" */
  insert_finding_types?: Maybe<Finding_Types_Mutation_Response>;
  /** insert a single row into the table: "finding_types" */
  insert_finding_types_one?: Maybe<Finding_Types>;
  /** insert data into the table: "forms" */
  insert_forms?: Maybe<Forms_Mutation_Response>;
  /** insert a single row into the table: "forms" */
  insert_forms_one?: Maybe<Forms>;
  /** insert data into the table: "frameworks" */
  insert_frameworks?: Maybe<Frameworks_Mutation_Response>;
  /** insert data into the table: "frameworks_controls" */
  insert_frameworks_controls?: Maybe<Frameworks_Controls_Mutation_Response>;
  /** insert a single row into the table: "frameworks_controls" */
  insert_frameworks_controls_one?: Maybe<Frameworks_Controls>;
  /** insert a single row into the table: "frameworks" */
  insert_frameworks_one?: Maybe<Frameworks>;
  /** insert data into the table: "frequencies" */
  insert_frequencies?: Maybe<Frequencies_Mutation_Response>;
  /** insert a single row into the table: "frequencies" */
  insert_frequencies_one?: Maybe<Frequencies>;
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>;
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>;
  /** insert data into the table: "integration_names" */
  insert_integration_names?: Maybe<Integration_Names_Mutation_Response>;
  /** insert a single row into the table: "integration_names" */
  insert_integration_names_one?: Maybe<Integration_Names>;
  /** insert data into the table: "integration_runs" */
  insert_integration_runs?: Maybe<Integration_Runs_Mutation_Response>;
  /** insert a single row into the table: "integration_runs" */
  insert_integration_runs_one?: Maybe<Integration_Runs>;
  /** insert data into the table: "integrations" */
  insert_integrations?: Maybe<Integrations_Mutation_Response>;
  /** insert a single row into the table: "integrations" */
  insert_integrations_one?: Maybe<Integrations>;
  /** insert data into the table: "invitations" */
  insert_invitations?: Maybe<Invitations_Mutation_Response>;
  /** insert a single row into the table: "invitations" */
  insert_invitations_one?: Maybe<Invitations>;
  /** insert data into the table: "notification_delivery_types" */
  insert_notification_delivery_types?: Maybe<Notification_Delivery_Types_Mutation_Response>;
  /** insert a single row into the table: "notification_delivery_types" */
  insert_notification_delivery_types_one?: Maybe<Notification_Delivery_Types>;
  /** insert data into the table: "notification_disabled_settings" */
  insert_notification_disabled_settings?: Maybe<Notification_Disabled_Settings_Mutation_Response>;
  /** insert a single row into the table: "notification_disabled_settings" */
  insert_notification_disabled_settings_one?: Maybe<Notification_Disabled_Settings>;
  /** insert data into the table: "notification_types" */
  insert_notification_types?: Maybe<Notification_Types_Mutation_Response>;
  /** insert a single row into the table: "notification_types" */
  insert_notification_types_one?: Maybe<Notification_Types>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "number_field_values" */
  insert_number_field_values?: Maybe<Number_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "number_field_values" */
  insert_number_field_values_one?: Maybe<Number_Field_Values>;
  /** insert data into the table: "organization_evidence_tags" */
  insert_organization_evidence_tags?: Maybe<Organization_Evidence_Tags_Mutation_Response>;
  /** insert a single row into the table: "organization_evidence_tags" */
  insert_organization_evidence_tags_one?: Maybe<Organization_Evidence_Tags>;
  /** insert data into the table: "organization_integrations" */
  insert_organization_integrations?: Maybe<Organization_Integrations_Mutation_Response>;
  /** insert a single row into the table: "organization_integrations" */
  insert_organization_integrations_one?: Maybe<Organization_Integrations>;
  /** insert data into the table: "organization_risk_categories" */
  insert_organization_risk_categories?: Maybe<Organization_Risk_Categories_Mutation_Response>;
  /** insert a single row into the table: "organization_risk_categories" */
  insert_organization_risk_categories_one?: Maybe<Organization_Risk_Categories>;
  /** insert data into the table: "organization_risk_tags" */
  insert_organization_risk_tags?: Maybe<Organization_Risk_Tags_Mutation_Response>;
  /** insert a single row into the table: "organization_risk_tags" */
  insert_organization_risk_tags_one?: Maybe<Organization_Risk_Tags>;
  /** insert data into the table: "organization_template_frameworks" */
  insert_organization_template_frameworks?: Maybe<Organization_Template_Frameworks_Mutation_Response>;
  /** insert a single row into the table: "organization_template_frameworks" */
  insert_organization_template_frameworks_one?: Maybe<Organization_Template_Frameworks>;
  /** insert data into the table: "organization_tenants" */
  insert_organization_tenants?: Maybe<Organization_Tenants_Mutation_Response>;
  /** insert a single row into the table: "organization_tenants" */
  insert_organization_tenants_one?: Maybe<Organization_Tenants>;
  /** insert data into the table: "organization_users" */
  insert_organization_users?: Maybe<Organization_Users_Mutation_Response>;
  /** insert a single row into the table: "organization_users" */
  insert_organization_users_one?: Maybe<Organization_Users>;
  /** insert data into the table: "organization_vendor_risk_levels" */
  insert_organization_vendor_risk_levels?: Maybe<Organization_Vendor_Risk_Levels_Mutation_Response>;
  /** insert a single row into the table: "organization_vendor_risk_levels" */
  insert_organization_vendor_risk_levels_one?: Maybe<Organization_Vendor_Risk_Levels>;
  /** insert data into the table: "organization_vendor_tiers" */
  insert_organization_vendor_tiers?: Maybe<Organization_Vendor_Tiers_Mutation_Response>;
  /** insert a single row into the table: "organization_vendor_tiers" */
  insert_organization_vendor_tiers_one?: Maybe<Organization_Vendor_Tiers>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "permissions" */
  insert_permissions?: Maybe<Permissions_Mutation_Response>;
  /** insert a single row into the table: "permissions" */
  insert_permissions_one?: Maybe<Permissions>;
  /** insert data into the table: "policies" */
  insert_policies?: Maybe<Policies_Mutation_Response>;
  /** insert a single row into the table: "policies" */
  insert_policies_one?: Maybe<Policies>;
  /** insert data into the table: "policy_acknowledgement_statuses" */
  insert_policy_acknowledgement_statuses?: Maybe<Policy_Acknowledgement_Statuses_Mutation_Response>;
  /** insert a single row into the table: "policy_acknowledgement_statuses" */
  insert_policy_acknowledgement_statuses_one?: Maybe<Policy_Acknowledgement_Statuses>;
  /** insert data into the table: "policy_acknowledgement_users" */
  insert_policy_acknowledgement_users?: Maybe<Policy_Acknowledgement_Users_Mutation_Response>;
  /** insert a single row into the table: "policy_acknowledgement_users" */
  insert_policy_acknowledgement_users_one?: Maybe<Policy_Acknowledgement_Users>;
  /** insert data into the table: "policy_acknowledgements" */
  insert_policy_acknowledgements?: Maybe<Policy_Acknowledgements_Mutation_Response>;
  /** insert a single row into the table: "policy_acknowledgements" */
  insert_policy_acknowledgements_one?: Maybe<Policy_Acknowledgements>;
  /** insert data into the table: "policy_approval_statuses" */
  insert_policy_approval_statuses?: Maybe<Policy_Approval_Statuses_Mutation_Response>;
  /** insert a single row into the table: "policy_approval_statuses" */
  insert_policy_approval_statuses_one?: Maybe<Policy_Approval_Statuses>;
  /** insert data into the table: "policy_approval_users" */
  insert_policy_approval_users?: Maybe<Policy_Approval_Users_Mutation_Response>;
  /** insert a single row into the table: "policy_approval_users" */
  insert_policy_approval_users_one?: Maybe<Policy_Approval_Users>;
  /** insert data into the table: "policy_approvals" */
  insert_policy_approvals?: Maybe<Policy_Approvals_Mutation_Response>;
  /** insert a single row into the table: "policy_approvals" */
  insert_policy_approvals_one?: Maybe<Policy_Approvals>;
  /** insert data into the table: "policy_approvers" */
  insert_policy_approvers?: Maybe<Policy_Approvers_Mutation_Response>;
  /** insert a single row into the table: "policy_approvers" */
  insert_policy_approvers_one?: Maybe<Policy_Approvers>;
  /** insert data into the table: "policy_statuses" */
  insert_policy_statuses?: Maybe<Policy_Statuses_Mutation_Response>;
  /** insert a single row into the table: "policy_statuses" */
  insert_policy_statuses_one?: Maybe<Policy_Statuses>;
  /** insert data into the table: "policy_tasks" */
  insert_policy_tasks?: Maybe<Policy_Tasks_Mutation_Response>;
  /** insert a single row into the table: "policy_tasks" */
  insert_policy_tasks_one?: Maybe<Policy_Tasks>;
  /** insert data into the table: "policy_types" */
  insert_policy_types?: Maybe<Policy_Types_Mutation_Response>;
  /** insert a single row into the table: "policy_types" */
  insert_policy_types_one?: Maybe<Policy_Types>;
  /** insert data into the table: "policy_version_statuses" */
  insert_policy_version_statuses?: Maybe<Policy_Version_Statuses_Mutation_Response>;
  /** insert a single row into the table: "policy_version_statuses" */
  insert_policy_version_statuses_one?: Maybe<Policy_Version_Statuses>;
  /** insert data into the table: "policy_versions" */
  insert_policy_versions?: Maybe<Policy_Versions_Mutation_Response>;
  /** insert a single row into the table: "policy_versions" */
  insert_policy_versions_one?: Maybe<Policy_Versions>;
  /** insert data into the table: "program_controls" */
  insert_program_controls?: Maybe<Program_Controls_Mutation_Response>;
  /** insert a single row into the table: "program_controls" */
  insert_program_controls_one?: Maybe<Program_Controls>;
  /** insert data into the table: "program_field_configs" */
  insert_program_field_configs?: Maybe<Program_Field_Configs_Mutation_Response>;
  /** insert a single row into the table: "program_field_configs" */
  insert_program_field_configs_one?: Maybe<Program_Field_Configs>;
  /** insert data into the table: "programs" */
  insert_programs?: Maybe<Programs_Mutation_Response>;
  /** insert a single row into the table: "programs" */
  insert_programs_one?: Maybe<Programs>;
  /** insert data into the table: "questionnaire_uploads" */
  insert_questionnaire_uploads?: Maybe<Questionnaire_Uploads_Mutation_Response>;
  /** insert a single row into the table: "questionnaire_uploads" */
  insert_questionnaire_uploads_one?: Maybe<Questionnaire_Uploads>;
  /** insert data into the table: "questionnaires" */
  insert_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "questionnaires" */
  insert_questionnaires_one?: Maybe<Questionnaires>;
  /** insert data into the table: "report_types" */
  insert_report_types?: Maybe<Report_Types_Mutation_Response>;
  /** insert a single row into the table: "report_types" */
  insert_report_types_one?: Maybe<Report_Types>;
  /** insert data into the table: "reports" */
  insert_reports?: Maybe<Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "review_files" */
  insert_review_files?: Maybe<Review_Files_Mutation_Response>;
  /** insert a single row into the table: "review_files" */
  insert_review_files_one?: Maybe<Review_Files>;
  /** insert data into the table: "reviews" */
  insert_reviews?: Maybe<Reviews_Mutation_Response>;
  /** insert a single row into the table: "reviews" */
  insert_reviews_one?: Maybe<Reviews>;
  /** insert data into the table: "risk_categories" */
  insert_risk_categories?: Maybe<Risk_Categories_Mutation_Response>;
  /** insert a single row into the table: "risk_categories" */
  insert_risk_categories_one?: Maybe<Risk_Categories>;
  /** insert data into the table: "risk_controls" */
  insert_risk_controls?: Maybe<Risk_Controls_Mutation_Response>;
  /** insert a single row into the table: "risk_controls" */
  insert_risk_controls_one?: Maybe<Risk_Controls>;
  /** insert data into the table: "risk_documents" */
  insert_risk_documents?: Maybe<Risk_Documents_Mutation_Response>;
  /** insert a single row into the table: "risk_documents" */
  insert_risk_documents_one?: Maybe<Risk_Documents>;
  /** insert data into the table: "risk_impacts" */
  insert_risk_impacts?: Maybe<Risk_Impacts_Mutation_Response>;
  /** insert a single row into the table: "risk_impacts" */
  insert_risk_impacts_one?: Maybe<Risk_Impacts>;
  /** insert data into the table: "risk_inherent_levels" */
  insert_risk_inherent_levels?: Maybe<Risk_Inherent_Levels_Mutation_Response>;
  /** insert a single row into the table: "risk_inherent_levels" */
  insert_risk_inherent_levels_one?: Maybe<Risk_Inherent_Levels>;
  /** insert data into the table: "risk_levels" */
  insert_risk_levels?: Maybe<Risk_Levels_Mutation_Response>;
  /** insert a single row into the table: "risk_levels" */
  insert_risk_levels_one?: Maybe<Risk_Levels>;
  /** insert data into the table: "risk_likelihoods" */
  insert_risk_likelihoods?: Maybe<Risk_Likelihoods_Mutation_Response>;
  /** insert a single row into the table: "risk_likelihoods" */
  insert_risk_likelihoods_one?: Maybe<Risk_Likelihoods>;
  /** insert data into the table: "risk_residual_levels" */
  insert_risk_residual_levels?: Maybe<Risk_Residual_Levels_Mutation_Response>;
  /** insert a single row into the table: "risk_residual_levels" */
  insert_risk_residual_levels_one?: Maybe<Risk_Residual_Levels>;
  /** insert data into the table: "risk_tags" */
  insert_risk_tags?: Maybe<Risk_Tags_Mutation_Response>;
  /** insert a single row into the table: "risk_tags" */
  insert_risk_tags_one?: Maybe<Risk_Tags>;
  /** insert data into the table: "risk_tasks" */
  insert_risk_tasks?: Maybe<Risk_Tasks_Mutation_Response>;
  /** insert a single row into the table: "risk_tasks" */
  insert_risk_tasks_one?: Maybe<Risk_Tasks>;
  /** insert data into the table: "risks" */
  insert_risks?: Maybe<Risks_Mutation_Response>;
  /** insert a single row into the table: "risks" */
  insert_risks_one?: Maybe<Risks>;
  /** insert data into the table: "role_permissions" */
  insert_role_permissions?: Maybe<Role_Permissions_Mutation_Response>;
  /** insert a single row into the table: "role_permissions" */
  insert_role_permissions_one?: Maybe<Role_Permissions>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "select_field_configs" */
  insert_select_field_configs?: Maybe<Select_Field_Configs_Mutation_Response>;
  /** insert a single row into the table: "select_field_configs" */
  insert_select_field_configs_one?: Maybe<Select_Field_Configs>;
  /** insert data into the table: "select_field_options" */
  insert_select_field_options?: Maybe<Select_Field_Options_Mutation_Response>;
  /** insert a single row into the table: "select_field_options" */
  insert_select_field_options_one?: Maybe<Select_Field_Options>;
  /** insert data into the table: "select_field_values" */
  insert_select_field_values?: Maybe<Select_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "select_field_values" */
  insert_select_field_values_one?: Maybe<Select_Field_Values>;
  /** insert data into the table: "system_roles" */
  insert_system_roles?: Maybe<System_Roles_Mutation_Response>;
  /** insert a single row into the table: "system_roles" */
  insert_system_roles_one?: Maybe<System_Roles>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "task_owners" */
  insert_task_owners?: Maybe<Task_Owners_Mutation_Response>;
  /** insert a single row into the table: "task_owners" */
  insert_task_owners_one?: Maybe<Task_Owners>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "template_control_ai_review_criteria" */
  insert_template_control_ai_review_criteria?: Maybe<Template_Control_Ai_Review_Criteria_Mutation_Response>;
  /** insert a single row into the table: "template_control_ai_review_criteria" */
  insert_template_control_ai_review_criteria_one?: Maybe<Template_Control_Ai_Review_Criteria>;
  /** insert data into the table: "template_controls" */
  insert_template_controls?: Maybe<Template_Controls_Mutation_Response>;
  /** insert a single row into the table: "template_controls" */
  insert_template_controls_one?: Maybe<Template_Controls>;
  /** insert data into the table: "template_frameworks" */
  insert_template_frameworks?: Maybe<Template_Frameworks_Mutation_Response>;
  /** insert data into the table: "template_frameworks_controls" */
  insert_template_frameworks_controls?: Maybe<Template_Frameworks_Controls_Mutation_Response>;
  /** insert a single row into the table: "template_frameworks_controls" */
  insert_template_frameworks_controls_one?: Maybe<Template_Frameworks_Controls>;
  /** insert a single row into the table: "template_frameworks" */
  insert_template_frameworks_one?: Maybe<Template_Frameworks>;
  /** insert data into the table: "template_tasks" */
  insert_template_tasks?: Maybe<Template_Tasks_Mutation_Response>;
  /** insert a single row into the table: "template_tasks" */
  insert_template_tasks_one?: Maybe<Template_Tasks>;
  /** insert data into the table: "text_field_values" */
  insert_text_field_values?: Maybe<Text_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "text_field_values" */
  insert_text_field_values_one?: Maybe<Text_Field_Values>;
  /** insert data into the table: "treatment_plan" */
  insert_treatment_plan?: Maybe<Treatment_Plan_Mutation_Response>;
  /** insert a single row into the table: "treatment_plan" */
  insert_treatment_plan_one?: Maybe<Treatment_Plan>;
  /** insert data into the table: "url_field_values" */
  insert_url_field_values?: Maybe<Url_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "url_field_values" */
  insert_url_field_values_one?: Maybe<Url_Field_Values>;
  /** insert data into the table: "user_notifications" */
  insert_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** insert a single row into the table: "user_notifications" */
  insert_user_notifications_one?: Maybe<User_Notifications>;
  /** insert data into the table: "vendor_approval_statuses" */
  insert_vendor_approval_statuses?: Maybe<Vendor_Approval_Statuses_Mutation_Response>;
  /** insert a single row into the table: "vendor_approval_statuses" */
  insert_vendor_approval_statuses_one?: Maybe<Vendor_Approval_Statuses>;
  /** insert data into the table: "vendor_documents" */
  insert_vendor_documents?: Maybe<Vendor_Documents_Mutation_Response>;
  /** insert a single row into the table: "vendor_documents" */
  insert_vendor_documents_one?: Maybe<Vendor_Documents>;
  /** insert data into the table: "vendor_findings" */
  insert_vendor_findings?: Maybe<Vendor_Findings_Mutation_Response>;
  /** insert a single row into the table: "vendor_findings" */
  insert_vendor_findings_one?: Maybe<Vendor_Findings>;
  /** insert data into the table: "vendor_questionnaire_ai_review_assessment_sources" */
  insert_vendor_questionnaire_ai_review_assessment_sources?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_ai_review_assessment_sources" */
  insert_vendor_questionnaire_ai_review_assessment_sources_one?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** insert data into the table: "vendor_questionnaire_ai_review_assessments" */
  insert_vendor_questionnaire_ai_review_assessments?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_ai_review_assessments" */
  insert_vendor_questionnaire_ai_review_assessments_one?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** insert data into the table: "vendor_questionnaire_ai_review_run_status" */
  insert_vendor_questionnaire_ai_review_run_status?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_ai_review_run_status" */
  insert_vendor_questionnaire_ai_review_run_status_one?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status>;
  /** insert data into the table: "vendor_questionnaire_ai_review_runs" */
  insert_vendor_questionnaire_ai_review_runs?: Maybe<Vendor_Questionnaire_Ai_Review_Runs_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_ai_review_runs" */
  insert_vendor_questionnaire_ai_review_runs_one?: Maybe<Vendor_Questionnaire_Ai_Review_Runs>;
  /** insert data into the table: "vendor_questionnaire_form_answer_statuses" */
  insert_vendor_questionnaire_form_answer_statuses?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_form_answer_statuses" */
  insert_vendor_questionnaire_form_answer_statuses_one?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** insert data into the table: "vendor_questionnaire_form_answers" */
  insert_vendor_questionnaire_form_answers?: Maybe<Vendor_Questionnaire_Form_Answers_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_form_answers" */
  insert_vendor_questionnaire_form_answers_one?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** insert data into the table: "vendor_questionnaire_form_uploads" */
  insert_vendor_questionnaire_form_uploads?: Maybe<Vendor_Questionnaire_Form_Uploads_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_form_uploads" */
  insert_vendor_questionnaire_form_uploads_one?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** insert data into the table: "vendor_questionnaire_forms" */
  insert_vendor_questionnaire_forms?: Maybe<Vendor_Questionnaire_Forms_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_forms" */
  insert_vendor_questionnaire_forms_one?: Maybe<Vendor_Questionnaire_Forms>;
  /** insert data into the table: "vendor_questionnaire_statuses" */
  insert_vendor_questionnaire_statuses?: Maybe<Vendor_Questionnaire_Statuses_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_statuses" */
  insert_vendor_questionnaire_statuses_one?: Maybe<Vendor_Questionnaire_Statuses>;
  /** insert data into the table: "vendor_questionnaire_uploads" */
  insert_vendor_questionnaire_uploads?: Maybe<Vendor_Questionnaire_Uploads_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_uploads" */
  insert_vendor_questionnaire_uploads_one?: Maybe<Vendor_Questionnaire_Uploads>;
  /** insert data into the table: "vendor_questionnaires" */
  insert_vendor_questionnaires?: Maybe<Vendor_Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaires" */
  insert_vendor_questionnaires_one?: Maybe<Vendor_Questionnaires>;
  /** insert data into the table: "vendor_risks" */
  insert_vendor_risks?: Maybe<Vendor_Risks_Mutation_Response>;
  /** insert a single row into the table: "vendor_risks" */
  insert_vendor_risks_one?: Maybe<Vendor_Risks>;
  /** insert data into the table: "vendor_tasks" */
  insert_vendor_tasks?: Maybe<Vendor_Tasks_Mutation_Response>;
  /** insert a single row into the table: "vendor_tasks" */
  insert_vendor_tasks_one?: Maybe<Vendor_Tasks>;
  /** insert data into the table: "vendors" */
  insert_vendors?: Maybe<Vendors_Mutation_Response>;
  /** insert a single row into the table: "vendors" */
  insert_vendors_one?: Maybe<Vendors>;
  invite_user?: Maybe<InviteUserOutput>;
  join_organization?: Maybe<GeneralActionOutput>;
  revoke_evidence_access?: Maybe<RevokeEvidenceAccessOutput>;
  /** Send questionnaire to multiple vendors */
  sendVendorQuestionnaire?: Maybe<SendVendorQuestionnaireOutput>;
  /** This action triggers an endpoint that sends reminder for policy approval */
  send_approval_reminder?: Maybe<ApprovalReminderOutput>;
  set_evidence_confidential?: Maybe<SetEvidenceConfidentialOutput>;
  set_org_integration_config?: Maybe<SetOrgIntegrationConfigOutput>;
  set_select_field_values?: Maybe<SetSelectFieldValuesOutput>;
  toggle_audit_trail?: Maybe<GeneralActionOutput>;
  trigger_all_controls_embeddings_lifecycle?: Maybe<GeneralActionOutput>;
  trigger_all_evidences_embeddings_lifecycle?: Maybe<GeneralActionOutput>;
  /** Trigger all files embeddings */
  trigger_all_files_embeddings_lifecycle?: Maybe<GeneralActionOutput>;
  trigger_all_policies_embeddings_lifecycle?: Maybe<GeneralActionOutput>;
  trigger_all_programs_embeddings_lifecycle?: Maybe<GeneralActionOutput>;
  trigger_all_risks_embeddings_lifecycle?: Maybe<GeneralActionOutput>;
  trigger_all_tasks_embeddings_lifecycle?: Maybe<GeneralActionOutput>;
  trigger_all_vendors_embeddings_lifecycle?: Maybe<GeneralActionOutput>;
  /** update single row of the table: "auth.providers" */
  updateAuthProvider?: Maybe<AuthProviders>;
  /** update single row of the table: "auth.provider_requests" */
  updateAuthProviderRequest?: Maybe<AuthProviderRequests>;
  /** update data of the table: "auth.provider_requests" */
  updateAuthProviderRequests?: Maybe<AuthProviderRequests_Mutation_Response>;
  /** update data of the table: "auth.providers" */
  updateAuthProviders?: Maybe<AuthProviders_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  updateAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** update single row of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** update data of the table: "auth.refresh_token_types" */
  updateAuthRefreshTokenTypes?: Maybe<AuthRefreshTokenTypes_Mutation_Response>;
  /** update data of the table: "auth.refresh_tokens" */
  updateAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** update single row of the table: "auth.roles" */
  updateAuthRole?: Maybe<AuthRoles>;
  /** update data of the table: "auth.roles" */
  updateAuthRoles?: Maybe<AuthRoles_Mutation_Response>;
  /** update single row of the table: "auth.user_providers" */
  updateAuthUserProvider?: Maybe<AuthUserProviders>;
  /** update data of the table: "auth.user_providers" */
  updateAuthUserProviders?: Maybe<AuthUserProviders_Mutation_Response>;
  /** update single row of the table: "auth.user_roles" */
  updateAuthUserRole?: Maybe<AuthUserRoles>;
  /** update data of the table: "auth.user_roles" */
  updateAuthUserRoles?: Maybe<AuthUserRoles_Mutation_Response>;
  /** update single row of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** update data of the table: "auth.user_security_keys" */
  updateAuthUserSecurityKeys?: Maybe<AuthUserSecurityKeys_Mutation_Response>;
  /** update single row of the table: "storage.buckets" */
  updateBucket?: Maybe<Buckets>;
  /** update data of the table: "storage.buckets" */
  updateBuckets?: Maybe<Buckets_Mutation_Response>;
  /** update single row of the table: "storage.files" */
  updateFile?: Maybe<Files>;
  /** update data of the table: "storage.files" */
  updateFiles?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "auth.users" */
  updateUser?: Maybe<Users>;
  /** update data of the table: "auth.users" */
  updateUsers?: Maybe<Users_Mutation_Response>;
  /** update data of the table: "ai.controls_vector_store" */
  update_ai_controls_vector_store?: Maybe<Ai_Controls_Vector_Store_Mutation_Response>;
  /** update single row of the table: "ai.controls_vector_store" */
  update_ai_controls_vector_store_by_pk?: Maybe<Ai_Controls_Vector_Store>;
  /** update multiples rows of table: "ai.controls_vector_store" */
  update_ai_controls_vector_store_many?: Maybe<
    Array<Maybe<Ai_Controls_Vector_Store_Mutation_Response>>
  >;
  /** update data of the table: "ai.evidences_vector_store" */
  update_ai_evidences_vector_store?: Maybe<Ai_Evidences_Vector_Store_Mutation_Response>;
  /** update single row of the table: "ai.evidences_vector_store" */
  update_ai_evidences_vector_store_by_pk?: Maybe<Ai_Evidences_Vector_Store>;
  /** update multiples rows of table: "ai.evidences_vector_store" */
  update_ai_evidences_vector_store_many?: Maybe<
    Array<Maybe<Ai_Evidences_Vector_Store_Mutation_Response>>
  >;
  /** update data of the table: "ai.files_document_store" */
  update_ai_files_document_store?: Maybe<Ai_Files_Document_Store_Mutation_Response>;
  /** update single row of the table: "ai.files_document_store" */
  update_ai_files_document_store_by_pk?: Maybe<Ai_Files_Document_Store>;
  /** update multiples rows of table: "ai.files_document_store" */
  update_ai_files_document_store_many?: Maybe<
    Array<Maybe<Ai_Files_Document_Store_Mutation_Response>>
  >;
  /** update data of the table: "ai.files_vector_store" */
  update_ai_files_vector_store?: Maybe<Ai_Files_Vector_Store_Mutation_Response>;
  /** update single row of the table: "ai.files_vector_store" */
  update_ai_files_vector_store_by_pk?: Maybe<Ai_Files_Vector_Store>;
  /** update multiples rows of table: "ai.files_vector_store" */
  update_ai_files_vector_store_many?: Maybe<Array<Maybe<Ai_Files_Vector_Store_Mutation_Response>>>;
  /** update data of the table: "ai.policies_vector_store" */
  update_ai_policies_vector_store?: Maybe<Ai_Policies_Vector_Store_Mutation_Response>;
  /** update single row of the table: "ai.policies_vector_store" */
  update_ai_policies_vector_store_by_pk?: Maybe<Ai_Policies_Vector_Store>;
  /** update multiples rows of table: "ai.policies_vector_store" */
  update_ai_policies_vector_store_many?: Maybe<
    Array<Maybe<Ai_Policies_Vector_Store_Mutation_Response>>
  >;
  /** update data of the table: "ai.programs_vector_store" */
  update_ai_programs_vector_store?: Maybe<Ai_Programs_Vector_Store_Mutation_Response>;
  /** update single row of the table: "ai.programs_vector_store" */
  update_ai_programs_vector_store_by_pk?: Maybe<Ai_Programs_Vector_Store>;
  /** update multiples rows of table: "ai.programs_vector_store" */
  update_ai_programs_vector_store_many?: Maybe<
    Array<Maybe<Ai_Programs_Vector_Store_Mutation_Response>>
  >;
  /** update data of the table: "ai.risks_vector_store" */
  update_ai_risks_vector_store?: Maybe<Ai_Risks_Vector_Store_Mutation_Response>;
  /** update single row of the table: "ai.risks_vector_store" */
  update_ai_risks_vector_store_by_pk?: Maybe<Ai_Risks_Vector_Store>;
  /** update multiples rows of table: "ai.risks_vector_store" */
  update_ai_risks_vector_store_many?: Maybe<Array<Maybe<Ai_Risks_Vector_Store_Mutation_Response>>>;
  /** update data of the table: "ai.tasks_vector_store" */
  update_ai_tasks_vector_store?: Maybe<Ai_Tasks_Vector_Store_Mutation_Response>;
  /** update single row of the table: "ai.tasks_vector_store" */
  update_ai_tasks_vector_store_by_pk?: Maybe<Ai_Tasks_Vector_Store>;
  /** update multiples rows of table: "ai.tasks_vector_store" */
  update_ai_tasks_vector_store_many?: Maybe<Array<Maybe<Ai_Tasks_Vector_Store_Mutation_Response>>>;
  /** update data of the table: "ai.vendors_vector_store" */
  update_ai_vendors_vector_store?: Maybe<Ai_Vendors_Vector_Store_Mutation_Response>;
  /** update single row of the table: "ai.vendors_vector_store" */
  update_ai_vendors_vector_store_by_pk?: Maybe<Ai_Vendors_Vector_Store>;
  /** update multiples rows of table: "ai.vendors_vector_store" */
  update_ai_vendors_vector_store_many?: Maybe<
    Array<Maybe<Ai_Vendors_Vector_Store_Mutation_Response>>
  >;
  update_all_evidence_status?: Maybe<GeneralActionOutput>;
  update_all_risks_assessment_status?: Maybe<GeneralActionOutput>;
  update_all_vendor_questionnaires_status?: Maybe<GeneralActionOutput>;
  update_all_vendors_assessment_status?: Maybe<GeneralActionOutput>;
  /** update data of the table: "audit.logged_actions" */
  update_audit_logged_actions?: Maybe<Audit_Logged_Actions_Mutation_Response>;
  /** update single row of the table: "audit.logged_actions" */
  update_audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** update multiples rows of table: "audit.logged_actions" */
  update_audit_logged_actions_many?: Maybe<Array<Maybe<Audit_Logged_Actions_Mutation_Response>>>;
  /** update data of the table: "audit.policy_history_view" */
  update_audit_policy_history_view?: Maybe<Audit_Policy_History_View_Mutation_Response>;
  /** update multiples rows of table: "audit.policy_history_view" */
  update_audit_policy_history_view_many?: Maybe<
    Array<Maybe<Audit_Policy_History_View_Mutation_Response>>
  >;
  /** update data of the table: "audit.risk_history_view" */
  update_audit_risk_history_view?: Maybe<Audit_Risk_History_View_Mutation_Response>;
  /** update multiples rows of table: "audit.risk_history_view" */
  update_audit_risk_history_view_many?: Maybe<
    Array<Maybe<Audit_Risk_History_View_Mutation_Response>>
  >;
  /** update data of the table: "audit.vendor_history_view" */
  update_audit_vendor_history_view?: Maybe<Audit_Vendor_History_View_Mutation_Response>;
  /** update multiples rows of table: "audit.vendor_history_view" */
  update_audit_vendor_history_view_many?: Maybe<
    Array<Maybe<Audit_Vendor_History_View_Mutation_Response>>
  >;
  /** update multiples rows of table: "auth.provider_requests" */
  update_authProviderRequests_many?: Maybe<Array<Maybe<AuthProviderRequests_Mutation_Response>>>;
  /** update multiples rows of table: "auth.providers" */
  update_authProviders_many?: Maybe<Array<Maybe<AuthProviders_Mutation_Response>>>;
  /** update multiples rows of table: "auth.refresh_token_types" */
  update_authRefreshTokenTypes_many?: Maybe<Array<Maybe<AuthRefreshTokenTypes_Mutation_Response>>>;
  /** update multiples rows of table: "auth.refresh_tokens" */
  update_authRefreshTokens_many?: Maybe<Array<Maybe<AuthRefreshTokens_Mutation_Response>>>;
  /** update multiples rows of table: "auth.roles" */
  update_authRoles_many?: Maybe<Array<Maybe<AuthRoles_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_providers" */
  update_authUserProviders_many?: Maybe<Array<Maybe<AuthUserProviders_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_roles" */
  update_authUserRoles_many?: Maybe<Array<Maybe<AuthUserRoles_Mutation_Response>>>;
  /** update multiples rows of table: "auth.user_security_keys" */
  update_authUserSecurityKeys_many?: Maybe<Array<Maybe<AuthUserSecurityKeys_Mutation_Response>>>;
  /** update data of the table: "auth.jackson_index" */
  update_auth_jackson_index?: Maybe<Auth_Jackson_Index_Mutation_Response>;
  /** update single row of the table: "auth.jackson_index" */
  update_auth_jackson_index_by_pk?: Maybe<Auth_Jackson_Index>;
  /** update multiples rows of table: "auth.jackson_index" */
  update_auth_jackson_index_many?: Maybe<Array<Maybe<Auth_Jackson_Index_Mutation_Response>>>;
  /** update data of the table: "auth.jackson_store" */
  update_auth_jackson_store?: Maybe<Auth_Jackson_Store_Mutation_Response>;
  /** update single row of the table: "auth.jackson_store" */
  update_auth_jackson_store_by_pk?: Maybe<Auth_Jackson_Store>;
  /** update multiples rows of table: "auth.jackson_store" */
  update_auth_jackson_store_many?: Maybe<Array<Maybe<Auth_Jackson_Store_Mutation_Response>>>;
  /** update data of the table: "auth.jackson_ttl" */
  update_auth_jackson_ttl?: Maybe<Auth_Jackson_Ttl_Mutation_Response>;
  /** update single row of the table: "auth.jackson_ttl" */
  update_auth_jackson_ttl_by_pk?: Maybe<Auth_Jackson_Ttl>;
  /** update multiples rows of table: "auth.jackson_ttl" */
  update_auth_jackson_ttl_many?: Maybe<Array<Maybe<Auth_Jackson_Ttl_Mutation_Response>>>;
  /** update data of the table: "auth.migrations" */
  update_auth_migrations?: Maybe<Auth_Migrations_Mutation_Response>;
  /** update single row of the table: "auth.migrations" */
  update_auth_migrations_by_pk?: Maybe<Auth_Migrations>;
  /** update multiples rows of table: "auth.migrations" */
  update_auth_migrations_many?: Maybe<Array<Maybe<Auth_Migrations_Mutation_Response>>>;
  /** update multiples rows of table: "storage.buckets" */
  update_buckets_many?: Maybe<Array<Maybe<Buckets_Mutation_Response>>>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>;
  /** update data of the table: "client_questionnaire_answer_ai_sources" */
  update_client_questionnaire_answer_ai_sources?: Maybe<Client_Questionnaire_Answer_Ai_Sources_Mutation_Response>;
  /** update single row of the table: "client_questionnaire_answer_ai_sources" */
  update_client_questionnaire_answer_ai_sources_by_pk?: Maybe<Client_Questionnaire_Answer_Ai_Sources>;
  /** update multiples rows of table: "client_questionnaire_answer_ai_sources" */
  update_client_questionnaire_answer_ai_sources_many?: Maybe<
    Array<Maybe<Client_Questionnaire_Answer_Ai_Sources_Mutation_Response>>
  >;
  /** update data of the table: "client_questionnaire_answers" */
  update_client_questionnaire_answers?: Maybe<Client_Questionnaire_Answers_Mutation_Response>;
  /** update single row of the table: "client_questionnaire_answers" */
  update_client_questionnaire_answers_by_pk?: Maybe<Client_Questionnaire_Answers>;
  /** update multiples rows of table: "client_questionnaire_answers" */
  update_client_questionnaire_answers_many?: Maybe<
    Array<Maybe<Client_Questionnaire_Answers_Mutation_Response>>
  >;
  /** update data of the table: "client_questionnaire_question_status" */
  update_client_questionnaire_question_status?: Maybe<Client_Questionnaire_Question_Status_Mutation_Response>;
  /** update single row of the table: "client_questionnaire_question_status" */
  update_client_questionnaire_question_status_by_pk?: Maybe<Client_Questionnaire_Question_Status>;
  /** update multiples rows of table: "client_questionnaire_question_status" */
  update_client_questionnaire_question_status_many?: Maybe<
    Array<Maybe<Client_Questionnaire_Question_Status_Mutation_Response>>
  >;
  /** update data of the table: "client_questionnaire_questions" */
  update_client_questionnaire_questions?: Maybe<Client_Questionnaire_Questions_Mutation_Response>;
  /** update single row of the table: "client_questionnaire_questions" */
  update_client_questionnaire_questions_by_pk?: Maybe<Client_Questionnaire_Questions>;
  /** update multiples rows of table: "client_questionnaire_questions" */
  update_client_questionnaire_questions_many?: Maybe<
    Array<Maybe<Client_Questionnaire_Questions_Mutation_Response>>
  >;
  /** update data of the table: "client_questionnaire_status" */
  update_client_questionnaire_status?: Maybe<Client_Questionnaire_Status_Mutation_Response>;
  /** update single row of the table: "client_questionnaire_status" */
  update_client_questionnaire_status_by_pk?: Maybe<Client_Questionnaire_Status>;
  /** update multiples rows of table: "client_questionnaire_status" */
  update_client_questionnaire_status_many?: Maybe<
    Array<Maybe<Client_Questionnaire_Status_Mutation_Response>>
  >;
  /** update data of the table: "client_questionnaires" */
  update_client_questionnaires?: Maybe<Client_Questionnaires_Mutation_Response>;
  /** update single row of the table: "client_questionnaires" */
  update_client_questionnaires_by_pk?: Maybe<Client_Questionnaires>;
  /** update multiples rows of table: "client_questionnaires" */
  update_client_questionnaires_many?: Maybe<Array<Maybe<Client_Questionnaires_Mutation_Response>>>;
  /** update data of the table: "comment_uploads" */
  update_comment_uploads?: Maybe<Comment_Uploads_Mutation_Response>;
  /** update single row of the table: "comment_uploads" */
  update_comment_uploads_by_pk?: Maybe<Comment_Uploads>;
  /** update multiples rows of table: "comment_uploads" */
  update_comment_uploads_many?: Maybe<Array<Maybe<Comment_Uploads_Mutation_Response>>>;
  /** update data of the table: "comments" */
  update_comments?: Maybe<Comments_Mutation_Response>;
  /** update single row of the table: "comments" */
  update_comments_by_pk?: Maybe<Comments>;
  /** update multiples rows of table: "comments" */
  update_comments_many?: Maybe<Array<Maybe<Comments_Mutation_Response>>>;
  /** update data of the table: "control_ai_review_assessment_sources" */
  update_control_ai_review_assessment_sources?: Maybe<Control_Ai_Review_Assessment_Sources_Mutation_Response>;
  /** update single row of the table: "control_ai_review_assessment_sources" */
  update_control_ai_review_assessment_sources_by_pk?: Maybe<Control_Ai_Review_Assessment_Sources>;
  /** update multiples rows of table: "control_ai_review_assessment_sources" */
  update_control_ai_review_assessment_sources_many?: Maybe<
    Array<Maybe<Control_Ai_Review_Assessment_Sources_Mutation_Response>>
  >;
  /** update data of the table: "control_ai_review_assessments" */
  update_control_ai_review_assessments?: Maybe<Control_Ai_Review_Assessments_Mutation_Response>;
  /** update single row of the table: "control_ai_review_assessments" */
  update_control_ai_review_assessments_by_pk?: Maybe<Control_Ai_Review_Assessments>;
  /** update multiples rows of table: "control_ai_review_assessments" */
  update_control_ai_review_assessments_many?: Maybe<
    Array<Maybe<Control_Ai_Review_Assessments_Mutation_Response>>
  >;
  /** update data of the table: "control_ai_review_criteria" */
  update_control_ai_review_criteria?: Maybe<Control_Ai_Review_Criteria_Mutation_Response>;
  /** update single row of the table: "control_ai_review_criteria" */
  update_control_ai_review_criteria_by_pk?: Maybe<Control_Ai_Review_Criteria>;
  /** update multiples rows of table: "control_ai_review_criteria" */
  update_control_ai_review_criteria_many?: Maybe<
    Array<Maybe<Control_Ai_Review_Criteria_Mutation_Response>>
  >;
  /** update data of the table: "control_ai_review_runs" */
  update_control_ai_review_runs?: Maybe<Control_Ai_Review_Runs_Mutation_Response>;
  /** update single row of the table: "control_ai_review_runs" */
  update_control_ai_review_runs_by_pk?: Maybe<Control_Ai_Review_Runs>;
  /** update multiples rows of table: "control_ai_review_runs" */
  update_control_ai_review_runs_many?: Maybe<
    Array<Maybe<Control_Ai_Review_Runs_Mutation_Response>>
  >;
  /** update data of the table: "control_ai_review_status" */
  update_control_ai_review_status?: Maybe<Control_Ai_Review_Status_Mutation_Response>;
  /** update single row of the table: "control_ai_review_status" */
  update_control_ai_review_status_by_pk?: Maybe<Control_Ai_Review_Status>;
  /** update multiples rows of table: "control_ai_review_status" */
  update_control_ai_review_status_many?: Maybe<
    Array<Maybe<Control_Ai_Review_Status_Mutation_Response>>
  >;
  /** update data of the table: "control_categories" */
  update_control_categories?: Maybe<Control_Categories_Mutation_Response>;
  /** update single row of the table: "control_categories" */
  update_control_categories_by_pk?: Maybe<Control_Categories>;
  /** update multiples rows of table: "control_categories" */
  update_control_categories_many?: Maybe<Array<Maybe<Control_Categories_Mutation_Response>>>;
  /** update data of the table: "control_criteria" */
  update_control_criteria?: Maybe<Control_Criteria_Mutation_Response>;
  /** update single row of the table: "control_criteria" */
  update_control_criteria_by_pk?: Maybe<Control_Criteria>;
  /** update multiples rows of table: "control_criteria" */
  update_control_criteria_many?: Maybe<Array<Maybe<Control_Criteria_Mutation_Response>>>;
  /** update data of the table: "control_evidences" */
  update_control_evidences?: Maybe<Control_Evidences_Mutation_Response>;
  /** update single row of the table: "control_evidences" */
  update_control_evidences_by_pk?: Maybe<Control_Evidences>;
  /** update multiples rows of table: "control_evidences" */
  update_control_evidences_many?: Maybe<Array<Maybe<Control_Evidences_Mutation_Response>>>;
  /** update data of the table: "control_findings" */
  update_control_findings?: Maybe<Control_Findings_Mutation_Response>;
  /** update single row of the table: "control_findings" */
  update_control_findings_by_pk?: Maybe<Control_Findings>;
  /** update multiples rows of table: "control_findings" */
  update_control_findings_many?: Maybe<Array<Maybe<Control_Findings_Mutation_Response>>>;
  /** update data of the table: "control_frequencies" */
  update_control_frequencies?: Maybe<Control_Frequencies_Mutation_Response>;
  /** update single row of the table: "control_frequencies" */
  update_control_frequencies_by_pk?: Maybe<Control_Frequencies>;
  /** update multiples rows of table: "control_frequencies" */
  update_control_frequencies_many?: Maybe<Array<Maybe<Control_Frequencies_Mutation_Response>>>;
  /** update data of the table: "control_groups" */
  update_control_groups?: Maybe<Control_Groups_Mutation_Response>;
  /** update single row of the table: "control_groups" */
  update_control_groups_by_pk?: Maybe<Control_Groups>;
  /** update multiples rows of table: "control_groups" */
  update_control_groups_many?: Maybe<Array<Maybe<Control_Groups_Mutation_Response>>>;
  /** update data of the table: "control_priorities" */
  update_control_priorities?: Maybe<Control_Priorities_Mutation_Response>;
  /** update single row of the table: "control_priorities" */
  update_control_priorities_by_pk?: Maybe<Control_Priorities>;
  /** update multiples rows of table: "control_priorities" */
  update_control_priorities_many?: Maybe<Array<Maybe<Control_Priorities_Mutation_Response>>>;
  /** update data of the table: "control_tags" */
  update_control_tags?: Maybe<Control_Tags_Mutation_Response>;
  /** update single row of the table: "control_tags" */
  update_control_tags_by_pk?: Maybe<Control_Tags>;
  /** update multiples rows of table: "control_tags" */
  update_control_tags_many?: Maybe<Array<Maybe<Control_Tags_Mutation_Response>>>;
  /** update data of the table: "control_tasks" */
  update_control_tasks?: Maybe<Control_Tasks_Mutation_Response>;
  /** update single row of the table: "control_tasks" */
  update_control_tasks_by_pk?: Maybe<Control_Tasks>;
  /** update multiples rows of table: "control_tasks" */
  update_control_tasks_many?: Maybe<Array<Maybe<Control_Tasks_Mutation_Response>>>;
  /** update data of the table: "controls" */
  update_controls?: Maybe<Controls_Mutation_Response>;
  /** update single row of the table: "controls" */
  update_controls_by_pk?: Maybe<Controls>;
  /** update data of the table: "controls_history_stats" */
  update_controls_history_stats?: Maybe<Controls_History_Stats_Mutation_Response>;
  /** update single row of the table: "controls_history_stats" */
  update_controls_history_stats_by_pk?: Maybe<Controls_History_Stats>;
  /** update multiples rows of table: "controls_history_stats" */
  update_controls_history_stats_many?: Maybe<
    Array<Maybe<Controls_History_Stats_Mutation_Response>>
  >;
  /** update multiples rows of table: "controls" */
  update_controls_many?: Maybe<Array<Maybe<Controls_Mutation_Response>>>;
  /** update data of the table: "criteria" */
  update_criteria?: Maybe<Criteria_Mutation_Response>;
  /** update single row of the table: "criteria" */
  update_criteria_by_pk?: Maybe<Criteria>;
  /** update multiples rows of table: "criteria" */
  update_criteria_many?: Maybe<Array<Maybe<Criteria_Mutation_Response>>>;
  /** update data of the table: "date_field_values" */
  update_date_field_values?: Maybe<Date_Field_Values_Mutation_Response>;
  /** update single row of the table: "date_field_values" */
  update_date_field_values_by_pk?: Maybe<Date_Field_Values>;
  /** update multiples rows of table: "date_field_values" */
  update_date_field_values_many?: Maybe<Array<Maybe<Date_Field_Values_Mutation_Response>>>;
  /** update data of the table: "email_field_values" */
  update_email_field_values?: Maybe<Email_Field_Values_Mutation_Response>;
  /** update single row of the table: "email_field_values" */
  update_email_field_values_by_pk?: Maybe<Email_Field_Values>;
  /** update multiples rows of table: "email_field_values" */
  update_email_field_values_many?: Maybe<Array<Maybe<Email_Field_Values_Mutation_Response>>>;
  /** update data of the table: "evidence_acl" */
  update_evidence_acl?: Maybe<Evidence_Acl_Mutation_Response>;
  /** update single row of the table: "evidence_acl" */
  update_evidence_acl_by_pk?: Maybe<Evidence_Acl>;
  /** update multiples rows of table: "evidence_acl" */
  update_evidence_acl_many?: Maybe<Array<Maybe<Evidence_Acl_Mutation_Response>>>;
  /** update data of the table: "evidence_integrations" */
  update_evidence_integrations?: Maybe<Evidence_Integrations_Mutation_Response>;
  /** update single row of the table: "evidence_integrations" */
  update_evidence_integrations_by_pk?: Maybe<Evidence_Integrations>;
  /** update multiples rows of table: "evidence_integrations" */
  update_evidence_integrations_many?: Maybe<Array<Maybe<Evidence_Integrations_Mutation_Response>>>;
  /** update data of the table: "evidence_policies" */
  update_evidence_policies?: Maybe<Evidence_Policies_Mutation_Response>;
  /** update single row of the table: "evidence_policies" */
  update_evidence_policies_by_pk?: Maybe<Evidence_Policies>;
  /** update multiples rows of table: "evidence_policies" */
  update_evidence_policies_many?: Maybe<Array<Maybe<Evidence_Policies_Mutation_Response>>>;
  /** update data of the table: "evidence_tags" */
  update_evidence_tags?: Maybe<Evidence_Tags_Mutation_Response>;
  /** update single row of the table: "evidence_tags" */
  update_evidence_tags_by_pk?: Maybe<Evidence_Tags>;
  /** update multiples rows of table: "evidence_tags" */
  update_evidence_tags_many?: Maybe<Array<Maybe<Evidence_Tags_Mutation_Response>>>;
  /** update data of the table: "evidence_version_file" */
  update_evidence_version_file?: Maybe<Evidence_Version_File_Mutation_Response>;
  /** update single row of the table: "evidence_version_file" */
  update_evidence_version_file_by_pk?: Maybe<Evidence_Version_File>;
  /** update multiples rows of table: "evidence_version_file" */
  update_evidence_version_file_many?: Maybe<Array<Maybe<Evidence_Version_File_Mutation_Response>>>;
  /** update data of the table: "evidence_versions" */
  update_evidence_versions?: Maybe<Evidence_Versions_Mutation_Response>;
  /** update single row of the table: "evidence_versions" */
  update_evidence_versions_by_pk?: Maybe<Evidence_Versions>;
  /** update multiples rows of table: "evidence_versions" */
  update_evidence_versions_many?: Maybe<Array<Maybe<Evidence_Versions_Mutation_Response>>>;
  /** update data of the table: "evidences" */
  update_evidences?: Maybe<Evidences_Mutation_Response>;
  /** update single row of the table: "evidences" */
  update_evidences_by_pk?: Maybe<Evidences>;
  /** update multiples rows of table: "evidences" */
  update_evidences_many?: Maybe<Array<Maybe<Evidences_Mutation_Response>>>;
  /** update data of the table: "field_configs" */
  update_field_configs?: Maybe<Field_Configs_Mutation_Response>;
  /** update single row of the table: "field_configs" */
  update_field_configs_by_pk?: Maybe<Field_Configs>;
  /** update multiples rows of table: "field_configs" */
  update_field_configs_many?: Maybe<Array<Maybe<Field_Configs_Mutation_Response>>>;
  /** update data of the table: "field_entities" */
  update_field_entities?: Maybe<Field_Entities_Mutation_Response>;
  /** update single row of the table: "field_entities" */
  update_field_entities_by_pk?: Maybe<Field_Entities>;
  /** update multiples rows of table: "field_entities" */
  update_field_entities_many?: Maybe<Array<Maybe<Field_Entities_Mutation_Response>>>;
  /** update data of the table: "field_types" */
  update_field_types?: Maybe<Field_Types_Mutation_Response>;
  /** update single row of the table: "field_types" */
  update_field_types_by_pk?: Maybe<Field_Types>;
  /** update multiples rows of table: "field_types" */
  update_field_types_many?: Maybe<Array<Maybe<Field_Types_Mutation_Response>>>;
  /** update data of the table: "field_values" */
  update_field_values?: Maybe<Field_Values_Mutation_Response>;
  /** update single row of the table: "field_values" */
  update_field_values_by_pk?: Maybe<Field_Values>;
  /** update multiples rows of table: "field_values" */
  update_field_values_many?: Maybe<Array<Maybe<Field_Values_Mutation_Response>>>;
  /** update multiples rows of table: "storage.files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "finding_causes" */
  update_finding_causes?: Maybe<Finding_Causes_Mutation_Response>;
  /** update single row of the table: "finding_causes" */
  update_finding_causes_by_pk?: Maybe<Finding_Causes>;
  /** update multiples rows of table: "finding_causes" */
  update_finding_causes_many?: Maybe<Array<Maybe<Finding_Causes_Mutation_Response>>>;
  /** update data of the table: "finding_types" */
  update_finding_types?: Maybe<Finding_Types_Mutation_Response>;
  /** update single row of the table: "finding_types" */
  update_finding_types_by_pk?: Maybe<Finding_Types>;
  /** update multiples rows of table: "finding_types" */
  update_finding_types_many?: Maybe<Array<Maybe<Finding_Types_Mutation_Response>>>;
  /** update data of the table: "forms" */
  update_forms?: Maybe<Forms_Mutation_Response>;
  /** update single row of the table: "forms" */
  update_forms_by_pk?: Maybe<Forms>;
  /** update multiples rows of table: "forms" */
  update_forms_many?: Maybe<Array<Maybe<Forms_Mutation_Response>>>;
  /** update data of the table: "frameworks" */
  update_frameworks?: Maybe<Frameworks_Mutation_Response>;
  /** update single row of the table: "frameworks" */
  update_frameworks_by_pk?: Maybe<Frameworks>;
  /** update data of the table: "frameworks_controls" */
  update_frameworks_controls?: Maybe<Frameworks_Controls_Mutation_Response>;
  /** update single row of the table: "frameworks_controls" */
  update_frameworks_controls_by_pk?: Maybe<Frameworks_Controls>;
  /** update multiples rows of table: "frameworks_controls" */
  update_frameworks_controls_many?: Maybe<Array<Maybe<Frameworks_Controls_Mutation_Response>>>;
  /** update multiples rows of table: "frameworks" */
  update_frameworks_many?: Maybe<Array<Maybe<Frameworks_Mutation_Response>>>;
  /** update data of the table: "frequencies" */
  update_frequencies?: Maybe<Frequencies_Mutation_Response>;
  /** update single row of the table: "frequencies" */
  update_frequencies_by_pk?: Maybe<Frequencies>;
  /** update multiples rows of table: "frequencies" */
  update_frequencies_many?: Maybe<Array<Maybe<Frequencies_Mutation_Response>>>;
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>;
  /** update multiples rows of table: "groups" */
  update_groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>;
  /** update data of the table: "integration_names" */
  update_integration_names?: Maybe<Integration_Names_Mutation_Response>;
  /** update single row of the table: "integration_names" */
  update_integration_names_by_pk?: Maybe<Integration_Names>;
  /** update multiples rows of table: "integration_names" */
  update_integration_names_many?: Maybe<Array<Maybe<Integration_Names_Mutation_Response>>>;
  /** update data of the table: "integration_runs" */
  update_integration_runs?: Maybe<Integration_Runs_Mutation_Response>;
  /** update single row of the table: "integration_runs" */
  update_integration_runs_by_pk?: Maybe<Integration_Runs>;
  /** update multiples rows of table: "integration_runs" */
  update_integration_runs_many?: Maybe<Array<Maybe<Integration_Runs_Mutation_Response>>>;
  /** update data of the table: "integrations" */
  update_integrations?: Maybe<Integrations_Mutation_Response>;
  /** update single row of the table: "integrations" */
  update_integrations_by_pk?: Maybe<Integrations>;
  /** update multiples rows of table: "integrations" */
  update_integrations_many?: Maybe<Array<Maybe<Integrations_Mutation_Response>>>;
  /** update data of the table: "invitations" */
  update_invitations?: Maybe<Invitations_Mutation_Response>;
  /** update single row of the table: "invitations" */
  update_invitations_by_pk?: Maybe<Invitations>;
  /** update multiples rows of table: "invitations" */
  update_invitations_many?: Maybe<Array<Maybe<Invitations_Mutation_Response>>>;
  /** update data of the table: "notification_delivery_types" */
  update_notification_delivery_types?: Maybe<Notification_Delivery_Types_Mutation_Response>;
  /** update single row of the table: "notification_delivery_types" */
  update_notification_delivery_types_by_pk?: Maybe<Notification_Delivery_Types>;
  /** update multiples rows of table: "notification_delivery_types" */
  update_notification_delivery_types_many?: Maybe<
    Array<Maybe<Notification_Delivery_Types_Mutation_Response>>
  >;
  /** update data of the table: "notification_disabled_settings" */
  update_notification_disabled_settings?: Maybe<Notification_Disabled_Settings_Mutation_Response>;
  /** update single row of the table: "notification_disabled_settings" */
  update_notification_disabled_settings_by_pk?: Maybe<Notification_Disabled_Settings>;
  /** update multiples rows of table: "notification_disabled_settings" */
  update_notification_disabled_settings_many?: Maybe<
    Array<Maybe<Notification_Disabled_Settings_Mutation_Response>>
  >;
  /** update data of the table: "notification_types" */
  update_notification_types?: Maybe<Notification_Types_Mutation_Response>;
  /** update single row of the table: "notification_types" */
  update_notification_types_by_pk?: Maybe<Notification_Types>;
  /** update multiples rows of table: "notification_types" */
  update_notification_types_many?: Maybe<Array<Maybe<Notification_Types_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "number_field_values" */
  update_number_field_values?: Maybe<Number_Field_Values_Mutation_Response>;
  /** update single row of the table: "number_field_values" */
  update_number_field_values_by_pk?: Maybe<Number_Field_Values>;
  /** update multiples rows of table: "number_field_values" */
  update_number_field_values_many?: Maybe<Array<Maybe<Number_Field_Values_Mutation_Response>>>;
  /** update data of the table: "organization_evidence_tags" */
  update_organization_evidence_tags?: Maybe<Organization_Evidence_Tags_Mutation_Response>;
  /** update single row of the table: "organization_evidence_tags" */
  update_organization_evidence_tags_by_pk?: Maybe<Organization_Evidence_Tags>;
  /** update multiples rows of table: "organization_evidence_tags" */
  update_organization_evidence_tags_many?: Maybe<
    Array<Maybe<Organization_Evidence_Tags_Mutation_Response>>
  >;
  /** update data of the table: "organization_integrations" */
  update_organization_integrations?: Maybe<Organization_Integrations_Mutation_Response>;
  /** update single row of the table: "organization_integrations" */
  update_organization_integrations_by_pk?: Maybe<Organization_Integrations>;
  /** update multiples rows of table: "organization_integrations" */
  update_organization_integrations_many?: Maybe<
    Array<Maybe<Organization_Integrations_Mutation_Response>>
  >;
  /** update data of the table: "organization_risk_categories" */
  update_organization_risk_categories?: Maybe<Organization_Risk_Categories_Mutation_Response>;
  /** update single row of the table: "organization_risk_categories" */
  update_organization_risk_categories_by_pk?: Maybe<Organization_Risk_Categories>;
  /** update multiples rows of table: "organization_risk_categories" */
  update_organization_risk_categories_many?: Maybe<
    Array<Maybe<Organization_Risk_Categories_Mutation_Response>>
  >;
  /** update data of the table: "organization_risk_tags" */
  update_organization_risk_tags?: Maybe<Organization_Risk_Tags_Mutation_Response>;
  /** update single row of the table: "organization_risk_tags" */
  update_organization_risk_tags_by_pk?: Maybe<Organization_Risk_Tags>;
  /** update multiples rows of table: "organization_risk_tags" */
  update_organization_risk_tags_many?: Maybe<
    Array<Maybe<Organization_Risk_Tags_Mutation_Response>>
  >;
  /** update data of the table: "organization_template_frameworks" */
  update_organization_template_frameworks?: Maybe<Organization_Template_Frameworks_Mutation_Response>;
  /** update single row of the table: "organization_template_frameworks" */
  update_organization_template_frameworks_by_pk?: Maybe<Organization_Template_Frameworks>;
  /** update multiples rows of table: "organization_template_frameworks" */
  update_organization_template_frameworks_many?: Maybe<
    Array<Maybe<Organization_Template_Frameworks_Mutation_Response>>
  >;
  /** update data of the table: "organization_tenants" */
  update_organization_tenants?: Maybe<Organization_Tenants_Mutation_Response>;
  /** update single row of the table: "organization_tenants" */
  update_organization_tenants_by_pk?: Maybe<Organization_Tenants>;
  /** update multiples rows of table: "organization_tenants" */
  update_organization_tenants_many?: Maybe<Array<Maybe<Organization_Tenants_Mutation_Response>>>;
  /** update data of the table: "organization_users" */
  update_organization_users?: Maybe<Organization_Users_Mutation_Response>;
  /** update single row of the table: "organization_users" */
  update_organization_users_by_pk?: Maybe<Organization_Users>;
  /** update multiples rows of table: "organization_users" */
  update_organization_users_many?: Maybe<Array<Maybe<Organization_Users_Mutation_Response>>>;
  /** update data of the table: "organization_vendor_risk_levels" */
  update_organization_vendor_risk_levels?: Maybe<Organization_Vendor_Risk_Levels_Mutation_Response>;
  /** update single row of the table: "organization_vendor_risk_levels" */
  update_organization_vendor_risk_levels_by_pk?: Maybe<Organization_Vendor_Risk_Levels>;
  /** update multiples rows of table: "organization_vendor_risk_levels" */
  update_organization_vendor_risk_levels_many?: Maybe<
    Array<Maybe<Organization_Vendor_Risk_Levels_Mutation_Response>>
  >;
  /** update data of the table: "organization_vendor_tiers" */
  update_organization_vendor_tiers?: Maybe<Organization_Vendor_Tiers_Mutation_Response>;
  /** update single row of the table: "organization_vendor_tiers" */
  update_organization_vendor_tiers_by_pk?: Maybe<Organization_Vendor_Tiers>;
  /** update multiples rows of table: "organization_vendor_tiers" */
  update_organization_vendor_tiers_many?: Maybe<
    Array<Maybe<Organization_Vendor_Tiers_Mutation_Response>>
  >;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update multiples rows of table: "organizations" */
  update_organizations_many?: Maybe<Array<Maybe<Organizations_Mutation_Response>>>;
  /** update data of the table: "permissions" */
  update_permissions?: Maybe<Permissions_Mutation_Response>;
  /** update single row of the table: "permissions" */
  update_permissions_by_pk?: Maybe<Permissions>;
  /** update multiples rows of table: "permissions" */
  update_permissions_many?: Maybe<Array<Maybe<Permissions_Mutation_Response>>>;
  /** update data of the table: "policies" */
  update_policies?: Maybe<Policies_Mutation_Response>;
  /** update single row of the table: "policies" */
  update_policies_by_pk?: Maybe<Policies>;
  /** update multiples rows of table: "policies" */
  update_policies_many?: Maybe<Array<Maybe<Policies_Mutation_Response>>>;
  /** update data of the table: "policy_acknowledgement_statuses" */
  update_policy_acknowledgement_statuses?: Maybe<Policy_Acknowledgement_Statuses_Mutation_Response>;
  /** update single row of the table: "policy_acknowledgement_statuses" */
  update_policy_acknowledgement_statuses_by_pk?: Maybe<Policy_Acknowledgement_Statuses>;
  /** update multiples rows of table: "policy_acknowledgement_statuses" */
  update_policy_acknowledgement_statuses_many?: Maybe<
    Array<Maybe<Policy_Acknowledgement_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "policy_acknowledgement_users" */
  update_policy_acknowledgement_users?: Maybe<Policy_Acknowledgement_Users_Mutation_Response>;
  /** update single row of the table: "policy_acknowledgement_users" */
  update_policy_acknowledgement_users_by_pk?: Maybe<Policy_Acknowledgement_Users>;
  /** update multiples rows of table: "policy_acknowledgement_users" */
  update_policy_acknowledgement_users_many?: Maybe<
    Array<Maybe<Policy_Acknowledgement_Users_Mutation_Response>>
  >;
  /** update data of the table: "policy_acknowledgements" */
  update_policy_acknowledgements?: Maybe<Policy_Acknowledgements_Mutation_Response>;
  /** update single row of the table: "policy_acknowledgements" */
  update_policy_acknowledgements_by_pk?: Maybe<Policy_Acknowledgements>;
  /** update multiples rows of table: "policy_acknowledgements" */
  update_policy_acknowledgements_many?: Maybe<
    Array<Maybe<Policy_Acknowledgements_Mutation_Response>>
  >;
  /** update data of the table: "policy_approval_statuses" */
  update_policy_approval_statuses?: Maybe<Policy_Approval_Statuses_Mutation_Response>;
  /** update single row of the table: "policy_approval_statuses" */
  update_policy_approval_statuses_by_pk?: Maybe<Policy_Approval_Statuses>;
  /** update multiples rows of table: "policy_approval_statuses" */
  update_policy_approval_statuses_many?: Maybe<
    Array<Maybe<Policy_Approval_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "policy_approval_users" */
  update_policy_approval_users?: Maybe<Policy_Approval_Users_Mutation_Response>;
  /** update single row of the table: "policy_approval_users" */
  update_policy_approval_users_by_pk?: Maybe<Policy_Approval_Users>;
  /** update multiples rows of table: "policy_approval_users" */
  update_policy_approval_users_many?: Maybe<Array<Maybe<Policy_Approval_Users_Mutation_Response>>>;
  /** update data of the table: "policy_approvals" */
  update_policy_approvals?: Maybe<Policy_Approvals_Mutation_Response>;
  /** update single row of the table: "policy_approvals" */
  update_policy_approvals_by_pk?: Maybe<Policy_Approvals>;
  /** update multiples rows of table: "policy_approvals" */
  update_policy_approvals_many?: Maybe<Array<Maybe<Policy_Approvals_Mutation_Response>>>;
  /** update data of the table: "policy_approvers" */
  update_policy_approvers?: Maybe<Policy_Approvers_Mutation_Response>;
  /** update single row of the table: "policy_approvers" */
  update_policy_approvers_by_pk?: Maybe<Policy_Approvers>;
  /** update multiples rows of table: "policy_approvers" */
  update_policy_approvers_many?: Maybe<Array<Maybe<Policy_Approvers_Mutation_Response>>>;
  /** update data of the table: "policy_statuses" */
  update_policy_statuses?: Maybe<Policy_Statuses_Mutation_Response>;
  /** update single row of the table: "policy_statuses" */
  update_policy_statuses_by_pk?: Maybe<Policy_Statuses>;
  /** update multiples rows of table: "policy_statuses" */
  update_policy_statuses_many?: Maybe<Array<Maybe<Policy_Statuses_Mutation_Response>>>;
  /** update data of the table: "policy_tasks" */
  update_policy_tasks?: Maybe<Policy_Tasks_Mutation_Response>;
  /** update single row of the table: "policy_tasks" */
  update_policy_tasks_by_pk?: Maybe<Policy_Tasks>;
  /** update multiples rows of table: "policy_tasks" */
  update_policy_tasks_many?: Maybe<Array<Maybe<Policy_Tasks_Mutation_Response>>>;
  /** update data of the table: "policy_types" */
  update_policy_types?: Maybe<Policy_Types_Mutation_Response>;
  /** update single row of the table: "policy_types" */
  update_policy_types_by_pk?: Maybe<Policy_Types>;
  /** update multiples rows of table: "policy_types" */
  update_policy_types_many?: Maybe<Array<Maybe<Policy_Types_Mutation_Response>>>;
  update_policy_version_content?: Maybe<GeneralActionOutput>;
  /** update data of the table: "policy_version_statuses" */
  update_policy_version_statuses?: Maybe<Policy_Version_Statuses_Mutation_Response>;
  /** update single row of the table: "policy_version_statuses" */
  update_policy_version_statuses_by_pk?: Maybe<Policy_Version_Statuses>;
  /** update multiples rows of table: "policy_version_statuses" */
  update_policy_version_statuses_many?: Maybe<
    Array<Maybe<Policy_Version_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "policy_versions" */
  update_policy_versions?: Maybe<Policy_Versions_Mutation_Response>;
  /** update single row of the table: "policy_versions" */
  update_policy_versions_by_pk?: Maybe<Policy_Versions>;
  /** update multiples rows of table: "policy_versions" */
  update_policy_versions_many?: Maybe<Array<Maybe<Policy_Versions_Mutation_Response>>>;
  /** update data of the table: "program_controls" */
  update_program_controls?: Maybe<Program_Controls_Mutation_Response>;
  /** update single row of the table: "program_controls" */
  update_program_controls_by_pk?: Maybe<Program_Controls>;
  /** update multiples rows of table: "program_controls" */
  update_program_controls_many?: Maybe<Array<Maybe<Program_Controls_Mutation_Response>>>;
  /** update data of the table: "program_field_configs" */
  update_program_field_configs?: Maybe<Program_Field_Configs_Mutation_Response>;
  /** update single row of the table: "program_field_configs" */
  update_program_field_configs_by_pk?: Maybe<Program_Field_Configs>;
  /** update multiples rows of table: "program_field_configs" */
  update_program_field_configs_many?: Maybe<Array<Maybe<Program_Field_Configs_Mutation_Response>>>;
  /** update data of the table: "programs" */
  update_programs?: Maybe<Programs_Mutation_Response>;
  /** update single row of the table: "programs" */
  update_programs_by_pk?: Maybe<Programs>;
  /** update multiples rows of table: "programs" */
  update_programs_many?: Maybe<Array<Maybe<Programs_Mutation_Response>>>;
  /** update data of the table: "questionnaire_uploads" */
  update_questionnaire_uploads?: Maybe<Questionnaire_Uploads_Mutation_Response>;
  /** update single row of the table: "questionnaire_uploads" */
  update_questionnaire_uploads_by_pk?: Maybe<Questionnaire_Uploads>;
  /** update multiples rows of table: "questionnaire_uploads" */
  update_questionnaire_uploads_many?: Maybe<Array<Maybe<Questionnaire_Uploads_Mutation_Response>>>;
  /** update data of the table: "questionnaires" */
  update_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** update single row of the table: "questionnaires" */
  update_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** update multiples rows of table: "questionnaires" */
  update_questionnaires_many?: Maybe<Array<Maybe<Questionnaires_Mutation_Response>>>;
  /** update data of the table: "report_types" */
  update_report_types?: Maybe<Report_Types_Mutation_Response>;
  /** update single row of the table: "report_types" */
  update_report_types_by_pk?: Maybe<Report_Types>;
  /** update multiples rows of table: "report_types" */
  update_report_types_many?: Maybe<Array<Maybe<Report_Types_Mutation_Response>>>;
  /** update data of the table: "reports" */
  update_reports?: Maybe<Reports_Mutation_Response>;
  /** update single row of the table: "reports" */
  update_reports_by_pk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  update_reports_many?: Maybe<Array<Maybe<Reports_Mutation_Response>>>;
  /** update data of the table: "review_files" */
  update_review_files?: Maybe<Review_Files_Mutation_Response>;
  /** update single row of the table: "review_files" */
  update_review_files_by_pk?: Maybe<Review_Files>;
  /** update multiples rows of table: "review_files" */
  update_review_files_many?: Maybe<Array<Maybe<Review_Files_Mutation_Response>>>;
  /** update data of the table: "reviews" */
  update_reviews?: Maybe<Reviews_Mutation_Response>;
  /** update single row of the table: "reviews" */
  update_reviews_by_pk?: Maybe<Reviews>;
  /** update multiples rows of table: "reviews" */
  update_reviews_many?: Maybe<Array<Maybe<Reviews_Mutation_Response>>>;
  /** update data of the table: "risk_categories" */
  update_risk_categories?: Maybe<Risk_Categories_Mutation_Response>;
  /** update single row of the table: "risk_categories" */
  update_risk_categories_by_pk?: Maybe<Risk_Categories>;
  /** update multiples rows of table: "risk_categories" */
  update_risk_categories_many?: Maybe<Array<Maybe<Risk_Categories_Mutation_Response>>>;
  /** update data of the table: "risk_controls" */
  update_risk_controls?: Maybe<Risk_Controls_Mutation_Response>;
  /** update single row of the table: "risk_controls" */
  update_risk_controls_by_pk?: Maybe<Risk_Controls>;
  /** update multiples rows of table: "risk_controls" */
  update_risk_controls_many?: Maybe<Array<Maybe<Risk_Controls_Mutation_Response>>>;
  /** update data of the table: "risk_documents" */
  update_risk_documents?: Maybe<Risk_Documents_Mutation_Response>;
  /** update single row of the table: "risk_documents" */
  update_risk_documents_by_pk?: Maybe<Risk_Documents>;
  /** update multiples rows of table: "risk_documents" */
  update_risk_documents_many?: Maybe<Array<Maybe<Risk_Documents_Mutation_Response>>>;
  /** update data of the table: "risk_impacts" */
  update_risk_impacts?: Maybe<Risk_Impacts_Mutation_Response>;
  /** update single row of the table: "risk_impacts" */
  update_risk_impacts_by_pk?: Maybe<Risk_Impacts>;
  /** update multiples rows of table: "risk_impacts" */
  update_risk_impacts_many?: Maybe<Array<Maybe<Risk_Impacts_Mutation_Response>>>;
  /** update data of the table: "risk_inherent_levels" */
  update_risk_inherent_levels?: Maybe<Risk_Inherent_Levels_Mutation_Response>;
  /** update single row of the table: "risk_inherent_levels" */
  update_risk_inherent_levels_by_pk?: Maybe<Risk_Inherent_Levels>;
  /** update multiples rows of table: "risk_inherent_levels" */
  update_risk_inherent_levels_many?: Maybe<Array<Maybe<Risk_Inherent_Levels_Mutation_Response>>>;
  /** update data of the table: "risk_levels" */
  update_risk_levels?: Maybe<Risk_Levels_Mutation_Response>;
  /** update single row of the table: "risk_levels" */
  update_risk_levels_by_pk?: Maybe<Risk_Levels>;
  /** update multiples rows of table: "risk_levels" */
  update_risk_levels_many?: Maybe<Array<Maybe<Risk_Levels_Mutation_Response>>>;
  /** update data of the table: "risk_likelihoods" */
  update_risk_likelihoods?: Maybe<Risk_Likelihoods_Mutation_Response>;
  /** update single row of the table: "risk_likelihoods" */
  update_risk_likelihoods_by_pk?: Maybe<Risk_Likelihoods>;
  /** update multiples rows of table: "risk_likelihoods" */
  update_risk_likelihoods_many?: Maybe<Array<Maybe<Risk_Likelihoods_Mutation_Response>>>;
  /** update data of the table: "risk_residual_levels" */
  update_risk_residual_levels?: Maybe<Risk_Residual_Levels_Mutation_Response>;
  /** update single row of the table: "risk_residual_levels" */
  update_risk_residual_levels_by_pk?: Maybe<Risk_Residual_Levels>;
  /** update multiples rows of table: "risk_residual_levels" */
  update_risk_residual_levels_many?: Maybe<Array<Maybe<Risk_Residual_Levels_Mutation_Response>>>;
  /** update data of the table: "risk_tags" */
  update_risk_tags?: Maybe<Risk_Tags_Mutation_Response>;
  /** update single row of the table: "risk_tags" */
  update_risk_tags_by_pk?: Maybe<Risk_Tags>;
  /** update multiples rows of table: "risk_tags" */
  update_risk_tags_many?: Maybe<Array<Maybe<Risk_Tags_Mutation_Response>>>;
  /** update data of the table: "risk_tasks" */
  update_risk_tasks?: Maybe<Risk_Tasks_Mutation_Response>;
  /** update single row of the table: "risk_tasks" */
  update_risk_tasks_by_pk?: Maybe<Risk_Tasks>;
  /** update multiples rows of table: "risk_tasks" */
  update_risk_tasks_many?: Maybe<Array<Maybe<Risk_Tasks_Mutation_Response>>>;
  /** update data of the table: "risks" */
  update_risks?: Maybe<Risks_Mutation_Response>;
  /** update single row of the table: "risks" */
  update_risks_by_pk?: Maybe<Risks>;
  /** update multiples rows of table: "risks" */
  update_risks_many?: Maybe<Array<Maybe<Risks_Mutation_Response>>>;
  /** update data of the table: "role_permissions" */
  update_role_permissions?: Maybe<Role_Permissions_Mutation_Response>;
  /** update single row of the table: "role_permissions" */
  update_role_permissions_by_pk?: Maybe<Role_Permissions>;
  /** update multiples rows of table: "role_permissions" */
  update_role_permissions_many?: Maybe<Array<Maybe<Role_Permissions_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "select_field_configs" */
  update_select_field_configs?: Maybe<Select_Field_Configs_Mutation_Response>;
  /** update single row of the table: "select_field_configs" */
  update_select_field_configs_by_pk?: Maybe<Select_Field_Configs>;
  /** update multiples rows of table: "select_field_configs" */
  update_select_field_configs_many?: Maybe<Array<Maybe<Select_Field_Configs_Mutation_Response>>>;
  /** update data of the table: "select_field_options" */
  update_select_field_options?: Maybe<Select_Field_Options_Mutation_Response>;
  /** update single row of the table: "select_field_options" */
  update_select_field_options_by_pk?: Maybe<Select_Field_Options>;
  /** update multiples rows of table: "select_field_options" */
  update_select_field_options_many?: Maybe<Array<Maybe<Select_Field_Options_Mutation_Response>>>;
  /** update data of the table: "select_field_values" */
  update_select_field_values?: Maybe<Select_Field_Values_Mutation_Response>;
  /** update single row of the table: "select_field_values" */
  update_select_field_values_by_pk?: Maybe<Select_Field_Values>;
  /** update multiples rows of table: "select_field_values" */
  update_select_field_values_many?: Maybe<Array<Maybe<Select_Field_Values_Mutation_Response>>>;
  /** update data of the table: "system_roles" */
  update_system_roles?: Maybe<System_Roles_Mutation_Response>;
  /** update single row of the table: "system_roles" */
  update_system_roles_by_pk?: Maybe<System_Roles>;
  /** update multiples rows of table: "system_roles" */
  update_system_roles_many?: Maybe<Array<Maybe<System_Roles_Mutation_Response>>>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  update_tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "task_owners" */
  update_task_owners?: Maybe<Task_Owners_Mutation_Response>;
  /** update single row of the table: "task_owners" */
  update_task_owners_by_pk?: Maybe<Task_Owners>;
  /** update multiples rows of table: "task_owners" */
  update_task_owners_many?: Maybe<Array<Maybe<Task_Owners_Mutation_Response>>>;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update multiples rows of table: "tasks" */
  update_tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "template_control_ai_review_criteria" */
  update_template_control_ai_review_criteria?: Maybe<Template_Control_Ai_Review_Criteria_Mutation_Response>;
  /** update single row of the table: "template_control_ai_review_criteria" */
  update_template_control_ai_review_criteria_by_pk?: Maybe<Template_Control_Ai_Review_Criteria>;
  /** update multiples rows of table: "template_control_ai_review_criteria" */
  update_template_control_ai_review_criteria_many?: Maybe<
    Array<Maybe<Template_Control_Ai_Review_Criteria_Mutation_Response>>
  >;
  /** update data of the table: "template_controls" */
  update_template_controls?: Maybe<Template_Controls_Mutation_Response>;
  /** update single row of the table: "template_controls" */
  update_template_controls_by_pk?: Maybe<Template_Controls>;
  /** update multiples rows of table: "template_controls" */
  update_template_controls_many?: Maybe<Array<Maybe<Template_Controls_Mutation_Response>>>;
  /** update data of the table: "template_frameworks" */
  update_template_frameworks?: Maybe<Template_Frameworks_Mutation_Response>;
  /** update single row of the table: "template_frameworks" */
  update_template_frameworks_by_pk?: Maybe<Template_Frameworks>;
  /** update data of the table: "template_frameworks_controls" */
  update_template_frameworks_controls?: Maybe<Template_Frameworks_Controls_Mutation_Response>;
  /** update single row of the table: "template_frameworks_controls" */
  update_template_frameworks_controls_by_pk?: Maybe<Template_Frameworks_Controls>;
  /** update multiples rows of table: "template_frameworks_controls" */
  update_template_frameworks_controls_many?: Maybe<
    Array<Maybe<Template_Frameworks_Controls_Mutation_Response>>
  >;
  /** update multiples rows of table: "template_frameworks" */
  update_template_frameworks_many?: Maybe<Array<Maybe<Template_Frameworks_Mutation_Response>>>;
  /** update data of the table: "template_tasks" */
  update_template_tasks?: Maybe<Template_Tasks_Mutation_Response>;
  /** update single row of the table: "template_tasks" */
  update_template_tasks_by_pk?: Maybe<Template_Tasks>;
  /** update multiples rows of table: "template_tasks" */
  update_template_tasks_many?: Maybe<Array<Maybe<Template_Tasks_Mutation_Response>>>;
  /** update data of the table: "text_field_values" */
  update_text_field_values?: Maybe<Text_Field_Values_Mutation_Response>;
  /** update single row of the table: "text_field_values" */
  update_text_field_values_by_pk?: Maybe<Text_Field_Values>;
  /** update multiples rows of table: "text_field_values" */
  update_text_field_values_many?: Maybe<Array<Maybe<Text_Field_Values_Mutation_Response>>>;
  /** update data of the table: "treatment_plan" */
  update_treatment_plan?: Maybe<Treatment_Plan_Mutation_Response>;
  /** update single row of the table: "treatment_plan" */
  update_treatment_plan_by_pk?: Maybe<Treatment_Plan>;
  /** update multiples rows of table: "treatment_plan" */
  update_treatment_plan_many?: Maybe<Array<Maybe<Treatment_Plan_Mutation_Response>>>;
  /** update data of the table: "url_field_values" */
  update_url_field_values?: Maybe<Url_Field_Values_Mutation_Response>;
  /** update single row of the table: "url_field_values" */
  update_url_field_values_by_pk?: Maybe<Url_Field_Values>;
  /** update multiples rows of table: "url_field_values" */
  update_url_field_values_many?: Maybe<Array<Maybe<Url_Field_Values_Mutation_Response>>>;
  /** update data of the table: "user_notifications" */
  update_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** update single row of the table: "user_notifications" */
  update_user_notifications_by_pk?: Maybe<User_Notifications>;
  /** update multiples rows of table: "user_notifications" */
  update_user_notifications_many?: Maybe<Array<Maybe<User_Notifications_Mutation_Response>>>;
  /** update multiples rows of table: "auth.users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "vendor_approval_statuses" */
  update_vendor_approval_statuses?: Maybe<Vendor_Approval_Statuses_Mutation_Response>;
  /** update single row of the table: "vendor_approval_statuses" */
  update_vendor_approval_statuses_by_pk?: Maybe<Vendor_Approval_Statuses>;
  /** update multiples rows of table: "vendor_approval_statuses" */
  update_vendor_approval_statuses_many?: Maybe<
    Array<Maybe<Vendor_Approval_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "vendor_documents" */
  update_vendor_documents?: Maybe<Vendor_Documents_Mutation_Response>;
  /** update single row of the table: "vendor_documents" */
  update_vendor_documents_by_pk?: Maybe<Vendor_Documents>;
  /** update multiples rows of table: "vendor_documents" */
  update_vendor_documents_many?: Maybe<Array<Maybe<Vendor_Documents_Mutation_Response>>>;
  /** update data of the table: "vendor_findings" */
  update_vendor_findings?: Maybe<Vendor_Findings_Mutation_Response>;
  /** update single row of the table: "vendor_findings" */
  update_vendor_findings_by_pk?: Maybe<Vendor_Findings>;
  /** update multiples rows of table: "vendor_findings" */
  update_vendor_findings_many?: Maybe<Array<Maybe<Vendor_Findings_Mutation_Response>>>;
  /** update data of the table: "vendor_questionnaire_ai_review_assessment_sources" */
  update_vendor_questionnaire_ai_review_assessment_sources?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_ai_review_assessment_sources" */
  update_vendor_questionnaire_ai_review_assessment_sources_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** update multiples rows of table: "vendor_questionnaire_ai_review_assessment_sources" */
  update_vendor_questionnaire_ai_review_assessment_sources_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_ai_review_assessments" */
  update_vendor_questionnaire_ai_review_assessments?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_ai_review_assessments" */
  update_vendor_questionnaire_ai_review_assessments_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** update multiples rows of table: "vendor_questionnaire_ai_review_assessments" */
  update_vendor_questionnaire_ai_review_assessments_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Ai_Review_Assessments_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_ai_review_run_status" */
  update_vendor_questionnaire_ai_review_run_status?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_ai_review_run_status" */
  update_vendor_questionnaire_ai_review_run_status_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status>;
  /** update multiples rows of table: "vendor_questionnaire_ai_review_run_status" */
  update_vendor_questionnaire_ai_review_run_status_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Ai_Review_Run_Status_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_ai_review_runs" */
  update_vendor_questionnaire_ai_review_runs?: Maybe<Vendor_Questionnaire_Ai_Review_Runs_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_ai_review_runs" */
  update_vendor_questionnaire_ai_review_runs_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Runs>;
  /** update multiples rows of table: "vendor_questionnaire_ai_review_runs" */
  update_vendor_questionnaire_ai_review_runs_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Ai_Review_Runs_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_form_answer_statuses" */
  update_vendor_questionnaire_form_answer_statuses?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_form_answer_statuses" */
  update_vendor_questionnaire_form_answer_statuses_by_pk?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** update multiples rows of table: "vendor_questionnaire_form_answer_statuses" */
  update_vendor_questionnaire_form_answer_statuses_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Form_Answer_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_form_answers" */
  update_vendor_questionnaire_form_answers?: Maybe<Vendor_Questionnaire_Form_Answers_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_form_answers" */
  update_vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** update multiples rows of table: "vendor_questionnaire_form_answers" */
  update_vendor_questionnaire_form_answers_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Form_Answers_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_form_uploads" */
  update_vendor_questionnaire_form_uploads?: Maybe<Vendor_Questionnaire_Form_Uploads_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_form_uploads" */
  update_vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** update multiples rows of table: "vendor_questionnaire_form_uploads" */
  update_vendor_questionnaire_form_uploads_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Form_Uploads_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_forms" */
  update_vendor_questionnaire_forms?: Maybe<Vendor_Questionnaire_Forms_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_forms" */
  update_vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** update multiples rows of table: "vendor_questionnaire_forms" */
  update_vendor_questionnaire_forms_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Forms_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_statuses" */
  update_vendor_questionnaire_statuses?: Maybe<Vendor_Questionnaire_Statuses_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_statuses" */
  update_vendor_questionnaire_statuses_by_pk?: Maybe<Vendor_Questionnaire_Statuses>;
  /** update multiples rows of table: "vendor_questionnaire_statuses" */
  update_vendor_questionnaire_statuses_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Statuses_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaire_uploads" */
  update_vendor_questionnaire_uploads?: Maybe<Vendor_Questionnaire_Uploads_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaire_uploads" */
  update_vendor_questionnaire_uploads_by_pk?: Maybe<Vendor_Questionnaire_Uploads>;
  /** update multiples rows of table: "vendor_questionnaire_uploads" */
  update_vendor_questionnaire_uploads_many?: Maybe<
    Array<Maybe<Vendor_Questionnaire_Uploads_Mutation_Response>>
  >;
  /** update data of the table: "vendor_questionnaires" */
  update_vendor_questionnaires?: Maybe<Vendor_Questionnaires_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaires" */
  update_vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** update multiples rows of table: "vendor_questionnaires" */
  update_vendor_questionnaires_many?: Maybe<Array<Maybe<Vendor_Questionnaires_Mutation_Response>>>;
  /** update data of the table: "vendor_risks" */
  update_vendor_risks?: Maybe<Vendor_Risks_Mutation_Response>;
  /** update single row of the table: "vendor_risks" */
  update_vendor_risks_by_pk?: Maybe<Vendor_Risks>;
  /** update multiples rows of table: "vendor_risks" */
  update_vendor_risks_many?: Maybe<Array<Maybe<Vendor_Risks_Mutation_Response>>>;
  /** update data of the table: "vendor_tasks" */
  update_vendor_tasks?: Maybe<Vendor_Tasks_Mutation_Response>;
  /** update single row of the table: "vendor_tasks" */
  update_vendor_tasks_by_pk?: Maybe<Vendor_Tasks>;
  /** update multiples rows of table: "vendor_tasks" */
  update_vendor_tasks_many?: Maybe<Array<Maybe<Vendor_Tasks_Mutation_Response>>>;
  /** update data of the table: "vendors" */
  update_vendors?: Maybe<Vendors_Mutation_Response>;
  /** update single row of the table: "vendors" */
  update_vendors_by_pk?: Maybe<Vendors>;
  /** update multiples rows of table: "vendors" */
  update_vendors_many?: Maybe<Array<Maybe<Vendors_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootAi_Autocomplete_EntityArgs = {
  input: AiAutocompleteEntityInput;
};

/** mutation root */
export type Mutation_RootAi_Create_EntityArgs = {
  input: Scalars['AiCreateEntityInput']['input'];
};

/** mutation root */
export type Mutation_RootAi_Generate_Sparse_VectorArgs = {
  text: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootAi_Trigger_Control_ReviewArgs = {
  input: ControlAiReviewInput;
};

/** mutation root */
export type Mutation_RootAi_Trigger_Org_Control_ReviewArgs = {
  input: OrgControlAiReviewInput;
};

/** mutation root */
export type Mutation_RootAi_Trigger_Vendor_Questionnaire_ReviewArgs = {
  input: VendorQuestionnaireAiReviewInput;
};

/** mutation root */
export type Mutation_RootChange_Evidence_OwnerArgs = {
  object: ChangeEvidenceOwnerInput;
};

/** mutation root */
export type Mutation_RootCreate_ControlArgs = {
  payload: CreateControlInput;
};

/** mutation root */
export type Mutation_RootCreate_Custom_Program_RequestArgs = {
  payload: CreateCustomProgramRequestInput;
};

/** mutation root */
export type Mutation_RootCreate_Framework_RequestArgs = {
  object: FrameworkRequestInput;
};

/** mutation root */
export type Mutation_RootCreate_ProgramArgs = {
  object: CreateProgramInput;
};

/** mutation root */
export type Mutation_RootDeleteAuthProviderArgs = {
  id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthProviderRequestsArgs = {
  where: AuthProviderRequests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuthProvidersArgs = {
  where: AuthProviders_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenTypesArgs = {
  where: AuthRefreshTokenTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokensArgs = {
  where: AuthRefreshTokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuthRoleArgs = {
  role: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthRolesArgs = {
  where: AuthRoles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthUserProvidersArgs = {
  where: AuthUserProviders_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthUserRolesArgs = {
  where: AuthUserRoles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthUserSecurityKeysArgs = {
  where: AuthUserSecurityKeys_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteBucketArgs = {
  id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDeleteBucketsArgs = {
  where: Buckets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteFileArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteFilesArgs = {
  where: Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDeleteUserArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteUsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Controls_Vector_StoreArgs = {
  where: Ai_Controls_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Controls_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Evidences_Vector_StoreArgs = {
  where: Ai_Evidences_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Evidences_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Files_Document_StoreArgs = {
  where: Ai_Files_Document_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Files_Document_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Files_Vector_StoreArgs = {
  where: Ai_Files_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Files_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Policies_Vector_StoreArgs = {
  where: Ai_Policies_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Policies_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Programs_Vector_StoreArgs = {
  where: Ai_Programs_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Programs_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Risks_Vector_StoreArgs = {
  where: Ai_Risks_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Risks_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Tasks_Vector_StoreArgs = {
  where: Ai_Tasks_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Tasks_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Ai_Vendors_Vector_StoreArgs = {
  where: Ai_Vendors_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ai_Vendors_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Audit_Logged_ActionsArgs = {
  where: Audit_Logged_Actions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Audit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Audit_Policy_History_ViewArgs = {
  where: Audit_Policy_History_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Audit_Risk_History_ViewArgs = {
  where: Audit_Risk_History_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Audit_Vendor_History_ViewArgs = {
  where: Audit_Vendor_History_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Jackson_IndexArgs = {
  where: Auth_Jackson_Index_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Jackson_Index_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_Jackson_StoreArgs = {
  where: Auth_Jackson_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Jackson_Store_By_PkArgs = {
  key: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_Jackson_TtlArgs = {
  where: Auth_Jackson_Ttl_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Jackson_Ttl_By_PkArgs = {
  key: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Auth_MigrationsArgs = {
  where: Auth_Migrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Auth_Migrations_By_PkArgs = {
  id: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_Answer_Ai_SourcesArgs = {
  where: Client_Questionnaire_Answer_Ai_Sources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_Answer_Ai_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_AnswersArgs = {
  where: Client_Questionnaire_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_Question_StatusArgs = {
  where: Client_Questionnaire_Question_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_Question_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_QuestionsArgs = {
  where: Client_Questionnaire_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_StatusArgs = {
  where: Client_Questionnaire_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaire_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Client_QuestionnairesArgs = {
  where: Client_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Comment_UploadsArgs = {
  where: Comment_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Comment_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_CommentsArgs = {
  where: Comments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Comments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_Assessment_SourcesArgs = {
  where: Control_Ai_Review_Assessment_Sources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_Assessment_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_AssessmentsArgs = {
  where: Control_Ai_Review_Assessments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_Assessments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_CriteriaArgs = {
  where: Control_Ai_Review_Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_Criteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_RunsArgs = {
  where: Control_Ai_Review_Runs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_StatusArgs = {
  where: Control_Ai_Review_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Ai_Review_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_CategoriesArgs = {
  where: Control_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Categories_By_PkArgs = {
  category_id: Scalars['uuid']['input'];
  control_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_CriteriaArgs = {
  where: Control_Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Criteria_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  criteria_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_EvidencesArgs = {
  where: Control_Evidences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Evidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_FindingsArgs = {
  where: Control_Findings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Findings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_FrequenciesArgs = {
  where: Control_Frequencies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Frequencies_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_GroupsArgs = {
  where: Control_Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Groups_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  group_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_PrioritiesArgs = {
  where: Control_Priorities_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Priorities_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_TagsArgs = {
  where: Control_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Tags_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  tag_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_TasksArgs = {
  where: Control_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ControlsArgs = {
  where: Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Controls_History_StatsArgs = {
  where: Controls_History_Stats_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Controls_History_Stats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_CriteriaArgs = {
  where: Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Criteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Date_Field_ValuesArgs = {
  where: Date_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Date_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Email_Field_ValuesArgs = {
  where: Email_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Email_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_AclArgs = {
  where: Evidence_Acl_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Acl_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_IntegrationsArgs = {
  where: Evidence_Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_PoliciesArgs = {
  where: Evidence_Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Policies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_TagsArgs = {
  where: Evidence_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Version_FileArgs = {
  where: Evidence_Version_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Version_File_By_PkArgs = {
  evidence_version_id: Scalars['uuid']['input'];
  file_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_VersionsArgs = {
  where: Evidence_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_EvidencesArgs = {
  where: Evidences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Field_ConfigsArgs = {
  where: Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Field_EntitiesArgs = {
  where: Field_Entities_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Entities_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Field_TypesArgs = {
  where: Field_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Field_ValuesArgs = {
  where: Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Values_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Finding_CausesArgs = {
  where: Finding_Causes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Finding_Causes_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Finding_TypesArgs = {
  where: Finding_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Finding_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_FormsArgs = {
  where: Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_FrameworksArgs = {
  where: Frameworks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Frameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Frameworks_ControlsArgs = {
  where: Frameworks_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Frameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_FrequenciesArgs = {
  where: Frequencies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Frequencies_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Integration_NamesArgs = {
  where: Integration_Names_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Integration_Names_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Integration_RunsArgs = {
  where: Integration_Runs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Integration_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_IntegrationsArgs = {
  where: Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_InvitationsArgs = {
  where: Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invitations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Notification_Delivery_TypesArgs = {
  where: Notification_Delivery_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notification_Delivery_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Notification_Disabled_SettingsArgs = {
  where: Notification_Disabled_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notification_Disabled_Settings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Notification_TypesArgs = {
  where: Notification_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notification_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Number_Field_ValuesArgs = {
  where: Number_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Number_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Evidence_TagsArgs = {
  where: Organization_Evidence_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Evidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_IntegrationsArgs = {
  where: Organization_Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Risk_CategoriesArgs = {
  where: Organization_Risk_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Risk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Risk_TagsArgs = {
  where: Organization_Risk_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Risk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Template_FrameworksArgs = {
  where: Organization_Template_Frameworks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Template_Frameworks_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  template_framework_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_TenantsArgs = {
  where: Organization_Tenants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Tenants_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_UsersArgs = {
  where: Organization_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Users_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Vendor_Risk_LevelsArgs = {
  where: Organization_Vendor_Risk_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Vendor_Risk_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Organization_Vendor_TiersArgs = {
  where: Organization_Vendor_Tiers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organization_Vendor_Tiers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_PermissionsArgs = {
  where: Permissions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Permissions_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_PoliciesArgs = {
  where: Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_Acknowledgement_StatusesArgs = {
  where: Policy_Acknowledgement_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Acknowledgement_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_Acknowledgement_UsersArgs = {
  where: Policy_Acknowledgement_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Acknowledgement_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_AcknowledgementsArgs = {
  where: Policy_Acknowledgements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Acknowledgements_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_Approval_StatusesArgs = {
  where: Policy_Approval_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Approval_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_Approval_UsersArgs = {
  where: Policy_Approval_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Approval_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_ApprovalsArgs = {
  where: Policy_Approvals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Approvals_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_ApproversArgs = {
  where: Policy_Approvers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Approvers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_StatusesArgs = {
  where: Policy_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_TasksArgs = {
  where: Policy_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_TypesArgs = {
  where: Policy_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_Version_StatusesArgs = {
  where: Policy_Version_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Version_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_VersionsArgs = {
  where: Policy_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Program_By_IdArgs = {
  object: DeleteProgramInput;
};

/** mutation root */
export type Mutation_RootDelete_Program_ControlsArgs = {
  where: Program_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Program_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  program_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Program_Field_ConfigsArgs = {
  where: Program_Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Program_Field_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ProgramsArgs = {
  where: Programs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Programs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Questionnaire_UploadsArgs = {
  where: Questionnaire_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_QuestionnairesArgs = {
  where: Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Report_TypesArgs = {
  where: Report_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Report_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ReportsArgs = {
  where: Reports_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Review_FilesArgs = {
  where: Review_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Review_Files_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ReviewsArgs = {
  where: Reviews_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reviews_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_CategoriesArgs = {
  where: Risk_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_ControlsArgs = {
  where: Risk_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_DocumentsArgs = {
  where: Risk_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Documents_By_PkArgs = {
  file_id: Scalars['uuid']['input'];
  risk_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_ImpactsArgs = {
  where: Risk_Impacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Impacts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_Inherent_LevelsArgs = {
  where: Risk_Inherent_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Inherent_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_LevelsArgs = {
  where: Risk_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Levels_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_LikelihoodsArgs = {
  where: Risk_Likelihoods_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Likelihoods_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_Residual_LevelsArgs = {
  where: Risk_Residual_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Residual_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_TagsArgs = {
  where: Risk_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_TasksArgs = {
  where: Risk_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_RisksArgs = {
  where: Risks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Role_PermissionsArgs = {
  where: Role_Permissions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Permissions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Select_Field_ConfigsArgs = {
  where: Select_Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Select_Field_Configs_By_PkArgs = {
  field_config_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Select_Field_OptionsArgs = {
  where: Select_Field_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Select_Field_Options_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Select_Field_ValuesArgs = {
  where: Select_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Select_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_System_RolesArgs = {
  where: System_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_System_Roles_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Task_OwnersArgs = {
  where: Task_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Owners_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Template_Control_Ai_Review_CriteriaArgs = {
  where: Template_Control_Ai_Review_Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Template_Control_Ai_Review_Criteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Template_ControlsArgs = {
  where: Template_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Template_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Template_FrameworksArgs = {
  where: Template_Frameworks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Template_Frameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Template_Frameworks_ControlsArgs = {
  where: Template_Frameworks_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Template_Frameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Template_TasksArgs = {
  where: Template_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Template_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Text_Field_ValuesArgs = {
  where: Text_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Text_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Treatment_PlanArgs = {
  where: Treatment_Plan_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Treatment_Plan_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Url_Field_ValuesArgs = {
  where: Url_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Url_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_User_NotificationsArgs = {
  where: User_Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Approval_StatusesArgs = {
  where: Vendor_Approval_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Approval_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_DocumentsArgs = {
  where: Vendor_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Documents_By_PkArgs = {
  file_id: Scalars['uuid']['input'];
  vendor_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_FindingsArgs = {
  where: Vendor_Findings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Findings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Ai_Review_Assessment_SourcesArgs = {
  where: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Ai_Review_Assessment_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Ai_Review_AssessmentsArgs = {
  where: Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Ai_Review_Assessments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Ai_Review_Run_StatusArgs = {
  where: Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Ai_Review_Run_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Ai_Review_RunsArgs = {
  where: Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Ai_Review_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_Answer_StatusesArgs = {
  where: Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_Answer_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_AnswersArgs = {
  where: Vendor_Questionnaire_Form_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_UploadsArgs = {
  where: Vendor_Questionnaire_Form_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Form_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_FormsArgs = {
  where: Vendor_Questionnaire_Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_StatusesArgs = {
  where: Vendor_Questionnaire_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_UploadsArgs = {
  where: Vendor_Questionnaire_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_QuestionnairesArgs = {
  where: Vendor_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_RisksArgs = {
  where: Vendor_Risks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Risks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_TasksArgs = {
  where: Vendor_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_VendorsArgs = {
  where: Vendors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDuplicate_ControlArgs = {
  id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootExport_PolicyArgs = {
  input: ExportPolicyInput;
};

/** mutation root */
export type Mutation_RootGen_Integration_Run_ReportArgs = {
  input: GenIntegrationRunReportInput;
};

/** mutation root */
export type Mutation_RootGenerate_Workos_Admin_LinkArgs = {
  object: GenerateWorkosAdminLinkInput;
};

/** mutation root */
export type Mutation_RootGrant_Evidence_AccessArgs = {
  input: GrantEvidenceAccessInput;
};

/** mutation root */
export type Mutation_RootImpersonate_UserArgs = {
  object: ImpersonateUserInput;
};

/** mutation root */
export type Mutation_RootImport_PoliciesArgs = {
  object: ImportPoliciesInput;
};

/** mutation root */
export type Mutation_RootImport_ProgramArgs = {
  object: ImportProgramInput;
};

/** mutation root */
export type Mutation_RootImport_RisksArgs = {
  object: ImportRisksInput;
};

/** mutation root */
export type Mutation_RootImport_VendorsArgs = {
  object: ImportVendorsInput;
};

/** mutation root */
export type Mutation_RootInsertAuthProviderArgs = {
  object: AuthProviders_Insert_Input;
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthProviderRequestArgs = {
  object: AuthProviderRequests_Insert_Input;
  on_conflict?: InputMaybe<AuthProviderRequests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthProviderRequestsArgs = {
  objects: Array<AuthProviderRequests_Insert_Input>;
  on_conflict?: InputMaybe<AuthProviderRequests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthProvidersArgs = {
  objects: Array<AuthProviders_Insert_Input>;
  on_conflict?: InputMaybe<AuthProviders_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenArgs = {
  object: AuthRefreshTokens_Insert_Input;
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenTypeArgs = {
  object: AuthRefreshTokenTypes_Insert_Input;
  on_conflict?: InputMaybe<AuthRefreshTokenTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthRefreshTokenTypesArgs = {
  objects: Array<AuthRefreshTokenTypes_Insert_Input>;
  on_conflict?: InputMaybe<AuthRefreshTokenTypes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthRefreshTokensArgs = {
  objects: Array<AuthRefreshTokens_Insert_Input>;
  on_conflict?: InputMaybe<AuthRefreshTokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthRoleArgs = {
  object: AuthRoles_Insert_Input;
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthRolesArgs = {
  objects: Array<AuthRoles_Insert_Input>;
  on_conflict?: InputMaybe<AuthRoles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthUserProviderArgs = {
  object: AuthUserProviders_Insert_Input;
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthUserProvidersArgs = {
  objects: Array<AuthUserProviders_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserProviders_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthUserRoleArgs = {
  object: AuthUserRoles_Insert_Input;
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthUserRolesArgs = {
  objects: Array<AuthUserRoles_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserRoles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthUserSecurityKeyArgs = {
  object: AuthUserSecurityKeys_Insert_Input;
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertAuthUserSecurityKeysArgs = {
  objects: Array<AuthUserSecurityKeys_Insert_Input>;
  on_conflict?: InputMaybe<AuthUserSecurityKeys_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertBucketArgs = {
  object: Buckets_Insert_Input;
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertBucketsArgs = {
  objects: Array<Buckets_Insert_Input>;
  on_conflict?: InputMaybe<Buckets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertFileArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertFilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUserArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertUsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Controls_Vector_StoreArgs = {
  objects: Array<Ai_Controls_Vector_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Controls_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Controls_Vector_Store_OneArgs = {
  object: Ai_Controls_Vector_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Controls_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Evidences_Vector_StoreArgs = {
  objects: Array<Ai_Evidences_Vector_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Evidences_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Evidences_Vector_Store_OneArgs = {
  object: Ai_Evidences_Vector_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Evidences_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Files_Document_StoreArgs = {
  objects: Array<Ai_Files_Document_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Files_Document_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Files_Document_Store_OneArgs = {
  object: Ai_Files_Document_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Files_Document_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Files_Vector_StoreArgs = {
  objects: Array<Ai_Files_Vector_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Files_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Files_Vector_Store_OneArgs = {
  object: Ai_Files_Vector_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Files_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Policies_Vector_StoreArgs = {
  objects: Array<Ai_Policies_Vector_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Policies_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Policies_Vector_Store_OneArgs = {
  object: Ai_Policies_Vector_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Policies_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Programs_Vector_StoreArgs = {
  objects: Array<Ai_Programs_Vector_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Programs_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Programs_Vector_Store_OneArgs = {
  object: Ai_Programs_Vector_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Programs_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Risks_Vector_StoreArgs = {
  objects: Array<Ai_Risks_Vector_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Risks_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Risks_Vector_Store_OneArgs = {
  object: Ai_Risks_Vector_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Risks_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Tasks_Vector_StoreArgs = {
  objects: Array<Ai_Tasks_Vector_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Tasks_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Tasks_Vector_Store_OneArgs = {
  object: Ai_Tasks_Vector_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Tasks_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Vendors_Vector_StoreArgs = {
  objects: Array<Ai_Vendors_Vector_Store_Insert_Input>;
  on_conflict?: InputMaybe<Ai_Vendors_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ai_Vendors_Vector_Store_OneArgs = {
  object: Ai_Vendors_Vector_Store_Insert_Input;
  on_conflict?: InputMaybe<Ai_Vendors_Vector_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Logged_ActionsArgs = {
  objects: Array<Audit_Logged_Actions_Insert_Input>;
  on_conflict?: InputMaybe<Audit_Logged_Actions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Logged_Actions_OneArgs = {
  object: Audit_Logged_Actions_Insert_Input;
  on_conflict?: InputMaybe<Audit_Logged_Actions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Policy_History_ViewArgs = {
  objects: Array<Audit_Policy_History_View_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Policy_History_View_OneArgs = {
  object: Audit_Policy_History_View_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Risk_History_ViewArgs = {
  objects: Array<Audit_Risk_History_View_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Risk_History_View_OneArgs = {
  object: Audit_Risk_History_View_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Vendor_History_ViewArgs = {
  objects: Array<Audit_Vendor_History_View_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Audit_Vendor_History_View_OneArgs = {
  object: Audit_Vendor_History_View_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Jackson_IndexArgs = {
  objects: Array<Auth_Jackson_Index_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Jackson_Index_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Jackson_Index_OneArgs = {
  object: Auth_Jackson_Index_Insert_Input;
  on_conflict?: InputMaybe<Auth_Jackson_Index_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Jackson_StoreArgs = {
  objects: Array<Auth_Jackson_Store_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Jackson_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Jackson_Store_OneArgs = {
  object: Auth_Jackson_Store_Insert_Input;
  on_conflict?: InputMaybe<Auth_Jackson_Store_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Jackson_TtlArgs = {
  objects: Array<Auth_Jackson_Ttl_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Jackson_Ttl_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Jackson_Ttl_OneArgs = {
  object: Auth_Jackson_Ttl_Insert_Input;
  on_conflict?: InputMaybe<Auth_Jackson_Ttl_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_MigrationsArgs = {
  objects: Array<Auth_Migrations_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Migrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Auth_Migrations_OneArgs = {
  object: Auth_Migrations_Insert_Input;
  on_conflict?: InputMaybe<Auth_Migrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_Answer_Ai_SourcesArgs = {
  objects: Array<Client_Questionnaire_Answer_Ai_Sources_Insert_Input>;
  on_conflict?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_Answer_Ai_Sources_OneArgs = {
  object: Client_Questionnaire_Answer_Ai_Sources_Insert_Input;
  on_conflict?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_AnswersArgs = {
  objects: Array<Client_Questionnaire_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Client_Questionnaire_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_Answers_OneArgs = {
  object: Client_Questionnaire_Answers_Insert_Input;
  on_conflict?: InputMaybe<Client_Questionnaire_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_Question_StatusArgs = {
  objects: Array<Client_Questionnaire_Question_Status_Insert_Input>;
  on_conflict?: InputMaybe<Client_Questionnaire_Question_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_Question_Status_OneArgs = {
  object: Client_Questionnaire_Question_Status_Insert_Input;
  on_conflict?: InputMaybe<Client_Questionnaire_Question_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_QuestionsArgs = {
  objects: Array<Client_Questionnaire_Questions_Insert_Input>;
  on_conflict?: InputMaybe<Client_Questionnaire_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_Questions_OneArgs = {
  object: Client_Questionnaire_Questions_Insert_Input;
  on_conflict?: InputMaybe<Client_Questionnaire_Questions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_StatusArgs = {
  objects: Array<Client_Questionnaire_Status_Insert_Input>;
  on_conflict?: InputMaybe<Client_Questionnaire_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_Status_OneArgs = {
  object: Client_Questionnaire_Status_Insert_Input;
  on_conflict?: InputMaybe<Client_Questionnaire_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_QuestionnairesArgs = {
  objects: Array<Client_Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Client_Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaires_OneArgs = {
  object: Client_Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Client_Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Comment_UploadsArgs = {
  objects: Array<Comment_Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Comment_Uploads_OneArgs = {
  object: Comment_Uploads_Insert_Input;
  on_conflict?: InputMaybe<Comment_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CommentsArgs = {
  objects: Array<Comments_Insert_Input>;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Comments_OneArgs = {
  object: Comments_Insert_Input;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_Assessment_SourcesArgs = {
  objects: Array<Control_Ai_Review_Assessment_Sources_Insert_Input>;
  on_conflict?: InputMaybe<Control_Ai_Review_Assessment_Sources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_Assessment_Sources_OneArgs = {
  object: Control_Ai_Review_Assessment_Sources_Insert_Input;
  on_conflict?: InputMaybe<Control_Ai_Review_Assessment_Sources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_AssessmentsArgs = {
  objects: Array<Control_Ai_Review_Assessments_Insert_Input>;
  on_conflict?: InputMaybe<Control_Ai_Review_Assessments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_Assessments_OneArgs = {
  object: Control_Ai_Review_Assessments_Insert_Input;
  on_conflict?: InputMaybe<Control_Ai_Review_Assessments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_CriteriaArgs = {
  objects: Array<Control_Ai_Review_Criteria_Insert_Input>;
  on_conflict?: InputMaybe<Control_Ai_Review_Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_Criteria_OneArgs = {
  object: Control_Ai_Review_Criteria_Insert_Input;
  on_conflict?: InputMaybe<Control_Ai_Review_Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_RunsArgs = {
  objects: Array<Control_Ai_Review_Runs_Insert_Input>;
  on_conflict?: InputMaybe<Control_Ai_Review_Runs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_Runs_OneArgs = {
  object: Control_Ai_Review_Runs_Insert_Input;
  on_conflict?: InputMaybe<Control_Ai_Review_Runs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_StatusArgs = {
  objects: Array<Control_Ai_Review_Status_Insert_Input>;
  on_conflict?: InputMaybe<Control_Ai_Review_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Ai_Review_Status_OneArgs = {
  object: Control_Ai_Review_Status_Insert_Input;
  on_conflict?: InputMaybe<Control_Ai_Review_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_CategoriesArgs = {
  objects: Array<Control_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Control_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Categories_OneArgs = {
  object: Control_Categories_Insert_Input;
  on_conflict?: InputMaybe<Control_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_CriteriaArgs = {
  objects: Array<Control_Criteria_Insert_Input>;
  on_conflict?: InputMaybe<Control_Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Criteria_OneArgs = {
  object: Control_Criteria_Insert_Input;
  on_conflict?: InputMaybe<Control_Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_EvidencesArgs = {
  objects: Array<Control_Evidences_Insert_Input>;
  on_conflict?: InputMaybe<Control_Evidences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Evidences_OneArgs = {
  object: Control_Evidences_Insert_Input;
  on_conflict?: InputMaybe<Control_Evidences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_FindingsArgs = {
  objects: Array<Control_Findings_Insert_Input>;
  on_conflict?: InputMaybe<Control_Findings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Findings_OneArgs = {
  object: Control_Findings_Insert_Input;
  on_conflict?: InputMaybe<Control_Findings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_FrequenciesArgs = {
  objects: Array<Control_Frequencies_Insert_Input>;
  on_conflict?: InputMaybe<Control_Frequencies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Frequencies_OneArgs = {
  object: Control_Frequencies_Insert_Input;
  on_conflict?: InputMaybe<Control_Frequencies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_GroupsArgs = {
  objects: Array<Control_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Control_Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Groups_OneArgs = {
  object: Control_Groups_Insert_Input;
  on_conflict?: InputMaybe<Control_Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_PrioritiesArgs = {
  objects: Array<Control_Priorities_Insert_Input>;
  on_conflict?: InputMaybe<Control_Priorities_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Priorities_OneArgs = {
  object: Control_Priorities_Insert_Input;
  on_conflict?: InputMaybe<Control_Priorities_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_TagsArgs = {
  objects: Array<Control_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Control_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Tags_OneArgs = {
  object: Control_Tags_Insert_Input;
  on_conflict?: InputMaybe<Control_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_TasksArgs = {
  objects: Array<Control_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Control_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Tasks_OneArgs = {
  object: Control_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Control_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ControlsArgs = {
  objects: Array<Controls_Insert_Input>;
  on_conflict?: InputMaybe<Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Controls_History_StatsArgs = {
  objects: Array<Controls_History_Stats_Insert_Input>;
  on_conflict?: InputMaybe<Controls_History_Stats_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Controls_History_Stats_OneArgs = {
  object: Controls_History_Stats_Insert_Input;
  on_conflict?: InputMaybe<Controls_History_Stats_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Controls_OneArgs = {
  object: Controls_Insert_Input;
  on_conflict?: InputMaybe<Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CriteriaArgs = {
  objects: Array<Criteria_Insert_Input>;
  on_conflict?: InputMaybe<Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Criteria_OneArgs = {
  object: Criteria_Insert_Input;
  on_conflict?: InputMaybe<Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Date_Field_ValuesArgs = {
  objects: Array<Date_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Date_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Date_Field_Values_OneArgs = {
  object: Date_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Date_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Field_ValuesArgs = {
  objects: Array<Email_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Email_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Field_Values_OneArgs = {
  object: Email_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Email_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_AclArgs = {
  objects: Array<Evidence_Acl_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Acl_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Acl_OneArgs = {
  object: Evidence_Acl_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Acl_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_IntegrationsArgs = {
  objects: Array<Evidence_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Integrations_OneArgs = {
  object: Evidence_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_PoliciesArgs = {
  objects: Array<Evidence_Policies_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Policies_OneArgs = {
  object: Evidence_Policies_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_TagsArgs = {
  objects: Array<Evidence_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Tags_OneArgs = {
  object: Evidence_Tags_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Version_FileArgs = {
  objects: Array<Evidence_Version_File_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Version_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Version_File_OneArgs = {
  object: Evidence_Version_File_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Version_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_VersionsArgs = {
  objects: Array<Evidence_Versions_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Versions_OneArgs = {
  object: Evidence_Versions_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EvidencesArgs = {
  objects: Array<Evidences_Insert_Input>;
  on_conflict?: InputMaybe<Evidences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidences_OneArgs = {
  object: Evidences_Insert_Input;
  on_conflict?: InputMaybe<Evidences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_ConfigsArgs = {
  objects: Array<Field_Configs_Insert_Input>;
  on_conflict?: InputMaybe<Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Configs_OneArgs = {
  object: Field_Configs_Insert_Input;
  on_conflict?: InputMaybe<Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_EntitiesArgs = {
  objects: Array<Field_Entities_Insert_Input>;
  on_conflict?: InputMaybe<Field_Entities_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Entities_OneArgs = {
  object: Field_Entities_Insert_Input;
  on_conflict?: InputMaybe<Field_Entities_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_TypesArgs = {
  objects: Array<Field_Types_Insert_Input>;
  on_conflict?: InputMaybe<Field_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Types_OneArgs = {
  object: Field_Types_Insert_Input;
  on_conflict?: InputMaybe<Field_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_ValuesArgs = {
  objects: Array<Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Values_OneArgs = {
  object: Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Finding_CausesArgs = {
  objects: Array<Finding_Causes_Insert_Input>;
  on_conflict?: InputMaybe<Finding_Causes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Finding_Causes_OneArgs = {
  object: Finding_Causes_Insert_Input;
  on_conflict?: InputMaybe<Finding_Causes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Finding_TypesArgs = {
  objects: Array<Finding_Types_Insert_Input>;
  on_conflict?: InputMaybe<Finding_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Finding_Types_OneArgs = {
  object: Finding_Types_Insert_Input;
  on_conflict?: InputMaybe<Finding_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FormsArgs = {
  objects: Array<Forms_Insert_Input>;
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Forms_OneArgs = {
  object: Forms_Insert_Input;
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FrameworksArgs = {
  objects: Array<Frameworks_Insert_Input>;
  on_conflict?: InputMaybe<Frameworks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Frameworks_ControlsArgs = {
  objects: Array<Frameworks_Controls_Insert_Input>;
  on_conflict?: InputMaybe<Frameworks_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Frameworks_Controls_OneArgs = {
  object: Frameworks_Controls_Insert_Input;
  on_conflict?: InputMaybe<Frameworks_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Frameworks_OneArgs = {
  object: Frameworks_Insert_Input;
  on_conflict?: InputMaybe<Frameworks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FrequenciesArgs = {
  objects: Array<Frequencies_Insert_Input>;
  on_conflict?: InputMaybe<Frequencies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Frequencies_OneArgs = {
  object: Frequencies_Insert_Input;
  on_conflict?: InputMaybe<Frequencies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_NamesArgs = {
  objects: Array<Integration_Names_Insert_Input>;
  on_conflict?: InputMaybe<Integration_Names_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_Names_OneArgs = {
  object: Integration_Names_Insert_Input;
  on_conflict?: InputMaybe<Integration_Names_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_RunsArgs = {
  objects: Array<Integration_Runs_Insert_Input>;
  on_conflict?: InputMaybe<Integration_Runs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integration_Runs_OneArgs = {
  object: Integration_Runs_Insert_Input;
  on_conflict?: InputMaybe<Integration_Runs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_IntegrationsArgs = {
  objects: Array<Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Integrations_OneArgs = {
  object: Integrations_Insert_Input;
  on_conflict?: InputMaybe<Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InvitationsArgs = {
  objects: Array<Invitations_Insert_Input>;
  on_conflict?: InputMaybe<Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invitations_OneArgs = {
  object: Invitations_Insert_Input;
  on_conflict?: InputMaybe<Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_Delivery_TypesArgs = {
  objects: Array<Notification_Delivery_Types_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Delivery_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_Delivery_Types_OneArgs = {
  object: Notification_Delivery_Types_Insert_Input;
  on_conflict?: InputMaybe<Notification_Delivery_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_Disabled_SettingsArgs = {
  objects: Array<Notification_Disabled_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Disabled_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_Disabled_Settings_OneArgs = {
  object: Notification_Disabled_Settings_Insert_Input;
  on_conflict?: InputMaybe<Notification_Disabled_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_TypesArgs = {
  objects: Array<Notification_Types_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_Types_OneArgs = {
  object: Notification_Types_Insert_Input;
  on_conflict?: InputMaybe<Notification_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Field_ValuesArgs = {
  objects: Array<Number_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Number_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Field_Values_OneArgs = {
  object: Number_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Number_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Evidence_TagsArgs = {
  objects: Array<Organization_Evidence_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Evidence_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Evidence_Tags_OneArgs = {
  object: Organization_Evidence_Tags_Insert_Input;
  on_conflict?: InputMaybe<Organization_Evidence_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_IntegrationsArgs = {
  objects: Array<Organization_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Integrations_OneArgs = {
  object: Organization_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Organization_Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Risk_CategoriesArgs = {
  objects: Array<Organization_Risk_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Risk_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Risk_Categories_OneArgs = {
  object: Organization_Risk_Categories_Insert_Input;
  on_conflict?: InputMaybe<Organization_Risk_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Risk_TagsArgs = {
  objects: Array<Organization_Risk_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Risk_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Risk_Tags_OneArgs = {
  object: Organization_Risk_Tags_Insert_Input;
  on_conflict?: InputMaybe<Organization_Risk_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Template_FrameworksArgs = {
  objects: Array<Organization_Template_Frameworks_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Template_Frameworks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Template_Frameworks_OneArgs = {
  object: Organization_Template_Frameworks_Insert_Input;
  on_conflict?: InputMaybe<Organization_Template_Frameworks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_TenantsArgs = {
  objects: Array<Organization_Tenants_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Tenants_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Tenants_OneArgs = {
  object: Organization_Tenants_Insert_Input;
  on_conflict?: InputMaybe<Organization_Tenants_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_UsersArgs = {
  objects: Array<Organization_Users_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Users_OneArgs = {
  object: Organization_Users_Insert_Input;
  on_conflict?: InputMaybe<Organization_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Vendor_Risk_LevelsArgs = {
  objects: Array<Organization_Vendor_Risk_Levels_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Vendor_Risk_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Vendor_Risk_Levels_OneArgs = {
  object: Organization_Vendor_Risk_Levels_Insert_Input;
  on_conflict?: InputMaybe<Organization_Vendor_Risk_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Vendor_TiersArgs = {
  objects: Array<Organization_Vendor_Tiers_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Vendor_Tiers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Vendor_Tiers_OneArgs = {
  object: Organization_Vendor_Tiers_Insert_Input;
  on_conflict?: InputMaybe<Organization_Vendor_Tiers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PermissionsArgs = {
  objects: Array<Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permissions_OneArgs = {
  object: Permissions_Insert_Input;
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PoliciesArgs = {
  objects: Array<Policies_Insert_Input>;
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policies_OneArgs = {
  object: Policies_Insert_Input;
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Acknowledgement_StatusesArgs = {
  objects: Array<Policy_Acknowledgement_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Acknowledgement_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Acknowledgement_Statuses_OneArgs = {
  object: Policy_Acknowledgement_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Policy_Acknowledgement_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Acknowledgement_UsersArgs = {
  objects: Array<Policy_Acknowledgement_Users_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Acknowledgement_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Acknowledgement_Users_OneArgs = {
  object: Policy_Acknowledgement_Users_Insert_Input;
  on_conflict?: InputMaybe<Policy_Acknowledgement_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_AcknowledgementsArgs = {
  objects: Array<Policy_Acknowledgements_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Acknowledgements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Acknowledgements_OneArgs = {
  object: Policy_Acknowledgements_Insert_Input;
  on_conflict?: InputMaybe<Policy_Acknowledgements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approval_StatusesArgs = {
  objects: Array<Policy_Approval_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Approval_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approval_Statuses_OneArgs = {
  object: Policy_Approval_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Policy_Approval_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approval_UsersArgs = {
  objects: Array<Policy_Approval_Users_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Approval_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approval_Users_OneArgs = {
  object: Policy_Approval_Users_Insert_Input;
  on_conflict?: InputMaybe<Policy_Approval_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_ApprovalsArgs = {
  objects: Array<Policy_Approvals_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Approvals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approvals_OneArgs = {
  object: Policy_Approvals_Insert_Input;
  on_conflict?: InputMaybe<Policy_Approvals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_ApproversArgs = {
  objects: Array<Policy_Approvers_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Approvers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approvers_OneArgs = {
  object: Policy_Approvers_Insert_Input;
  on_conflict?: InputMaybe<Policy_Approvers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_StatusesArgs = {
  objects: Array<Policy_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Statuses_OneArgs = {
  object: Policy_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Policy_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_TasksArgs = {
  objects: Array<Policy_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Tasks_OneArgs = {
  object: Policy_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Policy_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_TypesArgs = {
  objects: Array<Policy_Types_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Types_OneArgs = {
  object: Policy_Types_Insert_Input;
  on_conflict?: InputMaybe<Policy_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Version_StatusesArgs = {
  objects: Array<Policy_Version_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Version_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Version_Statuses_OneArgs = {
  object: Policy_Version_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Policy_Version_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_VersionsArgs = {
  objects: Array<Policy_Versions_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Versions_OneArgs = {
  object: Policy_Versions_Insert_Input;
  on_conflict?: InputMaybe<Policy_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Program_ControlsArgs = {
  objects: Array<Program_Controls_Insert_Input>;
  on_conflict?: InputMaybe<Program_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Program_Controls_OneArgs = {
  object: Program_Controls_Insert_Input;
  on_conflict?: InputMaybe<Program_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Program_Field_ConfigsArgs = {
  objects: Array<Program_Field_Configs_Insert_Input>;
  on_conflict?: InputMaybe<Program_Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Program_Field_Configs_OneArgs = {
  object: Program_Field_Configs_Insert_Input;
  on_conflict?: InputMaybe<Program_Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProgramsArgs = {
  objects: Array<Programs_Insert_Input>;
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Programs_OneArgs = {
  object: Programs_Insert_Input;
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionnaire_UploadsArgs = {
  objects: Array<Questionnaire_Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaire_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionnaire_Uploads_OneArgs = {
  object: Questionnaire_Uploads_Insert_Input;
  on_conflict?: InputMaybe<Questionnaire_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QuestionnairesArgs = {
  objects: Array<Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionnaires_OneArgs = {
  object: Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Report_TypesArgs = {
  objects: Array<Report_Types_Insert_Input>;
  on_conflict?: InputMaybe<Report_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Report_Types_OneArgs = {
  object: Report_Types_Insert_Input;
  on_conflict?: InputMaybe<Report_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ReportsArgs = {
  objects: Array<Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reports_OneArgs = {
  object: Reports_Insert_Input;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_FilesArgs = {
  objects: Array<Review_Files_Insert_Input>;
  on_conflict?: InputMaybe<Review_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Files_OneArgs = {
  object: Review_Files_Insert_Input;
  on_conflict?: InputMaybe<Review_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ReviewsArgs = {
  objects: Array<Reviews_Insert_Input>;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reviews_OneArgs = {
  object: Reviews_Insert_Input;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_CategoriesArgs = {
  objects: Array<Risk_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Categories_OneArgs = {
  object: Risk_Categories_Insert_Input;
  on_conflict?: InputMaybe<Risk_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_ControlsArgs = {
  objects: Array<Risk_Controls_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Controls_OneArgs = {
  object: Risk_Controls_Insert_Input;
  on_conflict?: InputMaybe<Risk_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_DocumentsArgs = {
  objects: Array<Risk_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Documents_OneArgs = {
  object: Risk_Documents_Insert_Input;
  on_conflict?: InputMaybe<Risk_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_ImpactsArgs = {
  objects: Array<Risk_Impacts_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Impacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Impacts_OneArgs = {
  object: Risk_Impacts_Insert_Input;
  on_conflict?: InputMaybe<Risk_Impacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Inherent_LevelsArgs = {
  objects: Array<Risk_Inherent_Levels_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Inherent_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Inherent_Levels_OneArgs = {
  object: Risk_Inherent_Levels_Insert_Input;
  on_conflict?: InputMaybe<Risk_Inherent_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_LevelsArgs = {
  objects: Array<Risk_Levels_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Levels_OneArgs = {
  object: Risk_Levels_Insert_Input;
  on_conflict?: InputMaybe<Risk_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_LikelihoodsArgs = {
  objects: Array<Risk_Likelihoods_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Likelihoods_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Likelihoods_OneArgs = {
  object: Risk_Likelihoods_Insert_Input;
  on_conflict?: InputMaybe<Risk_Likelihoods_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Residual_LevelsArgs = {
  objects: Array<Risk_Residual_Levels_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Residual_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Residual_Levels_OneArgs = {
  object: Risk_Residual_Levels_Insert_Input;
  on_conflict?: InputMaybe<Risk_Residual_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_TagsArgs = {
  objects: Array<Risk_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Tags_OneArgs = {
  object: Risk_Tags_Insert_Input;
  on_conflict?: InputMaybe<Risk_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_TasksArgs = {
  objects: Array<Risk_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Tasks_OneArgs = {
  object: Risk_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Risk_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RisksArgs = {
  objects: Array<Risks_Insert_Input>;
  on_conflict?: InputMaybe<Risks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risks_OneArgs = {
  object: Risks_Insert_Input;
  on_conflict?: InputMaybe<Risks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_PermissionsArgs = {
  objects: Array<Role_Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Role_Permissions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Permissions_OneArgs = {
  object: Role_Permissions_Insert_Input;
  on_conflict?: InputMaybe<Role_Permissions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_ConfigsArgs = {
  objects: Array<Select_Field_Configs_Insert_Input>;
  on_conflict?: InputMaybe<Select_Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_Configs_OneArgs = {
  object: Select_Field_Configs_Insert_Input;
  on_conflict?: InputMaybe<Select_Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_OptionsArgs = {
  objects: Array<Select_Field_Options_Insert_Input>;
  on_conflict?: InputMaybe<Select_Field_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_Options_OneArgs = {
  object: Select_Field_Options_Insert_Input;
  on_conflict?: InputMaybe<Select_Field_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_ValuesArgs = {
  objects: Array<Select_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Select_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_Values_OneArgs = {
  object: Select_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Select_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_System_RolesArgs = {
  objects: Array<System_Roles_Insert_Input>;
  on_conflict?: InputMaybe<System_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_System_Roles_OneArgs = {
  object: System_Roles_Insert_Input;
  on_conflict?: InputMaybe<System_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_OwnersArgs = {
  objects: Array<Task_Owners_Insert_Input>;
  on_conflict?: InputMaybe<Task_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Owners_OneArgs = {
  object: Task_Owners_Insert_Input;
  on_conflict?: InputMaybe<Task_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_Control_Ai_Review_CriteriaArgs = {
  objects: Array<Template_Control_Ai_Review_Criteria_Insert_Input>;
  on_conflict?: InputMaybe<Template_Control_Ai_Review_Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_Control_Ai_Review_Criteria_OneArgs = {
  object: Template_Control_Ai_Review_Criteria_Insert_Input;
  on_conflict?: InputMaybe<Template_Control_Ai_Review_Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_ControlsArgs = {
  objects: Array<Template_Controls_Insert_Input>;
  on_conflict?: InputMaybe<Template_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_Controls_OneArgs = {
  object: Template_Controls_Insert_Input;
  on_conflict?: InputMaybe<Template_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_FrameworksArgs = {
  objects: Array<Template_Frameworks_Insert_Input>;
  on_conflict?: InputMaybe<Template_Frameworks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_Frameworks_ControlsArgs = {
  objects: Array<Template_Frameworks_Controls_Insert_Input>;
  on_conflict?: InputMaybe<Template_Frameworks_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_Frameworks_Controls_OneArgs = {
  object: Template_Frameworks_Controls_Insert_Input;
  on_conflict?: InputMaybe<Template_Frameworks_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_Frameworks_OneArgs = {
  object: Template_Frameworks_Insert_Input;
  on_conflict?: InputMaybe<Template_Frameworks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_TasksArgs = {
  objects: Array<Template_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Template_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Template_Tasks_OneArgs = {
  object: Template_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Template_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Text_Field_ValuesArgs = {
  objects: Array<Text_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Text_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Text_Field_Values_OneArgs = {
  object: Text_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Text_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Treatment_PlanArgs = {
  objects: Array<Treatment_Plan_Insert_Input>;
  on_conflict?: InputMaybe<Treatment_Plan_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Treatment_Plan_OneArgs = {
  object: Treatment_Plan_Insert_Input;
  on_conflict?: InputMaybe<Treatment_Plan_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Url_Field_ValuesArgs = {
  objects: Array<Url_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Url_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Url_Field_Values_OneArgs = {
  object: Url_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Url_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_NotificationsArgs = {
  objects: Array<User_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<User_Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Notifications_OneArgs = {
  object: User_Notifications_Insert_Input;
  on_conflict?: InputMaybe<User_Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Approval_StatusesArgs = {
  objects: Array<Vendor_Approval_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Approval_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Approval_Statuses_OneArgs = {
  object: Vendor_Approval_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Approval_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_DocumentsArgs = {
  objects: Array<Vendor_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Documents_OneArgs = {
  object: Vendor_Documents_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_FindingsArgs = {
  objects: Array<Vendor_Findings_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Findings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Findings_OneArgs = {
  object: Vendor_Findings_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Findings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Ai_Review_Assessment_SourcesArgs = {
  objects: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Ai_Review_Assessment_Sources_OneArgs = {
  object: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Ai_Review_AssessmentsArgs = {
  objects: Array<Vendor_Questionnaire_Ai_Review_Assessments_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Ai_Review_Assessments_OneArgs = {
  object: Vendor_Questionnaire_Ai_Review_Assessments_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Ai_Review_Run_StatusArgs = {
  objects: Array<Vendor_Questionnaire_Ai_Review_Run_Status_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Ai_Review_Run_Status_OneArgs = {
  object: Vendor_Questionnaire_Ai_Review_Run_Status_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Ai_Review_RunsArgs = {
  objects: Array<Vendor_Questionnaire_Ai_Review_Runs_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Ai_Review_Runs_OneArgs = {
  object: Vendor_Questionnaire_Ai_Review_Runs_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_Answer_StatusesArgs = {
  objects: Array<Vendor_Questionnaire_Form_Answer_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_Answer_Statuses_OneArgs = {
  object: Vendor_Questionnaire_Form_Answer_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_AnswersArgs = {
  objects: Array<Vendor_Questionnaire_Form_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_Answers_OneArgs = {
  object: Vendor_Questionnaire_Form_Answers_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_UploadsArgs = {
  objects: Array<Vendor_Questionnaire_Form_Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Form_Uploads_OneArgs = {
  object: Vendor_Questionnaire_Form_Uploads_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_FormsArgs = {
  objects: Array<Vendor_Questionnaire_Forms_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Forms_OneArgs = {
  object: Vendor_Questionnaire_Forms_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_StatusesArgs = {
  objects: Array<Vendor_Questionnaire_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Statuses_OneArgs = {
  object: Vendor_Questionnaire_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Statuses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_UploadsArgs = {
  objects: Array<Vendor_Questionnaire_Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Uploads_OneArgs = {
  object: Vendor_Questionnaire_Uploads_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_QuestionnairesArgs = {
  objects: Array<Vendor_Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaires_OneArgs = {
  object: Vendor_Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_RisksArgs = {
  objects: Array<Vendor_Risks_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Risks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Risks_OneArgs = {
  object: Vendor_Risks_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Risks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_TasksArgs = {
  objects: Array<Vendor_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Tasks_OneArgs = {
  object: Vendor_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_VendorsArgs = {
  objects: Array<Vendors_Insert_Input>;
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendors_OneArgs = {
  object: Vendors_Insert_Input;
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInvite_UserArgs = {
  object: InviteUserInput;
};

/** mutation root */
export type Mutation_RootJoin_OrganizationArgs = {
  object: JoinOrganizationInput;
};

/** mutation root */
export type Mutation_RootRevoke_Evidence_AccessArgs = {
  input: RevokeEvidenceAccessInput;
};

/** mutation root */
export type Mutation_RootSendVendorQuestionnaireArgs = {
  input: SendVendorQuestionnaireInput;
};

/** mutation root */
export type Mutation_RootSend_Approval_ReminderArgs = {
  input: ApprovalReminderInput;
};

/** mutation root */
export type Mutation_RootSet_Evidence_ConfidentialArgs = {
  input: SetEvidenceConfidentialInput;
};

/** mutation root */
export type Mutation_RootSet_Org_Integration_ConfigArgs = {
  input: SetOrgIntegrationConfigInput;
};

/** mutation root */
export type Mutation_RootSet_Select_Field_ValuesArgs = {
  input: SetSelectFieldValuesInput;
};

/** mutation root */
export type Mutation_RootToggle_Audit_TrailArgs = {
  object: ToggleAuditTrailInput;
};

/** mutation root */
export type Mutation_RootTrigger_All_Controls_Embeddings_LifecycleArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTrigger_All_Evidences_Embeddings_LifecycleArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTrigger_All_Files_Embeddings_LifecycleArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTrigger_All_Policies_Embeddings_LifecycleArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTrigger_All_Programs_Embeddings_LifecycleArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTrigger_All_Risks_Embeddings_LifecycleArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTrigger_All_Tasks_Embeddings_LifecycleArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootTrigger_All_Vendors_Embeddings_LifecycleArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootUpdateAuthProviderArgs = {
  _set?: InputMaybe<AuthProviders_Set_Input>;
  pk_columns: AuthProviders_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuthProviderRequestArgs = {
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  pk_columns: AuthProviderRequests_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuthProviderRequestsArgs = {
  _append?: InputMaybe<AuthProviderRequests_Append_Input>;
  _delete_at_path?: InputMaybe<AuthProviderRequests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthProviderRequests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthProviderRequests_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthProviderRequests_Prepend_Input>;
  _set?: InputMaybe<AuthProviderRequests_Set_Input>;
  where: AuthProviderRequests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateAuthProvidersArgs = {
  _set?: InputMaybe<AuthProviders_Set_Input>;
  where: AuthProviders_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenArgs = {
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  pk_columns: AuthRefreshTokens_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenTypeArgs = {
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  pk_columns: AuthRefreshTokenTypes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokenTypesArgs = {
  _set?: InputMaybe<AuthRefreshTokenTypes_Set_Input>;
  where: AuthRefreshTokenTypes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateAuthRefreshTokensArgs = {
  _append?: InputMaybe<AuthRefreshTokens_Append_Input>;
  _delete_at_path?: InputMaybe<AuthRefreshTokens_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<AuthRefreshTokens_Delete_Elem_Input>;
  _delete_key?: InputMaybe<AuthRefreshTokens_Delete_Key_Input>;
  _prepend?: InputMaybe<AuthRefreshTokens_Prepend_Input>;
  _set?: InputMaybe<AuthRefreshTokens_Set_Input>;
  where: AuthRefreshTokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateAuthRoleArgs = {
  _set?: InputMaybe<AuthRoles_Set_Input>;
  pk_columns: AuthRoles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuthRolesArgs = {
  _set?: InputMaybe<AuthRoles_Set_Input>;
  where: AuthRoles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateAuthUserProviderArgs = {
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  pk_columns: AuthUserProviders_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuthUserProvidersArgs = {
  _set?: InputMaybe<AuthUserProviders_Set_Input>;
  where: AuthUserProviders_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateAuthUserRoleArgs = {
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  pk_columns: AuthUserRoles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuthUserRolesArgs = {
  _set?: InputMaybe<AuthUserRoles_Set_Input>;
  where: AuthUserRoles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateAuthUserSecurityKeyArgs = {
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  pk_columns: AuthUserSecurityKeys_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateAuthUserSecurityKeysArgs = {
  _inc?: InputMaybe<AuthUserSecurityKeys_Inc_Input>;
  _set?: InputMaybe<AuthUserSecurityKeys_Set_Input>;
  where: AuthUserSecurityKeys_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateBucketArgs = {
  _inc?: InputMaybe<Buckets_Inc_Input>;
  _set?: InputMaybe<Buckets_Set_Input>;
  pk_columns: Buckets_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateBucketsArgs = {
  _inc?: InputMaybe<Buckets_Inc_Input>;
  _set?: InputMaybe<Buckets_Set_Input>;
  where: Buckets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateFileArgs = {
  _append?: InputMaybe<Files_Append_Input>;
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  _inc?: InputMaybe<Files_Inc_Input>;
  _prepend?: InputMaybe<Files_Prepend_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateFilesArgs = {
  _append?: InputMaybe<Files_Append_Input>;
  _delete_at_path?: InputMaybe<Files_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Files_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Files_Delete_Key_Input>;
  _inc?: InputMaybe<Files_Inc_Input>;
  _prepend?: InputMaybe<Files_Prepend_Input>;
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Controls_Vector_StoreArgs = {
  _append?: InputMaybe<Ai_Controls_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Controls_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Controls_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Controls_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Controls_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Controls_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Controls_Vector_Store_Set_Input>;
  where: Ai_Controls_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Controls_Vector_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Controls_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Controls_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Controls_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Controls_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Controls_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Controls_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Controls_Vector_Store_Set_Input>;
  pk_columns: Ai_Controls_Vector_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Controls_Vector_Store_ManyArgs = {
  updates: Array<Ai_Controls_Vector_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Evidences_Vector_StoreArgs = {
  _append?: InputMaybe<Ai_Evidences_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Evidences_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Evidences_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Evidences_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Evidences_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Evidences_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Evidences_Vector_Store_Set_Input>;
  where: Ai_Evidences_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Evidences_Vector_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Evidences_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Evidences_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Evidences_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Evidences_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Evidences_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Evidences_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Evidences_Vector_Store_Set_Input>;
  pk_columns: Ai_Evidences_Vector_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Evidences_Vector_Store_ManyArgs = {
  updates: Array<Ai_Evidences_Vector_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Files_Document_StoreArgs = {
  _append?: InputMaybe<Ai_Files_Document_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Files_Document_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Files_Document_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Files_Document_Store_Delete_Key_Input>;
  _prepend?: InputMaybe<Ai_Files_Document_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Files_Document_Store_Set_Input>;
  where: Ai_Files_Document_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Files_Document_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Files_Document_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Files_Document_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Files_Document_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Files_Document_Store_Delete_Key_Input>;
  _prepend?: InputMaybe<Ai_Files_Document_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Files_Document_Store_Set_Input>;
  pk_columns: Ai_Files_Document_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Files_Document_Store_ManyArgs = {
  updates: Array<Ai_Files_Document_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Files_Vector_StoreArgs = {
  _append?: InputMaybe<Ai_Files_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Files_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Files_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Files_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Files_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Files_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Files_Vector_Store_Set_Input>;
  where: Ai_Files_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Files_Vector_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Files_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Files_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Files_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Files_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Files_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Files_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Files_Vector_Store_Set_Input>;
  pk_columns: Ai_Files_Vector_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Files_Vector_Store_ManyArgs = {
  updates: Array<Ai_Files_Vector_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Policies_Vector_StoreArgs = {
  _append?: InputMaybe<Ai_Policies_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Policies_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Policies_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Policies_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Policies_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Policies_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Policies_Vector_Store_Set_Input>;
  where: Ai_Policies_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Policies_Vector_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Policies_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Policies_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Policies_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Policies_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Policies_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Policies_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Policies_Vector_Store_Set_Input>;
  pk_columns: Ai_Policies_Vector_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Policies_Vector_Store_ManyArgs = {
  updates: Array<Ai_Policies_Vector_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Programs_Vector_StoreArgs = {
  _append?: InputMaybe<Ai_Programs_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Programs_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Programs_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Programs_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Programs_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Programs_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Programs_Vector_Store_Set_Input>;
  where: Ai_Programs_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Programs_Vector_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Programs_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Programs_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Programs_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Programs_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Programs_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Programs_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Programs_Vector_Store_Set_Input>;
  pk_columns: Ai_Programs_Vector_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Programs_Vector_Store_ManyArgs = {
  updates: Array<Ai_Programs_Vector_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Risks_Vector_StoreArgs = {
  _append?: InputMaybe<Ai_Risks_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Risks_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Risks_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Risks_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Risks_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Risks_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Risks_Vector_Store_Set_Input>;
  where: Ai_Risks_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Risks_Vector_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Risks_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Risks_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Risks_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Risks_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Risks_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Risks_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Risks_Vector_Store_Set_Input>;
  pk_columns: Ai_Risks_Vector_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Risks_Vector_Store_ManyArgs = {
  updates: Array<Ai_Risks_Vector_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Tasks_Vector_StoreArgs = {
  _append?: InputMaybe<Ai_Tasks_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Tasks_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Tasks_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Tasks_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Tasks_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Tasks_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Tasks_Vector_Store_Set_Input>;
  where: Ai_Tasks_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Tasks_Vector_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Tasks_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Tasks_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Tasks_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Tasks_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Tasks_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Tasks_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Tasks_Vector_Store_Set_Input>;
  pk_columns: Ai_Tasks_Vector_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Tasks_Vector_Store_ManyArgs = {
  updates: Array<Ai_Tasks_Vector_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Vendors_Vector_StoreArgs = {
  _append?: InputMaybe<Ai_Vendors_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Vendors_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Vendors_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Vendors_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Vendors_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Vendors_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Vendors_Vector_Store_Set_Input>;
  where: Ai_Vendors_Vector_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Vendors_Vector_Store_By_PkArgs = {
  _append?: InputMaybe<Ai_Vendors_Vector_Store_Append_Input>;
  _delete_at_path?: InputMaybe<Ai_Vendors_Vector_Store_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ai_Vendors_Vector_Store_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ai_Vendors_Vector_Store_Delete_Key_Input>;
  _inc?: InputMaybe<Ai_Vendors_Vector_Store_Inc_Input>;
  _prepend?: InputMaybe<Ai_Vendors_Vector_Store_Prepend_Input>;
  _set?: InputMaybe<Ai_Vendors_Vector_Store_Set_Input>;
  pk_columns: Ai_Vendors_Vector_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ai_Vendors_Vector_Store_ManyArgs = {
  updates: Array<Ai_Vendors_Vector_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_All_Evidence_StatusArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootUpdate_All_Risks_Assessment_StatusArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootUpdate_All_Vendor_Questionnaires_StatusArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootUpdate_All_Vendors_Assessment_StatusArgs = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_ActionsArgs = {
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  where: Audit_Logged_Actions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_By_PkArgs = {
  _append?: InputMaybe<Audit_Logged_Actions_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Logged_Actions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Logged_Actions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Logged_Actions_Delete_Key_Input>;
  _inc?: InputMaybe<Audit_Logged_Actions_Inc_Input>;
  _prepend?: InputMaybe<Audit_Logged_Actions_Prepend_Input>;
  _set?: InputMaybe<Audit_Logged_Actions_Set_Input>;
  pk_columns: Audit_Logged_Actions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Logged_Actions_ManyArgs = {
  updates: Array<Audit_Logged_Actions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Policy_History_ViewArgs = {
  _append?: InputMaybe<Audit_Policy_History_View_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Policy_History_View_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Policy_History_View_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Policy_History_View_Delete_Key_Input>;
  _prepend?: InputMaybe<Audit_Policy_History_View_Prepend_Input>;
  _set?: InputMaybe<Audit_Policy_History_View_Set_Input>;
  where: Audit_Policy_History_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Policy_History_View_ManyArgs = {
  updates: Array<Audit_Policy_History_View_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Risk_History_ViewArgs = {
  _append?: InputMaybe<Audit_Risk_History_View_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Risk_History_View_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Risk_History_View_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Risk_History_View_Delete_Key_Input>;
  _prepend?: InputMaybe<Audit_Risk_History_View_Prepend_Input>;
  _set?: InputMaybe<Audit_Risk_History_View_Set_Input>;
  where: Audit_Risk_History_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Risk_History_View_ManyArgs = {
  updates: Array<Audit_Risk_History_View_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Vendor_History_ViewArgs = {
  _append?: InputMaybe<Audit_Vendor_History_View_Append_Input>;
  _delete_at_path?: InputMaybe<Audit_Vendor_History_View_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Audit_Vendor_History_View_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Audit_Vendor_History_View_Delete_Key_Input>;
  _prepend?: InputMaybe<Audit_Vendor_History_View_Prepend_Input>;
  _set?: InputMaybe<Audit_Vendor_History_View_Set_Input>;
  where: Audit_Vendor_History_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Audit_Vendor_History_View_ManyArgs = {
  updates: Array<Audit_Vendor_History_View_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AuthProviderRequests_ManyArgs = {
  updates: Array<AuthProviderRequests_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AuthProviders_ManyArgs = {
  updates: Array<AuthProviders_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AuthRefreshTokenTypes_ManyArgs = {
  updates: Array<AuthRefreshTokenTypes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AuthRefreshTokens_ManyArgs = {
  updates: Array<AuthRefreshTokens_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AuthRoles_ManyArgs = {
  updates: Array<AuthRoles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AuthUserProviders_ManyArgs = {
  updates: Array<AuthUserProviders_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AuthUserRoles_ManyArgs = {
  updates: Array<AuthUserRoles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AuthUserSecurityKeys_ManyArgs = {
  updates: Array<AuthUserSecurityKeys_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_IndexArgs = {
  _inc?: InputMaybe<Auth_Jackson_Index_Inc_Input>;
  _set?: InputMaybe<Auth_Jackson_Index_Set_Input>;
  where: Auth_Jackson_Index_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_Index_By_PkArgs = {
  _inc?: InputMaybe<Auth_Jackson_Index_Inc_Input>;
  _set?: InputMaybe<Auth_Jackson_Index_Set_Input>;
  pk_columns: Auth_Jackson_Index_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_Index_ManyArgs = {
  updates: Array<Auth_Jackson_Index_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_StoreArgs = {
  _set?: InputMaybe<Auth_Jackson_Store_Set_Input>;
  where: Auth_Jackson_Store_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_Store_By_PkArgs = {
  _set?: InputMaybe<Auth_Jackson_Store_Set_Input>;
  pk_columns: Auth_Jackson_Store_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_Store_ManyArgs = {
  updates: Array<Auth_Jackson_Store_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_TtlArgs = {
  _inc?: InputMaybe<Auth_Jackson_Ttl_Inc_Input>;
  _set?: InputMaybe<Auth_Jackson_Ttl_Set_Input>;
  where: Auth_Jackson_Ttl_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_Ttl_By_PkArgs = {
  _inc?: InputMaybe<Auth_Jackson_Ttl_Inc_Input>;
  _set?: InputMaybe<Auth_Jackson_Ttl_Set_Input>;
  pk_columns: Auth_Jackson_Ttl_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Jackson_Ttl_ManyArgs = {
  updates: Array<Auth_Jackson_Ttl_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_MigrationsArgs = {
  _inc?: InputMaybe<Auth_Migrations_Inc_Input>;
  _set?: InputMaybe<Auth_Migrations_Set_Input>;
  where: Auth_Migrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Migrations_By_PkArgs = {
  _inc?: InputMaybe<Auth_Migrations_Inc_Input>;
  _set?: InputMaybe<Auth_Migrations_Set_Input>;
  pk_columns: Auth_Migrations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Auth_Migrations_ManyArgs = {
  updates: Array<Auth_Migrations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Buckets_ManyArgs = {
  updates: Array<Buckets_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _set?: InputMaybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _set?: InputMaybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Answer_Ai_SourcesArgs = {
  _append?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_Key_Input>;
  _inc?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Inc_Input>;
  _prepend?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Prepend_Input>;
  _set?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Set_Input>;
  where: Client_Questionnaire_Answer_Ai_Sources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Answer_Ai_Sources_By_PkArgs = {
  _append?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Delete_Key_Input>;
  _inc?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Inc_Input>;
  _prepend?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Prepend_Input>;
  _set?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Set_Input>;
  pk_columns: Client_Questionnaire_Answer_Ai_Sources_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Answer_Ai_Sources_ManyArgs = {
  updates: Array<Client_Questionnaire_Answer_Ai_Sources_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_AnswersArgs = {
  _set?: InputMaybe<Client_Questionnaire_Answers_Set_Input>;
  where: Client_Questionnaire_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Answers_By_PkArgs = {
  _set?: InputMaybe<Client_Questionnaire_Answers_Set_Input>;
  pk_columns: Client_Questionnaire_Answers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Answers_ManyArgs = {
  updates: Array<Client_Questionnaire_Answers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Question_StatusArgs = {
  _set?: InputMaybe<Client_Questionnaire_Question_Status_Set_Input>;
  where: Client_Questionnaire_Question_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Question_Status_By_PkArgs = {
  _set?: InputMaybe<Client_Questionnaire_Question_Status_Set_Input>;
  pk_columns: Client_Questionnaire_Question_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Question_Status_ManyArgs = {
  updates: Array<Client_Questionnaire_Question_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_QuestionsArgs = {
  _append?: InputMaybe<Client_Questionnaire_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Questionnaire_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Questionnaire_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Questionnaire_Questions_Delete_Key_Input>;
  _prepend?: InputMaybe<Client_Questionnaire_Questions_Prepend_Input>;
  _set?: InputMaybe<Client_Questionnaire_Questions_Set_Input>;
  where: Client_Questionnaire_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Questions_By_PkArgs = {
  _append?: InputMaybe<Client_Questionnaire_Questions_Append_Input>;
  _delete_at_path?: InputMaybe<Client_Questionnaire_Questions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Client_Questionnaire_Questions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Client_Questionnaire_Questions_Delete_Key_Input>;
  _prepend?: InputMaybe<Client_Questionnaire_Questions_Prepend_Input>;
  _set?: InputMaybe<Client_Questionnaire_Questions_Set_Input>;
  pk_columns: Client_Questionnaire_Questions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Questions_ManyArgs = {
  updates: Array<Client_Questionnaire_Questions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_StatusArgs = {
  _set?: InputMaybe<Client_Questionnaire_Status_Set_Input>;
  where: Client_Questionnaire_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Status_By_PkArgs = {
  _set?: InputMaybe<Client_Questionnaire_Status_Set_Input>;
  pk_columns: Client_Questionnaire_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Status_ManyArgs = {
  updates: Array<Client_Questionnaire_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_QuestionnairesArgs = {
  _set?: InputMaybe<Client_Questionnaires_Set_Input>;
  where: Client_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Client_Questionnaires_Set_Input>;
  pk_columns: Client_Questionnaires_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaires_ManyArgs = {
  updates: Array<Client_Questionnaires_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Comment_UploadsArgs = {
  _set?: InputMaybe<Comment_Uploads_Set_Input>;
  where: Comment_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Comment_Uploads_By_PkArgs = {
  _set?: InputMaybe<Comment_Uploads_Set_Input>;
  pk_columns: Comment_Uploads_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Comment_Uploads_ManyArgs = {
  updates: Array<Comment_Uploads_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CommentsArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Comments_By_PkArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  pk_columns: Comments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Comments_ManyArgs = {
  updates: Array<Comments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Assessment_SourcesArgs = {
  _set?: InputMaybe<Control_Ai_Review_Assessment_Sources_Set_Input>;
  where: Control_Ai_Review_Assessment_Sources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Assessment_Sources_By_PkArgs = {
  _set?: InputMaybe<Control_Ai_Review_Assessment_Sources_Set_Input>;
  pk_columns: Control_Ai_Review_Assessment_Sources_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Assessment_Sources_ManyArgs = {
  updates: Array<Control_Ai_Review_Assessment_Sources_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_AssessmentsArgs = {
  _set?: InputMaybe<Control_Ai_Review_Assessments_Set_Input>;
  where: Control_Ai_Review_Assessments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Assessments_By_PkArgs = {
  _set?: InputMaybe<Control_Ai_Review_Assessments_Set_Input>;
  pk_columns: Control_Ai_Review_Assessments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Assessments_ManyArgs = {
  updates: Array<Control_Ai_Review_Assessments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_CriteriaArgs = {
  _set?: InputMaybe<Control_Ai_Review_Criteria_Set_Input>;
  where: Control_Ai_Review_Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Criteria_By_PkArgs = {
  _set?: InputMaybe<Control_Ai_Review_Criteria_Set_Input>;
  pk_columns: Control_Ai_Review_Criteria_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Criteria_ManyArgs = {
  updates: Array<Control_Ai_Review_Criteria_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_RunsArgs = {
  _set?: InputMaybe<Control_Ai_Review_Runs_Set_Input>;
  where: Control_Ai_Review_Runs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Runs_By_PkArgs = {
  _set?: InputMaybe<Control_Ai_Review_Runs_Set_Input>;
  pk_columns: Control_Ai_Review_Runs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Runs_ManyArgs = {
  updates: Array<Control_Ai_Review_Runs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_StatusArgs = {
  _set?: InputMaybe<Control_Ai_Review_Status_Set_Input>;
  where: Control_Ai_Review_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Status_By_PkArgs = {
  _set?: InputMaybe<Control_Ai_Review_Status_Set_Input>;
  pk_columns: Control_Ai_Review_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Ai_Review_Status_ManyArgs = {
  updates: Array<Control_Ai_Review_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_CategoriesArgs = {
  _set?: InputMaybe<Control_Categories_Set_Input>;
  where: Control_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Categories_By_PkArgs = {
  _set?: InputMaybe<Control_Categories_Set_Input>;
  pk_columns: Control_Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Categories_ManyArgs = {
  updates: Array<Control_Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_CriteriaArgs = {
  _set?: InputMaybe<Control_Criteria_Set_Input>;
  where: Control_Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Criteria_By_PkArgs = {
  _set?: InputMaybe<Control_Criteria_Set_Input>;
  pk_columns: Control_Criteria_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Criteria_ManyArgs = {
  updates: Array<Control_Criteria_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_EvidencesArgs = {
  _set?: InputMaybe<Control_Evidences_Set_Input>;
  where: Control_Evidences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Evidences_By_PkArgs = {
  _set?: InputMaybe<Control_Evidences_Set_Input>;
  pk_columns: Control_Evidences_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Evidences_ManyArgs = {
  updates: Array<Control_Evidences_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_FindingsArgs = {
  _set?: InputMaybe<Control_Findings_Set_Input>;
  where: Control_Findings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Findings_By_PkArgs = {
  _set?: InputMaybe<Control_Findings_Set_Input>;
  pk_columns: Control_Findings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Findings_ManyArgs = {
  updates: Array<Control_Findings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_FrequenciesArgs = {
  _set?: InputMaybe<Control_Frequencies_Set_Input>;
  where: Control_Frequencies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Frequencies_By_PkArgs = {
  _set?: InputMaybe<Control_Frequencies_Set_Input>;
  pk_columns: Control_Frequencies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Frequencies_ManyArgs = {
  updates: Array<Control_Frequencies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_GroupsArgs = {
  _set?: InputMaybe<Control_Groups_Set_Input>;
  where: Control_Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Groups_By_PkArgs = {
  _set?: InputMaybe<Control_Groups_Set_Input>;
  pk_columns: Control_Groups_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Groups_ManyArgs = {
  updates: Array<Control_Groups_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_PrioritiesArgs = {
  _set?: InputMaybe<Control_Priorities_Set_Input>;
  where: Control_Priorities_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Priorities_By_PkArgs = {
  _set?: InputMaybe<Control_Priorities_Set_Input>;
  pk_columns: Control_Priorities_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Priorities_ManyArgs = {
  updates: Array<Control_Priorities_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_TagsArgs = {
  _set?: InputMaybe<Control_Tags_Set_Input>;
  where: Control_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Tags_By_PkArgs = {
  _set?: InputMaybe<Control_Tags_Set_Input>;
  pk_columns: Control_Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Tags_ManyArgs = {
  updates: Array<Control_Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_TasksArgs = {
  _set?: InputMaybe<Control_Tasks_Set_Input>;
  where: Control_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Tasks_By_PkArgs = {
  _set?: InputMaybe<Control_Tasks_Set_Input>;
  pk_columns: Control_Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Tasks_ManyArgs = {
  updates: Array<Control_Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ControlsArgs = {
  _inc?: InputMaybe<Controls_Inc_Input>;
  _set?: InputMaybe<Controls_Set_Input>;
  where: Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Controls_By_PkArgs = {
  _inc?: InputMaybe<Controls_Inc_Input>;
  _set?: InputMaybe<Controls_Set_Input>;
  pk_columns: Controls_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Controls_History_StatsArgs = {
  _inc?: InputMaybe<Controls_History_Stats_Inc_Input>;
  _set?: InputMaybe<Controls_History_Stats_Set_Input>;
  where: Controls_History_Stats_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Controls_History_Stats_By_PkArgs = {
  _inc?: InputMaybe<Controls_History_Stats_Inc_Input>;
  _set?: InputMaybe<Controls_History_Stats_Set_Input>;
  pk_columns: Controls_History_Stats_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Controls_History_Stats_ManyArgs = {
  updates: Array<Controls_History_Stats_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Controls_ManyArgs = {
  updates: Array<Controls_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CriteriaArgs = {
  _set?: InputMaybe<Criteria_Set_Input>;
  where: Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Criteria_By_PkArgs = {
  _set?: InputMaybe<Criteria_Set_Input>;
  pk_columns: Criteria_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Criteria_ManyArgs = {
  updates: Array<Criteria_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Date_Field_ValuesArgs = {
  _set?: InputMaybe<Date_Field_Values_Set_Input>;
  where: Date_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Date_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Date_Field_Values_Set_Input>;
  pk_columns: Date_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Date_Field_Values_ManyArgs = {
  updates: Array<Date_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Field_ValuesArgs = {
  _set?: InputMaybe<Email_Field_Values_Set_Input>;
  where: Email_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Email_Field_Values_Set_Input>;
  pk_columns: Email_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Field_Values_ManyArgs = {
  updates: Array<Email_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_AclArgs = {
  _set?: InputMaybe<Evidence_Acl_Set_Input>;
  where: Evidence_Acl_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Acl_By_PkArgs = {
  _set?: InputMaybe<Evidence_Acl_Set_Input>;
  pk_columns: Evidence_Acl_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Acl_ManyArgs = {
  updates: Array<Evidence_Acl_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_IntegrationsArgs = {
  _append?: InputMaybe<Evidence_Integrations_Append_Input>;
  _delete_at_path?: InputMaybe<Evidence_Integrations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Evidence_Integrations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Evidence_Integrations_Delete_Key_Input>;
  _prepend?: InputMaybe<Evidence_Integrations_Prepend_Input>;
  _set?: InputMaybe<Evidence_Integrations_Set_Input>;
  where: Evidence_Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Integrations_By_PkArgs = {
  _append?: InputMaybe<Evidence_Integrations_Append_Input>;
  _delete_at_path?: InputMaybe<Evidence_Integrations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Evidence_Integrations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Evidence_Integrations_Delete_Key_Input>;
  _prepend?: InputMaybe<Evidence_Integrations_Prepend_Input>;
  _set?: InputMaybe<Evidence_Integrations_Set_Input>;
  pk_columns: Evidence_Integrations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Integrations_ManyArgs = {
  updates: Array<Evidence_Integrations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_PoliciesArgs = {
  _set?: InputMaybe<Evidence_Policies_Set_Input>;
  where: Evidence_Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Policies_By_PkArgs = {
  _set?: InputMaybe<Evidence_Policies_Set_Input>;
  pk_columns: Evidence_Policies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Policies_ManyArgs = {
  updates: Array<Evidence_Policies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_TagsArgs = {
  _set?: InputMaybe<Evidence_Tags_Set_Input>;
  where: Evidence_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Tags_By_PkArgs = {
  _set?: InputMaybe<Evidence_Tags_Set_Input>;
  pk_columns: Evidence_Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Tags_ManyArgs = {
  updates: Array<Evidence_Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Version_FileArgs = {
  _set?: InputMaybe<Evidence_Version_File_Set_Input>;
  where: Evidence_Version_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Version_File_By_PkArgs = {
  _set?: InputMaybe<Evidence_Version_File_Set_Input>;
  pk_columns: Evidence_Version_File_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Version_File_ManyArgs = {
  updates: Array<Evidence_Version_File_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_VersionsArgs = {
  _set?: InputMaybe<Evidence_Versions_Set_Input>;
  where: Evidence_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Versions_By_PkArgs = {
  _set?: InputMaybe<Evidence_Versions_Set_Input>;
  pk_columns: Evidence_Versions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Versions_ManyArgs = {
  updates: Array<Evidence_Versions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_EvidencesArgs = {
  _inc?: InputMaybe<Evidences_Inc_Input>;
  _set?: InputMaybe<Evidences_Set_Input>;
  where: Evidences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidences_By_PkArgs = {
  _inc?: InputMaybe<Evidences_Inc_Input>;
  _set?: InputMaybe<Evidences_Set_Input>;
  pk_columns: Evidences_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidences_ManyArgs = {
  updates: Array<Evidences_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Field_ConfigsArgs = {
  _set?: InputMaybe<Field_Configs_Set_Input>;
  where: Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Configs_By_PkArgs = {
  _set?: InputMaybe<Field_Configs_Set_Input>;
  pk_columns: Field_Configs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Configs_ManyArgs = {
  updates: Array<Field_Configs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Field_EntitiesArgs = {
  _set?: InputMaybe<Field_Entities_Set_Input>;
  where: Field_Entities_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Entities_By_PkArgs = {
  _set?: InputMaybe<Field_Entities_Set_Input>;
  pk_columns: Field_Entities_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Entities_ManyArgs = {
  updates: Array<Field_Entities_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Field_TypesArgs = {
  _set?: InputMaybe<Field_Types_Set_Input>;
  where: Field_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Types_By_PkArgs = {
  _set?: InputMaybe<Field_Types_Set_Input>;
  pk_columns: Field_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Types_ManyArgs = {
  updates: Array<Field_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Field_ValuesArgs = {
  _set?: InputMaybe<Field_Values_Set_Input>;
  where: Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Field_Values_Set_Input>;
  pk_columns: Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Values_ManyArgs = {
  updates: Array<Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Finding_CausesArgs = {
  _set?: InputMaybe<Finding_Causes_Set_Input>;
  where: Finding_Causes_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Finding_Causes_By_PkArgs = {
  _set?: InputMaybe<Finding_Causes_Set_Input>;
  pk_columns: Finding_Causes_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Finding_Causes_ManyArgs = {
  updates: Array<Finding_Causes_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Finding_TypesArgs = {
  _set?: InputMaybe<Finding_Types_Set_Input>;
  where: Finding_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Finding_Types_By_PkArgs = {
  _set?: InputMaybe<Finding_Types_Set_Input>;
  pk_columns: Finding_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Finding_Types_ManyArgs = {
  updates: Array<Finding_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FormsArgs = {
  _append?: InputMaybe<Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  _set?: InputMaybe<Forms_Set_Input>;
  where: Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Forms_By_PkArgs = {
  _append?: InputMaybe<Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  _set?: InputMaybe<Forms_Set_Input>;
  pk_columns: Forms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Forms_ManyArgs = {
  updates: Array<Forms_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FrameworksArgs = {
  _set?: InputMaybe<Frameworks_Set_Input>;
  where: Frameworks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Frameworks_By_PkArgs = {
  _set?: InputMaybe<Frameworks_Set_Input>;
  pk_columns: Frameworks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Frameworks_ControlsArgs = {
  _set?: InputMaybe<Frameworks_Controls_Set_Input>;
  where: Frameworks_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Frameworks_Controls_By_PkArgs = {
  _set?: InputMaybe<Frameworks_Controls_Set_Input>;
  pk_columns: Frameworks_Controls_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Frameworks_Controls_ManyArgs = {
  updates: Array<Frameworks_Controls_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Frameworks_ManyArgs = {
  updates: Array<Frameworks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FrequenciesArgs = {
  _set?: InputMaybe<Frequencies_Set_Input>;
  where: Frequencies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Frequencies_By_PkArgs = {
  _set?: InputMaybe<Frequencies_Set_Input>;
  pk_columns: Frequencies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Frequencies_ManyArgs = {
  updates: Array<Frequencies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
  updates: Array<Groups_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_NamesArgs = {
  _set?: InputMaybe<Integration_Names_Set_Input>;
  where: Integration_Names_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Names_By_PkArgs = {
  _set?: InputMaybe<Integration_Names_Set_Input>;
  pk_columns: Integration_Names_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Names_ManyArgs = {
  updates: Array<Integration_Names_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_RunsArgs = {
  _append?: InputMaybe<Integration_Runs_Append_Input>;
  _delete_at_path?: InputMaybe<Integration_Runs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Integration_Runs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Integration_Runs_Delete_Key_Input>;
  _prepend?: InputMaybe<Integration_Runs_Prepend_Input>;
  _set?: InputMaybe<Integration_Runs_Set_Input>;
  where: Integration_Runs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Runs_By_PkArgs = {
  _append?: InputMaybe<Integration_Runs_Append_Input>;
  _delete_at_path?: InputMaybe<Integration_Runs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Integration_Runs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Integration_Runs_Delete_Key_Input>;
  _prepend?: InputMaybe<Integration_Runs_Prepend_Input>;
  _set?: InputMaybe<Integration_Runs_Set_Input>;
  pk_columns: Integration_Runs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Integration_Runs_ManyArgs = {
  updates: Array<Integration_Runs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_IntegrationsArgs = {
  _set?: InputMaybe<Integrations_Set_Input>;
  where: Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Integrations_By_PkArgs = {
  _set?: InputMaybe<Integrations_Set_Input>;
  pk_columns: Integrations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Integrations_ManyArgs = {
  updates: Array<Integrations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_InvitationsArgs = {
  _set?: InputMaybe<Invitations_Set_Input>;
  where: Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Invitations_By_PkArgs = {
  _set?: InputMaybe<Invitations_Set_Input>;
  pk_columns: Invitations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Invitations_ManyArgs = {
  updates: Array<Invitations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Delivery_TypesArgs = {
  _set?: InputMaybe<Notification_Delivery_Types_Set_Input>;
  where: Notification_Delivery_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Delivery_Types_By_PkArgs = {
  _set?: InputMaybe<Notification_Delivery_Types_Set_Input>;
  pk_columns: Notification_Delivery_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Delivery_Types_ManyArgs = {
  updates: Array<Notification_Delivery_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Disabled_SettingsArgs = {
  _set?: InputMaybe<Notification_Disabled_Settings_Set_Input>;
  where: Notification_Disabled_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Disabled_Settings_By_PkArgs = {
  _set?: InputMaybe<Notification_Disabled_Settings_Set_Input>;
  pk_columns: Notification_Disabled_Settings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Disabled_Settings_ManyArgs = {
  updates: Array<Notification_Disabled_Settings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_TypesArgs = {
  _set?: InputMaybe<Notification_Types_Set_Input>;
  where: Notification_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Types_By_PkArgs = {
  _set?: InputMaybe<Notification_Types_Set_Input>;
  pk_columns: Notification_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Types_ManyArgs = {
  updates: Array<Notification_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _append?: InputMaybe<Notifications_Append_Input>;
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Field_ValuesArgs = {
  _inc?: InputMaybe<Number_Field_Values_Inc_Input>;
  _set?: InputMaybe<Number_Field_Values_Set_Input>;
  where: Number_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Field_Values_By_PkArgs = {
  _inc?: InputMaybe<Number_Field_Values_Inc_Input>;
  _set?: InputMaybe<Number_Field_Values_Set_Input>;
  pk_columns: Number_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Field_Values_ManyArgs = {
  updates: Array<Number_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Evidence_TagsArgs = {
  _set?: InputMaybe<Organization_Evidence_Tags_Set_Input>;
  where: Organization_Evidence_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Evidence_Tags_By_PkArgs = {
  _set?: InputMaybe<Organization_Evidence_Tags_Set_Input>;
  pk_columns: Organization_Evidence_Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Evidence_Tags_ManyArgs = {
  updates: Array<Organization_Evidence_Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_IntegrationsArgs = {
  _set?: InputMaybe<Organization_Integrations_Set_Input>;
  where: Organization_Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Integrations_By_PkArgs = {
  _set?: InputMaybe<Organization_Integrations_Set_Input>;
  pk_columns: Organization_Integrations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Integrations_ManyArgs = {
  updates: Array<Organization_Integrations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Risk_CategoriesArgs = {
  _set?: InputMaybe<Organization_Risk_Categories_Set_Input>;
  where: Organization_Risk_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Risk_Categories_By_PkArgs = {
  _set?: InputMaybe<Organization_Risk_Categories_Set_Input>;
  pk_columns: Organization_Risk_Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Risk_Categories_ManyArgs = {
  updates: Array<Organization_Risk_Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Risk_TagsArgs = {
  _set?: InputMaybe<Organization_Risk_Tags_Set_Input>;
  where: Organization_Risk_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Risk_Tags_By_PkArgs = {
  _set?: InputMaybe<Organization_Risk_Tags_Set_Input>;
  pk_columns: Organization_Risk_Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Risk_Tags_ManyArgs = {
  updates: Array<Organization_Risk_Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Template_FrameworksArgs = {
  _set?: InputMaybe<Organization_Template_Frameworks_Set_Input>;
  where: Organization_Template_Frameworks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Template_Frameworks_By_PkArgs = {
  _set?: InputMaybe<Organization_Template_Frameworks_Set_Input>;
  pk_columns: Organization_Template_Frameworks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Template_Frameworks_ManyArgs = {
  updates: Array<Organization_Template_Frameworks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_TenantsArgs = {
  _set?: InputMaybe<Organization_Tenants_Set_Input>;
  where: Organization_Tenants_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Tenants_By_PkArgs = {
  _set?: InputMaybe<Organization_Tenants_Set_Input>;
  pk_columns: Organization_Tenants_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Tenants_ManyArgs = {
  updates: Array<Organization_Tenants_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_UsersArgs = {
  _set?: InputMaybe<Organization_Users_Set_Input>;
  where: Organization_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Users_By_PkArgs = {
  _set?: InputMaybe<Organization_Users_Set_Input>;
  pk_columns: Organization_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Users_ManyArgs = {
  updates: Array<Organization_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Vendor_Risk_LevelsArgs = {
  _set?: InputMaybe<Organization_Vendor_Risk_Levels_Set_Input>;
  where: Organization_Vendor_Risk_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Vendor_Risk_Levels_By_PkArgs = {
  _set?: InputMaybe<Organization_Vendor_Risk_Levels_Set_Input>;
  pk_columns: Organization_Vendor_Risk_Levels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Vendor_Risk_Levels_ManyArgs = {
  updates: Array<Organization_Vendor_Risk_Levels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Vendor_TiersArgs = {
  _set?: InputMaybe<Organization_Vendor_Tiers_Set_Input>;
  where: Organization_Vendor_Tiers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Vendor_Tiers_By_PkArgs = {
  _set?: InputMaybe<Organization_Vendor_Tiers_Set_Input>;
  pk_columns: Organization_Vendor_Tiers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Vendor_Tiers_ManyArgs = {
  updates: Array<Organization_Vendor_Tiers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _set?: InputMaybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _set?: InputMaybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organizations_ManyArgs = {
  updates: Array<Organizations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PermissionsArgs = {
  _set?: InputMaybe<Permissions_Set_Input>;
  where: Permissions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Permissions_By_PkArgs = {
  _set?: InputMaybe<Permissions_Set_Input>;
  pk_columns: Permissions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Permissions_ManyArgs = {
  updates: Array<Permissions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PoliciesArgs = {
  _inc?: InputMaybe<Policies_Inc_Input>;
  _set?: InputMaybe<Policies_Set_Input>;
  where: Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policies_By_PkArgs = {
  _inc?: InputMaybe<Policies_Inc_Input>;
  _set?: InputMaybe<Policies_Set_Input>;
  pk_columns: Policies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policies_ManyArgs = {
  updates: Array<Policies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_StatusesArgs = {
  _set?: InputMaybe<Policy_Acknowledgement_Statuses_Set_Input>;
  where: Policy_Acknowledgement_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_Statuses_By_PkArgs = {
  _set?: InputMaybe<Policy_Acknowledgement_Statuses_Set_Input>;
  pk_columns: Policy_Acknowledgement_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_Statuses_ManyArgs = {
  updates: Array<Policy_Acknowledgement_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_UsersArgs = {
  _set?: InputMaybe<Policy_Acknowledgement_Users_Set_Input>;
  where: Policy_Acknowledgement_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_Users_By_PkArgs = {
  _set?: InputMaybe<Policy_Acknowledgement_Users_Set_Input>;
  pk_columns: Policy_Acknowledgement_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_Users_ManyArgs = {
  updates: Array<Policy_Acknowledgement_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_AcknowledgementsArgs = {
  _set?: InputMaybe<Policy_Acknowledgements_Set_Input>;
  where: Policy_Acknowledgements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgements_By_PkArgs = {
  _set?: InputMaybe<Policy_Acknowledgements_Set_Input>;
  pk_columns: Policy_Acknowledgements_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgements_ManyArgs = {
  updates: Array<Policy_Acknowledgements_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_StatusesArgs = {
  _set?: InputMaybe<Policy_Approval_Statuses_Set_Input>;
  where: Policy_Approval_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_Statuses_By_PkArgs = {
  _set?: InputMaybe<Policy_Approval_Statuses_Set_Input>;
  pk_columns: Policy_Approval_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_Statuses_ManyArgs = {
  updates: Array<Policy_Approval_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_UsersArgs = {
  _set?: InputMaybe<Policy_Approval_Users_Set_Input>;
  where: Policy_Approval_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_Users_By_PkArgs = {
  _set?: InputMaybe<Policy_Approval_Users_Set_Input>;
  pk_columns: Policy_Approval_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_Users_ManyArgs = {
  updates: Array<Policy_Approval_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_ApprovalsArgs = {
  _set?: InputMaybe<Policy_Approvals_Set_Input>;
  where: Policy_Approvals_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approvals_By_PkArgs = {
  _set?: InputMaybe<Policy_Approvals_Set_Input>;
  pk_columns: Policy_Approvals_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approvals_ManyArgs = {
  updates: Array<Policy_Approvals_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_ApproversArgs = {
  _set?: InputMaybe<Policy_Approvers_Set_Input>;
  where: Policy_Approvers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approvers_By_PkArgs = {
  _set?: InputMaybe<Policy_Approvers_Set_Input>;
  pk_columns: Policy_Approvers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approvers_ManyArgs = {
  updates: Array<Policy_Approvers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_StatusesArgs = {
  _set?: InputMaybe<Policy_Statuses_Set_Input>;
  where: Policy_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Statuses_By_PkArgs = {
  _set?: InputMaybe<Policy_Statuses_Set_Input>;
  pk_columns: Policy_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Statuses_ManyArgs = {
  updates: Array<Policy_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_TasksArgs = {
  _set?: InputMaybe<Policy_Tasks_Set_Input>;
  where: Policy_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Tasks_By_PkArgs = {
  _set?: InputMaybe<Policy_Tasks_Set_Input>;
  pk_columns: Policy_Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Tasks_ManyArgs = {
  updates: Array<Policy_Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_TypesArgs = {
  _set?: InputMaybe<Policy_Types_Set_Input>;
  where: Policy_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Types_By_PkArgs = {
  _set?: InputMaybe<Policy_Types_Set_Input>;
  pk_columns: Policy_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Types_ManyArgs = {
  updates: Array<Policy_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Version_ContentArgs = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_text?: InputMaybe<Scalars['String']['input']>;
  policy_version_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Version_StatusesArgs = {
  _set?: InputMaybe<Policy_Version_Statuses_Set_Input>;
  where: Policy_Version_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Version_Statuses_By_PkArgs = {
  _set?: InputMaybe<Policy_Version_Statuses_Set_Input>;
  pk_columns: Policy_Version_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Version_Statuses_ManyArgs = {
  updates: Array<Policy_Version_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_VersionsArgs = {
  _inc?: InputMaybe<Policy_Versions_Inc_Input>;
  _set?: InputMaybe<Policy_Versions_Set_Input>;
  where: Policy_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Versions_By_PkArgs = {
  _inc?: InputMaybe<Policy_Versions_Inc_Input>;
  _set?: InputMaybe<Policy_Versions_Set_Input>;
  pk_columns: Policy_Versions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Versions_ManyArgs = {
  updates: Array<Policy_Versions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Program_ControlsArgs = {
  _set?: InputMaybe<Program_Controls_Set_Input>;
  where: Program_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Program_Controls_By_PkArgs = {
  _set?: InputMaybe<Program_Controls_Set_Input>;
  pk_columns: Program_Controls_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Program_Controls_ManyArgs = {
  updates: Array<Program_Controls_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Program_Field_ConfigsArgs = {
  _set?: InputMaybe<Program_Field_Configs_Set_Input>;
  where: Program_Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Program_Field_Configs_By_PkArgs = {
  _set?: InputMaybe<Program_Field_Configs_Set_Input>;
  pk_columns: Program_Field_Configs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Program_Field_Configs_ManyArgs = {
  updates: Array<Program_Field_Configs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProgramsArgs = {
  _inc?: InputMaybe<Programs_Inc_Input>;
  _set?: InputMaybe<Programs_Set_Input>;
  where: Programs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Programs_By_PkArgs = {
  _inc?: InputMaybe<Programs_Inc_Input>;
  _set?: InputMaybe<Programs_Set_Input>;
  pk_columns: Programs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Programs_ManyArgs = {
  updates: Array<Programs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaire_UploadsArgs = {
  _set?: InputMaybe<Questionnaire_Uploads_Set_Input>;
  where: Questionnaire_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Uploads_By_PkArgs = {
  _set?: InputMaybe<Questionnaire_Uploads_Set_Input>;
  pk_columns: Questionnaire_Uploads_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Uploads_ManyArgs = {
  updates: Array<Questionnaire_Uploads_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_QuestionnairesArgs = {
  _set?: InputMaybe<Questionnaires_Set_Input>;
  where: Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Questionnaires_Set_Input>;
  pk_columns: Questionnaires_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaires_ManyArgs = {
  updates: Array<Questionnaires_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Report_TypesArgs = {
  _set?: InputMaybe<Report_Types_Set_Input>;
  where: Report_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Report_Types_By_PkArgs = {
  _set?: InputMaybe<Report_Types_Set_Input>;
  pk_columns: Report_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Report_Types_ManyArgs = {
  updates: Array<Report_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ReportsArgs = {
  _append?: InputMaybe<Reports_Append_Input>;
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  _set?: InputMaybe<Reports_Set_Input>;
  where: Reports_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reports_By_PkArgs = {
  _append?: InputMaybe<Reports_Append_Input>;
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  _set?: InputMaybe<Reports_Set_Input>;
  pk_columns: Reports_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reports_ManyArgs = {
  updates: Array<Reports_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Review_FilesArgs = {
  _set?: InputMaybe<Review_Files_Set_Input>;
  where: Review_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Files_By_PkArgs = {
  _set?: InputMaybe<Review_Files_Set_Input>;
  pk_columns: Review_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Files_ManyArgs = {
  updates: Array<Review_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ReviewsArgs = {
  _set?: InputMaybe<Reviews_Set_Input>;
  where: Reviews_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reviews_By_PkArgs = {
  _set?: InputMaybe<Reviews_Set_Input>;
  pk_columns: Reviews_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reviews_ManyArgs = {
  updates: Array<Reviews_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_CategoriesArgs = {
  _set?: InputMaybe<Risk_Categories_Set_Input>;
  where: Risk_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Categories_By_PkArgs = {
  _set?: InputMaybe<Risk_Categories_Set_Input>;
  pk_columns: Risk_Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Categories_ManyArgs = {
  updates: Array<Risk_Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_ControlsArgs = {
  _set?: InputMaybe<Risk_Controls_Set_Input>;
  where: Risk_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Controls_By_PkArgs = {
  _set?: InputMaybe<Risk_Controls_Set_Input>;
  pk_columns: Risk_Controls_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Controls_ManyArgs = {
  updates: Array<Risk_Controls_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_DocumentsArgs = {
  _set?: InputMaybe<Risk_Documents_Set_Input>;
  where: Risk_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Documents_By_PkArgs = {
  _set?: InputMaybe<Risk_Documents_Set_Input>;
  pk_columns: Risk_Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Documents_ManyArgs = {
  updates: Array<Risk_Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_ImpactsArgs = {
  _set?: InputMaybe<Risk_Impacts_Set_Input>;
  where: Risk_Impacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Impacts_By_PkArgs = {
  _set?: InputMaybe<Risk_Impacts_Set_Input>;
  pk_columns: Risk_Impacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Impacts_ManyArgs = {
  updates: Array<Risk_Impacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Inherent_LevelsArgs = {
  _set?: InputMaybe<Risk_Inherent_Levels_Set_Input>;
  where: Risk_Inherent_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Inherent_Levels_By_PkArgs = {
  _set?: InputMaybe<Risk_Inherent_Levels_Set_Input>;
  pk_columns: Risk_Inherent_Levels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Inherent_Levels_ManyArgs = {
  updates: Array<Risk_Inherent_Levels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_LevelsArgs = {
  _set?: InputMaybe<Risk_Levels_Set_Input>;
  where: Risk_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Levels_By_PkArgs = {
  _set?: InputMaybe<Risk_Levels_Set_Input>;
  pk_columns: Risk_Levels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Levels_ManyArgs = {
  updates: Array<Risk_Levels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_LikelihoodsArgs = {
  _set?: InputMaybe<Risk_Likelihoods_Set_Input>;
  where: Risk_Likelihoods_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Likelihoods_By_PkArgs = {
  _set?: InputMaybe<Risk_Likelihoods_Set_Input>;
  pk_columns: Risk_Likelihoods_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Likelihoods_ManyArgs = {
  updates: Array<Risk_Likelihoods_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Residual_LevelsArgs = {
  _set?: InputMaybe<Risk_Residual_Levels_Set_Input>;
  where: Risk_Residual_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Residual_Levels_By_PkArgs = {
  _set?: InputMaybe<Risk_Residual_Levels_Set_Input>;
  pk_columns: Risk_Residual_Levels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Residual_Levels_ManyArgs = {
  updates: Array<Risk_Residual_Levels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_TagsArgs = {
  _set?: InputMaybe<Risk_Tags_Set_Input>;
  where: Risk_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Tags_By_PkArgs = {
  _set?: InputMaybe<Risk_Tags_Set_Input>;
  pk_columns: Risk_Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Tags_ManyArgs = {
  updates: Array<Risk_Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_TasksArgs = {
  _set?: InputMaybe<Risk_Tasks_Set_Input>;
  where: Risk_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Tasks_By_PkArgs = {
  _set?: InputMaybe<Risk_Tasks_Set_Input>;
  pk_columns: Risk_Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Tasks_ManyArgs = {
  updates: Array<Risk_Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RisksArgs = {
  _inc?: InputMaybe<Risks_Inc_Input>;
  _set?: InputMaybe<Risks_Set_Input>;
  where: Risks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risks_By_PkArgs = {
  _inc?: InputMaybe<Risks_Inc_Input>;
  _set?: InputMaybe<Risks_Set_Input>;
  pk_columns: Risks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risks_ManyArgs = {
  updates: Array<Risks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Role_PermissionsArgs = {
  _set?: InputMaybe<Role_Permissions_Set_Input>;
  where: Role_Permissions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Permissions_By_PkArgs = {
  _set?: InputMaybe<Role_Permissions_Set_Input>;
  pk_columns: Role_Permissions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Role_Permissions_ManyArgs = {
  updates: Array<Role_Permissions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_ConfigsArgs = {
  _set?: InputMaybe<Select_Field_Configs_Set_Input>;
  where: Select_Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_Configs_By_PkArgs = {
  _set?: InputMaybe<Select_Field_Configs_Set_Input>;
  pk_columns: Select_Field_Configs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_Configs_ManyArgs = {
  updates: Array<Select_Field_Configs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_OptionsArgs = {
  _set?: InputMaybe<Select_Field_Options_Set_Input>;
  where: Select_Field_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_Options_By_PkArgs = {
  _set?: InputMaybe<Select_Field_Options_Set_Input>;
  pk_columns: Select_Field_Options_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_Options_ManyArgs = {
  updates: Array<Select_Field_Options_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_ValuesArgs = {
  _set?: InputMaybe<Select_Field_Values_Set_Input>;
  where: Select_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Select_Field_Values_Set_Input>;
  pk_columns: Select_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_Values_ManyArgs = {
  updates: Array<Select_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_System_RolesArgs = {
  _set?: InputMaybe<System_Roles_Set_Input>;
  where: System_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_System_Roles_By_PkArgs = {
  _set?: InputMaybe<System_Roles_Set_Input>;
  pk_columns: System_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_System_Roles_ManyArgs = {
  updates: Array<System_Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_OwnersArgs = {
  _set?: InputMaybe<Task_Owners_Set_Input>;
  where: Task_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Owners_By_PkArgs = {
  _set?: InputMaybe<Task_Owners_Set_Input>;
  pk_columns: Task_Owners_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Owners_ManyArgs = {
  updates: Array<Task_Owners_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _inc?: InputMaybe<Tasks_Inc_Input>;
  _set?: InputMaybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _inc?: InputMaybe<Tasks_Inc_Input>;
  _set?: InputMaybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Control_Ai_Review_CriteriaArgs = {
  _set?: InputMaybe<Template_Control_Ai_Review_Criteria_Set_Input>;
  where: Template_Control_Ai_Review_Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Control_Ai_Review_Criteria_By_PkArgs = {
  _set?: InputMaybe<Template_Control_Ai_Review_Criteria_Set_Input>;
  pk_columns: Template_Control_Ai_Review_Criteria_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Control_Ai_Review_Criteria_ManyArgs = {
  updates: Array<Template_Control_Ai_Review_Criteria_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Template_ControlsArgs = {
  _append?: InputMaybe<Template_Controls_Append_Input>;
  _delete_at_path?: InputMaybe<Template_Controls_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Template_Controls_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Template_Controls_Delete_Key_Input>;
  _prepend?: InputMaybe<Template_Controls_Prepend_Input>;
  _set?: InputMaybe<Template_Controls_Set_Input>;
  where: Template_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Controls_By_PkArgs = {
  _append?: InputMaybe<Template_Controls_Append_Input>;
  _delete_at_path?: InputMaybe<Template_Controls_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Template_Controls_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Template_Controls_Delete_Key_Input>;
  _prepend?: InputMaybe<Template_Controls_Prepend_Input>;
  _set?: InputMaybe<Template_Controls_Set_Input>;
  pk_columns: Template_Controls_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Controls_ManyArgs = {
  updates: Array<Template_Controls_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Template_FrameworksArgs = {
  _set?: InputMaybe<Template_Frameworks_Set_Input>;
  where: Template_Frameworks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Frameworks_By_PkArgs = {
  _set?: InputMaybe<Template_Frameworks_Set_Input>;
  pk_columns: Template_Frameworks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Frameworks_ControlsArgs = {
  _set?: InputMaybe<Template_Frameworks_Controls_Set_Input>;
  where: Template_Frameworks_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Frameworks_Controls_By_PkArgs = {
  _set?: InputMaybe<Template_Frameworks_Controls_Set_Input>;
  pk_columns: Template_Frameworks_Controls_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Frameworks_Controls_ManyArgs = {
  updates: Array<Template_Frameworks_Controls_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Frameworks_ManyArgs = {
  updates: Array<Template_Frameworks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Template_TasksArgs = {
  _set?: InputMaybe<Template_Tasks_Set_Input>;
  where: Template_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Tasks_By_PkArgs = {
  _set?: InputMaybe<Template_Tasks_Set_Input>;
  pk_columns: Template_Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Template_Tasks_ManyArgs = {
  updates: Array<Template_Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Text_Field_ValuesArgs = {
  _set?: InputMaybe<Text_Field_Values_Set_Input>;
  where: Text_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Text_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Text_Field_Values_Set_Input>;
  pk_columns: Text_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Text_Field_Values_ManyArgs = {
  updates: Array<Text_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Treatment_PlanArgs = {
  _set?: InputMaybe<Treatment_Plan_Set_Input>;
  where: Treatment_Plan_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Treatment_Plan_By_PkArgs = {
  _set?: InputMaybe<Treatment_Plan_Set_Input>;
  pk_columns: Treatment_Plan_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Treatment_Plan_ManyArgs = {
  updates: Array<Treatment_Plan_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Url_Field_ValuesArgs = {
  _set?: InputMaybe<Url_Field_Values_Set_Input>;
  where: Url_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Url_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Url_Field_Values_Set_Input>;
  pk_columns: Url_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Url_Field_Values_ManyArgs = {
  updates: Array<Url_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_NotificationsArgs = {
  _set?: InputMaybe<User_Notifications_Set_Input>;
  where: User_Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Notifications_By_PkArgs = {
  _set?: InputMaybe<User_Notifications_Set_Input>;
  pk_columns: User_Notifications_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Notifications_ManyArgs = {
  updates: Array<User_Notifications_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Approval_StatusesArgs = {
  _set?: InputMaybe<Vendor_Approval_Statuses_Set_Input>;
  where: Vendor_Approval_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Approval_Statuses_By_PkArgs = {
  _set?: InputMaybe<Vendor_Approval_Statuses_Set_Input>;
  pk_columns: Vendor_Approval_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Approval_Statuses_ManyArgs = {
  updates: Array<Vendor_Approval_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_DocumentsArgs = {
  _set?: InputMaybe<Vendor_Documents_Set_Input>;
  where: Vendor_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Documents_By_PkArgs = {
  _set?: InputMaybe<Vendor_Documents_Set_Input>;
  pk_columns: Vendor_Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Documents_ManyArgs = {
  updates: Array<Vendor_Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_FindingsArgs = {
  _set?: InputMaybe<Vendor_Findings_Set_Input>;
  where: Vendor_Findings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Findings_By_PkArgs = {
  _set?: InputMaybe<Vendor_Findings_Set_Input>;
  pk_columns: Vendor_Findings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Findings_ManyArgs = {
  updates: Array<Vendor_Findings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Assessment_SourcesArgs = {
  _inc?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Inc_Input>;
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Set_Input>;
  where: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Assessment_Sources_By_PkArgs = {
  _inc?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Inc_Input>;
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Set_Input>;
  pk_columns: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Assessment_Sources_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_AssessmentsArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Set_Input>;
  where: Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Assessments_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Set_Input>;
  pk_columns: Vendor_Questionnaire_Ai_Review_Assessments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Assessments_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Ai_Review_Assessments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Run_StatusArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Set_Input>;
  where: Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Run_Status_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Set_Input>;
  pk_columns: Vendor_Questionnaire_Ai_Review_Run_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Run_Status_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Ai_Review_Run_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_RunsArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Set_Input>;
  where: Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Runs_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Set_Input>;
  pk_columns: Vendor_Questionnaire_Ai_Review_Runs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Ai_Review_Runs_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Ai_Review_Runs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Answer_StatusesArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Set_Input>;
  where: Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Answer_Statuses_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Set_Input>;
  pk_columns: Vendor_Questionnaire_Form_Answer_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Answer_Statuses_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Form_Answer_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_AnswersArgs = {
  _append?: InputMaybe<Vendor_Questionnaire_Form_Answers_Append_Input>;
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Key_Input>;
  _prepend?: InputMaybe<Vendor_Questionnaire_Form_Answers_Prepend_Input>;
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answers_Set_Input>;
  where: Vendor_Questionnaire_Form_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Answers_By_PkArgs = {
  _append?: InputMaybe<Vendor_Questionnaire_Form_Answers_Append_Input>;
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Key_Input>;
  _prepend?: InputMaybe<Vendor_Questionnaire_Form_Answers_Prepend_Input>;
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answers_Set_Input>;
  pk_columns: Vendor_Questionnaire_Form_Answers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Answers_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Form_Answers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_UploadsArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Set_Input>;
  where: Vendor_Questionnaire_Form_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Uploads_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Set_Input>;
  pk_columns: Vendor_Questionnaire_Form_Uploads_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Form_Uploads_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Form_Uploads_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_FormsArgs = {
  _append?: InputMaybe<Vendor_Questionnaire_Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vendor_Questionnaire_Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Vendor_Questionnaire_Forms_Prepend_Input>;
  _set?: InputMaybe<Vendor_Questionnaire_Forms_Set_Input>;
  where: Vendor_Questionnaire_Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Forms_By_PkArgs = {
  _append?: InputMaybe<Vendor_Questionnaire_Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vendor_Questionnaire_Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Vendor_Questionnaire_Forms_Prepend_Input>;
  _set?: InputMaybe<Vendor_Questionnaire_Forms_Set_Input>;
  pk_columns: Vendor_Questionnaire_Forms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Forms_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Forms_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_StatusesArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Statuses_Set_Input>;
  where: Vendor_Questionnaire_Statuses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Statuses_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Statuses_Set_Input>;
  pk_columns: Vendor_Questionnaire_Statuses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Statuses_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Statuses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_UploadsArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Uploads_Set_Input>;
  where: Vendor_Questionnaire_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Uploads_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaire_Uploads_Set_Input>;
  pk_columns: Vendor_Questionnaire_Uploads_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaire_Uploads_ManyArgs = {
  updates: Array<Vendor_Questionnaire_Uploads_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_QuestionnairesArgs = {
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  where: Vendor_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  pk_columns: Vendor_Questionnaires_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaires_ManyArgs = {
  updates: Array<Vendor_Questionnaires_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_RisksArgs = {
  _set?: InputMaybe<Vendor_Risks_Set_Input>;
  where: Vendor_Risks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Risks_By_PkArgs = {
  _set?: InputMaybe<Vendor_Risks_Set_Input>;
  pk_columns: Vendor_Risks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Risks_ManyArgs = {
  updates: Array<Vendor_Risks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_TasksArgs = {
  _set?: InputMaybe<Vendor_Tasks_Set_Input>;
  where: Vendor_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Tasks_By_PkArgs = {
  _set?: InputMaybe<Vendor_Tasks_Set_Input>;
  pk_columns: Vendor_Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Tasks_ManyArgs = {
  updates: Array<Vendor_Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_VendorsArgs = {
  _inc?: InputMaybe<Vendors_Inc_Input>;
  _set?: InputMaybe<Vendors_Set_Input>;
  where: Vendors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendors_By_PkArgs = {
  _inc?: InputMaybe<Vendors_Inc_Input>;
  _set?: InputMaybe<Vendors_Set_Input>;
  pk_columns: Vendors_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendors_ManyArgs = {
  updates: Array<Vendors_Updates>;
};

/** enum table for notification delivery types like email, in-platform */
export type Notification_Delivery_Types = {
  __typename?: 'notification_delivery_types';
  /** An array relationship */
  notification_disabled_settings: Array<Notification_Disabled_Settings>;
  /** An aggregate relationship */
  notification_disabled_settings_aggregate: Notification_Disabled_Settings_Aggregate;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  value: Scalars['String']['output'];
};

/** enum table for notification delivery types like email, in-platform */
export type Notification_Delivery_TypesNotification_Disabled_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** enum table for notification delivery types like email, in-platform */
export type Notification_Delivery_TypesNotification_Disabled_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** enum table for notification delivery types like email, in-platform */
export type Notification_Delivery_TypesUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** enum table for notification delivery types like email, in-platform */
export type Notification_Delivery_TypesUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** aggregated selection of "notification_delivery_types" */
export type Notification_Delivery_Types_Aggregate = {
  __typename?: 'notification_delivery_types_aggregate';
  aggregate?: Maybe<Notification_Delivery_Types_Aggregate_Fields>;
  nodes: Array<Notification_Delivery_Types>;
};

/** aggregate fields of "notification_delivery_types" */
export type Notification_Delivery_Types_Aggregate_Fields = {
  __typename?: 'notification_delivery_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Delivery_Types_Max_Fields>;
  min?: Maybe<Notification_Delivery_Types_Min_Fields>;
};

/** aggregate fields of "notification_delivery_types" */
export type Notification_Delivery_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Delivery_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_delivery_types". All fields are combined with a logical 'AND'. */
export type Notification_Delivery_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Delivery_Types_Bool_Exp>>;
  _not?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Delivery_Types_Bool_Exp>>;
  notification_disabled_settings?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
  notification_disabled_settings_aggregate?: InputMaybe<Notification_Disabled_Settings_Aggregate_Bool_Exp>;
  user_notifications?: InputMaybe<User_Notifications_Bool_Exp>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_delivery_types" */
export enum Notification_Delivery_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationDeliveryTypesPkey = 'notification_delivery_types_pkey',
}

export enum Notification_Delivery_Types_Enum {
  Email = 'email',
  Platform = 'platform',
}

/** Boolean expression to compare columns of type "notification_delivery_types_enum". All fields are combined with logical 'AND'. */
export type Notification_Delivery_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Delivery_Types_Enum>;
  _in?: InputMaybe<Array<Notification_Delivery_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Notification_Delivery_Types_Enum>;
  _nin?: InputMaybe<Array<Notification_Delivery_Types_Enum>>;
};

/** input type for inserting data into table "notification_delivery_types" */
export type Notification_Delivery_Types_Insert_Input = {
  notification_disabled_settings?: InputMaybe<Notification_Disabled_Settings_Arr_Rel_Insert_Input>;
  user_notifications?: InputMaybe<User_Notifications_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notification_Delivery_Types_Max_Fields = {
  __typename?: 'notification_delivery_types_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notification_Delivery_Types_Min_Fields = {
  __typename?: 'notification_delivery_types_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notification_delivery_types" */
export type Notification_Delivery_Types_Mutation_Response = {
  __typename?: 'notification_delivery_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Delivery_Types>;
};

/** input type for inserting object relation for remote table "notification_delivery_types" */
export type Notification_Delivery_Types_Obj_Rel_Insert_Input = {
  data: Notification_Delivery_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Delivery_Types_On_Conflict>;
};

/** on_conflict condition type for table "notification_delivery_types" */
export type Notification_Delivery_Types_On_Conflict = {
  constraint: Notification_Delivery_Types_Constraint;
  update_columns?: Array<Notification_Delivery_Types_Update_Column>;
  where?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_delivery_types". */
export type Notification_Delivery_Types_Order_By = {
  notification_disabled_settings_aggregate?: InputMaybe<Notification_Disabled_Settings_Aggregate_Order_By>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_delivery_types */
export type Notification_Delivery_Types_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "notification_delivery_types" */
export enum Notification_Delivery_Types_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "notification_delivery_types" */
export type Notification_Delivery_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notification_delivery_types" */
export type Notification_Delivery_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Delivery_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Delivery_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_delivery_types" */
export enum Notification_Delivery_Types_Update_Column {
  /** column name */
  Value = 'value',
}

export type Notification_Delivery_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Delivery_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Delivery_Types_Bool_Exp;
};

/** entries for disabling a notification delivery type */
export type Notification_Disabled_Settings = {
  __typename?: 'notification_disabled_settings';
  created_at: Scalars['timestamptz']['output'];
  delivery_type: Notification_Delivery_Types_Enum;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notification_delivery_type: Notification_Delivery_Types;
  /** An object relationship */
  notification_type: Notification_Types;
  type: Notification_Types_Enum;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "notification_disabled_settings" */
export type Notification_Disabled_Settings_Aggregate = {
  __typename?: 'notification_disabled_settings_aggregate';
  aggregate?: Maybe<Notification_Disabled_Settings_Aggregate_Fields>;
  nodes: Array<Notification_Disabled_Settings>;
};

export type Notification_Disabled_Settings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Notification_Disabled_Settings_Aggregate_Bool_Exp_Count>;
};

export type Notification_Disabled_Settings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notification_disabled_settings" */
export type Notification_Disabled_Settings_Aggregate_Fields = {
  __typename?: 'notification_disabled_settings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Disabled_Settings_Max_Fields>;
  min?: Maybe<Notification_Disabled_Settings_Min_Fields>;
};

/** aggregate fields of "notification_disabled_settings" */
export type Notification_Disabled_Settings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Disabled_Settings_Max_Order_By>;
  min?: InputMaybe<Notification_Disabled_Settings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Arr_Rel_Insert_Input = {
  data: Array<Notification_Disabled_Settings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Disabled_Settings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_disabled_settings". All fields are combined with a logical 'AND'. */
export type Notification_Disabled_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Disabled_Settings_Bool_Exp>>;
  _not?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Disabled_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_delivery_type?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
  notification_type?: InputMaybe<Notification_Types_Bool_Exp>;
  type?: InputMaybe<Notification_Types_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_disabled_settings" */
export enum Notification_Disabled_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationDisabledSettingsPkey = 'notification_disabled_settings_pkey',
  /** unique or primary key constraint on columns "delivery_type", "user_id", "type" */
  NotificationDisabledSettingsUserIdTypeDeliveryTypeKey = 'notification_disabled_settings_user_id_type_delivery_type_key',
}

/** input type for inserting data into table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_delivery_type?: InputMaybe<Notification_Delivery_Types_Obj_Rel_Insert_Input>;
  notification_type?: InputMaybe<Notification_Types_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Notification_Types_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Notification_Disabled_Settings_Max_Fields = {
  __typename?: 'notification_disabled_settings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Disabled_Settings_Min_Fields = {
  __typename?: 'notification_disabled_settings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Mutation_Response = {
  __typename?: 'notification_disabled_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Disabled_Settings>;
};

/** on_conflict condition type for table "notification_disabled_settings" */
export type Notification_Disabled_Settings_On_Conflict = {
  constraint: Notification_Disabled_Settings_Constraint;
  update_columns?: Array<Notification_Disabled_Settings_Update_Column>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_disabled_settings". */
export type Notification_Disabled_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_delivery_type?: InputMaybe<Notification_Delivery_Types_Order_By>;
  notification_type?: InputMaybe<Notification_Types_Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_disabled_settings */
export type Notification_Disabled_Settings_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notification_disabled_settings" */
export enum Notification_Disabled_Settings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Notification_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Disabled_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Disabled_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Notification_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "notification_disabled_settings" */
export enum Notification_Disabled_Settings_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Disabled_Settings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Disabled_Settings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Disabled_Settings_Bool_Exp;
};

/** notification types enum table */
export type Notification_Types = {
  __typename?: 'notification_types';
  /** An array relationship */
  notification_disabled_settings: Array<Notification_Disabled_Settings>;
  /** An aggregate relationship */
  notification_disabled_settings_aggregate: Notification_Disabled_Settings_Aggregate;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  value: Scalars['String']['output'];
};

/** notification types enum table */
export type Notification_TypesNotification_Disabled_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** notification types enum table */
export type Notification_TypesNotification_Disabled_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** notification types enum table */
export type Notification_TypesNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** notification types enum table */
export type Notification_TypesNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** aggregated selection of "notification_types" */
export type Notification_Types_Aggregate = {
  __typename?: 'notification_types_aggregate';
  aggregate?: Maybe<Notification_Types_Aggregate_Fields>;
  nodes: Array<Notification_Types>;
};

/** aggregate fields of "notification_types" */
export type Notification_Types_Aggregate_Fields = {
  __typename?: 'notification_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Types_Max_Fields>;
  min?: Maybe<Notification_Types_Min_Fields>;
};

/** aggregate fields of "notification_types" */
export type Notification_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notification_types". All fields are combined with a logical 'AND'. */
export type Notification_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Types_Bool_Exp>>;
  _not?: InputMaybe<Notification_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Types_Bool_Exp>>;
  notification_disabled_settings?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
  notification_disabled_settings_aggregate?: InputMaybe<Notification_Disabled_Settings_Aggregate_Bool_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_types" */
export enum Notification_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  NotificationTypesPkey = 'notification_types_pkey',
}

export enum Notification_Types_Enum {
  ControlStatusChangedToNotValid = 'control_status_changed_to_not_valid',
  EntityOwnershipChanged = 'entity_ownership_changed',
  EvidenceAccessGranted = 'evidence_access_granted',
  MentionedInComment = 'mentioned_in_comment',
  OverdueTasksAssignedToYou = 'overdue_tasks_assigned_to_you',
  OverdueTasksCreatedByYou = 'overdue_tasks_created_by_you',
  PolicyAcknowledgementReminder = 'policy_acknowledgement_reminder',
  PolicyApprovalReminder = 'policy_approval_reminder',
  QuestionnaireStatusOverdue = 'questionnaire_status_overdue',
  RiskStatusChangedToExpiring = 'risk_status_changed_to_expiring',
  VendorQuestionnaireSubmission = 'vendor_questionnaire_submission',
  VendorStatusChangedToExpiring = 'vendor_status_changed_to_expiring',
}

/** Boolean expression to compare columns of type "notification_types_enum". All fields are combined with logical 'AND'. */
export type Notification_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Types_Enum>;
  _in?: InputMaybe<Array<Notification_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Notification_Types_Enum>;
  _nin?: InputMaybe<Array<Notification_Types_Enum>>;
};

/** input type for inserting data into table "notification_types" */
export type Notification_Types_Insert_Input = {
  notification_disabled_settings?: InputMaybe<Notification_Disabled_Settings_Arr_Rel_Insert_Input>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notification_Types_Max_Fields = {
  __typename?: 'notification_types_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Notification_Types_Min_Fields = {
  __typename?: 'notification_types_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "notification_types" */
export type Notification_Types_Mutation_Response = {
  __typename?: 'notification_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Types>;
};

/** input type for inserting object relation for remote table "notification_types" */
export type Notification_Types_Obj_Rel_Insert_Input = {
  data: Notification_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Types_On_Conflict>;
};

/** on_conflict condition type for table "notification_types" */
export type Notification_Types_On_Conflict = {
  constraint: Notification_Types_Constraint;
  update_columns?: Array<Notification_Types_Update_Column>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_types". */
export type Notification_Types_Order_By = {
  notification_disabled_settings_aggregate?: InputMaybe<Notification_Disabled_Settings_Aggregate_Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_types */
export type Notification_Types_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "notification_types" */
export enum Notification_Types_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "notification_types" */
export type Notification_Types_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "notification_types" */
export type Notification_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Types_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_types" */
export enum Notification_Types_Update_Column {
  /** column name */
  Value = 'value',
}

export type Notification_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Types_Bool_Exp;
};

/** this table contains a notification entries which could have many users associated to */
export type Notifications = {
  __typename?: 'notifications';
  comment_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  control?: Maybe<Controls>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notification_type: Notification_Types;
  /** An object relationship */
  notifications_comment?: Maybe<Comments>;
  /** An object relationship */
  notifications_evidence?: Maybe<Evidences>;
  /** An object relationship */
  notifications_risk?: Maybe<Risks>;
  /** An object relationship */
  notifications_vendor?: Maybe<Vendors>;
  /** An object relationship */
  notifications_vendor_questionnaire?: Maybe<Vendor_Questionnaires>;
  params: Scalars['NotificationParams']['output'];
  /** An object relationship */
  policy?: Maybe<Policies>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  policy_version?: Maybe<Policy_Versions>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  type: Notification_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** this table contains a notification entries which could have many users associated to */
export type NotificationsParamsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** this table contains a notification entries which could have many users associated to */
export type NotificationsUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** this table contains a notification entries which could have many users associated to */
export type NotificationsUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

export type Notifications_Aggregate_Bool_Exp = {
  count?: InputMaybe<Notifications_Aggregate_Bool_Exp_Count>;
};

export type Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notifications_Max_Order_By>;
  min?: InputMaybe<Notifications_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Append_Input = {
  params?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification_type?: InputMaybe<Notification_Types_Bool_Exp>;
  notifications_comment?: InputMaybe<Comments_Bool_Exp>;
  notifications_evidence?: InputMaybe<Evidences_Bool_Exp>;
  notifications_risk?: InputMaybe<Risks_Bool_Exp>;
  notifications_vendor?: InputMaybe<Vendors_Bool_Exp>;
  notifications_vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  params?: InputMaybe<Jsonb_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_version?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Notification_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_notifications?: InputMaybe<User_Notifications_Bool_Exp>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notifications_Delete_At_Path_Input = {
  params?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notifications_Delete_Elem_Input = {
  params?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notifications_Delete_Key_Input = {
  params?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_type?: InputMaybe<Notification_Types_Obj_Rel_Insert_Input>;
  notifications_comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  notifications_evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  notifications_risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  notifications_vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  notifications_vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Obj_Rel_Insert_Input>;
  params: Scalars['NotificationParams']['input'];
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_version?: InputMaybe<Policy_Versions_Obj_Rel_Insert_Input>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Notification_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_notifications?: InputMaybe<User_Notifications_Arr_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  comment_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  comment_id?: Maybe<Scalars['uuid']['output']>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  evidence_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** input type for inserting object relation for remote table "notifications" */
export type Notifications_Obj_Rel_Insert_Input = {
  data: Notifications_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_type?: InputMaybe<Notification_Types_Order_By>;
  notifications_comment?: InputMaybe<Comments_Order_By>;
  notifications_evidence?: InputMaybe<Evidences_Order_By>;
  notifications_risk?: InputMaybe<Risks_Order_By>;
  notifications_vendor?: InputMaybe<Vendors_Order_By>;
  notifications_vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  params?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_version?: InputMaybe<Policy_Versions_Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notifications_Prepend_Input = {
  params?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  Params = 'params',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  params?: InputMaybe<Scalars['jsonb']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Notification_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  params?: InputMaybe<Scalars['jsonb']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Notification_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  Params = 'params',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

export type Notifications_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Notifications_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Notifications_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Notifications_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Notifications_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Notifications_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** columns and relationships of "number_field_values" */
export type Number_Field_Values = {
  __typename?: 'number_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['numeric']['output'];
};

/** aggregated selection of "number_field_values" */
export type Number_Field_Values_Aggregate = {
  __typename?: 'number_field_values_aggregate';
  aggregate?: Maybe<Number_Field_Values_Aggregate_Fields>;
  nodes: Array<Number_Field_Values>;
};

export type Number_Field_Values_Aggregate_Bool_Exp = {
  count?: InputMaybe<Number_Field_Values_Aggregate_Bool_Exp_Count>;
};

export type Number_Field_Values_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Number_Field_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "number_field_values" */
export type Number_Field_Values_Aggregate_Fields = {
  __typename?: 'number_field_values_aggregate_fields';
  avg?: Maybe<Number_Field_Values_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Number_Field_Values_Max_Fields>;
  min?: Maybe<Number_Field_Values_Min_Fields>;
  stddev?: Maybe<Number_Field_Values_Stddev_Fields>;
  stddev_pop?: Maybe<Number_Field_Values_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Number_Field_Values_Stddev_Samp_Fields>;
  sum?: Maybe<Number_Field_Values_Sum_Fields>;
  var_pop?: Maybe<Number_Field_Values_Var_Pop_Fields>;
  var_samp?: Maybe<Number_Field_Values_Var_Samp_Fields>;
  variance?: Maybe<Number_Field_Values_Variance_Fields>;
};

/** aggregate fields of "number_field_values" */
export type Number_Field_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "number_field_values" */
export type Number_Field_Values_Aggregate_Order_By = {
  avg?: InputMaybe<Number_Field_Values_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Number_Field_Values_Max_Order_By>;
  min?: InputMaybe<Number_Field_Values_Min_Order_By>;
  stddev?: InputMaybe<Number_Field_Values_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Number_Field_Values_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Number_Field_Values_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Number_Field_Values_Sum_Order_By>;
  var_pop?: InputMaybe<Number_Field_Values_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Number_Field_Values_Var_Samp_Order_By>;
  variance?: InputMaybe<Number_Field_Values_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "number_field_values" */
export type Number_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Number_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Number_Field_Values_On_Conflict>;
};

/** aggregate avg on columns */
export type Number_Field_Values_Avg_Fields = {
  __typename?: 'number_field_values_avg_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "number_field_values" */
export type Number_Field_Values_Avg_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "number_field_values". All fields are combined with a logical 'AND'. */
export type Number_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Number_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Number_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Number_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "number_field_values" */
export enum Number_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  NumberFieldValuesPkey = 'number_field_values_pkey',
}

/** input type for incrementing numeric columns in table "number_field_values" */
export type Number_Field_Values_Inc_Input = {
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "number_field_values" */
export type Number_Field_Values_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type Number_Field_Values_Max_Fields = {
  __typename?: 'number_field_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "number_field_values" */
export type Number_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Number_Field_Values_Min_Fields = {
  __typename?: 'number_field_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "number_field_values" */
export type Number_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "number_field_values" */
export type Number_Field_Values_Mutation_Response = {
  __typename?: 'number_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Number_Field_Values>;
};

/** input type for inserting object relation for remote table "number_field_values" */
export type Number_Field_Values_Obj_Rel_Insert_Input = {
  data: Number_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Number_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "number_field_values" */
export type Number_Field_Values_On_Conflict = {
  constraint: Number_Field_Values_Constraint;
  update_columns?: Array<Number_Field_Values_Update_Column>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "number_field_values". */
export type Number_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: number_field_values */
export type Number_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "number_field_values" */
export enum Number_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "number_field_values" */
export type Number_Field_Values_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type Number_Field_Values_Stddev_Fields = {
  __typename?: 'number_field_values_stddev_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "number_field_values" */
export type Number_Field_Values_Stddev_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Number_Field_Values_Stddev_Pop_Fields = {
  __typename?: 'number_field_values_stddev_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "number_field_values" */
export type Number_Field_Values_Stddev_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Number_Field_Values_Stddev_Samp_Fields = {
  __typename?: 'number_field_values_stddev_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "number_field_values" */
export type Number_Field_Values_Stddev_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "number_field_values" */
export type Number_Field_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Number_Field_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Number_Field_Values_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type Number_Field_Values_Sum_Fields = {
  __typename?: 'number_field_values_sum_fields';
  value?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "number_field_values" */
export type Number_Field_Values_Sum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** update columns of table "number_field_values" */
export enum Number_Field_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

export type Number_Field_Values_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Number_Field_Values_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Number_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Number_Field_Values_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Number_Field_Values_Var_Pop_Fields = {
  __typename?: 'number_field_values_var_pop_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "number_field_values" */
export type Number_Field_Values_Var_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Number_Field_Values_Var_Samp_Fields = {
  __typename?: 'number_field_values_var_samp_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "number_field_values" */
export type Number_Field_Values_Var_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Number_Field_Values_Variance_Fields = {
  __typename?: 'number_field_values_variance_fields';
  value?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "number_field_values" */
export type Number_Field_Values_Variance_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** Boolean expression to compare columns of type "oid". All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['oid']['input']>;
  _gt?: InputMaybe<Scalars['oid']['input']>;
  _gte?: InputMaybe<Scalars['oid']['input']>;
  _in?: InputMaybe<Array<Scalars['oid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['oid']['input']>;
  _lte?: InputMaybe<Scalars['oid']['input']>;
  _neq?: InputMaybe<Scalars['oid']['input']>;
  _nin?: InputMaybe<Array<Scalars['oid']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "organization_evidence_tags" */
export type Organization_Evidence_Tags = {
  __typename?: 'organization_evidence_tags';
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  evidence_tags: Array<Evidence_Tags>;
  /** An aggregate relationship */
  evidence_tags_aggregate: Evidence_Tags_Aggregate;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "organization_evidence_tags" */
export type Organization_Evidence_TagsEvidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

/** columns and relationships of "organization_evidence_tags" */
export type Organization_Evidence_TagsEvidence_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

/** aggregated selection of "organization_evidence_tags" */
export type Organization_Evidence_Tags_Aggregate = {
  __typename?: 'organization_evidence_tags_aggregate';
  aggregate?: Maybe<Organization_Evidence_Tags_Aggregate_Fields>;
  nodes: Array<Organization_Evidence_Tags>;
};

export type Organization_Evidence_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Evidence_Tags_Aggregate_Bool_Exp_Count>;
};

export type Organization_Evidence_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_evidence_tags" */
export type Organization_Evidence_Tags_Aggregate_Fields = {
  __typename?: 'organization_evidence_tags_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Evidence_Tags_Max_Fields>;
  min?: Maybe<Organization_Evidence_Tags_Min_Fields>;
};

/** aggregate fields of "organization_evidence_tags" */
export type Organization_Evidence_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Evidence_Tags_Max_Order_By>;
  min?: InputMaybe<Organization_Evidence_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Arr_Rel_Insert_Input = {
  data: Array<Organization_Evidence_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Evidence_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_evidence_tags". All fields are combined with a logical 'AND'. */
export type Organization_Evidence_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Evidence_Tags_Bool_Exp>>;
  _not?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Evidence_Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence_tags?: InputMaybe<Evidence_Tags_Bool_Exp>;
  evidence_tags_aggregate?: InputMaybe<Evidence_Tags_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_evidence_tags" */
export enum Organization_Evidence_Tags_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationEvidenceTagsNameOrganizationIdKey = 'organization_evidence_tags_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationEvidenceTagsPkey = 'organization_evidence_tags_pkey',
}

/** input type for inserting data into table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  evidence_tags?: InputMaybe<Evidence_Tags_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Organization_Evidence_Tags_Max_Fields = {
  __typename?: 'organization_evidence_tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Evidence_Tags_Min_Fields = {
  __typename?: 'organization_evidence_tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Mutation_Response = {
  __typename?: 'organization_evidence_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Evidence_Tags>;
};

/** input type for inserting object relation for remote table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Obj_Rel_Insert_Input = {
  data: Organization_Evidence_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Evidence_Tags_On_Conflict>;
};

/** on_conflict condition type for table "organization_evidence_tags" */
export type Organization_Evidence_Tags_On_Conflict = {
  constraint: Organization_Evidence_Tags_Constraint;
  update_columns?: Array<Organization_Evidence_Tags_Update_Column>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_evidence_tags". */
export type Organization_Evidence_Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_tags_aggregate?: InputMaybe<Evidence_Tags_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_evidence_tags */
export type Organization_Evidence_Tags_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organization_evidence_tags" */
export enum Organization_Evidence_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Evidence_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Evidence_Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organization_evidence_tags" */
export enum Organization_Evidence_Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Evidence_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Evidence_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Evidence_Tags_Bool_Exp;
};

/** columns and relationships of "organization_integrations" */
export type Organization_Integrations = {
  __typename?: 'organization_integrations';
  created_at: Scalars['timestamptz']['output'];
  disabled: Scalars['Boolean']['output'];
  encrypted_config: Scalars['String']['output'];
  /** An array relationship */
  evidence_integrations: Array<Evidence_Integrations>;
  /** An aggregate relationship */
  evidence_integrations_aggregate: Evidence_Integrations_Aggregate;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  integration: Integrations;
  integration_id: Scalars['uuid']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  usage_stats?: Maybe<Integration_Usage_Stats_View>;
};

/** columns and relationships of "organization_integrations" */
export type Organization_IntegrationsEvidence_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

/** columns and relationships of "organization_integrations" */
export type Organization_IntegrationsEvidence_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

/** aggregated selection of "organization_integrations" */
export type Organization_Integrations_Aggregate = {
  __typename?: 'organization_integrations_aggregate';
  aggregate?: Maybe<Organization_Integrations_Aggregate_Fields>;
  nodes: Array<Organization_Integrations>;
};

export type Organization_Integrations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organization_Integrations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organization_Integrations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organization_Integrations_Aggregate_Bool_Exp_Count>;
};

export type Organization_Integrations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organization_Integrations_Select_Column_Organization_Integrations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Integrations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Integrations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organization_Integrations_Select_Column_Organization_Integrations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Integrations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Integrations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Integrations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_integrations" */
export type Organization_Integrations_Aggregate_Fields = {
  __typename?: 'organization_integrations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Integrations_Max_Fields>;
  min?: Maybe<Organization_Integrations_Min_Fields>;
};

/** aggregate fields of "organization_integrations" */
export type Organization_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_integrations" */
export type Organization_Integrations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Integrations_Max_Order_By>;
  min?: InputMaybe<Organization_Integrations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_integrations" */
export type Organization_Integrations_Arr_Rel_Insert_Input = {
  data: Array<Organization_Integrations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Integrations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_integrations". All fields are combined with a logical 'AND'. */
export type Organization_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Organization_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Integrations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  encrypted_config?: InputMaybe<String_Comparison_Exp>;
  evidence_integrations?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  evidence_integrations_aggregate?: InputMaybe<Evidence_Integrations_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Integrations_Bool_Exp>;
  integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  usage_stats?: InputMaybe<Integration_Usage_Stats_View_Bool_Exp>;
};

/** unique or primary key constraints on table "organization_integrations" */
export enum Organization_Integrations_Constraint {
  /** unique or primary key constraint on columns "integration_id", "organization_id" */
  OrganizationIntegrationsOrganizationIdIntegrationIdKey = 'organization_integrations_organization_id_integration_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationIntegrationsPkey = 'organization_integrations_pkey',
}

/** input type for inserting data into table "organization_integrations" */
export type Organization_Integrations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  encrypted_config?: InputMaybe<Scalars['String']['input']>;
  evidence_integrations?: InputMaybe<Evidence_Integrations_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration?: InputMaybe<Integrations_Obj_Rel_Insert_Input>;
  integration_id?: InputMaybe<Scalars['uuid']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  usage_stats?: InputMaybe<Integration_Usage_Stats_View_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organization_Integrations_Max_Fields = {
  __typename?: 'organization_integrations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  encrypted_config?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_id?: Maybe<Scalars['uuid']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "organization_integrations" */
export type Organization_Integrations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  encrypted_config?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Integrations_Min_Fields = {
  __typename?: 'organization_integrations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  encrypted_config?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  integration_id?: Maybe<Scalars['uuid']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "organization_integrations" */
export type Organization_Integrations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  encrypted_config?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  integration_id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_integrations" */
export type Organization_Integrations_Mutation_Response = {
  __typename?: 'organization_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Integrations>;
};

/** input type for inserting object relation for remote table "organization_integrations" */
export type Organization_Integrations_Obj_Rel_Insert_Input = {
  data: Organization_Integrations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Integrations_On_Conflict>;
};

/** on_conflict condition type for table "organization_integrations" */
export type Organization_Integrations_On_Conflict = {
  constraint: Organization_Integrations_Constraint;
  update_columns?: Array<Organization_Integrations_Update_Column>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_integrations". */
export type Organization_Integrations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  disabled?: InputMaybe<Order_By>;
  encrypted_config?: InputMaybe<Order_By>;
  evidence_integrations_aggregate?: InputMaybe<Evidence_Integrations_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Integrations_Order_By>;
  integration_id?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  usage_stats?: InputMaybe<Integration_Usage_Stats_View_Order_By>;
};

/** primary key columns input for table: organization_integrations */
export type Organization_Integrations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organization_integrations" */
export enum Organization_Integrations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EncryptedConfig = 'encrypted_config',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "organization_integrations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_integrations" */
export enum Organization_Integrations_Select_Column_Organization_Integrations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
}

/** select "organization_integrations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_integrations" */
export enum Organization_Integrations_Select_Column_Organization_Integrations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
}

/** input type for updating data in table "organization_integrations" */
export type Organization_Integrations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  encrypted_config?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_id?: InputMaybe<Scalars['uuid']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organization_integrations" */
export type Organization_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Integrations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  encrypted_config?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  integration_id?: InputMaybe<Scalars['uuid']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organization_integrations" */
export enum Organization_Integrations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EncryptedConfig = 'encrypted_config',
  /** column name */
  Id = 'id',
  /** column name */
  IntegrationId = 'integration_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Integrations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Integrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Integrations_Bool_Exp;
};

/** columns and relationships of "organization_risk_categories" */
export type Organization_Risk_Categories = {
  __typename?: 'organization_risk_categories';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  risk_categories: Array<Risk_Categories>;
  /** An aggregate relationship */
  risk_categories_aggregate: Risk_Categories_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "organization_risk_categories" */
export type Organization_Risk_CategoriesRisk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

/** columns and relationships of "organization_risk_categories" */
export type Organization_Risk_CategoriesRisk_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

/** aggregated selection of "organization_risk_categories" */
export type Organization_Risk_Categories_Aggregate = {
  __typename?: 'organization_risk_categories_aggregate';
  aggregate?: Maybe<Organization_Risk_Categories_Aggregate_Fields>;
  nodes: Array<Organization_Risk_Categories>;
};

export type Organization_Risk_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Risk_Categories_Aggregate_Bool_Exp_Count>;
};

export type Organization_Risk_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_risk_categories" */
export type Organization_Risk_Categories_Aggregate_Fields = {
  __typename?: 'organization_risk_categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Risk_Categories_Max_Fields>;
  min?: Maybe<Organization_Risk_Categories_Min_Fields>;
};

/** aggregate fields of "organization_risk_categories" */
export type Organization_Risk_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_risk_categories" */
export type Organization_Risk_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Risk_Categories_Max_Order_By>;
  min?: InputMaybe<Organization_Risk_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_risk_categories" */
export type Organization_Risk_Categories_Arr_Rel_Insert_Input = {
  data: Array<Organization_Risk_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Risk_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_risk_categories". All fields are combined with a logical 'AND'. */
export type Organization_Risk_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Risk_Categories_Bool_Exp>>;
  _not?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Risk_Categories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_categories?: InputMaybe<Risk_Categories_Bool_Exp>;
  risk_categories_aggregate?: InputMaybe<Risk_Categories_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_risk_categories" */
export enum Organization_Risk_Categories_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationRiskCategoriesNameOrganizationIdKey = 'organization_risk_categories_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationRiskCategoriesPkey = 'organization_risk_categories_pkey',
}

/** input type for inserting data into table "organization_risk_categories" */
export type Organization_Risk_Categories_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_categories?: InputMaybe<Risk_Categories_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Organization_Risk_Categories_Max_Fields = {
  __typename?: 'organization_risk_categories_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "organization_risk_categories" */
export type Organization_Risk_Categories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Risk_Categories_Min_Fields = {
  __typename?: 'organization_risk_categories_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "organization_risk_categories" */
export type Organization_Risk_Categories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_risk_categories" */
export type Organization_Risk_Categories_Mutation_Response = {
  __typename?: 'organization_risk_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Risk_Categories>;
};

/** input type for inserting object relation for remote table "organization_risk_categories" */
export type Organization_Risk_Categories_Obj_Rel_Insert_Input = {
  data: Organization_Risk_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Risk_Categories_On_Conflict>;
};

/** on_conflict condition type for table "organization_risk_categories" */
export type Organization_Risk_Categories_On_Conflict = {
  constraint: Organization_Risk_Categories_Constraint;
  update_columns?: Array<Organization_Risk_Categories_Update_Column>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_risk_categories". */
export type Organization_Risk_Categories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  risk_categories_aggregate?: InputMaybe<Risk_Categories_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_risk_categories */
export type Organization_Risk_Categories_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organization_risk_categories" */
export enum Organization_Risk_Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_risk_categories" */
export type Organization_Risk_Categories_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organization_risk_categories" */
export type Organization_Risk_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Risk_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Risk_Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organization_risk_categories" */
export enum Organization_Risk_Categories_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Risk_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Risk_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Risk_Categories_Bool_Exp;
};

/** columns and relationships of "organization_risk_tags" */
export type Organization_Risk_Tags = {
  __typename?: 'organization_risk_tags';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  risk_tags: Array<Risk_Tags>;
  /** An aggregate relationship */
  risk_tags_aggregate: Risk_Tags_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "organization_risk_tags" */
export type Organization_Risk_TagsRisk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

/** columns and relationships of "organization_risk_tags" */
export type Organization_Risk_TagsRisk_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

/** aggregated selection of "organization_risk_tags" */
export type Organization_Risk_Tags_Aggregate = {
  __typename?: 'organization_risk_tags_aggregate';
  aggregate?: Maybe<Organization_Risk_Tags_Aggregate_Fields>;
  nodes: Array<Organization_Risk_Tags>;
};

export type Organization_Risk_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Risk_Tags_Aggregate_Bool_Exp_Count>;
};

export type Organization_Risk_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_risk_tags" */
export type Organization_Risk_Tags_Aggregate_Fields = {
  __typename?: 'organization_risk_tags_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Risk_Tags_Max_Fields>;
  min?: Maybe<Organization_Risk_Tags_Min_Fields>;
};

/** aggregate fields of "organization_risk_tags" */
export type Organization_Risk_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_risk_tags" */
export type Organization_Risk_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Risk_Tags_Max_Order_By>;
  min?: InputMaybe<Organization_Risk_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_risk_tags" */
export type Organization_Risk_Tags_Arr_Rel_Insert_Input = {
  data: Array<Organization_Risk_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Risk_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_risk_tags". All fields are combined with a logical 'AND'. */
export type Organization_Risk_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Risk_Tags_Bool_Exp>>;
  _not?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Risk_Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_tags?: InputMaybe<Risk_Tags_Bool_Exp>;
  risk_tags_aggregate?: InputMaybe<Risk_Tags_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_risk_tags" */
export enum Organization_Risk_Tags_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationRiskTagsNameOrganizationIdKey = 'organization_risk_tags_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationRiskTagsPkey = 'organization_risk_tags_pkey',
}

/** input type for inserting data into table "organization_risk_tags" */
export type Organization_Risk_Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_tags?: InputMaybe<Risk_Tags_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Organization_Risk_Tags_Max_Fields = {
  __typename?: 'organization_risk_tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "organization_risk_tags" */
export type Organization_Risk_Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Risk_Tags_Min_Fields = {
  __typename?: 'organization_risk_tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "organization_risk_tags" */
export type Organization_Risk_Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_risk_tags" */
export type Organization_Risk_Tags_Mutation_Response = {
  __typename?: 'organization_risk_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Risk_Tags>;
};

/** input type for inserting object relation for remote table "organization_risk_tags" */
export type Organization_Risk_Tags_Obj_Rel_Insert_Input = {
  data: Organization_Risk_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Risk_Tags_On_Conflict>;
};

/** on_conflict condition type for table "organization_risk_tags" */
export type Organization_Risk_Tags_On_Conflict = {
  constraint: Organization_Risk_Tags_Constraint;
  update_columns?: Array<Organization_Risk_Tags_Update_Column>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_risk_tags". */
export type Organization_Risk_Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  risk_tags_aggregate?: InputMaybe<Risk_Tags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_risk_tags */
export type Organization_Risk_Tags_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organization_risk_tags" */
export enum Organization_Risk_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_risk_tags" */
export type Organization_Risk_Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organization_risk_tags" */
export type Organization_Risk_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Risk_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Risk_Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organization_risk_tags" */
export enum Organization_Risk_Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Risk_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Risk_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Risk_Tags_Bool_Exp;
};

/** columns and relationships of "organization_template_frameworks" */
export type Organization_Template_Frameworks = {
  __typename?: 'organization_template_frameworks';
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  template_framework: Template_Frameworks;
  template_framework_id: Scalars['uuid']['output'];
};

/** aggregated selection of "organization_template_frameworks" */
export type Organization_Template_Frameworks_Aggregate = {
  __typename?: 'organization_template_frameworks_aggregate';
  aggregate?: Maybe<Organization_Template_Frameworks_Aggregate_Fields>;
  nodes: Array<Organization_Template_Frameworks>;
};

export type Organization_Template_Frameworks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Template_Frameworks_Aggregate_Bool_Exp_Count>;
};

export type Organization_Template_Frameworks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_template_frameworks" */
export type Organization_Template_Frameworks_Aggregate_Fields = {
  __typename?: 'organization_template_frameworks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Template_Frameworks_Max_Fields>;
  min?: Maybe<Organization_Template_Frameworks_Min_Fields>;
};

/** aggregate fields of "organization_template_frameworks" */
export type Organization_Template_Frameworks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Template_Frameworks_Max_Order_By>;
  min?: InputMaybe<Organization_Template_Frameworks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Arr_Rel_Insert_Input = {
  data: Array<Organization_Template_Frameworks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Template_Frameworks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_template_frameworks". All fields are combined with a logical 'AND'. */
export type Organization_Template_Frameworks_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Template_Frameworks_Bool_Exp>>;
  _not?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Template_Frameworks_Bool_Exp>>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_framework?: InputMaybe<Template_Frameworks_Bool_Exp>;
  template_framework_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_template_frameworks" */
export enum Organization_Template_Frameworks_Constraint {
  /** unique or primary key constraint on columns "template_framework_id", "organization_id" */
  OrganizationTemplateFrameworksPkey = 'organization_template_frameworks_pkey',
}

/** input type for inserting data into table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Insert_Input = {
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  template_framework?: InputMaybe<Template_Frameworks_Obj_Rel_Insert_Input>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Organization_Template_Frameworks_Max_Fields = {
  __typename?: 'organization_template_frameworks_max_fields';
  organization_id?: Maybe<Scalars['uuid']['output']>;
  template_framework_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Max_Order_By = {
  organization_id?: InputMaybe<Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Template_Frameworks_Min_Fields = {
  __typename?: 'organization_template_frameworks_min_fields';
  organization_id?: Maybe<Scalars['uuid']['output']>;
  template_framework_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Min_Order_By = {
  organization_id?: InputMaybe<Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Mutation_Response = {
  __typename?: 'organization_template_frameworks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Template_Frameworks>;
};

/** on_conflict condition type for table "organization_template_frameworks" */
export type Organization_Template_Frameworks_On_Conflict = {
  constraint: Organization_Template_Frameworks_Constraint;
  update_columns?: Array<Organization_Template_Frameworks_Update_Column>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_template_frameworks". */
export type Organization_Template_Frameworks_Order_By = {
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  template_framework?: InputMaybe<Template_Frameworks_Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_template_frameworks */
export type Organization_Template_Frameworks_Pk_Columns_Input = {
  organization_id: Scalars['uuid']['input'];
  template_framework_id: Scalars['uuid']['input'];
};

/** select columns of table "organization_template_frameworks" */
export enum Organization_Template_Frameworks_Select_Column {
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TemplateFrameworkId = 'template_framework_id',
}

/** input type for updating data in table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Set_Input = {
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Template_Frameworks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Template_Frameworks_Stream_Cursor_Value_Input = {
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "organization_template_frameworks" */
export enum Organization_Template_Frameworks_Update_Column {
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TemplateFrameworkId = 'template_framework_id',
}

export type Organization_Template_Frameworks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Template_Frameworks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Template_Frameworks_Bool_Exp;
};

/** table consisting of tenants for an organization used for SSO */
export type Organization_Tenants = {
  __typename?: 'organization_tenants';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "organization_tenants" */
export type Organization_Tenants_Aggregate = {
  __typename?: 'organization_tenants_aggregate';
  aggregate?: Maybe<Organization_Tenants_Aggregate_Fields>;
  nodes: Array<Organization_Tenants>;
};

export type Organization_Tenants_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Tenants_Aggregate_Bool_Exp_Count>;
};

export type Organization_Tenants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Tenants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_tenants" */
export type Organization_Tenants_Aggregate_Fields = {
  __typename?: 'organization_tenants_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Tenants_Max_Fields>;
  min?: Maybe<Organization_Tenants_Min_Fields>;
};

/** aggregate fields of "organization_tenants" */
export type Organization_Tenants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_tenants" */
export type Organization_Tenants_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Tenants_Max_Order_By>;
  min?: InputMaybe<Organization_Tenants_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_tenants" */
export type Organization_Tenants_Arr_Rel_Insert_Input = {
  data: Array<Organization_Tenants_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Tenants_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_tenants". All fields are combined with a logical 'AND'. */
export type Organization_Tenants_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Tenants_Bool_Exp>>;
  _not?: InputMaybe<Organization_Tenants_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Tenants_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_tenants" */
export enum Organization_Tenants_Constraint {
  /** unique or primary key constraint on columns "name" */
  OrganizationTenantsNameKey = 'organization_tenants_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationTenantsPkey = 'organization_tenants_pkey',
}

/** input type for inserting data into table "organization_tenants" */
export type Organization_Tenants_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Organization_Tenants_Max_Fields = {
  __typename?: 'organization_tenants_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "organization_tenants" */
export type Organization_Tenants_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Tenants_Min_Fields = {
  __typename?: 'organization_tenants_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "organization_tenants" */
export type Organization_Tenants_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_tenants" */
export type Organization_Tenants_Mutation_Response = {
  __typename?: 'organization_tenants_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Tenants>;
};

/** on_conflict condition type for table "organization_tenants" */
export type Organization_Tenants_On_Conflict = {
  constraint: Organization_Tenants_Constraint;
  update_columns?: Array<Organization_Tenants_Update_Column>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_tenants". */
export type Organization_Tenants_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_tenants */
export type Organization_Tenants_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organization_tenants" */
export enum Organization_Tenants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_tenants" */
export type Organization_Tenants_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organization_tenants" */
export type Organization_Tenants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Tenants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Tenants_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organization_tenants" */
export enum Organization_Tenants_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Tenants_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Tenants_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Tenants_Bool_Exp;
};

/** An organization can have multiple users. One user can belong to only one organization */
export type Organization_Users = {
  __typename?: 'organization_users';
  disabled: Scalars['Boolean']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  role: Roles;
  role_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "organization_users" */
export type Organization_Users_Aggregate = {
  __typename?: 'organization_users_aggregate';
  aggregate?: Maybe<Organization_Users_Aggregate_Fields>;
  nodes: Array<Organization_Users>;
};

export type Organization_Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organization_Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organization_Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organization_Users_Aggregate_Bool_Exp_Count>;
};

export type Organization_Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organization_Users_Select_Column_Organization_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organization_Users_Select_Column_Organization_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organization_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_users" */
export type Organization_Users_Aggregate_Fields = {
  __typename?: 'organization_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Users_Max_Fields>;
  min?: Maybe<Organization_Users_Min_Fields>;
};

/** aggregate fields of "organization_users" */
export type Organization_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_users" */
export type Organization_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Users_Max_Order_By>;
  min?: InputMaybe<Organization_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_users" */
export type Organization_Users_Arr_Rel_Insert_Input = {
  data: Array<Organization_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_users". All fields are combined with a logical 'AND'. */
export type Organization_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Users_Bool_Exp>>;
  _not?: InputMaybe<Organization_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Users_Bool_Exp>>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_users" */
export enum Organization_Users_Constraint {
  /** unique or primary key constraint on columns "user_id", "organization_id" */
  OrganizationUsersPkey = 'organization_users_pkey',
  /** unique or primary key constraint on columns "user_id", "organization_id" */
  OrganizationUsersUserIdOrganizationIdKey = 'organization_users_user_id_organization_id_key',
}

/** input type for inserting data into table "organization_users" */
export type Organization_Users_Insert_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Organization_Users_Max_Fields = {
  __typename?: 'organization_users_max_fields';
  organization_id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "organization_users" */
export type Organization_Users_Max_Order_By = {
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Users_Min_Fields = {
  __typename?: 'organization_users_min_fields';
  organization_id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "organization_users" */
export type Organization_Users_Min_Order_By = {
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_users" */
export type Organization_Users_Mutation_Response = {
  __typename?: 'organization_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Users>;
};

/** on_conflict condition type for table "organization_users" */
export type Organization_Users_On_Conflict = {
  constraint: Organization_Users_Constraint;
  update_columns?: Array<Organization_Users_Update_Column>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_users". */
export type Organization_Users_Order_By = {
  disabled?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_users */
export type Organization_Users_Pk_Columns_Input = {
  organization_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

/** select columns of table "organization_users" */
export enum Organization_Users_Select_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id',
}

/** select "organization_users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organization_users" */
export enum Organization_Users_Select_Column_Organization_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
}

/** select "organization_users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organization_users" */
export enum Organization_Users_Select_Column_Organization_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
}

/** input type for updating data in table "organization_users" */
export type Organization_Users_Set_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "organization_users" */
export type Organization_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Users_Stream_Cursor_Value_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "organization_users" */
export enum Organization_Users_Update_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id',
}

export type Organization_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Users_Bool_Exp;
};

/** columns and relationships of "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels = {
  __typename?: 'organization_vendor_risk_levels';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  vendors: Array<Vendors>;
  /** An aggregate relationship */
  vendors_aggregate: Vendors_Aggregate;
};

/** columns and relationships of "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_LevelsVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** columns and relationships of "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_LevelsVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** aggregated selection of "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Aggregate = {
  __typename?: 'organization_vendor_risk_levels_aggregate';
  aggregate?: Maybe<Organization_Vendor_Risk_Levels_Aggregate_Fields>;
  nodes: Array<Organization_Vendor_Risk_Levels>;
};

export type Organization_Vendor_Risk_Levels_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Vendor_Risk_Levels_Aggregate_Bool_Exp_Count>;
};

export type Organization_Vendor_Risk_Levels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Aggregate_Fields = {
  __typename?: 'organization_vendor_risk_levels_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Vendor_Risk_Levels_Max_Fields>;
  min?: Maybe<Organization_Vendor_Risk_Levels_Min_Fields>;
};

/** aggregate fields of "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Vendor_Risk_Levels_Max_Order_By>;
  min?: InputMaybe<Organization_Vendor_Risk_Levels_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Arr_Rel_Insert_Input = {
  data: Array<Organization_Vendor_Risk_Levels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Vendor_Risk_Levels_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_vendor_risk_levels". All fields are combined with a logical 'AND'. */
export type Organization_Vendor_Risk_Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Bool_Exp>>;
  _not?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organization_vendor_risk_levels" */
export enum Organization_Vendor_Risk_Levels_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationVendorRiskLevelsOrganizationIdNameKey = 'organization_vendor_risk_levels_organization_id_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationVendorRiskLevelsPkey = 'organization_vendor_risk_levels_pkey',
}

/** input type for inserting data into table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendors?: InputMaybe<Vendors_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organization_Vendor_Risk_Levels_Max_Fields = {
  __typename?: 'organization_vendor_risk_levels_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Vendor_Risk_Levels_Min_Fields = {
  __typename?: 'organization_vendor_risk_levels_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Mutation_Response = {
  __typename?: 'organization_vendor_risk_levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Vendor_Risk_Levels>;
};

/** input type for inserting object relation for remote table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Obj_Rel_Insert_Input = {
  data: Organization_Vendor_Risk_Levels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Vendor_Risk_Levels_On_Conflict>;
};

/** on_conflict condition type for table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_On_Conflict = {
  constraint: Organization_Vendor_Risk_Levels_Constraint;
  update_columns?: Array<Organization_Vendor_Risk_Levels_Update_Column>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_vendor_risk_levels". */
export type Organization_Vendor_Risk_Levels_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
};

/** primary key columns input for table: organization_vendor_risk_levels */
export type Organization_Vendor_Risk_Levels_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organization_vendor_risk_levels" */
export enum Organization_Vendor_Risk_Levels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Vendor_Risk_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Vendor_Risk_Levels_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organization_vendor_risk_levels" */
export enum Organization_Vendor_Risk_Levels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Vendor_Risk_Levels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Vendor_Risk_Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Vendor_Risk_Levels_Bool_Exp;
};

/** columns and relationships of "organization_vendor_tiers" */
export type Organization_Vendor_Tiers = {
  __typename?: 'organization_vendor_tiers';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  vendors: Array<Vendors>;
  /** An aggregate relationship */
  vendors_aggregate: Vendors_Aggregate;
};

/** columns and relationships of "organization_vendor_tiers" */
export type Organization_Vendor_TiersVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** columns and relationships of "organization_vendor_tiers" */
export type Organization_Vendor_TiersVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** aggregated selection of "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Aggregate = {
  __typename?: 'organization_vendor_tiers_aggregate';
  aggregate?: Maybe<Organization_Vendor_Tiers_Aggregate_Fields>;
  nodes: Array<Organization_Vendor_Tiers>;
};

export type Organization_Vendor_Tiers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Organization_Vendor_Tiers_Aggregate_Bool_Exp_Count>;
};

export type Organization_Vendor_Tiers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Aggregate_Fields = {
  __typename?: 'organization_vendor_tiers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organization_Vendor_Tiers_Max_Fields>;
  min?: Maybe<Organization_Vendor_Tiers_Min_Fields>;
};

/** aggregate fields of "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Vendor_Tiers_Max_Order_By>;
  min?: InputMaybe<Organization_Vendor_Tiers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Arr_Rel_Insert_Input = {
  data: Array<Organization_Vendor_Tiers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Vendor_Tiers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_vendor_tiers". All fields are combined with a logical 'AND'. */
export type Organization_Vendor_Tiers_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Vendor_Tiers_Bool_Exp>>;
  _not?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Vendor_Tiers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organization_vendor_tiers" */
export enum Organization_Vendor_Tiers_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationVendorTiersOrganizationIdNameKey = 'organization_vendor_tiers_organization_id_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationVendorTiersPkey = 'organization_vendor_tiers_pkey',
}

/** input type for inserting data into table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendors?: InputMaybe<Vendors_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organization_Vendor_Tiers_Max_Fields = {
  __typename?: 'organization_vendor_tiers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Vendor_Tiers_Min_Fields = {
  __typename?: 'organization_vendor_tiers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Mutation_Response = {
  __typename?: 'organization_vendor_tiers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Vendor_Tiers>;
};

/** input type for inserting object relation for remote table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Obj_Rel_Insert_Input = {
  data: Organization_Vendor_Tiers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Vendor_Tiers_On_Conflict>;
};

/** on_conflict condition type for table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_On_Conflict = {
  constraint: Organization_Vendor_Tiers_Constraint;
  update_columns?: Array<Organization_Vendor_Tiers_Update_Column>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_vendor_tiers". */
export type Organization_Vendor_Tiers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
};

/** primary key columns input for table: organization_vendor_tiers */
export type Organization_Vendor_Tiers_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organization_vendor_tiers" */
export enum Organization_Vendor_Tiers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Vendor_Tiers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Vendor_Tiers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organization_vendor_tiers" */
export enum Organization_Vendor_Tiers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Organization_Vendor_Tiers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Vendor_Tiers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Vendor_Tiers_Bool_Exp;
};

/** Table to manage organizations */
export type Organizations = {
  __typename?: 'organizations';
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** An aggregate relationship */
  client_questionnaires_aggregate: Client_Questionnaires_Aggregate;
  /** An array relationship */
  controls_histories: Array<Controls_History_Stats>;
  /** An aggregate relationship */
  controls_histories_aggregate: Controls_History_Stats_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  criteria: Array<Criteria>;
  /** An aggregate relationship */
  criteria_aggregate: Criteria_Aggregate;
  description?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  /** An array relationship */
  evidence_tags: Array<Organization_Evidence_Tags>;
  /** An aggregate relationship */
  evidence_tags_aggregate: Organization_Evidence_Tags_Aggregate;
  /** An array relationship */
  evidences: Array<Evidences>;
  /** An aggregate relationship */
  evidences_aggregate: Evidences_Aggregate;
  /** An array relationship */
  field_configs: Array<Field_Configs>;
  /** An aggregate relationship */
  field_configs_aggregate: Field_Configs_Aggregate;
  /** An array relationship */
  frameworks: Array<Frameworks>;
  /** An aggregate relationship */
  frameworks_aggregate: Frameworks_Aggregate;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  invitations: Array<Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Invitations_Aggregate;
  is_audit_trail_enabled: Scalars['Boolean']['output'];
  is_control_ai_review_enabled: Scalars['Boolean']['output'];
  is_controls_module_enabled: Scalars['Boolean']['output'];
  is_day_zero_ai_features_enabled: Scalars['Boolean']['output'];
  is_policies_module_enabled: Scalars['Boolean']['output'];
  is_risks_module_enabled: Scalars['Boolean']['output'];
  is_vendor_questionnaire_ai_review_enabled: Scalars['Boolean']['output'];
  is_vendors_module_enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  /** An array relationship */
  organization_controls: Array<Controls>;
  /** An aggregate relationship */
  organization_controls_aggregate: Controls_Aggregate;
  /** An array relationship */
  organization_integrations: Array<Organization_Integrations>;
  /** An aggregate relationship */
  organization_integrations_aggregate: Organization_Integrations_Aggregate;
  /** An array relationship */
  organization_template_frameworks: Array<Organization_Template_Frameworks>;
  /** An aggregate relationship */
  organization_template_frameworks_aggregate: Organization_Template_Frameworks_Aggregate;
  /** An array relationship */
  organization_tenants: Array<Organization_Tenants>;
  /** An aggregate relationship */
  organization_tenants_aggregate: Organization_Tenants_Aggregate;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  parent_organization_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
  /** An array relationship */
  programs: Array<Programs>;
  /** An aggregate relationship */
  programs_aggregate: Programs_Aggregate;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** An array relationship */
  risk_categories: Array<Organization_Risk_Categories>;
  /** An aggregate relationship */
  risk_categories_aggregate: Organization_Risk_Categories_Aggregate;
  /** An array relationship */
  risk_impacts: Array<Risk_Impacts>;
  /** An aggregate relationship */
  risk_impacts_aggregate: Risk_Impacts_Aggregate;
  /** An array relationship */
  risk_likelihoods: Array<Risk_Likelihoods>;
  /** An aggregate relationship */
  risk_likelihoods_aggregate: Risk_Likelihoods_Aggregate;
  /** An array relationship */
  risk_tags: Array<Organization_Risk_Tags>;
  /** An aggregate relationship */
  risk_tags_aggregate: Organization_Risk_Tags_Aggregate;
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** An array relationship */
  roles: Array<Roles>;
  /** An aggregate relationship */
  roles_aggregate: Roles_Aggregate;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  vendor_risk_levels: Array<Organization_Vendor_Risk_Levels>;
  /** An aggregate relationship */
  vendor_risk_levels_aggregate: Organization_Vendor_Risk_Levels_Aggregate;
  /** An array relationship */
  vendor_tiers: Array<Organization_Vendor_Tiers>;
  /** An aggregate relationship */
  vendor_tiers_aggregate: Organization_Vendor_Tiers_Aggregate;
  /** An array relationship */
  vendors: Array<Vendors>;
  /** An aggregate relationship */
  vendors_aggregate: Vendors_Aggregate;
  workos_organization_id?: Maybe<Scalars['String']['output']>;
};

/** Table to manage organizations */
export type OrganizationsCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsClient_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsControls_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsControls_Histories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsCriteriaArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsEvidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsEvidence_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsEvidencesArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsEvidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsField_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsField_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsFrameworksArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsFrameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsInvitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_Template_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_Template_Frameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsPrograms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_Impacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_Likelihoods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendor_Risk_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendor_Risk_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendor_TiersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendor_Tiers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

export type Organizations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organizations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organizations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organizations_Aggregate_Bool_Exp_Count>;
};

export type Organizations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: 'organizations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organizations" */
export type Organizations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Max_Order_By>;
  min?: InputMaybe<Organizations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organizations" */
export type Organizations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Bool_Exp>>;
  categories?: InputMaybe<Categories_Bool_Exp>;
  categories_aggregate?: InputMaybe<Categories_Aggregate_Bool_Exp>;
  client_questionnaires?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Bool_Exp>;
  controls_histories?: InputMaybe<Controls_History_Stats_Bool_Exp>;
  controls_histories_aggregate?: InputMaybe<Controls_History_Stats_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  criteria?: InputMaybe<Criteria_Bool_Exp>;
  criteria_aggregate?: InputMaybe<Criteria_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  evidence_tags?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
  evidence_tags_aggregate?: InputMaybe<Organization_Evidence_Tags_Aggregate_Bool_Exp>;
  evidences?: InputMaybe<Evidences_Bool_Exp>;
  evidences_aggregate?: InputMaybe<Evidences_Aggregate_Bool_Exp>;
  field_configs?: InputMaybe<Field_Configs_Bool_Exp>;
  field_configs_aggregate?: InputMaybe<Field_Configs_Aggregate_Bool_Exp>;
  frameworks?: InputMaybe<Frameworks_Bool_Exp>;
  frameworks_aggregate?: InputMaybe<Frameworks_Aggregate_Bool_Exp>;
  groups?: InputMaybe<Groups_Bool_Exp>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitations?: InputMaybe<Invitations_Bool_Exp>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Bool_Exp>;
  is_audit_trail_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_control_ai_review_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_controls_module_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_day_zero_ai_features_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_policies_module_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_risks_module_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_vendor_questionnaire_ai_review_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_vendors_module_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_controls?: InputMaybe<Controls_Bool_Exp>;
  organization_controls_aggregate?: InputMaybe<Controls_Aggregate_Bool_Exp>;
  organization_integrations?: InputMaybe<Organization_Integrations_Bool_Exp>;
  organization_integrations_aggregate?: InputMaybe<Organization_Integrations_Aggregate_Bool_Exp>;
  organization_template_frameworks?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
  organization_template_frameworks_aggregate?: InputMaybe<Organization_Template_Frameworks_Aggregate_Bool_Exp>;
  organization_tenants?: InputMaybe<Organization_Tenants_Bool_Exp>;
  organization_tenants_aggregate?: InputMaybe<Organization_Tenants_Aggregate_Bool_Exp>;
  organization_users?: InputMaybe<Organization_Users_Bool_Exp>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Bool_Exp>;
  organizations?: InputMaybe<Organizations_Bool_Exp>;
  organizations_aggregate?: InputMaybe<Organizations_Aggregate_Bool_Exp>;
  parent_organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  policies?: InputMaybe<Policies_Bool_Exp>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Bool_Exp>;
  programs?: InputMaybe<Programs_Bool_Exp>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Bool_Exp>;
  questionnaires?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Bool_Exp>;
  reports?: InputMaybe<Reports_Bool_Exp>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Bool_Exp>;
  risk_categories?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
  risk_categories_aggregate?: InputMaybe<Organization_Risk_Categories_Aggregate_Bool_Exp>;
  risk_impacts?: InputMaybe<Risk_Impacts_Bool_Exp>;
  risk_impacts_aggregate?: InputMaybe<Risk_Impacts_Aggregate_Bool_Exp>;
  risk_likelihoods?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  risk_likelihoods_aggregate?: InputMaybe<Risk_Likelihoods_Aggregate_Bool_Exp>;
  risk_tags?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
  risk_tags_aggregate?: InputMaybe<Organization_Risk_Tags_Aggregate_Bool_Exp>;
  risks?: InputMaybe<Risks_Bool_Exp>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  roles?: InputMaybe<Roles_Bool_Exp>;
  roles_aggregate?: InputMaybe<Roles_Aggregate_Bool_Exp>;
  tags?: InputMaybe<Tags_Bool_Exp>;
  tags_aggregate?: InputMaybe<Tags_Aggregate_Bool_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_risk_levels?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
  vendor_risk_levels_aggregate?: InputMaybe<Organization_Vendor_Risk_Levels_Aggregate_Bool_Exp>;
  vendor_tiers?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
  vendor_tiers_aggregate?: InputMaybe<Organization_Vendor_Tiers_Aggregate_Bool_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Bool_Exp>;
  workos_organization_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationsPkey = 'organizations_pkey',
}

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  categories?: InputMaybe<Categories_Arr_Rel_Insert_Input>;
  client_questionnaires?: InputMaybe<Client_Questionnaires_Arr_Rel_Insert_Input>;
  controls_histories?: InputMaybe<Controls_History_Stats_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  criteria?: InputMaybe<Criteria_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  evidence_tags?: InputMaybe<Organization_Evidence_Tags_Arr_Rel_Insert_Input>;
  evidences?: InputMaybe<Evidences_Arr_Rel_Insert_Input>;
  field_configs?: InputMaybe<Field_Configs_Arr_Rel_Insert_Input>;
  frameworks?: InputMaybe<Frameworks_Arr_Rel_Insert_Input>;
  groups?: InputMaybe<Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invitations?: InputMaybe<Invitations_Arr_Rel_Insert_Input>;
  is_audit_trail_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_control_ai_review_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_controls_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_day_zero_ai_features_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_policies_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_risks_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_vendor_questionnaire_ai_review_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_vendors_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_controls?: InputMaybe<Controls_Arr_Rel_Insert_Input>;
  organization_integrations?: InputMaybe<Organization_Integrations_Arr_Rel_Insert_Input>;
  organization_template_frameworks?: InputMaybe<Organization_Template_Frameworks_Arr_Rel_Insert_Input>;
  organization_tenants?: InputMaybe<Organization_Tenants_Arr_Rel_Insert_Input>;
  organization_users?: InputMaybe<Organization_Users_Arr_Rel_Insert_Input>;
  organizations?: InputMaybe<Organizations_Arr_Rel_Insert_Input>;
  parent_organization_id?: InputMaybe<Scalars['uuid']['input']>;
  policies?: InputMaybe<Policies_Arr_Rel_Insert_Input>;
  programs?: InputMaybe<Programs_Arr_Rel_Insert_Input>;
  questionnaires?: InputMaybe<Questionnaires_Arr_Rel_Insert_Input>;
  reports?: InputMaybe<Reports_Arr_Rel_Insert_Input>;
  risk_categories?: InputMaybe<Organization_Risk_Categories_Arr_Rel_Insert_Input>;
  risk_impacts?: InputMaybe<Risk_Impacts_Arr_Rel_Insert_Input>;
  risk_likelihoods?: InputMaybe<Risk_Likelihoods_Arr_Rel_Insert_Input>;
  risk_tags?: InputMaybe<Organization_Risk_Tags_Arr_Rel_Insert_Input>;
  risks?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  roles?: InputMaybe<Roles_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Tags_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_risk_levels?: InputMaybe<Organization_Vendor_Risk_Levels_Arr_Rel_Insert_Input>;
  vendor_tiers?: InputMaybe<Organization_Vendor_Tiers_Arr_Rel_Insert_Input>;
  vendors?: InputMaybe<Vendors_Arr_Rel_Insert_Input>;
  workos_organization_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: 'organizations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  workos_organization_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "organizations" */
export type Organizations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workos_organization_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: 'organizations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent_organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  workos_organization_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "organizations" */
export type Organizations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  workos_organization_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: 'organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** on_conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns?: Array<Organizations_Update_Column>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  categories_aggregate?: InputMaybe<Categories_Aggregate_Order_By>;
  client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Order_By>;
  controls_histories_aggregate?: InputMaybe<Controls_History_Stats_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria_aggregate?: InputMaybe<Criteria_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  disabled?: InputMaybe<Order_By>;
  evidence_tags_aggregate?: InputMaybe<Organization_Evidence_Tags_Aggregate_Order_By>;
  evidences_aggregate?: InputMaybe<Evidences_Aggregate_Order_By>;
  field_configs_aggregate?: InputMaybe<Field_Configs_Aggregate_Order_By>;
  frameworks_aggregate?: InputMaybe<Frameworks_Aggregate_Order_By>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Order_By>;
  is_audit_trail_enabled?: InputMaybe<Order_By>;
  is_control_ai_review_enabled?: InputMaybe<Order_By>;
  is_controls_module_enabled?: InputMaybe<Order_By>;
  is_day_zero_ai_features_enabled?: InputMaybe<Order_By>;
  is_policies_module_enabled?: InputMaybe<Order_By>;
  is_risks_module_enabled?: InputMaybe<Order_By>;
  is_vendor_questionnaire_ai_review_enabled?: InputMaybe<Order_By>;
  is_vendors_module_enabled?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_controls_aggregate?: InputMaybe<Controls_Aggregate_Order_By>;
  organization_integrations_aggregate?: InputMaybe<Organization_Integrations_Aggregate_Order_By>;
  organization_template_frameworks_aggregate?: InputMaybe<Organization_Template_Frameworks_Aggregate_Order_By>;
  organization_tenants_aggregate?: InputMaybe<Organization_Tenants_Aggregate_Order_By>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Order_By>;
  organizations_aggregate?: InputMaybe<Organizations_Aggregate_Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Order_By>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Order_By>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Order_By>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Order_By>;
  risk_categories_aggregate?: InputMaybe<Organization_Risk_Categories_Aggregate_Order_By>;
  risk_impacts_aggregate?: InputMaybe<Risk_Impacts_Aggregate_Order_By>;
  risk_likelihoods_aggregate?: InputMaybe<Risk_Likelihoods_Aggregate_Order_By>;
  risk_tags_aggregate?: InputMaybe<Organization_Risk_Tags_Aggregate_Order_By>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  roles_aggregate?: InputMaybe<Roles_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tags_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_risk_levels_aggregate?: InputMaybe<Organization_Vendor_Risk_Levels_Aggregate_Order_By>;
  vendor_tiers_aggregate?: InputMaybe<Organization_Vendor_Tiers_Aggregate_Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
  workos_organization_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organizations */
export type Organizations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  IsAuditTrailEnabled = 'is_audit_trail_enabled',
  /** column name */
  IsControlAiReviewEnabled = 'is_control_ai_review_enabled',
  /** column name */
  IsControlsModuleEnabled = 'is_controls_module_enabled',
  /** column name */
  IsDayZeroAiFeaturesEnabled = 'is_day_zero_ai_features_enabled',
  /** column name */
  IsPoliciesModuleEnabled = 'is_policies_module_enabled',
  /** column name */
  IsRisksModuleEnabled = 'is_risks_module_enabled',
  /** column name */
  IsVendorQuestionnaireAiReviewEnabled = 'is_vendor_questionnaire_ai_review_enabled',
  /** column name */
  IsVendorsModuleEnabled = 'is_vendors_module_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  ParentOrganizationId = 'parent_organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkosOrganizationId = 'workos_organization_id',
}

/** select "organizations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  IsAuditTrailEnabled = 'is_audit_trail_enabled',
  /** column name */
  IsControlAiReviewEnabled = 'is_control_ai_review_enabled',
  /** column name */
  IsControlsModuleEnabled = 'is_controls_module_enabled',
  /** column name */
  IsDayZeroAiFeaturesEnabled = 'is_day_zero_ai_features_enabled',
  /** column name */
  IsPoliciesModuleEnabled = 'is_policies_module_enabled',
  /** column name */
  IsRisksModuleEnabled = 'is_risks_module_enabled',
  /** column name */
  IsVendorQuestionnaireAiReviewEnabled = 'is_vendor_questionnaire_ai_review_enabled',
  /** column name */
  IsVendorsModuleEnabled = 'is_vendors_module_enabled',
}

/** select "organizations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  IsAuditTrailEnabled = 'is_audit_trail_enabled',
  /** column name */
  IsControlAiReviewEnabled = 'is_control_ai_review_enabled',
  /** column name */
  IsControlsModuleEnabled = 'is_controls_module_enabled',
  /** column name */
  IsDayZeroAiFeaturesEnabled = 'is_day_zero_ai_features_enabled',
  /** column name */
  IsPoliciesModuleEnabled = 'is_policies_module_enabled',
  /** column name */
  IsRisksModuleEnabled = 'is_risks_module_enabled',
  /** column name */
  IsVendorQuestionnaireAiReviewEnabled = 'is_vendor_questionnaire_ai_review_enabled',
  /** column name */
  IsVendorsModuleEnabled = 'is_vendors_module_enabled',
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_audit_trail_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_control_ai_review_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_controls_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_day_zero_ai_features_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_policies_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_risks_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_vendor_questionnaire_ai_review_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_vendors_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  workos_organization_id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "organizations" */
export type Organizations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organizations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organizations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_audit_trail_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_control_ai_review_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_controls_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_day_zero_ai_features_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_policies_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_risks_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_vendor_questionnaire_ai_review_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_vendors_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  workos_organization_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  IsAuditTrailEnabled = 'is_audit_trail_enabled',
  /** column name */
  IsControlAiReviewEnabled = 'is_control_ai_review_enabled',
  /** column name */
  IsControlsModuleEnabled = 'is_controls_module_enabled',
  /** column name */
  IsDayZeroAiFeaturesEnabled = 'is_day_zero_ai_features_enabled',
  /** column name */
  IsPoliciesModuleEnabled = 'is_policies_module_enabled',
  /** column name */
  IsRisksModuleEnabled = 'is_risks_module_enabled',
  /** column name */
  IsVendorQuestionnaireAiReviewEnabled = 'is_vendor_questionnaire_ai_review_enabled',
  /** column name */
  IsVendorsModuleEnabled = 'is_vendors_module_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  ParentOrganizationId = 'parent_organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkosOrganizationId = 'workos_organization_id',
}

export type Organizations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organizations_Bool_Exp;
};

/** Granular permissions for the application */
export type Permissions = {
  __typename?: 'permissions';
  comment: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  role_permissions: Array<Role_Permissions>;
  /** An aggregate relationship */
  role_permissions_aggregate: Role_Permissions_Aggregate;
};

/** Granular permissions for the application */
export type PermissionsRole_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

/** Granular permissions for the application */
export type PermissionsRole_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

/** aggregated selection of "permissions" */
export type Permissions_Aggregate = {
  __typename?: 'permissions_aggregate';
  aggregate?: Maybe<Permissions_Aggregate_Fields>;
  nodes: Array<Permissions>;
};

/** aggregate fields of "permissions" */
export type Permissions_Aggregate_Fields = {
  __typename?: 'permissions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Permissions_Max_Fields>;
  min?: Maybe<Permissions_Min_Fields>;
};

/** aggregate fields of "permissions" */
export type Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "permissions". All fields are combined with a logical 'AND'. */
export type Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Permissions_Bool_Exp>>;
  _not?: InputMaybe<Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Permissions_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role_permissions?: InputMaybe<Role_Permissions_Bool_Exp>;
  role_permissions_aggregate?: InputMaybe<Role_Permissions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "permissions" */
export enum Permissions_Constraint {
  /** unique or primary key constraint on columns "name" */
  PermissionsPkey = 'permissions_pkey',
}

export enum Permissions_Enum {
  /** Can acknowledge policies */
  AcknowledgePolicies = 'acknowledge_policies',
  /** ability to create or delete controls */
  CreateControls = 'create_controls',
  /** ability to link evidence to controls */
  LinkControlsEvidence = 'link_controls_evidence',
  /** ability to link controls to programs */
  LinkControlsPrograms = 'link_controls_programs',
  /** ability to link controls to risks */
  LinkControlsRisks = 'link_controls_risks',
  /** ability to link risks to vendors */
  LinkRisksVendors = 'link_risks_vendors',
  /** ability to manage risk classification */
  ManageRiskClassification = 'manage_risk_classification',
  /** notified when control is at risk or moves to invalid */
  NotifyOnControlAtRisk = 'notify_on_control_at_risk',
  /** ability to get notified when questionnaire is overdue */
  NotifyOnQuestionnaireOverdue = 'notify_on_questionnaire_overdue',
  /** ability to get notified when risk is expiring */
  NotifyOnRiskExpiring = 'notify_on_risk_expiring',
  /** ability to get notified when vendor is expiring */
  NotifyOnVendorExpiring = 'notify_on_vendor_expiring',
  /** View client questionnaires */
  ReadClientQ = 'read_client_q',
  /** ability to read confidential evidence */
  ReadConfidentialEvidence = 'read_confidential_evidence',
  /** ability to read controls */
  ReadControls = 'read_controls',
  /** ability to read evidence */
  ReadEvidence = 'read_evidence',
  /** ability to view policy */
  ReadPolicies = 'read_policies',
  /** ability to read reports */
  ReadReports = 'read_reports',
  /** ability to read risks */
  ReadRisks = 'read_risks',
  /** ability to view tasks */
  ReadTasks = 'read_tasks',
  /** ability to read vendors */
  ReadVendors = 'read_vendors',
  /** ability to update controls */
  UpdateControls = 'update_controls',
  /** Create and edit client questionnaires */
  WriteClientQ = 'write_client_q',
  /** ability to modify confidential evidence */
  WriteConfidentialEvidence = 'write_confidential_evidence',
  /** Create and edit custom fields for controls */
  WriteControlFieldConfigs = 'write_control_field_configs',
  /** ability to modify evidence */
  WriteEvidence = 'write_evidence',
  /** ability to modify policy */
  WritePolicies = 'write_policies',
  /** Create and edit custom fields for policies */
  WritePolicyFieldConfigs = 'write_policy_field_configs',
  /** ability to modify programs */
  WritePrograms = 'write_programs',
  /** ability to modify reports */
  WriteReports = 'write_reports',
  /** ability to modify risk custom field configs */
  WriteRiskFieldConfigs = 'write_risk_field_configs',
  /** ability to modify risks */
  WriteRisks = 'write_risks',
  /** ability to modify roles */
  WriteRoles = 'write_roles',
  /** Create and edit custom fields for tasks */
  WriteTaskFieldConfigs = 'write_task_field_configs',
  /** ability to create and update tasks */
  WriteTasks = 'write_tasks',
  /** ability to modify vendor custom field configs */
  WriteVendorFieldConfigs = 'write_vendor_field_configs',
  /** ability to modify vendors */
  WriteVendors = 'write_vendors',
}

/** Boolean expression to compare columns of type "permissions_enum". All fields are combined with logical 'AND'. */
export type Permissions_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Permissions_Enum>;
  _in?: InputMaybe<Array<Permissions_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Permissions_Enum>;
  _nin?: InputMaybe<Array<Permissions_Enum>>;
};

/** input type for inserting data into table "permissions" */
export type Permissions_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  role_permissions?: InputMaybe<Role_Permissions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Permissions_Max_Fields = {
  __typename?: 'permissions_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Permissions_Min_Fields = {
  __typename?: 'permissions_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "permissions" */
export type Permissions_Mutation_Response = {
  __typename?: 'permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Permissions>;
};

/** input type for inserting object relation for remote table "permissions" */
export type Permissions_Obj_Rel_Insert_Input = {
  data: Permissions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};

/** on_conflict condition type for table "permissions" */
export type Permissions_On_Conflict = {
  constraint: Permissions_Constraint;
  update_columns?: Array<Permissions_Update_Column>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "permissions". */
export type Permissions_Order_By = {
  comment?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  role_permissions_aggregate?: InputMaybe<Role_Permissions_Aggregate_Order_By>;
};

/** primary key columns input for table: permissions */
export type Permissions_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "permissions" */
export enum Permissions_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "permissions" */
export type Permissions_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "permissions" */
export type Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Permissions_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "permissions" */
export enum Permissions_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Name = 'name',
}

export type Permissions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Permissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Permissions_Bool_Exp;
};

/** policies module */
export type Policies = {
  __typename?: 'policies';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  evidence_policies: Array<Evidence_Policies>;
  /** An aggregate relationship */
  evidence_policies_aggregate: Evidence_Policies_Aggregate;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  frequency: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  policies_comments: Array<Comments>;
  /** An aggregate relationship */
  policies_comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  policies_policy_tasks: Array<Policy_Tasks>;
  /** An aggregate relationship */
  policies_policy_tasks_aggregate: Policy_Tasks_Aggregate;
  /** An object relationship */
  policies_vector_store?: Maybe<Ai_Policies_Vector_Store>;
  /** An array relationship */
  policy_approvers: Array<Policy_Approvers>;
  /** An aggregate relationship */
  policy_approvers_aggregate: Policy_Approvers_Aggregate;
  /** An object relationship */
  policy_status: Policy_Statuses;
  /** An object relationship */
  policy_type: Policy_Types;
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  /** An aggregate relationship */
  policy_versions_aggregate: Policy_Versions_Aggregate;
  similarity?: Maybe<Scalars['float8']['output']>;
  status: Policy_Statuses_Enum;
  type: Policy_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** policies module */
export type PoliciesEvidence_PoliciesArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Policies_Order_By>>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

/** policies module */
export type PoliciesEvidence_Policies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Policies_Order_By>>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

/** policies module */
export type PoliciesField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** policies module */
export type PoliciesField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** policies module */
export type PoliciesNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** policies module */
export type PoliciesNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicies_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicies_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicies_Policy_TasksArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicies_Policy_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicy_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicy_Approvers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicy_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** aggregated selection of "policies" */
export type Policies_Aggregate = {
  __typename?: 'policies_aggregate';
  aggregate?: Maybe<Policies_Aggregate_Fields>;
  nodes: Array<Policies>;
};

export type Policies_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Policies_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Policies_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Policies_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Policies_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Policies_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Policies_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Policies_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Policies_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Policies_Aggregate_Bool_Exp_Var_Samp>;
};

export type Policies_Aggregate_Bool_Exp_Avg = {
  arguments: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Corr = {
  arguments: Policies_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Policies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Policies_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Policies_Aggregate_Bool_Exp_Max = {
  arguments: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Min = {
  arguments: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Sum = {
  arguments: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "policies" */
export type Policies_Aggregate_Fields = {
  __typename?: 'policies_aggregate_fields';
  avg?: Maybe<Policies_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Policies_Max_Fields>;
  min?: Maybe<Policies_Min_Fields>;
  stddev?: Maybe<Policies_Stddev_Fields>;
  stddev_pop?: Maybe<Policies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Policies_Stddev_Samp_Fields>;
  sum?: Maybe<Policies_Sum_Fields>;
  var_pop?: Maybe<Policies_Var_Pop_Fields>;
  var_samp?: Maybe<Policies_Var_Samp_Fields>;
  variance?: Maybe<Policies_Variance_Fields>;
};

/** aggregate fields of "policies" */
export type Policies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policies" */
export type Policies_Aggregate_Order_By = {
  avg?: InputMaybe<Policies_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policies_Max_Order_By>;
  min?: InputMaybe<Policies_Min_Order_By>;
  stddev?: InputMaybe<Policies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Policies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Policies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Policies_Sum_Order_By>;
  var_pop?: InputMaybe<Policies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Policies_Var_Samp_Order_By>;
  variance?: InputMaybe<Policies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "policies" */
export type Policies_Arr_Rel_Insert_Input = {
  data: Array<Policies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** aggregate avg on columns */
export type Policies_Avg_Fields = {
  __typename?: 'policies_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "policies" */
export type Policies_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "policies". All fields are combined with a logical 'AND'. */
export type Policies_Bool_Exp = {
  _and?: InputMaybe<Array<Policies_Bool_Exp>>;
  _not?: InputMaybe<Policies_Bool_Exp>;
  _or?: InputMaybe<Array<Policies_Bool_Exp>>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  evidence_policies?: InputMaybe<Evidence_Policies_Bool_Exp>;
  evidence_policies_aggregate?: InputMaybe<Evidence_Policies_Aggregate_Bool_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Bool_Exp>;
  frequency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  policies_comments?: InputMaybe<Comments_Bool_Exp>;
  policies_comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  policies_policy_tasks?: InputMaybe<Policy_Tasks_Bool_Exp>;
  policies_policy_tasks_aggregate?: InputMaybe<Policy_Tasks_Aggregate_Bool_Exp>;
  policies_vector_store?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
  policy_approvers?: InputMaybe<Policy_Approvers_Bool_Exp>;
  policy_approvers_aggregate?: InputMaybe<Policy_Approvers_Aggregate_Bool_Exp>;
  policy_status?: InputMaybe<Policy_Statuses_Bool_Exp>;
  policy_type?: InputMaybe<Policy_Types_Bool_Exp>;
  policy_versions?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  status?: InputMaybe<Policy_Statuses_Enum_Comparison_Exp>;
  type?: InputMaybe<Policy_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "policies" */
export enum Policies_Constraint {
  /** unique or primary key constraint on columns "id" */
  PoliciesPkey = 'policies_pkey',
}

/** input type for incrementing numeric columns in table "policies" */
export type Policies_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "policies" */
export type Policies_Insert_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  evidence_policies?: InputMaybe<Evidence_Policies_Arr_Rel_Insert_Input>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  policies_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  policies_policy_tasks?: InputMaybe<Policy_Tasks_Arr_Rel_Insert_Input>;
  policies_vector_store?: InputMaybe<Ai_Policies_Vector_Store_Obj_Rel_Insert_Input>;
  policy_approvers?: InputMaybe<Policy_Approvers_Arr_Rel_Insert_Input>;
  policy_status?: InputMaybe<Policy_Statuses_Obj_Rel_Insert_Input>;
  policy_type?: InputMaybe<Policy_Types_Obj_Rel_Insert_Input>;
  policy_versions?: InputMaybe<Policy_Versions_Arr_Rel_Insert_Input>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Policy_Statuses_Enum>;
  type?: InputMaybe<Policy_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Policies_Max_Fields = {
  __typename?: 'policies_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "policies" */
export type Policies_Max_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policies_Min_Fields = {
  __typename?: 'policies_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "policies" */
export type Policies_Min_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policies" */
export type Policies_Mutation_Response = {
  __typename?: 'policies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policies>;
};

/** input type for inserting object relation for remote table "policies" */
export type Policies_Obj_Rel_Insert_Input = {
  data: Policies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** on_conflict condition type for table "policies" */
export type Policies_On_Conflict = {
  constraint: Policies_Constraint;
  update_columns?: Array<Policies_Update_Column>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** Ordering options when selecting data from "policies". */
export type Policies_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  evidence_policies_aggregate?: InputMaybe<Evidence_Policies_Aggregate_Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  policies_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  policies_policy_tasks_aggregate?: InputMaybe<Policy_Tasks_Aggregate_Order_By>;
  policies_vector_store?: InputMaybe<Ai_Policies_Vector_Store_Order_By>;
  policy_approvers_aggregate?: InputMaybe<Policy_Approvers_Aggregate_Order_By>;
  policy_status?: InputMaybe<Policy_Statuses_Order_By>;
  policy_type?: InputMaybe<Policy_Types_Order_By>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: policies */
export type Policies_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policies" */
export enum Policies_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "policies_aggregate_bool_exp_avg_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "policies_aggregate_bool_exp_corr_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "policies_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "policies_aggregate_bool_exp_max_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "policies_aggregate_bool_exp_min_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "policies_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "policies_aggregate_bool_exp_sum_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "policies_aggregate_bool_exp_var_samp_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "policies" */
export type Policies_Set_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Policy_Statuses_Enum>;
  type?: InputMaybe<Policy_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Policies_Stddev_Fields = {
  __typename?: 'policies_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "policies" */
export type Policies_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Policies_Stddev_Pop_Fields = {
  __typename?: 'policies_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "policies" */
export type Policies_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Policies_Stddev_Samp_Fields = {
  __typename?: 'policies_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "policies" */
export type Policies_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "policies" */
export type Policies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policies_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Policy_Statuses_Enum>;
  type?: InputMaybe<Policy_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Policies_Sum_Fields = {
  __typename?: 'policies_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "policies" */
export type Policies_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "policies" */
export enum Policies_Update_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Policies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Policies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Policies_Var_Pop_Fields = {
  __typename?: 'policies_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "policies" */
export type Policies_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Policies_Var_Samp_Fields = {
  __typename?: 'policies_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "policies" */
export type Policies_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Policies_Variance_Fields = {
  __typename?: 'policies_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "policies" */
export type Policies_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses = {
  __typename?: 'policy_acknowledgement_statuses';
  name: Scalars['String']['output'];
  /** An array relationship */
  policy_acknowledgements: Array<Policy_Acknowledgements>;
  /** An aggregate relationship */
  policy_acknowledgements_aggregate: Policy_Acknowledgements_Aggregate;
};

/** columns and relationships of "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_StatusesPolicy_AcknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** columns and relationships of "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_StatusesPolicy_Acknowledgements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** aggregated selection of "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_Aggregate = {
  __typename?: 'policy_acknowledgement_statuses_aggregate';
  aggregate?: Maybe<Policy_Acknowledgement_Statuses_Aggregate_Fields>;
  nodes: Array<Policy_Acknowledgement_Statuses>;
};

/** aggregate fields of "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_Aggregate_Fields = {
  __typename?: 'policy_acknowledgement_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Acknowledgement_Statuses_Max_Fields>;
  min?: Maybe<Policy_Acknowledgement_Statuses_Min_Fields>;
};

/** aggregate fields of "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "policy_acknowledgement_statuses". All fields are combined with a logical 'AND'. */
export type Policy_Acknowledgement_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Policy_Acknowledgement_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  policy_acknowledgements_aggregate?: InputMaybe<Policy_Acknowledgements_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_acknowledgement_statuses" */
export enum Policy_Acknowledgement_Statuses_Constraint {
  /** unique or primary key constraint on columns "name" */
  PolicyAcknowledgementStatusesPkey = 'policy_acknowledgement_statuses_pkey',
}

export enum Policy_Acknowledgement_Statuses_Enum {
  Acknowledged = 'Acknowledged',
  Expiring = 'Expiring',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Retired = 'Retired',
}

/** Boolean expression to compare columns of type "policy_acknowledgement_statuses_enum". All fields are combined with logical 'AND'. */
export type Policy_Acknowledgement_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Acknowledgement_Statuses_Enum>;
  _in?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Acknowledgement_Statuses_Enum>;
  _nin?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Enum>>;
};

/** input type for inserting data into table "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Policy_Acknowledgement_Statuses_Max_Fields = {
  __typename?: 'policy_acknowledgement_statuses_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Policy_Acknowledgement_Statuses_Min_Fields = {
  __typename?: 'policy_acknowledgement_statuses_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_Mutation_Response = {
  __typename?: 'policy_acknowledgement_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Acknowledgement_Statuses>;
};

/** input type for inserting object relation for remote table "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_Obj_Rel_Insert_Input = {
  data: Policy_Acknowledgement_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Acknowledgement_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_On_Conflict = {
  constraint: Policy_Acknowledgement_Statuses_Constraint;
  update_columns?: Array<Policy_Acknowledgement_Statuses_Update_Column>;
  where?: InputMaybe<Policy_Acknowledgement_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_acknowledgement_statuses". */
export type Policy_Acknowledgement_Statuses_Order_By = {
  name?: InputMaybe<Order_By>;
  policy_acknowledgements_aggregate?: InputMaybe<Policy_Acknowledgements_Aggregate_Order_By>;
};

/** primary key columns input for table: policy_acknowledgement_statuses */
export type Policy_Acknowledgement_Statuses_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "policy_acknowledgement_statuses" */
export enum Policy_Acknowledgement_Statuses_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "policy_acknowledgement_statuses" */
export type Policy_Acknowledgement_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Acknowledgement_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Acknowledgement_Statuses_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "policy_acknowledgement_statuses" */
export enum Policy_Acknowledgement_Statuses_Update_Column {
  /** column name */
  Name = 'name',
}

export type Policy_Acknowledgement_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Acknowledgement_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Acknowledgement_Statuses_Bool_Exp;
};

/** columns and relationships of "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users = {
  __typename?: 'policy_acknowledgement_users';
  acknowledged_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  policy_acknowledgement: Policy_Acknowledgements;
  policy_acknowledgement_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Aggregate = {
  __typename?: 'policy_acknowledgement_users_aggregate';
  aggregate?: Maybe<Policy_Acknowledgement_Users_Aggregate_Fields>;
  nodes: Array<Policy_Acknowledgement_Users>;
};

export type Policy_Acknowledgement_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Bool_Exp_Count>;
};

export type Policy_Acknowledgement_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Aggregate_Fields = {
  __typename?: 'policy_acknowledgement_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Acknowledgement_Users_Max_Fields>;
  min?: Maybe<Policy_Acknowledgement_Users_Min_Fields>;
};

/** aggregate fields of "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Acknowledgement_Users_Max_Order_By>;
  min?: InputMaybe<Policy_Acknowledgement_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Arr_Rel_Insert_Input = {
  data: Array<Policy_Acknowledgement_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Acknowledgement_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_acknowledgement_users". All fields are combined with a logical 'AND'. */
export type Policy_Acknowledgement_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Acknowledgement_Users_Bool_Exp>>;
  _not?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Acknowledgement_Users_Bool_Exp>>;
  acknowledged_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_acknowledgement?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  policy_acknowledgement_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_acknowledgement_users" */
export enum Policy_Acknowledgement_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyAcknowledgementUsersPkey = 'policy_acknowledgement_users_pkey',
  /** unique or primary key constraint on columns "user_id", "policy_acknowledgement_id" */
  PolicyAcknowledgementUsersUserIdPolicyAcknowledgementKey = 'policy_acknowledgement_users_user_id_policy_acknowledgement_key',
}

/** input type for inserting data into table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Insert_Input = {
  acknowledged_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_acknowledgement?: InputMaybe<Policy_Acknowledgements_Obj_Rel_Insert_Input>;
  policy_acknowledgement_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Policy_Acknowledgement_Users_Max_Fields = {
  __typename?: 'policy_acknowledgement_users_max_fields';
  acknowledged_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_acknowledgement_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Max_Order_By = {
  acknowledged_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_acknowledgement_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Acknowledgement_Users_Min_Fields = {
  __typename?: 'policy_acknowledgement_users_min_fields';
  acknowledged_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_acknowledgement_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Min_Order_By = {
  acknowledged_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_acknowledgement_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Mutation_Response = {
  __typename?: 'policy_acknowledgement_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Acknowledgement_Users>;
};

/** on_conflict condition type for table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_On_Conflict = {
  constraint: Policy_Acknowledgement_Users_Constraint;
  update_columns?: Array<Policy_Acknowledgement_Users_Update_Column>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_acknowledgement_users". */
export type Policy_Acknowledgement_Users_Order_By = {
  acknowledged_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_acknowledgement?: InputMaybe<Policy_Acknowledgements_Order_By>;
  policy_acknowledgement_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policy_acknowledgement_users */
export type Policy_Acknowledgement_Users_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_acknowledgement_users" */
export enum Policy_Acknowledgement_Users_Select_Column {
  /** column name */
  AcknowledgedAt = 'acknowledged_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyAcknowledgementId = 'policy_acknowledgement_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Set_Input = {
  acknowledged_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_acknowledgement_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Acknowledgement_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Acknowledgement_Users_Stream_Cursor_Value_Input = {
  acknowledged_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_acknowledgement_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "policy_acknowledgement_users" */
export enum Policy_Acknowledgement_Users_Update_Column {
  /** column name */
  AcknowledgedAt = 'acknowledged_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyAcknowledgementId = 'policy_acknowledgement_id',
  /** column name */
  UserId = 'user_id',
}

export type Policy_Acknowledgement_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Acknowledgement_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Acknowledgement_Users_Bool_Exp;
};

/** columns and relationships of "policy_acknowledgements" */
export type Policy_Acknowledgements = {
  __typename?: 'policy_acknowledgements';
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['uuid']['output'];
  due_date: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  message: Scalars['String']['output'];
  /** An object relationship */
  policy_acknowledgement_status: Policy_Acknowledgement_Statuses;
  /** An array relationship */
  policy_acknowledgement_users: Array<Policy_Acknowledgement_Users>;
  /** An aggregate relationship */
  policy_acknowledgement_users_aggregate: Policy_Acknowledgement_Users_Aggregate;
  /** An object relationship */
  policy_version: Policy_Versions;
  policy_version_id: Scalars['uuid']['output'];
  status: Policy_Acknowledgement_Statuses_Enum;
  subject: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};

/** columns and relationships of "policy_acknowledgements" */
export type Policy_AcknowledgementsPolicy_Acknowledgement_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** columns and relationships of "policy_acknowledgements" */
export type Policy_AcknowledgementsPolicy_Acknowledgement_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** aggregated selection of "policy_acknowledgements" */
export type Policy_Acknowledgements_Aggregate = {
  __typename?: 'policy_acknowledgements_aggregate';
  aggregate?: Maybe<Policy_Acknowledgements_Aggregate_Fields>;
  nodes: Array<Policy_Acknowledgements>;
};

export type Policy_Acknowledgements_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Acknowledgements_Aggregate_Bool_Exp_Count>;
};

export type Policy_Acknowledgements_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_acknowledgements" */
export type Policy_Acknowledgements_Aggregate_Fields = {
  __typename?: 'policy_acknowledgements_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Acknowledgements_Max_Fields>;
  min?: Maybe<Policy_Acknowledgements_Min_Fields>;
};

/** aggregate fields of "policy_acknowledgements" */
export type Policy_Acknowledgements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_acknowledgements" */
export type Policy_Acknowledgements_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Acknowledgements_Max_Order_By>;
  min?: InputMaybe<Policy_Acknowledgements_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_acknowledgements" */
export type Policy_Acknowledgements_Arr_Rel_Insert_Input = {
  data: Array<Policy_Acknowledgements_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Acknowledgements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_acknowledgements". All fields are combined with a logical 'AND'. */
export type Policy_Acknowledgements_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Acknowledgements_Bool_Exp>>;
  _not?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Acknowledgements_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  policy_acknowledgement_status?: InputMaybe<Policy_Acknowledgement_Statuses_Bool_Exp>;
  policy_acknowledgement_users?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
  policy_acknowledgement_users_aggregate?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Bool_Exp>;
  policy_version?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Policy_Acknowledgement_Statuses_Enum_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_acknowledgements" */
export enum Policy_Acknowledgements_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyAcknowledgementsPkey = 'policy_acknowledgements_pkey',
}

/** input type for inserting data into table "policy_acknowledgements" */
export type Policy_Acknowledgements_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policy_acknowledgement_status?: InputMaybe<Policy_Acknowledgement_Statuses_Obj_Rel_Insert_Input>;
  policy_acknowledgement_users?: InputMaybe<Policy_Acknowledgement_Users_Arr_Rel_Insert_Input>;
  policy_version?: InputMaybe<Policy_Versions_Obj_Rel_Insert_Input>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Policy_Acknowledgement_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Policy_Acknowledgements_Max_Fields = {
  __typename?: 'policy_acknowledgements_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "policy_acknowledgements" */
export type Policy_Acknowledgements_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Acknowledgements_Min_Fields = {
  __typename?: 'policy_acknowledgements_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "policy_acknowledgements" */
export type Policy_Acknowledgements_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_acknowledgements" */
export type Policy_Acknowledgements_Mutation_Response = {
  __typename?: 'policy_acknowledgements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Acknowledgements>;
};

/** input type for inserting object relation for remote table "policy_acknowledgements" */
export type Policy_Acknowledgements_Obj_Rel_Insert_Input = {
  data: Policy_Acknowledgements_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Acknowledgements_On_Conflict>;
};

/** on_conflict condition type for table "policy_acknowledgements" */
export type Policy_Acknowledgements_On_Conflict = {
  constraint: Policy_Acknowledgements_Constraint;
  update_columns?: Array<Policy_Acknowledgements_Update_Column>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_acknowledgements". */
export type Policy_Acknowledgements_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_acknowledgement_status?: InputMaybe<Policy_Acknowledgement_Statuses_Order_By>;
  policy_acknowledgement_users_aggregate?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Order_By>;
  policy_version?: InputMaybe<Policy_Versions_Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: policy_acknowledgements */
export type Policy_Acknowledgements_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_acknowledgements" */
export enum Policy_Acknowledgements_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "policy_acknowledgements" */
export type Policy_Acknowledgements_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Policy_Acknowledgement_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "policy_acknowledgements" */
export type Policy_Acknowledgements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Acknowledgements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Acknowledgements_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Policy_Acknowledgement_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "policy_acknowledgements" */
export enum Policy_Acknowledgements_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Policy_Acknowledgements_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Acknowledgements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Acknowledgements_Bool_Exp;
};

/** columns and relationships of "policy_approval_statuses" */
export type Policy_Approval_Statuses = {
  __typename?: 'policy_approval_statuses';
  name: Scalars['String']['output'];
  /** An array relationship */
  policy_approvals: Array<Policy_Approvals>;
  /** An aggregate relationship */
  policy_approvals_aggregate: Policy_Approvals_Aggregate;
};

/** columns and relationships of "policy_approval_statuses" */
export type Policy_Approval_StatusesPolicy_ApprovalsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** columns and relationships of "policy_approval_statuses" */
export type Policy_Approval_StatusesPolicy_Approvals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** aggregated selection of "policy_approval_statuses" */
export type Policy_Approval_Statuses_Aggregate = {
  __typename?: 'policy_approval_statuses_aggregate';
  aggregate?: Maybe<Policy_Approval_Statuses_Aggregate_Fields>;
  nodes: Array<Policy_Approval_Statuses>;
};

/** aggregate fields of "policy_approval_statuses" */
export type Policy_Approval_Statuses_Aggregate_Fields = {
  __typename?: 'policy_approval_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Approval_Statuses_Max_Fields>;
  min?: Maybe<Policy_Approval_Statuses_Min_Fields>;
};

/** aggregate fields of "policy_approval_statuses" */
export type Policy_Approval_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Approval_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "policy_approval_statuses". All fields are combined with a logical 'AND'. */
export type Policy_Approval_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Approval_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Policy_Approval_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Approval_Statuses_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  policy_approvals?: InputMaybe<Policy_Approvals_Bool_Exp>;
  policy_approvals_aggregate?: InputMaybe<Policy_Approvals_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_approval_statuses" */
export enum Policy_Approval_Statuses_Constraint {
  /** unique or primary key constraint on columns "name" */
  PolicyApprovalStatusesPkey = 'policy_approval_statuses_pkey',
}

export enum Policy_Approval_Statuses_Enum {
  Approved = 'Approved',
  Expiring = 'Expiring',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Retired = 'Retired',
}

/** Boolean expression to compare columns of type "policy_approval_statuses_enum". All fields are combined with logical 'AND'. */
export type Policy_Approval_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Approval_Statuses_Enum>;
  _in?: InputMaybe<Array<Policy_Approval_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Approval_Statuses_Enum>;
  _nin?: InputMaybe<Array<Policy_Approval_Statuses_Enum>>;
};

/** input type for inserting data into table "policy_approval_statuses" */
export type Policy_Approval_Statuses_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  policy_approvals?: InputMaybe<Policy_Approvals_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Policy_Approval_Statuses_Max_Fields = {
  __typename?: 'policy_approval_statuses_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Policy_Approval_Statuses_Min_Fields = {
  __typename?: 'policy_approval_statuses_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "policy_approval_statuses" */
export type Policy_Approval_Statuses_Mutation_Response = {
  __typename?: 'policy_approval_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Approval_Statuses>;
};

/** input type for inserting object relation for remote table "policy_approval_statuses" */
export type Policy_Approval_Statuses_Obj_Rel_Insert_Input = {
  data: Policy_Approval_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approval_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "policy_approval_statuses" */
export type Policy_Approval_Statuses_On_Conflict = {
  constraint: Policy_Approval_Statuses_Constraint;
  update_columns?: Array<Policy_Approval_Statuses_Update_Column>;
  where?: InputMaybe<Policy_Approval_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_approval_statuses". */
export type Policy_Approval_Statuses_Order_By = {
  name?: InputMaybe<Order_By>;
  policy_approvals_aggregate?: InputMaybe<Policy_Approvals_Aggregate_Order_By>;
};

/** primary key columns input for table: policy_approval_statuses */
export type Policy_Approval_Statuses_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "policy_approval_statuses" */
export enum Policy_Approval_Statuses_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "policy_approval_statuses" */
export type Policy_Approval_Statuses_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "policy_approval_statuses" */
export type Policy_Approval_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Approval_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Approval_Statuses_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "policy_approval_statuses" */
export enum Policy_Approval_Statuses_Update_Column {
  /** column name */
  Name = 'name',
}

export type Policy_Approval_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Approval_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Approval_Statuses_Bool_Exp;
};

/** users approving a policy */
export type Policy_Approval_Users = {
  __typename?: 'policy_approval_users';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  policy_approval: Policy_Approvals;
  policy_approval_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "policy_approval_users" */
export type Policy_Approval_Users_Aggregate = {
  __typename?: 'policy_approval_users_aggregate';
  aggregate?: Maybe<Policy_Approval_Users_Aggregate_Fields>;
  nodes: Array<Policy_Approval_Users>;
};

export type Policy_Approval_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Approval_Users_Aggregate_Bool_Exp_Count>;
};

export type Policy_Approval_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_approval_users" */
export type Policy_Approval_Users_Aggregate_Fields = {
  __typename?: 'policy_approval_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Approval_Users_Max_Fields>;
  min?: Maybe<Policy_Approval_Users_Min_Fields>;
};

/** aggregate fields of "policy_approval_users" */
export type Policy_Approval_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_approval_users" */
export type Policy_Approval_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Approval_Users_Max_Order_By>;
  min?: InputMaybe<Policy_Approval_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_approval_users" */
export type Policy_Approval_Users_Arr_Rel_Insert_Input = {
  data: Array<Policy_Approval_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approval_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_approval_users". All fields are combined with a logical 'AND'. */
export type Policy_Approval_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Approval_Users_Bool_Exp>>;
  _not?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Approval_Users_Bool_Exp>>;
  approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_approval?: InputMaybe<Policy_Approvals_Bool_Exp>;
  policy_approval_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_approval_users" */
export enum Policy_Approval_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyApprovalUsersPkey = 'policy_approval_users_pkey',
  /** unique or primary key constraint on columns "user_id", "policy_approval_id" */
  PolicyApprovalUsersPolicyApprovalIdUserIdKey = 'policy_approval_users_policy_approval_id_user_id_key',
}

/** input type for inserting data into table "policy_approval_users" */
export type Policy_Approval_Users_Insert_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_approval?: InputMaybe<Policy_Approvals_Obj_Rel_Insert_Input>;
  policy_approval_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Policy_Approval_Users_Max_Fields = {
  __typename?: 'policy_approval_users_max_fields';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_approval_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "policy_approval_users" */
export type Policy_Approval_Users_Max_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_approval_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Approval_Users_Min_Fields = {
  __typename?: 'policy_approval_users_min_fields';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_approval_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "policy_approval_users" */
export type Policy_Approval_Users_Min_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_approval_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_approval_users" */
export type Policy_Approval_Users_Mutation_Response = {
  __typename?: 'policy_approval_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Approval_Users>;
};

/** on_conflict condition type for table "policy_approval_users" */
export type Policy_Approval_Users_On_Conflict = {
  constraint: Policy_Approval_Users_Constraint;
  update_columns?: Array<Policy_Approval_Users_Update_Column>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_approval_users". */
export type Policy_Approval_Users_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_approval?: InputMaybe<Policy_Approvals_Order_By>;
  policy_approval_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policy_approval_users */
export type Policy_Approval_Users_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_approval_users" */
export enum Policy_Approval_Users_Select_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyApprovalId = 'policy_approval_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "policy_approval_users" */
export type Policy_Approval_Users_Set_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_approval_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "policy_approval_users" */
export type Policy_Approval_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Approval_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Approval_Users_Stream_Cursor_Value_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_approval_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "policy_approval_users" */
export enum Policy_Approval_Users_Update_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyApprovalId = 'policy_approval_id',
  /** column name */
  UserId = 'user_id',
}

export type Policy_Approval_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Approval_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Approval_Users_Bool_Exp;
};

/** table for approvals */
export type Policy_Approvals = {
  __typename?: 'policy_approvals';
  /** A computed field, executes function "policy_approvals_approved_at" */
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['uuid']['output'];
  due_date: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  message: Scalars['String']['output'];
  /** An object relationship */
  policy_approval_status: Policy_Approval_Statuses;
  /** An array relationship */
  policy_approval_users: Array<Policy_Approval_Users>;
  /** An aggregate relationship */
  policy_approval_users_aggregate: Policy_Approval_Users_Aggregate;
  /** An object relationship */
  policy_version: Policy_Versions;
  policy_version_id: Scalars['uuid']['output'];
  status: Policy_Approval_Statuses_Enum;
  subject: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};

/** table for approvals */
export type Policy_ApprovalsPolicy_Approval_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** table for approvals */
export type Policy_ApprovalsPolicy_Approval_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** aggregated selection of "policy_approvals" */
export type Policy_Approvals_Aggregate = {
  __typename?: 'policy_approvals_aggregate';
  aggregate?: Maybe<Policy_Approvals_Aggregate_Fields>;
  nodes: Array<Policy_Approvals>;
};

export type Policy_Approvals_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Approvals_Aggregate_Bool_Exp_Count>;
};

export type Policy_Approvals_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Approvals_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_approvals" */
export type Policy_Approvals_Aggregate_Fields = {
  __typename?: 'policy_approvals_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Approvals_Max_Fields>;
  min?: Maybe<Policy_Approvals_Min_Fields>;
};

/** aggregate fields of "policy_approvals" */
export type Policy_Approvals_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_approvals" */
export type Policy_Approvals_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Approvals_Max_Order_By>;
  min?: InputMaybe<Policy_Approvals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_approvals" */
export type Policy_Approvals_Arr_Rel_Insert_Input = {
  data: Array<Policy_Approvals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approvals_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_approvals". All fields are combined with a logical 'AND'. */
export type Policy_Approvals_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Approvals_Bool_Exp>>;
  _not?: InputMaybe<Policy_Approvals_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Approvals_Bool_Exp>>;
  approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  policy_approval_status?: InputMaybe<Policy_Approval_Statuses_Bool_Exp>;
  policy_approval_users?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
  policy_approval_users_aggregate?: InputMaybe<Policy_Approval_Users_Aggregate_Bool_Exp>;
  policy_version?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Policy_Approval_Statuses_Enum_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_approvals" */
export enum Policy_Approvals_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyApprovalsPkey = 'policy_approvals_pkey',
}

/** input type for inserting data into table "policy_approvals" */
export type Policy_Approvals_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policy_approval_status?: InputMaybe<Policy_Approval_Statuses_Obj_Rel_Insert_Input>;
  policy_approval_users?: InputMaybe<Policy_Approval_Users_Arr_Rel_Insert_Input>;
  policy_version?: InputMaybe<Policy_Versions_Obj_Rel_Insert_Input>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Policy_Approval_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Policy_Approvals_Max_Fields = {
  __typename?: 'policy_approvals_max_fields';
  /** A computed field, executes function "policy_approvals_approved_at" */
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "policy_approvals" */
export type Policy_Approvals_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Approvals_Min_Fields = {
  __typename?: 'policy_approvals_min_fields';
  /** A computed field, executes function "policy_approvals_approved_at" */
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "policy_approvals" */
export type Policy_Approvals_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_approvals" */
export type Policy_Approvals_Mutation_Response = {
  __typename?: 'policy_approvals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Approvals>;
};

/** input type for inserting object relation for remote table "policy_approvals" */
export type Policy_Approvals_Obj_Rel_Insert_Input = {
  data: Policy_Approvals_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approvals_On_Conflict>;
};

/** on_conflict condition type for table "policy_approvals" */
export type Policy_Approvals_On_Conflict = {
  constraint: Policy_Approvals_Constraint;
  update_columns?: Array<Policy_Approvals_Update_Column>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_approvals". */
export type Policy_Approvals_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_approval_status?: InputMaybe<Policy_Approval_Statuses_Order_By>;
  policy_approval_users_aggregate?: InputMaybe<Policy_Approval_Users_Aggregate_Order_By>;
  policy_version?: InputMaybe<Policy_Versions_Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: policy_approvals */
export type Policy_Approvals_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_approvals" */
export enum Policy_Approvals_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "policy_approvals" */
export type Policy_Approvals_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Policy_Approval_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "policy_approvals" */
export type Policy_Approvals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Approvals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Approvals_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Policy_Approval_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "policy_approvals" */
export enum Policy_Approvals_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Policy_Approvals_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Approvals_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Approvals_Bool_Exp;
};

/** list of users that would approve a policy, a snapshot of this instance is taken when approval is sent */
export type Policy_Approvers = {
  __typename?: 'policy_approvers';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  policy: Policies;
  policy_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "policy_approvers" */
export type Policy_Approvers_Aggregate = {
  __typename?: 'policy_approvers_aggregate';
  aggregate?: Maybe<Policy_Approvers_Aggregate_Fields>;
  nodes: Array<Policy_Approvers>;
};

export type Policy_Approvers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Approvers_Aggregate_Bool_Exp_Count>;
};

export type Policy_Approvers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Approvers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_approvers" */
export type Policy_Approvers_Aggregate_Fields = {
  __typename?: 'policy_approvers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Approvers_Max_Fields>;
  min?: Maybe<Policy_Approvers_Min_Fields>;
};

/** aggregate fields of "policy_approvers" */
export type Policy_Approvers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_approvers" */
export type Policy_Approvers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Approvers_Max_Order_By>;
  min?: InputMaybe<Policy_Approvers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_approvers" */
export type Policy_Approvers_Arr_Rel_Insert_Input = {
  data: Array<Policy_Approvers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approvers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_approvers". All fields are combined with a logical 'AND'. */
export type Policy_Approvers_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Approvers_Bool_Exp>>;
  _not?: InputMaybe<Policy_Approvers_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Approvers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_approvers" */
export enum Policy_Approvers_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyApproversPkey = 'policy_approvers_pkey',
  /** unique or primary key constraint on columns "user_id", "policy_id" */
  PolicyApproversPolicyIdUserIdKey = 'policy_approvers_policy_id_user_id_key',
}

/** input type for inserting data into table "policy_approvers" */
export type Policy_Approvers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Policy_Approvers_Max_Fields = {
  __typename?: 'policy_approvers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "policy_approvers" */
export type Policy_Approvers_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Approvers_Min_Fields = {
  __typename?: 'policy_approvers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "policy_approvers" */
export type Policy_Approvers_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_approvers" */
export type Policy_Approvers_Mutation_Response = {
  __typename?: 'policy_approvers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Approvers>;
};

/** on_conflict condition type for table "policy_approvers" */
export type Policy_Approvers_On_Conflict = {
  constraint: Policy_Approvers_Constraint;
  update_columns?: Array<Policy_Approvers_Update_Column>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_approvers". */
export type Policy_Approvers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policy_approvers */
export type Policy_Approvers_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_approvers" */
export enum Policy_Approvers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "policy_approvers" */
export type Policy_Approvers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "policy_approvers" */
export type Policy_Approvers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Approvers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Approvers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "policy_approvers" */
export enum Policy_Approvers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  UserId = 'user_id',
}

export type Policy_Approvers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Approvers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Approvers_Bool_Exp;
};

/** columns and relationships of "policy_statuses" */
export type Policy_Statuses = {
  __typename?: 'policy_statuses';
  name: Scalars['String']['output'];
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
};

/** columns and relationships of "policy_statuses" */
export type Policy_StatusesPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** columns and relationships of "policy_statuses" */
export type Policy_StatusesPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** aggregated selection of "policy_statuses" */
export type Policy_Statuses_Aggregate = {
  __typename?: 'policy_statuses_aggregate';
  aggregate?: Maybe<Policy_Statuses_Aggregate_Fields>;
  nodes: Array<Policy_Statuses>;
};

/** aggregate fields of "policy_statuses" */
export type Policy_Statuses_Aggregate_Fields = {
  __typename?: 'policy_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Statuses_Max_Fields>;
  min?: Maybe<Policy_Statuses_Min_Fields>;
};

/** aggregate fields of "policy_statuses" */
export type Policy_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "policy_statuses". All fields are combined with a logical 'AND'. */
export type Policy_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Policy_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Statuses_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  policies?: InputMaybe<Policies_Bool_Exp>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_statuses" */
export enum Policy_Statuses_Constraint {
  /** unique or primary key constraint on columns "name" */
  PolicyStatusesPkey = 'policy_statuses_pkey',
}

export enum Policy_Statuses_Enum {
  Approved = 'Approved',
  AwaitingApproval = 'Awaiting_approval',
  Draft = 'Draft',
}

/** Boolean expression to compare columns of type "policy_statuses_enum". All fields are combined with logical 'AND'. */
export type Policy_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Statuses_Enum>;
  _in?: InputMaybe<Array<Policy_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Statuses_Enum>;
  _nin?: InputMaybe<Array<Policy_Statuses_Enum>>;
};

/** input type for inserting data into table "policy_statuses" */
export type Policy_Statuses_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  policies?: InputMaybe<Policies_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Policy_Statuses_Max_Fields = {
  __typename?: 'policy_statuses_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Policy_Statuses_Min_Fields = {
  __typename?: 'policy_statuses_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "policy_statuses" */
export type Policy_Statuses_Mutation_Response = {
  __typename?: 'policy_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Statuses>;
};

/** input type for inserting object relation for remote table "policy_statuses" */
export type Policy_Statuses_Obj_Rel_Insert_Input = {
  data: Policy_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "policy_statuses" */
export type Policy_Statuses_On_Conflict = {
  constraint: Policy_Statuses_Constraint;
  update_columns?: Array<Policy_Statuses_Update_Column>;
  where?: InputMaybe<Policy_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_statuses". */
export type Policy_Statuses_Order_By = {
  name?: InputMaybe<Order_By>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Order_By>;
};

/** primary key columns input for table: policy_statuses */
export type Policy_Statuses_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "policy_statuses" */
export enum Policy_Statuses_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "policy_statuses" */
export type Policy_Statuses_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "policy_statuses" */
export type Policy_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Statuses_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "policy_statuses" */
export enum Policy_Statuses_Update_Column {
  /** column name */
  Name = 'name',
}

export type Policy_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Statuses_Bool_Exp;
};

/** Stores list of tasks linked to policies */
export type Policy_Tasks = {
  __typename?: 'policy_tasks';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  policy_id: Scalars['uuid']['output'];
  /** An object relationship */
  policy_tasks_policy: Policies;
  /** An object relationship */
  policy_tasks_task: Tasks;
  task_id: Scalars['uuid']['output'];
};

/** aggregated selection of "policy_tasks" */
export type Policy_Tasks_Aggregate = {
  __typename?: 'policy_tasks_aggregate';
  aggregate?: Maybe<Policy_Tasks_Aggregate_Fields>;
  nodes: Array<Policy_Tasks>;
};

export type Policy_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Policy_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_tasks" */
export type Policy_Tasks_Aggregate_Fields = {
  __typename?: 'policy_tasks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Tasks_Max_Fields>;
  min?: Maybe<Policy_Tasks_Min_Fields>;
};

/** aggregate fields of "policy_tasks" */
export type Policy_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_tasks" */
export type Policy_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Tasks_Max_Order_By>;
  min?: InputMaybe<Policy_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_tasks" */
export type Policy_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Policy_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_tasks". All fields are combined with a logical 'AND'. */
export type Policy_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Policy_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Tasks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_tasks_policy?: InputMaybe<Policies_Bool_Exp>;
  policy_tasks_task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_tasks" */
export enum Policy_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyTasksPkey = 'policy_tasks_pkey',
  /** unique or primary key constraint on columns "task_id", "policy_id" */
  PolicyTasksPolicyIdTaskIdKey = 'policy_tasks_policy_id_task_id_key',
}

/** input type for inserting data into table "policy_tasks" */
export type Policy_Tasks_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_tasks_policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_tasks_task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Policy_Tasks_Max_Fields = {
  __typename?: 'policy_tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "policy_tasks" */
export type Policy_Tasks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Tasks_Min_Fields = {
  __typename?: 'policy_tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "policy_tasks" */
export type Policy_Tasks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_tasks" */
export type Policy_Tasks_Mutation_Response = {
  __typename?: 'policy_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Tasks>;
};

/** on_conflict condition type for table "policy_tasks" */
export type Policy_Tasks_On_Conflict = {
  constraint: Policy_Tasks_Constraint;
  update_columns?: Array<Policy_Tasks_Update_Column>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_tasks". */
export type Policy_Tasks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_tasks_policy?: InputMaybe<Policies_Order_By>;
  policy_tasks_task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policy_tasks */
export type Policy_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_tasks" */
export enum Policy_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "policy_tasks" */
export type Policy_Tasks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "policy_tasks" */
export type Policy_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Tasks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "policy_tasks" */
export enum Policy_Tasks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  TaskId = 'task_id',
}

export type Policy_Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Tasks_Bool_Exp;
};

/** columns and relationships of "policy_types" */
export type Policy_Types = {
  __typename?: 'policy_types';
  name: Scalars['String']['output'];
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
};

/** columns and relationships of "policy_types" */
export type Policy_TypesPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** columns and relationships of "policy_types" */
export type Policy_TypesPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** aggregated selection of "policy_types" */
export type Policy_Types_Aggregate = {
  __typename?: 'policy_types_aggregate';
  aggregate?: Maybe<Policy_Types_Aggregate_Fields>;
  nodes: Array<Policy_Types>;
};

/** aggregate fields of "policy_types" */
export type Policy_Types_Aggregate_Fields = {
  __typename?: 'policy_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Types_Max_Fields>;
  min?: Maybe<Policy_Types_Min_Fields>;
};

/** aggregate fields of "policy_types" */
export type Policy_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "policy_types". All fields are combined with a logical 'AND'. */
export type Policy_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Types_Bool_Exp>>;
  _not?: InputMaybe<Policy_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Types_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  policies?: InputMaybe<Policies_Bool_Exp>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_types" */
export enum Policy_Types_Constraint {
  /** unique or primary key constraint on columns "name" */
  PolicyTypesPkey = 'policy_types_pkey',
}

export enum Policy_Types_Enum {
  Custom = 'Custom',
  Template = 'Template',
  Upload = 'Upload',
}

/** Boolean expression to compare columns of type "policy_types_enum". All fields are combined with logical 'AND'. */
export type Policy_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Types_Enum>;
  _in?: InputMaybe<Array<Policy_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Types_Enum>;
  _nin?: InputMaybe<Array<Policy_Types_Enum>>;
};

/** input type for inserting data into table "policy_types" */
export type Policy_Types_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  policies?: InputMaybe<Policies_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Policy_Types_Max_Fields = {
  __typename?: 'policy_types_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Policy_Types_Min_Fields = {
  __typename?: 'policy_types_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "policy_types" */
export type Policy_Types_Mutation_Response = {
  __typename?: 'policy_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Types>;
};

/** input type for inserting object relation for remote table "policy_types" */
export type Policy_Types_Obj_Rel_Insert_Input = {
  data: Policy_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Types_On_Conflict>;
};

/** on_conflict condition type for table "policy_types" */
export type Policy_Types_On_Conflict = {
  constraint: Policy_Types_Constraint;
  update_columns?: Array<Policy_Types_Update_Column>;
  where?: InputMaybe<Policy_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_types". */
export type Policy_Types_Order_By = {
  name?: InputMaybe<Order_By>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Order_By>;
};

/** primary key columns input for table: policy_types */
export type Policy_Types_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "policy_types" */
export enum Policy_Types_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "policy_types" */
export type Policy_Types_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "policy_types" */
export type Policy_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Types_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "policy_types" */
export enum Policy_Types_Update_Column {
  /** column name */
  Name = 'name',
}

export type Policy_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Types_Bool_Exp;
};

/** columns and relationships of "policy_version_statuses" */
export type Policy_Version_Statuses = {
  __typename?: 'policy_version_statuses';
  name: Scalars['String']['output'];
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  /** An aggregate relationship */
  policy_versions_aggregate: Policy_Versions_Aggregate;
};

/** columns and relationships of "policy_version_statuses" */
export type Policy_Version_StatusesPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** columns and relationships of "policy_version_statuses" */
export type Policy_Version_StatusesPolicy_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** aggregated selection of "policy_version_statuses" */
export type Policy_Version_Statuses_Aggregate = {
  __typename?: 'policy_version_statuses_aggregate';
  aggregate?: Maybe<Policy_Version_Statuses_Aggregate_Fields>;
  nodes: Array<Policy_Version_Statuses>;
};

/** aggregate fields of "policy_version_statuses" */
export type Policy_Version_Statuses_Aggregate_Fields = {
  __typename?: 'policy_version_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Version_Statuses_Max_Fields>;
  min?: Maybe<Policy_Version_Statuses_Min_Fields>;
};

/** aggregate fields of "policy_version_statuses" */
export type Policy_Version_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Version_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "policy_version_statuses". All fields are combined with a logical 'AND'. */
export type Policy_Version_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Version_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Policy_Version_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Version_Statuses_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  policy_versions?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_version_statuses" */
export enum Policy_Version_Statuses_Constraint {
  /** unique or primary key constraint on columns "name" */
  PolicyVersionStatusesPkey = 'policy_version_statuses_pkey',
}

export enum Policy_Version_Statuses_Enum {
  Approved = 'Approved',
  AwaitingApproval = 'Awaiting_approval',
  Draft = 'Draft',
  Retired = 'Retired',
}

/** Boolean expression to compare columns of type "policy_version_statuses_enum". All fields are combined with logical 'AND'. */
export type Policy_Version_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Version_Statuses_Enum>;
  _in?: InputMaybe<Array<Policy_Version_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Version_Statuses_Enum>;
  _nin?: InputMaybe<Array<Policy_Version_Statuses_Enum>>;
};

/** input type for inserting data into table "policy_version_statuses" */
export type Policy_Version_Statuses_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  policy_versions?: InputMaybe<Policy_Versions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Policy_Version_Statuses_Max_Fields = {
  __typename?: 'policy_version_statuses_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Policy_Version_Statuses_Min_Fields = {
  __typename?: 'policy_version_statuses_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "policy_version_statuses" */
export type Policy_Version_Statuses_Mutation_Response = {
  __typename?: 'policy_version_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Version_Statuses>;
};

/** input type for inserting object relation for remote table "policy_version_statuses" */
export type Policy_Version_Statuses_Obj_Rel_Insert_Input = {
  data: Policy_Version_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Version_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "policy_version_statuses" */
export type Policy_Version_Statuses_On_Conflict = {
  constraint: Policy_Version_Statuses_Constraint;
  update_columns?: Array<Policy_Version_Statuses_Update_Column>;
  where?: InputMaybe<Policy_Version_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_version_statuses". */
export type Policy_Version_Statuses_Order_By = {
  name?: InputMaybe<Order_By>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Order_By>;
};

/** primary key columns input for table: policy_version_statuses */
export type Policy_Version_Statuses_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "policy_version_statuses" */
export enum Policy_Version_Statuses_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "policy_version_statuses" */
export type Policy_Version_Statuses_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "policy_version_statuses" */
export type Policy_Version_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Version_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Version_Statuses_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "policy_version_statuses" */
export enum Policy_Version_Statuses_Update_Column {
  /** column name */
  Name = 'name',
}

export type Policy_Version_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Version_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Version_Statuses_Bool_Exp;
};

/** columns and relationships of "policy_versions" */
export type Policy_Versions = {
  __typename?: 'policy_versions';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  evidence_versions: Array<Evidence_Versions>;
  /** An aggregate relationship */
  evidence_versions_aggregate: Evidence_Versions_Aggregate;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  policy: Policies;
  /** An array relationship */
  policy_acknowledgements: Array<Policy_Acknowledgements>;
  /** An aggregate relationship */
  policy_acknowledgements_aggregate: Policy_Acknowledgements_Aggregate;
  /** An array relationship */
  policy_approvals: Array<Policy_Approvals>;
  /** An aggregate relationship */
  policy_approvals_aggregate: Policy_Approvals_Aggregate;
  policy_id: Scalars['uuid']['output'];
  policy_text?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  policy_version_status: Policy_Version_Statuses;
  revision_details?: Maybe<Scalars['String']['output']>;
  status: Policy_Version_Statuses_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  validity_start?: Maybe<Scalars['timestamptz']['output']>;
  version_id: Scalars['Int']['output'];
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsEvidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsEvidence_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsPolicy_AcknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsPolicy_Acknowledgements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsPolicy_ApprovalsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsPolicy_Approvals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** aggregated selection of "policy_versions" */
export type Policy_Versions_Aggregate = {
  __typename?: 'policy_versions_aggregate';
  aggregate?: Maybe<Policy_Versions_Aggregate_Fields>;
  nodes: Array<Policy_Versions>;
};

export type Policy_Versions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Versions_Aggregate_Bool_Exp_Count>;
};

export type Policy_Versions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Versions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_versions" */
export type Policy_Versions_Aggregate_Fields = {
  __typename?: 'policy_versions_aggregate_fields';
  avg?: Maybe<Policy_Versions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Versions_Max_Fields>;
  min?: Maybe<Policy_Versions_Min_Fields>;
  stddev?: Maybe<Policy_Versions_Stddev_Fields>;
  stddev_pop?: Maybe<Policy_Versions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Policy_Versions_Stddev_Samp_Fields>;
  sum?: Maybe<Policy_Versions_Sum_Fields>;
  var_pop?: Maybe<Policy_Versions_Var_Pop_Fields>;
  var_samp?: Maybe<Policy_Versions_Var_Samp_Fields>;
  variance?: Maybe<Policy_Versions_Variance_Fields>;
};

/** aggregate fields of "policy_versions" */
export type Policy_Versions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_versions" */
export type Policy_Versions_Aggregate_Order_By = {
  avg?: InputMaybe<Policy_Versions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Versions_Max_Order_By>;
  min?: InputMaybe<Policy_Versions_Min_Order_By>;
  stddev?: InputMaybe<Policy_Versions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Policy_Versions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Policy_Versions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Policy_Versions_Sum_Order_By>;
  var_pop?: InputMaybe<Policy_Versions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Policy_Versions_Var_Samp_Order_By>;
  variance?: InputMaybe<Policy_Versions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "policy_versions" */
export type Policy_Versions_Arr_Rel_Insert_Input = {
  data: Array<Policy_Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Versions_On_Conflict>;
};

/** aggregate avg on columns */
export type Policy_Versions_Avg_Fields = {
  __typename?: 'policy_versions_avg_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "policy_versions" */
export type Policy_Versions_Avg_Order_By = {
  version_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "policy_versions". All fields are combined with a logical 'AND'. */
export type Policy_Versions_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Versions_Bool_Exp>>;
  _not?: InputMaybe<Policy_Versions_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Versions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  evidence_versions?: InputMaybe<Evidence_Versions_Bool_Exp>;
  evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Bool_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  policy_acknowledgements_aggregate?: InputMaybe<Policy_Acknowledgements_Aggregate_Bool_Exp>;
  policy_approvals?: InputMaybe<Policy_Approvals_Bool_Exp>;
  policy_approvals_aggregate?: InputMaybe<Policy_Approvals_Aggregate_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_text?: InputMaybe<String_Comparison_Exp>;
  policy_version_status?: InputMaybe<Policy_Version_Statuses_Bool_Exp>;
  revision_details?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Policy_Version_Statuses_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  validity_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_versions" */
export enum Policy_Versions_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyVersionsPkey = 'policy_versions_pkey',
}

/** input type for incrementing numeric columns in table "policy_versions" */
export type Policy_Versions_Inc_Input = {
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "policy_versions" */
export type Policy_Versions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  evidence_versions?: InputMaybe<Evidence_Versions_Arr_Rel_Insert_Input>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Arr_Rel_Insert_Input>;
  policy_approvals?: InputMaybe<Policy_Approvals_Arr_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_text?: InputMaybe<Scalars['String']['input']>;
  policy_version_status?: InputMaybe<Policy_Version_Statuses_Obj_Rel_Insert_Input>;
  revision_details?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Policy_Version_Statuses_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type Policy_Versions_Max_Fields = {
  __typename?: 'policy_versions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  policy_text?: Maybe<Scalars['String']['output']>;
  revision_details?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  validity_start?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "policy_versions" */
export type Policy_Versions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_text?: InputMaybe<Order_By>;
  revision_details?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validity_start?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Versions_Min_Fields = {
  __typename?: 'policy_versions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  policy_text?: Maybe<Scalars['String']['output']>;
  revision_details?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  validity_start?: Maybe<Scalars['timestamptz']['output']>;
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "policy_versions" */
export type Policy_Versions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_text?: InputMaybe<Order_By>;
  revision_details?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validity_start?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_versions" */
export type Policy_Versions_Mutation_Response = {
  __typename?: 'policy_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Versions>;
};

/** input type for inserting object relation for remote table "policy_versions" */
export type Policy_Versions_Obj_Rel_Insert_Input = {
  data: Policy_Versions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Versions_On_Conflict>;
};

/** on_conflict condition type for table "policy_versions" */
export type Policy_Versions_On_Conflict = {
  constraint: Policy_Versions_Constraint;
  update_columns?: Array<Policy_Versions_Update_Column>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_versions". */
export type Policy_Versions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_acknowledgements_aggregate?: InputMaybe<Policy_Acknowledgements_Aggregate_Order_By>;
  policy_approvals_aggregate?: InputMaybe<Policy_Approvals_Aggregate_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_text?: InputMaybe<Order_By>;
  policy_version_status?: InputMaybe<Policy_Version_Statuses_Order_By>;
  revision_details?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  validity_start?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policy_versions */
export type Policy_Versions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_versions" */
export enum Policy_Versions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  PolicyText = 'policy_text',
  /** column name */
  RevisionDetails = 'revision_details',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidityStart = 'validity_start',
  /** column name */
  VersionId = 'version_id',
}

/** input type for updating data in table "policy_versions" */
export type Policy_Versions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_text?: InputMaybe<Scalars['String']['input']>;
  revision_details?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Policy_Version_Statuses_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type Policy_Versions_Stddev_Fields = {
  __typename?: 'policy_versions_stddev_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "policy_versions" */
export type Policy_Versions_Stddev_Order_By = {
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Policy_Versions_Stddev_Pop_Fields = {
  __typename?: 'policy_versions_stddev_pop_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "policy_versions" */
export type Policy_Versions_Stddev_Pop_Order_By = {
  version_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Policy_Versions_Stddev_Samp_Fields = {
  __typename?: 'policy_versions_stddev_samp_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "policy_versions" */
export type Policy_Versions_Stddev_Samp_Order_By = {
  version_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "policy_versions" */
export type Policy_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policy_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policy_Versions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_text?: InputMaybe<Scalars['String']['input']>;
  revision_details?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Policy_Version_Statuses_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Policy_Versions_Sum_Fields = {
  __typename?: 'policy_versions_sum_fields';
  version_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "policy_versions" */
export type Policy_Versions_Sum_Order_By = {
  version_id?: InputMaybe<Order_By>;
};

/** update columns of table "policy_versions" */
export enum Policy_Versions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  PolicyText = 'policy_text',
  /** column name */
  RevisionDetails = 'revision_details',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidityStart = 'validity_start',
  /** column name */
  VersionId = 'version_id',
}

export type Policy_Versions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Policy_Versions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Versions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Versions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Policy_Versions_Var_Pop_Fields = {
  __typename?: 'policy_versions_var_pop_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "policy_versions" */
export type Policy_Versions_Var_Pop_Order_By = {
  version_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Policy_Versions_Var_Samp_Fields = {
  __typename?: 'policy_versions_var_samp_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "policy_versions" */
export type Policy_Versions_Var_Samp_Order_By = {
  version_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Policy_Versions_Variance_Fields = {
  __typename?: 'policy_versions_variance_fields';
  version_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "policy_versions" */
export type Policy_Versions_Variance_Order_By = {
  version_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "program_controls" */
export type Program_Controls = {
  __typename?: 'program_controls';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  program: Programs;
  program_id: Scalars['uuid']['output'];
};

/** aggregated selection of "program_controls" */
export type Program_Controls_Aggregate = {
  __typename?: 'program_controls_aggregate';
  aggregate?: Maybe<Program_Controls_Aggregate_Fields>;
  nodes: Array<Program_Controls>;
};

export type Program_Controls_Aggregate_Bool_Exp = {
  count?: InputMaybe<Program_Controls_Aggregate_Bool_Exp_Count>;
};

export type Program_Controls_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Program_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Program_Controls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "program_controls" */
export type Program_Controls_Aggregate_Fields = {
  __typename?: 'program_controls_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Program_Controls_Max_Fields>;
  min?: Maybe<Program_Controls_Min_Fields>;
};

/** aggregate fields of "program_controls" */
export type Program_Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Program_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "program_controls" */
export type Program_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Program_Controls_Max_Order_By>;
  min?: InputMaybe<Program_Controls_Min_Order_By>;
};

/** input type for inserting array relation for remote table "program_controls" */
export type Program_Controls_Arr_Rel_Insert_Input = {
  data: Array<Program_Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Program_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "program_controls". All fields are combined with a logical 'AND'. */
export type Program_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Program_Controls_Bool_Exp>>;
  _not?: InputMaybe<Program_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Program_Controls_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  program?: InputMaybe<Programs_Bool_Exp>;
  program_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "program_controls" */
export enum Program_Controls_Constraint {
  /** unique or primary key constraint on columns "control_id", "program_id" */
  ProgramOrganizationControlsPkey = 'program_organization_controls_pkey',
}

/** input type for inserting data into table "program_controls" */
export type Program_Controls_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  program?: InputMaybe<Programs_Obj_Rel_Insert_Input>;
  program_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Program_Controls_Max_Fields = {
  __typename?: 'program_controls_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  program_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "program_controls" */
export type Program_Controls_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Program_Controls_Min_Fields = {
  __typename?: 'program_controls_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  program_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "program_controls" */
export type Program_Controls_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "program_controls" */
export type Program_Controls_Mutation_Response = {
  __typename?: 'program_controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Program_Controls>;
};

/** on_conflict condition type for table "program_controls" */
export type Program_Controls_On_Conflict = {
  constraint: Program_Controls_Constraint;
  update_columns?: Array<Program_Controls_Update_Column>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "program_controls". */
export type Program_Controls_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  program?: InputMaybe<Programs_Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: program_controls */
export type Program_Controls_Pk_Columns_Input = {
  control_id: Scalars['uuid']['input'];
  program_id: Scalars['uuid']['input'];
};

/** select columns of table "program_controls" */
export enum Program_Controls_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  ProgramId = 'program_id',
}

/** input type for updating data in table "program_controls" */
export type Program_Controls_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  program_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "program_controls" */
export type Program_Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Program_Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Program_Controls_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  program_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "program_controls" */
export enum Program_Controls_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  ProgramId = 'program_id',
}

export type Program_Controls_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Program_Controls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Program_Controls_Bool_Exp;
};

/** Determines which custom field is shown in a control belonging to a program */
export type Program_Field_Configs = {
  __typename?: 'program_field_configs';
  created_at: Scalars['timestamptz']['output'];
  entity_name: Scalars['String']['output'];
  /** An object relationship */
  field_config?: Maybe<Field_Configs>;
  field_config_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  program: Programs;
  program_id: Scalars['uuid']['output'];
};

/** aggregated selection of "program_field_configs" */
export type Program_Field_Configs_Aggregate = {
  __typename?: 'program_field_configs_aggregate';
  aggregate?: Maybe<Program_Field_Configs_Aggregate_Fields>;
  nodes: Array<Program_Field_Configs>;
};

export type Program_Field_Configs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Program_Field_Configs_Aggregate_Bool_Exp_Count>;
};

export type Program_Field_Configs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Program_Field_Configs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "program_field_configs" */
export type Program_Field_Configs_Aggregate_Fields = {
  __typename?: 'program_field_configs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Program_Field_Configs_Max_Fields>;
  min?: Maybe<Program_Field_Configs_Min_Fields>;
};

/** aggregate fields of "program_field_configs" */
export type Program_Field_Configs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "program_field_configs" */
export type Program_Field_Configs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Program_Field_Configs_Max_Order_By>;
  min?: InputMaybe<Program_Field_Configs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "program_field_configs" */
export type Program_Field_Configs_Arr_Rel_Insert_Input = {
  data: Array<Program_Field_Configs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Program_Field_Configs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "program_field_configs". All fields are combined with a logical 'AND'. */
export type Program_Field_Configs_Bool_Exp = {
  _and?: InputMaybe<Array<Program_Field_Configs_Bool_Exp>>;
  _not?: InputMaybe<Program_Field_Configs_Bool_Exp>;
  _or?: InputMaybe<Array<Program_Field_Configs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_name?: InputMaybe<String_Comparison_Exp>;
  field_config?: InputMaybe<Field_Configs_Bool_Exp>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  program?: InputMaybe<Programs_Bool_Exp>;
  program_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "program_field_configs" */
export enum Program_Field_Configs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramFieldConfigsPkey = 'program_field_configs_pkey',
  /** unique or primary key constraint on columns "field_config_id", "program_id" */
  ProgramFieldConfigsProgramIdFieldConfigIdKey = 'program_field_configs_program_id_field_config_id_key',
}

/** input type for inserting data into table "program_field_configs" */
export type Program_Field_Configs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_name?: InputMaybe<Scalars['String']['input']>;
  field_config?: InputMaybe<Field_Configs_Obj_Rel_Insert_Input>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  program?: InputMaybe<Programs_Obj_Rel_Insert_Input>;
  program_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Program_Field_Configs_Max_Fields = {
  __typename?: 'program_field_configs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  entity_name?: Maybe<Scalars['String']['output']>;
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  program_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "program_field_configs" */
export type Program_Field_Configs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Program_Field_Configs_Min_Fields = {
  __typename?: 'program_field_configs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  entity_name?: Maybe<Scalars['String']['output']>;
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  program_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "program_field_configs" */
export type Program_Field_Configs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "program_field_configs" */
export type Program_Field_Configs_Mutation_Response = {
  __typename?: 'program_field_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Program_Field_Configs>;
};

/** on_conflict condition type for table "program_field_configs" */
export type Program_Field_Configs_On_Conflict = {
  constraint: Program_Field_Configs_Constraint;
  update_columns?: Array<Program_Field_Configs_Update_Column>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "program_field_configs". */
export type Program_Field_Configs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  field_config?: InputMaybe<Field_Configs_Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  program?: InputMaybe<Programs_Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: program_field_configs */
export type Program_Field_Configs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "program_field_configs" */
export enum Program_Field_Configs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
}

/** input type for updating data in table "program_field_configs" */
export type Program_Field_Configs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_name?: InputMaybe<Scalars['String']['input']>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  program_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "program_field_configs" */
export type Program_Field_Configs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Program_Field_Configs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Program_Field_Configs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_name?: InputMaybe<Scalars['String']['input']>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  program_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "program_field_configs" */
export enum Program_Field_Configs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
}

export type Program_Field_Configs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Program_Field_Configs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Program_Field_Configs_Bool_Exp;
};

/** columns and relationships of "programs" */
export type Programs = {
  __typename?: 'programs';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  framework?: Maybe<Frameworks>;
  framework_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  program_controls: Array<Program_Controls>;
  /** An aggregate relationship */
  program_controls_aggregate: Program_Controls_Aggregate;
  /** An array relationship */
  program_field_configs: Array<Program_Field_Configs>;
  /** An aggregate relationship */
  program_field_configs_aggregate: Program_Field_Configs_Aggregate;
  /** An object relationship */
  programs_vector_store?: Maybe<Ai_Programs_Vector_Store>;
  similarity?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  template_framework?: Maybe<Template_Frameworks>;
  template_framework_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** columns and relationships of "programs" */
export type ProgramsProgram_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** columns and relationships of "programs" */
export type ProgramsProgram_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** columns and relationships of "programs" */
export type ProgramsProgram_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

/** columns and relationships of "programs" */
export type ProgramsProgram_Field_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

/** aggregated selection of "programs" */
export type Programs_Aggregate = {
  __typename?: 'programs_aggregate';
  aggregate?: Maybe<Programs_Aggregate_Fields>;
  nodes: Array<Programs>;
};

export type Programs_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Programs_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Programs_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Programs_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Programs_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Programs_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Programs_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Programs_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Programs_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Programs_Aggregate_Bool_Exp_Var_Samp>;
};

export type Programs_Aggregate_Bool_Exp_Avg = {
  arguments: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Programs_Aggregate_Bool_Exp_Corr = {
  arguments: Programs_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Programs_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Programs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Programs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Programs_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Programs_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Programs_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Programs_Aggregate_Bool_Exp_Max = {
  arguments: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Programs_Aggregate_Bool_Exp_Min = {
  arguments: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Programs_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Programs_Aggregate_Bool_Exp_Sum = {
  arguments: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Programs_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Programs_Select_Column_Programs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Programs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "programs" */
export type Programs_Aggregate_Fields = {
  __typename?: 'programs_aggregate_fields';
  avg?: Maybe<Programs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Programs_Max_Fields>;
  min?: Maybe<Programs_Min_Fields>;
  stddev?: Maybe<Programs_Stddev_Fields>;
  stddev_pop?: Maybe<Programs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Programs_Stddev_Samp_Fields>;
  sum?: Maybe<Programs_Sum_Fields>;
  var_pop?: Maybe<Programs_Var_Pop_Fields>;
  var_samp?: Maybe<Programs_Var_Samp_Fields>;
  variance?: Maybe<Programs_Variance_Fields>;
};

/** aggregate fields of "programs" */
export type Programs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Programs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "programs" */
export type Programs_Aggregate_Order_By = {
  avg?: InputMaybe<Programs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Programs_Max_Order_By>;
  min?: InputMaybe<Programs_Min_Order_By>;
  stddev?: InputMaybe<Programs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Programs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Programs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Programs_Sum_Order_By>;
  var_pop?: InputMaybe<Programs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Programs_Var_Samp_Order_By>;
  variance?: InputMaybe<Programs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "programs" */
export type Programs_Arr_Rel_Insert_Input = {
  data: Array<Programs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};

/** aggregate avg on columns */
export type Programs_Avg_Fields = {
  __typename?: 'programs_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "programs" */
export type Programs_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "programs". All fields are combined with a logical 'AND'. */
export type Programs_Bool_Exp = {
  _and?: InputMaybe<Array<Programs_Bool_Exp>>;
  _not?: InputMaybe<Programs_Bool_Exp>;
  _or?: InputMaybe<Array<Programs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  framework?: InputMaybe<Frameworks_Bool_Exp>;
  framework_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  program_controls?: InputMaybe<Program_Controls_Bool_Exp>;
  program_controls_aggregate?: InputMaybe<Program_Controls_Aggregate_Bool_Exp>;
  program_field_configs?: InputMaybe<Program_Field_Configs_Bool_Exp>;
  program_field_configs_aggregate?: InputMaybe<Program_Field_Configs_Aggregate_Bool_Exp>;
  programs_vector_store?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  template_framework?: InputMaybe<Template_Frameworks_Bool_Exp>;
  template_framework_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "programs" */
export enum Programs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramsIdKey = 'programs_id_key',
  /** unique or primary key constraint on columns "id" */
  ProgramsPkey = 'programs_pkey',
}

/** input type for incrementing numeric columns in table "programs" */
export type Programs_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "programs" */
export type Programs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  framework?: InputMaybe<Frameworks_Obj_Rel_Insert_Input>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  program_controls?: InputMaybe<Program_Controls_Arr_Rel_Insert_Input>;
  program_field_configs?: InputMaybe<Program_Field_Configs_Arr_Rel_Insert_Input>;
  programs_vector_store?: InputMaybe<Ai_Programs_Vector_Store_Obj_Rel_Insert_Input>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  template_framework?: InputMaybe<Template_Frameworks_Obj_Rel_Insert_Input>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Programs_Max_Fields = {
  __typename?: 'programs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  framework_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  template_framework_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "programs" */
export type Programs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Programs_Min_Fields = {
  __typename?: 'programs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  framework_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  template_framework_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "programs" */
export type Programs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "programs" */
export type Programs_Mutation_Response = {
  __typename?: 'programs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Programs>;
};

/** input type for inserting object relation for remote table "programs" */
export type Programs_Obj_Rel_Insert_Input = {
  data: Programs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};

/** on_conflict condition type for table "programs" */
export type Programs_On_Conflict = {
  constraint: Programs_Constraint;
  update_columns?: Array<Programs_Update_Column>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** Ordering options when selecting data from "programs". */
export type Programs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  framework?: InputMaybe<Frameworks_Order_By>;
  framework_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  program_controls_aggregate?: InputMaybe<Program_Controls_Aggregate_Order_By>;
  program_field_configs_aggregate?: InputMaybe<Program_Field_Configs_Aggregate_Order_By>;
  programs_vector_store?: InputMaybe<Ai_Programs_Vector_Store_Order_By>;
  similarity?: InputMaybe<Order_By>;
  template_framework?: InputMaybe<Template_Frameworks_Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: programs */
export type Programs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "programs" */
export enum Programs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FrameworkId = 'framework_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TemplateFrameworkId = 'template_framework_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "programs_aggregate_bool_exp_avg_arguments_columns" columns of table "programs" */
export enum Programs_Select_Column_Programs_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "programs_aggregate_bool_exp_corr_arguments_columns" columns of table "programs" */
export enum Programs_Select_Column_Programs_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "programs_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "programs" */
export enum Programs_Select_Column_Programs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "programs_aggregate_bool_exp_max_arguments_columns" columns of table "programs" */
export enum Programs_Select_Column_Programs_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "programs_aggregate_bool_exp_min_arguments_columns" columns of table "programs" */
export enum Programs_Select_Column_Programs_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "programs_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "programs" */
export enum Programs_Select_Column_Programs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "programs_aggregate_bool_exp_sum_arguments_columns" columns of table "programs" */
export enum Programs_Select_Column_Programs_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "programs_aggregate_bool_exp_var_samp_arguments_columns" columns of table "programs" */
export enum Programs_Select_Column_Programs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "programs" */
export type Programs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Programs_Stddev_Fields = {
  __typename?: 'programs_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "programs" */
export type Programs_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Programs_Stddev_Pop_Fields = {
  __typename?: 'programs_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "programs" */
export type Programs_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Programs_Stddev_Samp_Fields = {
  __typename?: 'programs_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "programs" */
export type Programs_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "programs" */
export type Programs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Programs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Programs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Programs_Sum_Fields = {
  __typename?: 'programs_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "programs" */
export type Programs_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "programs" */
export enum Programs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FrameworkId = 'framework_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TemplateFrameworkId = 'template_framework_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Programs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Programs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Programs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Programs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Programs_Var_Pop_Fields = {
  __typename?: 'programs_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "programs" */
export type Programs_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Programs_Var_Samp_Fields = {
  __typename?: 'programs_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "programs" */
export type Programs_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Programs_Variance_Fields = {
  __typename?: 'programs_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "programs" */
export type Programs_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** execute function "ai.control_evidences_suggestions" which returns "evidences" */
  ai_control_evidences_suggestions: Array<Evidences>;
  /** execute function "ai.control_evidences_suggestions" and query aggregates on result of table type "evidences" */
  ai_control_evidences_suggestions_aggregate: Evidences_Aggregate;
  /** execute function "ai.controls_text_search" which returns "controls" */
  ai_controls_text_search: Array<Controls>;
  /** execute function "ai.controls_text_search" and query aggregates on result of table type "controls" */
  ai_controls_text_search_aggregate: Controls_Aggregate;
  /** fetch data from the table: "ai.controls_vector_store" */
  ai_controls_vector_store: Array<Ai_Controls_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.controls_vector_store" */
  ai_controls_vector_store_aggregate: Ai_Controls_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.controls_vector_store" using primary key columns */
  ai_controls_vector_store_by_pk?: Maybe<Ai_Controls_Vector_Store>;
  /** execute function "ai.controls_vector_store_search" which returns "controls" */
  ai_controls_vector_store_search: Array<Controls>;
  /** execute function "ai.controls_vector_store_search" and query aggregates on result of table type "controls" */
  ai_controls_vector_store_search_aggregate: Controls_Aggregate;
  /** ai_evidence_suggestions_for_control */
  ai_evidence_suggestions_for_control?: Maybe<AiControlEvidenceSuggestionsOutput>;
  /** execute function "ai.evidences_text_search" which returns "evidences" */
  ai_evidences_text_search: Array<Evidences>;
  /** execute function "ai.evidences_text_search" and query aggregates on result of table type "evidences" */
  ai_evidences_text_search_aggregate: Evidences_Aggregate;
  /** fetch data from the table: "ai.evidences_vector_store" */
  ai_evidences_vector_store: Array<Ai_Evidences_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.evidences_vector_store" */
  ai_evidences_vector_store_aggregate: Ai_Evidences_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.evidences_vector_store" using primary key columns */
  ai_evidences_vector_store_by_pk?: Maybe<Ai_Evidences_Vector_Store>;
  /** execute function "ai.evidences_vector_store_search" which returns "evidences" */
  ai_evidences_vector_store_search: Array<Evidences>;
  /** execute function "ai.evidences_vector_store_search" and query aggregates on result of table type "evidences" */
  ai_evidences_vector_store_search_aggregate: Evidences_Aggregate;
  /** fetch data from the table: "ai.files_document_store" */
  ai_files_document_store: Array<Ai_Files_Document_Store>;
  /** fetch aggregated fields from the table: "ai.files_document_store" */
  ai_files_document_store_aggregate: Ai_Files_Document_Store_Aggregate;
  /** fetch data from the table: "ai.files_document_store" using primary key columns */
  ai_files_document_store_by_pk?: Maybe<Ai_Files_Document_Store>;
  /** fetch data from the table: "ai.files_vector_store" */
  ai_files_vector_store: Array<Ai_Files_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.files_vector_store" */
  ai_files_vector_store_aggregate: Ai_Files_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.files_vector_store" using primary key columns */
  ai_files_vector_store_by_pk?: Maybe<Ai_Files_Vector_Store>;
  /** execute function "ai.files_vector_store_search" which returns "ai.files_vector_store" */
  ai_files_vector_store_search: Array<Ai_Files_Vector_Store>;
  /** execute function "ai.files_vector_store_search" and query aggregates on result of table type "ai.files_vector_store" */
  ai_files_vector_store_search_aggregate: Ai_Files_Vector_Store_Aggregate;
  /** execute function "ai.policies_text_search" which returns "policies" */
  ai_policies_text_search: Array<Policies>;
  /** execute function "ai.policies_text_search" and query aggregates on result of table type "policies" */
  ai_policies_text_search_aggregate: Policies_Aggregate;
  /** fetch data from the table: "ai.policies_vector_store" */
  ai_policies_vector_store: Array<Ai_Policies_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.policies_vector_store" */
  ai_policies_vector_store_aggregate: Ai_Policies_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.policies_vector_store" using primary key columns */
  ai_policies_vector_store_by_pk?: Maybe<Ai_Policies_Vector_Store>;
  /** execute function "ai.policies_vector_store_search" which returns "policies" */
  ai_policies_vector_store_search: Array<Policies>;
  /** execute function "ai.policies_vector_store_search" and query aggregates on result of table type "policies" */
  ai_policies_vector_store_search_aggregate: Policies_Aggregate;
  /** execute function "ai.programs_text_search" which returns "programs" */
  ai_programs_text_search: Array<Programs>;
  /** execute function "ai.programs_text_search" and query aggregates on result of table type "programs" */
  ai_programs_text_search_aggregate: Programs_Aggregate;
  /** fetch data from the table: "ai.programs_vector_store" */
  ai_programs_vector_store: Array<Ai_Programs_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.programs_vector_store" */
  ai_programs_vector_store_aggregate: Ai_Programs_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.programs_vector_store" using primary key columns */
  ai_programs_vector_store_by_pk?: Maybe<Ai_Programs_Vector_Store>;
  /** execute function "ai.programs_vector_store_search" which returns "programs" */
  ai_programs_vector_store_search: Array<Programs>;
  /** execute function "ai.programs_vector_store_search" and query aggregates on result of table type "programs" */
  ai_programs_vector_store_search_aggregate: Programs_Aggregate;
  /** execute function "ai.risks_text_search" which returns "risks" */
  ai_risks_text_search: Array<Risks>;
  /** execute function "ai.risks_text_search" and query aggregates on result of table type "risks" */
  ai_risks_text_search_aggregate: Risks_Aggregate;
  /** fetch data from the table: "ai.risks_vector_store" */
  ai_risks_vector_store: Array<Ai_Risks_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.risks_vector_store" */
  ai_risks_vector_store_aggregate: Ai_Risks_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.risks_vector_store" using primary key columns */
  ai_risks_vector_store_by_pk?: Maybe<Ai_Risks_Vector_Store>;
  /** execute function "ai.risks_vector_store_search" which returns "risks" */
  ai_risks_vector_store_search: Array<Risks>;
  /** execute function "ai.risks_vector_store_search" and query aggregates on result of table type "risks" */
  ai_risks_vector_store_search_aggregate: Risks_Aggregate;
  /** execute function "ai.tasks_text_search" which returns "tasks" */
  ai_tasks_text_search: Array<Tasks>;
  /** execute function "ai.tasks_text_search" and query aggregates on result of table type "tasks" */
  ai_tasks_text_search_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "ai.tasks_vector_store" */
  ai_tasks_vector_store: Array<Ai_Tasks_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.tasks_vector_store" */
  ai_tasks_vector_store_aggregate: Ai_Tasks_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.tasks_vector_store" using primary key columns */
  ai_tasks_vector_store_by_pk?: Maybe<Ai_Tasks_Vector_Store>;
  /** execute function "ai.tasks_vector_store_search" which returns "tasks" */
  ai_tasks_vector_store_search: Array<Tasks>;
  /** execute function "ai.tasks_vector_store_search" and query aggregates on result of table type "tasks" */
  ai_tasks_vector_store_search_aggregate: Tasks_Aggregate;
  /** execute function "ai.vendors_text_search" which returns "vendors" */
  ai_vendors_text_search: Array<Vendors>;
  /** execute function "ai.vendors_text_search" and query aggregates on result of table type "vendors" */
  ai_vendors_text_search_aggregate: Vendors_Aggregate;
  /** fetch data from the table: "ai.vendors_vector_store" */
  ai_vendors_vector_store: Array<Ai_Vendors_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.vendors_vector_store" */
  ai_vendors_vector_store_aggregate: Ai_Vendors_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.vendors_vector_store" using primary key columns */
  ai_vendors_vector_store_by_pk?: Maybe<Ai_Vendors_Vector_Store>;
  /** execute function "ai.vendors_vector_store_search" which returns "vendors" */
  ai_vendors_vector_store_search: Array<Vendors>;
  /** execute function "ai.vendors_vector_store_search" and query aggregates on result of table type "vendors" */
  ai_vendors_vector_store_search_aggregate: Vendors_Aggregate;
  /** fetch data from the table: "audit.control_history_view" */
  audit_control_history_view: Array<Audit_Control_History_View>;
  /** fetch aggregated fields from the table: "audit.control_history_view" */
  audit_control_history_view_aggregate: Audit_Control_History_View_Aggregate;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table: "audit.policy_history_view" */
  audit_policy_history_view: Array<Audit_Policy_History_View>;
  /** fetch aggregated fields from the table: "audit.policy_history_view" */
  audit_policy_history_view_aggregate: Audit_Policy_History_View_Aggregate;
  /** fetch data from the table: "audit.risk_history_view" */
  audit_risk_history_view: Array<Audit_Risk_History_View>;
  /** fetch aggregated fields from the table: "audit.risk_history_view" */
  audit_risk_history_view_aggregate: Audit_Risk_History_View_Aggregate;
  /** fetch data from the table: "audit.vendor_history_view" */
  audit_vendor_history_view: Array<Audit_Vendor_History_View>;
  /** fetch aggregated fields from the table: "audit.vendor_history_view" */
  audit_vendor_history_view_aggregate: Audit_Vendor_History_View_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequests_Aggregate;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProviders_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypes_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokens_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRoles_Aggregate;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProviders_Aggregate;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRoles_Aggregate;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeys_Aggregate;
  /** fetch data from the table: "auth.jackson_index" */
  auth_jackson_index: Array<Auth_Jackson_Index>;
  /** fetch aggregated fields from the table: "auth.jackson_index" */
  auth_jackson_index_aggregate: Auth_Jackson_Index_Aggregate;
  /** fetch data from the table: "auth.jackson_index" using primary key columns */
  auth_jackson_index_by_pk?: Maybe<Auth_Jackson_Index>;
  /** fetch data from the table: "auth.jackson_store" */
  auth_jackson_store: Array<Auth_Jackson_Store>;
  /** fetch aggregated fields from the table: "auth.jackson_store" */
  auth_jackson_store_aggregate: Auth_Jackson_Store_Aggregate;
  /** fetch data from the table: "auth.jackson_store" using primary key columns */
  auth_jackson_store_by_pk?: Maybe<Auth_Jackson_Store>;
  /** fetch data from the table: "auth.jackson_ttl" */
  auth_jackson_ttl: Array<Auth_Jackson_Ttl>;
  /** fetch aggregated fields from the table: "auth.jackson_ttl" */
  auth_jackson_ttl_aggregate: Auth_Jackson_Ttl_Aggregate;
  /** fetch data from the table: "auth.jackson_ttl" using primary key columns */
  auth_jackson_ttl_by_pk?: Maybe<Auth_Jackson_Ttl>;
  /** fetch data from the table: "auth.migrations" */
  auth_migrations: Array<Auth_Migrations>;
  /** fetch aggregated fields from the table: "auth.migrations" */
  auth_migrations_aggregate: Auth_Migrations_Aggregate;
  /** fetch data from the table: "auth.migrations" using primary key columns */
  auth_migrations_by_pk?: Maybe<Auth_Migrations>;
  /** fetch data from the table: "storage.buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage.buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage.buckets" */
  bucketsAggregate: Buckets_Aggregate;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** An array relationship */
  client_questionnaire_answer_ai_sources: Array<Client_Questionnaire_Answer_Ai_Sources>;
  /** An aggregate relationship */
  client_questionnaire_answer_ai_sources_aggregate: Client_Questionnaire_Answer_Ai_Sources_Aggregate;
  /** fetch data from the table: "client_questionnaire_answer_ai_sources" using primary key columns */
  client_questionnaire_answer_ai_sources_by_pk?: Maybe<Client_Questionnaire_Answer_Ai_Sources>;
  /** An array relationship */
  client_questionnaire_answers: Array<Client_Questionnaire_Answers>;
  /** An aggregate relationship */
  client_questionnaire_answers_aggregate: Client_Questionnaire_Answers_Aggregate;
  /** fetch data from the table: "client_questionnaire_answers" using primary key columns */
  client_questionnaire_answers_by_pk?: Maybe<Client_Questionnaire_Answers>;
  /** fetch data from the table: "client_questionnaire_question_status" */
  client_questionnaire_question_status: Array<Client_Questionnaire_Question_Status>;
  /** fetch aggregated fields from the table: "client_questionnaire_question_status" */
  client_questionnaire_question_status_aggregate: Client_Questionnaire_Question_Status_Aggregate;
  /** fetch data from the table: "client_questionnaire_question_status" using primary key columns */
  client_questionnaire_question_status_by_pk?: Maybe<Client_Questionnaire_Question_Status>;
  /** An array relationship */
  client_questionnaire_questions: Array<Client_Questionnaire_Questions>;
  /** An aggregate relationship */
  client_questionnaire_questions_aggregate: Client_Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "client_questionnaire_questions" using primary key columns */
  client_questionnaire_questions_by_pk?: Maybe<Client_Questionnaire_Questions>;
  /** fetch data from the table: "client_questionnaire_status" */
  client_questionnaire_status: Array<Client_Questionnaire_Status>;
  /** fetch aggregated fields from the table: "client_questionnaire_status" */
  client_questionnaire_status_aggregate: Client_Questionnaire_Status_Aggregate;
  /** fetch data from the table: "client_questionnaire_status" using primary key columns */
  client_questionnaire_status_by_pk?: Maybe<Client_Questionnaire_Status>;
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** An aggregate relationship */
  client_questionnaires_aggregate: Client_Questionnaires_Aggregate;
  /** fetch data from the table: "client_questionnaires" using primary key columns */
  client_questionnaires_by_pk?: Maybe<Client_Questionnaires>;
  /** fetch data from the table: "comment_uploads" */
  comment_uploads: Array<Comment_Uploads>;
  /** fetch aggregated fields from the table: "comment_uploads" */
  comment_uploads_aggregate: Comment_Uploads_Aggregate;
  /** fetch data from the table: "comment_uploads" using primary key columns */
  comment_uploads_by_pk?: Maybe<Comment_Uploads>;
  /** An array relationship */
  comments: Array<Comments>;
  /** An aggregate relationship */
  comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  comments_by_pk?: Maybe<Comments>;
  /** An array relationship */
  control_ai_review_assessment_sources: Array<Control_Ai_Review_Assessment_Sources>;
  /** An aggregate relationship */
  control_ai_review_assessment_sources_aggregate: Control_Ai_Review_Assessment_Sources_Aggregate;
  /** fetch data from the table: "control_ai_review_assessment_sources" using primary key columns */
  control_ai_review_assessment_sources_by_pk?: Maybe<Control_Ai_Review_Assessment_Sources>;
  /** An array relationship */
  control_ai_review_assessments: Array<Control_Ai_Review_Assessments>;
  /** An aggregate relationship */
  control_ai_review_assessments_aggregate: Control_Ai_Review_Assessments_Aggregate;
  /** fetch data from the table: "control_ai_review_assessments" using primary key columns */
  control_ai_review_assessments_by_pk?: Maybe<Control_Ai_Review_Assessments>;
  /** An array relationship */
  control_ai_review_criteria: Array<Control_Ai_Review_Criteria>;
  /** An aggregate relationship */
  control_ai_review_criteria_aggregate: Control_Ai_Review_Criteria_Aggregate;
  /** fetch data from the table: "control_ai_review_criteria" using primary key columns */
  control_ai_review_criteria_by_pk?: Maybe<Control_Ai_Review_Criteria>;
  /** An array relationship */
  control_ai_review_runs: Array<Control_Ai_Review_Runs>;
  /** An aggregate relationship */
  control_ai_review_runs_aggregate: Control_Ai_Review_Runs_Aggregate;
  /** fetch data from the table: "control_ai_review_runs" using primary key columns */
  control_ai_review_runs_by_pk?: Maybe<Control_Ai_Review_Runs>;
  /** fetch data from the table: "control_ai_review_status" */
  control_ai_review_status: Array<Control_Ai_Review_Status>;
  /** fetch aggregated fields from the table: "control_ai_review_status" */
  control_ai_review_status_aggregate: Control_Ai_Review_Status_Aggregate;
  /** fetch data from the table: "control_ai_review_status" using primary key columns */
  control_ai_review_status_by_pk?: Maybe<Control_Ai_Review_Status>;
  /** An array relationship */
  control_categories: Array<Control_Categories>;
  /** An aggregate relationship */
  control_categories_aggregate: Control_Categories_Aggregate;
  /** fetch data from the table: "control_categories" using primary key columns */
  control_categories_by_pk?: Maybe<Control_Categories>;
  /** An array relationship */
  control_criteria: Array<Control_Criteria>;
  /** An aggregate relationship */
  control_criteria_aggregate: Control_Criteria_Aggregate;
  /** fetch data from the table: "control_criteria" using primary key columns */
  control_criteria_by_pk?: Maybe<Control_Criteria>;
  /** An array relationship */
  control_evidences: Array<Control_Evidences>;
  /** An aggregate relationship */
  control_evidences_aggregate: Control_Evidences_Aggregate;
  /** fetch data from the table: "control_evidences" using primary key columns */
  control_evidences_by_pk?: Maybe<Control_Evidences>;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  /** An aggregate relationship */
  control_findings_aggregate: Control_Findings_Aggregate;
  /** fetch data from the table: "control_findings" using primary key columns */
  control_findings_by_pk?: Maybe<Control_Findings>;
  /** fetch data from the table: "control_frequencies" */
  control_frequencies: Array<Control_Frequencies>;
  /** fetch aggregated fields from the table: "control_frequencies" */
  control_frequencies_aggregate: Control_Frequencies_Aggregate;
  /** fetch data from the table: "control_frequencies" using primary key columns */
  control_frequencies_by_pk?: Maybe<Control_Frequencies>;
  /** An array relationship */
  control_groups: Array<Control_Groups>;
  /** An aggregate relationship */
  control_groups_aggregate: Control_Groups_Aggregate;
  /** fetch data from the table: "control_groups" using primary key columns */
  control_groups_by_pk?: Maybe<Control_Groups>;
  /** fetch data from the table: "control_priorities" */
  control_priorities: Array<Control_Priorities>;
  /** fetch aggregated fields from the table: "control_priorities" */
  control_priorities_aggregate: Control_Priorities_Aggregate;
  /** fetch data from the table: "control_priorities" using primary key columns */
  control_priorities_by_pk?: Maybe<Control_Priorities>;
  /** An array relationship */
  control_tags: Array<Control_Tags>;
  /** An aggregate relationship */
  control_tags_aggregate: Control_Tags_Aggregate;
  /** fetch data from the table: "control_tags" using primary key columns */
  control_tags_by_pk?: Maybe<Control_Tags>;
  /** An array relationship */
  control_tasks: Array<Control_Tasks>;
  /** An aggregate relationship */
  control_tasks_aggregate: Control_Tasks_Aggregate;
  /** fetch data from the table: "control_tasks" using primary key columns */
  control_tasks_by_pk?: Maybe<Control_Tasks>;
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  /** fetch data from the table: "controls" using primary key columns */
  controls_by_pk?: Maybe<Controls>;
  /** An array relationship */
  controls_history_stats: Array<Controls_History_Stats>;
  /** An aggregate relationship */
  controls_history_stats_aggregate: Controls_History_Stats_Aggregate;
  /** fetch data from the table: "controls_history_stats" using primary key columns */
  controls_history_stats_by_pk?: Maybe<Controls_History_Stats>;
  /** fetch data from the table: "controls_stats_view" */
  controls_stats_view: Array<Controls_Stats_View>;
  /** fetch aggregated fields from the table: "controls_stats_view" */
  controls_stats_view_aggregate: Controls_Stats_View_Aggregate;
  /** An array relationship */
  criteria: Array<Criteria>;
  /** An aggregate relationship */
  criteria_aggregate: Criteria_Aggregate;
  /** fetch data from the table: "criteria" using primary key columns */
  criteria_by_pk?: Maybe<Criteria>;
  /** fetch data from the table: "daily_control_stats_view" */
  daily_control_stats_view: Array<Daily_Control_Stats_View>;
  /** fetch aggregated fields from the table: "daily_control_stats_view" */
  daily_control_stats_view_aggregate: Daily_Control_Stats_View_Aggregate;
  /** fetch data from the table: "daily_control_user_stats_view" */
  daily_control_user_stats_view: Array<Daily_Control_User_Stats_View>;
  /** fetch aggregated fields from the table: "daily_control_user_stats_view" */
  daily_control_user_stats_view_aggregate: Daily_Control_User_Stats_View_Aggregate;
  /** An array relationship */
  date_field_values: Array<Date_Field_Values>;
  /** An aggregate relationship */
  date_field_values_aggregate: Date_Field_Values_Aggregate;
  /** fetch data from the table: "date_field_values" using primary key columns */
  date_field_values_by_pk?: Maybe<Date_Field_Values>;
  /** An array relationship */
  email_field_values: Array<Email_Field_Values>;
  /** An aggregate relationship */
  email_field_values_aggregate: Email_Field_Values_Aggregate;
  /** fetch data from the table: "email_field_values" using primary key columns */
  email_field_values_by_pk?: Maybe<Email_Field_Values>;
  /** fetch data from the table: "evidence_acl" */
  evidence_acl: Array<Evidence_Acl>;
  /** fetch aggregated fields from the table: "evidence_acl" */
  evidence_acl_aggregate: Evidence_Acl_Aggregate;
  /** fetch data from the table: "evidence_acl" using primary key columns */
  evidence_acl_by_pk?: Maybe<Evidence_Acl>;
  /** An array relationship */
  evidence_integrations: Array<Evidence_Integrations>;
  /** An aggregate relationship */
  evidence_integrations_aggregate: Evidence_Integrations_Aggregate;
  /** fetch data from the table: "evidence_integrations" using primary key columns */
  evidence_integrations_by_pk?: Maybe<Evidence_Integrations>;
  /** An array relationship */
  evidence_policies: Array<Evidence_Policies>;
  /** An aggregate relationship */
  evidence_policies_aggregate: Evidence_Policies_Aggregate;
  /** fetch data from the table: "evidence_policies" using primary key columns */
  evidence_policies_by_pk?: Maybe<Evidence_Policies>;
  /** An array relationship */
  evidence_tags: Array<Evidence_Tags>;
  /** An aggregate relationship */
  evidence_tags_aggregate: Evidence_Tags_Aggregate;
  /** fetch data from the table: "evidence_tags" using primary key columns */
  evidence_tags_by_pk?: Maybe<Evidence_Tags>;
  /** fetch data from the table: "evidence_version_file" */
  evidence_version_file: Array<Evidence_Version_File>;
  /** fetch aggregated fields from the table: "evidence_version_file" */
  evidence_version_file_aggregate: Evidence_Version_File_Aggregate;
  /** fetch data from the table: "evidence_version_file" using primary key columns */
  evidence_version_file_by_pk?: Maybe<Evidence_Version_File>;
  /** An array relationship */
  evidence_versions: Array<Evidence_Versions>;
  /** An aggregate relationship */
  evidence_versions_aggregate: Evidence_Versions_Aggregate;
  /** fetch data from the table: "evidence_versions" using primary key columns */
  evidence_versions_by_pk?: Maybe<Evidence_Versions>;
  /** An array relationship */
  evidences: Array<Evidences>;
  /** An aggregate relationship */
  evidences_aggregate: Evidences_Aggregate;
  /** fetch data from the table: "evidences" using primary key columns */
  evidences_by_pk?: Maybe<Evidences>;
  /** An array relationship */
  field_configs: Array<Field_Configs>;
  /** An aggregate relationship */
  field_configs_aggregate: Field_Configs_Aggregate;
  /** fetch data from the table: "field_configs" using primary key columns */
  field_configs_by_pk?: Maybe<Field_Configs>;
  /** fetch data from the table: "field_entities" */
  field_entities: Array<Field_Entities>;
  /** fetch aggregated fields from the table: "field_entities" */
  field_entities_aggregate: Field_Entities_Aggregate;
  /** fetch data from the table: "field_entities" using primary key columns */
  field_entities_by_pk?: Maybe<Field_Entities>;
  /** fetch data from the table: "field_types" */
  field_types: Array<Field_Types>;
  /** fetch aggregated fields from the table: "field_types" */
  field_types_aggregate: Field_Types_Aggregate;
  /** fetch data from the table: "field_types" using primary key columns */
  field_types_by_pk?: Maybe<Field_Types>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  /** fetch data from the table: "field_values" using primary key columns */
  field_values_by_pk?: Maybe<Field_Values>;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage.files" */
  filesAggregate: Files_Aggregate;
  /** fetch data from the table: "finding_causes" */
  finding_causes: Array<Finding_Causes>;
  /** fetch aggregated fields from the table: "finding_causes" */
  finding_causes_aggregate: Finding_Causes_Aggregate;
  /** fetch data from the table: "finding_causes" using primary key columns */
  finding_causes_by_pk?: Maybe<Finding_Causes>;
  /** fetch data from the table: "finding_types" */
  finding_types: Array<Finding_Types>;
  /** fetch aggregated fields from the table: "finding_types" */
  finding_types_aggregate: Finding_Types_Aggregate;
  /** fetch data from the table: "finding_types" using primary key columns */
  finding_types_by_pk?: Maybe<Finding_Types>;
  /** fetch data from the table: "forms" */
  forms: Array<Forms>;
  /** fetch aggregated fields from the table: "forms" */
  forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>;
  /** An array relationship */
  frameworks: Array<Frameworks>;
  /** An aggregate relationship */
  frameworks_aggregate: Frameworks_Aggregate;
  /** fetch data from the table: "frameworks" using primary key columns */
  frameworks_by_pk?: Maybe<Frameworks>;
  /** An array relationship */
  frameworks_controls: Array<Frameworks_Controls>;
  /** An aggregate relationship */
  frameworks_controls_aggregate: Frameworks_Controls_Aggregate;
  /** fetch data from the table: "frameworks_controls" using primary key columns */
  frameworks_controls_by_pk?: Maybe<Frameworks_Controls>;
  /** fetch data from the table: "frequencies" */
  frequencies: Array<Frequencies>;
  /** fetch aggregated fields from the table: "frequencies" */
  frequencies_aggregate: Frequencies_Aggregate;
  /** fetch data from the table: "frequencies" using primary key columns */
  frequencies_by_pk?: Maybe<Frequencies>;
  /** Execute a global search */
  global_search?: Maybe<SearchOutput>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** This action is used to import policies from csv */
  import_policies?: Maybe<Import_Policies>;
  /** This action is used to import programs from csv */
  import_program?: Maybe<Import_Program>;
  /** This action is used to import risks from csv */
  import_risks?: Maybe<Import_Risks>;
  /** This action is used to import vendors from csv */
  import_vendors?: Maybe<Import_Vendors>;
  /** fetch data from the table: "integration_names" */
  integration_names: Array<Integration_Names>;
  /** fetch aggregated fields from the table: "integration_names" */
  integration_names_aggregate: Integration_Names_Aggregate;
  /** fetch data from the table: "integration_names" using primary key columns */
  integration_names_by_pk?: Maybe<Integration_Names>;
  /** fetch data from the table: "integration_runs" */
  integration_runs: Array<Integration_Runs>;
  /** fetch aggregated fields from the table: "integration_runs" */
  integration_runs_aggregate: Integration_Runs_Aggregate;
  /** fetch data from the table: "integration_runs" using primary key columns */
  integration_runs_by_pk?: Maybe<Integration_Runs>;
  /** fetch data from the table: "integration_usage_stats_view" */
  integration_usage_stats_view: Array<Integration_Usage_Stats_View>;
  /** fetch aggregated fields from the table: "integration_usage_stats_view" */
  integration_usage_stats_view_aggregate: Integration_Usage_Stats_View_Aggregate;
  /** fetch data from the table: "integrations" */
  integrations: Array<Integrations>;
  /** fetch aggregated fields from the table: "integrations" */
  integrations_aggregate: Integrations_Aggregate;
  /** fetch data from the table: "integrations" using primary key columns */
  integrations_by_pk?: Maybe<Integrations>;
  /** An array relationship */
  invitations: Array<Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Invitations_Aggregate;
  /** fetch data from the table: "invitations" using primary key columns */
  invitations_by_pk?: Maybe<Invitations>;
  /** fetch data from the table: "notification_delivery_types" */
  notification_delivery_types: Array<Notification_Delivery_Types>;
  /** fetch aggregated fields from the table: "notification_delivery_types" */
  notification_delivery_types_aggregate: Notification_Delivery_Types_Aggregate;
  /** fetch data from the table: "notification_delivery_types" using primary key columns */
  notification_delivery_types_by_pk?: Maybe<Notification_Delivery_Types>;
  /** An array relationship */
  notification_disabled_settings: Array<Notification_Disabled_Settings>;
  /** An aggregate relationship */
  notification_disabled_settings_aggregate: Notification_Disabled_Settings_Aggregate;
  /** fetch data from the table: "notification_disabled_settings" using primary key columns */
  notification_disabled_settings_by_pk?: Maybe<Notification_Disabled_Settings>;
  /** fetch data from the table: "notification_types" */
  notification_types: Array<Notification_Types>;
  /** fetch aggregated fields from the table: "notification_types" */
  notification_types_aggregate: Notification_Types_Aggregate;
  /** fetch data from the table: "notification_types" using primary key columns */
  notification_types_by_pk?: Maybe<Notification_Types>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** An array relationship */
  number_field_values: Array<Number_Field_Values>;
  /** An aggregate relationship */
  number_field_values_aggregate: Number_Field_Values_Aggregate;
  /** fetch data from the table: "number_field_values" using primary key columns */
  number_field_values_by_pk?: Maybe<Number_Field_Values>;
  /** fetch data from the table: "organization_evidence_tags" */
  organization_evidence_tags: Array<Organization_Evidence_Tags>;
  /** fetch aggregated fields from the table: "organization_evidence_tags" */
  organization_evidence_tags_aggregate: Organization_Evidence_Tags_Aggregate;
  /** fetch data from the table: "organization_evidence_tags" using primary key columns */
  organization_evidence_tags_by_pk?: Maybe<Organization_Evidence_Tags>;
  /** An array relationship */
  organization_integrations: Array<Organization_Integrations>;
  /** An aggregate relationship */
  organization_integrations_aggregate: Organization_Integrations_Aggregate;
  /** fetch data from the table: "organization_integrations" using primary key columns */
  organization_integrations_by_pk?: Maybe<Organization_Integrations>;
  /** fetch data from the table: "organization_risk_categories" */
  organization_risk_categories: Array<Organization_Risk_Categories>;
  /** fetch aggregated fields from the table: "organization_risk_categories" */
  organization_risk_categories_aggregate: Organization_Risk_Categories_Aggregate;
  /** fetch data from the table: "organization_risk_categories" using primary key columns */
  organization_risk_categories_by_pk?: Maybe<Organization_Risk_Categories>;
  /** fetch data from the table: "organization_risk_tags" */
  organization_risk_tags: Array<Organization_Risk_Tags>;
  /** fetch aggregated fields from the table: "organization_risk_tags" */
  organization_risk_tags_aggregate: Organization_Risk_Tags_Aggregate;
  /** fetch data from the table: "organization_risk_tags" using primary key columns */
  organization_risk_tags_by_pk?: Maybe<Organization_Risk_Tags>;
  /** An array relationship */
  organization_template_frameworks: Array<Organization_Template_Frameworks>;
  /** An aggregate relationship */
  organization_template_frameworks_aggregate: Organization_Template_Frameworks_Aggregate;
  /** fetch data from the table: "organization_template_frameworks" using primary key columns */
  organization_template_frameworks_by_pk?: Maybe<Organization_Template_Frameworks>;
  /** An array relationship */
  organization_tenants: Array<Organization_Tenants>;
  /** An aggregate relationship */
  organization_tenants_aggregate: Organization_Tenants_Aggregate;
  /** fetch data from the table: "organization_tenants" using primary key columns */
  organization_tenants_by_pk?: Maybe<Organization_Tenants>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  /** fetch data from the table: "organization_users" using primary key columns */
  organization_users_by_pk?: Maybe<Organization_Users>;
  /** fetch data from the table: "organization_vendor_risk_levels" */
  organization_vendor_risk_levels: Array<Organization_Vendor_Risk_Levels>;
  /** fetch aggregated fields from the table: "organization_vendor_risk_levels" */
  organization_vendor_risk_levels_aggregate: Organization_Vendor_Risk_Levels_Aggregate;
  /** fetch data from the table: "organization_vendor_risk_levels" using primary key columns */
  organization_vendor_risk_levels_by_pk?: Maybe<Organization_Vendor_Risk_Levels>;
  /** fetch data from the table: "organization_vendor_tiers" */
  organization_vendor_tiers: Array<Organization_Vendor_Tiers>;
  /** fetch aggregated fields from the table: "organization_vendor_tiers" */
  organization_vendor_tiers_aggregate: Organization_Vendor_Tiers_Aggregate;
  /** fetch data from the table: "organization_vendor_tiers" using primary key columns */
  organization_vendor_tiers_by_pk?: Maybe<Organization_Vendor_Tiers>;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
  /** fetch data from the table: "policies" using primary key columns */
  policies_by_pk?: Maybe<Policies>;
  /** fetch data from the table: "policy_acknowledgement_statuses" */
  policy_acknowledgement_statuses: Array<Policy_Acknowledgement_Statuses>;
  /** fetch aggregated fields from the table: "policy_acknowledgement_statuses" */
  policy_acknowledgement_statuses_aggregate: Policy_Acknowledgement_Statuses_Aggregate;
  /** fetch data from the table: "policy_acknowledgement_statuses" using primary key columns */
  policy_acknowledgement_statuses_by_pk?: Maybe<Policy_Acknowledgement_Statuses>;
  /** An array relationship */
  policy_acknowledgement_users: Array<Policy_Acknowledgement_Users>;
  /** An aggregate relationship */
  policy_acknowledgement_users_aggregate: Policy_Acknowledgement_Users_Aggregate;
  /** fetch data from the table: "policy_acknowledgement_users" using primary key columns */
  policy_acknowledgement_users_by_pk?: Maybe<Policy_Acknowledgement_Users>;
  /** An array relationship */
  policy_acknowledgements: Array<Policy_Acknowledgements>;
  /** An aggregate relationship */
  policy_acknowledgements_aggregate: Policy_Acknowledgements_Aggregate;
  /** fetch data from the table: "policy_acknowledgements" using primary key columns */
  policy_acknowledgements_by_pk?: Maybe<Policy_Acknowledgements>;
  /** fetch data from the table: "policy_approval_statuses" */
  policy_approval_statuses: Array<Policy_Approval_Statuses>;
  /** fetch aggregated fields from the table: "policy_approval_statuses" */
  policy_approval_statuses_aggregate: Policy_Approval_Statuses_Aggregate;
  /** fetch data from the table: "policy_approval_statuses" using primary key columns */
  policy_approval_statuses_by_pk?: Maybe<Policy_Approval_Statuses>;
  /** An array relationship */
  policy_approval_users: Array<Policy_Approval_Users>;
  /** An aggregate relationship */
  policy_approval_users_aggregate: Policy_Approval_Users_Aggregate;
  /** fetch data from the table: "policy_approval_users" using primary key columns */
  policy_approval_users_by_pk?: Maybe<Policy_Approval_Users>;
  /** An array relationship */
  policy_approvals: Array<Policy_Approvals>;
  /** An aggregate relationship */
  policy_approvals_aggregate: Policy_Approvals_Aggregate;
  /** fetch data from the table: "policy_approvals" using primary key columns */
  policy_approvals_by_pk?: Maybe<Policy_Approvals>;
  /** An array relationship */
  policy_approvers: Array<Policy_Approvers>;
  /** An aggregate relationship */
  policy_approvers_aggregate: Policy_Approvers_Aggregate;
  /** fetch data from the table: "policy_approvers" using primary key columns */
  policy_approvers_by_pk?: Maybe<Policy_Approvers>;
  /** fetch data from the table: "policy_statuses" */
  policy_statuses: Array<Policy_Statuses>;
  /** fetch aggregated fields from the table: "policy_statuses" */
  policy_statuses_aggregate: Policy_Statuses_Aggregate;
  /** fetch data from the table: "policy_statuses" using primary key columns */
  policy_statuses_by_pk?: Maybe<Policy_Statuses>;
  /** fetch data from the table: "policy_tasks" */
  policy_tasks: Array<Policy_Tasks>;
  /** fetch aggregated fields from the table: "policy_tasks" */
  policy_tasks_aggregate: Policy_Tasks_Aggregate;
  /** fetch data from the table: "policy_tasks" using primary key columns */
  policy_tasks_by_pk?: Maybe<Policy_Tasks>;
  /** fetch data from the table: "policy_types" */
  policy_types: Array<Policy_Types>;
  /** fetch aggregated fields from the table: "policy_types" */
  policy_types_aggregate: Policy_Types_Aggregate;
  /** fetch data from the table: "policy_types" using primary key columns */
  policy_types_by_pk?: Maybe<Policy_Types>;
  /** fetch data from the table: "policy_version_statuses" */
  policy_version_statuses: Array<Policy_Version_Statuses>;
  /** fetch aggregated fields from the table: "policy_version_statuses" */
  policy_version_statuses_aggregate: Policy_Version_Statuses_Aggregate;
  /** fetch data from the table: "policy_version_statuses" using primary key columns */
  policy_version_statuses_by_pk?: Maybe<Policy_Version_Statuses>;
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  /** An aggregate relationship */
  policy_versions_aggregate: Policy_Versions_Aggregate;
  /** fetch data from the table: "policy_versions" using primary key columns */
  policy_versions_by_pk?: Maybe<Policy_Versions>;
  /** An array relationship */
  program_controls: Array<Program_Controls>;
  /** An aggregate relationship */
  program_controls_aggregate: Program_Controls_Aggregate;
  /** fetch data from the table: "program_controls" using primary key columns */
  program_controls_by_pk?: Maybe<Program_Controls>;
  /** An array relationship */
  program_field_configs: Array<Program_Field_Configs>;
  /** An aggregate relationship */
  program_field_configs_aggregate: Program_Field_Configs_Aggregate;
  /** fetch data from the table: "program_field_configs" using primary key columns */
  program_field_configs_by_pk?: Maybe<Program_Field_Configs>;
  /** An array relationship */
  programs: Array<Programs>;
  /** An aggregate relationship */
  programs_aggregate: Programs_Aggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  /** An aggregate relationship */
  questionnaire_uploads_aggregate: Questionnaire_Uploads_Aggregate;
  /** fetch data from the table: "questionnaire_uploads" using primary key columns */
  questionnaire_uploads_by_pk?: Maybe<Questionnaire_Uploads>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table: "report_types" */
  report_types: Array<Report_Types>;
  /** fetch aggregated fields from the table: "report_types" */
  report_types_aggregate: Report_Types_Aggregate;
  /** fetch data from the table: "report_types" using primary key columns */
  report_types_by_pk?: Maybe<Report_Types>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table: "review_files" */
  review_files: Array<Review_Files>;
  /** fetch aggregated fields from the table: "review_files" */
  review_files_aggregate: Review_Files_Aggregate;
  /** fetch data from the table: "review_files" using primary key columns */
  review_files_by_pk?: Maybe<Review_Files>;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** fetch data from the table: "reviews" using primary key columns */
  reviews_by_pk?: Maybe<Reviews>;
  /** An array relationship */
  risk_categories: Array<Risk_Categories>;
  /** An aggregate relationship */
  risk_categories_aggregate: Risk_Categories_Aggregate;
  /** fetch data from the table: "risk_categories" using primary key columns */
  risk_categories_by_pk?: Maybe<Risk_Categories>;
  /** An array relationship */
  risk_controls: Array<Risk_Controls>;
  /** An aggregate relationship */
  risk_controls_aggregate: Risk_Controls_Aggregate;
  /** fetch data from the table: "risk_controls" using primary key columns */
  risk_controls_by_pk?: Maybe<Risk_Controls>;
  /** fetch data from the table: "risk_documents" */
  risk_documents: Array<Risk_Documents>;
  /** fetch aggregated fields from the table: "risk_documents" */
  risk_documents_aggregate: Risk_Documents_Aggregate;
  /** fetch data from the table: "risk_documents" using primary key columns */
  risk_documents_by_pk?: Maybe<Risk_Documents>;
  /** An array relationship */
  risk_impacts: Array<Risk_Impacts>;
  /** An aggregate relationship */
  risk_impacts_aggregate: Risk_Impacts_Aggregate;
  /** fetch data from the table: "risk_impacts" using primary key columns */
  risk_impacts_by_pk?: Maybe<Risk_Impacts>;
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** An aggregate relationship */
  risk_inherent_levels_aggregate: Risk_Inherent_Levels_Aggregate;
  /** fetch data from the table: "risk_inherent_levels" using primary key columns */
  risk_inherent_levels_by_pk?: Maybe<Risk_Inherent_Levels>;
  /** fetch data from the table: "risk_levels" */
  risk_levels: Array<Risk_Levels>;
  /** fetch aggregated fields from the table: "risk_levels" */
  risk_levels_aggregate: Risk_Levels_Aggregate;
  /** fetch data from the table: "risk_levels" using primary key columns */
  risk_levels_by_pk?: Maybe<Risk_Levels>;
  /** An array relationship */
  risk_likelihoods: Array<Risk_Likelihoods>;
  /** An aggregate relationship */
  risk_likelihoods_aggregate: Risk_Likelihoods_Aggregate;
  /** fetch data from the table: "risk_likelihoods" using primary key columns */
  risk_likelihoods_by_pk?: Maybe<Risk_Likelihoods>;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  /** An aggregate relationship */
  risk_residual_levels_aggregate: Risk_Residual_Levels_Aggregate;
  /** fetch data from the table: "risk_residual_levels" using primary key columns */
  risk_residual_levels_by_pk?: Maybe<Risk_Residual_Levels>;
  /** An array relationship */
  risk_tags: Array<Risk_Tags>;
  /** An aggregate relationship */
  risk_tags_aggregate: Risk_Tags_Aggregate;
  /** fetch data from the table: "risk_tags" using primary key columns */
  risk_tags_by_pk?: Maybe<Risk_Tags>;
  /** fetch data from the table: "risk_tasks" */
  risk_tasks: Array<Risk_Tasks>;
  /** fetch aggregated fields from the table: "risk_tasks" */
  risk_tasks_aggregate: Risk_Tasks_Aggregate;
  /** fetch data from the table: "risk_tasks" using primary key columns */
  risk_tasks_by_pk?: Maybe<Risk_Tasks>;
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** fetch data from the table: "risks" using primary key columns */
  risks_by_pk?: Maybe<Risks>;
  /** An array relationship */
  role_permissions: Array<Role_Permissions>;
  /** An aggregate relationship */
  role_permissions_aggregate: Role_Permissions_Aggregate;
  /** fetch data from the table: "role_permissions" using primary key columns */
  role_permissions_by_pk?: Maybe<Role_Permissions>;
  /** An array relationship */
  roles: Array<Roles>;
  /** An aggregate relationship */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** An array relationship */
  select_field_configs: Array<Select_Field_Configs>;
  /** An aggregate relationship */
  select_field_configs_aggregate: Select_Field_Configs_Aggregate;
  /** fetch data from the table: "select_field_configs" using primary key columns */
  select_field_configs_by_pk?: Maybe<Select_Field_Configs>;
  /** An array relationship */
  select_field_options: Array<Select_Field_Options>;
  /** An aggregate relationship */
  select_field_options_aggregate: Select_Field_Options_Aggregate;
  /** fetch data from the table: "select_field_options" using primary key columns */
  select_field_options_by_pk?: Maybe<Select_Field_Options>;
  /** An array relationship */
  select_field_values: Array<Select_Field_Values>;
  /** An aggregate relationship */
  select_field_values_aggregate: Select_Field_Values_Aggregate;
  /** fetch data from the table: "select_field_values" using primary key columns */
  select_field_values_by_pk?: Maybe<Select_Field_Values>;
  /** fetch data from the table: "system_roles" */
  system_roles: Array<System_Roles>;
  /** fetch aggregated fields from the table: "system_roles" */
  system_roles_aggregate: System_Roles_Aggregate;
  /** fetch data from the table: "system_roles" using primary key columns */
  system_roles_by_pk?: Maybe<System_Roles>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  task_owners: Array<Task_Owners>;
  /** An aggregate relationship */
  task_owners_aggregate: Task_Owners_Aggregate;
  /** fetch data from the table: "task_owners" using primary key columns */
  task_owners_by_pk?: Maybe<Task_Owners>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  template_control_ai_review_criteria: Array<Template_Control_Ai_Review_Criteria>;
  /** An aggregate relationship */
  template_control_ai_review_criteria_aggregate: Template_Control_Ai_Review_Criteria_Aggregate;
  /** fetch data from the table: "template_control_ai_review_criteria" using primary key columns */
  template_control_ai_review_criteria_by_pk?: Maybe<Template_Control_Ai_Review_Criteria>;
  /** An array relationship */
  template_controls: Array<Template_Controls>;
  /** An aggregate relationship */
  template_controls_aggregate: Template_Controls_Aggregate;
  /** fetch data from the table: "template_controls" using primary key columns */
  template_controls_by_pk?: Maybe<Template_Controls>;
  /** fetch data from the table: "template_frameworks" */
  template_frameworks: Array<Template_Frameworks>;
  /** fetch aggregated fields from the table: "template_frameworks" */
  template_frameworks_aggregate: Template_Frameworks_Aggregate;
  /** fetch data from the table: "template_frameworks" using primary key columns */
  template_frameworks_by_pk?: Maybe<Template_Frameworks>;
  /** An array relationship */
  template_frameworks_controls: Array<Template_Frameworks_Controls>;
  /** An aggregate relationship */
  template_frameworks_controls_aggregate: Template_Frameworks_Controls_Aggregate;
  /** fetch data from the table: "template_frameworks_controls" using primary key columns */
  template_frameworks_controls_by_pk?: Maybe<Template_Frameworks_Controls>;
  /** An array relationship */
  template_tasks: Array<Template_Tasks>;
  /** An aggregate relationship */
  template_tasks_aggregate: Template_Tasks_Aggregate;
  /** fetch data from the table: "template_tasks" using primary key columns */
  template_tasks_by_pk?: Maybe<Template_Tasks>;
  /** An array relationship */
  text_field_values: Array<Text_Field_Values>;
  /** An aggregate relationship */
  text_field_values_aggregate: Text_Field_Values_Aggregate;
  /** fetch data from the table: "text_field_values" using primary key columns */
  text_field_values_by_pk?: Maybe<Text_Field_Values>;
  /** fetch data from the table: "treatment_plan" */
  treatment_plan: Array<Treatment_Plan>;
  /** fetch aggregated fields from the table: "treatment_plan" */
  treatment_plan_aggregate: Treatment_Plan_Aggregate;
  /** fetch data from the table: "treatment_plan" using primary key columns */
  treatment_plan_by_pk?: Maybe<Treatment_Plan>;
  /** An array relationship */
  url_field_values: Array<Url_Field_Values>;
  /** An aggregate relationship */
  url_field_values_aggregate: Url_Field_Values_Aggregate;
  /** fetch data from the table: "url_field_values" using primary key columns */
  url_field_values_by_pk?: Maybe<Url_Field_Values>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_controls_stats_view" */
  user_controls_stats_view: Array<User_Controls_Stats_View>;
  /** fetch aggregated fields from the table: "user_controls_stats_view" */
  user_controls_stats_view_aggregate: User_Controls_Stats_View_Aggregate;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** fetch data from the table: "user_notifications" using primary key columns */
  user_notifications_by_pk?: Maybe<User_Notifications>;
  /** fetch data from the table: "user_tasks_stats_view" */
  user_tasks_stats_view: Array<User_Tasks_Stats_View>;
  /** fetch aggregated fields from the table: "user_tasks_stats_view" */
  user_tasks_stats_view_aggregate: User_Tasks_Stats_View_Aggregate;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table: "vendor_approval_statuses" */
  vendor_approval_statuses: Array<Vendor_Approval_Statuses>;
  /** fetch aggregated fields from the table: "vendor_approval_statuses" */
  vendor_approval_statuses_aggregate: Vendor_Approval_Statuses_Aggregate;
  /** fetch data from the table: "vendor_approval_statuses" using primary key columns */
  vendor_approval_statuses_by_pk?: Maybe<Vendor_Approval_Statuses>;
  /** fetch data from the table: "vendor_documents" */
  vendor_documents: Array<Vendor_Documents>;
  /** fetch aggregated fields from the table: "vendor_documents" */
  vendor_documents_aggregate: Vendor_Documents_Aggregate;
  /** fetch data from the table: "vendor_documents" using primary key columns */
  vendor_documents_by_pk?: Maybe<Vendor_Documents>;
  /** An array relationship */
  vendor_findings: Array<Vendor_Findings>;
  /** An aggregate relationship */
  vendor_findings_aggregate: Vendor_Findings_Aggregate;
  /** fetch data from the table: "vendor_findings" using primary key columns */
  vendor_findings_by_pk?: Maybe<Vendor_Findings>;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessment_sources: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_assessment_sources_aggregate: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_ai_review_assessment_sources" using primary key columns */
  vendor_questionnaire_ai_review_assessment_sources_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessments: Array<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_assessments_aggregate: Vendor_Questionnaire_Ai_Review_Assessments_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_ai_review_assessments" using primary key columns */
  vendor_questionnaire_ai_review_assessments_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** fetch data from the table: "vendor_questionnaire_ai_review_run_status" */
  vendor_questionnaire_ai_review_run_status: Array<Vendor_Questionnaire_Ai_Review_Run_Status>;
  /** fetch aggregated fields from the table: "vendor_questionnaire_ai_review_run_status" */
  vendor_questionnaire_ai_review_run_status_aggregate: Vendor_Questionnaire_Ai_Review_Run_Status_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_ai_review_run_status" using primary key columns */
  vendor_questionnaire_ai_review_run_status_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status>;
  /** An array relationship */
  vendor_questionnaire_ai_review_runs: Array<Vendor_Questionnaire_Ai_Review_Runs>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_runs_aggregate: Vendor_Questionnaire_Ai_Review_Runs_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_ai_review_runs" using primary key columns */
  vendor_questionnaire_ai_review_runs_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Runs>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch aggregated fields from the table: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses_aggregate: Vendor_Questionnaire_Form_Answer_Statuses_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" using primary key columns */
  vendor_questionnaire_form_answer_statuses_by_pk?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
  /** An aggregate relationship */
  vendor_questionnaire_form_answers_aggregate: Vendor_Questionnaire_Form_Answers_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_form_answers" using primary key columns */
  vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** An aggregate relationship */
  vendor_questionnaire_form_uploads_aggregate: Vendor_Questionnaire_Form_Uploads_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_form_uploads" using primary key columns */
  vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms: Array<Vendor_Questionnaire_Forms>;
  /** fetch aggregated fields from the table: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms_aggregate: Vendor_Questionnaire_Forms_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_forms" using primary key columns */
  vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses: Array<Vendor_Questionnaire_Statuses>;
  /** fetch aggregated fields from the table: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses_aggregate: Vendor_Questionnaire_Statuses_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_statuses" using primary key columns */
  vendor_questionnaire_statuses_by_pk?: Maybe<Vendor_Questionnaire_Statuses>;
  /** An array relationship */
  vendor_questionnaire_uploads: Array<Vendor_Questionnaire_Uploads>;
  /** An aggregate relationship */
  vendor_questionnaire_uploads_aggregate: Vendor_Questionnaire_Uploads_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_uploads" using primary key columns */
  vendor_questionnaire_uploads_by_pk?: Maybe<Vendor_Questionnaire_Uploads>;
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
  /** An aggregate relationship */
  vendor_questionnaires_aggregate: Vendor_Questionnaires_Aggregate;
  /** fetch data from the table: "vendor_questionnaires" using primary key columns */
  vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** An array relationship */
  vendor_risks: Array<Vendor_Risks>;
  /** An aggregate relationship */
  vendor_risks_aggregate: Vendor_Risks_Aggregate;
  /** fetch data from the table: "vendor_risks" using primary key columns */
  vendor_risks_by_pk?: Maybe<Vendor_Risks>;
  /** fetch data from the table: "vendor_tasks" */
  vendor_tasks: Array<Vendor_Tasks>;
  /** fetch aggregated fields from the table: "vendor_tasks" */
  vendor_tasks_aggregate: Vendor_Tasks_Aggregate;
  /** fetch data from the table: "vendor_tasks" using primary key columns */
  vendor_tasks_by_pk?: Maybe<Vendor_Tasks>;
  /** An array relationship */
  vendors: Array<Vendors>;
  /** An aggregate relationship */
  vendors_aggregate: Vendors_Aggregate;
  /** fetch data from the table: "vendors" using primary key columns */
  vendors_by_pk?: Maybe<Vendors>;
  /** fetch data from the table: "vendors_summary_view" */
  vendors_summary_view: Array<Vendors_Summary_View>;
  /** fetch aggregated fields from the table: "vendors_summary_view" */
  vendors_summary_view_aggregate: Vendors_Summary_View_Aggregate;
};

export type Query_RootAi_Control_Evidences_SuggestionsArgs = {
  args: Ai_Control_Evidences_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Control_Evidences_Suggestions_AggregateArgs = {
  args: Ai_Control_Evidences_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Controls_Text_SearchArgs = {
  args: Ai_Controls_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Controls_Text_Search_AggregateArgs = {
  args: Ai_Controls_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Controls_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Controls_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Controls_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Controls_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Controls_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Controls_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Controls_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Controls_Vector_Store_SearchArgs = {
  args: Ai_Controls_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Controls_Vector_Store_Search_AggregateArgs = {
  args: Ai_Controls_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Evidence_Suggestions_For_ControlArgs = {
  input: AiControlEvidenceSuggestionsInput;
};

export type Query_RootAi_Evidences_Text_SearchArgs = {
  args: Ai_Evidences_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Evidences_Text_Search_AggregateArgs = {
  args: Ai_Evidences_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Evidences_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Evidences_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Evidences_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Evidences_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Evidences_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Evidences_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Evidences_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Evidences_Vector_Store_SearchArgs = {
  args: Ai_Evidences_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Evidences_Vector_Store_Search_AggregateArgs = {
  args: Ai_Evidences_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Files_Document_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Document_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Document_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
};

export type Query_RootAi_Files_Document_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Document_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Document_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
};

export type Query_RootAi_Files_Document_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Files_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Files_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Files_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Files_Vector_Store_SearchArgs = {
  args: Ai_Files_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Files_Vector_Store_Search_AggregateArgs = {
  args: Ai_Files_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Policies_Text_SearchArgs = {
  args: Ai_Policies_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootAi_Policies_Text_Search_AggregateArgs = {
  args: Ai_Policies_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootAi_Policies_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Policies_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Policies_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Policies_Vector_Store_SearchArgs = {
  args: Ai_Policies_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootAi_Policies_Vector_Store_Search_AggregateArgs = {
  args: Ai_Policies_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootAi_Programs_Text_SearchArgs = {
  args: Ai_Programs_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootAi_Programs_Text_Search_AggregateArgs = {
  args: Ai_Programs_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootAi_Programs_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Programs_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Programs_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Programs_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Programs_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Programs_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Programs_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Programs_Vector_Store_SearchArgs = {
  args: Ai_Programs_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootAi_Programs_Vector_Store_Search_AggregateArgs = {
  args: Ai_Programs_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootAi_Risks_Text_SearchArgs = {
  args: Ai_Risks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootAi_Risks_Text_Search_AggregateArgs = {
  args: Ai_Risks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootAi_Risks_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Risks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Risks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Risks_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Risks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Risks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Risks_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Risks_Vector_Store_SearchArgs = {
  args: Ai_Risks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootAi_Risks_Vector_Store_Search_AggregateArgs = {
  args: Ai_Risks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootAi_Tasks_Text_SearchArgs = {
  args: Ai_Tasks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootAi_Tasks_Text_Search_AggregateArgs = {
  args: Ai_Tasks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootAi_Tasks_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Tasks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Tasks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Tasks_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Tasks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Tasks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Tasks_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Tasks_Vector_Store_SearchArgs = {
  args: Ai_Tasks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootAi_Tasks_Vector_Store_Search_AggregateArgs = {
  args: Ai_Tasks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootAi_Vendors_Text_SearchArgs = {
  args: Ai_Vendors_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootAi_Vendors_Text_Search_AggregateArgs = {
  args: Ai_Vendors_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootAi_Vendors_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Vendors_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Vendors_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Vendors_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Vendors_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Vendors_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Vendors_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Vendors_Vector_Store_SearchArgs = {
  args: Ai_Vendors_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootAi_Vendors_Vector_Store_Search_AggregateArgs = {
  args: Ai_Vendors_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootAudit_Control_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Control_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Control_History_View_Order_By>>;
  where?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
};

export type Query_RootAudit_Control_History_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Control_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Control_History_View_Order_By>>;
  where?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
};

export type Query_RootAudit_Logged_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Query_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Query_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint']['input'];
};

export type Query_RootAudit_Policy_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Policy_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Policy_History_View_Order_By>>;
  where?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
};

export type Query_RootAudit_Policy_History_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Policy_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Policy_History_View_Order_By>>;
  where?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
};

export type Query_RootAudit_Risk_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Risk_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Risk_History_View_Order_By>>;
  where?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
};

export type Query_RootAudit_Risk_History_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Risk_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Risk_History_View_Order_By>>;
  where?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
};

export type Query_RootAudit_Vendor_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Vendor_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Vendor_History_View_Order_By>>;
  where?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
};

export type Query_RootAudit_Vendor_History_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Vendor_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Vendor_History_View_Order_By>>;
  where?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
};

export type Query_RootAuthProviderArgs = {
  id: Scalars['String']['input'];
};

export type Query_RootAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAuthProviderRequestsArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};

export type Query_RootAuthProviderRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};

export type Query_RootAuthProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};

export type Query_RootAuthProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};

export type Query_RootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootAuthRefreshTokenTypesArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};

export type Query_RootAuthRefreshTokenTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};

export type Query_RootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

export type Query_RootAuthRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

export type Query_RootAuthRoleArgs = {
  role: Scalars['String']['input'];
};

export type Query_RootAuthRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};

export type Query_RootAuthRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};

export type Query_RootAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAuthUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

export type Query_RootAuthUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

export type Query_RootAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAuthUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

export type Query_RootAuthUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

export type Query_RootAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAuthUserSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

export type Query_RootAuthUserSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

export type Query_RootAuth_Jackson_IndexArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Index_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
};

export type Query_RootAuth_Jackson_Index_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Index_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
};

export type Query_RootAuth_Jackson_Index_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootAuth_Jackson_StoreArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Store_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Store_Bool_Exp>;
};

export type Query_RootAuth_Jackson_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Store_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Store_Bool_Exp>;
};

export type Query_RootAuth_Jackson_Store_By_PkArgs = {
  key: Scalars['String']['input'];
};

export type Query_RootAuth_Jackson_TtlArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Ttl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Ttl_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Ttl_Bool_Exp>;
};

export type Query_RootAuth_Jackson_Ttl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Ttl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Ttl_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Ttl_Bool_Exp>;
};

export type Query_RootAuth_Jackson_Ttl_By_PkArgs = {
  key: Scalars['String']['input'];
};

export type Query_RootAuth_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Migrations_Order_By>>;
  where?: InputMaybe<Auth_Migrations_Bool_Exp>;
};

export type Query_RootAuth_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Migrations_Order_By>>;
  where?: InputMaybe<Auth_Migrations_Bool_Exp>;
};

export type Query_RootAuth_Migrations_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Query_RootBucketArgs = {
  id: Scalars['String']['input'];
};

export type Query_RootBucketsArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

export type Query_RootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

export type Query_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

export type Query_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

export type Query_RootCategories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootClient_Questionnaire_Answer_Ai_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Answer_Ai_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Answer_Ai_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootClient_Questionnaire_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootClient_Questionnaire_Question_StatusArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Question_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Question_Status_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Question_Status_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Question_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Question_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Question_Status_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Question_Status_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Question_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootClient_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootClient_Questionnaire_StatusArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Status_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Status_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Status_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Status_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

export type Query_RootClient_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

export type Query_RootClient_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootComment_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Uploads_Order_By>>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

export type Query_RootComment_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Uploads_Order_By>>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

export type Query_RootComment_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Query_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Query_RootComments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControl_Ai_Review_Assessment_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Assessment_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Assessment_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControl_Ai_Review_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Assessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Assessments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControl_Ai_Review_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Criteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControl_Ai_Review_RunsArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControl_Ai_Review_StatusArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Status_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Status_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Status_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Status_Bool_Exp>;
};

export type Query_RootControl_Ai_Review_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootControl_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

export type Query_RootControl_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

export type Query_RootControl_Categories_By_PkArgs = {
  category_id: Scalars['uuid']['input'];
  control_id: Scalars['uuid']['input'];
};

export type Query_RootControl_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

export type Query_RootControl_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

export type Query_RootControl_Criteria_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  criteria_id: Scalars['uuid']['input'];
};

export type Query_RootControl_EvidencesArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

export type Query_RootControl_Evidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

export type Query_RootControl_Evidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

export type Query_RootControl_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

export type Query_RootControl_Findings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControl_FrequenciesArgs = {
  distinct_on?: InputMaybe<Array<Control_Frequencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Frequencies_Order_By>>;
  where?: InputMaybe<Control_Frequencies_Bool_Exp>;
};

export type Query_RootControl_Frequencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Frequencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Frequencies_Order_By>>;
  where?: InputMaybe<Control_Frequencies_Bool_Exp>;
};

export type Query_RootControl_Frequencies_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootControl_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

export type Query_RootControl_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

export type Query_RootControl_Groups_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  group_id: Scalars['uuid']['input'];
};

export type Query_RootControl_PrioritiesArgs = {
  distinct_on?: InputMaybe<Array<Control_Priorities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Priorities_Order_By>>;
  where?: InputMaybe<Control_Priorities_Bool_Exp>;
};

export type Query_RootControl_Priorities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Priorities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Priorities_Order_By>>;
  where?: InputMaybe<Control_Priorities_Bool_Exp>;
};

export type Query_RootControl_Priorities_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootControl_TagsArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

export type Query_RootControl_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

export type Query_RootControl_Tags_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  tag_id: Scalars['uuid']['input'];
};

export type Query_RootControl_TasksArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

export type Query_RootControl_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

export type Query_RootControl_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootControls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControls_History_StatsArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

export type Query_RootControls_History_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

export type Query_RootControls_History_Stats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControls_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Stats_View_Order_By>>;
  where?: InputMaybe<Controls_Stats_View_Bool_Exp>;
};

export type Query_RootControls_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Stats_View_Order_By>>;
  where?: InputMaybe<Controls_Stats_View_Bool_Exp>;
};

export type Query_RootCriteriaArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

export type Query_RootCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

export type Query_RootCriteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootDaily_Control_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
};

export type Query_RootDaily_Control_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
};

export type Query_RootDaily_Control_User_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_User_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_User_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
};

export type Query_RootDaily_Control_User_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_User_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_User_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
};

export type Query_RootDate_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Date_Field_Values_Order_By>>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

export type Query_RootDate_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Date_Field_Values_Order_By>>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

export type Query_RootDate_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Query_RootEmail_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Email_Field_Values_Order_By>>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

export type Query_RootEmail_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Email_Field_Values_Order_By>>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

export type Query_RootEmail_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Query_RootEvidence_AclArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

export type Query_RootEvidence_Acl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

export type Query_RootEvidence_Acl_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEvidence_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

export type Query_RootEvidence_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

export type Query_RootEvidence_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEvidence_PoliciesArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Policies_Order_By>>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

export type Query_RootEvidence_Policies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Policies_Order_By>>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

export type Query_RootEvidence_Policies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEvidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

export type Query_RootEvidence_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

export type Query_RootEvidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEvidence_Version_FileArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Version_File_Order_By>>;
  where?: InputMaybe<Evidence_Version_File_Bool_Exp>;
};

export type Query_RootEvidence_Version_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Version_File_Order_By>>;
  where?: InputMaybe<Evidence_Version_File_Bool_Exp>;
};

export type Query_RootEvidence_Version_File_By_PkArgs = {
  evidence_version_id: Scalars['uuid']['input'];
  file_id: Scalars['uuid']['input'];
};

export type Query_RootEvidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

export type Query_RootEvidence_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

export type Query_RootEvidence_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEvidencesArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootEvidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootEvidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootField_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

export type Query_RootField_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

export type Query_RootField_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootField_EntitiesArgs = {
  distinct_on?: InputMaybe<Array<Field_Entities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Entities_Order_By>>;
  where?: InputMaybe<Field_Entities_Bool_Exp>;
};

export type Query_RootField_Entities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Entities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Entities_Order_By>>;
  where?: InputMaybe<Field_Entities_Bool_Exp>;
};

export type Query_RootField_Entities_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootField_TypesArgs = {
  distinct_on?: InputMaybe<Array<Field_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Types_Order_By>>;
  where?: InputMaybe<Field_Types_Bool_Exp>;
};

export type Query_RootField_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Types_Order_By>>;
  where?: InputMaybe<Field_Types_Bool_Exp>;
};

export type Query_RootField_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

export type Query_RootField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

export type Query_RootField_Values_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFileArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Query_RootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Query_RootFinding_CausesArgs = {
  distinct_on?: InputMaybe<Array<Finding_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Finding_Causes_Order_By>>;
  where?: InputMaybe<Finding_Causes_Bool_Exp>;
};

export type Query_RootFinding_Causes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Finding_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Finding_Causes_Order_By>>;
  where?: InputMaybe<Finding_Causes_Bool_Exp>;
};

export type Query_RootFinding_Causes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootFinding_TypesArgs = {
  distinct_on?: InputMaybe<Array<Finding_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Finding_Types_Order_By>>;
  where?: InputMaybe<Finding_Types_Bool_Exp>;
};

export type Query_RootFinding_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Finding_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Finding_Types_Order_By>>;
  where?: InputMaybe<Finding_Types_Bool_Exp>;
};

export type Query_RootFinding_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Query_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Query_RootForms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFrameworksArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

export type Query_RootFrameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

export type Query_RootFrameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFrameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

export type Query_RootFrameworks_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

export type Query_RootFrameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

export type Query_RootFrequenciesArgs = {
  distinct_on?: InputMaybe<Array<Frequencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frequencies_Order_By>>;
  where?: InputMaybe<Frequencies_Bool_Exp>;
};

export type Query_RootFrequencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frequencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frequencies_Order_By>>;
  where?: InputMaybe<Frequencies_Bool_Exp>;
};

export type Query_RootFrequencies_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootGlobal_SearchArgs = {
  input: SearchInput;
};

export type Query_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

export type Query_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

export type Query_RootGroups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootImport_PoliciesArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootImport_ProgramArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootImport_RisksArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootImport_VendorsArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootIntegration_NamesArgs = {
  distinct_on?: InputMaybe<Array<Integration_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Names_Order_By>>;
  where?: InputMaybe<Integration_Names_Bool_Exp>;
};

export type Query_RootIntegration_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Names_Order_By>>;
  where?: InputMaybe<Integration_Names_Bool_Exp>;
};

export type Query_RootIntegration_Names_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootIntegration_RunsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Runs_Order_By>>;
  where?: InputMaybe<Integration_Runs_Bool_Exp>;
};

export type Query_RootIntegration_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Runs_Order_By>>;
  where?: InputMaybe<Integration_Runs_Bool_Exp>;
};

export type Query_RootIntegration_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootIntegration_Usage_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Integration_Usage_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Usage_Stats_View_Order_By>>;
  where?: InputMaybe<Integration_Usage_Stats_View_Bool_Exp>;
};

export type Query_RootIntegration_Usage_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Usage_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Usage_Stats_View_Order_By>>;
  where?: InputMaybe<Integration_Usage_Stats_View_Bool_Exp>;
};

export type Query_RootIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Query_RootIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Query_RootIntegrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

export type Query_RootInvitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

export type Query_RootInvitations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootNotification_Delivery_TypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Delivery_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Delivery_Types_Order_By>>;
  where?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
};

export type Query_RootNotification_Delivery_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Delivery_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Delivery_Types_Order_By>>;
  where?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
};

export type Query_RootNotification_Delivery_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootNotification_Disabled_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

export type Query_RootNotification_Disabled_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

export type Query_RootNotification_Disabled_Settings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootNotification_TypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Types_Order_By>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};

export type Query_RootNotification_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Types_Order_By>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};

export type Query_RootNotification_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootNumber_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Number_Field_Values_Order_By>>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

export type Query_RootNumber_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Number_Field_Values_Order_By>>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

export type Query_RootNumber_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Evidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

export type Query_RootOrganization_Evidence_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

export type Query_RootOrganization_Evidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

export type Query_RootOrganization_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

export type Query_RootOrganization_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Risk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

export type Query_RootOrganization_Risk_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

export type Query_RootOrganization_Risk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Risk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

export type Query_RootOrganization_Risk_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

export type Query_RootOrganization_Risk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Template_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

export type Query_RootOrganization_Template_Frameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

export type Query_RootOrganization_Template_Frameworks_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  template_framework_id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

export type Query_RootOrganization_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

export type Query_RootOrganization_Tenants_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

export type Query_RootOrganization_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

export type Query_RootOrganization_Users_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Vendor_Risk_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

export type Query_RootOrganization_Vendor_Risk_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

export type Query_RootOrganization_Vendor_Risk_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Vendor_TiersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

export type Query_RootOrganization_Vendor_Tiers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

export type Query_RootOrganization_Vendor_Tiers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Query_RootOrganizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};

export type Query_RootPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};

export type Query_RootPermissions_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootPolicies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPolicy_Acknowledgement_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Statuses_Bool_Exp>;
};

export type Query_RootPolicy_Acknowledgement_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Statuses_Bool_Exp>;
};

export type Query_RootPolicy_Acknowledgement_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootPolicy_Acknowledgement_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

export type Query_RootPolicy_Acknowledgement_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

export type Query_RootPolicy_Acknowledgement_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPolicy_AcknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

export type Query_RootPolicy_Acknowledgements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

export type Query_RootPolicy_Acknowledgements_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPolicy_Approval_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Approval_Statuses_Bool_Exp>;
};

export type Query_RootPolicy_Approval_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Approval_Statuses_Bool_Exp>;
};

export type Query_RootPolicy_Approval_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootPolicy_Approval_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

export type Query_RootPolicy_Approval_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

export type Query_RootPolicy_Approval_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPolicy_ApprovalsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

export type Query_RootPolicy_Approvals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

export type Query_RootPolicy_Approvals_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPolicy_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

export type Query_RootPolicy_Approvers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

export type Query_RootPolicy_Approvers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPolicy_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Statuses_Bool_Exp>;
};

export type Query_RootPolicy_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Statuses_Bool_Exp>;
};

export type Query_RootPolicy_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootPolicy_TasksArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

export type Query_RootPolicy_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

export type Query_RootPolicy_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPolicy_TypesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Types_Order_By>>;
  where?: InputMaybe<Policy_Types_Bool_Exp>;
};

export type Query_RootPolicy_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Types_Order_By>>;
  where?: InputMaybe<Policy_Types_Bool_Exp>;
};

export type Query_RootPolicy_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootPolicy_Version_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Version_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Version_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Version_Statuses_Bool_Exp>;
};

export type Query_RootPolicy_Version_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Version_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Version_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Version_Statuses_Bool_Exp>;
};

export type Query_RootPolicy_Version_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

export type Query_RootPolicy_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

export type Query_RootPolicy_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootProgram_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

export type Query_RootProgram_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

export type Query_RootProgram_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  program_id: Scalars['uuid']['input'];
};

export type Query_RootProgram_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

export type Query_RootProgram_Field_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

export type Query_RootProgram_Field_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootPrograms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootPrograms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

export type Query_RootQuestionnaire_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

export type Query_RootQuestionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Query_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Query_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootReport_TypesArgs = {
  distinct_on?: InputMaybe<Array<Report_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Types_Order_By>>;
  where?: InputMaybe<Report_Types_Bool_Exp>;
};

export type Query_RootReport_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Types_Order_By>>;
  where?: InputMaybe<Report_Types_Bool_Exp>;
};

export type Query_RootReport_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

export type Query_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

export type Query_RootReports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootReview_FilesArgs = {
  distinct_on?: InputMaybe<Array<Review_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Review_Files_Order_By>>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

export type Query_RootReview_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Review_Files_Order_By>>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

export type Query_RootReview_Files_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Query_RootReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Query_RootReviews_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

export type Query_RootRisk_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

export type Query_RootRisk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

export type Query_RootRisk_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

export type Query_RootRisk_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Documents_Order_By>>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

export type Query_RootRisk_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Documents_Order_By>>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

export type Query_RootRisk_Documents_By_PkArgs = {
  file_id: Scalars['uuid']['input'];
  risk_id: Scalars['uuid']['input'];
};

export type Query_RootRisk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

export type Query_RootRisk_Impacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

export type Query_RootRisk_Impacts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

export type Query_RootRisk_Inherent_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

export type Query_RootRisk_Inherent_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Levels_Order_By>>;
  where?: InputMaybe<Risk_Levels_Bool_Exp>;
};

export type Query_RootRisk_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Levels_Order_By>>;
  where?: InputMaybe<Risk_Levels_Bool_Exp>;
};

export type Query_RootRisk_Levels_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootRisk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

export type Query_RootRisk_Likelihoods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

export type Query_RootRisk_Likelihoods_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

export type Query_RootRisk_Residual_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

export type Query_RootRisk_Residual_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

export type Query_RootRisk_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

export type Query_RootRisk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_TasksArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

export type Query_RootRisk_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

export type Query_RootRisk_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootRisks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRole_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

export type Query_RootRole_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

export type Query_RootRole_Permissions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootSelect_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Configs_Order_By>>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

export type Query_RootSelect_Field_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Configs_Order_By>>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

export type Query_RootSelect_Field_Configs_By_PkArgs = {
  field_config_id: Scalars['uuid']['input'];
};

export type Query_RootSelect_Field_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Options_Order_By>>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

export type Query_RootSelect_Field_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Options_Order_By>>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

export type Query_RootSelect_Field_Options_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootSelect_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

export type Query_RootSelect_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

export type Query_RootSelect_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Query_RootSystem_RolesArgs = {
  distinct_on?: InputMaybe<Array<System_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<System_Roles_Order_By>>;
  where?: InputMaybe<System_Roles_Bool_Exp>;
};

export type Query_RootSystem_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<System_Roles_Order_By>>;
  where?: InputMaybe<System_Roles_Bool_Exp>;
};

export type Query_RootSystem_Roles_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTask_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

export type Query_RootTask_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

export type Query_RootTask_Owners_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_Control_Ai_Review_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Query_RootTemplate_Control_Ai_Review_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Query_RootTemplate_Control_Ai_Review_Criteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Query_RootTemplate_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Query_RootTemplate_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

export type Query_RootTemplate_Frameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

export type Query_RootTemplate_Frameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_Frameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

export type Query_RootTemplate_Frameworks_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

export type Query_RootTemplate_Frameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_TasksArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Query_RootTemplate_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Query_RootTemplate_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootText_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Field_Values_Order_By>>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

export type Query_RootText_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Field_Values_Order_By>>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

export type Query_RootText_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Query_RootTreatment_PlanArgs = {
  distinct_on?: InputMaybe<Array<Treatment_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treatment_Plan_Order_By>>;
  where?: InputMaybe<Treatment_Plan_Bool_Exp>;
};

export type Query_RootTreatment_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treatment_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treatment_Plan_Order_By>>;
  where?: InputMaybe<Treatment_Plan_Bool_Exp>;
};

export type Query_RootTreatment_Plan_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootUrl_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Field_Values_Order_By>>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

export type Query_RootUrl_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Field_Values_Order_By>>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

export type Query_RootUrl_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Query_RootUserArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootUser_Controls_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Controls_Stats_View_Order_By>>;
  where?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
};

export type Query_RootUser_Controls_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Controls_Stats_View_Order_By>>;
  where?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
};

export type Query_RootUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Query_RootUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Query_RootUser_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootUser_Tasks_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Tasks_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tasks_Stats_View_Order_By>>;
  where?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
};

export type Query_RootUser_Tasks_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tasks_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tasks_Stats_View_Order_By>>;
  where?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootVendor_Approval_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Approval_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Approval_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Approval_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Approval_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Approval_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Approval_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Approval_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Approval_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Query_RootVendor_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Documents_Order_By>>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

export type Query_RootVendor_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Documents_Order_By>>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

export type Query_RootVendor_Documents_By_PkArgs = {
  file_id: Scalars['uuid']['input'];
  vendor_id: Scalars['uuid']['input'];
};

export type Query_RootVendor_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

export type Query_RootVendor_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

export type Query_RootVendor_Findings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_Ai_Review_Assessment_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Ai_Review_Assessment_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Ai_Review_Assessment_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_Ai_Review_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Ai_Review_Assessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Ai_Review_Assessments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_Ai_Review_Run_StatusArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Ai_Review_Run_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Ai_Review_Run_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootVendor_Questionnaire_Ai_Review_RunsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Ai_Review_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Ai_Review_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_Form_Answer_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answer_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answer_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_FormsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Forms_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Forms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Forms_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootVendor_Questionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Query_RootVendor_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Query_RootVendor_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_RisksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

export type Query_RootVendor_Risks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

export type Query_RootVendor_Risks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_TasksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

export type Query_RootVendor_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

export type Query_RootVendor_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootVendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendors_Summary_ViewArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Summary_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Summary_View_Order_By>>;
  where?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
};

export type Query_RootVendors_Summary_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Summary_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Summary_View_Order_By>>;
  where?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
};

/** File uploads for questionnaires */
export type Questionnaire_Uploads = {
  __typename?: 'questionnaire_uploads';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid']['output'];
};

/** aggregated selection of "questionnaire_uploads" */
export type Questionnaire_Uploads_Aggregate = {
  __typename?: 'questionnaire_uploads_aggregate';
  aggregate?: Maybe<Questionnaire_Uploads_Aggregate_Fields>;
  nodes: Array<Questionnaire_Uploads>;
};

export type Questionnaire_Uploads_Aggregate_Bool_Exp = {
  count?: InputMaybe<Questionnaire_Uploads_Aggregate_Bool_Exp_Count>;
};

export type Questionnaire_Uploads_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionnaire_uploads" */
export type Questionnaire_Uploads_Aggregate_Fields = {
  __typename?: 'questionnaire_uploads_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Questionnaire_Uploads_Max_Fields>;
  min?: Maybe<Questionnaire_Uploads_Min_Fields>;
};

/** aggregate fields of "questionnaire_uploads" */
export type Questionnaire_Uploads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaire_Uploads_Max_Order_By>;
  min?: InputMaybe<Questionnaire_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionnaire_uploads" */
export type Questionnaire_Uploads_Arr_Rel_Insert_Input = {
  data: Array<Questionnaire_Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaire_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionnaire_uploads". All fields are combined with a logical 'AND'. */
export type Questionnaire_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaire_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Uploads_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionnaire_uploads" */
export enum Questionnaire_Uploads_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnaireUploadsPkey = 'questionnaire_uploads_pkey',
}

/** input type for inserting data into table "questionnaire_uploads" */
export type Questionnaire_Uploads_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Questionnaire_Uploads_Max_Fields = {
  __typename?: 'questionnaire_uploads_max_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionnaire_Uploads_Min_Fields = {
  __typename?: 'questionnaire_uploads_min_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaire_uploads" */
export type Questionnaire_Uploads_Mutation_Response = {
  __typename?: 'questionnaire_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire_Uploads>;
};

/** on_conflict condition type for table "questionnaire_uploads" */
export type Questionnaire_Uploads_On_Conflict = {
  constraint: Questionnaire_Uploads_Constraint;
  update_columns?: Array<Questionnaire_Uploads_Update_Column>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaire_uploads". */
export type Questionnaire_Uploads_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionnaire_uploads */
export type Questionnaire_Uploads_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "questionnaire_uploads" */
export enum Questionnaire_Uploads_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
}

/** input type for updating data in table "questionnaire_uploads" */
export type Questionnaire_Uploads_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "questionnaire_uploads" */
export type Questionnaire_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaire_Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaire_Uploads_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "questionnaire_uploads" */
export enum Questionnaire_Uploads_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
}

export type Questionnaire_Uploads_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaire_Uploads_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaire_Uploads_Bool_Exp;
};

/** columns and relationships of "questionnaires" */
export type Questionnaires = {
  __typename?: 'questionnaires';
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  form?: Maybe<Forms>;
  form_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  /** An aggregate relationship */
  questionnaire_uploads_aggregate: Questionnaire_Uploads_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
  /** An aggregate relationship */
  vendor_questionnaires_aggregate: Vendor_Questionnaires_Aggregate;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestionnaire_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesVendor_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** aggregated selection of "questionnaires" */
export type Questionnaires_Aggregate = {
  __typename?: 'questionnaires_aggregate';
  aggregate?: Maybe<Questionnaires_Aggregate_Fields>;
  nodes: Array<Questionnaires>;
};

export type Questionnaires_Aggregate_Bool_Exp = {
  count?: InputMaybe<Questionnaires_Aggregate_Bool_Exp_Count>;
};

export type Questionnaires_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Questionnaires_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "questionnaires" */
export type Questionnaires_Aggregate_Fields = {
  __typename?: 'questionnaires_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Questionnaires_Max_Fields>;
  min?: Maybe<Questionnaires_Min_Fields>;
};

/** aggregate fields of "questionnaires" */
export type Questionnaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "questionnaires" */
export type Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaires_Max_Order_By>;
  min?: InputMaybe<Questionnaires_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionnaires" */
export type Questionnaires_Arr_Rel_Insert_Input = {
  data: Array<Questionnaires_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionnaires". All fields are combined with a logical 'AND'. */
export type Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  questionnaire_uploads_aggregate?: InputMaybe<Questionnaire_Uploads_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaires?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "questionnaires" */
export enum Questionnaires_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  QuestionnairesFileIdKey = 'questionnaires_file_id_key',
  /** unique or primary key constraint on columns "form_id" */
  QuestionnairesFormIdKey = 'questionnaires_form_id_key',
  /** unique or primary key constraint on columns "id" */
  QuestionnairesPkey = 'questionnaires_pkey',
}

/** input type for inserting data into table "questionnaires" */
export type Questionnaires_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  form_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaires?: InputMaybe<Vendor_Questionnaires_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Questionnaires_Max_Fields = {
  __typename?: 'questionnaires_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  form_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "questionnaires" */
export type Questionnaires_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Questionnaires_Min_Fields = {
  __typename?: 'questionnaires_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  form_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "questionnaires" */
export type Questionnaires_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaires" */
export type Questionnaires_Mutation_Response = {
  __typename?: 'questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaires>;
};

/** input type for inserting object relation for remote table "questionnaires" */
export type Questionnaires_Obj_Rel_Insert_Input = {
  data: Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "questionnaires" */
export type Questionnaires_On_Conflict = {
  constraint: Questionnaires_Constraint;
  update_columns?: Array<Questionnaires_Update_Column>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaires". */
export type Questionnaires_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  questionnaire_uploads_aggregate?: InputMaybe<Questionnaire_Uploads_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** primary key columns input for table: questionnaires */
export type Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "questionnaires" */
export enum Questionnaires_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "questionnaires" */
export type Questionnaires_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  form_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "questionnaires" */
export type Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaires_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  form_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "questionnaires" */
export enum Questionnaires_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Questionnaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaires_Bool_Exp;
};

/** columns and relationships of "report_types" */
export type Report_Types = {
  __typename?: 'report_types';
  name: Scalars['String']['output'];
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
};

/** columns and relationships of "report_types" */
export type Report_TypesReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** columns and relationships of "report_types" */
export type Report_TypesReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** aggregated selection of "report_types" */
export type Report_Types_Aggregate = {
  __typename?: 'report_types_aggregate';
  aggregate?: Maybe<Report_Types_Aggregate_Fields>;
  nodes: Array<Report_Types>;
};

/** aggregate fields of "report_types" */
export type Report_Types_Aggregate_Fields = {
  __typename?: 'report_types_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Report_Types_Max_Fields>;
  min?: Maybe<Report_Types_Min_Fields>;
};

/** aggregate fields of "report_types" */
export type Report_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Report_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "report_types". All fields are combined with a logical 'AND'. */
export type Report_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Report_Types_Bool_Exp>>;
  _not?: InputMaybe<Report_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Report_Types_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  reports?: InputMaybe<Reports_Bool_Exp>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "report_types" */
export enum Report_Types_Constraint {
  /** unique or primary key constraint on columns "name" */
  ReportTypesPkey = 'report_types_pkey',
}

export enum Report_Types_Enum {
  Controls = 'controls',
  Evidence = 'evidence',
  Risks = 'risks',
  Tasks = 'tasks',
  Vendors = 'vendors',
}

/** Boolean expression to compare columns of type "report_types_enum". All fields are combined with logical 'AND'. */
export type Report_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Report_Types_Enum>;
  _in?: InputMaybe<Array<Report_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Report_Types_Enum>;
  _nin?: InputMaybe<Array<Report_Types_Enum>>;
};

/** input type for inserting data into table "report_types" */
export type Report_Types_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<Reports_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Report_Types_Max_Fields = {
  __typename?: 'report_types_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Report_Types_Min_Fields = {
  __typename?: 'report_types_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "report_types" */
export type Report_Types_Mutation_Response = {
  __typename?: 'report_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Report_Types>;
};

/** input type for inserting object relation for remote table "report_types" */
export type Report_Types_Obj_Rel_Insert_Input = {
  data: Report_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Report_Types_On_Conflict>;
};

/** on_conflict condition type for table "report_types" */
export type Report_Types_On_Conflict = {
  constraint: Report_Types_Constraint;
  update_columns?: Array<Report_Types_Update_Column>;
  where?: InputMaybe<Report_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "report_types". */
export type Report_Types_Order_By = {
  name?: InputMaybe<Order_By>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Order_By>;
};

/** primary key columns input for table: report_types */
export type Report_Types_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "report_types" */
export enum Report_Types_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "report_types" */
export type Report_Types_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "report_types" */
export type Report_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Report_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Report_Types_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "report_types" */
export enum Report_Types_Update_Column {
  /** column name */
  Name = 'name',
}

export type Report_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Report_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Report_Types_Bool_Exp;
};

/** columns and relationships of "reports" */
export type Reports = {
  __typename?: 'reports';
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Scalars['ColumnFiltersState']['output']>;
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  report_type: Report_Types;
  sorting?: Maybe<Scalars['SortingState']['output']>;
  type: Report_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};

/** columns and relationships of "reports" */
export type ReportsFiltersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "reports" */
export type ReportsSortingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "reports" */
export type Reports_Aggregate = {
  __typename?: 'reports_aggregate';
  aggregate?: Maybe<Reports_Aggregate_Fields>;
  nodes: Array<Reports>;
};

export type Reports_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reports_Aggregate_Bool_Exp_Count>;
};

export type Reports_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reports_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reports" */
export type Reports_Aggregate_Fields = {
  __typename?: 'reports_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Reports_Max_Fields>;
  min?: Maybe<Reports_Min_Fields>;
};

/** aggregate fields of "reports" */
export type Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reports" */
export type Reports_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reports_Max_Order_By>;
  min?: InputMaybe<Reports_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Reports_Append_Input = {
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "reports" */
export type Reports_Arr_Rel_Insert_Input = {
  data: Array<Reports_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Reports_Bool_Exp>>;
  _not?: InputMaybe<Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Reports_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  filters?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  report_type?: InputMaybe<Report_Types_Bool_Exp>;
  sorting?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Report_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Reports_Delete_At_Path_Input = {
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  sorting?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Reports_Delete_Elem_Input = {
  filters?: InputMaybe<Scalars['Int']['input']>;
  sorting?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Reports_Delete_Key_Input = {
  filters?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "reports" */
export type Reports_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  report_type?: InputMaybe<Report_Types_Obj_Rel_Insert_Input>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Report_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Reports_Max_Fields = {
  __typename?: 'reports_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "reports" */
export type Reports_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reports_Min_Fields = {
  __typename?: 'reports_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "reports" */
export type Reports_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reports" */
export type Reports_Mutation_Response = {
  __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reports>;
};

/** on_conflict condition type for table "reports" */
export type Reports_On_Conflict = {
  constraint: Reports_Constraint;
  update_columns?: Array<Reports_Update_Column>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  filters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  report_type?: InputMaybe<Report_Types_Order_By>;
  sorting?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: reports */
export type Reports_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Reports_Prepend_Input = {
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Sorting = 'sorting',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "reports" */
export type Reports_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Report_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "reports" */
export type Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reports_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Report_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "reports" */
export enum Reports_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Sorting = 'sorting',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Reports_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reports_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reports_Bool_Exp;
};

/** columns and relationships of "review_files" */
export type Review_Files = {
  __typename?: 'review_files';
  created_at: Scalars['timestamptz']['output'];
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  review_files_file: Files;
  /** An object relationship */
  review_files_review: Reviews;
  review_id: Scalars['uuid']['output'];
};

/** aggregated selection of "review_files" */
export type Review_Files_Aggregate = {
  __typename?: 'review_files_aggregate';
  aggregate?: Maybe<Review_Files_Aggregate_Fields>;
  nodes: Array<Review_Files>;
};

export type Review_Files_Aggregate_Bool_Exp = {
  count?: InputMaybe<Review_Files_Aggregate_Bool_Exp_Count>;
};

export type Review_Files_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Review_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Review_Files_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "review_files" */
export type Review_Files_Aggregate_Fields = {
  __typename?: 'review_files_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Review_Files_Max_Fields>;
  min?: Maybe<Review_Files_Min_Fields>;
};

/** aggregate fields of "review_files" */
export type Review_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Review_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "review_files" */
export type Review_Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Files_Max_Order_By>;
  min?: InputMaybe<Review_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "review_files" */
export type Review_Files_Arr_Rel_Insert_Input = {
  data: Array<Review_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "review_files". All fields are combined with a logical 'AND'. */
export type Review_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Files_Bool_Exp>>;
  _not?: InputMaybe<Review_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  review_files_file?: InputMaybe<Files_Bool_Exp>;
  review_files_review?: InputMaybe<Reviews_Bool_Exp>;
  review_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "review_files" */
export enum Review_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewFilesPkey = 'review_files_pkey',
}

/** input type for inserting data into table "review_files" */
export type Review_Files_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  review_files_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  review_files_review?: InputMaybe<Reviews_Obj_Rel_Insert_Input>;
  review_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Review_Files_Max_Fields = {
  __typename?: 'review_files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  review_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "review_files" */
export type Review_Files_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Review_Files_Min_Fields = {
  __typename?: 'review_files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  review_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "review_files" */
export type Review_Files_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "review_files" */
export type Review_Files_Mutation_Response = {
  __typename?: 'review_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Files>;
};

/** on_conflict condition type for table "review_files" */
export type Review_Files_On_Conflict = {
  constraint: Review_Files_Constraint;
  update_columns?: Array<Review_Files_Update_Column>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "review_files". */
export type Review_Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_files_file?: InputMaybe<Files_Order_By>;
  review_files_review?: InputMaybe<Reviews_Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: review_files */
export type Review_Files_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "review_files" */
export enum Review_Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReviewId = 'review_id',
}

/** input type for updating data in table "review_files" */
export type Review_Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  review_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "review_files" */
export type Review_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Review_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Review_Files_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  review_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "review_files" */
export enum Review_Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReviewId = 'review_id',
}

export type Review_Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Files_Bool_Exp;
};

/** stores review information related to risks and vendors */
export type Reviews = {
  __typename?: 'reviews';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  review_date: Scalars['timestamptz']['output'];
  reviewed_by?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  reviews_review_files: Array<Review_Files>;
  /** An aggregate relationship */
  reviews_review_files_aggregate: Review_Files_Aggregate;
  /** An object relationship */
  reviews_risk?: Maybe<Risks>;
  /** An object relationship */
  reviews_user?: Maybe<Users>;
  /** An object relationship */
  reviews_vendor?: Maybe<Vendors>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** stores review information related to risks and vendors */
export type ReviewsReviews_Review_FilesArgs = {
  distinct_on?: InputMaybe<Array<Review_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Review_Files_Order_By>>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

/** stores review information related to risks and vendors */
export type ReviewsReviews_Review_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Review_Files_Order_By>>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

/** aggregated selection of "reviews" */
export type Reviews_Aggregate = {
  __typename?: 'reviews_aggregate';
  aggregate?: Maybe<Reviews_Aggregate_Fields>;
  nodes: Array<Reviews>;
};

export type Reviews_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reviews_Aggregate_Bool_Exp_Count>;
};

export type Reviews_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reviews_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reviews" */
export type Reviews_Aggregate_Fields = {
  __typename?: 'reviews_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Reviews_Max_Fields>;
  min?: Maybe<Reviews_Min_Fields>;
};

/** aggregate fields of "reviews" */
export type Reviews_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reviews_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reviews" */
export type Reviews_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reviews_Max_Order_By>;
  min?: InputMaybe<Reviews_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reviews" */
export type Reviews_Arr_Rel_Insert_Input = {
  data: Array<Reviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reviews". All fields are combined with a logical 'AND'. */
export type Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<Reviews_Bool_Exp>>;
  _not?: InputMaybe<Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Reviews_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  review_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  reviewed_by?: InputMaybe<Uuid_Comparison_Exp>;
  reviews_review_files?: InputMaybe<Review_Files_Bool_Exp>;
  reviews_review_files_aggregate?: InputMaybe<Review_Files_Aggregate_Bool_Exp>;
  reviews_risk?: InputMaybe<Risks_Bool_Exp>;
  reviews_user?: InputMaybe<Users_Bool_Exp>;
  reviews_vendor?: InputMaybe<Vendors_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reviews" */
export enum Reviews_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewsPkey = 'reviews_pkey',
}

/** input type for inserting data into table "reviews" */
export type Reviews_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  review_date?: InputMaybe<Scalars['timestamptz']['input']>;
  reviewed_by?: InputMaybe<Scalars['uuid']['input']>;
  reviews_review_files?: InputMaybe<Review_Files_Arr_Rel_Insert_Input>;
  reviews_risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  reviews_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  reviews_vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Reviews_Max_Fields = {
  __typename?: 'reviews_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  review_date?: Maybe<Scalars['timestamptz']['output']>;
  reviewed_by?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "reviews" */
export type Reviews_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  review_date?: InputMaybe<Order_By>;
  reviewed_by?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reviews_Min_Fields = {
  __typename?: 'reviews_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  review_date?: Maybe<Scalars['timestamptz']['output']>;
  reviewed_by?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "reviews" */
export type Reviews_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  review_date?: InputMaybe<Order_By>;
  reviewed_by?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reviews" */
export type Reviews_Mutation_Response = {
  __typename?: 'reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reviews>;
};

/** input type for inserting object relation for remote table "reviews" */
export type Reviews_Obj_Rel_Insert_Input = {
  data: Reviews_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** on_conflict condition type for table "reviews" */
export type Reviews_On_Conflict = {
  constraint: Reviews_Constraint;
  update_columns?: Array<Reviews_Update_Column>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "reviews". */
export type Reviews_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  review_date?: InputMaybe<Order_By>;
  reviewed_by?: InputMaybe<Order_By>;
  reviews_review_files_aggregate?: InputMaybe<Review_Files_Aggregate_Order_By>;
  reviews_risk?: InputMaybe<Risks_Order_By>;
  reviews_user?: InputMaybe<Users_Order_By>;
  reviews_vendor?: InputMaybe<Vendors_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reviews */
export type Reviews_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "reviews" */
export enum Reviews_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  ReviewDate = 'review_date',
  /** column name */
  ReviewedBy = 'reviewed_by',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "reviews" */
export type Reviews_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  review_date?: InputMaybe<Scalars['timestamptz']['input']>;
  reviewed_by?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "reviews" */
export type Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reviews_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  review_date?: InputMaybe<Scalars['timestamptz']['input']>;
  reviewed_by?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "reviews" */
export enum Reviews_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  ReviewDate = 'review_date',
  /** column name */
  ReviewedBy = 'reviewed_by',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
}

export type Reviews_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reviews_Bool_Exp;
};

/** columns and relationships of "risk_categories" */
export type Risk_Categories = {
  __typename?: 'risk_categories';
  /** An object relationship */
  category: Organization_Risk_Categories;
  category_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
};

/** aggregated selection of "risk_categories" */
export type Risk_Categories_Aggregate = {
  __typename?: 'risk_categories_aggregate';
  aggregate?: Maybe<Risk_Categories_Aggregate_Fields>;
  nodes: Array<Risk_Categories>;
};

export type Risk_Categories_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Categories_Aggregate_Bool_Exp_Count>;
};

export type Risk_Categories_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Categories_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_categories" */
export type Risk_Categories_Aggregate_Fields = {
  __typename?: 'risk_categories_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Categories_Max_Fields>;
  min?: Maybe<Risk_Categories_Min_Fields>;
};

/** aggregate fields of "risk_categories" */
export type Risk_Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_categories" */
export type Risk_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Categories_Max_Order_By>;
  min?: InputMaybe<Risk_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_categories" */
export type Risk_Categories_Arr_Rel_Insert_Input = {
  data: Array<Risk_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_categories". All fields are combined with a logical 'AND'. */
export type Risk_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Categories_Bool_Exp>>;
  _not?: InputMaybe<Risk_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Categories_Bool_Exp>>;
  category?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_categories" */
export enum Risk_Categories_Constraint {
  /** unique or primary key constraint on columns "risk_id", "category_id" */
  RiskCategoriesCategoryIdRiskIdKey = 'risk_categories_category_id_risk_id_key',
  /** unique or primary key constraint on columns "id" */
  RiskCategoriesPkey = 'risk_categories_pkey',
}

/** input type for inserting data into table "risk_categories" */
export type Risk_Categories_Insert_Input = {
  category?: InputMaybe<Organization_Risk_Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Risk_Categories_Max_Fields = {
  __typename?: 'risk_categories_max_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risk_categories" */
export type Risk_Categories_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Categories_Min_Fields = {
  __typename?: 'risk_categories_min_fields';
  category_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risk_categories" */
export type Risk_Categories_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_categories" */
export type Risk_Categories_Mutation_Response = {
  __typename?: 'risk_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Categories>;
};

/** on_conflict condition type for table "risk_categories" */
export type Risk_Categories_On_Conflict = {
  constraint: Risk_Categories_Constraint;
  update_columns?: Array<Risk_Categories_Update_Column>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_categories". */
export type Risk_Categories_Order_By = {
  category?: InputMaybe<Organization_Risk_Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_categories */
export type Risk_Categories_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_categories" */
export enum Risk_Categories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
}

/** input type for updating data in table "risk_categories" */
export type Risk_Categories_Set_Input = {
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "risk_categories" */
export type Risk_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Categories_Stream_Cursor_Value_Input = {
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risk_categories" */
export enum Risk_Categories_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
}

export type Risk_Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Categories_Bool_Exp;
};

/** join table for controls associated with risk */
export type Risk_Controls = {
  __typename?: 'risk_controls';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  is_control_primary: Scalars['Boolean']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "risk_controls" */
export type Risk_Controls_Aggregate = {
  __typename?: 'risk_controls_aggregate';
  aggregate?: Maybe<Risk_Controls_Aggregate_Fields>;
  nodes: Array<Risk_Controls>;
};

export type Risk_Controls_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Risk_Controls_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Risk_Controls_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Risk_Controls_Aggregate_Bool_Exp_Count>;
};

export type Risk_Controls_Aggregate_Bool_Exp_Bool_And = {
  arguments: Risk_Controls_Select_Column_Risk_Controls_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Controls_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Risk_Controls_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Risk_Controls_Select_Column_Risk_Controls_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Controls_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Risk_Controls_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Controls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_controls" */
export type Risk_Controls_Aggregate_Fields = {
  __typename?: 'risk_controls_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Controls_Max_Fields>;
  min?: Maybe<Risk_Controls_Min_Fields>;
};

/** aggregate fields of "risk_controls" */
export type Risk_Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_controls" */
export type Risk_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Controls_Max_Order_By>;
  min?: InputMaybe<Risk_Controls_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_controls" */
export type Risk_Controls_Arr_Rel_Insert_Input = {
  data: Array<Risk_Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_controls". All fields are combined with a logical 'AND'. */
export type Risk_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Controls_Bool_Exp>>;
  _not?: InputMaybe<Risk_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Controls_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_control_primary?: InputMaybe<Boolean_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_controls" */
export enum Risk_Controls_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskControlsPkey = 'risk_controls_pkey',
  /** unique or primary key constraint on columns "control_id", "risk_id" */
  RiskControlsRiskIdControlIdKey = 'risk_controls_risk_id_control_id_key',
}

/** input type for inserting data into table "risk_controls" */
export type Risk_Controls_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_control_primary?: InputMaybe<Scalars['Boolean']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Risk_Controls_Max_Fields = {
  __typename?: 'risk_controls_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risk_controls" */
export type Risk_Controls_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Controls_Min_Fields = {
  __typename?: 'risk_controls_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risk_controls" */
export type Risk_Controls_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_controls" */
export type Risk_Controls_Mutation_Response = {
  __typename?: 'risk_controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Controls>;
};

/** on_conflict condition type for table "risk_controls" */
export type Risk_Controls_On_Conflict = {
  constraint: Risk_Controls_Constraint;
  update_columns?: Array<Risk_Controls_Update_Column>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_controls". */
export type Risk_Controls_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_control_primary?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_controls */
export type Risk_Controls_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_controls" */
export enum Risk_Controls_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsControlPrimary = 'is_control_primary',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "risk_controls_aggregate_bool_exp_bool_and_arguments_columns" columns of table "risk_controls" */
export enum Risk_Controls_Select_Column_Risk_Controls_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsControlPrimary = 'is_control_primary',
}

/** select "risk_controls_aggregate_bool_exp_bool_or_arguments_columns" columns of table "risk_controls" */
export enum Risk_Controls_Select_Column_Risk_Controls_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsControlPrimary = 'is_control_primary',
}

/** input type for updating data in table "risk_controls" */
export type Risk_Controls_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_control_primary?: InputMaybe<Scalars['Boolean']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "risk_controls" */
export type Risk_Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Controls_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_control_primary?: InputMaybe<Scalars['Boolean']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "risk_controls" */
export enum Risk_Controls_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsControlPrimary = 'is_control_primary',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Risk_Controls_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Controls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Controls_Bool_Exp;
};

/** columns and relationships of "risk_documents" */
export type Risk_Documents = {
  __typename?: 'risk_documents';
  /** An object relationship */
  file?: Maybe<Files>;
  file_id: Scalars['uuid']['output'];
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id: Scalars['uuid']['output'];
};

/** aggregated selection of "risk_documents" */
export type Risk_Documents_Aggregate = {
  __typename?: 'risk_documents_aggregate';
  aggregate?: Maybe<Risk_Documents_Aggregate_Fields>;
  nodes: Array<Risk_Documents>;
};

export type Risk_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Documents_Aggregate_Bool_Exp_Count>;
};

export type Risk_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_documents" */
export type Risk_Documents_Aggregate_Fields = {
  __typename?: 'risk_documents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Documents_Max_Fields>;
  min?: Maybe<Risk_Documents_Min_Fields>;
};

/** aggregate fields of "risk_documents" */
export type Risk_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_documents" */
export type Risk_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Documents_Max_Order_By>;
  min?: InputMaybe<Risk_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_documents" */
export type Risk_Documents_Arr_Rel_Insert_Input = {
  data: Array<Risk_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_documents". All fields are combined with a logical 'AND'. */
export type Risk_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Documents_Bool_Exp>>;
  _not?: InputMaybe<Risk_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Documents_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_documents" */
export enum Risk_Documents_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  RiskDocumentsFileIdKey = 'risk_documents_file_id_key',
  /** unique or primary key constraint on columns "file_id", "risk_id" */
  RiskDocumentsPkey = 'risk_documents_pkey',
}

/** input type for inserting data into table "risk_documents" */
export type Risk_Documents_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Risk_Documents_Max_Fields = {
  __typename?: 'risk_documents_max_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risk_documents" */
export type Risk_Documents_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Documents_Min_Fields = {
  __typename?: 'risk_documents_min_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risk_documents" */
export type Risk_Documents_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_documents" */
export type Risk_Documents_Mutation_Response = {
  __typename?: 'risk_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Documents>;
};

/** input type for inserting object relation for remote table "risk_documents" */
export type Risk_Documents_Obj_Rel_Insert_Input = {
  data: Risk_Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Documents_On_Conflict>;
};

/** on_conflict condition type for table "risk_documents" */
export type Risk_Documents_On_Conflict = {
  constraint: Risk_Documents_Constraint;
  update_columns?: Array<Risk_Documents_Update_Column>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_documents". */
export type Risk_Documents_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_documents */
export type Risk_Documents_Pk_Columns_Input = {
  file_id: Scalars['uuid']['input'];
  risk_id: Scalars['uuid']['input'];
};

/** select columns of table "risk_documents" */
export enum Risk_Documents_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  RiskId = 'risk_id',
}

/** input type for updating data in table "risk_documents" */
export type Risk_Documents_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "risk_documents" */
export type Risk_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Documents_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risk_documents" */
export enum Risk_Documents_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  RiskId = 'risk_id',
}

export type Risk_Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Documents_Bool_Exp;
};

/** columns and relationships of "risk_impacts" */
export type Risk_Impacts = {
  __typename?: 'risk_impacts';
  created_at: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  inherent_risk_impacts: Array<Risks>;
  /** An aggregate relationship */
  inherent_risk_impacts_aggregate: Risks_Aggregate;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  residual_risk_impacts: Array<Risks>;
  /** An aggregate relationship */
  residual_risk_impacts_aggregate: Risks_Aggregate;
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** An aggregate relationship */
  risk_inherent_levels_aggregate: Risk_Inherent_Levels_Aggregate;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  /** An aggregate relationship */
  risk_residual_levels_aggregate: Risk_Residual_Levels_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsInherent_Risk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsInherent_Risk_Impacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsResidual_Risk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsResidual_Risk_Impacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsRisk_Inherent_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsRisk_Residual_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** aggregated selection of "risk_impacts" */
export type Risk_Impacts_Aggregate = {
  __typename?: 'risk_impacts_aggregate';
  aggregate?: Maybe<Risk_Impacts_Aggregate_Fields>;
  nodes: Array<Risk_Impacts>;
};

export type Risk_Impacts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Impacts_Aggregate_Bool_Exp_Count>;
};

export type Risk_Impacts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Impacts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_impacts" */
export type Risk_Impacts_Aggregate_Fields = {
  __typename?: 'risk_impacts_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Impacts_Max_Fields>;
  min?: Maybe<Risk_Impacts_Min_Fields>;
};

/** aggregate fields of "risk_impacts" */
export type Risk_Impacts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_impacts" */
export type Risk_Impacts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Impacts_Max_Order_By>;
  min?: InputMaybe<Risk_Impacts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_impacts" */
export type Risk_Impacts_Arr_Rel_Insert_Input = {
  data: Array<Risk_Impacts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Impacts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_impacts". All fields are combined with a logical 'AND'. */
export type Risk_Impacts_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Impacts_Bool_Exp>>;
  _not?: InputMaybe<Risk_Impacts_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Impacts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inherent_risk_impacts?: InputMaybe<Risks_Bool_Exp>;
  inherent_risk_impacts_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  residual_risk_impacts?: InputMaybe<Risks_Bool_Exp>;
  residual_risk_impacts_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  risk_inherent_levels?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
  risk_inherent_levels_aggregate?: InputMaybe<Risk_Inherent_Levels_Aggregate_Bool_Exp>;
  risk_residual_levels?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
  risk_residual_levels_aggregate?: InputMaybe<Risk_Residual_Levels_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_impacts" */
export enum Risk_Impacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationRiskImpactsPkey = 'organization_risk_impacts_pkey',
  /** unique or primary key constraint on columns "name", "organization_id" */
  RiskImpactsNameOrganizationIdKey = 'risk_impacts_name_organization_id_key',
  /** unique or primary key constraint on columns "order", "organization_id" */
  RiskImpactsOrganizationIdOrderKey = 'risk_impacts_organization_id_order_key',
}

/** input type for inserting data into table "risk_impacts" */
export type Risk_Impacts_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_risk_impacts?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_risk_impacts?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  risk_inherent_levels?: InputMaybe<Risk_Inherent_Levels_Arr_Rel_Insert_Input>;
  risk_residual_levels?: InputMaybe<Risk_Residual_Levels_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Risk_Impacts_Max_Fields = {
  __typename?: 'risk_impacts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risk_impacts" */
export type Risk_Impacts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Impacts_Min_Fields = {
  __typename?: 'risk_impacts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risk_impacts" */
export type Risk_Impacts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_impacts" */
export type Risk_Impacts_Mutation_Response = {
  __typename?: 'risk_impacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Impacts>;
};

/** input type for inserting object relation for remote table "risk_impacts" */
export type Risk_Impacts_Obj_Rel_Insert_Input = {
  data: Risk_Impacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Impacts_On_Conflict>;
};

/** on_conflict condition type for table "risk_impacts" */
export type Risk_Impacts_On_Conflict = {
  constraint: Risk_Impacts_Constraint;
  update_columns?: Array<Risk_Impacts_Update_Column>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_impacts". */
export type Risk_Impacts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_risk_impacts_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_risk_impacts_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  risk_inherent_levels_aggregate?: InputMaybe<Risk_Inherent_Levels_Aggregate_Order_By>;
  risk_residual_levels_aggregate?: InputMaybe<Risk_Residual_Levels_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_impacts */
export type Risk_Impacts_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_impacts" */
export enum Risk_Impacts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "risk_impacts" */
export type Risk_Impacts_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "risk_impacts" */
export type Risk_Impacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Impacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Impacts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "risk_impacts" */
export enum Risk_Impacts_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Risk_Impacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Impacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Impacts_Bool_Exp;
};

/** columns and relationships of "risk_inherent_levels" */
export type Risk_Inherent_Levels = {
  __typename?: 'risk_inherent_levels';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  level: Risk_Levels_Enum;
  /** An object relationship */
  risk_impact: Risk_Impacts;
  risk_impact_id: Scalars['uuid']['output'];
  /** An object relationship */
  risk_level: Risk_Levels;
  /** An object relationship */
  risk_likelihood: Risk_Likelihoods;
  risk_likelihood_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "risk_inherent_levels" */
export type Risk_Inherent_Levels_Aggregate = {
  __typename?: 'risk_inherent_levels_aggregate';
  aggregate?: Maybe<Risk_Inherent_Levels_Aggregate_Fields>;
  nodes: Array<Risk_Inherent_Levels>;
};

export type Risk_Inherent_Levels_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Inherent_Levels_Aggregate_Bool_Exp_Count>;
};

export type Risk_Inherent_Levels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_inherent_levels" */
export type Risk_Inherent_Levels_Aggregate_Fields = {
  __typename?: 'risk_inherent_levels_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Inherent_Levels_Max_Fields>;
  min?: Maybe<Risk_Inherent_Levels_Min_Fields>;
};

/** aggregate fields of "risk_inherent_levels" */
export type Risk_Inherent_Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Inherent_Levels_Max_Order_By>;
  min?: InputMaybe<Risk_Inherent_Levels_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Arr_Rel_Insert_Input = {
  data: Array<Risk_Inherent_Levels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Inherent_Levels_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_inherent_levels". All fields are combined with a logical 'AND'. */
export type Risk_Inherent_Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Inherent_Levels_Bool_Exp>>;
  _not?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Inherent_Levels_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<Risk_Levels_Enum_Comparison_Exp>;
  risk_impact?: InputMaybe<Risk_Impacts_Bool_Exp>;
  risk_impact_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_level?: InputMaybe<Risk_Levels_Bool_Exp>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  risk_likelihood_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_inherent_levels" */
export enum Risk_Inherent_Levels_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskInherentLevelsPkey = 'risk_inherent_levels_pkey',
  /** unique or primary key constraint on columns "risk_likelihood_id", "risk_impact_id" */
  RiskInherentLevelsRiskImpactIdRiskLikelihoodIdKey = 'risk_inherent_levels_risk_impact_id_risk_likelihood_id_key',
}

/** input type for inserting data into table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<Risk_Levels_Enum>;
  risk_impact?: InputMaybe<Risk_Impacts_Obj_Rel_Insert_Input>;
  risk_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_level?: InputMaybe<Risk_Levels_Obj_Rel_Insert_Input>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Obj_Rel_Insert_Input>;
  risk_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Risk_Inherent_Levels_Max_Fields = {
  __typename?: 'risk_inherent_levels_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_impact_id?: Maybe<Scalars['uuid']['output']>;
  risk_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_impact_id?: InputMaybe<Order_By>;
  risk_likelihood_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Inherent_Levels_Min_Fields = {
  __typename?: 'risk_inherent_levels_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_impact_id?: Maybe<Scalars['uuid']['output']>;
  risk_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_impact_id?: InputMaybe<Order_By>;
  risk_likelihood_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Mutation_Response = {
  __typename?: 'risk_inherent_levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Inherent_Levels>;
};

/** on_conflict condition type for table "risk_inherent_levels" */
export type Risk_Inherent_Levels_On_Conflict = {
  constraint: Risk_Inherent_Levels_Constraint;
  update_columns?: Array<Risk_Inherent_Levels_Update_Column>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_inherent_levels". */
export type Risk_Inherent_Levels_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  risk_impact?: InputMaybe<Risk_Impacts_Order_By>;
  risk_impact_id?: InputMaybe<Order_By>;
  risk_level?: InputMaybe<Risk_Levels_Order_By>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Order_By>;
  risk_likelihood_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_inherent_levels */
export type Risk_Inherent_Levels_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_inherent_levels" */
export enum Risk_Inherent_Levels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  RiskImpactId = 'risk_impact_id',
  /** column name */
  RiskLikelihoodId = 'risk_likelihood_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<Risk_Levels_Enum>;
  risk_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Inherent_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Inherent_Levels_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<Risk_Levels_Enum>;
  risk_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "risk_inherent_levels" */
export enum Risk_Inherent_Levels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  RiskImpactId = 'risk_impact_id',
  /** column name */
  RiskLikelihoodId = 'risk_likelihood_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Risk_Inherent_Levels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Inherent_Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Inherent_Levels_Bool_Exp;
};

/** columns and relationships of "risk_levels" */
export type Risk_Levels = {
  __typename?: 'risk_levels';
  name: Scalars['String']['output'];
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** An aggregate relationship */
  risk_inherent_levels_aggregate: Risk_Inherent_Levels_Aggregate;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  /** An aggregate relationship */
  risk_residual_levels_aggregate: Risk_Residual_Levels_Aggregate;
};

/** columns and relationships of "risk_levels" */
export type Risk_LevelsRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_levels" */
export type Risk_LevelsRisk_Inherent_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_levels" */
export type Risk_LevelsRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_levels" */
export type Risk_LevelsRisk_Residual_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** aggregated selection of "risk_levels" */
export type Risk_Levels_Aggregate = {
  __typename?: 'risk_levels_aggregate';
  aggregate?: Maybe<Risk_Levels_Aggregate_Fields>;
  nodes: Array<Risk_Levels>;
};

/** aggregate fields of "risk_levels" */
export type Risk_Levels_Aggregate_Fields = {
  __typename?: 'risk_levels_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Levels_Max_Fields>;
  min?: Maybe<Risk_Levels_Min_Fields>;
};

/** aggregate fields of "risk_levels" */
export type Risk_Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risk_levels". All fields are combined with a logical 'AND'. */
export type Risk_Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Levels_Bool_Exp>>;
  _not?: InputMaybe<Risk_Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Levels_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  risk_inherent_levels?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
  risk_inherent_levels_aggregate?: InputMaybe<Risk_Inherent_Levels_Aggregate_Bool_Exp>;
  risk_residual_levels?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
  risk_residual_levels_aggregate?: InputMaybe<Risk_Residual_Levels_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risk_levels" */
export enum Risk_Levels_Constraint {
  /** unique or primary key constraint on columns "name" */
  RiskLevelsPkey = 'risk_levels_pkey',
}

export enum Risk_Levels_Enum {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  VeryHigh = 'very_high',
  VeryLow = 'very_low',
}

/** Boolean expression to compare columns of type "risk_levels_enum". All fields are combined with logical 'AND'. */
export type Risk_Levels_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Risk_Levels_Enum>;
  _in?: InputMaybe<Array<Risk_Levels_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Risk_Levels_Enum>;
  _nin?: InputMaybe<Array<Risk_Levels_Enum>>;
};

/** input type for inserting data into table "risk_levels" */
export type Risk_Levels_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  risk_inherent_levels?: InputMaybe<Risk_Inherent_Levels_Arr_Rel_Insert_Input>;
  risk_residual_levels?: InputMaybe<Risk_Residual_Levels_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Risk_Levels_Max_Fields = {
  __typename?: 'risk_levels_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Risk_Levels_Min_Fields = {
  __typename?: 'risk_levels_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risk_levels" */
export type Risk_Levels_Mutation_Response = {
  __typename?: 'risk_levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Levels>;
};

/** input type for inserting object relation for remote table "risk_levels" */
export type Risk_Levels_Obj_Rel_Insert_Input = {
  data: Risk_Levels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Levels_On_Conflict>;
};

/** on_conflict condition type for table "risk_levels" */
export type Risk_Levels_On_Conflict = {
  constraint: Risk_Levels_Constraint;
  update_columns?: Array<Risk_Levels_Update_Column>;
  where?: InputMaybe<Risk_Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_levels". */
export type Risk_Levels_Order_By = {
  name?: InputMaybe<Order_By>;
  risk_inherent_levels_aggregate?: InputMaybe<Risk_Inherent_Levels_Aggregate_Order_By>;
  risk_residual_levels_aggregate?: InputMaybe<Risk_Residual_Levels_Aggregate_Order_By>;
};

/** primary key columns input for table: risk_levels */
export type Risk_Levels_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "risk_levels" */
export enum Risk_Levels_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "risk_levels" */
export type Risk_Levels_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risk_levels" */
export type Risk_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Levels_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risk_levels" */
export enum Risk_Levels_Update_Column {
  /** column name */
  Name = 'name',
}

export type Risk_Levels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Levels_Bool_Exp;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_Likelihoods = {
  __typename?: 'risk_likelihoods';
  created_at: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  inherent_risk_likelihoods: Array<Risks>;
  /** An aggregate relationship */
  inherent_risk_likelihoods_aggregate: Risks_Aggregate;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  residual_risk_likelihoods: Array<Risks>;
  /** An aggregate relationship */
  residual_risk_likelihoods_aggregate: Risks_Aggregate;
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** An aggregate relationship */
  risk_inherent_levels_aggregate: Risk_Inherent_Levels_Aggregate;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  /** An aggregate relationship */
  risk_residual_levels_aggregate: Risk_Residual_Levels_Aggregate;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsInherent_Risk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsInherent_Risk_Likelihoods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsResidual_Risk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsResidual_Risk_Likelihoods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsRisk_Inherent_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsRisk_Residual_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** aggregated selection of "risk_likelihoods" */
export type Risk_Likelihoods_Aggregate = {
  __typename?: 'risk_likelihoods_aggregate';
  aggregate?: Maybe<Risk_Likelihoods_Aggregate_Fields>;
  nodes: Array<Risk_Likelihoods>;
};

export type Risk_Likelihoods_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Likelihoods_Aggregate_Bool_Exp_Count>;
};

export type Risk_Likelihoods_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_likelihoods" */
export type Risk_Likelihoods_Aggregate_Fields = {
  __typename?: 'risk_likelihoods_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Likelihoods_Max_Fields>;
  min?: Maybe<Risk_Likelihoods_Min_Fields>;
};

/** aggregate fields of "risk_likelihoods" */
export type Risk_Likelihoods_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_likelihoods" */
export type Risk_Likelihoods_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Likelihoods_Max_Order_By>;
  min?: InputMaybe<Risk_Likelihoods_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_likelihoods" */
export type Risk_Likelihoods_Arr_Rel_Insert_Input = {
  data: Array<Risk_Likelihoods_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Likelihoods_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_likelihoods". All fields are combined with a logical 'AND'. */
export type Risk_Likelihoods_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Likelihoods_Bool_Exp>>;
  _not?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Likelihoods_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inherent_risk_likelihoods?: InputMaybe<Risks_Bool_Exp>;
  inherent_risk_likelihoods_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  residual_risk_likelihoods?: InputMaybe<Risks_Bool_Exp>;
  residual_risk_likelihoods_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  risk_inherent_levels?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
  risk_inherent_levels_aggregate?: InputMaybe<Risk_Inherent_Levels_Aggregate_Bool_Exp>;
  risk_residual_levels?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
  risk_residual_levels_aggregate?: InputMaybe<Risk_Residual_Levels_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_likelihoods" */
export enum Risk_Likelihoods_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationRiskLikelihoodsPkey = 'organization_risk_likelihoods_pkey',
  /** unique or primary key constraint on columns "name", "organization_id" */
  RiskLikelihoodsNameOrganizationIdKey = 'risk_likelihoods_name_organization_id_key',
  /** unique or primary key constraint on columns "order", "organization_id" */
  RiskLikelihoodsOrganizationIdOrderKey = 'risk_likelihoods_organization_id_order_key',
}

/** input type for inserting data into table "risk_likelihoods" */
export type Risk_Likelihoods_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_risk_likelihoods?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_risk_likelihoods?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  risk_inherent_levels?: InputMaybe<Risk_Inherent_Levels_Arr_Rel_Insert_Input>;
  risk_residual_levels?: InputMaybe<Risk_Residual_Levels_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Risk_Likelihoods_Max_Fields = {
  __typename?: 'risk_likelihoods_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risk_likelihoods" */
export type Risk_Likelihoods_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Likelihoods_Min_Fields = {
  __typename?: 'risk_likelihoods_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risk_likelihoods" */
export type Risk_Likelihoods_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_likelihoods" */
export type Risk_Likelihoods_Mutation_Response = {
  __typename?: 'risk_likelihoods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Likelihoods>;
};

/** input type for inserting object relation for remote table "risk_likelihoods" */
export type Risk_Likelihoods_Obj_Rel_Insert_Input = {
  data: Risk_Likelihoods_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Likelihoods_On_Conflict>;
};

/** on_conflict condition type for table "risk_likelihoods" */
export type Risk_Likelihoods_On_Conflict = {
  constraint: Risk_Likelihoods_Constraint;
  update_columns?: Array<Risk_Likelihoods_Update_Column>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_likelihoods". */
export type Risk_Likelihoods_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_risk_likelihoods_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_risk_likelihoods_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  risk_inherent_levels_aggregate?: InputMaybe<Risk_Inherent_Levels_Aggregate_Order_By>;
  risk_residual_levels_aggregate?: InputMaybe<Risk_Residual_Levels_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_likelihoods */
export type Risk_Likelihoods_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_likelihoods" */
export enum Risk_Likelihoods_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "risk_likelihoods" */
export type Risk_Likelihoods_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "risk_likelihoods" */
export type Risk_Likelihoods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Likelihoods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Likelihoods_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "risk_likelihoods" */
export enum Risk_Likelihoods_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Risk_Likelihoods_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Likelihoods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Likelihoods_Bool_Exp;
};

/** columns and relationships of "risk_residual_levels" */
export type Risk_Residual_Levels = {
  __typename?: 'risk_residual_levels';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  level: Risk_Levels_Enum;
  /** An object relationship */
  risk_impact: Risk_Impacts;
  risk_impact_id: Scalars['uuid']['output'];
  /** An object relationship */
  risk_level: Risk_Levels;
  /** An object relationship */
  risk_likelihood: Risk_Likelihoods;
  risk_likelihood_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "risk_residual_levels" */
export type Risk_Residual_Levels_Aggregate = {
  __typename?: 'risk_residual_levels_aggregate';
  aggregate?: Maybe<Risk_Residual_Levels_Aggregate_Fields>;
  nodes: Array<Risk_Residual_Levels>;
};

export type Risk_Residual_Levels_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Residual_Levels_Aggregate_Bool_Exp_Count>;
};

export type Risk_Residual_Levels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_residual_levels" */
export type Risk_Residual_Levels_Aggregate_Fields = {
  __typename?: 'risk_residual_levels_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Residual_Levels_Max_Fields>;
  min?: Maybe<Risk_Residual_Levels_Min_Fields>;
};

/** aggregate fields of "risk_residual_levels" */
export type Risk_Residual_Levels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_residual_levels" */
export type Risk_Residual_Levels_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Residual_Levels_Max_Order_By>;
  min?: InputMaybe<Risk_Residual_Levels_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_residual_levels" */
export type Risk_Residual_Levels_Arr_Rel_Insert_Input = {
  data: Array<Risk_Residual_Levels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Residual_Levels_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_residual_levels". All fields are combined with a logical 'AND'. */
export type Risk_Residual_Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Residual_Levels_Bool_Exp>>;
  _not?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Residual_Levels_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<Risk_Levels_Enum_Comparison_Exp>;
  risk_impact?: InputMaybe<Risk_Impacts_Bool_Exp>;
  risk_impact_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_level?: InputMaybe<Risk_Levels_Bool_Exp>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  risk_likelihood_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_residual_levels" */
export enum Risk_Residual_Levels_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskResidualLevelsPkey = 'risk_residual_levels_pkey',
  /** unique or primary key constraint on columns "risk_likelihood_id", "risk_impact_id" */
  RiskResidualLevelsRiskImpactIdRiskLikelihoodIdKey = 'risk_residual_levels_risk_impact_id_risk_likelihood_id_key',
}

/** input type for inserting data into table "risk_residual_levels" */
export type Risk_Residual_Levels_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<Risk_Levels_Enum>;
  risk_impact?: InputMaybe<Risk_Impacts_Obj_Rel_Insert_Input>;
  risk_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_level?: InputMaybe<Risk_Levels_Obj_Rel_Insert_Input>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Obj_Rel_Insert_Input>;
  risk_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Risk_Residual_Levels_Max_Fields = {
  __typename?: 'risk_residual_levels_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_impact_id?: Maybe<Scalars['uuid']['output']>;
  risk_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risk_residual_levels" */
export type Risk_Residual_Levels_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_impact_id?: InputMaybe<Order_By>;
  risk_likelihood_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Residual_Levels_Min_Fields = {
  __typename?: 'risk_residual_levels_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_impact_id?: Maybe<Scalars['uuid']['output']>;
  risk_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risk_residual_levels" */
export type Risk_Residual_Levels_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_impact_id?: InputMaybe<Order_By>;
  risk_likelihood_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_residual_levels" */
export type Risk_Residual_Levels_Mutation_Response = {
  __typename?: 'risk_residual_levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Residual_Levels>;
};

/** on_conflict condition type for table "risk_residual_levels" */
export type Risk_Residual_Levels_On_Conflict = {
  constraint: Risk_Residual_Levels_Constraint;
  update_columns?: Array<Risk_Residual_Levels_Update_Column>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_residual_levels". */
export type Risk_Residual_Levels_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  risk_impact?: InputMaybe<Risk_Impacts_Order_By>;
  risk_impact_id?: InputMaybe<Order_By>;
  risk_level?: InputMaybe<Risk_Levels_Order_By>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Order_By>;
  risk_likelihood_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_residual_levels */
export type Risk_Residual_Levels_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_residual_levels" */
export enum Risk_Residual_Levels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  RiskImpactId = 'risk_impact_id',
  /** column name */
  RiskLikelihoodId = 'risk_likelihood_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "risk_residual_levels" */
export type Risk_Residual_Levels_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<Risk_Levels_Enum>;
  risk_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "risk_residual_levels" */
export type Risk_Residual_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Residual_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Residual_Levels_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<Risk_Levels_Enum>;
  risk_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "risk_residual_levels" */
export enum Risk_Residual_Levels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  RiskImpactId = 'risk_impact_id',
  /** column name */
  RiskLikelihoodId = 'risk_likelihood_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Risk_Residual_Levels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Residual_Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Residual_Levels_Bool_Exp;
};

/** columns and relationships of "risk_tags" */
export type Risk_Tags = {
  __typename?: 'risk_tags';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
  /** An object relationship */
  tag: Organization_Risk_Tags;
  tag_id: Scalars['uuid']['output'];
};

/** aggregated selection of "risk_tags" */
export type Risk_Tags_Aggregate = {
  __typename?: 'risk_tags_aggregate';
  aggregate?: Maybe<Risk_Tags_Aggregate_Fields>;
  nodes: Array<Risk_Tags>;
};

export type Risk_Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Tags_Aggregate_Bool_Exp_Count>;
};

export type Risk_Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_tags" */
export type Risk_Tags_Aggregate_Fields = {
  __typename?: 'risk_tags_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Tags_Max_Fields>;
  min?: Maybe<Risk_Tags_Min_Fields>;
};

/** aggregate fields of "risk_tags" */
export type Risk_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_tags" */
export type Risk_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Tags_Max_Order_By>;
  min?: InputMaybe<Risk_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_tags" */
export type Risk_Tags_Arr_Rel_Insert_Input = {
  data: Array<Risk_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_tags". All fields are combined with a logical 'AND'. */
export type Risk_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Tags_Bool_Exp>>;
  _not?: InputMaybe<Risk_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Tags_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_tags" */
export enum Risk_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskTagsPkey = 'risk_tags_pkey',
  /** unique or primary key constraint on columns "risk_id", "tag_id" */
  RiskTagsTagIdRiskIdKey = 'risk_tags_tag_id_risk_id_key',
}

/** input type for inserting data into table "risk_tags" */
export type Risk_Tags_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Organization_Risk_Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Risk_Tags_Max_Fields = {
  __typename?: 'risk_tags_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  tag_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risk_tags" */
export type Risk_Tags_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Tags_Min_Fields = {
  __typename?: 'risk_tags_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  tag_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risk_tags" */
export type Risk_Tags_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_tags" */
export type Risk_Tags_Mutation_Response = {
  __typename?: 'risk_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Tags>;
};

/** on_conflict condition type for table "risk_tags" */
export type Risk_Tags_On_Conflict = {
  constraint: Risk_Tags_Constraint;
  update_columns?: Array<Risk_Tags_Update_Column>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_tags". */
export type Risk_Tags_Order_By = {
  id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Organization_Risk_Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_tags */
export type Risk_Tags_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_tags" */
export enum Risk_Tags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TagId = 'tag_id',
}

/** input type for updating data in table "risk_tags" */
export type Risk_Tags_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "risk_tags" */
export type Risk_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Tags_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risk_tags" */
export enum Risk_Tags_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TagId = 'tag_id',
}

export type Risk_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Tags_Bool_Exp;
};

/** Stores list of tasks linked to risks */
export type Risk_Tasks = {
  __typename?: 'risk_tasks';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid']['output'];
};

/** aggregated selection of "risk_tasks" */
export type Risk_Tasks_Aggregate = {
  __typename?: 'risk_tasks_aggregate';
  aggregate?: Maybe<Risk_Tasks_Aggregate_Fields>;
  nodes: Array<Risk_Tasks>;
};

export type Risk_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Risk_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Risk_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risk_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "risk_tasks" */
export type Risk_Tasks_Aggregate_Fields = {
  __typename?: 'risk_tasks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Risk_Tasks_Max_Fields>;
  min?: Maybe<Risk_Tasks_Min_Fields>;
};

/** aggregate fields of "risk_tasks" */
export type Risk_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risk_tasks" */
export type Risk_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Tasks_Max_Order_By>;
  min?: InputMaybe<Risk_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_tasks" */
export type Risk_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Risk_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_tasks". All fields are combined with a logical 'AND'. */
export type Risk_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Risk_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Tasks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_tasks" */
export enum Risk_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskTasksPkey = 'risk_tasks_pkey',
  /** unique or primary key constraint on columns "task_id", "risk_id" */
  RiskTasksRiskIdTaskIdKey = 'risk_tasks_risk_id_task_id_key',
}

/** input type for inserting data into table "risk_tasks" */
export type Risk_Tasks_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Risk_Tasks_Max_Fields = {
  __typename?: 'risk_tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risk_tasks" */
export type Risk_Tasks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risk_Tasks_Min_Fields = {
  __typename?: 'risk_tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risk_tasks" */
export type Risk_Tasks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_tasks" */
export type Risk_Tasks_Mutation_Response = {
  __typename?: 'risk_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Tasks>;
};

/** on_conflict condition type for table "risk_tasks" */
export type Risk_Tasks_On_Conflict = {
  constraint: Risk_Tasks_Constraint;
  update_columns?: Array<Risk_Tasks_Update_Column>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_tasks". */
export type Risk_Tasks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_tasks */
export type Risk_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_tasks" */
export enum Risk_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
}

/** input type for updating data in table "risk_tasks" */
export type Risk_Tasks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "risk_tasks" */
export type Risk_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Tasks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risk_tasks" */
export enum Risk_Tasks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
}

export type Risk_Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Tasks_Bool_Exp;
};

/** table contains list of risks related to organizations */
export type Risks = {
  __typename?: 'risks';
  assessment_status: Scalars['RiskAssessmentStatus']['output'];
  /** An object relationship */
  assignee?: Maybe<Users>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  categories: Array<Risk_Categories>;
  /** An aggregate relationship */
  categories_aggregate: Risk_Categories_Aggregate;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  /** An aggregate relationship */
  control_findings_aggregate: Control_Findings_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<Risk_Documents>;
  /** An aggregate relationship */
  documents_aggregate: Risk_Documents_Aggregate;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  /** An object relationship */
  frequency: Frequencies;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  inherent_impact?: Maybe<Risk_Impacts>;
  inherent_impact_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  inherent_likelihood?: Maybe<Risk_Likelihoods>;
  inherent_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  residual_impact?: Maybe<Risk_Impacts>;
  residual_impact_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  residual_likelihood?: Maybe<Risk_Likelihoods>;
  residual_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  review_frequency: Frequencies_Enum;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** An array relationship */
  risk_controls: Array<Risk_Controls>;
  /** An aggregate relationship */
  risk_controls_aggregate: Risk_Controls_Aggregate;
  /** An object relationship */
  risk_treatment_plan: Treatment_Plan;
  /** An array relationship */
  risks_comments: Array<Comments>;
  /** An aggregate relationship */
  risks_comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  risks_notifications: Array<Notifications>;
  /** An aggregate relationship */
  risks_notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  risks_vector_store?: Maybe<Ai_Risks_Vector_Store>;
  similarity?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  tags: Array<Risk_Tags>;
  /** An aggregate relationship */
  tags_aggregate: Risk_Tags_Aggregate;
  /** An array relationship */
  tasks: Array<Risk_Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Risk_Tasks_Aggregate;
  treatment_details?: Maybe<Scalars['String']['output']>;
  treatment_plan: Treatment_Plan_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_finding?: Maybe<Vendor_Findings>;
  /** An array relationship */
  vendor_risks: Array<Vendor_Risks>;
  /** An aggregate relationship */
  vendor_risks_aggregate: Vendor_Risks_Aggregate;
};

/** table contains list of risks related to organizations */
export type RisksCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksControl_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Documents_Order_By>>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Documents_Order_By>>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisk_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisk_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisks_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisks_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisks_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksTagsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksTasksArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksVendor_RisksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksVendor_Risks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** aggregated selection of "risks" */
export type Risks_Aggregate = {
  __typename?: 'risks_aggregate';
  aggregate?: Maybe<Risks_Aggregate_Fields>;
  nodes: Array<Risks>;
};

export type Risks_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Risks_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Risks_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Risks_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Risks_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Risks_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Risks_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Risks_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Risks_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Risks_Aggregate_Bool_Exp_Var_Samp>;
};

export type Risks_Aggregate_Bool_Exp_Avg = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Corr = {
  arguments: Risks_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Risks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Risks_Aggregate_Bool_Exp_Max = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Min = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Sum = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "risks" */
export type Risks_Aggregate_Fields = {
  __typename?: 'risks_aggregate_fields';
  avg?: Maybe<Risks_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Risks_Max_Fields>;
  min?: Maybe<Risks_Min_Fields>;
  stddev?: Maybe<Risks_Stddev_Fields>;
  stddev_pop?: Maybe<Risks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Risks_Stddev_Samp_Fields>;
  sum?: Maybe<Risks_Sum_Fields>;
  var_pop?: Maybe<Risks_Var_Pop_Fields>;
  var_samp?: Maybe<Risks_Var_Samp_Fields>;
  variance?: Maybe<Risks_Variance_Fields>;
};

/** aggregate fields of "risks" */
export type Risks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risks" */
export type Risks_Aggregate_Order_By = {
  avg?: InputMaybe<Risks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risks_Max_Order_By>;
  min?: InputMaybe<Risks_Min_Order_By>;
  stddev?: InputMaybe<Risks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Risks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Risks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Risks_Sum_Order_By>;
  var_pop?: InputMaybe<Risks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Risks_Var_Samp_Order_By>;
  variance?: InputMaybe<Risks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risks" */
export type Risks_Arr_Rel_Insert_Input = {
  data: Array<Risks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risks_On_Conflict>;
};

/** aggregate avg on columns */
export type Risks_Avg_Fields = {
  __typename?: 'risks_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risks" */
export type Risks_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risks". All fields are combined with a logical 'AND'. */
export type Risks_Bool_Exp = {
  _and?: InputMaybe<Array<Risks_Bool_Exp>>;
  _not?: InputMaybe<Risks_Bool_Exp>;
  _or?: InputMaybe<Array<Risks_Bool_Exp>>;
  assessment_status?: InputMaybe<String_Comparison_Exp>;
  assignee?: InputMaybe<Users_Bool_Exp>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  categories?: InputMaybe<Risk_Categories_Bool_Exp>;
  categories_aggregate?: InputMaybe<Risk_Categories_Aggregate_Bool_Exp>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Risk_Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Risk_Documents_Aggregate_Bool_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Bool_Exp>;
  frequency?: InputMaybe<Frequencies_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inherent_impact?: InputMaybe<Risk_Impacts_Bool_Exp>;
  inherent_impact_id?: InputMaybe<Uuid_Comparison_Exp>;
  inherent_likelihood?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  inherent_likelihood_id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  residual_impact?: InputMaybe<Risk_Impacts_Bool_Exp>;
  residual_impact_id?: InputMaybe<Uuid_Comparison_Exp>;
  residual_likelihood?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  residual_likelihood_id?: InputMaybe<Uuid_Comparison_Exp>;
  review_frequency?: InputMaybe<Frequencies_Enum_Comparison_Exp>;
  reviews?: InputMaybe<Reviews_Bool_Exp>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Bool_Exp>;
  risk_controls?: InputMaybe<Risk_Controls_Bool_Exp>;
  risk_controls_aggregate?: InputMaybe<Risk_Controls_Aggregate_Bool_Exp>;
  risk_treatment_plan?: InputMaybe<Treatment_Plan_Bool_Exp>;
  risks_comments?: InputMaybe<Comments_Bool_Exp>;
  risks_comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  risks_notifications?: InputMaybe<Notifications_Bool_Exp>;
  risks_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  risks_vector_store?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  tags?: InputMaybe<Risk_Tags_Bool_Exp>;
  tags_aggregate?: InputMaybe<Risk_Tags_Aggregate_Bool_Exp>;
  tasks?: InputMaybe<Risk_Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Risk_Tasks_Aggregate_Bool_Exp>;
  treatment_details?: InputMaybe<String_Comparison_Exp>;
  treatment_plan?: InputMaybe<Treatment_Plan_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_finding?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendor_risks?: InputMaybe<Vendor_Risks_Bool_Exp>;
  vendor_risks_aggregate?: InputMaybe<Vendor_Risks_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "risks" */
export enum Risks_Constraint {
  /** unique or primary key constraint on columns "id" */
  RisksPkey = 'risks_pkey',
}

/** input type for incrementing numeric columns in table "risks" */
export type Risks_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "risks" */
export type Risks_Insert_Input = {
  assessment_status?: InputMaybe<Scalars['String']['input']>;
  assignee?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  categories?: InputMaybe<Risk_Categories_Arr_Rel_Insert_Input>;
  control_findings?: InputMaybe<Control_Findings_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Risk_Documents_Arr_Rel_Insert_Input>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  frequency?: InputMaybe<Frequencies_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_impact?: InputMaybe<Risk_Impacts_Obj_Rel_Insert_Input>;
  inherent_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_likelihood?: InputMaybe<Risk_Likelihoods_Obj_Rel_Insert_Input>;
  inherent_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_impact?: InputMaybe<Risk_Impacts_Obj_Rel_Insert_Input>;
  residual_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_likelihood?: InputMaybe<Risk_Likelihoods_Obj_Rel_Insert_Input>;
  residual_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  risk_controls?: InputMaybe<Risk_Controls_Arr_Rel_Insert_Input>;
  risk_treatment_plan?: InputMaybe<Treatment_Plan_Obj_Rel_Insert_Input>;
  risks_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  risks_notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  risks_vector_store?: InputMaybe<Ai_Risks_Vector_Store_Obj_Rel_Insert_Input>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  tags?: InputMaybe<Risk_Tags_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Risk_Tasks_Arr_Rel_Insert_Input>;
  treatment_details?: InputMaybe<Scalars['String']['input']>;
  treatment_plan?: InputMaybe<Treatment_Plan_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_finding?: InputMaybe<Vendor_Findings_Obj_Rel_Insert_Input>;
  vendor_risks?: InputMaybe<Vendor_Risks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Risks_Max_Fields = {
  __typename?: 'risks_max_fields';
  assessment_status?: Maybe<Scalars['String']['output']>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inherent_impact_id?: Maybe<Scalars['uuid']['output']>;
  inherent_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  residual_impact_id?: Maybe<Scalars['uuid']['output']>;
  residual_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  treatment_details?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risks" */
export type Risks_Max_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_impact_id?: InputMaybe<Order_By>;
  inherent_likelihood_id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_impact_id?: InputMaybe<Order_By>;
  residual_likelihood_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  treatment_details?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risks_Min_Fields = {
  __typename?: 'risks_min_fields';
  assessment_status?: Maybe<Scalars['String']['output']>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inherent_impact_id?: Maybe<Scalars['uuid']['output']>;
  inherent_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  residual_impact_id?: Maybe<Scalars['uuid']['output']>;
  residual_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  treatment_details?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risks" */
export type Risks_Min_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_impact_id?: InputMaybe<Order_By>;
  inherent_likelihood_id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_impact_id?: InputMaybe<Order_By>;
  residual_likelihood_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  treatment_details?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risks" */
export type Risks_Mutation_Response = {
  __typename?: 'risks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risks>;
};

/** input type for inserting object relation for remote table "risks" */
export type Risks_Obj_Rel_Insert_Input = {
  data: Risks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risks_On_Conflict>;
};

/** on_conflict condition type for table "risks" */
export type Risks_On_Conflict = {
  constraint: Risks_Constraint;
  update_columns?: Array<Risks_Update_Column>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** Ordering options when selecting data from "risks". */
export type Risks_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  assignee?: InputMaybe<Users_Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  categories_aggregate?: InputMaybe<Risk_Categories_Aggregate_Order_By>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Risk_Documents_Aggregate_Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  frequency?: InputMaybe<Frequencies_Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_impact?: InputMaybe<Risk_Impacts_Order_By>;
  inherent_impact_id?: InputMaybe<Order_By>;
  inherent_likelihood?: InputMaybe<Risk_Likelihoods_Order_By>;
  inherent_likelihood_id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_impact?: InputMaybe<Risk_Impacts_Order_By>;
  residual_impact_id?: InputMaybe<Order_By>;
  residual_likelihood?: InputMaybe<Risk_Likelihoods_Order_By>;
  residual_likelihood_id?: InputMaybe<Order_By>;
  review_frequency?: InputMaybe<Order_By>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  risk_controls_aggregate?: InputMaybe<Risk_Controls_Aggregate_Order_By>;
  risk_treatment_plan?: InputMaybe<Treatment_Plan_Order_By>;
  risks_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  risks_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  risks_vector_store?: InputMaybe<Ai_Risks_Vector_Store_Order_By>;
  similarity?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Risk_Tags_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Risk_Tasks_Aggregate_Order_By>;
  treatment_details?: InputMaybe<Order_By>;
  treatment_plan?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_finding?: InputMaybe<Vendor_Findings_Order_By>;
  vendor_risks_aggregate?: InputMaybe<Vendor_Risks_Aggregate_Order_By>;
};

/** primary key columns input for table: risks */
export type Risks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risks" */
export enum Risks_Select_Column {
  /** column name */
  AssessmentStatus = 'assessment_status',
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InherentImpactId = 'inherent_impact_id',
  /** column name */
  InherentLikelihoodId = 'inherent_likelihood_id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ResidualImpactId = 'residual_impact_id',
  /** column name */
  ResidualLikelihoodId = 'residual_likelihood_id',
  /** column name */
  ReviewFrequency = 'review_frequency',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TreatmentDetails = 'treatment_details',
  /** column name */
  TreatmentPlan = 'treatment_plan',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "risks_aggregate_bool_exp_avg_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_corr_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_max_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_min_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_sum_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_var_samp_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "risks" */
export type Risks_Set_Input = {
  assessment_status?: InputMaybe<Scalars['String']['input']>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  treatment_details?: InputMaybe<Scalars['String']['input']>;
  treatment_plan?: InputMaybe<Treatment_Plan_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Risks_Stddev_Fields = {
  __typename?: 'risks_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risks" */
export type Risks_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Risks_Stddev_Pop_Fields = {
  __typename?: 'risks_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risks" */
export type Risks_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Risks_Stddev_Samp_Fields = {
  __typename?: 'risks_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risks" */
export type Risks_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "risks" */
export type Risks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risks_Stream_Cursor_Value_Input = {
  assessment_status?: InputMaybe<Scalars['String']['input']>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  treatment_details?: InputMaybe<Scalars['String']['input']>;
  treatment_plan?: InputMaybe<Treatment_Plan_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Risks_Sum_Fields = {
  __typename?: 'risks_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "risks" */
export type Risks_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "risks" */
export enum Risks_Update_Column {
  /** column name */
  AssessmentStatus = 'assessment_status',
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InherentImpactId = 'inherent_impact_id',
  /** column name */
  InherentLikelihoodId = 'inherent_likelihood_id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ResidualImpactId = 'residual_impact_id',
  /** column name */
  ResidualLikelihoodId = 'residual_likelihood_id',
  /** column name */
  ReviewFrequency = 'review_frequency',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TreatmentDetails = 'treatment_details',
  /** column name */
  TreatmentPlan = 'treatment_plan',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Risks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Risks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Risks_Var_Pop_Fields = {
  __typename?: 'risks_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risks" */
export type Risks_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Risks_Var_Samp_Fields = {
  __typename?: 'risks_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risks" */
export type Risks_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Risks_Variance_Fields = {
  __typename?: 'risks_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risks" */
export type Risks_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** set of permissions which make a role */
export type Role_Permissions = {
  __typename?: 'role_permissions';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  permission: Permissions_Enum;
  /** An object relationship */
  permissionByPermission: Permissions;
  /** An object relationship */
  role: Roles;
  role_id: Scalars['uuid']['output'];
};

/** aggregated selection of "role_permissions" */
export type Role_Permissions_Aggregate = {
  __typename?: 'role_permissions_aggregate';
  aggregate?: Maybe<Role_Permissions_Aggregate_Fields>;
  nodes: Array<Role_Permissions>;
};

export type Role_Permissions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Permissions_Aggregate_Bool_Exp_Count>;
};

export type Role_Permissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Role_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_permissions" */
export type Role_Permissions_Aggregate_Fields = {
  __typename?: 'role_permissions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Role_Permissions_Max_Fields>;
  min?: Maybe<Role_Permissions_Min_Fields>;
};

/** aggregate fields of "role_permissions" */
export type Role_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "role_permissions" */
export type Role_Permissions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Permissions_Max_Order_By>;
  min?: InputMaybe<Role_Permissions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_permissions" */
export type Role_Permissions_Arr_Rel_Insert_Input = {
  data: Array<Role_Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Permissions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_permissions". All fields are combined with a logical 'AND'. */
export type Role_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Role_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Permissions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  permission?: InputMaybe<Permissions_Enum_Comparison_Exp>;
  permissionByPermission?: InputMaybe<Permissions_Bool_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_permissions" */
export enum Role_Permissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolePermissionsPkey = 'role_permissions_pkey',
  /** unique or primary key constraint on columns "role_id", "permission" */
  RolePermissionsRoleIdPermissionKey = 'role_permissions_role_id_permission_key',
}

/** input type for inserting data into table "role_permissions" */
export type Role_Permissions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  permission?: InputMaybe<Permissions_Enum>;
  permissionByPermission?: InputMaybe<Permissions_Obj_Rel_Insert_Input>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Role_Permissions_Max_Fields = {
  __typename?: 'role_permissions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "role_permissions" */
export type Role_Permissions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Permissions_Min_Fields = {
  __typename?: 'role_permissions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  role_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "role_permissions" */
export type Role_Permissions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_permissions" */
export type Role_Permissions_Mutation_Response = {
  __typename?: 'role_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Permissions>;
};

/** on_conflict condition type for table "role_permissions" */
export type Role_Permissions_On_Conflict = {
  constraint: Role_Permissions_Constraint;
  update_columns?: Array<Role_Permissions_Update_Column>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "role_permissions". */
export type Role_Permissions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  permissionByPermission?: InputMaybe<Permissions_Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_permissions */
export type Role_Permissions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "role_permissions" */
export enum Role_Permissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Permission = 'permission',
  /** column name */
  RoleId = 'role_id',
}

/** input type for updating data in table "role_permissions" */
export type Role_Permissions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  permission?: InputMaybe<Permissions_Enum>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "role_permissions" */
export type Role_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Permissions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  permission?: InputMaybe<Permissions_Enum>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "role_permissions" */
export enum Role_Permissions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Permission = 'permission',
  /** column name */
  RoleId = 'role_id',
}

export type Role_Permissions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Permissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Permissions_Bool_Exp;
};

/** different roles managed by organization  */
export type Roles = {
  __typename?: 'roles';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  invitations: Array<Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Invitations_Aggregate;
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  /** An array relationship */
  role_permissions: Array<Role_Permissions>;
  /** An aggregate relationship */
  role_permissions_aggregate: Role_Permissions_Aggregate;
  /** An object relationship */
  systemRoleBySystemRole?: Maybe<System_Roles>;
  system_role?: Maybe<System_Roles_Enum>;
  updated_at: Scalars['timestamptz']['output'];
};

/** different roles managed by organization  */
export type RolesInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** different roles managed by organization  */
export type RolesInvitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** different roles managed by organization  */
export type RolesOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** different roles managed by organization  */
export type RolesOrganization_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** different roles managed by organization  */
export type RolesRole_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

/** different roles managed by organization  */
export type RolesRole_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

export type Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Roles_Aggregate_Bool_Exp_Count>;
};

export type Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "roles" */
export type Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Roles_Max_Order_By>;
  min?: InputMaybe<Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "roles" */
export type Roles_Arr_Rel_Insert_Input = {
  data: Array<Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitations?: InputMaybe<Invitations_Bool_Exp>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_users?: InputMaybe<Organization_Users_Bool_Exp>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Bool_Exp>;
  role_permissions?: InputMaybe<Role_Permissions_Bool_Exp>;
  role_permissions_aggregate?: InputMaybe<Role_Permissions_Aggregate_Bool_Exp>;
  systemRoleBySystemRole?: InputMaybe<System_Roles_Bool_Exp>;
  system_role?: InputMaybe<System_Roles_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "system_role", "organization_id" */
  RolesOrganizationIdSystemRoleKey = 'roles_organization_id_system_role_key',
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey',
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invitations?: InputMaybe<Invitations_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  organization_users?: InputMaybe<Organization_Users_Arr_Rel_Insert_Input>;
  role_permissions?: InputMaybe<Role_Permissions_Arr_Rel_Insert_Input>;
  systemRoleBySystemRole?: InputMaybe<System_Roles_Obj_Rel_Insert_Input>;
  system_role?: InputMaybe<System_Roles_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "roles" */
export type Roles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "roles" */
export type Roles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Order_By>;
  role_permissions_aggregate?: InputMaybe<Role_Permissions_Aggregate_Order_By>;
  systemRoleBySystemRole?: InputMaybe<System_Roles_Order_By>;
  system_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  system_role?: InputMaybe<System_Roles_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  system_role?: InputMaybe<System_Roles_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** columns and relationships of "select_field_configs" */
export type Select_Field_Configs = {
  __typename?: 'select_field_configs';
  /** An object relationship */
  fieldConfigByFieldConfigId: Field_Configs;
  /** An object relationship */
  field_config?: Maybe<Field_Configs>;
  field_config_id: Scalars['uuid']['output'];
  field_type: Scalars['String']['output'];
  is_creatable: Scalars['Boolean']['output'];
  is_multi: Scalars['Boolean']['output'];
  /** An array relationship */
  select_field_options: Array<Select_Field_Options>;
  /** An aggregate relationship */
  select_field_options_aggregate: Select_Field_Options_Aggregate;
};

/** columns and relationships of "select_field_configs" */
export type Select_Field_ConfigsSelect_Field_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Options_Order_By>>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

/** columns and relationships of "select_field_configs" */
export type Select_Field_ConfigsSelect_Field_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Options_Order_By>>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

/** aggregated selection of "select_field_configs" */
export type Select_Field_Configs_Aggregate = {
  __typename?: 'select_field_configs_aggregate';
  aggregate?: Maybe<Select_Field_Configs_Aggregate_Fields>;
  nodes: Array<Select_Field_Configs>;
};

export type Select_Field_Configs_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Select_Field_Configs_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Select_Field_Configs_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Select_Field_Configs_Aggregate_Bool_Exp_Count>;
};

export type Select_Field_Configs_Aggregate_Bool_Exp_Bool_And = {
  arguments: Select_Field_Configs_Select_Column_Select_Field_Configs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Select_Field_Configs_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Select_Field_Configs_Select_Column_Select_Field_Configs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Select_Field_Configs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "select_field_configs" */
export type Select_Field_Configs_Aggregate_Fields = {
  __typename?: 'select_field_configs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Select_Field_Configs_Max_Fields>;
  min?: Maybe<Select_Field_Configs_Min_Fields>;
};

/** aggregate fields of "select_field_configs" */
export type Select_Field_Configs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "select_field_configs" */
export type Select_Field_Configs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Select_Field_Configs_Max_Order_By>;
  min?: InputMaybe<Select_Field_Configs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "select_field_configs" */
export type Select_Field_Configs_Arr_Rel_Insert_Input = {
  data: Array<Select_Field_Configs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Configs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "select_field_configs". All fields are combined with a logical 'AND'. */
export type Select_Field_Configs_Bool_Exp = {
  _and?: InputMaybe<Array<Select_Field_Configs_Bool_Exp>>;
  _not?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  _or?: InputMaybe<Array<Select_Field_Configs_Bool_Exp>>;
  fieldConfigByFieldConfigId?: InputMaybe<Field_Configs_Bool_Exp>;
  field_config?: InputMaybe<Field_Configs_Bool_Exp>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  is_creatable?: InputMaybe<Boolean_Comparison_Exp>;
  is_multi?: InputMaybe<Boolean_Comparison_Exp>;
  select_field_options?: InputMaybe<Select_Field_Options_Bool_Exp>;
  select_field_options_aggregate?: InputMaybe<Select_Field_Options_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "select_field_configs" */
export enum Select_Field_Configs_Constraint {
  /** unique or primary key constraint on columns "field_config_id" */
  SelectFieldConfigsPkey = 'select_field_configs_pkey',
}

/** input type for inserting data into table "select_field_configs" */
export type Select_Field_Configs_Insert_Input = {
  fieldConfigByFieldConfigId?: InputMaybe<Field_Configs_Obj_Rel_Insert_Input>;
  field_config?: InputMaybe<Field_Configs_Obj_Rel_Insert_Input>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  is_creatable?: InputMaybe<Scalars['Boolean']['input']>;
  is_multi?: InputMaybe<Scalars['Boolean']['input']>;
  select_field_options?: InputMaybe<Select_Field_Options_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Select_Field_Configs_Max_Fields = {
  __typename?: 'select_field_configs_max_fields';
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "select_field_configs" */
export type Select_Field_Configs_Max_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Select_Field_Configs_Min_Fields = {
  __typename?: 'select_field_configs_min_fields';
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "select_field_configs" */
export type Select_Field_Configs_Min_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "select_field_configs" */
export type Select_Field_Configs_Mutation_Response = {
  __typename?: 'select_field_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Select_Field_Configs>;
};

/** input type for inserting object relation for remote table "select_field_configs" */
export type Select_Field_Configs_Obj_Rel_Insert_Input = {
  data: Select_Field_Configs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Configs_On_Conflict>;
};

/** on_conflict condition type for table "select_field_configs" */
export type Select_Field_Configs_On_Conflict = {
  constraint: Select_Field_Configs_Constraint;
  update_columns?: Array<Select_Field_Configs_Update_Column>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "select_field_configs". */
export type Select_Field_Configs_Order_By = {
  fieldConfigByFieldConfigId?: InputMaybe<Field_Configs_Order_By>;
  field_config?: InputMaybe<Field_Configs_Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  is_creatable?: InputMaybe<Order_By>;
  is_multi?: InputMaybe<Order_By>;
  select_field_options_aggregate?: InputMaybe<Select_Field_Options_Aggregate_Order_By>;
};

/** primary key columns input for table: select_field_configs */
export type Select_Field_Configs_Pk_Columns_Input = {
  field_config_id: Scalars['uuid']['input'];
};

/** select columns of table "select_field_configs" */
export enum Select_Field_Configs_Select_Column {
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  IsCreatable = 'is_creatable',
  /** column name */
  IsMulti = 'is_multi',
}

/** select "select_field_configs_aggregate_bool_exp_bool_and_arguments_columns" columns of table "select_field_configs" */
export enum Select_Field_Configs_Select_Column_Select_Field_Configs_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCreatable = 'is_creatable',
  /** column name */
  IsMulti = 'is_multi',
}

/** select "select_field_configs_aggregate_bool_exp_bool_or_arguments_columns" columns of table "select_field_configs" */
export enum Select_Field_Configs_Select_Column_Select_Field_Configs_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCreatable = 'is_creatable',
  /** column name */
  IsMulti = 'is_multi',
}

/** input type for updating data in table "select_field_configs" */
export type Select_Field_Configs_Set_Input = {
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  is_creatable?: InputMaybe<Scalars['Boolean']['input']>;
  is_multi?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "select_field_configs" */
export type Select_Field_Configs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Select_Field_Configs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Select_Field_Configs_Stream_Cursor_Value_Input = {
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  is_creatable?: InputMaybe<Scalars['Boolean']['input']>;
  is_multi?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "select_field_configs" */
export enum Select_Field_Configs_Update_Column {
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  IsCreatable = 'is_creatable',
  /** column name */
  IsMulti = 'is_multi',
}

export type Select_Field_Configs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Select_Field_Configs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Select_Field_Configs_Bool_Exp;
};

/** columns and relationships of "select_field_options" */
export type Select_Field_Options = {
  __typename?: 'select_field_options';
  field_config_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  select_field_config: Select_Field_Configs;
  /** An array relationship */
  select_field_values: Array<Select_Field_Values>;
  /** An aggregate relationship */
  select_field_values_aggregate: Select_Field_Values_Aggregate;
  value: Scalars['String']['output'];
};

/** columns and relationships of "select_field_options" */
export type Select_Field_OptionsSelect_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

/** columns and relationships of "select_field_options" */
export type Select_Field_OptionsSelect_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

/** aggregated selection of "select_field_options" */
export type Select_Field_Options_Aggregate = {
  __typename?: 'select_field_options_aggregate';
  aggregate?: Maybe<Select_Field_Options_Aggregate_Fields>;
  nodes: Array<Select_Field_Options>;
};

export type Select_Field_Options_Aggregate_Bool_Exp = {
  count?: InputMaybe<Select_Field_Options_Aggregate_Bool_Exp_Count>;
};

export type Select_Field_Options_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Select_Field_Options_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "select_field_options" */
export type Select_Field_Options_Aggregate_Fields = {
  __typename?: 'select_field_options_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Select_Field_Options_Max_Fields>;
  min?: Maybe<Select_Field_Options_Min_Fields>;
};

/** aggregate fields of "select_field_options" */
export type Select_Field_Options_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "select_field_options" */
export type Select_Field_Options_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Select_Field_Options_Max_Order_By>;
  min?: InputMaybe<Select_Field_Options_Min_Order_By>;
};

/** input type for inserting array relation for remote table "select_field_options" */
export type Select_Field_Options_Arr_Rel_Insert_Input = {
  data: Array<Select_Field_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Options_On_Conflict>;
};

/** Boolean expression to filter rows from the table "select_field_options". All fields are combined with a logical 'AND'. */
export type Select_Field_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Select_Field_Options_Bool_Exp>>;
  _not?: InputMaybe<Select_Field_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Select_Field_Options_Bool_Exp>>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  select_field_config?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  select_field_values?: InputMaybe<Select_Field_Values_Bool_Exp>;
  select_field_values_aggregate?: InputMaybe<Select_Field_Values_Aggregate_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "select_field_options" */
export enum Select_Field_Options_Constraint {
  /** unique or primary key constraint on columns "field_config_id", "id" */
  SelectFieldOptionsFieldConfigIdIdKey = 'select_field_options_field_config_id_id_key',
  /** unique or primary key constraint on columns "id" */
  SelectFieldOptionsPkey = 'select_field_options_pkey',
  /** unique or primary key constraint on columns "field_config_id", "value" */
  SelectFieldOptionsValueFieldConfigIdKey = 'select_field_options_value_field_config_id_key',
}

/** input type for inserting data into table "select_field_options" */
export type Select_Field_Options_Insert_Input = {
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  select_field_config?: InputMaybe<Select_Field_Configs_Obj_Rel_Insert_Input>;
  select_field_values?: InputMaybe<Select_Field_Values_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Select_Field_Options_Max_Fields = {
  __typename?: 'select_field_options_max_fields';
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "select_field_options" */
export type Select_Field_Options_Max_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Select_Field_Options_Min_Fields = {
  __typename?: 'select_field_options_min_fields';
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "select_field_options" */
export type Select_Field_Options_Min_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "select_field_options" */
export type Select_Field_Options_Mutation_Response = {
  __typename?: 'select_field_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Select_Field_Options>;
};

/** input type for inserting object relation for remote table "select_field_options" */
export type Select_Field_Options_Obj_Rel_Insert_Input = {
  data: Select_Field_Options_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Options_On_Conflict>;
};

/** on_conflict condition type for table "select_field_options" */
export type Select_Field_Options_On_Conflict = {
  constraint: Select_Field_Options_Constraint;
  update_columns?: Array<Select_Field_Options_Update_Column>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "select_field_options". */
export type Select_Field_Options_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  select_field_config?: InputMaybe<Select_Field_Configs_Order_By>;
  select_field_values_aggregate?: InputMaybe<Select_Field_Values_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: select_field_options */
export type Select_Field_Options_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "select_field_options" */
export enum Select_Field_Options_Select_Column {
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "select_field_options" */
export type Select_Field_Options_Set_Input = {
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "select_field_options" */
export type Select_Field_Options_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Select_Field_Options_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Select_Field_Options_Stream_Cursor_Value_Input = {
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "select_field_options" */
export enum Select_Field_Options_Update_Column {
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
}

export type Select_Field_Options_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Select_Field_Options_Set_Input>;
  /** filter the rows which have to be updated */
  where: Select_Field_Options_Bool_Exp;
};

/** columns and relationships of "select_field_values" */
export type Select_Field_Values = {
  __typename?: 'select_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_config_id: Scalars['uuid']['output'];
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  /** An object relationship */
  select_field_option?: Maybe<Select_Field_Options>;
  select_field_option_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "select_field_values" */
export type Select_Field_Values_Aggregate = {
  __typename?: 'select_field_values_aggregate';
  aggregate?: Maybe<Select_Field_Values_Aggregate_Fields>;
  nodes: Array<Select_Field_Values>;
};

export type Select_Field_Values_Aggregate_Bool_Exp = {
  count?: InputMaybe<Select_Field_Values_Aggregate_Bool_Exp_Count>;
};

export type Select_Field_Values_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Select_Field_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "select_field_values" */
export type Select_Field_Values_Aggregate_Fields = {
  __typename?: 'select_field_values_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Select_Field_Values_Max_Fields>;
  min?: Maybe<Select_Field_Values_Min_Fields>;
};

/** aggregate fields of "select_field_values" */
export type Select_Field_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "select_field_values" */
export type Select_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Select_Field_Values_Max_Order_By>;
  min?: InputMaybe<Select_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "select_field_values" */
export type Select_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Select_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "select_field_values". All fields are combined with a logical 'AND'. */
export type Select_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Select_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Select_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Select_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  select_field_option?: InputMaybe<Select_Field_Options_Bool_Exp>;
  select_field_option_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "select_field_values" */
export enum Select_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  SelectFieldValuesPkey = 'select_field_values_pkey',
}

/** input type for inserting data into table "select_field_values" */
export type Select_Field_Values_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  select_field_option?: InputMaybe<Select_Field_Options_Obj_Rel_Insert_Input>;
  select_field_option_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Select_Field_Values_Max_Fields = {
  __typename?: 'select_field_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  select_field_option_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "select_field_values" */
export type Select_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  select_field_option_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Select_Field_Values_Min_Fields = {
  __typename?: 'select_field_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_config_id?: Maybe<Scalars['uuid']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  select_field_option_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "select_field_values" */
export type Select_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  select_field_option_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "select_field_values" */
export type Select_Field_Values_Mutation_Response = {
  __typename?: 'select_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Select_Field_Values>;
};

/** input type for inserting object relation for remote table "select_field_values" */
export type Select_Field_Values_Obj_Rel_Insert_Input = {
  data: Select_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "select_field_values" */
export type Select_Field_Values_On_Conflict = {
  constraint: Select_Field_Values_Constraint;
  update_columns?: Array<Select_Field_Values_Update_Column>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "select_field_values". */
export type Select_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  select_field_option?: InputMaybe<Select_Field_Options_Order_By>;
  select_field_option_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: select_field_values */
export type Select_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "select_field_values" */
export enum Select_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  SelectFieldOptionId = 'select_field_option_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "select_field_values" */
export type Select_Field_Values_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  select_field_option_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "select_field_values" */
export type Select_Field_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Select_Field_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Select_Field_Values_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  select_field_option_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "select_field_values" */
export enum Select_Field_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  SelectFieldOptionId = 'select_field_option_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Select_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Select_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Select_Field_Values_Bool_Exp;
};

/** Boolean expression to compare columns of type "sparsevec". All fields are combined with logical 'AND'. */
export type Sparsevec_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['sparsevec']['input']>;
  _gt?: InputMaybe<Scalars['sparsevec']['input']>;
  _gte?: InputMaybe<Scalars['sparsevec']['input']>;
  _in?: InputMaybe<Array<Scalars['sparsevec']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['sparsevec']['input']>;
  _lte?: InputMaybe<Scalars['sparsevec']['input']>;
  _neq?: InputMaybe<Scalars['sparsevec']['input']>;
  _nin?: InputMaybe<Array<Scalars['sparsevec']['input']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** execute function "ai.control_evidences_suggestions" which returns "evidences" */
  ai_control_evidences_suggestions: Array<Evidences>;
  /** execute function "ai.control_evidences_suggestions" and query aggregates on result of table type "evidences" */
  ai_control_evidences_suggestions_aggregate: Evidences_Aggregate;
  /** execute function "ai.controls_text_search" which returns "controls" */
  ai_controls_text_search: Array<Controls>;
  /** execute function "ai.controls_text_search" and query aggregates on result of table type "controls" */
  ai_controls_text_search_aggregate: Controls_Aggregate;
  /** fetch data from the table: "ai.controls_vector_store" */
  ai_controls_vector_store: Array<Ai_Controls_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.controls_vector_store" */
  ai_controls_vector_store_aggregate: Ai_Controls_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.controls_vector_store" using primary key columns */
  ai_controls_vector_store_by_pk?: Maybe<Ai_Controls_Vector_Store>;
  /** execute function "ai.controls_vector_store_search" which returns "controls" */
  ai_controls_vector_store_search: Array<Controls>;
  /** execute function "ai.controls_vector_store_search" and query aggregates on result of table type "controls" */
  ai_controls_vector_store_search_aggregate: Controls_Aggregate;
  /** fetch data from the table in a streaming manner: "ai.controls_vector_store" */
  ai_controls_vector_store_stream: Array<Ai_Controls_Vector_Store>;
  /** execute function "ai.evidences_text_search" which returns "evidences" */
  ai_evidences_text_search: Array<Evidences>;
  /** execute function "ai.evidences_text_search" and query aggregates on result of table type "evidences" */
  ai_evidences_text_search_aggregate: Evidences_Aggregate;
  /** fetch data from the table: "ai.evidences_vector_store" */
  ai_evidences_vector_store: Array<Ai_Evidences_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.evidences_vector_store" */
  ai_evidences_vector_store_aggregate: Ai_Evidences_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.evidences_vector_store" using primary key columns */
  ai_evidences_vector_store_by_pk?: Maybe<Ai_Evidences_Vector_Store>;
  /** execute function "ai.evidences_vector_store_search" which returns "evidences" */
  ai_evidences_vector_store_search: Array<Evidences>;
  /** execute function "ai.evidences_vector_store_search" and query aggregates on result of table type "evidences" */
  ai_evidences_vector_store_search_aggregate: Evidences_Aggregate;
  /** fetch data from the table in a streaming manner: "ai.evidences_vector_store" */
  ai_evidences_vector_store_stream: Array<Ai_Evidences_Vector_Store>;
  /** fetch data from the table: "ai.files_document_store" */
  ai_files_document_store: Array<Ai_Files_Document_Store>;
  /** fetch aggregated fields from the table: "ai.files_document_store" */
  ai_files_document_store_aggregate: Ai_Files_Document_Store_Aggregate;
  /** fetch data from the table: "ai.files_document_store" using primary key columns */
  ai_files_document_store_by_pk?: Maybe<Ai_Files_Document_Store>;
  /** fetch data from the table in a streaming manner: "ai.files_document_store" */
  ai_files_document_store_stream: Array<Ai_Files_Document_Store>;
  /** fetch data from the table: "ai.files_vector_store" */
  ai_files_vector_store: Array<Ai_Files_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.files_vector_store" */
  ai_files_vector_store_aggregate: Ai_Files_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.files_vector_store" using primary key columns */
  ai_files_vector_store_by_pk?: Maybe<Ai_Files_Vector_Store>;
  /** execute function "ai.files_vector_store_search" which returns "ai.files_vector_store" */
  ai_files_vector_store_search: Array<Ai_Files_Vector_Store>;
  /** execute function "ai.files_vector_store_search" and query aggregates on result of table type "ai.files_vector_store" */
  ai_files_vector_store_search_aggregate: Ai_Files_Vector_Store_Aggregate;
  /** fetch data from the table in a streaming manner: "ai.files_vector_store" */
  ai_files_vector_store_stream: Array<Ai_Files_Vector_Store>;
  /** execute function "ai.policies_text_search" which returns "policies" */
  ai_policies_text_search: Array<Policies>;
  /** execute function "ai.policies_text_search" and query aggregates on result of table type "policies" */
  ai_policies_text_search_aggregate: Policies_Aggregate;
  /** fetch data from the table: "ai.policies_vector_store" */
  ai_policies_vector_store: Array<Ai_Policies_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.policies_vector_store" */
  ai_policies_vector_store_aggregate: Ai_Policies_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.policies_vector_store" using primary key columns */
  ai_policies_vector_store_by_pk?: Maybe<Ai_Policies_Vector_Store>;
  /** execute function "ai.policies_vector_store_search" which returns "policies" */
  ai_policies_vector_store_search: Array<Policies>;
  /** execute function "ai.policies_vector_store_search" and query aggregates on result of table type "policies" */
  ai_policies_vector_store_search_aggregate: Policies_Aggregate;
  /** fetch data from the table in a streaming manner: "ai.policies_vector_store" */
  ai_policies_vector_store_stream: Array<Ai_Policies_Vector_Store>;
  /** execute function "ai.programs_text_search" which returns "programs" */
  ai_programs_text_search: Array<Programs>;
  /** execute function "ai.programs_text_search" and query aggregates on result of table type "programs" */
  ai_programs_text_search_aggregate: Programs_Aggregate;
  /** fetch data from the table: "ai.programs_vector_store" */
  ai_programs_vector_store: Array<Ai_Programs_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.programs_vector_store" */
  ai_programs_vector_store_aggregate: Ai_Programs_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.programs_vector_store" using primary key columns */
  ai_programs_vector_store_by_pk?: Maybe<Ai_Programs_Vector_Store>;
  /** execute function "ai.programs_vector_store_search" which returns "programs" */
  ai_programs_vector_store_search: Array<Programs>;
  /** execute function "ai.programs_vector_store_search" and query aggregates on result of table type "programs" */
  ai_programs_vector_store_search_aggregate: Programs_Aggregate;
  /** fetch data from the table in a streaming manner: "ai.programs_vector_store" */
  ai_programs_vector_store_stream: Array<Ai_Programs_Vector_Store>;
  /** execute function "ai.risks_text_search" which returns "risks" */
  ai_risks_text_search: Array<Risks>;
  /** execute function "ai.risks_text_search" and query aggregates on result of table type "risks" */
  ai_risks_text_search_aggregate: Risks_Aggregate;
  /** fetch data from the table: "ai.risks_vector_store" */
  ai_risks_vector_store: Array<Ai_Risks_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.risks_vector_store" */
  ai_risks_vector_store_aggregate: Ai_Risks_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.risks_vector_store" using primary key columns */
  ai_risks_vector_store_by_pk?: Maybe<Ai_Risks_Vector_Store>;
  /** execute function "ai.risks_vector_store_search" which returns "risks" */
  ai_risks_vector_store_search: Array<Risks>;
  /** execute function "ai.risks_vector_store_search" and query aggregates on result of table type "risks" */
  ai_risks_vector_store_search_aggregate: Risks_Aggregate;
  /** fetch data from the table in a streaming manner: "ai.risks_vector_store" */
  ai_risks_vector_store_stream: Array<Ai_Risks_Vector_Store>;
  /** execute function "ai.tasks_text_search" which returns "tasks" */
  ai_tasks_text_search: Array<Tasks>;
  /** execute function "ai.tasks_text_search" and query aggregates on result of table type "tasks" */
  ai_tasks_text_search_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "ai.tasks_vector_store" */
  ai_tasks_vector_store: Array<Ai_Tasks_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.tasks_vector_store" */
  ai_tasks_vector_store_aggregate: Ai_Tasks_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.tasks_vector_store" using primary key columns */
  ai_tasks_vector_store_by_pk?: Maybe<Ai_Tasks_Vector_Store>;
  /** execute function "ai.tasks_vector_store_search" which returns "tasks" */
  ai_tasks_vector_store_search: Array<Tasks>;
  /** execute function "ai.tasks_vector_store_search" and query aggregates on result of table type "tasks" */
  ai_tasks_vector_store_search_aggregate: Tasks_Aggregate;
  /** fetch data from the table in a streaming manner: "ai.tasks_vector_store" */
  ai_tasks_vector_store_stream: Array<Ai_Tasks_Vector_Store>;
  /** execute function "ai.vendors_text_search" which returns "vendors" */
  ai_vendors_text_search: Array<Vendors>;
  /** execute function "ai.vendors_text_search" and query aggregates on result of table type "vendors" */
  ai_vendors_text_search_aggregate: Vendors_Aggregate;
  /** fetch data from the table: "ai.vendors_vector_store" */
  ai_vendors_vector_store: Array<Ai_Vendors_Vector_Store>;
  /** fetch aggregated fields from the table: "ai.vendors_vector_store" */
  ai_vendors_vector_store_aggregate: Ai_Vendors_Vector_Store_Aggregate;
  /** fetch data from the table: "ai.vendors_vector_store" using primary key columns */
  ai_vendors_vector_store_by_pk?: Maybe<Ai_Vendors_Vector_Store>;
  /** execute function "ai.vendors_vector_store_search" which returns "vendors" */
  ai_vendors_vector_store_search: Array<Vendors>;
  /** execute function "ai.vendors_vector_store_search" and query aggregates on result of table type "vendors" */
  ai_vendors_vector_store_search_aggregate: Vendors_Aggregate;
  /** fetch data from the table in a streaming manner: "ai.vendors_vector_store" */
  ai_vendors_vector_store_stream: Array<Ai_Vendors_Vector_Store>;
  /** fetch data from the table: "audit.control_history_view" */
  audit_control_history_view: Array<Audit_Control_History_View>;
  /** fetch aggregated fields from the table: "audit.control_history_view" */
  audit_control_history_view_aggregate: Audit_Control_History_View_Aggregate;
  /** fetch data from the table in a streaming manner: "audit.control_history_view" */
  audit_control_history_view_stream: Array<Audit_Control_History_View>;
  /** fetch data from the table: "audit.logged_actions" */
  audit_logged_actions: Array<Audit_Logged_Actions>;
  /** fetch aggregated fields from the table: "audit.logged_actions" */
  audit_logged_actions_aggregate: Audit_Logged_Actions_Aggregate;
  /** fetch data from the table: "audit.logged_actions" using primary key columns */
  audit_logged_actions_by_pk?: Maybe<Audit_Logged_Actions>;
  /** fetch data from the table in a streaming manner: "audit.logged_actions" */
  audit_logged_actions_stream: Array<Audit_Logged_Actions>;
  /** fetch data from the table: "audit.policy_history_view" */
  audit_policy_history_view: Array<Audit_Policy_History_View>;
  /** fetch aggregated fields from the table: "audit.policy_history_view" */
  audit_policy_history_view_aggregate: Audit_Policy_History_View_Aggregate;
  /** fetch data from the table in a streaming manner: "audit.policy_history_view" */
  audit_policy_history_view_stream: Array<Audit_Policy_History_View>;
  /** fetch data from the table: "audit.risk_history_view" */
  audit_risk_history_view: Array<Audit_Risk_History_View>;
  /** fetch aggregated fields from the table: "audit.risk_history_view" */
  audit_risk_history_view_aggregate: Audit_Risk_History_View_Aggregate;
  /** fetch data from the table in a streaming manner: "audit.risk_history_view" */
  audit_risk_history_view_stream: Array<Audit_Risk_History_View>;
  /** fetch data from the table: "audit.vendor_history_view" */
  audit_vendor_history_view: Array<Audit_Vendor_History_View>;
  /** fetch aggregated fields from the table: "audit.vendor_history_view" */
  audit_vendor_history_view_aggregate: Audit_Vendor_History_View_Aggregate;
  /** fetch data from the table in a streaming manner: "audit.vendor_history_view" */
  audit_vendor_history_view_stream: Array<Audit_Vendor_History_View>;
  /** fetch data from the table: "auth.providers" using primary key columns */
  authProvider?: Maybe<AuthProviders>;
  /** fetch data from the table: "auth.provider_requests" using primary key columns */
  authProviderRequest?: Maybe<AuthProviderRequests>;
  /** fetch data from the table: "auth.provider_requests" */
  authProviderRequests: Array<AuthProviderRequests>;
  /** fetch aggregated fields from the table: "auth.provider_requests" */
  authProviderRequestsAggregate: AuthProviderRequests_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.provider_requests" */
  authProviderRequests_stream: Array<AuthProviderRequests>;
  /** fetch data from the table: "auth.providers" */
  authProviders: Array<AuthProviders>;
  /** fetch aggregated fields from the table: "auth.providers" */
  authProvidersAggregate: AuthProviders_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.providers" */
  authProviders_stream: Array<AuthProviders>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_token_types" using primary key columns */
  authRefreshTokenType?: Maybe<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_token_types" */
  authRefreshTokenTypes: Array<AuthRefreshTokenTypes>;
  /** fetch aggregated fields from the table: "auth.refresh_token_types" */
  authRefreshTokenTypesAggregate: AuthRefreshTokenTypes_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_token_types" */
  authRefreshTokenTypes_stream: Array<AuthRefreshTokenTypes>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  authRefreshTokensAggregate: AuthRefreshTokens_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  authRefreshTokens_stream: Array<AuthRefreshTokens>;
  /** fetch data from the table: "auth.roles" using primary key columns */
  authRole?: Maybe<AuthRoles>;
  /** fetch data from the table: "auth.roles" */
  authRoles: Array<AuthRoles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  authRolesAggregate: AuthRoles_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.roles" */
  authRoles_stream: Array<AuthRoles>;
  /** fetch data from the table: "auth.user_providers" using primary key columns */
  authUserProvider?: Maybe<AuthUserProviders>;
  /** fetch data from the table: "auth.user_providers" */
  authUserProviders: Array<AuthUserProviders>;
  /** fetch aggregated fields from the table: "auth.user_providers" */
  authUserProvidersAggregate: AuthUserProviders_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_providers" */
  authUserProviders_stream: Array<AuthUserProviders>;
  /** fetch data from the table: "auth.user_roles" using primary key columns */
  authUserRole?: Maybe<AuthUserRoles>;
  /** fetch data from the table: "auth.user_roles" */
  authUserRoles: Array<AuthUserRoles>;
  /** fetch aggregated fields from the table: "auth.user_roles" */
  authUserRolesAggregate: AuthUserRoles_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_roles" */
  authUserRoles_stream: Array<AuthUserRoles>;
  /** fetch data from the table: "auth.user_security_keys" using primary key columns */
  authUserSecurityKey?: Maybe<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.user_security_keys" */
  authUserSecurityKeys: Array<AuthUserSecurityKeys>;
  /** fetch aggregated fields from the table: "auth.user_security_keys" */
  authUserSecurityKeysAggregate: AuthUserSecurityKeys_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.user_security_keys" */
  authUserSecurityKeys_stream: Array<AuthUserSecurityKeys>;
  /** fetch data from the table: "auth.jackson_index" */
  auth_jackson_index: Array<Auth_Jackson_Index>;
  /** fetch aggregated fields from the table: "auth.jackson_index" */
  auth_jackson_index_aggregate: Auth_Jackson_Index_Aggregate;
  /** fetch data from the table: "auth.jackson_index" using primary key columns */
  auth_jackson_index_by_pk?: Maybe<Auth_Jackson_Index>;
  /** fetch data from the table in a streaming manner: "auth.jackson_index" */
  auth_jackson_index_stream: Array<Auth_Jackson_Index>;
  /** fetch data from the table: "auth.jackson_store" */
  auth_jackson_store: Array<Auth_Jackson_Store>;
  /** fetch aggregated fields from the table: "auth.jackson_store" */
  auth_jackson_store_aggregate: Auth_Jackson_Store_Aggregate;
  /** fetch data from the table: "auth.jackson_store" using primary key columns */
  auth_jackson_store_by_pk?: Maybe<Auth_Jackson_Store>;
  /** fetch data from the table in a streaming manner: "auth.jackson_store" */
  auth_jackson_store_stream: Array<Auth_Jackson_Store>;
  /** fetch data from the table: "auth.jackson_ttl" */
  auth_jackson_ttl: Array<Auth_Jackson_Ttl>;
  /** fetch aggregated fields from the table: "auth.jackson_ttl" */
  auth_jackson_ttl_aggregate: Auth_Jackson_Ttl_Aggregate;
  /** fetch data from the table: "auth.jackson_ttl" using primary key columns */
  auth_jackson_ttl_by_pk?: Maybe<Auth_Jackson_Ttl>;
  /** fetch data from the table in a streaming manner: "auth.jackson_ttl" */
  auth_jackson_ttl_stream: Array<Auth_Jackson_Ttl>;
  /** fetch data from the table: "auth.migrations" */
  auth_migrations: Array<Auth_Migrations>;
  /** fetch aggregated fields from the table: "auth.migrations" */
  auth_migrations_aggregate: Auth_Migrations_Aggregate;
  /** fetch data from the table: "auth.migrations" using primary key columns */
  auth_migrations_by_pk?: Maybe<Auth_Migrations>;
  /** fetch data from the table in a streaming manner: "auth.migrations" */
  auth_migrations_stream: Array<Auth_Migrations>;
  /** fetch data from the table: "storage.buckets" using primary key columns */
  bucket?: Maybe<Buckets>;
  /** fetch data from the table: "storage.buckets" */
  buckets: Array<Buckets>;
  /** fetch aggregated fields from the table: "storage.buckets" */
  bucketsAggregate: Buckets_Aggregate;
  /** fetch data from the table in a streaming manner: "storage.buckets" */
  buckets_stream: Array<Buckets>;
  /** An array relationship */
  categories: Array<Categories>;
  /** An aggregate relationship */
  categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<Categories>;
  /** An array relationship */
  client_questionnaire_answer_ai_sources: Array<Client_Questionnaire_Answer_Ai_Sources>;
  /** An aggregate relationship */
  client_questionnaire_answer_ai_sources_aggregate: Client_Questionnaire_Answer_Ai_Sources_Aggregate;
  /** fetch data from the table: "client_questionnaire_answer_ai_sources" using primary key columns */
  client_questionnaire_answer_ai_sources_by_pk?: Maybe<Client_Questionnaire_Answer_Ai_Sources>;
  /** fetch data from the table in a streaming manner: "client_questionnaire_answer_ai_sources" */
  client_questionnaire_answer_ai_sources_stream: Array<Client_Questionnaire_Answer_Ai_Sources>;
  /** An array relationship */
  client_questionnaire_answers: Array<Client_Questionnaire_Answers>;
  /** An aggregate relationship */
  client_questionnaire_answers_aggregate: Client_Questionnaire_Answers_Aggregate;
  /** fetch data from the table: "client_questionnaire_answers" using primary key columns */
  client_questionnaire_answers_by_pk?: Maybe<Client_Questionnaire_Answers>;
  /** fetch data from the table in a streaming manner: "client_questionnaire_answers" */
  client_questionnaire_answers_stream: Array<Client_Questionnaire_Answers>;
  /** fetch data from the table: "client_questionnaire_question_status" */
  client_questionnaire_question_status: Array<Client_Questionnaire_Question_Status>;
  /** fetch aggregated fields from the table: "client_questionnaire_question_status" */
  client_questionnaire_question_status_aggregate: Client_Questionnaire_Question_Status_Aggregate;
  /** fetch data from the table: "client_questionnaire_question_status" using primary key columns */
  client_questionnaire_question_status_by_pk?: Maybe<Client_Questionnaire_Question_Status>;
  /** fetch data from the table in a streaming manner: "client_questionnaire_question_status" */
  client_questionnaire_question_status_stream: Array<Client_Questionnaire_Question_Status>;
  /** An array relationship */
  client_questionnaire_questions: Array<Client_Questionnaire_Questions>;
  /** An aggregate relationship */
  client_questionnaire_questions_aggregate: Client_Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "client_questionnaire_questions" using primary key columns */
  client_questionnaire_questions_by_pk?: Maybe<Client_Questionnaire_Questions>;
  /** fetch data from the table in a streaming manner: "client_questionnaire_questions" */
  client_questionnaire_questions_stream: Array<Client_Questionnaire_Questions>;
  /** fetch data from the table: "client_questionnaire_status" */
  client_questionnaire_status: Array<Client_Questionnaire_Status>;
  /** fetch aggregated fields from the table: "client_questionnaire_status" */
  client_questionnaire_status_aggregate: Client_Questionnaire_Status_Aggregate;
  /** fetch data from the table: "client_questionnaire_status" using primary key columns */
  client_questionnaire_status_by_pk?: Maybe<Client_Questionnaire_Status>;
  /** fetch data from the table in a streaming manner: "client_questionnaire_status" */
  client_questionnaire_status_stream: Array<Client_Questionnaire_Status>;
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** An aggregate relationship */
  client_questionnaires_aggregate: Client_Questionnaires_Aggregate;
  /** fetch data from the table: "client_questionnaires" using primary key columns */
  client_questionnaires_by_pk?: Maybe<Client_Questionnaires>;
  /** fetch data from the table in a streaming manner: "client_questionnaires" */
  client_questionnaires_stream: Array<Client_Questionnaires>;
  /** fetch data from the table: "comment_uploads" */
  comment_uploads: Array<Comment_Uploads>;
  /** fetch aggregated fields from the table: "comment_uploads" */
  comment_uploads_aggregate: Comment_Uploads_Aggregate;
  /** fetch data from the table: "comment_uploads" using primary key columns */
  comment_uploads_by_pk?: Maybe<Comment_Uploads>;
  /** fetch data from the table in a streaming manner: "comment_uploads" */
  comment_uploads_stream: Array<Comment_Uploads>;
  /** An array relationship */
  comments: Array<Comments>;
  /** An aggregate relationship */
  comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  comments_by_pk?: Maybe<Comments>;
  /** fetch data from the table in a streaming manner: "comments" */
  comments_stream: Array<Comments>;
  /** An array relationship */
  control_ai_review_assessment_sources: Array<Control_Ai_Review_Assessment_Sources>;
  /** An aggregate relationship */
  control_ai_review_assessment_sources_aggregate: Control_Ai_Review_Assessment_Sources_Aggregate;
  /** fetch data from the table: "control_ai_review_assessment_sources" using primary key columns */
  control_ai_review_assessment_sources_by_pk?: Maybe<Control_Ai_Review_Assessment_Sources>;
  /** fetch data from the table in a streaming manner: "control_ai_review_assessment_sources" */
  control_ai_review_assessment_sources_stream: Array<Control_Ai_Review_Assessment_Sources>;
  /** An array relationship */
  control_ai_review_assessments: Array<Control_Ai_Review_Assessments>;
  /** An aggregate relationship */
  control_ai_review_assessments_aggregate: Control_Ai_Review_Assessments_Aggregate;
  /** fetch data from the table: "control_ai_review_assessments" using primary key columns */
  control_ai_review_assessments_by_pk?: Maybe<Control_Ai_Review_Assessments>;
  /** fetch data from the table in a streaming manner: "control_ai_review_assessments" */
  control_ai_review_assessments_stream: Array<Control_Ai_Review_Assessments>;
  /** An array relationship */
  control_ai_review_criteria: Array<Control_Ai_Review_Criteria>;
  /** An aggregate relationship */
  control_ai_review_criteria_aggregate: Control_Ai_Review_Criteria_Aggregate;
  /** fetch data from the table: "control_ai_review_criteria" using primary key columns */
  control_ai_review_criteria_by_pk?: Maybe<Control_Ai_Review_Criteria>;
  /** fetch data from the table in a streaming manner: "control_ai_review_criteria" */
  control_ai_review_criteria_stream: Array<Control_Ai_Review_Criteria>;
  /** An array relationship */
  control_ai_review_runs: Array<Control_Ai_Review_Runs>;
  /** An aggregate relationship */
  control_ai_review_runs_aggregate: Control_Ai_Review_Runs_Aggregate;
  /** fetch data from the table: "control_ai_review_runs" using primary key columns */
  control_ai_review_runs_by_pk?: Maybe<Control_Ai_Review_Runs>;
  /** fetch data from the table in a streaming manner: "control_ai_review_runs" */
  control_ai_review_runs_stream: Array<Control_Ai_Review_Runs>;
  /** fetch data from the table: "control_ai_review_status" */
  control_ai_review_status: Array<Control_Ai_Review_Status>;
  /** fetch aggregated fields from the table: "control_ai_review_status" */
  control_ai_review_status_aggregate: Control_Ai_Review_Status_Aggregate;
  /** fetch data from the table: "control_ai_review_status" using primary key columns */
  control_ai_review_status_by_pk?: Maybe<Control_Ai_Review_Status>;
  /** fetch data from the table in a streaming manner: "control_ai_review_status" */
  control_ai_review_status_stream: Array<Control_Ai_Review_Status>;
  /** An array relationship */
  control_categories: Array<Control_Categories>;
  /** An aggregate relationship */
  control_categories_aggregate: Control_Categories_Aggregate;
  /** fetch data from the table: "control_categories" using primary key columns */
  control_categories_by_pk?: Maybe<Control_Categories>;
  /** fetch data from the table in a streaming manner: "control_categories" */
  control_categories_stream: Array<Control_Categories>;
  /** An array relationship */
  control_criteria: Array<Control_Criteria>;
  /** An aggregate relationship */
  control_criteria_aggregate: Control_Criteria_Aggregate;
  /** fetch data from the table: "control_criteria" using primary key columns */
  control_criteria_by_pk?: Maybe<Control_Criteria>;
  /** fetch data from the table in a streaming manner: "control_criteria" */
  control_criteria_stream: Array<Control_Criteria>;
  /** An array relationship */
  control_evidences: Array<Control_Evidences>;
  /** An aggregate relationship */
  control_evidences_aggregate: Control_Evidences_Aggregate;
  /** fetch data from the table: "control_evidences" using primary key columns */
  control_evidences_by_pk?: Maybe<Control_Evidences>;
  /** fetch data from the table in a streaming manner: "control_evidences" */
  control_evidences_stream: Array<Control_Evidences>;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  /** An aggregate relationship */
  control_findings_aggregate: Control_Findings_Aggregate;
  /** fetch data from the table: "control_findings" using primary key columns */
  control_findings_by_pk?: Maybe<Control_Findings>;
  /** fetch data from the table in a streaming manner: "control_findings" */
  control_findings_stream: Array<Control_Findings>;
  /** fetch data from the table: "control_frequencies" */
  control_frequencies: Array<Control_Frequencies>;
  /** fetch aggregated fields from the table: "control_frequencies" */
  control_frequencies_aggregate: Control_Frequencies_Aggregate;
  /** fetch data from the table: "control_frequencies" using primary key columns */
  control_frequencies_by_pk?: Maybe<Control_Frequencies>;
  /** fetch data from the table in a streaming manner: "control_frequencies" */
  control_frequencies_stream: Array<Control_Frequencies>;
  /** An array relationship */
  control_groups: Array<Control_Groups>;
  /** An aggregate relationship */
  control_groups_aggregate: Control_Groups_Aggregate;
  /** fetch data from the table: "control_groups" using primary key columns */
  control_groups_by_pk?: Maybe<Control_Groups>;
  /** fetch data from the table in a streaming manner: "control_groups" */
  control_groups_stream: Array<Control_Groups>;
  /** fetch data from the table: "control_priorities" */
  control_priorities: Array<Control_Priorities>;
  /** fetch aggregated fields from the table: "control_priorities" */
  control_priorities_aggregate: Control_Priorities_Aggregate;
  /** fetch data from the table: "control_priorities" using primary key columns */
  control_priorities_by_pk?: Maybe<Control_Priorities>;
  /** fetch data from the table in a streaming manner: "control_priorities" */
  control_priorities_stream: Array<Control_Priorities>;
  /** An array relationship */
  control_tags: Array<Control_Tags>;
  /** An aggregate relationship */
  control_tags_aggregate: Control_Tags_Aggregate;
  /** fetch data from the table: "control_tags" using primary key columns */
  control_tags_by_pk?: Maybe<Control_Tags>;
  /** fetch data from the table in a streaming manner: "control_tags" */
  control_tags_stream: Array<Control_Tags>;
  /** An array relationship */
  control_tasks: Array<Control_Tasks>;
  /** An aggregate relationship */
  control_tasks_aggregate: Control_Tasks_Aggregate;
  /** fetch data from the table: "control_tasks" using primary key columns */
  control_tasks_by_pk?: Maybe<Control_Tasks>;
  /** fetch data from the table in a streaming manner: "control_tasks" */
  control_tasks_stream: Array<Control_Tasks>;
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  /** fetch data from the table: "controls" using primary key columns */
  controls_by_pk?: Maybe<Controls>;
  /** An array relationship */
  controls_history_stats: Array<Controls_History_Stats>;
  /** An aggregate relationship */
  controls_history_stats_aggregate: Controls_History_Stats_Aggregate;
  /** fetch data from the table: "controls_history_stats" using primary key columns */
  controls_history_stats_by_pk?: Maybe<Controls_History_Stats>;
  /** fetch data from the table in a streaming manner: "controls_history_stats" */
  controls_history_stats_stream: Array<Controls_History_Stats>;
  /** fetch data from the table: "controls_stats_view" */
  controls_stats_view: Array<Controls_Stats_View>;
  /** fetch aggregated fields from the table: "controls_stats_view" */
  controls_stats_view_aggregate: Controls_Stats_View_Aggregate;
  /** fetch data from the table in a streaming manner: "controls_stats_view" */
  controls_stats_view_stream: Array<Controls_Stats_View>;
  /** fetch data from the table in a streaming manner: "controls" */
  controls_stream: Array<Controls>;
  /** An array relationship */
  criteria: Array<Criteria>;
  /** An aggregate relationship */
  criteria_aggregate: Criteria_Aggregate;
  /** fetch data from the table: "criteria" using primary key columns */
  criteria_by_pk?: Maybe<Criteria>;
  /** fetch data from the table in a streaming manner: "criteria" */
  criteria_stream: Array<Criteria>;
  /** fetch data from the table: "daily_control_stats_view" */
  daily_control_stats_view: Array<Daily_Control_Stats_View>;
  /** fetch aggregated fields from the table: "daily_control_stats_view" */
  daily_control_stats_view_aggregate: Daily_Control_Stats_View_Aggregate;
  /** fetch data from the table in a streaming manner: "daily_control_stats_view" */
  daily_control_stats_view_stream: Array<Daily_Control_Stats_View>;
  /** fetch data from the table: "daily_control_user_stats_view" */
  daily_control_user_stats_view: Array<Daily_Control_User_Stats_View>;
  /** fetch aggregated fields from the table: "daily_control_user_stats_view" */
  daily_control_user_stats_view_aggregate: Daily_Control_User_Stats_View_Aggregate;
  /** fetch data from the table in a streaming manner: "daily_control_user_stats_view" */
  daily_control_user_stats_view_stream: Array<Daily_Control_User_Stats_View>;
  /** An array relationship */
  date_field_values: Array<Date_Field_Values>;
  /** An aggregate relationship */
  date_field_values_aggregate: Date_Field_Values_Aggregate;
  /** fetch data from the table: "date_field_values" using primary key columns */
  date_field_values_by_pk?: Maybe<Date_Field_Values>;
  /** fetch data from the table in a streaming manner: "date_field_values" */
  date_field_values_stream: Array<Date_Field_Values>;
  /** An array relationship */
  email_field_values: Array<Email_Field_Values>;
  /** An aggregate relationship */
  email_field_values_aggregate: Email_Field_Values_Aggregate;
  /** fetch data from the table: "email_field_values" using primary key columns */
  email_field_values_by_pk?: Maybe<Email_Field_Values>;
  /** fetch data from the table in a streaming manner: "email_field_values" */
  email_field_values_stream: Array<Email_Field_Values>;
  /** fetch data from the table: "evidence_acl" */
  evidence_acl: Array<Evidence_Acl>;
  /** fetch aggregated fields from the table: "evidence_acl" */
  evidence_acl_aggregate: Evidence_Acl_Aggregate;
  /** fetch data from the table: "evidence_acl" using primary key columns */
  evidence_acl_by_pk?: Maybe<Evidence_Acl>;
  /** fetch data from the table in a streaming manner: "evidence_acl" */
  evidence_acl_stream: Array<Evidence_Acl>;
  /** An array relationship */
  evidence_integrations: Array<Evidence_Integrations>;
  /** An aggregate relationship */
  evidence_integrations_aggregate: Evidence_Integrations_Aggregate;
  /** fetch data from the table: "evidence_integrations" using primary key columns */
  evidence_integrations_by_pk?: Maybe<Evidence_Integrations>;
  /** fetch data from the table in a streaming manner: "evidence_integrations" */
  evidence_integrations_stream: Array<Evidence_Integrations>;
  /** An array relationship */
  evidence_policies: Array<Evidence_Policies>;
  /** An aggregate relationship */
  evidence_policies_aggregate: Evidence_Policies_Aggregate;
  /** fetch data from the table: "evidence_policies" using primary key columns */
  evidence_policies_by_pk?: Maybe<Evidence_Policies>;
  /** fetch data from the table in a streaming manner: "evidence_policies" */
  evidence_policies_stream: Array<Evidence_Policies>;
  /** An array relationship */
  evidence_tags: Array<Evidence_Tags>;
  /** An aggregate relationship */
  evidence_tags_aggregate: Evidence_Tags_Aggregate;
  /** fetch data from the table: "evidence_tags" using primary key columns */
  evidence_tags_by_pk?: Maybe<Evidence_Tags>;
  /** fetch data from the table in a streaming manner: "evidence_tags" */
  evidence_tags_stream: Array<Evidence_Tags>;
  /** fetch data from the table: "evidence_version_file" */
  evidence_version_file: Array<Evidence_Version_File>;
  /** fetch aggregated fields from the table: "evidence_version_file" */
  evidence_version_file_aggregate: Evidence_Version_File_Aggregate;
  /** fetch data from the table: "evidence_version_file" using primary key columns */
  evidence_version_file_by_pk?: Maybe<Evidence_Version_File>;
  /** fetch data from the table in a streaming manner: "evidence_version_file" */
  evidence_version_file_stream: Array<Evidence_Version_File>;
  /** An array relationship */
  evidence_versions: Array<Evidence_Versions>;
  /** An aggregate relationship */
  evidence_versions_aggregate: Evidence_Versions_Aggregate;
  /** fetch data from the table: "evidence_versions" using primary key columns */
  evidence_versions_by_pk?: Maybe<Evidence_Versions>;
  /** fetch data from the table in a streaming manner: "evidence_versions" */
  evidence_versions_stream: Array<Evidence_Versions>;
  /** An array relationship */
  evidences: Array<Evidences>;
  /** An aggregate relationship */
  evidences_aggregate: Evidences_Aggregate;
  /** fetch data from the table: "evidences" using primary key columns */
  evidences_by_pk?: Maybe<Evidences>;
  /** fetch data from the table in a streaming manner: "evidences" */
  evidences_stream: Array<Evidences>;
  /** An array relationship */
  field_configs: Array<Field_Configs>;
  /** An aggregate relationship */
  field_configs_aggregate: Field_Configs_Aggregate;
  /** fetch data from the table: "field_configs" using primary key columns */
  field_configs_by_pk?: Maybe<Field_Configs>;
  /** fetch data from the table in a streaming manner: "field_configs" */
  field_configs_stream: Array<Field_Configs>;
  /** fetch data from the table: "field_entities" */
  field_entities: Array<Field_Entities>;
  /** fetch aggregated fields from the table: "field_entities" */
  field_entities_aggregate: Field_Entities_Aggregate;
  /** fetch data from the table: "field_entities" using primary key columns */
  field_entities_by_pk?: Maybe<Field_Entities>;
  /** fetch data from the table in a streaming manner: "field_entities" */
  field_entities_stream: Array<Field_Entities>;
  /** fetch data from the table: "field_types" */
  field_types: Array<Field_Types>;
  /** fetch aggregated fields from the table: "field_types" */
  field_types_aggregate: Field_Types_Aggregate;
  /** fetch data from the table: "field_types" using primary key columns */
  field_types_by_pk?: Maybe<Field_Types>;
  /** fetch data from the table in a streaming manner: "field_types" */
  field_types_stream: Array<Field_Types>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  /** fetch data from the table: "field_values" using primary key columns */
  field_values_by_pk?: Maybe<Field_Values>;
  /** fetch data from the table in a streaming manner: "field_values" */
  field_values_stream: Array<Field_Values>;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "storage.files" */
  filesAggregate: Files_Aggregate;
  /** fetch data from the table in a streaming manner: "storage.files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "finding_causes" */
  finding_causes: Array<Finding_Causes>;
  /** fetch aggregated fields from the table: "finding_causes" */
  finding_causes_aggregate: Finding_Causes_Aggregate;
  /** fetch data from the table: "finding_causes" using primary key columns */
  finding_causes_by_pk?: Maybe<Finding_Causes>;
  /** fetch data from the table in a streaming manner: "finding_causes" */
  finding_causes_stream: Array<Finding_Causes>;
  /** fetch data from the table: "finding_types" */
  finding_types: Array<Finding_Types>;
  /** fetch aggregated fields from the table: "finding_types" */
  finding_types_aggregate: Finding_Types_Aggregate;
  /** fetch data from the table: "finding_types" using primary key columns */
  finding_types_by_pk?: Maybe<Finding_Types>;
  /** fetch data from the table in a streaming manner: "finding_types" */
  finding_types_stream: Array<Finding_Types>;
  /** fetch data from the table: "forms" */
  forms: Array<Forms>;
  /** fetch aggregated fields from the table: "forms" */
  forms_aggregate: Forms_Aggregate;
  /** fetch data from the table: "forms" using primary key columns */
  forms_by_pk?: Maybe<Forms>;
  /** fetch data from the table in a streaming manner: "forms" */
  forms_stream: Array<Forms>;
  /** An array relationship */
  frameworks: Array<Frameworks>;
  /** An aggregate relationship */
  frameworks_aggregate: Frameworks_Aggregate;
  /** fetch data from the table: "frameworks" using primary key columns */
  frameworks_by_pk?: Maybe<Frameworks>;
  /** An array relationship */
  frameworks_controls: Array<Frameworks_Controls>;
  /** An aggregate relationship */
  frameworks_controls_aggregate: Frameworks_Controls_Aggregate;
  /** fetch data from the table: "frameworks_controls" using primary key columns */
  frameworks_controls_by_pk?: Maybe<Frameworks_Controls>;
  /** fetch data from the table in a streaming manner: "frameworks_controls" */
  frameworks_controls_stream: Array<Frameworks_Controls>;
  /** fetch data from the table in a streaming manner: "frameworks" */
  frameworks_stream: Array<Frameworks>;
  /** fetch data from the table: "frequencies" */
  frequencies: Array<Frequencies>;
  /** fetch aggregated fields from the table: "frequencies" */
  frequencies_aggregate: Frequencies_Aggregate;
  /** fetch data from the table: "frequencies" using primary key columns */
  frequencies_by_pk?: Maybe<Frequencies>;
  /** fetch data from the table in a streaming manner: "frequencies" */
  frequencies_stream: Array<Frequencies>;
  /** An array relationship */
  groups: Array<Groups>;
  /** An aggregate relationship */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table in a streaming manner: "groups" */
  groups_stream: Array<Groups>;
  /** This action is used to import policies from csv */
  import_policies?: Maybe<Import_Policies>;
  /** This action is used to import programs from csv */
  import_program?: Maybe<Import_Program>;
  /** This action is used to import risks from csv */
  import_risks?: Maybe<Import_Risks>;
  /** This action is used to import vendors from csv */
  import_vendors?: Maybe<Import_Vendors>;
  /** fetch data from the table: "integration_names" */
  integration_names: Array<Integration_Names>;
  /** fetch aggregated fields from the table: "integration_names" */
  integration_names_aggregate: Integration_Names_Aggregate;
  /** fetch data from the table: "integration_names" using primary key columns */
  integration_names_by_pk?: Maybe<Integration_Names>;
  /** fetch data from the table in a streaming manner: "integration_names" */
  integration_names_stream: Array<Integration_Names>;
  /** fetch data from the table: "integration_runs" */
  integration_runs: Array<Integration_Runs>;
  /** fetch aggregated fields from the table: "integration_runs" */
  integration_runs_aggregate: Integration_Runs_Aggregate;
  /** fetch data from the table: "integration_runs" using primary key columns */
  integration_runs_by_pk?: Maybe<Integration_Runs>;
  /** fetch data from the table in a streaming manner: "integration_runs" */
  integration_runs_stream: Array<Integration_Runs>;
  /** fetch data from the table: "integration_usage_stats_view" */
  integration_usage_stats_view: Array<Integration_Usage_Stats_View>;
  /** fetch aggregated fields from the table: "integration_usage_stats_view" */
  integration_usage_stats_view_aggregate: Integration_Usage_Stats_View_Aggregate;
  /** fetch data from the table in a streaming manner: "integration_usage_stats_view" */
  integration_usage_stats_view_stream: Array<Integration_Usage_Stats_View>;
  /** fetch data from the table: "integrations" */
  integrations: Array<Integrations>;
  /** fetch aggregated fields from the table: "integrations" */
  integrations_aggregate: Integrations_Aggregate;
  /** fetch data from the table: "integrations" using primary key columns */
  integrations_by_pk?: Maybe<Integrations>;
  /** fetch data from the table in a streaming manner: "integrations" */
  integrations_stream: Array<Integrations>;
  /** An array relationship */
  invitations: Array<Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Invitations_Aggregate;
  /** fetch data from the table: "invitations" using primary key columns */
  invitations_by_pk?: Maybe<Invitations>;
  /** fetch data from the table in a streaming manner: "invitations" */
  invitations_stream: Array<Invitations>;
  /** fetch data from the table: "notification_delivery_types" */
  notification_delivery_types: Array<Notification_Delivery_Types>;
  /** fetch aggregated fields from the table: "notification_delivery_types" */
  notification_delivery_types_aggregate: Notification_Delivery_Types_Aggregate;
  /** fetch data from the table: "notification_delivery_types" using primary key columns */
  notification_delivery_types_by_pk?: Maybe<Notification_Delivery_Types>;
  /** fetch data from the table in a streaming manner: "notification_delivery_types" */
  notification_delivery_types_stream: Array<Notification_Delivery_Types>;
  /** An array relationship */
  notification_disabled_settings: Array<Notification_Disabled_Settings>;
  /** An aggregate relationship */
  notification_disabled_settings_aggregate: Notification_Disabled_Settings_Aggregate;
  /** fetch data from the table: "notification_disabled_settings" using primary key columns */
  notification_disabled_settings_by_pk?: Maybe<Notification_Disabled_Settings>;
  /** fetch data from the table in a streaming manner: "notification_disabled_settings" */
  notification_disabled_settings_stream: Array<Notification_Disabled_Settings>;
  /** fetch data from the table: "notification_types" */
  notification_types: Array<Notification_Types>;
  /** fetch aggregated fields from the table: "notification_types" */
  notification_types_aggregate: Notification_Types_Aggregate;
  /** fetch data from the table: "notification_types" using primary key columns */
  notification_types_by_pk?: Maybe<Notification_Types>;
  /** fetch data from the table in a streaming manner: "notification_types" */
  notification_types_stream: Array<Notification_Types>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** An array relationship */
  number_field_values: Array<Number_Field_Values>;
  /** An aggregate relationship */
  number_field_values_aggregate: Number_Field_Values_Aggregate;
  /** fetch data from the table: "number_field_values" using primary key columns */
  number_field_values_by_pk?: Maybe<Number_Field_Values>;
  /** fetch data from the table in a streaming manner: "number_field_values" */
  number_field_values_stream: Array<Number_Field_Values>;
  /** fetch data from the table: "organization_evidence_tags" */
  organization_evidence_tags: Array<Organization_Evidence_Tags>;
  /** fetch aggregated fields from the table: "organization_evidence_tags" */
  organization_evidence_tags_aggregate: Organization_Evidence_Tags_Aggregate;
  /** fetch data from the table: "organization_evidence_tags" using primary key columns */
  organization_evidence_tags_by_pk?: Maybe<Organization_Evidence_Tags>;
  /** fetch data from the table in a streaming manner: "organization_evidence_tags" */
  organization_evidence_tags_stream: Array<Organization_Evidence_Tags>;
  /** An array relationship */
  organization_integrations: Array<Organization_Integrations>;
  /** An aggregate relationship */
  organization_integrations_aggregate: Organization_Integrations_Aggregate;
  /** fetch data from the table: "organization_integrations" using primary key columns */
  organization_integrations_by_pk?: Maybe<Organization_Integrations>;
  /** fetch data from the table in a streaming manner: "organization_integrations" */
  organization_integrations_stream: Array<Organization_Integrations>;
  /** fetch data from the table: "organization_risk_categories" */
  organization_risk_categories: Array<Organization_Risk_Categories>;
  /** fetch aggregated fields from the table: "organization_risk_categories" */
  organization_risk_categories_aggregate: Organization_Risk_Categories_Aggregate;
  /** fetch data from the table: "organization_risk_categories" using primary key columns */
  organization_risk_categories_by_pk?: Maybe<Organization_Risk_Categories>;
  /** fetch data from the table in a streaming manner: "organization_risk_categories" */
  organization_risk_categories_stream: Array<Organization_Risk_Categories>;
  /** fetch data from the table: "organization_risk_tags" */
  organization_risk_tags: Array<Organization_Risk_Tags>;
  /** fetch aggregated fields from the table: "organization_risk_tags" */
  organization_risk_tags_aggregate: Organization_Risk_Tags_Aggregate;
  /** fetch data from the table: "organization_risk_tags" using primary key columns */
  organization_risk_tags_by_pk?: Maybe<Organization_Risk_Tags>;
  /** fetch data from the table in a streaming manner: "organization_risk_tags" */
  organization_risk_tags_stream: Array<Organization_Risk_Tags>;
  /** An array relationship */
  organization_template_frameworks: Array<Organization_Template_Frameworks>;
  /** An aggregate relationship */
  organization_template_frameworks_aggregate: Organization_Template_Frameworks_Aggregate;
  /** fetch data from the table: "organization_template_frameworks" using primary key columns */
  organization_template_frameworks_by_pk?: Maybe<Organization_Template_Frameworks>;
  /** fetch data from the table in a streaming manner: "organization_template_frameworks" */
  organization_template_frameworks_stream: Array<Organization_Template_Frameworks>;
  /** An array relationship */
  organization_tenants: Array<Organization_Tenants>;
  /** An aggregate relationship */
  organization_tenants_aggregate: Organization_Tenants_Aggregate;
  /** fetch data from the table: "organization_tenants" using primary key columns */
  organization_tenants_by_pk?: Maybe<Organization_Tenants>;
  /** fetch data from the table in a streaming manner: "organization_tenants" */
  organization_tenants_stream: Array<Organization_Tenants>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  /** fetch data from the table: "organization_users" using primary key columns */
  organization_users_by_pk?: Maybe<Organization_Users>;
  /** fetch data from the table in a streaming manner: "organization_users" */
  organization_users_stream: Array<Organization_Users>;
  /** fetch data from the table: "organization_vendor_risk_levels" */
  organization_vendor_risk_levels: Array<Organization_Vendor_Risk_Levels>;
  /** fetch aggregated fields from the table: "organization_vendor_risk_levels" */
  organization_vendor_risk_levels_aggregate: Organization_Vendor_Risk_Levels_Aggregate;
  /** fetch data from the table: "organization_vendor_risk_levels" using primary key columns */
  organization_vendor_risk_levels_by_pk?: Maybe<Organization_Vendor_Risk_Levels>;
  /** fetch data from the table in a streaming manner: "organization_vendor_risk_levels" */
  organization_vendor_risk_levels_stream: Array<Organization_Vendor_Risk_Levels>;
  /** fetch data from the table: "organization_vendor_tiers" */
  organization_vendor_tiers: Array<Organization_Vendor_Tiers>;
  /** fetch aggregated fields from the table: "organization_vendor_tiers" */
  organization_vendor_tiers_aggregate: Organization_Vendor_Tiers_Aggregate;
  /** fetch data from the table: "organization_vendor_tiers" using primary key columns */
  organization_vendor_tiers_by_pk?: Maybe<Organization_Vendor_Tiers>;
  /** fetch data from the table in a streaming manner: "organization_vendor_tiers" */
  organization_vendor_tiers_stream: Array<Organization_Vendor_Tiers>;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table in a streaming manner: "organizations" */
  organizations_stream: Array<Organizations>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table in a streaming manner: "permissions" */
  permissions_stream: Array<Permissions>;
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
  /** fetch data from the table: "policies" using primary key columns */
  policies_by_pk?: Maybe<Policies>;
  /** fetch data from the table in a streaming manner: "policies" */
  policies_stream: Array<Policies>;
  /** fetch data from the table: "policy_acknowledgement_statuses" */
  policy_acknowledgement_statuses: Array<Policy_Acknowledgement_Statuses>;
  /** fetch aggregated fields from the table: "policy_acknowledgement_statuses" */
  policy_acknowledgement_statuses_aggregate: Policy_Acknowledgement_Statuses_Aggregate;
  /** fetch data from the table: "policy_acknowledgement_statuses" using primary key columns */
  policy_acknowledgement_statuses_by_pk?: Maybe<Policy_Acknowledgement_Statuses>;
  /** fetch data from the table in a streaming manner: "policy_acknowledgement_statuses" */
  policy_acknowledgement_statuses_stream: Array<Policy_Acknowledgement_Statuses>;
  /** An array relationship */
  policy_acknowledgement_users: Array<Policy_Acknowledgement_Users>;
  /** An aggregate relationship */
  policy_acknowledgement_users_aggregate: Policy_Acknowledgement_Users_Aggregate;
  /** fetch data from the table: "policy_acknowledgement_users" using primary key columns */
  policy_acknowledgement_users_by_pk?: Maybe<Policy_Acknowledgement_Users>;
  /** fetch data from the table in a streaming manner: "policy_acknowledgement_users" */
  policy_acknowledgement_users_stream: Array<Policy_Acknowledgement_Users>;
  /** An array relationship */
  policy_acknowledgements: Array<Policy_Acknowledgements>;
  /** An aggregate relationship */
  policy_acknowledgements_aggregate: Policy_Acknowledgements_Aggregate;
  /** fetch data from the table: "policy_acknowledgements" using primary key columns */
  policy_acknowledgements_by_pk?: Maybe<Policy_Acknowledgements>;
  /** fetch data from the table in a streaming manner: "policy_acknowledgements" */
  policy_acknowledgements_stream: Array<Policy_Acknowledgements>;
  /** fetch data from the table: "policy_approval_statuses" */
  policy_approval_statuses: Array<Policy_Approval_Statuses>;
  /** fetch aggregated fields from the table: "policy_approval_statuses" */
  policy_approval_statuses_aggregate: Policy_Approval_Statuses_Aggregate;
  /** fetch data from the table: "policy_approval_statuses" using primary key columns */
  policy_approval_statuses_by_pk?: Maybe<Policy_Approval_Statuses>;
  /** fetch data from the table in a streaming manner: "policy_approval_statuses" */
  policy_approval_statuses_stream: Array<Policy_Approval_Statuses>;
  /** An array relationship */
  policy_approval_users: Array<Policy_Approval_Users>;
  /** An aggregate relationship */
  policy_approval_users_aggregate: Policy_Approval_Users_Aggregate;
  /** fetch data from the table: "policy_approval_users" using primary key columns */
  policy_approval_users_by_pk?: Maybe<Policy_Approval_Users>;
  /** fetch data from the table in a streaming manner: "policy_approval_users" */
  policy_approval_users_stream: Array<Policy_Approval_Users>;
  /** An array relationship */
  policy_approvals: Array<Policy_Approvals>;
  /** An aggregate relationship */
  policy_approvals_aggregate: Policy_Approvals_Aggregate;
  /** fetch data from the table: "policy_approvals" using primary key columns */
  policy_approvals_by_pk?: Maybe<Policy_Approvals>;
  /** fetch data from the table in a streaming manner: "policy_approvals" */
  policy_approvals_stream: Array<Policy_Approvals>;
  /** An array relationship */
  policy_approvers: Array<Policy_Approvers>;
  /** An aggregate relationship */
  policy_approvers_aggregate: Policy_Approvers_Aggregate;
  /** fetch data from the table: "policy_approvers" using primary key columns */
  policy_approvers_by_pk?: Maybe<Policy_Approvers>;
  /** fetch data from the table in a streaming manner: "policy_approvers" */
  policy_approvers_stream: Array<Policy_Approvers>;
  /** fetch data from the table: "policy_statuses" */
  policy_statuses: Array<Policy_Statuses>;
  /** fetch aggregated fields from the table: "policy_statuses" */
  policy_statuses_aggregate: Policy_Statuses_Aggregate;
  /** fetch data from the table: "policy_statuses" using primary key columns */
  policy_statuses_by_pk?: Maybe<Policy_Statuses>;
  /** fetch data from the table in a streaming manner: "policy_statuses" */
  policy_statuses_stream: Array<Policy_Statuses>;
  /** fetch data from the table: "policy_tasks" */
  policy_tasks: Array<Policy_Tasks>;
  /** fetch aggregated fields from the table: "policy_tasks" */
  policy_tasks_aggregate: Policy_Tasks_Aggregate;
  /** fetch data from the table: "policy_tasks" using primary key columns */
  policy_tasks_by_pk?: Maybe<Policy_Tasks>;
  /** fetch data from the table in a streaming manner: "policy_tasks" */
  policy_tasks_stream: Array<Policy_Tasks>;
  /** fetch data from the table: "policy_types" */
  policy_types: Array<Policy_Types>;
  /** fetch aggregated fields from the table: "policy_types" */
  policy_types_aggregate: Policy_Types_Aggregate;
  /** fetch data from the table: "policy_types" using primary key columns */
  policy_types_by_pk?: Maybe<Policy_Types>;
  /** fetch data from the table in a streaming manner: "policy_types" */
  policy_types_stream: Array<Policy_Types>;
  /** fetch data from the table: "policy_version_statuses" */
  policy_version_statuses: Array<Policy_Version_Statuses>;
  /** fetch aggregated fields from the table: "policy_version_statuses" */
  policy_version_statuses_aggregate: Policy_Version_Statuses_Aggregate;
  /** fetch data from the table: "policy_version_statuses" using primary key columns */
  policy_version_statuses_by_pk?: Maybe<Policy_Version_Statuses>;
  /** fetch data from the table in a streaming manner: "policy_version_statuses" */
  policy_version_statuses_stream: Array<Policy_Version_Statuses>;
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  /** An aggregate relationship */
  policy_versions_aggregate: Policy_Versions_Aggregate;
  /** fetch data from the table: "policy_versions" using primary key columns */
  policy_versions_by_pk?: Maybe<Policy_Versions>;
  /** fetch data from the table in a streaming manner: "policy_versions" */
  policy_versions_stream: Array<Policy_Versions>;
  /** An array relationship */
  program_controls: Array<Program_Controls>;
  /** An aggregate relationship */
  program_controls_aggregate: Program_Controls_Aggregate;
  /** fetch data from the table: "program_controls" using primary key columns */
  program_controls_by_pk?: Maybe<Program_Controls>;
  /** fetch data from the table in a streaming manner: "program_controls" */
  program_controls_stream: Array<Program_Controls>;
  /** An array relationship */
  program_field_configs: Array<Program_Field_Configs>;
  /** An aggregate relationship */
  program_field_configs_aggregate: Program_Field_Configs_Aggregate;
  /** fetch data from the table: "program_field_configs" using primary key columns */
  program_field_configs_by_pk?: Maybe<Program_Field_Configs>;
  /** fetch data from the table in a streaming manner: "program_field_configs" */
  program_field_configs_stream: Array<Program_Field_Configs>;
  /** An array relationship */
  programs: Array<Programs>;
  /** An aggregate relationship */
  programs_aggregate: Programs_Aggregate;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** fetch data from the table in a streaming manner: "programs" */
  programs_stream: Array<Programs>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  /** An aggregate relationship */
  questionnaire_uploads_aggregate: Questionnaire_Uploads_Aggregate;
  /** fetch data from the table: "questionnaire_uploads" using primary key columns */
  questionnaire_uploads_by_pk?: Maybe<Questionnaire_Uploads>;
  /** fetch data from the table in a streaming manner: "questionnaire_uploads" */
  questionnaire_uploads_stream: Array<Questionnaire_Uploads>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table in a streaming manner: "questionnaires" */
  questionnaires_stream: Array<Questionnaires>;
  /** fetch data from the table: "report_types" */
  report_types: Array<Report_Types>;
  /** fetch aggregated fields from the table: "report_types" */
  report_types_aggregate: Report_Types_Aggregate;
  /** fetch data from the table: "report_types" using primary key columns */
  report_types_by_pk?: Maybe<Report_Types>;
  /** fetch data from the table in a streaming manner: "report_types" */
  report_types_stream: Array<Report_Types>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  reports_stream: Array<Reports>;
  /** fetch data from the table: "review_files" */
  review_files: Array<Review_Files>;
  /** fetch aggregated fields from the table: "review_files" */
  review_files_aggregate: Review_Files_Aggregate;
  /** fetch data from the table: "review_files" using primary key columns */
  review_files_by_pk?: Maybe<Review_Files>;
  /** fetch data from the table in a streaming manner: "review_files" */
  review_files_stream: Array<Review_Files>;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** fetch data from the table: "reviews" using primary key columns */
  reviews_by_pk?: Maybe<Reviews>;
  /** fetch data from the table in a streaming manner: "reviews" */
  reviews_stream: Array<Reviews>;
  /** An array relationship */
  risk_categories: Array<Risk_Categories>;
  /** An aggregate relationship */
  risk_categories_aggregate: Risk_Categories_Aggregate;
  /** fetch data from the table: "risk_categories" using primary key columns */
  risk_categories_by_pk?: Maybe<Risk_Categories>;
  /** fetch data from the table in a streaming manner: "risk_categories" */
  risk_categories_stream: Array<Risk_Categories>;
  /** An array relationship */
  risk_controls: Array<Risk_Controls>;
  /** An aggregate relationship */
  risk_controls_aggregate: Risk_Controls_Aggregate;
  /** fetch data from the table: "risk_controls" using primary key columns */
  risk_controls_by_pk?: Maybe<Risk_Controls>;
  /** fetch data from the table in a streaming manner: "risk_controls" */
  risk_controls_stream: Array<Risk_Controls>;
  /** fetch data from the table: "risk_documents" */
  risk_documents: Array<Risk_Documents>;
  /** fetch aggregated fields from the table: "risk_documents" */
  risk_documents_aggregate: Risk_Documents_Aggregate;
  /** fetch data from the table: "risk_documents" using primary key columns */
  risk_documents_by_pk?: Maybe<Risk_Documents>;
  /** fetch data from the table in a streaming manner: "risk_documents" */
  risk_documents_stream: Array<Risk_Documents>;
  /** An array relationship */
  risk_impacts: Array<Risk_Impacts>;
  /** An aggregate relationship */
  risk_impacts_aggregate: Risk_Impacts_Aggregate;
  /** fetch data from the table: "risk_impacts" using primary key columns */
  risk_impacts_by_pk?: Maybe<Risk_Impacts>;
  /** fetch data from the table in a streaming manner: "risk_impacts" */
  risk_impacts_stream: Array<Risk_Impacts>;
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** An aggregate relationship */
  risk_inherent_levels_aggregate: Risk_Inherent_Levels_Aggregate;
  /** fetch data from the table: "risk_inherent_levels" using primary key columns */
  risk_inherent_levels_by_pk?: Maybe<Risk_Inherent_Levels>;
  /** fetch data from the table in a streaming manner: "risk_inherent_levels" */
  risk_inherent_levels_stream: Array<Risk_Inherent_Levels>;
  /** fetch data from the table: "risk_levels" */
  risk_levels: Array<Risk_Levels>;
  /** fetch aggregated fields from the table: "risk_levels" */
  risk_levels_aggregate: Risk_Levels_Aggregate;
  /** fetch data from the table: "risk_levels" using primary key columns */
  risk_levels_by_pk?: Maybe<Risk_Levels>;
  /** fetch data from the table in a streaming manner: "risk_levels" */
  risk_levels_stream: Array<Risk_Levels>;
  /** An array relationship */
  risk_likelihoods: Array<Risk_Likelihoods>;
  /** An aggregate relationship */
  risk_likelihoods_aggregate: Risk_Likelihoods_Aggregate;
  /** fetch data from the table: "risk_likelihoods" using primary key columns */
  risk_likelihoods_by_pk?: Maybe<Risk_Likelihoods>;
  /** fetch data from the table in a streaming manner: "risk_likelihoods" */
  risk_likelihoods_stream: Array<Risk_Likelihoods>;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  /** An aggregate relationship */
  risk_residual_levels_aggregate: Risk_Residual_Levels_Aggregate;
  /** fetch data from the table: "risk_residual_levels" using primary key columns */
  risk_residual_levels_by_pk?: Maybe<Risk_Residual_Levels>;
  /** fetch data from the table in a streaming manner: "risk_residual_levels" */
  risk_residual_levels_stream: Array<Risk_Residual_Levels>;
  /** An array relationship */
  risk_tags: Array<Risk_Tags>;
  /** An aggregate relationship */
  risk_tags_aggregate: Risk_Tags_Aggregate;
  /** fetch data from the table: "risk_tags" using primary key columns */
  risk_tags_by_pk?: Maybe<Risk_Tags>;
  /** fetch data from the table in a streaming manner: "risk_tags" */
  risk_tags_stream: Array<Risk_Tags>;
  /** fetch data from the table: "risk_tasks" */
  risk_tasks: Array<Risk_Tasks>;
  /** fetch aggregated fields from the table: "risk_tasks" */
  risk_tasks_aggregate: Risk_Tasks_Aggregate;
  /** fetch data from the table: "risk_tasks" using primary key columns */
  risk_tasks_by_pk?: Maybe<Risk_Tasks>;
  /** fetch data from the table in a streaming manner: "risk_tasks" */
  risk_tasks_stream: Array<Risk_Tasks>;
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** fetch data from the table: "risks" using primary key columns */
  risks_by_pk?: Maybe<Risks>;
  /** fetch data from the table in a streaming manner: "risks" */
  risks_stream: Array<Risks>;
  /** An array relationship */
  role_permissions: Array<Role_Permissions>;
  /** An aggregate relationship */
  role_permissions_aggregate: Role_Permissions_Aggregate;
  /** fetch data from the table: "role_permissions" using primary key columns */
  role_permissions_by_pk?: Maybe<Role_Permissions>;
  /** fetch data from the table in a streaming manner: "role_permissions" */
  role_permissions_stream: Array<Role_Permissions>;
  /** An array relationship */
  roles: Array<Roles>;
  /** An aggregate relationship */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** An array relationship */
  select_field_configs: Array<Select_Field_Configs>;
  /** An aggregate relationship */
  select_field_configs_aggregate: Select_Field_Configs_Aggregate;
  /** fetch data from the table: "select_field_configs" using primary key columns */
  select_field_configs_by_pk?: Maybe<Select_Field_Configs>;
  /** fetch data from the table in a streaming manner: "select_field_configs" */
  select_field_configs_stream: Array<Select_Field_Configs>;
  /** An array relationship */
  select_field_options: Array<Select_Field_Options>;
  /** An aggregate relationship */
  select_field_options_aggregate: Select_Field_Options_Aggregate;
  /** fetch data from the table: "select_field_options" using primary key columns */
  select_field_options_by_pk?: Maybe<Select_Field_Options>;
  /** fetch data from the table in a streaming manner: "select_field_options" */
  select_field_options_stream: Array<Select_Field_Options>;
  /** An array relationship */
  select_field_values: Array<Select_Field_Values>;
  /** An aggregate relationship */
  select_field_values_aggregate: Select_Field_Values_Aggregate;
  /** fetch data from the table: "select_field_values" using primary key columns */
  select_field_values_by_pk?: Maybe<Select_Field_Values>;
  /** fetch data from the table in a streaming manner: "select_field_values" */
  select_field_values_stream: Array<Select_Field_Values>;
  /** fetch data from the table: "system_roles" */
  system_roles: Array<System_Roles>;
  /** fetch aggregated fields from the table: "system_roles" */
  system_roles_aggregate: System_Roles_Aggregate;
  /** fetch data from the table: "system_roles" using primary key columns */
  system_roles_by_pk?: Maybe<System_Roles>;
  /** fetch data from the table in a streaming manner: "system_roles" */
  system_roles_stream: Array<System_Roles>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An aggregate relationship */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  tags_stream: Array<Tags>;
  /** An array relationship */
  task_owners: Array<Task_Owners>;
  /** An aggregate relationship */
  task_owners_aggregate: Task_Owners_Aggregate;
  /** fetch data from the table: "task_owners" using primary key columns */
  task_owners_by_pk?: Maybe<Task_Owners>;
  /** fetch data from the table in a streaming manner: "task_owners" */
  task_owners_stream: Array<Task_Owners>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table in a streaming manner: "tasks" */
  tasks_stream: Array<Tasks>;
  /** An array relationship */
  template_control_ai_review_criteria: Array<Template_Control_Ai_Review_Criteria>;
  /** An aggregate relationship */
  template_control_ai_review_criteria_aggregate: Template_Control_Ai_Review_Criteria_Aggregate;
  /** fetch data from the table: "template_control_ai_review_criteria" using primary key columns */
  template_control_ai_review_criteria_by_pk?: Maybe<Template_Control_Ai_Review_Criteria>;
  /** fetch data from the table in a streaming manner: "template_control_ai_review_criteria" */
  template_control_ai_review_criteria_stream: Array<Template_Control_Ai_Review_Criteria>;
  /** An array relationship */
  template_controls: Array<Template_Controls>;
  /** An aggregate relationship */
  template_controls_aggregate: Template_Controls_Aggregate;
  /** fetch data from the table: "template_controls" using primary key columns */
  template_controls_by_pk?: Maybe<Template_Controls>;
  /** fetch data from the table in a streaming manner: "template_controls" */
  template_controls_stream: Array<Template_Controls>;
  /** fetch data from the table: "template_frameworks" */
  template_frameworks: Array<Template_Frameworks>;
  /** fetch aggregated fields from the table: "template_frameworks" */
  template_frameworks_aggregate: Template_Frameworks_Aggregate;
  /** fetch data from the table: "template_frameworks" using primary key columns */
  template_frameworks_by_pk?: Maybe<Template_Frameworks>;
  /** An array relationship */
  template_frameworks_controls: Array<Template_Frameworks_Controls>;
  /** An aggregate relationship */
  template_frameworks_controls_aggregate: Template_Frameworks_Controls_Aggregate;
  /** fetch data from the table: "template_frameworks_controls" using primary key columns */
  template_frameworks_controls_by_pk?: Maybe<Template_Frameworks_Controls>;
  /** fetch data from the table in a streaming manner: "template_frameworks_controls" */
  template_frameworks_controls_stream: Array<Template_Frameworks_Controls>;
  /** fetch data from the table in a streaming manner: "template_frameworks" */
  template_frameworks_stream: Array<Template_Frameworks>;
  /** An array relationship */
  template_tasks: Array<Template_Tasks>;
  /** An aggregate relationship */
  template_tasks_aggregate: Template_Tasks_Aggregate;
  /** fetch data from the table: "template_tasks" using primary key columns */
  template_tasks_by_pk?: Maybe<Template_Tasks>;
  /** fetch data from the table in a streaming manner: "template_tasks" */
  template_tasks_stream: Array<Template_Tasks>;
  /** An array relationship */
  text_field_values: Array<Text_Field_Values>;
  /** An aggregate relationship */
  text_field_values_aggregate: Text_Field_Values_Aggregate;
  /** fetch data from the table: "text_field_values" using primary key columns */
  text_field_values_by_pk?: Maybe<Text_Field_Values>;
  /** fetch data from the table in a streaming manner: "text_field_values" */
  text_field_values_stream: Array<Text_Field_Values>;
  /** fetch data from the table: "treatment_plan" */
  treatment_plan: Array<Treatment_Plan>;
  /** fetch aggregated fields from the table: "treatment_plan" */
  treatment_plan_aggregate: Treatment_Plan_Aggregate;
  /** fetch data from the table: "treatment_plan" using primary key columns */
  treatment_plan_by_pk?: Maybe<Treatment_Plan>;
  /** fetch data from the table in a streaming manner: "treatment_plan" */
  treatment_plan_stream: Array<Treatment_Plan>;
  /** An array relationship */
  url_field_values: Array<Url_Field_Values>;
  /** An aggregate relationship */
  url_field_values_aggregate: Url_Field_Values_Aggregate;
  /** fetch data from the table: "url_field_values" using primary key columns */
  url_field_values_by_pk?: Maybe<Url_Field_Values>;
  /** fetch data from the table in a streaming manner: "url_field_values" */
  url_field_values_stream: Array<Url_Field_Values>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_controls_stats_view" */
  user_controls_stats_view: Array<User_Controls_Stats_View>;
  /** fetch aggregated fields from the table: "user_controls_stats_view" */
  user_controls_stats_view_aggregate: User_Controls_Stats_View_Aggregate;
  /** fetch data from the table in a streaming manner: "user_controls_stats_view" */
  user_controls_stats_view_stream: Array<User_Controls_Stats_View>;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** fetch data from the table: "user_notifications" using primary key columns */
  user_notifications_by_pk?: Maybe<User_Notifications>;
  /** fetch data from the table in a streaming manner: "user_notifications" */
  user_notifications_stream: Array<User_Notifications>;
  /** fetch data from the table: "user_tasks_stats_view" */
  user_tasks_stats_view: Array<User_Tasks_Stats_View>;
  /** fetch aggregated fields from the table: "user_tasks_stats_view" */
  user_tasks_stats_view_aggregate: User_Tasks_Stats_View_Aggregate;
  /** fetch data from the table in a streaming manner: "user_tasks_stats_view" */
  user_tasks_stats_view_stream: Array<User_Tasks_Stats_View>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "auth.users" */
  usersAggregate: Users_Aggregate;
  /** fetch data from the table in a streaming manner: "auth.users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "vendor_approval_statuses" */
  vendor_approval_statuses: Array<Vendor_Approval_Statuses>;
  /** fetch aggregated fields from the table: "vendor_approval_statuses" */
  vendor_approval_statuses_aggregate: Vendor_Approval_Statuses_Aggregate;
  /** fetch data from the table: "vendor_approval_statuses" using primary key columns */
  vendor_approval_statuses_by_pk?: Maybe<Vendor_Approval_Statuses>;
  /** fetch data from the table in a streaming manner: "vendor_approval_statuses" */
  vendor_approval_statuses_stream: Array<Vendor_Approval_Statuses>;
  /** fetch data from the table: "vendor_documents" */
  vendor_documents: Array<Vendor_Documents>;
  /** fetch aggregated fields from the table: "vendor_documents" */
  vendor_documents_aggregate: Vendor_Documents_Aggregate;
  /** fetch data from the table: "vendor_documents" using primary key columns */
  vendor_documents_by_pk?: Maybe<Vendor_Documents>;
  /** fetch data from the table in a streaming manner: "vendor_documents" */
  vendor_documents_stream: Array<Vendor_Documents>;
  /** An array relationship */
  vendor_findings: Array<Vendor_Findings>;
  /** An aggregate relationship */
  vendor_findings_aggregate: Vendor_Findings_Aggregate;
  /** fetch data from the table: "vendor_findings" using primary key columns */
  vendor_findings_by_pk?: Maybe<Vendor_Findings>;
  /** fetch data from the table in a streaming manner: "vendor_findings" */
  vendor_findings_stream: Array<Vendor_Findings>;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessment_sources: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_assessment_sources_aggregate: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_ai_review_assessment_sources" using primary key columns */
  vendor_questionnaire_ai_review_assessment_sources_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_ai_review_assessment_sources" */
  vendor_questionnaire_ai_review_assessment_sources_stream: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessments: Array<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_assessments_aggregate: Vendor_Questionnaire_Ai_Review_Assessments_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_ai_review_assessments" using primary key columns */
  vendor_questionnaire_ai_review_assessments_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_ai_review_assessments" */
  vendor_questionnaire_ai_review_assessments_stream: Array<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** fetch data from the table: "vendor_questionnaire_ai_review_run_status" */
  vendor_questionnaire_ai_review_run_status: Array<Vendor_Questionnaire_Ai_Review_Run_Status>;
  /** fetch aggregated fields from the table: "vendor_questionnaire_ai_review_run_status" */
  vendor_questionnaire_ai_review_run_status_aggregate: Vendor_Questionnaire_Ai_Review_Run_Status_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_ai_review_run_status" using primary key columns */
  vendor_questionnaire_ai_review_run_status_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_ai_review_run_status" */
  vendor_questionnaire_ai_review_run_status_stream: Array<Vendor_Questionnaire_Ai_Review_Run_Status>;
  /** An array relationship */
  vendor_questionnaire_ai_review_runs: Array<Vendor_Questionnaire_Ai_Review_Runs>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_runs_aggregate: Vendor_Questionnaire_Ai_Review_Runs_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_ai_review_runs" using primary key columns */
  vendor_questionnaire_ai_review_runs_by_pk?: Maybe<Vendor_Questionnaire_Ai_Review_Runs>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_ai_review_runs" */
  vendor_questionnaire_ai_review_runs_stream: Array<Vendor_Questionnaire_Ai_Review_Runs>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch aggregated fields from the table: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses_aggregate: Vendor_Questionnaire_Form_Answer_Statuses_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" using primary key columns */
  vendor_questionnaire_form_answer_statuses_by_pk?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses_stream: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
  /** An aggregate relationship */
  vendor_questionnaire_form_answers_aggregate: Vendor_Questionnaire_Form_Answers_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_form_answers" using primary key columns */
  vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_answers" */
  vendor_questionnaire_form_answers_stream: Array<Vendor_Questionnaire_Form_Answers>;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** An aggregate relationship */
  vendor_questionnaire_form_uploads_aggregate: Vendor_Questionnaire_Form_Uploads_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_form_uploads" using primary key columns */
  vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_uploads" */
  vendor_questionnaire_form_uploads_stream: Array<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms: Array<Vendor_Questionnaire_Forms>;
  /** fetch aggregated fields from the table: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms_aggregate: Vendor_Questionnaire_Forms_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_forms" using primary key columns */
  vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms_stream: Array<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses: Array<Vendor_Questionnaire_Statuses>;
  /** fetch aggregated fields from the table: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses_aggregate: Vendor_Questionnaire_Statuses_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_statuses" using primary key columns */
  vendor_questionnaire_statuses_by_pk?: Maybe<Vendor_Questionnaire_Statuses>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses_stream: Array<Vendor_Questionnaire_Statuses>;
  /** An array relationship */
  vendor_questionnaire_uploads: Array<Vendor_Questionnaire_Uploads>;
  /** An aggregate relationship */
  vendor_questionnaire_uploads_aggregate: Vendor_Questionnaire_Uploads_Aggregate;
  /** fetch data from the table: "vendor_questionnaire_uploads" using primary key columns */
  vendor_questionnaire_uploads_by_pk?: Maybe<Vendor_Questionnaire_Uploads>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_uploads" */
  vendor_questionnaire_uploads_stream: Array<Vendor_Questionnaire_Uploads>;
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
  /** An aggregate relationship */
  vendor_questionnaires_aggregate: Vendor_Questionnaires_Aggregate;
  /** fetch data from the table: "vendor_questionnaires" using primary key columns */
  vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaires" */
  vendor_questionnaires_stream: Array<Vendor_Questionnaires>;
  /** An array relationship */
  vendor_risks: Array<Vendor_Risks>;
  /** An aggregate relationship */
  vendor_risks_aggregate: Vendor_Risks_Aggregate;
  /** fetch data from the table: "vendor_risks" using primary key columns */
  vendor_risks_by_pk?: Maybe<Vendor_Risks>;
  /** fetch data from the table in a streaming manner: "vendor_risks" */
  vendor_risks_stream: Array<Vendor_Risks>;
  /** fetch data from the table: "vendor_tasks" */
  vendor_tasks: Array<Vendor_Tasks>;
  /** fetch aggregated fields from the table: "vendor_tasks" */
  vendor_tasks_aggregate: Vendor_Tasks_Aggregate;
  /** fetch data from the table: "vendor_tasks" using primary key columns */
  vendor_tasks_by_pk?: Maybe<Vendor_Tasks>;
  /** fetch data from the table in a streaming manner: "vendor_tasks" */
  vendor_tasks_stream: Array<Vendor_Tasks>;
  /** An array relationship */
  vendors: Array<Vendors>;
  /** An aggregate relationship */
  vendors_aggregate: Vendors_Aggregate;
  /** fetch data from the table: "vendors" using primary key columns */
  vendors_by_pk?: Maybe<Vendors>;
  /** fetch data from the table in a streaming manner: "vendors" */
  vendors_stream: Array<Vendors>;
  /** fetch data from the table: "vendors_summary_view" */
  vendors_summary_view: Array<Vendors_Summary_View>;
  /** fetch aggregated fields from the table: "vendors_summary_view" */
  vendors_summary_view_aggregate: Vendors_Summary_View_Aggregate;
  /** fetch data from the table in a streaming manner: "vendors_summary_view" */
  vendors_summary_view_stream: Array<Vendors_Summary_View>;
};

export type Subscription_RootAi_Control_Evidences_SuggestionsArgs = {
  args: Ai_Control_Evidences_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Control_Evidences_Suggestions_AggregateArgs = {
  args: Ai_Control_Evidences_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Text_SearchArgs = {
  args: Ai_Controls_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Text_Search_AggregateArgs = {
  args: Ai_Controls_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Controls_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Controls_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Controls_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Controls_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Controls_Vector_Store_SearchArgs = {
  args: Ai_Controls_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Vector_Store_Search_AggregateArgs = {
  args: Ai_Controls_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Controls_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Text_SearchArgs = {
  args: Ai_Evidences_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Text_Search_AggregateArgs = {
  args: Ai_Evidences_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Evidences_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Evidences_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Evidences_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Evidences_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Evidences_Vector_Store_SearchArgs = {
  args: Ai_Evidences_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Vector_Store_Search_AggregateArgs = {
  args: Ai_Evidences_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Evidences_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Files_Document_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Document_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Document_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
};

export type Subscription_RootAi_Files_Document_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Document_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Document_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
};

export type Subscription_RootAi_Files_Document_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Files_Document_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Files_Document_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Files_Document_Store_Bool_Exp>;
};

export type Subscription_RootAi_Files_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Files_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Files_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Files_Vector_Store_SearchArgs = {
  args: Ai_Files_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Files_Vector_Store_Search_AggregateArgs = {
  args: Ai_Files_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Ai_Files_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Files_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Files_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Files_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Files_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Text_SearchArgs = {
  args: Ai_Policies_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Text_Search_AggregateArgs = {
  args: Ai_Policies_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Policies_Vector_Store_SearchArgs = {
  args: Ai_Policies_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Vector_Store_Search_AggregateArgs = {
  args: Ai_Policies_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Policies_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Text_SearchArgs = {
  args: Ai_Programs_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Text_Search_AggregateArgs = {
  args: Ai_Programs_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Programs_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Programs_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Programs_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Programs_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Programs_Vector_Store_SearchArgs = {
  args: Ai_Programs_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Vector_Store_Search_AggregateArgs = {
  args: Ai_Programs_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Programs_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Text_SearchArgs = {
  args: Ai_Risks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Text_Search_AggregateArgs = {
  args: Ai_Risks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Risks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Risks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Risks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Risks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Risks_Vector_Store_SearchArgs = {
  args: Ai_Risks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Vector_Store_Search_AggregateArgs = {
  args: Ai_Risks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Risks_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Text_SearchArgs = {
  args: Ai_Tasks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Text_Search_AggregateArgs = {
  args: Ai_Tasks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Tasks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Tasks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Tasks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Tasks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Tasks_Vector_Store_SearchArgs = {
  args: Ai_Tasks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Vector_Store_Search_AggregateArgs = {
  args: Ai_Tasks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Tasks_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Text_SearchArgs = {
  args: Ai_Vendors_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Text_Search_AggregateArgs = {
  args: Ai_Vendors_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Vendors_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Vendors_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Vector_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ai_Vendors_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Vendors_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Vendors_Vector_Store_SearchArgs = {
  args: Ai_Vendors_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Vector_Store_Search_AggregateArgs = {
  args: Ai_Vendors_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Vendors_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAudit_Control_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Control_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Control_History_View_Order_By>>;
  where?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Control_History_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Control_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Control_History_View_Order_By>>;
  where?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Control_History_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Control_History_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Logged_ActionsArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Subscription_RootAudit_Logged_Actions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Logged_Actions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Logged_Actions_Order_By>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Subscription_RootAudit_Logged_Actions_By_PkArgs = {
  event_id: Scalars['bigint']['input'];
};

export type Subscription_RootAudit_Logged_Actions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Logged_Actions_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Logged_Actions_Bool_Exp>;
};

export type Subscription_RootAudit_Policy_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Policy_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Policy_History_View_Order_By>>;
  where?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Policy_History_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Policy_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Policy_History_View_Order_By>>;
  where?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Policy_History_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Policy_History_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Risk_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Risk_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Risk_History_View_Order_By>>;
  where?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Risk_History_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Risk_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Risk_History_View_Order_By>>;
  where?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Risk_History_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Risk_History_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Vendor_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Vendor_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Vendor_History_View_Order_By>>;
  where?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Vendor_History_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Vendor_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Vendor_History_View_Order_By>>;
  where?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Vendor_History_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Vendor_History_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
};

export type Subscription_RootAuthProviderArgs = {
  id: Scalars['String']['input'];
};

export type Subscription_RootAuthProviderRequestArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAuthProviderRequestsArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};

export type Subscription_RootAuthProviderRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviderRequests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviderRequests_Order_By>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};

export type Subscription_RootAuthProviderRequests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProviderRequests_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthProviderRequests_Bool_Exp>;
};

export type Subscription_RootAuthProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};

export type Subscription_RootAuthProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthProviders_Order_By>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};

export type Subscription_RootAuthProviders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthProviders_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthProviders_Bool_Exp>;
};

export type Subscription_RootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAuthRefreshTokenTypeArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootAuthRefreshTokenTypesArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};

export type Subscription_RootAuthRefreshTokenTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokenTypes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokenTypes_Order_By>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};

export type Subscription_RootAuthRefreshTokenTypes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokenTypes_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRefreshTokenTypes_Bool_Exp>;
};

export type Subscription_RootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

export type Subscription_RootAuthRefreshTokensAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

export type Subscription_RootAuthRefreshTokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokens_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

export type Subscription_RootAuthRoleArgs = {
  role: Scalars['String']['input'];
};

export type Subscription_RootAuthRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};

export type Subscription_RootAuthRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRoles_Order_By>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};

export type Subscription_RootAuthRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRoles_Bool_Exp>;
};

export type Subscription_RootAuthUserProviderArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAuthUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

export type Subscription_RootAuthUserProvidersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

export type Subscription_RootAuthUserProviders_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserProviders_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

export type Subscription_RootAuthUserRoleArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAuthUserRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

export type Subscription_RootAuthUserRolesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

export type Subscription_RootAuthUserRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserRoles_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

export type Subscription_RootAuthUserSecurityKeyArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAuthUserSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

export type Subscription_RootAuthUserSecurityKeysAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

export type Subscription_RootAuthUserSecurityKeys_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserSecurityKeys_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_IndexArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Index_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_Index_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Index_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Index_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_Index_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootAuth_Jackson_Index_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Jackson_Index_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Jackson_Index_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_StoreArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Store_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Store_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_Store_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Store_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Store_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_Store_By_PkArgs = {
  key: Scalars['String']['input'];
};

export type Subscription_RootAuth_Jackson_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Jackson_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Jackson_Store_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_TtlArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Ttl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Ttl_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Ttl_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_Ttl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Jackson_Ttl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Jackson_Ttl_Order_By>>;
  where?: InputMaybe<Auth_Jackson_Ttl_Bool_Exp>;
};

export type Subscription_RootAuth_Jackson_Ttl_By_PkArgs = {
  key: Scalars['String']['input'];
};

export type Subscription_RootAuth_Jackson_Ttl_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Jackson_Ttl_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Jackson_Ttl_Bool_Exp>;
};

export type Subscription_RootAuth_MigrationsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Migrations_Order_By>>;
  where?: InputMaybe<Auth_Migrations_Bool_Exp>;
};

export type Subscription_RootAuth_Migrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Auth_Migrations_Order_By>>;
  where?: InputMaybe<Auth_Migrations_Bool_Exp>;
};

export type Subscription_RootAuth_Migrations_By_PkArgs = {
  id: Scalars['Int']['input'];
};

export type Subscription_RootAuth_Migrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Auth_Migrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Auth_Migrations_Bool_Exp>;
};

export type Subscription_RootBucketArgs = {
  id: Scalars['String']['input'];
};

export type Subscription_RootBucketsArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

export type Subscription_RootBucketsAggregateArgs = {
  distinct_on?: InputMaybe<Array<Buckets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Buckets_Order_By>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

export type Subscription_RootBuckets_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Buckets_Stream_Cursor_Input>>;
  where?: InputMaybe<Buckets_Bool_Exp>;
};

export type Subscription_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Answer_Ai_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Answer_Ai_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answer_Ai_Sources_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Answer_Ai_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootClient_Questionnaire_Answer_Ai_Sources_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Questionnaire_Answer_Ai_Sources_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootClient_Questionnaire_Answers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Questionnaire_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Question_StatusArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Question_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Question_Status_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Question_Status_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Question_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Question_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Question_Status_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Question_Status_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Question_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootClient_Questionnaire_Question_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Questionnaire_Question_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Questionnaire_Question_Status_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootClient_Questionnaire_Questions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Questionnaire_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_StatusArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Status_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Status_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Status_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Status_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootClient_Questionnaire_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Questionnaire_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Questionnaire_Status_Bool_Exp>;
};

export type Subscription_RootClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootClient_Questionnaires_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

export type Subscription_RootComment_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Uploads_Order_By>>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

export type Subscription_RootComment_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Uploads_Order_By>>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

export type Subscription_RootComment_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootComment_Uploads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Comment_Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

export type Subscription_RootCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Subscription_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Subscription_RootComments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootComments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Assessment_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Assessment_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Assessment_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Ai_Review_Assessment_Sources_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Ai_Review_Assessment_Sources_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Assessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Assessments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Ai_Review_Assessments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Ai_Review_Assessments_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Criteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Ai_Review_Criteria_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Ai_Review_Criteria_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_RunsArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Ai_Review_Runs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Ai_Review_Runs_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Ai_Review_Runs_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_StatusArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Status_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Status_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Ai_Review_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Ai_Review_Status_Order_By>>;
  where?: InputMaybe<Control_Ai_Review_Status_Bool_Exp>;
};

export type Subscription_RootControl_Ai_Review_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootControl_Ai_Review_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Ai_Review_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Ai_Review_Status_Bool_Exp>;
};

export type Subscription_RootControl_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

export type Subscription_RootControl_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

export type Subscription_RootControl_Categories_By_PkArgs = {
  category_id: Scalars['uuid']['input'];
  control_id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

export type Subscription_RootControl_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

export type Subscription_RootControl_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

export type Subscription_RootControl_Criteria_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  criteria_id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Criteria_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Criteria_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

export type Subscription_RootControl_EvidencesArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

export type Subscription_RootControl_Evidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

export type Subscription_RootControl_Evidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Evidences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Evidences_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

export type Subscription_RootControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

export type Subscription_RootControl_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

export type Subscription_RootControl_Findings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Findings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Findings_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

export type Subscription_RootControl_FrequenciesArgs = {
  distinct_on?: InputMaybe<Array<Control_Frequencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Frequencies_Order_By>>;
  where?: InputMaybe<Control_Frequencies_Bool_Exp>;
};

export type Subscription_RootControl_Frequencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Frequencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Frequencies_Order_By>>;
  where?: InputMaybe<Control_Frequencies_Bool_Exp>;
};

export type Subscription_RootControl_Frequencies_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootControl_Frequencies_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Frequencies_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Frequencies_Bool_Exp>;
};

export type Subscription_RootControl_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

export type Subscription_RootControl_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

export type Subscription_RootControl_Groups_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  group_id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Groups_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

export type Subscription_RootControl_PrioritiesArgs = {
  distinct_on?: InputMaybe<Array<Control_Priorities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Priorities_Order_By>>;
  where?: InputMaybe<Control_Priorities_Bool_Exp>;
};

export type Subscription_RootControl_Priorities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Priorities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Priorities_Order_By>>;
  where?: InputMaybe<Control_Priorities_Bool_Exp>;
};

export type Subscription_RootControl_Priorities_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootControl_Priorities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Priorities_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Priorities_Bool_Exp>;
};

export type Subscription_RootControl_TagsArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

export type Subscription_RootControl_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

export type Subscription_RootControl_Tags_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  tag_id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

export type Subscription_RootControl_TasksArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

export type Subscription_RootControl_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

export type Subscription_RootControl_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControl_Tasks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Control_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

export type Subscription_RootControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootControls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControls_History_StatsArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

export type Subscription_RootControls_History_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

export type Subscription_RootControls_History_Stats_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControls_History_Stats_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Controls_History_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

export type Subscription_RootControls_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Stats_View_Order_By>>;
  where?: InputMaybe<Controls_Stats_View_Bool_Exp>;
};

export type Subscription_RootControls_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Stats_View_Order_By>>;
  where?: InputMaybe<Controls_Stats_View_Bool_Exp>;
};

export type Subscription_RootControls_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Controls_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Controls_Stats_View_Bool_Exp>;
};

export type Subscription_RootControls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootCriteriaArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

export type Subscription_RootCriteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

export type Subscription_RootCriteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootCriteria_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Criteria_Stream_Cursor_Input>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

export type Subscription_RootDaily_Control_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
};

export type Subscription_RootDaily_Control_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
};

export type Subscription_RootDaily_Control_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Daily_Control_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
};

export type Subscription_RootDaily_Control_User_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_User_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_User_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
};

export type Subscription_RootDaily_Control_User_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_User_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_User_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
};

export type Subscription_RootDaily_Control_User_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Daily_Control_User_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
};

export type Subscription_RootDate_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Date_Field_Values_Order_By>>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

export type Subscription_RootDate_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Date_Field_Values_Order_By>>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

export type Subscription_RootDate_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Subscription_RootDate_Field_Values_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Date_Field_Values_Stream_Cursor_Input>>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

export type Subscription_RootEmail_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Email_Field_Values_Order_By>>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

export type Subscription_RootEmail_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Email_Field_Values_Order_By>>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

export type Subscription_RootEmail_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Subscription_RootEmail_Field_Values_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Email_Field_Values_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

export type Subscription_RootEvidence_AclArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

export type Subscription_RootEvidence_Acl_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

export type Subscription_RootEvidence_Acl_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidence_Acl_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidence_Acl_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

export type Subscription_RootEvidence_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

export type Subscription_RootEvidence_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

export type Subscription_RootEvidence_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidence_Integrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidence_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

export type Subscription_RootEvidence_PoliciesArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Policies_Order_By>>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

export type Subscription_RootEvidence_Policies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Policies_Order_By>>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

export type Subscription_RootEvidence_Policies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidence_Policies_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidence_Policies_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

export type Subscription_RootEvidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

export type Subscription_RootEvidence_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

export type Subscription_RootEvidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidence_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidence_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

export type Subscription_RootEvidence_Version_FileArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Version_File_Order_By>>;
  where?: InputMaybe<Evidence_Version_File_Bool_Exp>;
};

export type Subscription_RootEvidence_Version_File_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Version_File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Version_File_Order_By>>;
  where?: InputMaybe<Evidence_Version_File_Bool_Exp>;
};

export type Subscription_RootEvidence_Version_File_By_PkArgs = {
  evidence_version_id: Scalars['uuid']['input'];
  file_id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidence_Version_File_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidence_Version_File_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidence_Version_File_Bool_Exp>;
};

export type Subscription_RootEvidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

export type Subscription_RootEvidence_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

export type Subscription_RootEvidence_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidence_Versions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidence_Versions_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

export type Subscription_RootEvidencesArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootEvidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootEvidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidences_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootField_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

export type Subscription_RootField_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

export type Subscription_RootField_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootField_Configs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Configs_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

export type Subscription_RootField_EntitiesArgs = {
  distinct_on?: InputMaybe<Array<Field_Entities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Entities_Order_By>>;
  where?: InputMaybe<Field_Entities_Bool_Exp>;
};

export type Subscription_RootField_Entities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Entities_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Entities_Order_By>>;
  where?: InputMaybe<Field_Entities_Bool_Exp>;
};

export type Subscription_RootField_Entities_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootField_Entities_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Entities_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Entities_Bool_Exp>;
};

export type Subscription_RootField_TypesArgs = {
  distinct_on?: InputMaybe<Array<Field_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Types_Order_By>>;
  where?: InputMaybe<Field_Types_Bool_Exp>;
};

export type Subscription_RootField_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Types_Order_By>>;
  where?: InputMaybe<Field_Types_Bool_Exp>;
};

export type Subscription_RootField_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootField_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Types_Bool_Exp>;
};

export type Subscription_RootField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

export type Subscription_RootField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

export type Subscription_RootField_Values_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootField_Values_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Values_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

export type Subscription_RootFileArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Subscription_RootFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

export type Subscription_RootFinding_CausesArgs = {
  distinct_on?: InputMaybe<Array<Finding_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Finding_Causes_Order_By>>;
  where?: InputMaybe<Finding_Causes_Bool_Exp>;
};

export type Subscription_RootFinding_Causes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Finding_Causes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Finding_Causes_Order_By>>;
  where?: InputMaybe<Finding_Causes_Bool_Exp>;
};

export type Subscription_RootFinding_Causes_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootFinding_Causes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Finding_Causes_Stream_Cursor_Input>>;
  where?: InputMaybe<Finding_Causes_Bool_Exp>;
};

export type Subscription_RootFinding_TypesArgs = {
  distinct_on?: InputMaybe<Array<Finding_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Finding_Types_Order_By>>;
  where?: InputMaybe<Finding_Types_Bool_Exp>;
};

export type Subscription_RootFinding_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Finding_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Finding_Types_Order_By>>;
  where?: InputMaybe<Finding_Types_Bool_Exp>;
};

export type Subscription_RootFinding_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootFinding_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Finding_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Finding_Types_Bool_Exp>;
};

export type Subscription_RootFormsArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Subscription_RootForms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Forms_Order_By>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Subscription_RootForms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootForms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Forms_Stream_Cursor_Input>>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

export type Subscription_RootFrameworksArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

export type Subscription_RootFrameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

export type Subscription_RootFrameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootFrameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootFrameworks_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootFrameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

export type Subscription_RootFrameworks_Controls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Frameworks_Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootFrameworks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Frameworks_Stream_Cursor_Input>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

export type Subscription_RootFrequenciesArgs = {
  distinct_on?: InputMaybe<Array<Frequencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frequencies_Order_By>>;
  where?: InputMaybe<Frequencies_Bool_Exp>;
};

export type Subscription_RootFrequencies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Frequencies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frequencies_Order_By>>;
  where?: InputMaybe<Frequencies_Bool_Exp>;
};

export type Subscription_RootFrequencies_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootFrequencies_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Frequencies_Stream_Cursor_Input>>;
  where?: InputMaybe<Frequencies_Bool_Exp>;
};

export type Subscription_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootGroups_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

export type Subscription_RootImport_PoliciesArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootImport_ProgramArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootImport_RisksArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootImport_VendorsArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootIntegration_NamesArgs = {
  distinct_on?: InputMaybe<Array<Integration_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Names_Order_By>>;
  where?: InputMaybe<Integration_Names_Bool_Exp>;
};

export type Subscription_RootIntegration_Names_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Names_Order_By>>;
  where?: InputMaybe<Integration_Names_Bool_Exp>;
};

export type Subscription_RootIntegration_Names_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootIntegration_Names_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Integration_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Names_Bool_Exp>;
};

export type Subscription_RootIntegration_RunsArgs = {
  distinct_on?: InputMaybe<Array<Integration_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Runs_Order_By>>;
  where?: InputMaybe<Integration_Runs_Bool_Exp>;
};

export type Subscription_RootIntegration_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Runs_Order_By>>;
  where?: InputMaybe<Integration_Runs_Bool_Exp>;
};

export type Subscription_RootIntegration_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootIntegration_Runs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Integration_Runs_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Runs_Bool_Exp>;
};

export type Subscription_RootIntegration_Usage_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Integration_Usage_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Usage_Stats_View_Order_By>>;
  where?: InputMaybe<Integration_Usage_Stats_View_Bool_Exp>;
};

export type Subscription_RootIntegration_Usage_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integration_Usage_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integration_Usage_Stats_View_Order_By>>;
  where?: InputMaybe<Integration_Usage_Stats_View_Bool_Exp>;
};

export type Subscription_RootIntegration_Usage_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Integration_Usage_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Integration_Usage_Stats_View_Bool_Exp>;
};

export type Subscription_RootIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Subscription_RootIntegrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Subscription_RootIntegrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootIntegrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Subscription_RootInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

export type Subscription_RootInvitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

export type Subscription_RootInvitations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootInvitations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Invitations_Stream_Cursor_Input>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

export type Subscription_RootNotification_Delivery_TypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Delivery_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Delivery_Types_Order_By>>;
  where?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
};

export type Subscription_RootNotification_Delivery_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Delivery_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Delivery_Types_Order_By>>;
  where?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
};

export type Subscription_RootNotification_Delivery_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootNotification_Delivery_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Delivery_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
};

export type Subscription_RootNotification_Disabled_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

export type Subscription_RootNotification_Disabled_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

export type Subscription_RootNotification_Disabled_Settings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootNotification_Disabled_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Disabled_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

export type Subscription_RootNotification_TypesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Types_Order_By>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};

export type Subscription_RootNotification_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Types_Order_By>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};

export type Subscription_RootNotification_Types_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootNotification_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Types_Bool_Exp>;
};

export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootNumber_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Number_Field_Values_Order_By>>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

export type Subscription_RootNumber_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Number_Field_Values_Order_By>>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

export type Subscription_RootNumber_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Subscription_RootNumber_Field_Values_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Number_Field_Values_Stream_Cursor_Input>>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

export type Subscription_RootOrganization_Evidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_Evidence_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_Evidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Evidence_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Evidence_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

export type Subscription_RootOrganization_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

export type Subscription_RootOrganization_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Integrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Risk_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Risk_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Risk_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Risk_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_Template_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootOrganization_Template_Frameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootOrganization_Template_Frameworks_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  template_framework_id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Template_Frameworks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Template_Frameworks_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootOrganization_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

export type Subscription_RootOrganization_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

export type Subscription_RootOrganization_Tenants_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Tenants_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Tenants_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

export type Subscription_RootOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

export type Subscription_RootOrganization_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

export type Subscription_RootOrganization_Users_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_Risk_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_Risk_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_Risk_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Vendor_Risk_Levels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Vendor_Risk_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_TiersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_Tiers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_Tiers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Vendor_Tiers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Vendor_Tiers_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

export type Subscription_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Subscription_RootOrganizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganizations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organizations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Subscription_RootPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};

export type Subscription_RootPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};

export type Subscription_RootPermissions_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootPermissions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};

export type Subscription_RootPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootPolicies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicies_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policies_Stream_Cursor_Input>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootPolicy_Acknowledgement_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Acknowledgement_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Acknowledgement_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootPolicy_Acknowledgement_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Acknowledgement_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Acknowledgement_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Acknowledgement_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

export type Subscription_RootPolicy_Acknowledgement_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

export type Subscription_RootPolicy_Acknowledgement_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicy_Acknowledgement_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Acknowledgement_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

export type Subscription_RootPolicy_AcknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

export type Subscription_RootPolicy_Acknowledgements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

export type Subscription_RootPolicy_Acknowledgements_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicy_Acknowledgements_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Acknowledgements_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

export type Subscription_RootPolicy_Approval_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Approval_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Approval_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Approval_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Approval_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootPolicy_Approval_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Approval_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Approval_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Approval_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

export type Subscription_RootPolicy_Approval_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

export type Subscription_RootPolicy_Approval_Users_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicy_Approval_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Approval_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

export type Subscription_RootPolicy_ApprovalsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

export type Subscription_RootPolicy_Approvals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

export type Subscription_RootPolicy_Approvals_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicy_Approvals_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Approvals_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

export type Subscription_RootPolicy_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

export type Subscription_RootPolicy_Approvers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

export type Subscription_RootPolicy_Approvers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicy_Approvers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Approvers_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

export type Subscription_RootPolicy_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootPolicy_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_TasksArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

export type Subscription_RootPolicy_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

export type Subscription_RootPolicy_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicy_Tasks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

export type Subscription_RootPolicy_TypesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Types_Order_By>>;
  where?: InputMaybe<Policy_Types_Bool_Exp>;
};

export type Subscription_RootPolicy_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Types_Order_By>>;
  where?: InputMaybe<Policy_Types_Bool_Exp>;
};

export type Subscription_RootPolicy_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootPolicy_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Types_Bool_Exp>;
};

export type Subscription_RootPolicy_Version_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Policy_Version_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Version_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Version_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Version_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Version_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Version_Statuses_Order_By>>;
  where?: InputMaybe<Policy_Version_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_Version_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootPolicy_Version_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Version_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Version_Statuses_Bool_Exp>;
};

export type Subscription_RootPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

export type Subscription_RootPolicy_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

export type Subscription_RootPolicy_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicy_Versions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policy_Versions_Stream_Cursor_Input>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

export type Subscription_RootProgram_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

export type Subscription_RootProgram_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

export type Subscription_RootProgram_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  program_id: Scalars['uuid']['input'];
};

export type Subscription_RootProgram_Controls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Program_Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

export type Subscription_RootProgram_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

export type Subscription_RootProgram_Field_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

export type Subscription_RootProgram_Field_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootProgram_Field_Configs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Program_Field_Configs_Stream_Cursor_Input>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

export type Subscription_RootProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootPrograms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootPrograms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPrograms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Programs_Stream_Cursor_Input>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootQuestionnaire_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootQuestionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootQuestionnaire_Uploads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaire_Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Subscription_RootQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Subscription_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootQuestionnaires_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Subscription_RootReport_TypesArgs = {
  distinct_on?: InputMaybe<Array<Report_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Types_Order_By>>;
  where?: InputMaybe<Report_Types_Bool_Exp>;
};

export type Subscription_RootReport_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Report_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Report_Types_Order_By>>;
  where?: InputMaybe<Report_Types_Bool_Exp>;
};

export type Subscription_RootReport_Types_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootReport_Types_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Report_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Report_Types_Bool_Exp>;
};

export type Subscription_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

export type Subscription_RootReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

export type Subscription_RootReports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootReports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

export type Subscription_RootReview_FilesArgs = {
  distinct_on?: InputMaybe<Array<Review_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Review_Files_Order_By>>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

export type Subscription_RootReview_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Review_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Review_Files_Order_By>>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

export type Subscription_RootReview_Files_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootReview_Files_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Review_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

export type Subscription_RootReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Subscription_RootReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Subscription_RootReviews_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootReviews_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

export type Subscription_RootRisk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

export type Subscription_RootRisk_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

export type Subscription_RootRisk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

export type Subscription_RootRisk_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

export type Subscription_RootRisk_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

export type Subscription_RootRisk_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Controls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

export type Subscription_RootRisk_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Documents_Order_By>>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

export type Subscription_RootRisk_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Documents_Order_By>>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

export type Subscription_RootRisk_Documents_By_PkArgs = {
  file_id: Scalars['uuid']['input'];
  risk_id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Documents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

export type Subscription_RootRisk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

export type Subscription_RootRisk_Impacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

export type Subscription_RootRisk_Impacts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Impacts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Impacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

export type Subscription_RootRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_Inherent_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_Inherent_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Inherent_Levels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Inherent_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Levels_Order_By>>;
  where?: InputMaybe<Risk_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Levels_Order_By>>;
  where?: InputMaybe<Risk_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_Levels_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootRisk_Levels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

export type Subscription_RootRisk_Likelihoods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

export type Subscription_RootRisk_Likelihoods_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Likelihoods_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Likelihoods_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

export type Subscription_RootRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_Residual_Levels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_Residual_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Residual_Levels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Residual_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

export type Subscription_RootRisk_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

export type Subscription_RootRisk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

export type Subscription_RootRisk_TasksArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

export type Subscription_RootRisk_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

export type Subscription_RootRisk_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Tasks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

export type Subscription_RootRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootRisks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risks_Stream_Cursor_Input>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootRole_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

export type Subscription_RootRole_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

export type Subscription_RootRole_Permissions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRole_Permissions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootSelect_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Configs_Order_By>>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

export type Subscription_RootSelect_Field_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Configs_Order_By>>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

export type Subscription_RootSelect_Field_Configs_By_PkArgs = {
  field_config_id: Scalars['uuid']['input'];
};

export type Subscription_RootSelect_Field_Configs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Select_Field_Configs_Stream_Cursor_Input>>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

export type Subscription_RootSelect_Field_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Options_Order_By>>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

export type Subscription_RootSelect_Field_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Options_Order_By>>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

export type Subscription_RootSelect_Field_Options_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootSelect_Field_Options_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Select_Field_Options_Stream_Cursor_Input>>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

export type Subscription_RootSelect_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

export type Subscription_RootSelect_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

export type Subscription_RootSelect_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Subscription_RootSelect_Field_Values_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Select_Field_Values_Stream_Cursor_Input>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

export type Subscription_RootSystem_RolesArgs = {
  distinct_on?: InputMaybe<Array<System_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<System_Roles_Order_By>>;
  where?: InputMaybe<System_Roles_Bool_Exp>;
};

export type Subscription_RootSystem_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<System_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<System_Roles_Order_By>>;
  where?: InputMaybe<System_Roles_Bool_Exp>;
};

export type Subscription_RootSystem_Roles_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootSystem_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<System_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<System_Roles_Bool_Exp>;
};

export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTask_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

export type Subscription_RootTask_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

export type Subscription_RootTask_Owners_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTask_Owners_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Task_Owners_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

export type Subscription_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTasks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTemplate_Control_Ai_Review_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Subscription_RootTemplate_Control_Ai_Review_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Subscription_RootTemplate_Control_Ai_Review_Criteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Control_Ai_Review_Criteria_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Control_Ai_Review_Criteria_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
};

export type Subscription_RootTemplate_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Controls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootTemplate_Frameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootTemplate_Frameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Frameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Frameworks_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Frameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Frameworks_Controls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Frameworks_Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Frameworks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Frameworks_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootTemplate_TasksArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Subscription_RootTemplate_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Subscription_RootTemplate_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Tasks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Subscription_RootText_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Field_Values_Order_By>>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

export type Subscription_RootText_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Field_Values_Order_By>>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

export type Subscription_RootText_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Subscription_RootText_Field_Values_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Text_Field_Values_Stream_Cursor_Input>>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

export type Subscription_RootTreatment_PlanArgs = {
  distinct_on?: InputMaybe<Array<Treatment_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treatment_Plan_Order_By>>;
  where?: InputMaybe<Treatment_Plan_Bool_Exp>;
};

export type Subscription_RootTreatment_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Treatment_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Treatment_Plan_Order_By>>;
  where?: InputMaybe<Treatment_Plan_Bool_Exp>;
};

export type Subscription_RootTreatment_Plan_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootTreatment_Plan_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Treatment_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Treatment_Plan_Bool_Exp>;
};

export type Subscription_RootUrl_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Field_Values_Order_By>>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

export type Subscription_RootUrl_Field_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Field_Values_Order_By>>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

export type Subscription_RootUrl_Field_Values_By_PkArgs = {
  field_value_id: Scalars['uuid']['input'];
};

export type Subscription_RootUrl_Field_Values_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Url_Field_Values_Stream_Cursor_Input>>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

export type Subscription_RootUserArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootUser_Controls_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Controls_Stats_View_Order_By>>;
  where?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
};

export type Subscription_RootUser_Controls_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Controls_Stats_View_Order_By>>;
  where?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
};

export type Subscription_RootUser_Controls_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Controls_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
};

export type Subscription_RootUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Subscription_RootUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Subscription_RootUser_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootUser_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Subscription_RootUser_Tasks_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Tasks_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tasks_Stats_View_Order_By>>;
  where?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
};

export type Subscription_RootUser_Tasks_Stats_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Tasks_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tasks_Stats_View_Order_By>>;
  where?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
};

export type Subscription_RootUser_Tasks_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Tasks_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootVendor_Approval_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Approval_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Approval_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Approval_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Approval_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Approval_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Approval_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Approval_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Approval_Statuses_By_PkArgs = {
  name: Scalars['String']['input'];
};

export type Subscription_RootVendor_Approval_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Approval_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Approval_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_DocumentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Documents_Order_By>>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

export type Subscription_RootVendor_Documents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Documents_Order_By>>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

export type Subscription_RootVendor_Documents_By_PkArgs = {
  file_id: Scalars['uuid']['input'];
  vendor_id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Documents_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

export type Subscription_RootVendor_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

export type Subscription_RootVendor_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

export type Subscription_RootVendor_Findings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Findings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Findings_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Assessment_SourcesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Assessment_Sources_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Assessment_Sources_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Assessment_Sources_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Assessments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Assessments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Assessments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Run_StatusArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Run_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Run_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Run_Status_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_RunsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Runs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Ai_Review_Runs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Answers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Uploads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_FormsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Forms_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Forms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Forms_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Forms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Forms_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootVendor_Questionnaire_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Uploads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaires_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Subscription_RootVendor_RisksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

export type Subscription_RootVendor_Risks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

export type Subscription_RootVendor_Risks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Risks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Risks_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

export type Subscription_RootVendor_TasksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

export type Subscription_RootVendor_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

export type Subscription_RootVendor_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Tasks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

export type Subscription_RootVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootVendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendors_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendors_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootVendors_Summary_ViewArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Summary_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Summary_View_Order_By>>;
  where?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
};

export type Subscription_RootVendors_Summary_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Summary_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Summary_View_Order_By>>;
  where?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
};

export type Subscription_RootVendors_Summary_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendors_Summary_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
};

/** columns and relationships of "system_roles" */
export type System_Roles = {
  __typename?: 'system_roles';
  name: Scalars['String']['output'];
  /** An array relationship */
  roles: Array<Roles>;
  /** An aggregate relationship */
  roles_aggregate: Roles_Aggregate;
};

/** columns and relationships of "system_roles" */
export type System_RolesRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** columns and relationships of "system_roles" */
export type System_RolesRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** aggregated selection of "system_roles" */
export type System_Roles_Aggregate = {
  __typename?: 'system_roles_aggregate';
  aggregate?: Maybe<System_Roles_Aggregate_Fields>;
  nodes: Array<System_Roles>;
};

/** aggregate fields of "system_roles" */
export type System_Roles_Aggregate_Fields = {
  __typename?: 'system_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<System_Roles_Max_Fields>;
  min?: Maybe<System_Roles_Min_Fields>;
};

/** aggregate fields of "system_roles" */
export type System_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<System_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "system_roles". All fields are combined with a logical 'AND'. */
export type System_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<System_Roles_Bool_Exp>>;
  _not?: InputMaybe<System_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<System_Roles_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  roles?: InputMaybe<Roles_Bool_Exp>;
  roles_aggregate?: InputMaybe<Roles_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "system_roles" */
export enum System_Roles_Constraint {
  /** unique or primary key constraint on columns "name" */
  SystemRolesPkey = 'system_roles_pkey',
}

export enum System_Roles_Enum {
  Admin = 'admin',
  Auditor = 'auditor',
  Member = 'member',
}

/** Boolean expression to compare columns of type "system_roles_enum". All fields are combined with logical 'AND'. */
export type System_Roles_Enum_Comparison_Exp = {
  _eq?: InputMaybe<System_Roles_Enum>;
  _in?: InputMaybe<Array<System_Roles_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<System_Roles_Enum>;
  _nin?: InputMaybe<Array<System_Roles_Enum>>;
};

/** input type for inserting data into table "system_roles" */
export type System_Roles_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Roles_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type System_Roles_Max_Fields = {
  __typename?: 'system_roles_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type System_Roles_Min_Fields = {
  __typename?: 'system_roles_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "system_roles" */
export type System_Roles_Mutation_Response = {
  __typename?: 'system_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<System_Roles>;
};

/** input type for inserting object relation for remote table "system_roles" */
export type System_Roles_Obj_Rel_Insert_Input = {
  data: System_Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<System_Roles_On_Conflict>;
};

/** on_conflict condition type for table "system_roles" */
export type System_Roles_On_Conflict = {
  constraint: System_Roles_Constraint;
  update_columns?: Array<System_Roles_Update_Column>;
  where?: InputMaybe<System_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "system_roles". */
export type System_Roles_Order_By = {
  name?: InputMaybe<Order_By>;
  roles_aggregate?: InputMaybe<Roles_Aggregate_Order_By>;
};

/** primary key columns input for table: system_roles */
export type System_Roles_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "system_roles" */
export enum System_Roles_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "system_roles" */
export type System_Roles_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "system_roles" */
export type System_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: System_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type System_Roles_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "system_roles" */
export enum System_Roles_Update_Column {
  /** column name */
  Name = 'name',
}

export type System_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<System_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: System_Roles_Bool_Exp;
};

/** stores custom tags related to organization */
export type Tags = {
  __typename?: 'tags';
  /** An array relationship */
  control_tags: Array<Control_Tags>;
  /** An aggregate relationship */
  control_tags_aggregate: Control_Tags_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** stores custom tags related to organization */
export type TagsControl_TagsArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

/** stores custom tags related to organization */
export type TagsControl_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

export type Tags_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tags_Aggregate_Bool_Exp_Count>;
};

export type Tags_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tags_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tags" */
export type Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tags_Max_Order_By>;
  min?: InputMaybe<Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tags" */
export type Tags_Arr_Rel_Insert_Input = {
  data: Array<Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  control_tags?: InputMaybe<Control_Tags_Bool_Exp>;
  control_tags_aggregate?: InputMaybe<Control_Tags_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  TagsNameOrganizationIdKey = 'tags_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey',
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  control_tags?: InputMaybe<Control_Tags_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "tags" */
export type Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "tags" */
export type Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  control_tags_aggregate?: InputMaybe<Control_Tags_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tags_Bool_Exp;
};

/** users responsible for task */
export type Task_Owners = {
  __typename?: 'task_owners';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  owner_id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "task_owners" */
export type Task_Owners_Aggregate = {
  __typename?: 'task_owners_aggregate';
  aggregate?: Maybe<Task_Owners_Aggregate_Fields>;
  nodes: Array<Task_Owners>;
};

export type Task_Owners_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Owners_Aggregate_Bool_Exp_Count>;
};

export type Task_Owners_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Owners_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Task_Owners_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_owners" */
export type Task_Owners_Aggregate_Fields = {
  __typename?: 'task_owners_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Task_Owners_Max_Fields>;
  min?: Maybe<Task_Owners_Min_Fields>;
};

/** aggregate fields of "task_owners" */
export type Task_Owners_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Owners_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "task_owners" */
export type Task_Owners_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Owners_Max_Order_By>;
  min?: InputMaybe<Task_Owners_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_owners" */
export type Task_Owners_Arr_Rel_Insert_Input = {
  data: Array<Task_Owners_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Owners_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_owners". All fields are combined with a logical 'AND'. */
export type Task_Owners_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Owners_Bool_Exp>>;
  _not?: InputMaybe<Task_Owners_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Owners_Bool_Exp>>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "task_owners" */
export enum Task_Owners_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskOwnersPkey = 'task_owners_pkey',
  /** unique or primary key constraint on columns "task_id", "owner_id" */
  TaskOwnersTaskIdOwnerIdKey = 'task_owners_task_id_owner_id_key',
}

/** input type for inserting data into table "task_owners" */
export type Task_Owners_Insert_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Task_Owners_Max_Fields = {
  __typename?: 'task_owners_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "task_owners" */
export type Task_Owners_Max_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Owners_Min_Fields = {
  __typename?: 'task_owners_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "task_owners" */
export type Task_Owners_Min_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_owners" */
export type Task_Owners_Mutation_Response = {
  __typename?: 'task_owners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Owners>;
};

/** on_conflict condition type for table "task_owners" */
export type Task_Owners_On_Conflict = {
  constraint: Task_Owners_Constraint;
  update_columns?: Array<Task_Owners_Update_Column>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

/** Ordering options when selecting data from "task_owners". */
export type Task_Owners_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: task_owners */
export type Task_Owners_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "task_owners" */
export enum Task_Owners_Select_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "task_owners" */
export type Task_Owners_Set_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "task_owners" */
export type Task_Owners_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Owners_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Owners_Stream_Cursor_Value_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "task_owners" */
export enum Task_Owners_Update_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Task_Owners_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Owners_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Owners_Bool_Exp;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type Tasks = {
  __typename?: 'tasks';
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  /** An aggregate relationship */
  control_findings_aggregate: Control_Findings_Aggregate;
  /** An array relationship */
  controls: Array<Control_Tasks>;
  /** An aggregate relationship */
  controls_aggregate: Control_Tasks_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  risks: Array<Risk_Tasks>;
  /** An aggregate relationship */
  risks_aggregate: Risk_Tasks_Aggregate;
  similarity?: Maybe<Scalars['float8']['output']>;
  status: Scalars['TaskStatus']['output'];
  /** An array relationship */
  task_owners: Array<Task_Owners>;
  /** An aggregate relationship */
  task_owners_aggregate: Task_Owners_Aggregate;
  /** An array relationship */
  tasks_comments: Array<Comments>;
  /** An aggregate relationship */
  tasks_comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  tasks_policy_tasks: Array<Policy_Tasks>;
  /** An aggregate relationship */
  tasks_policy_tasks_aggregate: Policy_Tasks_Aggregate;
  /** An object relationship */
  tasks_vector_store?: Maybe<Ai_Tasks_Vector_Store>;
  /** An object relationship */
  template_task?: Maybe<Template_Tasks>;
  template_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_finding?: Maybe<Vendor_Findings>;
  /** An array relationship */
  vendors: Array<Vendor_Tasks>;
  /** An aggregate relationship */
  vendors_aggregate: Vendor_Tasks_Aggregate;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksControl_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksControlsArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksRisksArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTask_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTask_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTasks_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTasks_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTasks_Policy_TasksArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTasks_Policy_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: 'tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

export type Tasks_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Tasks_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Tasks_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Tasks_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Tasks_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Tasks_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Tasks_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Tasks_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Tasks_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Tasks_Aggregate_Bool_Exp_Var_Samp>;
};

export type Tasks_Aggregate_Bool_Exp_Avg = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Corr = {
  arguments: Tasks_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Tasks_Aggregate_Bool_Exp_Max = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Min = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Sum = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'tasks_aggregate_fields';
  avg?: Maybe<Tasks_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
  stddev?: Maybe<Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Sum_Fields>;
  var_pop?: Maybe<Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Variance_Fields>;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tasks" */
export type Tasks_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Max_Order_By>;
  min?: InputMaybe<Tasks_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks" */
export type Tasks_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Avg_Fields = {
  __typename?: 'tasks_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tasks" */
export type Tasks_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Bool_Exp>>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Bool_Exp>;
  controls?: InputMaybe<Control_Tasks_Bool_Exp>;
  controls_aggregate?: InputMaybe<Control_Tasks_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  risks?: InputMaybe<Risk_Tasks_Bool_Exp>;
  risks_aggregate?: InputMaybe<Risk_Tasks_Aggregate_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  task_owners?: InputMaybe<Task_Owners_Bool_Exp>;
  task_owners_aggregate?: InputMaybe<Task_Owners_Aggregate_Bool_Exp>;
  tasks_comments?: InputMaybe<Comments_Bool_Exp>;
  tasks_comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  tasks_policy_tasks?: InputMaybe<Policy_Tasks_Bool_Exp>;
  tasks_policy_tasks_aggregate?: InputMaybe<Policy_Tasks_Aggregate_Bool_Exp>;
  tasks_vector_store?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
  template_task?: InputMaybe<Template_Tasks_Bool_Exp>;
  template_task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_finding?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendors?: InputMaybe<Vendor_Tasks_Bool_Exp>;
  vendors_aggregate?: InputMaybe<Vendor_Tasks_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramTasksPkey = 'program_tasks_pkey',
}

/** input type for incrementing numeric columns in table "tasks" */
export type Tasks_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  control_findings?: InputMaybe<Control_Findings_Arr_Rel_Insert_Input>;
  controls?: InputMaybe<Control_Tasks_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id: Scalars['uuid']['input'];
  risks?: InputMaybe<Risk_Tasks_Arr_Rel_Insert_Input>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  task_owners?: InputMaybe<Task_Owners_Arr_Rel_Insert_Input>;
  tasks_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  tasks_policy_tasks?: InputMaybe<Policy_Tasks_Arr_Rel_Insert_Input>;
  tasks_vector_store?: InputMaybe<Ai_Tasks_Vector_Store_Obj_Rel_Insert_Input>;
  template_task?: InputMaybe<Template_Tasks_Obj_Rel_Insert_Input>;
  template_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_finding?: InputMaybe<Vendor_Findings_Obj_Rel_Insert_Input>;
  vendors?: InputMaybe<Vendor_Tasks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "tasks" */
export type Tasks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "tasks" */
export type Tasks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** input type for inserting object relation for remote table "tasks" */
export type Tasks_Obj_Rel_Insert_Input = {
  data: Tasks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** on_conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  controls_aggregate?: InputMaybe<Control_Tasks_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  risks_aggregate?: InputMaybe<Risk_Tasks_Aggregate_Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  task_owners_aggregate?: InputMaybe<Task_Owners_Aggregate_Order_By>;
  tasks_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  tasks_policy_tasks_aggregate?: InputMaybe<Policy_Tasks_Aggregate_Order_By>;
  tasks_vector_store?: InputMaybe<Ai_Tasks_Vector_Store_Order_By>;
  template_task?: InputMaybe<Template_Tasks_Order_By>;
  template_task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_finding?: InputMaybe<Vendor_Findings_Order_By>;
  vendors_aggregate?: InputMaybe<Vendor_Tasks_Aggregate_Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tasks" */
export enum Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateTaskId = 'template_task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "tasks_aggregate_bool_exp_avg_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_corr_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_max_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_min_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_sum_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_var_samp_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Tasks_Stddev_Fields = {
  __typename?: 'tasks_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tasks" */
export type Tasks_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Stddev_Pop_Fields = {
  __typename?: 'tasks_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "tasks" */
export type Tasks_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Stddev_Samp_Fields = {
  __typename?: 'tasks_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "tasks" */
export type Tasks_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks" */
export type Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Tasks_Sum_Fields = {
  __typename?: 'tasks_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "tasks" */
export type Tasks_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "tasks" */
export enum Tasks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateTaskId = 'template_task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tasks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Var_Pop_Fields = {
  __typename?: 'tasks_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "tasks" */
export type Tasks_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Var_Samp_Fields = {
  __typename?: 'tasks_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "tasks" */
export type Tasks_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Variance_Fields = {
  __typename?: 'tasks_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tasks" */
export type Tasks_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** AI Review Criteria for Template controls */
export type Template_Control_Ai_Review_Criteria = {
  __typename?: 'template_control_ai_review_criteria';
  content: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  heading: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  suggestion: Scalars['String']['output'];
  /** An object relationship */
  template_control: Template_Controls;
  template_control_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** aggregated selection of "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Aggregate = {
  __typename?: 'template_control_ai_review_criteria_aggregate';
  aggregate?: Maybe<Template_Control_Ai_Review_Criteria_Aggregate_Fields>;
  nodes: Array<Template_Control_Ai_Review_Criteria>;
};

export type Template_Control_Ai_Review_Criteria_Aggregate_Bool_Exp = {
  count?: InputMaybe<Template_Control_Ai_Review_Criteria_Aggregate_Bool_Exp_Count>;
};

export type Template_Control_Ai_Review_Criteria_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Aggregate_Fields = {
  __typename?: 'template_control_ai_review_criteria_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Control_Ai_Review_Criteria_Max_Fields>;
  min?: Maybe<Template_Control_Ai_Review_Criteria_Min_Fields>;
};

/** aggregate fields of "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Template_Control_Ai_Review_Criteria_Max_Order_By>;
  min?: InputMaybe<Template_Control_Ai_Review_Criteria_Min_Order_By>;
};

/** input type for inserting array relation for remote table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Arr_Rel_Insert_Input = {
  data: Array<Template_Control_Ai_Review_Criteria_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Control_Ai_Review_Criteria_On_Conflict>;
};

/** Boolean expression to filter rows from the table "template_control_ai_review_criteria". All fields are combined with a logical 'AND'. */
export type Template_Control_Ai_Review_Criteria_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Bool_Exp>>;
  _not?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  heading?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  suggestion?: InputMaybe<String_Comparison_Exp>;
  template_control?: InputMaybe<Template_Controls_Bool_Exp>;
  template_control_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "template_control_ai_review_criteria" */
export enum Template_Control_Ai_Review_Criteria_Constraint {
  /** unique or primary key constraint on columns "id" */
  TemplateControlAiReviewCriteriaPkey = 'template_control_ai_review_criteria_pkey',
}

/** input type for inserting data into table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Insert_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  suggestion?: InputMaybe<Scalars['String']['input']>;
  template_control?: InputMaybe<Template_Controls_Obj_Rel_Insert_Input>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Template_Control_Ai_Review_Criteria_Max_Fields = {
  __typename?: 'template_control_ai_review_criteria_max_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
  template_control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Max_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Template_Control_Ai_Review_Criteria_Min_Fields = {
  __typename?: 'template_control_ai_review_criteria_min_fields';
  content?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  heading?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  suggestion?: Maybe<Scalars['String']['output']>;
  template_control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Min_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Mutation_Response = {
  __typename?: 'template_control_ai_review_criteria_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Control_Ai_Review_Criteria>;
};

/** on_conflict condition type for table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_On_Conflict = {
  constraint: Template_Control_Ai_Review_Criteria_Constraint;
  update_columns?: Array<Template_Control_Ai_Review_Criteria_Update_Column>;
  where?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
};

/** Ordering options when selecting data from "template_control_ai_review_criteria". */
export type Template_Control_Ai_Review_Criteria_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
  template_control?: InputMaybe<Template_Controls_Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: template_control_ai_review_criteria */
export type Template_Control_Ai_Review_Criteria_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "template_control_ai_review_criteria" */
export enum Template_Control_Ai_Review_Criteria_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Heading = 'heading',
  /** column name */
  Id = 'id',
  /** column name */
  Suggestion = 'suggestion',
  /** column name */
  TemplateControlId = 'template_control_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Set_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  suggestion?: InputMaybe<Scalars['String']['input']>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "template_control_ai_review_criteria" */
export type Template_Control_Ai_Review_Criteria_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Control_Ai_Review_Criteria_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Control_Ai_Review_Criteria_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  heading?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  suggestion?: InputMaybe<Scalars['String']['input']>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "template_control_ai_review_criteria" */
export enum Template_Control_Ai_Review_Criteria_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Heading = 'heading',
  /** column name */
  Id = 'id',
  /** column name */
  Suggestion = 'suggestion',
  /** column name */
  TemplateControlId = 'template_control_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Template_Control_Ai_Review_Criteria_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Control_Ai_Review_Criteria_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Control_Ai_Review_Criteria_Bool_Exp;
};

/** columns and relationships of "template_controls" */
export type Template_Controls = {
  __typename?: 'template_controls';
  /** An object relationship */
  control_frequency: Control_Frequencies;
  /** An object relationship */
  control_priority?: Maybe<Control_Priorities>;
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  description?: Maybe<Scalars['String']['output']>;
  frequency: Control_Frequencies_Enum;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priority?: Maybe<Control_Priorities_Enum>;
  tags: Scalars['ControlTags']['output'];
  /** An array relationship */
  template_control_ai_review_criteria: Array<Template_Control_Ai_Review_Criteria>;
  /** An aggregate relationship */
  template_control_ai_review_criteria_aggregate: Template_Control_Ai_Review_Criteria_Aggregate;
  /** An array relationship */
  template_frameworks_controls: Array<Template_Frameworks_Controls>;
  /** An aggregate relationship */
  template_frameworks_controls_aggregate: Template_Frameworks_Controls_Aggregate;
  /** An array relationship */
  template_tasks: Array<Template_Tasks>;
  /** An aggregate relationship */
  template_tasks_aggregate: Template_Tasks_Aggregate;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTagsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_Control_Ai_Review_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_Control_Ai_Review_Criteria_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Control_Ai_Review_Criteria_Order_By>>;
  where?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_Frameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_Frameworks_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_TasksArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

/** aggregated selection of "template_controls" */
export type Template_Controls_Aggregate = {
  __typename?: 'template_controls_aggregate';
  aggregate?: Maybe<Template_Controls_Aggregate_Fields>;
  nodes: Array<Template_Controls>;
};

export type Template_Controls_Aggregate_Bool_Exp = {
  count?: InputMaybe<Template_Controls_Aggregate_Bool_Exp_Count>;
};

export type Template_Controls_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Template_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Template_Controls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "template_controls" */
export type Template_Controls_Aggregate_Fields = {
  __typename?: 'template_controls_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Controls_Max_Fields>;
  min?: Maybe<Template_Controls_Min_Fields>;
};

/** aggregate fields of "template_controls" */
export type Template_Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "template_controls" */
export type Template_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Template_Controls_Max_Order_By>;
  min?: InputMaybe<Template_Controls_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Template_Controls_Append_Input = {
  tags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "template_controls" */
export type Template_Controls_Arr_Rel_Insert_Input = {
  data: Array<Template_Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "template_controls". All fields are combined with a logical 'AND'. */
export type Template_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Controls_Bool_Exp>>;
  _not?: InputMaybe<Template_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Controls_Bool_Exp>>;
  control_frequency?: InputMaybe<Control_Frequencies_Bool_Exp>;
  control_priority?: InputMaybe<Control_Priorities_Bool_Exp>;
  controls?: InputMaybe<Controls_Bool_Exp>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  frequency?: InputMaybe<Control_Frequencies_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Control_Priorities_Enum_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  template_control_ai_review_criteria?: InputMaybe<Template_Control_Ai_Review_Criteria_Bool_Exp>;
  template_control_ai_review_criteria_aggregate?: InputMaybe<Template_Control_Ai_Review_Criteria_Aggregate_Bool_Exp>;
  template_frameworks_controls?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
  template_frameworks_controls_aggregate?: InputMaybe<Template_Frameworks_Controls_Aggregate_Bool_Exp>;
  template_tasks?: InputMaybe<Template_Tasks_Bool_Exp>;
  template_tasks_aggregate?: InputMaybe<Template_Tasks_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "template_controls" */
export enum Template_Controls_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlsPkey = 'controls_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Template_Controls_Delete_At_Path_Input = {
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Template_Controls_Delete_Elem_Input = {
  tags?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Template_Controls_Delete_Key_Input = {
  tags?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "template_controls" */
export type Template_Controls_Insert_Input = {
  control_frequency?: InputMaybe<Control_Frequencies_Obj_Rel_Insert_Input>;
  control_priority?: InputMaybe<Control_Priorities_Obj_Rel_Insert_Input>;
  controls?: InputMaybe<Controls_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
  template_control_ai_review_criteria?: InputMaybe<Template_Control_Ai_Review_Criteria_Arr_Rel_Insert_Input>;
  template_frameworks_controls?: InputMaybe<Template_Frameworks_Controls_Arr_Rel_Insert_Input>;
  template_tasks?: InputMaybe<Template_Tasks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Template_Controls_Max_Fields = {
  __typename?: 'template_controls_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "template_controls" */
export type Template_Controls_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Template_Controls_Min_Fields = {
  __typename?: 'template_controls_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "template_controls" */
export type Template_Controls_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "template_controls" */
export type Template_Controls_Mutation_Response = {
  __typename?: 'template_controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Controls>;
};

/** input type for inserting object relation for remote table "template_controls" */
export type Template_Controls_Obj_Rel_Insert_Input = {
  data: Template_Controls_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Controls_On_Conflict>;
};

/** on_conflict condition type for table "template_controls" */
export type Template_Controls_On_Conflict = {
  constraint: Template_Controls_Constraint;
  update_columns?: Array<Template_Controls_Update_Column>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "template_controls". */
export type Template_Controls_Order_By = {
  control_frequency?: InputMaybe<Control_Frequencies_Order_By>;
  control_priority?: InputMaybe<Control_Priorities_Order_By>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  template_control_ai_review_criteria_aggregate?: InputMaybe<Template_Control_Ai_Review_Criteria_Aggregate_Order_By>;
  template_frameworks_controls_aggregate?: InputMaybe<Template_Frameworks_Controls_Aggregate_Order_By>;
  template_tasks_aggregate?: InputMaybe<Template_Tasks_Aggregate_Order_By>;
};

/** primary key columns input for table: template_controls */
export type Template_Controls_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Template_Controls_Prepend_Input = {
  tags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "template_controls" */
export enum Template_Controls_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Tags = 'tags',
}

/** input type for updating data in table "template_controls" */
export type Template_Controls_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "template_controls" */
export type Template_Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Controls_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "template_controls" */
export enum Template_Controls_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Tags = 'tags',
}

export type Template_Controls_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Template_Controls_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Template_Controls_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Template_Controls_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Template_Controls_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Template_Controls_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Controls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Controls_Bool_Exp;
};

/** columns and relationships of "template_frameworks" */
export type Template_Frameworks = {
  __typename?: 'template_frameworks';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  organization_template_frameworks: Array<Organization_Template_Frameworks>;
  /** An aggregate relationship */
  organization_template_frameworks_aggregate: Organization_Template_Frameworks_Aggregate;
  /** An array relationship */
  programs: Array<Programs>;
  /** An aggregate relationship */
  programs_aggregate: Programs_Aggregate;
  /** An array relationship */
  template_frameworks_controls: Array<Template_Frameworks_Controls>;
  /** An aggregate relationship */
  template_frameworks_controls_aggregate: Template_Frameworks_Controls_Aggregate;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksOrganization_Template_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksOrganization_Template_Frameworks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksPrograms_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksTemplate_Frameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksTemplate_Frameworks_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

/** aggregated selection of "template_frameworks" */
export type Template_Frameworks_Aggregate = {
  __typename?: 'template_frameworks_aggregate';
  aggregate?: Maybe<Template_Frameworks_Aggregate_Fields>;
  nodes: Array<Template_Frameworks>;
};

/** aggregate fields of "template_frameworks" */
export type Template_Frameworks_Aggregate_Fields = {
  __typename?: 'template_frameworks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Frameworks_Max_Fields>;
  min?: Maybe<Template_Frameworks_Min_Fields>;
};

/** aggregate fields of "template_frameworks" */
export type Template_Frameworks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Frameworks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "template_frameworks". All fields are combined with a logical 'AND'. */
export type Template_Frameworks_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Frameworks_Bool_Exp>>;
  _not?: InputMaybe<Template_Frameworks_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Frameworks_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_template_frameworks?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
  organization_template_frameworks_aggregate?: InputMaybe<Organization_Template_Frameworks_Aggregate_Bool_Exp>;
  programs?: InputMaybe<Programs_Bool_Exp>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Bool_Exp>;
  template_frameworks_controls?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
  template_frameworks_controls_aggregate?: InputMaybe<Template_Frameworks_Controls_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "template_frameworks" */
export enum Template_Frameworks_Constraint {
  /** unique or primary key constraint on columns "id" */
  FrameworksPkey = 'frameworks_pkey',
}

/** columns and relationships of "template_frameworks_controls" */
export type Template_Frameworks_Controls = {
  __typename?: 'template_frameworks_controls';
  control_id: Scalars['uuid']['output'];
  framework_id: Scalars['uuid']['output'];
  /** An object relationship */
  template_control: Template_Controls;
  /** An object relationship */
  template_framework: Template_Frameworks;
};

/** aggregated selection of "template_frameworks_controls" */
export type Template_Frameworks_Controls_Aggregate = {
  __typename?: 'template_frameworks_controls_aggregate';
  aggregate?: Maybe<Template_Frameworks_Controls_Aggregate_Fields>;
  nodes: Array<Template_Frameworks_Controls>;
};

export type Template_Frameworks_Controls_Aggregate_Bool_Exp = {
  count?: InputMaybe<Template_Frameworks_Controls_Aggregate_Bool_Exp_Count>;
};

export type Template_Frameworks_Controls_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "template_frameworks_controls" */
export type Template_Frameworks_Controls_Aggregate_Fields = {
  __typename?: 'template_frameworks_controls_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Frameworks_Controls_Max_Fields>;
  min?: Maybe<Template_Frameworks_Controls_Min_Fields>;
};

/** aggregate fields of "template_frameworks_controls" */
export type Template_Frameworks_Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Template_Frameworks_Controls_Max_Order_By>;
  min?: InputMaybe<Template_Frameworks_Controls_Min_Order_By>;
};

/** input type for inserting array relation for remote table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Arr_Rel_Insert_Input = {
  data: Array<Template_Frameworks_Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Frameworks_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "template_frameworks_controls". All fields are combined with a logical 'AND'. */
export type Template_Frameworks_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Frameworks_Controls_Bool_Exp>>;
  _not?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Frameworks_Controls_Bool_Exp>>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  framework_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_control?: InputMaybe<Template_Controls_Bool_Exp>;
  template_framework?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

/** unique or primary key constraints on table "template_frameworks_controls" */
export enum Template_Frameworks_Controls_Constraint {
  /** unique or primary key constraint on columns "control_id", "framework_id" */
  FrameworksControlsPkey = 'frameworks_controls_pkey',
}

/** input type for inserting data into table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Insert_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
  template_control?: InputMaybe<Template_Controls_Obj_Rel_Insert_Input>;
  template_framework?: InputMaybe<Template_Frameworks_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Template_Frameworks_Controls_Max_Fields = {
  __typename?: 'template_frameworks_controls_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  framework_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Template_Frameworks_Controls_Min_Fields = {
  __typename?: 'template_frameworks_controls_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  framework_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Mutation_Response = {
  __typename?: 'template_frameworks_controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Frameworks_Controls>;
};

/** on_conflict condition type for table "template_frameworks_controls" */
export type Template_Frameworks_Controls_On_Conflict = {
  constraint: Template_Frameworks_Controls_Constraint;
  update_columns?: Array<Template_Frameworks_Controls_Update_Column>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "template_frameworks_controls". */
export type Template_Frameworks_Controls_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
  template_control?: InputMaybe<Template_Controls_Order_By>;
  template_framework?: InputMaybe<Template_Frameworks_Order_By>;
};

/** primary key columns input for table: template_frameworks_controls */
export type Template_Frameworks_Controls_Pk_Columns_Input = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

/** select columns of table "template_frameworks_controls" */
export enum Template_Frameworks_Controls_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  FrameworkId = 'framework_id',
}

/** input type for updating data in table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Frameworks_Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Frameworks_Controls_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "template_frameworks_controls" */
export enum Template_Frameworks_Controls_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  FrameworkId = 'framework_id',
}

export type Template_Frameworks_Controls_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Frameworks_Controls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Frameworks_Controls_Bool_Exp;
};

/** input type for inserting data into table "template_frameworks" */
export type Template_Frameworks_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_template_frameworks?: InputMaybe<Organization_Template_Frameworks_Arr_Rel_Insert_Input>;
  programs?: InputMaybe<Programs_Arr_Rel_Insert_Input>;
  template_frameworks_controls?: InputMaybe<Template_Frameworks_Controls_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Template_Frameworks_Max_Fields = {
  __typename?: 'template_frameworks_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Template_Frameworks_Min_Fields = {
  __typename?: 'template_frameworks_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "template_frameworks" */
export type Template_Frameworks_Mutation_Response = {
  __typename?: 'template_frameworks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Frameworks>;
};

/** input type for inserting object relation for remote table "template_frameworks" */
export type Template_Frameworks_Obj_Rel_Insert_Input = {
  data: Template_Frameworks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Frameworks_On_Conflict>;
};

/** on_conflict condition type for table "template_frameworks" */
export type Template_Frameworks_On_Conflict = {
  constraint: Template_Frameworks_Constraint;
  update_columns?: Array<Template_Frameworks_Update_Column>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

/** Ordering options when selecting data from "template_frameworks". */
export type Template_Frameworks_Order_By = {
  active?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_template_frameworks_aggregate?: InputMaybe<Organization_Template_Frameworks_Aggregate_Order_By>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Order_By>;
  template_frameworks_controls_aggregate?: InputMaybe<Template_Frameworks_Controls_Aggregate_Order_By>;
};

/** primary key columns input for table: template_frameworks */
export type Template_Frameworks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "template_frameworks" */
export enum Template_Frameworks_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "template_frameworks" */
export type Template_Frameworks_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "template_frameworks" */
export type Template_Frameworks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Frameworks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Frameworks_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "template_frameworks" */
export enum Template_Frameworks_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type Template_Frameworks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Frameworks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Frameworks_Bool_Exp;
};

/** columns and relationships of "template_tasks" */
export type Template_Tasks = {
  __typename?: 'template_tasks';
  control_id: Scalars['uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An object relationship */
  template_control: Template_Controls;
};

/** columns and relationships of "template_tasks" */
export type Template_TasksTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "template_tasks" */
export type Template_TasksTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "template_tasks" */
export type Template_Tasks_Aggregate = {
  __typename?: 'template_tasks_aggregate';
  aggregate?: Maybe<Template_Tasks_Aggregate_Fields>;
  nodes: Array<Template_Tasks>;
};

export type Template_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Template_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Template_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Template_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "template_tasks" */
export type Template_Tasks_Aggregate_Fields = {
  __typename?: 'template_tasks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Tasks_Max_Fields>;
  min?: Maybe<Template_Tasks_Min_Fields>;
};

/** aggregate fields of "template_tasks" */
export type Template_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "template_tasks" */
export type Template_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Template_Tasks_Max_Order_By>;
  min?: InputMaybe<Template_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "template_tasks" */
export type Template_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Template_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "template_tasks". All fields are combined with a logical 'AND'. */
export type Template_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Template_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Tasks_Bool_Exp>>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  template_control?: InputMaybe<Template_Controls_Bool_Exp>;
};

/** unique or primary key constraints on table "template_tasks" */
export enum Template_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  TasksPkey = 'tasks_pkey',
}

/** input type for inserting data into table "template_tasks" */
export type Template_Tasks_Insert_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  template_control?: InputMaybe<Template_Controls_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Template_Tasks_Max_Fields = {
  __typename?: 'template_tasks_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "template_tasks" */
export type Template_Tasks_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Template_Tasks_Min_Fields = {
  __typename?: 'template_tasks_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "template_tasks" */
export type Template_Tasks_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "template_tasks" */
export type Template_Tasks_Mutation_Response = {
  __typename?: 'template_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Template_Tasks>;
};

/** input type for inserting object relation for remote table "template_tasks" */
export type Template_Tasks_Obj_Rel_Insert_Input = {
  data: Template_Tasks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Template_Tasks_On_Conflict>;
};

/** on_conflict condition type for table "template_tasks" */
export type Template_Tasks_On_Conflict = {
  constraint: Template_Tasks_Constraint;
  update_columns?: Array<Template_Tasks_Update_Column>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "template_tasks". */
export type Template_Tasks_Order_By = {
  control_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  template_control?: InputMaybe<Template_Controls_Order_By>;
};

/** primary key columns input for table: template_tasks */
export type Template_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "template_tasks" */
export enum Template_Tasks_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "template_tasks" */
export type Template_Tasks_Set_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "template_tasks" */
export type Template_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Tasks_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "template_tasks" */
export enum Template_Tasks_Update_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
}

export type Template_Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Template_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Template_Tasks_Bool_Exp;
};

/** columns and relationships of "text_field_values" */
export type Text_Field_Values = {
  __typename?: 'text_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "text_field_values" */
export type Text_Field_Values_Aggregate = {
  __typename?: 'text_field_values_aggregate';
  aggregate?: Maybe<Text_Field_Values_Aggregate_Fields>;
  nodes: Array<Text_Field_Values>;
};

export type Text_Field_Values_Aggregate_Bool_Exp = {
  count?: InputMaybe<Text_Field_Values_Aggregate_Bool_Exp_Count>;
};

export type Text_Field_Values_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Text_Field_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "text_field_values" */
export type Text_Field_Values_Aggregate_Fields = {
  __typename?: 'text_field_values_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Text_Field_Values_Max_Fields>;
  min?: Maybe<Text_Field_Values_Min_Fields>;
};

/** aggregate fields of "text_field_values" */
export type Text_Field_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "text_field_values" */
export type Text_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Text_Field_Values_Max_Order_By>;
  min?: InputMaybe<Text_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "text_field_values" */
export type Text_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Text_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Text_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "text_field_values". All fields are combined with a logical 'AND'. */
export type Text_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Text_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Text_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Text_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "text_field_values" */
export enum Text_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  TextFieldValuesPkey = 'text_field_values_pkey',
}

/** input type for inserting data into table "text_field_values" */
export type Text_Field_Values_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Text_Field_Values_Max_Fields = {
  __typename?: 'text_field_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "text_field_values" */
export type Text_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Text_Field_Values_Min_Fields = {
  __typename?: 'text_field_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "text_field_values" */
export type Text_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "text_field_values" */
export type Text_Field_Values_Mutation_Response = {
  __typename?: 'text_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Text_Field_Values>;
};

/** input type for inserting object relation for remote table "text_field_values" */
export type Text_Field_Values_Obj_Rel_Insert_Input = {
  data: Text_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Text_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "text_field_values" */
export type Text_Field_Values_On_Conflict = {
  constraint: Text_Field_Values_Constraint;
  update_columns?: Array<Text_Field_Values_Update_Column>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "text_field_values". */
export type Text_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: text_field_values */
export type Text_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "text_field_values" */
export enum Text_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "text_field_values" */
export type Text_Field_Values_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "text_field_values" */
export type Text_Field_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Text_Field_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Text_Field_Values_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "text_field_values" */
export enum Text_Field_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

export type Text_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Text_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Text_Field_Values_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "treatment_plan" */
export type Treatment_Plan = {
  __typename?: 'treatment_plan';
  name: Scalars['String']['output'];
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
};

/** columns and relationships of "treatment_plan" */
export type Treatment_PlanRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "treatment_plan" */
export type Treatment_PlanRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** aggregated selection of "treatment_plan" */
export type Treatment_Plan_Aggregate = {
  __typename?: 'treatment_plan_aggregate';
  aggregate?: Maybe<Treatment_Plan_Aggregate_Fields>;
  nodes: Array<Treatment_Plan>;
};

/** aggregate fields of "treatment_plan" */
export type Treatment_Plan_Aggregate_Fields = {
  __typename?: 'treatment_plan_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Treatment_Plan_Max_Fields>;
  min?: Maybe<Treatment_Plan_Min_Fields>;
};

/** aggregate fields of "treatment_plan" */
export type Treatment_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Treatment_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "treatment_plan". All fields are combined with a logical 'AND'. */
export type Treatment_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Treatment_Plan_Bool_Exp>>;
  _not?: InputMaybe<Treatment_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Treatment_Plan_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  risks?: InputMaybe<Risks_Bool_Exp>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "treatment_plan" */
export enum Treatment_Plan_Constraint {
  /** unique or primary key constraint on columns "name" */
  TreatmentPlanPkey = 'treatment_plan_pkey',
}

export enum Treatment_Plan_Enum {
  Accepted = 'Accepted',
  Avoided = 'Avoided',
  Mitigated = 'Mitigated',
  Pending = 'Pending',
  Transferred = 'Transferred',
}

/** Boolean expression to compare columns of type "treatment_plan_enum". All fields are combined with logical 'AND'. */
export type Treatment_Plan_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Treatment_Plan_Enum>;
  _in?: InputMaybe<Array<Treatment_Plan_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Treatment_Plan_Enum>;
  _nin?: InputMaybe<Array<Treatment_Plan_Enum>>;
};

/** input type for inserting data into table "treatment_plan" */
export type Treatment_Plan_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  risks?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Treatment_Plan_Max_Fields = {
  __typename?: 'treatment_plan_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Treatment_Plan_Min_Fields = {
  __typename?: 'treatment_plan_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "treatment_plan" */
export type Treatment_Plan_Mutation_Response = {
  __typename?: 'treatment_plan_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Treatment_Plan>;
};

/** input type for inserting object relation for remote table "treatment_plan" */
export type Treatment_Plan_Obj_Rel_Insert_Input = {
  data: Treatment_Plan_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Treatment_Plan_On_Conflict>;
};

/** on_conflict condition type for table "treatment_plan" */
export type Treatment_Plan_On_Conflict = {
  constraint: Treatment_Plan_Constraint;
  update_columns?: Array<Treatment_Plan_Update_Column>;
  where?: InputMaybe<Treatment_Plan_Bool_Exp>;
};

/** Ordering options when selecting data from "treatment_plan". */
export type Treatment_Plan_Order_By = {
  name?: InputMaybe<Order_By>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
};

/** primary key columns input for table: treatment_plan */
export type Treatment_Plan_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "treatment_plan" */
export enum Treatment_Plan_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "treatment_plan" */
export type Treatment_Plan_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "treatment_plan" */
export type Treatment_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Treatment_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Treatment_Plan_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "treatment_plan" */
export enum Treatment_Plan_Update_Column {
  /** column name */
  Name = 'name',
}

export type Treatment_Plan_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Treatment_Plan_Set_Input>;
  /** filter the rows which have to be updated */
  where: Treatment_Plan_Bool_Exp;
};

/** columns and relationships of "url_field_values" */
export type Url_Field_Values = {
  __typename?: 'url_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['String']['output'];
};

/** aggregated selection of "url_field_values" */
export type Url_Field_Values_Aggregate = {
  __typename?: 'url_field_values_aggregate';
  aggregate?: Maybe<Url_Field_Values_Aggregate_Fields>;
  nodes: Array<Url_Field_Values>;
};

export type Url_Field_Values_Aggregate_Bool_Exp = {
  count?: InputMaybe<Url_Field_Values_Aggregate_Bool_Exp_Count>;
};

export type Url_Field_Values_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Url_Field_Values_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "url_field_values" */
export type Url_Field_Values_Aggregate_Fields = {
  __typename?: 'url_field_values_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Url_Field_Values_Max_Fields>;
  min?: Maybe<Url_Field_Values_Min_Fields>;
};

/** aggregate fields of "url_field_values" */
export type Url_Field_Values_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "url_field_values" */
export type Url_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Url_Field_Values_Max_Order_By>;
  min?: InputMaybe<Url_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "url_field_values" */
export type Url_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Url_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Url_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "url_field_values". All fields are combined with a logical 'AND'. */
export type Url_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Url_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Url_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Url_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "url_field_values" */
export enum Url_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  UrlFieldValuesPkey = 'url_field_values_pkey',
}

/** input type for inserting data into table "url_field_values" */
export type Url_Field_Values_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Url_Field_Values_Max_Fields = {
  __typename?: 'url_field_values_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "url_field_values" */
export type Url_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Url_Field_Values_Min_Fields = {
  __typename?: 'url_field_values_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_type?: Maybe<Scalars['String']['output']>;
  field_value_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "url_field_values" */
export type Url_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "url_field_values" */
export type Url_Field_Values_Mutation_Response = {
  __typename?: 'url_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Url_Field_Values>;
};

/** input type for inserting object relation for remote table "url_field_values" */
export type Url_Field_Values_Obj_Rel_Insert_Input = {
  data: Url_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Url_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "url_field_values" */
export type Url_Field_Values_On_Conflict = {
  constraint: Url_Field_Values_Constraint;
  update_columns?: Array<Url_Field_Values_Update_Column>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "url_field_values". */
export type Url_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: url_field_values */
export type Url_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "url_field_values" */
export enum Url_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "url_field_values" */
export type Url_Field_Values_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "url_field_values" */
export type Url_Field_Values_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Url_Field_Values_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Url_Field_Values_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_type?: InputMaybe<Scalars['String']['input']>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "url_field_values" */
export enum Url_Field_Values_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

export type Url_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Url_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Url_Field_Values_Bool_Exp;
};

/** columns and relationships of "user_controls_stats_view" */
export type User_Controls_Stats_View = {
  __typename?: 'user_controls_stats_view';
  controls_count?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  valid_controls_count?: Maybe<Scalars['Int']['output']>;
  valid_controls_ratio?: Maybe<Scalars['float8']['output']>;
};

/** aggregated selection of "user_controls_stats_view" */
export type User_Controls_Stats_View_Aggregate = {
  __typename?: 'user_controls_stats_view_aggregate';
  aggregate?: Maybe<User_Controls_Stats_View_Aggregate_Fields>;
  nodes: Array<User_Controls_Stats_View>;
};

/** aggregate fields of "user_controls_stats_view" */
export type User_Controls_Stats_View_Aggregate_Fields = {
  __typename?: 'user_controls_stats_view_aggregate_fields';
  avg?: Maybe<User_Controls_Stats_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Controls_Stats_View_Max_Fields>;
  min?: Maybe<User_Controls_Stats_View_Min_Fields>;
  stddev?: Maybe<User_Controls_Stats_View_Stddev_Fields>;
  stddev_pop?: Maybe<User_Controls_Stats_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Controls_Stats_View_Stddev_Samp_Fields>;
  sum?: Maybe<User_Controls_Stats_View_Sum_Fields>;
  var_pop?: Maybe<User_Controls_Stats_View_Var_Pop_Fields>;
  var_samp?: Maybe<User_Controls_Stats_View_Var_Samp_Fields>;
  variance?: Maybe<User_Controls_Stats_View_Variance_Fields>;
};

/** aggregate fields of "user_controls_stats_view" */
export type User_Controls_Stats_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Controls_Stats_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Controls_Stats_View_Avg_Fields = {
  __typename?: 'user_controls_stats_view_avg_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_ratio?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_controls_stats_view". All fields are combined with a logical 'AND'. */
export type User_Controls_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<User_Controls_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<User_Controls_Stats_View_Bool_Exp>>;
  controls_count?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_controls_count?: InputMaybe<Int_Comparison_Exp>;
  valid_controls_ratio?: InputMaybe<Float8_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Controls_Stats_View_Max_Fields = {
  __typename?: 'user_controls_stats_view_max_fields';
  controls_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  valid_controls_count?: Maybe<Scalars['Int']['output']>;
  valid_controls_ratio?: Maybe<Scalars['float8']['output']>;
};

/** aggregate min on columns */
export type User_Controls_Stats_View_Min_Fields = {
  __typename?: 'user_controls_stats_view_min_fields';
  controls_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  valid_controls_count?: Maybe<Scalars['Int']['output']>;
  valid_controls_ratio?: Maybe<Scalars['float8']['output']>;
};

/** Ordering options when selecting data from "user_controls_stats_view". */
export type User_Controls_Stats_View_Order_By = {
  controls_count?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_controls_count?: InputMaybe<Order_By>;
  valid_controls_ratio?: InputMaybe<Order_By>;
};

/** select columns of table "user_controls_stats_view" */
export enum User_Controls_Stats_View_Select_Column {
  /** column name */
  ControlsCount = 'controls_count',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidControlsCount = 'valid_controls_count',
  /** column name */
  ValidControlsRatio = 'valid_controls_ratio',
}

/** aggregate stddev on columns */
export type User_Controls_Stats_View_Stddev_Fields = {
  __typename?: 'user_controls_stats_view_stddev_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_ratio?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Controls_Stats_View_Stddev_Pop_Fields = {
  __typename?: 'user_controls_stats_view_stddev_pop_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_ratio?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Controls_Stats_View_Stddev_Samp_Fields = {
  __typename?: 'user_controls_stats_view_stddev_samp_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_ratio?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_controls_stats_view" */
export type User_Controls_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Controls_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Controls_Stats_View_Stream_Cursor_Value_Input = {
  controls_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  valid_controls_count?: InputMaybe<Scalars['Int']['input']>;
  valid_controls_ratio?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate sum on columns */
export type User_Controls_Stats_View_Sum_Fields = {
  __typename?: 'user_controls_stats_view_sum_fields';
  controls_count?: Maybe<Scalars['Int']['output']>;
  valid_controls_count?: Maybe<Scalars['Int']['output']>;
  valid_controls_ratio?: Maybe<Scalars['float8']['output']>;
};

/** aggregate var_pop on columns */
export type User_Controls_Stats_View_Var_Pop_Fields = {
  __typename?: 'user_controls_stats_view_var_pop_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_ratio?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Controls_Stats_View_Var_Samp_Fields = {
  __typename?: 'user_controls_stats_view_var_samp_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_ratio?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Controls_Stats_View_Variance_Fields = {
  __typename?: 'user_controls_stats_view_variance_fields';
  controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_count?: Maybe<Scalars['Float']['output']>;
  valid_controls_ratio?: Maybe<Scalars['Float']['output']>;
};

/** this table contains actual notification entry for a user for different delivery type */
export type User_Notifications = {
  __typename?: 'user_notifications';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  delivery_type: Notification_Delivery_Types_Enum;
  email_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notification: Notifications;
  /** An object relationship */
  notification_delivery_type: Notification_Delivery_Types;
  notification_id: Scalars['uuid']['output'];
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "user_notifications" */
export type User_Notifications_Aggregate = {
  __typename?: 'user_notifications_aggregate';
  aggregate?: Maybe<User_Notifications_Aggregate_Fields>;
  nodes: Array<User_Notifications>;
};

export type User_Notifications_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Notifications_Aggregate_Bool_Exp_Count>;
};

export type User_Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_notifications" */
export type User_Notifications_Aggregate_Fields = {
  __typename?: 'user_notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Notifications_Max_Fields>;
  min?: Maybe<User_Notifications_Min_Fields>;
};

/** aggregate fields of "user_notifications" */
export type User_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_notifications" */
export type User_Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Notifications_Max_Order_By>;
  min?: InputMaybe<User_Notifications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_notifications" */
export type User_Notifications_Arr_Rel_Insert_Input = {
  data: Array<User_Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_notifications". All fields are combined with a logical 'AND'. */
export type User_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<User_Notifications_Bool_Exp>>;
  _not?: InputMaybe<User_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<User_Notifications_Bool_Exp>>;
  archived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum_Comparison_Exp>;
  email_sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification?: InputMaybe<Notifications_Bool_Exp>;
  notification_delivery_type?: InputMaybe<Notification_Delivery_Types_Bool_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_notifications" */
export enum User_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserNotificationsPkey = 'user_notifications_pkey',
}

/** input type for inserting data into table "user_notifications" */
export type User_Notifications_Insert_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  email_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification?: InputMaybe<Notifications_Obj_Rel_Insert_Input>;
  notification_delivery_type?: InputMaybe<Notification_Delivery_Types_Obj_Rel_Insert_Input>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type User_Notifications_Max_Fields = {
  __typename?: 'user_notifications_max_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_notifications" */
export type User_Notifications_Max_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_sent_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Notifications_Min_Fields = {
  __typename?: 'user_notifications_min_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  email_sent_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_notifications" */
export type User_Notifications_Min_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email_sent_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_notifications" */
export type User_Notifications_Mutation_Response = {
  __typename?: 'user_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Notifications>;
};

/** on_conflict condition type for table "user_notifications" */
export type User_Notifications_On_Conflict = {
  constraint: User_Notifications_Constraint;
  update_columns?: Array<User_Notifications_Update_Column>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "user_notifications". */
export type User_Notifications_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_type?: InputMaybe<Order_By>;
  email_sent_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Notifications_Order_By>;
  notification_delivery_type?: InputMaybe<Notification_Delivery_Types_Order_By>;
  notification_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_notifications */
export type User_Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_notifications" */
export enum User_Notifications_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  EmailSentAt = 'email_sent_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_notifications" */
export type User_Notifications_Set_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  email_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "user_notifications" */
export type User_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Notifications_Stream_Cursor_Value_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  email_sent_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "user_notifications" */
export enum User_Notifications_Update_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  EmailSentAt = 'email_sent_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Notifications_Bool_Exp;
};

/** columns and relationships of "user_tasks_stats_view" */
export type User_Tasks_Stats_View = {
  __typename?: 'user_tasks_stats_view';
  completed_tasks_count?: Maybe<Scalars['Int']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  tasks_count?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "user_tasks_stats_view" */
export type User_Tasks_Stats_View_Aggregate = {
  __typename?: 'user_tasks_stats_view_aggregate';
  aggregate?: Maybe<User_Tasks_Stats_View_Aggregate_Fields>;
  nodes: Array<User_Tasks_Stats_View>;
};

/** aggregate fields of "user_tasks_stats_view" */
export type User_Tasks_Stats_View_Aggregate_Fields = {
  __typename?: 'user_tasks_stats_view_aggregate_fields';
  avg?: Maybe<User_Tasks_Stats_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Tasks_Stats_View_Max_Fields>;
  min?: Maybe<User_Tasks_Stats_View_Min_Fields>;
  stddev?: Maybe<User_Tasks_Stats_View_Stddev_Fields>;
  stddev_pop?: Maybe<User_Tasks_Stats_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Tasks_Stats_View_Stddev_Samp_Fields>;
  sum?: Maybe<User_Tasks_Stats_View_Sum_Fields>;
  var_pop?: Maybe<User_Tasks_Stats_View_Var_Pop_Fields>;
  var_samp?: Maybe<User_Tasks_Stats_View_Var_Samp_Fields>;
  variance?: Maybe<User_Tasks_Stats_View_Variance_Fields>;
};

/** aggregate fields of "user_tasks_stats_view" */
export type User_Tasks_Stats_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Tasks_Stats_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Tasks_Stats_View_Avg_Fields = {
  __typename?: 'user_tasks_stats_view_avg_fields';
  completed_tasks_count?: Maybe<Scalars['Float']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['Float']['output']>;
  tasks_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_tasks_stats_view". All fields are combined with a logical 'AND'. */
export type User_Tasks_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<User_Tasks_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<User_Tasks_Stats_View_Bool_Exp>>;
  completed_tasks_count?: InputMaybe<Int_Comparison_Exp>;
  completed_tasks_ratio?: InputMaybe<Float8_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  tasks_count?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Tasks_Stats_View_Max_Fields = {
  __typename?: 'user_tasks_stats_view_max_fields';
  completed_tasks_count?: Maybe<Scalars['Int']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['float8']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  tasks_count?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type User_Tasks_Stats_View_Min_Fields = {
  __typename?: 'user_tasks_stats_view_min_fields';
  completed_tasks_count?: Maybe<Scalars['Int']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['float8']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  tasks_count?: Maybe<Scalars['Int']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** Ordering options when selecting data from "user_tasks_stats_view". */
export type User_Tasks_Stats_View_Order_By = {
  completed_tasks_count?: InputMaybe<Order_By>;
  completed_tasks_ratio?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  tasks_count?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_tasks_stats_view" */
export enum User_Tasks_Stats_View_Select_Column {
  /** column name */
  CompletedTasksCount = 'completed_tasks_count',
  /** column name */
  CompletedTasksRatio = 'completed_tasks_ratio',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TasksCount = 'tasks_count',
  /** column name */
  UserId = 'user_id',
}

/** aggregate stddev on columns */
export type User_Tasks_Stats_View_Stddev_Fields = {
  __typename?: 'user_tasks_stats_view_stddev_fields';
  completed_tasks_count?: Maybe<Scalars['Float']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['Float']['output']>;
  tasks_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Tasks_Stats_View_Stddev_Pop_Fields = {
  __typename?: 'user_tasks_stats_view_stddev_pop_fields';
  completed_tasks_count?: Maybe<Scalars['Float']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['Float']['output']>;
  tasks_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Tasks_Stats_View_Stddev_Samp_Fields = {
  __typename?: 'user_tasks_stats_view_stddev_samp_fields';
  completed_tasks_count?: Maybe<Scalars['Float']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['Float']['output']>;
  tasks_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_tasks_stats_view" */
export type User_Tasks_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Tasks_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Tasks_Stats_View_Stream_Cursor_Value_Input = {
  completed_tasks_count?: InputMaybe<Scalars['Int']['input']>;
  completed_tasks_ratio?: InputMaybe<Scalars['float8']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  tasks_count?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type User_Tasks_Stats_View_Sum_Fields = {
  __typename?: 'user_tasks_stats_view_sum_fields';
  completed_tasks_count?: Maybe<Scalars['Int']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['float8']['output']>;
  tasks_count?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type User_Tasks_Stats_View_Var_Pop_Fields = {
  __typename?: 'user_tasks_stats_view_var_pop_fields';
  completed_tasks_count?: Maybe<Scalars['Float']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['Float']['output']>;
  tasks_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Tasks_Stats_View_Var_Samp_Fields = {
  __typename?: 'user_tasks_stats_view_var_samp_fields';
  completed_tasks_count?: Maybe<Scalars['Float']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['Float']['output']>;
  tasks_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Tasks_Stats_View_Variance_Fields = {
  __typename?: 'user_tasks_stats_view_variance_fields';
  completed_tasks_count?: Maybe<Scalars['Float']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['Float']['output']>;
  tasks_count?: Maybe<Scalars['Float']['output']>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type Users = {
  __typename?: 'users';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl: Scalars['String']['output'];
  /** An array relationship */
  client_questionnaire_answers: Array<Client_Questionnaire_Answers>;
  /** An aggregate relationship */
  client_questionnaire_answers_aggregate: Client_Questionnaire_Answers_Aggregate;
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** An aggregate relationship */
  client_questionnaires_aggregate: Client_Questionnaires_Aggregate;
  /** An array relationship */
  controlEvidenceVersionsByUpdatedBy: Array<Evidence_Versions>;
  /** An aggregate relationship */
  controlEvidenceVersionsByUpdatedBy_aggregate: Evidence_Versions_Aggregate;
  /** An array relationship */
  control_evidence_versions: Array<Evidence_Versions>;
  /** An aggregate relationship */
  control_evidence_versions_aggregate: Evidence_Versions_Aggregate;
  /** An array relationship */
  control_evidences: Array<Evidences>;
  /** An aggregate relationship */
  control_evidences_aggregate: Evidences_Aggregate;
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  /** An array relationship */
  controls_history_stats: Array<Controls_History_Stats>;
  /** An aggregate relationship */
  controls_history_stats_aggregate: Controls_History_Stats_Aggregate;
  createdAt: Scalars['timestamptz']['output'];
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole: Scalars['String']['output'];
  /** An object relationship */
  defaultRoleByRole: AuthRoles;
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['citext']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  /** An array relationship */
  evidence_acls: Array<Evidence_Acl>;
  /** An aggregate relationship */
  evidence_acls_aggregate: Evidence_Acl_Aggregate;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  invitations: Array<Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Invitations_Aggregate;
  isAnonymous: Scalars['Boolean']['output'];
  joined_with_sso?: Maybe<Scalars['Boolean']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale: Scalars['String']['output'];
  metadata: Scalars['UserMetaData']['output'];
  newEmail?: Maybe<Scalars['citext']['output']>;
  /** An array relationship */
  notification_disabled_settings: Array<Notification_Disabled_Settings>;
  /** An aggregate relationship */
  notification_disabled_settings_aggregate: Notification_Disabled_Settings_Aggregate;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An aggregate relationship */
  organization_users_aggregate: Organization_Users_Aggregate;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt: Scalars['timestamptz']['output'];
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberVerified: Scalars['Boolean']['output'];
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
  /** An array relationship */
  policy_acknowledgement_users: Array<Policy_Acknowledgement_Users>;
  /** An aggregate relationship */
  policy_acknowledgement_users_aggregate: Policy_Acknowledgement_Users_Aggregate;
  /** An array relationship */
  policy_acknowledgements: Array<Policy_Acknowledgements>;
  /** An aggregate relationship */
  policy_acknowledgements_aggregate: Policy_Acknowledgements_Aggregate;
  /** An array relationship */
  policy_approval_users: Array<Policy_Approval_Users>;
  /** An aggregate relationship */
  policy_approval_users_aggregate: Policy_Approval_Users_Aggregate;
  /** An array relationship */
  policy_approvals: Array<Policy_Approvals>;
  /** An aggregate relationship */
  policy_approvals_aggregate: Policy_Approvals_Aggregate;
  /** An array relationship */
  policy_approvers: Array<Policy_Approvers>;
  /** An aggregate relationship */
  policy_approvers_aggregate: Policy_Approvers_Aggregate;
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  /** An aggregate relationship */
  policy_versions_aggregate: Policy_Versions_Aggregate;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Questionnaires_Aggregate;
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An aggregate relationship */
  refreshTokens_aggregate: AuthRefreshTokens_Aggregate;
  /** An array relationship */
  reports: Array<Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reports_Aggregate;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** An array relationship */
  roles: Array<AuthUserRoles>;
  /** An aggregate relationship */
  roles_aggregate: AuthUserRoles_Aggregate;
  /** An array relationship */
  securityKeys: Array<AuthUserSecurityKeys>;
  /** An aggregate relationship */
  securityKeys_aggregate: AuthUserSecurityKeys_Aggregate;
  /** An array relationship */
  task_owners: Array<Task_Owners>;
  /** An aggregate relationship */
  task_owners_aggregate: Task_Owners_Aggregate;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt: Scalars['timestamptz']['output'];
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  userProviders: Array<AuthUserProviders>;
  /** An aggregate relationship */
  userProviders_aggregate: AuthUserProviders_Aggregate;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** An array relationship */
  users_comments: Array<Comments>;
  /** An aggregate relationship */
  users_comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  vendor_questionnaires_sent_by: Array<Vendor_Questionnaires>;
  /** An aggregate relationship */
  vendor_questionnaires_sent_by_aggregate: Vendor_Questionnaires_Aggregate;
  /** An array relationship */
  vendor_questionnaires_sent_to: Array<Vendor_Questionnaires>;
  /** An aggregate relationship */
  vendor_questionnaires_sent_to_aggregate: Vendor_Questionnaires_Aggregate;
  /** An array relationship */
  vendors: Array<Vendors>;
  /** An aggregate relationship */
  vendors_aggregate: Vendors_Aggregate;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersClient_Questionnaire_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersClient_Questionnaire_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersClient_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControlEvidenceVersionsByUpdatedByArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControlEvidenceVersionsByUpdatedBy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControl_Evidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControl_Evidence_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControl_EvidencesArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControl_Evidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControls_History_StatsArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControls_History_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersEvidence_AclsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersEvidence_Acls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersInvitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersNotification_Disabled_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersNotification_Disabled_Settings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersOrganization_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Acknowledgement_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Acknowledgement_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_AcknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Acknowledgements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Approval_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Approval_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_ApprovalsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Approvals_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Approvers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Versions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRolesArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserRoles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserRoles_Order_By>>;
  where?: InputMaybe<AuthUserRoles_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSecurityKeysArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersSecurityKeys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSecurityKeys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserSecurityKeys_Order_By>>;
  where?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersTask_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersTask_Owners_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUserProvidersArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUserProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserProviders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserProviders_Order_By>>;
  where?: InputMaybe<AuthUserProviders_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUsers_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUsers_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendor_Questionnaires_Sent_ByArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendor_Questionnaires_Sent_By_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendor_Questionnaires_Sent_ToArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendor_Questionnaires_Sent_To_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** aggregated selection of "auth.users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "auth.users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};

/** aggregate fields of "auth.users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  metadata?: InputMaybe<Scalars['UserMetaData']['input']>;
};

/** input type for inserting array relation for remote table "auth.users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  activeMfaType?: InputMaybe<String_Comparison_Exp>;
  avatarUrl?: InputMaybe<String_Comparison_Exp>;
  client_questionnaire_answers?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  client_questionnaire_answers_aggregate?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Bool_Exp>;
  client_questionnaires?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Bool_Exp>;
  controlEvidenceVersionsByUpdatedBy?: InputMaybe<Evidence_Versions_Bool_Exp>;
  controlEvidenceVersionsByUpdatedBy_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Bool_Exp>;
  control_evidence_versions?: InputMaybe<Evidence_Versions_Bool_Exp>;
  control_evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Bool_Exp>;
  control_evidences?: InputMaybe<Evidences_Bool_Exp>;
  control_evidences_aggregate?: InputMaybe<Evidences_Aggregate_Bool_Exp>;
  controls?: InputMaybe<Controls_Bool_Exp>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Bool_Exp>;
  controls_history_stats?: InputMaybe<Controls_History_Stats_Bool_Exp>;
  controls_history_stats_aggregate?: InputMaybe<Controls_History_Stats_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  currentChallenge?: InputMaybe<String_Comparison_Exp>;
  defaultRole?: InputMaybe<String_Comparison_Exp>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Bool_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  emailVerified?: InputMaybe<Boolean_Comparison_Exp>;
  evidence_acls?: InputMaybe<Evidence_Acl_Bool_Exp>;
  evidence_acls_aggregate?: InputMaybe<Evidence_Acl_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitations?: InputMaybe<Invitations_Bool_Exp>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Bool_Exp>;
  isAnonymous?: InputMaybe<Boolean_Comparison_Exp>;
  joined_with_sso?: InputMaybe<Boolean_Comparison_Exp>;
  lastSeen?: InputMaybe<Timestamptz_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  newEmail?: InputMaybe<Citext_Comparison_Exp>;
  notification_disabled_settings?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
  notification_disabled_settings_aggregate?: InputMaybe<Notification_Disabled_Settings_Aggregate_Bool_Exp>;
  organization_users?: InputMaybe<Organization_Users_Bool_Exp>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Bool_Exp>;
  otpHash?: InputMaybe<String_Comparison_Exp>;
  otpHashExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  otpMethodLastUsed?: InputMaybe<String_Comparison_Exp>;
  passwordHash?: InputMaybe<String_Comparison_Exp>;
  phoneNumber?: InputMaybe<String_Comparison_Exp>;
  phoneNumberVerified?: InputMaybe<Boolean_Comparison_Exp>;
  policies?: InputMaybe<Policies_Bool_Exp>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Bool_Exp>;
  policy_acknowledgement_users?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
  policy_acknowledgement_users_aggregate?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Bool_Exp>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  policy_acknowledgements_aggregate?: InputMaybe<Policy_Acknowledgements_Aggregate_Bool_Exp>;
  policy_approval_users?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
  policy_approval_users_aggregate?: InputMaybe<Policy_Approval_Users_Aggregate_Bool_Exp>;
  policy_approvals?: InputMaybe<Policy_Approvals_Bool_Exp>;
  policy_approvals_aggregate?: InputMaybe<Policy_Approvals_Aggregate_Bool_Exp>;
  policy_approvers?: InputMaybe<Policy_Approvers_Bool_Exp>;
  policy_approvers_aggregate?: InputMaybe<Policy_Approvers_Aggregate_Bool_Exp>;
  policy_versions?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Bool_Exp>;
  questionnaires?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Bool_Exp>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Bool_Exp>;
  reports?: InputMaybe<Reports_Bool_Exp>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Bool_Exp>;
  reviews?: InputMaybe<Reviews_Bool_Exp>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Bool_Exp>;
  risks?: InputMaybe<Risks_Bool_Exp>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  roles?: InputMaybe<AuthUserRoles_Bool_Exp>;
  roles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Bool_Exp>;
  securityKeys?: InputMaybe<AuthUserSecurityKeys_Bool_Exp>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeys_Aggregate_Bool_Exp>;
  task_owners?: InputMaybe<Task_Owners_Bool_Exp>;
  task_owners_aggregate?: InputMaybe<Task_Owners_Aggregate_Bool_Exp>;
  ticket?: InputMaybe<String_Comparison_Exp>;
  ticketExpiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  totpSecret?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userProviders?: InputMaybe<AuthUserProviders_Bool_Exp>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Bool_Exp>;
  user_notifications?: InputMaybe<User_Notifications_Bool_Exp>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Bool_Exp>;
  users_comments?: InputMaybe<Comments_Bool_Exp>;
  users_comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  vendor_questionnaires_sent_by?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaires_sent_by_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp>;
  vendor_questionnaires_sent_to?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaires_sent_to_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberKey = 'users_phone_number_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "auth.users" */
export type Users_Insert_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  client_questionnaire_answers?: InputMaybe<Client_Questionnaire_Answers_Arr_Rel_Insert_Input>;
  client_questionnaires?: InputMaybe<Client_Questionnaires_Arr_Rel_Insert_Input>;
  controlEvidenceVersionsByUpdatedBy?: InputMaybe<Evidence_Versions_Arr_Rel_Insert_Input>;
  control_evidence_versions?: InputMaybe<Evidence_Versions_Arr_Rel_Insert_Input>;
  control_evidences?: InputMaybe<Evidences_Arr_Rel_Insert_Input>;
  controls?: InputMaybe<Controls_Arr_Rel_Insert_Input>;
  controls_history_stats?: InputMaybe<Controls_History_Stats_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Obj_Rel_Insert_Input>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  evidence_acls?: InputMaybe<Evidence_Acl_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  invitations?: InputMaybe<Invitations_Arr_Rel_Insert_Input>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  joined_with_sso?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  notification_disabled_settings?: InputMaybe<Notification_Disabled_Settings_Arr_Rel_Insert_Input>;
  organization_users?: InputMaybe<Organization_Users_Arr_Rel_Insert_Input>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  policies?: InputMaybe<Policies_Arr_Rel_Insert_Input>;
  policy_acknowledgement_users?: InputMaybe<Policy_Acknowledgement_Users_Arr_Rel_Insert_Input>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Arr_Rel_Insert_Input>;
  policy_approval_users?: InputMaybe<Policy_Approval_Users_Arr_Rel_Insert_Input>;
  policy_approvals?: InputMaybe<Policy_Approvals_Arr_Rel_Insert_Input>;
  policy_approvers?: InputMaybe<Policy_Approvers_Arr_Rel_Insert_Input>;
  policy_versions?: InputMaybe<Policy_Versions_Arr_Rel_Insert_Input>;
  questionnaires?: InputMaybe<Questionnaires_Arr_Rel_Insert_Input>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Arr_Rel_Insert_Input>;
  reports?: InputMaybe<Reports_Arr_Rel_Insert_Input>;
  reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  risks?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  roles?: InputMaybe<AuthUserRoles_Arr_Rel_Insert_Input>;
  securityKeys?: InputMaybe<AuthUserSecurityKeys_Arr_Rel_Insert_Input>;
  task_owners?: InputMaybe<Task_Owners_Arr_Rel_Insert_Input>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userProviders?: InputMaybe<AuthUserProviders_Arr_Rel_Insert_Input>;
  user_notifications?: InputMaybe<User_Notifications_Arr_Rel_Insert_Input>;
  users_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  vendor_questionnaires_sent_by?: InputMaybe<Vendor_Questionnaires_Arr_Rel_Insert_Input>;
  vendor_questionnaires_sent_to?: InputMaybe<Vendor_Questionnaires_Arr_Rel_Insert_Input>;
  vendors?: InputMaybe<Vendors_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "auth.users" */
export type Users_Max_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  defaultRole?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  currentChallenge?: Maybe<Scalars['String']['output']>;
  defaultRole?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['citext']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  lastSeen?: Maybe<Scalars['timestamptz']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  newEmail?: Maybe<Scalars['citext']['output']>;
  otpHash?: Maybe<Scalars['String']['output']>;
  otpHashExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  otpMethodLastUsed?: Maybe<Scalars['String']['output']>;
  passwordHash?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  ticket?: Maybe<Scalars['String']['output']>;
  ticketExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  totpSecret?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "auth.users" */
export type Users_Min_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  defaultRole?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "auth.users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "auth.users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.users". */
export type Users_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  client_questionnaire_answers_aggregate?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Order_By>;
  client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Order_By>;
  controlEvidenceVersionsByUpdatedBy_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Order_By>;
  control_evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Order_By>;
  control_evidences_aggregate?: InputMaybe<Evidences_Aggregate_Order_By>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Order_By>;
  controls_history_stats_aggregate?: InputMaybe<Controls_History_Stats_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  currentChallenge?: InputMaybe<Order_By>;
  defaultRole?: InputMaybe<Order_By>;
  defaultRoleByRole?: InputMaybe<AuthRoles_Order_By>;
  disabled?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  emailVerified?: InputMaybe<Order_By>;
  evidence_acls_aggregate?: InputMaybe<Evidence_Acl_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Order_By>;
  isAnonymous?: InputMaybe<Order_By>;
  joined_with_sso?: InputMaybe<Order_By>;
  lastSeen?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  newEmail?: InputMaybe<Order_By>;
  notification_disabled_settings_aggregate?: InputMaybe<Notification_Disabled_Settings_Aggregate_Order_By>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Order_By>;
  otpHash?: InputMaybe<Order_By>;
  otpHashExpiresAt?: InputMaybe<Order_By>;
  otpMethodLastUsed?: InputMaybe<Order_By>;
  passwordHash?: InputMaybe<Order_By>;
  phoneNumber?: InputMaybe<Order_By>;
  phoneNumberVerified?: InputMaybe<Order_By>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Order_By>;
  policy_acknowledgement_users_aggregate?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Order_By>;
  policy_acknowledgements_aggregate?: InputMaybe<Policy_Acknowledgements_Aggregate_Order_By>;
  policy_approval_users_aggregate?: InputMaybe<Policy_Approval_Users_Aggregate_Order_By>;
  policy_approvals_aggregate?: InputMaybe<Policy_Approvals_Aggregate_Order_By>;
  policy_approvers_aggregate?: InputMaybe<Policy_Approvers_Aggregate_Order_By>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Order_By>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Order_By>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Order_By>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Order_By>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  roles_aggregate?: InputMaybe<AuthUserRoles_Aggregate_Order_By>;
  securityKeys_aggregate?: InputMaybe<AuthUserSecurityKeys_Aggregate_Order_By>;
  task_owners_aggregate?: InputMaybe<Task_Owners_Aggregate_Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticketExpiresAt?: InputMaybe<Order_By>;
  totpSecret?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userProviders_aggregate?: InputMaybe<AuthUserProviders_Aggregate_Order_By>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Order_By>;
  users_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  vendor_questionnaires_sent_by_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
  vendor_questionnaires_sent_to_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.users" */
export enum Users_Select_Column {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  JoinedWithSso = 'joined_with_sso',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "auth.users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  JoinedWithSso = 'joined_with_sso',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "auth.users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  JoinedWithSso = 'joined_with_sso',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
}

/** input type for updating data in table "auth.users" */
export type Users_Set_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  joined_with_sso?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  currentChallenge?: InputMaybe<Scalars['String']['input']>;
  defaultRole?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  emailVerified?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
  joined_with_sso?: InputMaybe<Scalars['Boolean']['input']>;
  lastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  newEmail?: InputMaybe<Scalars['citext']['input']>;
  otpHash?: InputMaybe<Scalars['String']['input']>;
  otpHashExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  otpMethodLastUsed?: InputMaybe<Scalars['String']['input']>;
  passwordHash?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberVerified?: InputMaybe<Scalars['Boolean']['input']>;
  ticket?: InputMaybe<Scalars['String']['input']>;
  ticketExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  totpSecret?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "auth.users" */
export enum Users_Update_Column {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CurrentChallenge = 'currentChallenge',
  /** column name */
  DefaultRole = 'defaultRole',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'emailVerified',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'isAnonymous',
  /** column name */
  JoinedWithSso = 'joined_with_sso',
  /** column name */
  LastSeen = 'lastSeen',
  /** column name */
  Locale = 'locale',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NewEmail = 'newEmail',
  /** column name */
  OtpHash = 'otpHash',
  /** column name */
  OtpHashExpiresAt = 'otpHashExpiresAt',
  /** column name */
  OtpMethodLastUsed = 'otpMethodLastUsed',
  /** column name */
  PasswordHash = 'passwordHash',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PhoneNumberVerified = 'phoneNumberVerified',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticketExpiresAt',
  /** column name */
  TotpSecret = 'totpSecret',
  /** column name */
  UpdatedAt = 'updatedAt',
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "vector". All fields are combined with logical 'AND'. */
export type Vector_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['vector']['input']>;
  _gt?: InputMaybe<Scalars['vector']['input']>;
  _gte?: InputMaybe<Scalars['vector']['input']>;
  _in?: InputMaybe<Array<Scalars['vector']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['vector']['input']>;
  _lte?: InputMaybe<Scalars['vector']['input']>;
  _neq?: InputMaybe<Scalars['vector']['input']>;
  _nin?: InputMaybe<Array<Scalars['vector']['input']>>;
};

/** columns and relationships of "vendor_approval_statuses" */
export type Vendor_Approval_Statuses = {
  __typename?: 'vendor_approval_statuses';
  name: Scalars['String']['output'];
  /** An array relationship */
  vendors: Array<Vendors>;
  /** An aggregate relationship */
  vendors_aggregate: Vendors_Aggregate;
};

/** columns and relationships of "vendor_approval_statuses" */
export type Vendor_Approval_StatusesVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** columns and relationships of "vendor_approval_statuses" */
export type Vendor_Approval_StatusesVendors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** aggregated selection of "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_Aggregate = {
  __typename?: 'vendor_approval_statuses_aggregate';
  aggregate?: Maybe<Vendor_Approval_Statuses_Aggregate_Fields>;
  nodes: Array<Vendor_Approval_Statuses>;
};

/** aggregate fields of "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_Aggregate_Fields = {
  __typename?: 'vendor_approval_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Approval_Statuses_Max_Fields>;
  min?: Maybe<Vendor_Approval_Statuses_Min_Fields>;
};

/** aggregate fields of "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Approval_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "vendor_approval_statuses". All fields are combined with a logical 'AND'. */
export type Vendor_Approval_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Approval_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Approval_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Approval_Statuses_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vendor_approval_statuses" */
export enum Vendor_Approval_Statuses_Constraint {
  /** unique or primary key constraint on columns "name" */
  VendorStatusesPkey = 'vendor_statuses_pkey',
}

export enum Vendor_Approval_Statuses_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
}

/** Boolean expression to compare columns of type "vendor_approval_statuses_enum". All fields are combined with logical 'AND'. */
export type Vendor_Approval_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  _in?: InputMaybe<Array<Vendor_Approval_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  _nin?: InputMaybe<Array<Vendor_Approval_Statuses_Enum>>;
};

/** input type for inserting data into table "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  vendors?: InputMaybe<Vendors_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vendor_Approval_Statuses_Max_Fields = {
  __typename?: 'vendor_approval_statuses_max_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Vendor_Approval_Statuses_Min_Fields = {
  __typename?: 'vendor_approval_statuses_min_fields';
  name?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_Mutation_Response = {
  __typename?: 'vendor_approval_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Approval_Statuses>;
};

/** input type for inserting object relation for remote table "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_Obj_Rel_Insert_Input = {
  data: Vendor_Approval_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Approval_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_On_Conflict = {
  constraint: Vendor_Approval_Statuses_Constraint;
  update_columns?: Array<Vendor_Approval_Statuses_Update_Column>;
  where?: InputMaybe<Vendor_Approval_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_approval_statuses". */
export type Vendor_Approval_Statuses_Order_By = {
  name?: InputMaybe<Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
};

/** primary key columns input for table: vendor_approval_statuses */
export type Vendor_Approval_Statuses_Pk_Columns_Input = {
  name: Scalars['String']['input'];
};

/** select columns of table "vendor_approval_statuses" */
export enum Vendor_Approval_Statuses_Select_Column {
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "vendor_approval_statuses" */
export type Vendor_Approval_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Approval_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Approval_Statuses_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "vendor_approval_statuses" */
export enum Vendor_Approval_Statuses_Update_Column {
  /** column name */
  Name = 'name',
}

export type Vendor_Approval_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Approval_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Approval_Statuses_Bool_Exp;
};

/** columns and relationships of "vendor_documents" */
export type Vendor_Documents = {
  __typename?: 'vendor_documents';
  /** An object relationship */
  file?: Maybe<Files>;
  file_id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id: Scalars['uuid']['output'];
};

/** aggregated selection of "vendor_documents" */
export type Vendor_Documents_Aggregate = {
  __typename?: 'vendor_documents_aggregate';
  aggregate?: Maybe<Vendor_Documents_Aggregate_Fields>;
  nodes: Array<Vendor_Documents>;
};

export type Vendor_Documents_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vendor_Documents_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Documents_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Documents_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_documents" */
export type Vendor_Documents_Aggregate_Fields = {
  __typename?: 'vendor_documents_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Documents_Max_Fields>;
  min?: Maybe<Vendor_Documents_Min_Fields>;
};

/** aggregate fields of "vendor_documents" */
export type Vendor_Documents_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_documents" */
export type Vendor_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Documents_Max_Order_By>;
  min?: InputMaybe<Vendor_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_documents" */
export type Vendor_Documents_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_documents". All fields are combined with a logical 'AND'. */
export type Vendor_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Documents_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Documents_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_documents" */
export enum Vendor_Documents_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  VendorDocumentsFileIdKey = 'vendor_documents_file_id_key',
  /** unique or primary key constraint on columns "vendor_id", "file_id" */
  VendorDocumentsPkey = 'vendor_documents_pkey',
}

/** input type for inserting data into table "vendor_documents" */
export type Vendor_Documents_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Documents_Max_Fields = {
  __typename?: 'vendor_documents_max_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_documents" */
export type Vendor_Documents_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Documents_Min_Fields = {
  __typename?: 'vendor_documents_min_fields';
  file_id?: Maybe<Scalars['uuid']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_documents" */
export type Vendor_Documents_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_documents" */
export type Vendor_Documents_Mutation_Response = {
  __typename?: 'vendor_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Documents>;
};

/** input type for inserting object relation for remote table "vendor_documents" */
export type Vendor_Documents_Obj_Rel_Insert_Input = {
  data: Vendor_Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Documents_On_Conflict>;
};

/** on_conflict condition type for table "vendor_documents" */
export type Vendor_Documents_On_Conflict = {
  constraint: Vendor_Documents_Constraint;
  update_columns?: Array<Vendor_Documents_Update_Column>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_documents". */
export type Vendor_Documents_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_documents */
export type Vendor_Documents_Pk_Columns_Input = {
  file_id: Scalars['uuid']['input'];
  vendor_id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_documents" */
export enum Vendor_Documents_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "vendor_documents" */
export type Vendor_Documents_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_documents" */
export type Vendor_Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Documents_Stream_Cursor_Value_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_documents" */
export enum Vendor_Documents_Update_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  VendorId = 'vendor_id',
}

export type Vendor_Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Documents_Bool_Exp;
};

/** Set of relevant findings from humans and AI that need to be highlighted for vendors */
export type Vendor_Findings = {
  __typename?: 'vendor_findings';
  cause: Finding_Causes_Enum;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  ignored_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  type: Finding_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendor_id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor_questionnaire?: Maybe<Vendor_Questionnaires>;
  /** An object relationship */
  vendor_questionnaire_ai_review_assessment?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments>;
  vendor_questionnaire_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "vendor_findings" */
export type Vendor_Findings_Aggregate = {
  __typename?: 'vendor_findings_aggregate';
  aggregate?: Maybe<Vendor_Findings_Aggregate_Fields>;
  nodes: Array<Vendor_Findings>;
};

export type Vendor_Findings_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vendor_Findings_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Findings_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Findings_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_findings" */
export type Vendor_Findings_Aggregate_Fields = {
  __typename?: 'vendor_findings_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Findings_Max_Fields>;
  min?: Maybe<Vendor_Findings_Min_Fields>;
};

/** aggregate fields of "vendor_findings" */
export type Vendor_Findings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_findings" */
export type Vendor_Findings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Findings_Max_Order_By>;
  min?: InputMaybe<Vendor_Findings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_findings" */
export type Vendor_Findings_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Findings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Findings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_findings". All fields are combined with a logical 'AND'. */
export type Vendor_Findings_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Findings_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Findings_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Findings_Bool_Exp>>;
  cause?: InputMaybe<Finding_Causes_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ignored_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Finding_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_findings" */
export enum Vendor_Findings_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorFindingsPkey = 'vendor_findings_pkey',
  /** unique or primary key constraint on columns "risk_id" */
  VendorFindingsRiskIdKey = 'vendor_findings_risk_id_key',
  /** unique or primary key constraint on columns "task_id" */
  VendorFindingsTaskIdKey = 'vendor_findings_task_id_key',
  /** unique or primary key constraint on columns "vendor_questionnaire_ai_review_assessment_id" */
  VendorFindingsVendorQuestionnaireAiReviewAssessmentIKey = 'vendor_findings_vendor_questionnaire_ai_review_assessment_i_key',
}

/** input type for inserting data into table "vendor_findings" */
export type Vendor_Findings_Insert_Input = {
  cause?: InputMaybe<Finding_Causes_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ignored_at?: InputMaybe<Scalars['timestamptz']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Obj_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_assessment?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Obj_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Findings_Max_Fields = {
  __typename?: 'vendor_findings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ignored_at?: Maybe<Scalars['timestamptz']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_findings" */
export type Vendor_Findings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Findings_Min_Fields = {
  __typename?: 'vendor_findings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  ignored_at?: Maybe<Scalars['timestamptz']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_findings" */
export type Vendor_Findings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_findings" */
export type Vendor_Findings_Mutation_Response = {
  __typename?: 'vendor_findings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Findings>;
};

/** input type for inserting object relation for remote table "vendor_findings" */
export type Vendor_Findings_Obj_Rel_Insert_Input = {
  data: Vendor_Findings_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Findings_On_Conflict>;
};

/** on_conflict condition type for table "vendor_findings" */
export type Vendor_Findings_On_Conflict = {
  constraint: Vendor_Findings_Constraint;
  update_columns?: Array<Vendor_Findings_Update_Column>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_findings". */
export type Vendor_Findings_Order_By = {
  cause?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_ai_review_assessment?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_findings */
export type Vendor_Findings_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_findings" */
export enum Vendor_Findings_Select_Column {
  /** column name */
  Cause = 'cause',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IgnoredAt = 'ignored_at',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorQuestionnaireAiReviewAssessmentId = 'vendor_questionnaire_ai_review_assessment_id',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** input type for updating data in table "vendor_findings" */
export type Vendor_Findings_Set_Input = {
  cause?: InputMaybe<Finding_Causes_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ignored_at?: InputMaybe<Scalars['timestamptz']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_findings" */
export type Vendor_Findings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Findings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Findings_Stream_Cursor_Value_Input = {
  cause?: InputMaybe<Finding_Causes_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  ignored_at?: InputMaybe<Scalars['timestamptz']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Finding_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_findings" */
export enum Vendor_Findings_Update_Column {
  /** column name */
  Cause = 'cause',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IgnoredAt = 'ignored_at',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorQuestionnaireAiReviewAssessmentId = 'vendor_questionnaire_ai_review_assessment_id',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

export type Vendor_Findings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Findings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Findings_Bool_Exp;
};

/** Set of text fragments and sources that the AI harnessed to provide an assessment */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  similarity: Scalars['float8']['output'];
  text: Scalars['String']['output'];
  /** An object relationship */
  vendor_questionnaire_ai_review_assessment: Vendor_Questionnaire_Ai_Review_Assessments;
  vendor_questionnaire_ai_review_assessment_id: Scalars['uuid']['output'];
};

/** aggregated selection of "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Var_Samp>;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Avg = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Corr = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments =
  {
    X: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
    Y: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  };

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Max = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Min = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Sum = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_aggregate_fields';
  avg?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Min_Fields>;
  stddev?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Fields>;
  stddev_pop?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Samp_Fields>;
  sum?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Sum_Fields>;
  var_pop?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Var_Pop_Fields>;
  var_samp?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Var_Samp_Fields>;
  variance?: Maybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Variance_Fields>;
};

/** aggregate fields of "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Order_By = {
  avg?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Min_Order_By>;
  stddev?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Sum_Order_By>;
  var_pop?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Var_Samp_Order_By>;
  variance?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_On_Conflict>;
};

/** aggregate avg on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Avg_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_ai_review_assessment_sources". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  vendor_questionnaire_ai_review_assessment?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireAiReviewAssessmentSourcesPkey = 'vendor_questionnaire_ai_review_assessment_sources_pkey',
}

/** input type for incrementing numeric columns in table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vendor_questionnaire_ai_review_assessment?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Obj_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Max_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  vendor_questionnaire_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Min_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  vendor_questionnaire_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Mutation_Response = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
};

/** on_conflict condition type for table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_On_Conflict = {
  constraint: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_ai_review_assessment_sources". */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessment?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_ai_review_assessment_sources */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Text = 'text',
  /** column name */
  VendorQuestionnaireAiReviewAssessmentId = 'vendor_questionnaire_ai_review_assessment_id',
}

/** select "vendor_questionnaire_ai_review_assessment_sources_aggregate_bool_exp_avg_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendor_questionnaire_ai_review_assessment_sources_aggregate_bool_exp_corr_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendor_questionnaire_ai_review_assessment_sources_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendor_questionnaire_ai_review_assessment_sources_aggregate_bool_exp_max_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendor_questionnaire_ai_review_assessment_sources_aggregate_bool_exp_min_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendor_questionnaire_ai_review_assessment_sources_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendor_questionnaire_ai_review_assessment_sources_aggregate_bool_exp_sum_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendor_questionnaire_ai_review_assessment_sources_aggregate_bool_exp_var_samp_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column_Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Pop_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Samp_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Sum_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export enum Vendor_Questionnaire_Ai_Review_Assessment_Sources_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Text = 'text',
  /** column name */
  VendorQuestionnaireAiReviewAssessmentId = 'vendor_questionnaire_ai_review_assessment_id',
}

export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Var_Pop_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Var_Samp_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Variance_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessment_sources_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "vendor_questionnaire_ai_review_assessment_sources" */
export type Vendor_Questionnaire_Ai_Review_Assessment_Sources_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Assessments to individual questions based on AI Criteria */
export type Vendor_Questionnaire_Ai_Review_Assessments = {
  __typename?: 'vendor_questionnaire_ai_review_assessments';
  assessment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  criteria: Scalars['String']['output'];
  criteria_heading: Scalars['String']['output'];
  high_confidence: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor_finding?: Maybe<Vendor_Findings>;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessment_sources: Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_assessment_sources_aggregate: Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate;
  /** An object relationship */
  vendor_questionnaire_ai_review_run: Vendor_Questionnaire_Ai_Review_Runs;
  vendor_questionnaire_ai_review_run_id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor_questionnaire_form_answer: Vendor_Questionnaire_Form_Answers;
  vendor_questionnaire_form_answer_id: Scalars['uuid']['output'];
};

/** Assessments to individual questions based on AI Criteria */
export type Vendor_Questionnaire_Ai_Review_AssessmentsVendor_Questionnaire_Ai_Review_Assessment_SourcesArgs =
  {
    distinct_on?: InputMaybe<
      Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By>>;
    where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  };

/** Assessments to individual questions based on AI Criteria */
export type Vendor_Questionnaire_Ai_Review_AssessmentsVendor_Questionnaire_Ai_Review_Assessment_Sources_AggregateArgs =
  {
    distinct_on?: InputMaybe<
      Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Select_Column>
    >;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Order_By>>;
    where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  };

/** aggregated selection of "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate = {
  __typename?: 'vendor_questionnaire_ai_review_assessments_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Ai_Review_Assessments>;
};

export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_And = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessments_Select_Column_Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Vendor_Questionnaire_Ai_Review_Assessments_Select_Column_Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessments_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaire_Ai_Review_Assessments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_ai_review_assessments". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>>;
  assessment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  criteria?: InputMaybe<String_Comparison_Exp>;
  criteria_heading?: InputMaybe<String_Comparison_Exp>;
  high_confidence?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_finding?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment_sources?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment_sources_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Bool_Exp>;
  vendor_questionnaire_ai_review_run?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire_form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_ai_review_assessments" */
export enum Vendor_Questionnaire_Ai_Review_Assessments_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireAiReviewAssessmentsPkey = 'vendor_questionnaire_ai_review_assessments_pkey',
}

/** input type for inserting data into table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Insert_Input = {
  assessment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  criteria_heading?: InputMaybe<Scalars['String']['input']>;
  high_confidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_finding?: InputMaybe<Vendor_Findings_Obj_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_assessment_sources?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Arr_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_run?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Obj_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Obj_Rel_Insert_Input>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Ai_Review_Assessments_Max_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessments_max_fields';
  assessment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  criteria?: Maybe<Scalars['String']['output']>;
  criteria_heading?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_ai_review_run_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_form_answer_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Max_Order_By = {
  assessment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Ai_Review_Assessments_Min_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_assessments_min_fields';
  assessment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  criteria?: Maybe<Scalars['String']['output']>;
  criteria_heading?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_ai_review_run_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_form_answer_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Min_Order_By = {
  assessment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Mutation_Response = {
  __typename?: 'vendor_questionnaire_ai_review_assessments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Ai_Review_Assessments>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Ai_Review_Assessments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_On_Conflict = {
  constraint: Vendor_Questionnaire_Ai_Review_Assessments_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Ai_Review_Assessments_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_ai_review_assessments". */
export type Vendor_Questionnaire_Ai_Review_Assessments_Order_By = {
  assessment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  high_confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  vendor_finding?: InputMaybe<Vendor_Findings_Order_By>;
  vendor_questionnaire_ai_review_assessment_sources_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessment_Sources_Aggregate_Order_By>;
  vendor_questionnaire_ai_review_run?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Order_By>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_ai_review_assessments */
export type Vendor_Questionnaire_Ai_Review_Assessments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaire_ai_review_assessments" */
export enum Vendor_Questionnaire_Ai_Review_Assessments_Select_Column {
  /** column name */
  Assessment = 'assessment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  CriteriaHeading = 'criteria_heading',
  /** column name */
  HighConfidence = 'high_confidence',
  /** column name */
  Id = 'id',
  /** column name */
  VendorQuestionnaireAiReviewRunId = 'vendor_questionnaire_ai_review_run_id',
  /** column name */
  VendorQuestionnaireFormAnswerId = 'vendor_questionnaire_form_answer_id',
}

/** select "vendor_questionnaire_ai_review_assessments_aggregate_bool_exp_bool_and_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessments" */
export enum Vendor_Questionnaire_Ai_Review_Assessments_Select_Column_Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HighConfidence = 'high_confidence',
}

/** select "vendor_questionnaire_ai_review_assessments_aggregate_bool_exp_bool_or_arguments_columns" columns of table "vendor_questionnaire_ai_review_assessments" */
export enum Vendor_Questionnaire_Ai_Review_Assessments_Select_Column_Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HighConfidence = 'high_confidence',
}

/** input type for updating data in table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Set_Input = {
  assessment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  criteria_heading?: InputMaybe<Scalars['String']['input']>;
  high_confidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Ai_Review_Assessments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Ai_Review_Assessments_Stream_Cursor_Value_Input = {
  assessment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  criteria_heading?: InputMaybe<Scalars['String']['input']>;
  high_confidence?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaire_ai_review_assessments" */
export enum Vendor_Questionnaire_Ai_Review_Assessments_Update_Column {
  /** column name */
  Assessment = 'assessment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  CriteriaHeading = 'criteria_heading',
  /** column name */
  HighConfidence = 'high_confidence',
  /** column name */
  Id = 'id',
  /** column name */
  VendorQuestionnaireAiReviewRunId = 'vendor_questionnaire_ai_review_run_id',
  /** column name */
  VendorQuestionnaireFormAnswerId = 'vendor_questionnaire_form_answer_id',
}

export type Vendor_Questionnaire_Ai_Review_Assessments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp;
};

/** Status that a Vendor Questionnaire AI Review Run can take */
export type Vendor_Questionnaire_Ai_Review_Run_Status = {
  __typename?: 'vendor_questionnaire_ai_review_run_status';
  value: Scalars['String']['output'];
  /** An array relationship */
  vendor_questionnaire_ai_review_runs: Array<Vendor_Questionnaire_Ai_Review_Runs>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_runs_aggregate: Vendor_Questionnaire_Ai_Review_Runs_Aggregate;
};

/** Status that a Vendor Questionnaire AI Review Run can take */
export type Vendor_Questionnaire_Ai_Review_Run_StatusVendor_Questionnaire_Ai_Review_RunsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

/** Status that a Vendor Questionnaire AI Review Run can take */
export type Vendor_Questionnaire_Ai_Review_Run_StatusVendor_Questionnaire_Ai_Review_Runs_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Order_By>>;
    where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
  };

/** aggregated selection of "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Aggregate = {
  __typename?: 'vendor_questionnaire_ai_review_run_status_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Ai_Review_Run_Status>;
};

/** aggregate fields of "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_run_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Ai_Review_Run_Status_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_ai_review_run_status". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
  vendor_questionnaire_ai_review_runs?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
  vendor_questionnaire_ai_review_runs_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_ai_review_run_status" */
export enum Vendor_Questionnaire_Ai_Review_Run_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  VendorQuestionnaireAiReviewRunStatusPkey = 'vendor_questionnaire_ai_review_run_status_pkey',
}

export enum Vendor_Questionnaire_Ai_Review_Run_Status_Enum {
  Failed = 'Failed',
  Pending = 'Pending',
  Processing = 'Processing',
  Succeeded = 'Succeeded',
}

/** Boolean expression to compare columns of type "vendor_questionnaire_ai_review_run_status_enum". All fields are combined with logical 'AND'. */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Enum>;
  _in?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Enum>;
  _nin?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Run_Status_Enum>>;
};

/** input type for inserting data into table "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
  vendor_questionnaire_ai_review_runs?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Max_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_run_status_max_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Min_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_run_status_min_fields';
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Mutation_Response = {
  __typename?: 'vendor_questionnaire_ai_review_run_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Ai_Review_Run_Status>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Ai_Review_Run_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_On_Conflict = {
  constraint: Vendor_Questionnaire_Ai_Review_Run_Status_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Ai_Review_Run_Status_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_ai_review_run_status". */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Order_By = {
  value?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_runs_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_ai_review_run_status */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "vendor_questionnaire_ai_review_run_status" */
export enum Vendor_Questionnaire_Ai_Review_Run_Status_Select_Column {
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_ai_review_run_status" */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Ai_Review_Run_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Ai_Review_Run_Status_Stream_Cursor_Value_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "vendor_questionnaire_ai_review_run_status" */
export enum Vendor_Questionnaire_Ai_Review_Run_Status_Update_Column {
  /** column name */
  Value = 'value',
}

export type Vendor_Questionnaire_Ai_Review_Run_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp;
};

/** Set of executions of AI review for Vendor Questionnaires */
export type Vendor_Questionnaire_Ai_Review_Runs = {
  __typename?: 'vendor_questionnaire_ai_review_runs';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  status: Vendor_Questionnaire_Ai_Review_Run_Status_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_questionnaire: Vendor_Questionnaires;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessments: Array<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_assessments_aggregate: Vendor_Questionnaire_Ai_Review_Assessments_Aggregate;
  /** An object relationship */
  vendor_questionnaire_ai_review_run_status: Vendor_Questionnaire_Ai_Review_Run_Status;
  vendor_questionnaire_id: Scalars['uuid']['output'];
};

/** Set of executions of AI review for Vendor Questionnaires */
export type Vendor_Questionnaire_Ai_Review_RunsVendor_Questionnaire_Ai_Review_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

/** Set of executions of AI review for Vendor Questionnaires */
export type Vendor_Questionnaire_Ai_Review_RunsVendor_Questionnaire_Ai_Review_Assessments_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
    where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  };

/** aggregated selection of "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Aggregate = {
  __typename?: 'vendor_questionnaire_ai_review_runs_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Ai_Review_Runs>;
};

export type Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_runs_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Ai_Review_Runs_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Ai_Review_Runs_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaire_Ai_Review_Runs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_ai_review_runs". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_ai_review_assessments?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  vendor_questionnaire_ai_review_assessments_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp>;
  vendor_questionnaire_ai_review_run_status?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Bool_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_ai_review_runs" */
export enum Vendor_Questionnaire_Ai_Review_Runs_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireAiReviewRunsPkey = 'vendor_questionnaire_ai_review_runs_pkey',
}

/** input type for inserting data into table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Obj_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_assessments?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Arr_Rel_Insert_Input>;
  vendor_questionnaire_ai_review_run_status?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Obj_Rel_Insert_Input>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Ai_Review_Runs_Max_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_runs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Ai_Review_Runs_Min_Fields = {
  __typename?: 'vendor_questionnaire_ai_review_runs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Mutation_Response = {
  __typename?: 'vendor_questionnaire_ai_review_runs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Ai_Review_Runs>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Ai_Review_Runs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_On_Conflict = {
  constraint: Vendor_Questionnaire_Ai_Review_Runs_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Ai_Review_Runs_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_ai_review_runs". */
export type Vendor_Questionnaire_Ai_Review_Runs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_ai_review_assessments_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Order_By>;
  vendor_questionnaire_ai_review_run_status?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_ai_review_runs */
export type Vendor_Questionnaire_Ai_Review_Runs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaire_ai_review_runs" */
export enum Vendor_Questionnaire_Ai_Review_Runs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** input type for updating data in table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_ai_review_runs" */
export type Vendor_Questionnaire_Ai_Review_Runs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Ai_Review_Runs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Ai_Review_Runs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Ai_Review_Run_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaire_ai_review_runs" */
export enum Vendor_Questionnaire_Ai_Review_Runs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

export type Vendor_Questionnaire_Ai_Review_Runs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses = {
  __typename?: 'vendor_questionnaire_form_answer_statuses';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
  /** An aggregate relationship */
  vendor_questionnaire_form_answers_aggregate: Vendor_Questionnaire_Form_Answers_Aggregate;
};

/** columns and relationships of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_StatusesVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_StatusesVendor_Questionnaire_Form_Answers_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
    where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  };

/** aggregated selection of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Aggregate = {
  __typename?: 'vendor_questionnaire_form_answer_statuses_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
};

/** aggregate fields of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_form_answer_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_answer_statuses". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  vendor_questionnaire_form_answers?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  vendor_questionnaire_form_answers_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_form_answer_statuses" */
export enum Vendor_Questionnaire_Form_Answer_Statuses_Constraint {
  /** unique or primary key constraint on columns "value" */
  VendorQuestionnaireFormAnswerStatusesPkey = 'vendor_questionnaire_form_answer_statuses_pkey',
}

export enum Vendor_Questionnaire_Form_Answer_Statuses_Enum {
  /** An answer which has been marked as completed */
  Completed = 'completed',
  /** An answer which may have data but not marked as completed yet */
  Pending = 'pending',
}

/** Boolean expression to compare columns of type "vendor_questionnaire_form_answer_statuses_enum". All fields are combined with logical 'AND'. */
export type Vendor_Questionnaire_Form_Answer_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  _in?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  _nin?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Enum>>;
};

/** input type for inserting data into table "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  vendor_questionnaire_form_answers?: InputMaybe<Vendor_Questionnaire_Form_Answers_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Form_Answer_Statuses_Max_Fields = {
  __typename?: 'vendor_questionnaire_form_answer_statuses_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Form_Answer_Statuses_Min_Fields = {
  __typename?: 'vendor_questionnaire_form_answer_statuses_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Mutation_Response = {
  __typename?: 'vendor_questionnaire_form_answer_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Form_Answer_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_On_Conflict = {
  constraint: Vendor_Questionnaire_Form_Answer_Statuses_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Form_Answer_Statuses_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_answer_statuses". */
export type Vendor_Questionnaire_Form_Answer_Statuses_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answers_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_form_answer_statuses */
export type Vendor_Questionnaire_Form_Answer_Statuses_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "vendor_questionnaire_form_answer_statuses" */
export enum Vendor_Questionnaire_Form_Answer_Statuses_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "vendor_questionnaire_form_answer_statuses" */
export enum Vendor_Questionnaire_Form_Answer_Statuses_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Vendor_Questionnaire_Form_Answer_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers = {
  __typename?: 'vendor_questionnaire_form_answers';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  field_name: Scalars['String']['output'];
  /** An object relationship */
  form: Vendor_Questionnaire_Forms;
  /** An object relationship */
  form_answer_status: Vendor_Questionnaire_Form_Answer_Statuses;
  /** An array relationship */
  form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** An aggregate relationship */
  form_uploads_aggregate: Vendor_Questionnaire_Form_Uploads_Aggregate;
  id: Scalars['uuid']['output'];
  status: Vendor_Questionnaire_Form_Answer_Statuses_Enum;
  updated_at: Scalars['timestamptz']['output'];
  value?: Maybe<Scalars['VendorQuestionnaireFormAnswerValue']['output']>;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessments: Array<Vendor_Questionnaire_Ai_Review_Assessments>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_assessments_aggregate: Vendor_Questionnaire_Ai_Review_Assessments_Aggregate;
  vendor_questionnaire_form_id: Scalars['uuid']['output'];
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersForm_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersForm_Uploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersVendor_Questionnaire_Ai_Review_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersVendor_Questionnaire_Ai_Review_Assessments_AggregateArgs =
  {
    distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
    limit?: InputMaybe<Scalars['Int']['input']>;
    offset?: InputMaybe<Scalars['Int']['input']>;
    order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
    where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  };

/** aggregated selection of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Aggregate = {
  __typename?: 'vendor_questionnaire_form_answers_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Form_Answers_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Form_Answers>;
};

export type Vendor_Questionnaire_Form_Answers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Questionnaire_Form_Answers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_form_answers_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Form_Answers_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Form_Answers_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Form_Answers_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Form_Answers_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vendor_Questionnaire_Form_Answers_Append_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaire_Form_Answers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_answers". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_name?: InputMaybe<String_Comparison_Exp>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  form_answer_status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
  form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  form_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
  vendor_questionnaire_ai_review_assessments?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  vendor_questionnaire_ai_review_assessments_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Bool_Exp>;
  vendor_questionnaire_form_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_form_answers" */
export enum Vendor_Questionnaire_Form_Answers_Constraint {
  /** unique or primary key constraint on columns "field_name", "vendor_questionnaire_form_id" */
  VendorQuestionnaireFormAnswersFieldNameVendorQuestionnai = 'vendor_questionnaire_form_answers_field_name_vendor_questionnai',
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireFormAnswersPkey = 'vendor_questionnaire_form_answers_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vendor_Questionnaire_Form_Answers_Delete_At_Path_Input = {
  value?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vendor_Questionnaire_Form_Answers_Delete_Elem_Input = {
  value?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vendor_Questionnaire_Form_Answers_Delete_Key_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_name?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Obj_Rel_Insert_Input>;
  form_answer_status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Obj_Rel_Insert_Input>;
  form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  vendor_questionnaire_ai_review_assessments?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Arr_Rel_Insert_Input>;
  vendor_questionnaire_form_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Form_Answers_Max_Fields = {
  __typename?: 'vendor_questionnaire_form_answers_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_form_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Form_Answers_Min_Fields = {
  __typename?: 'vendor_questionnaire_form_answers_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  field_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_form_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Mutation_Response = {
  __typename?: 'vendor_questionnaire_form_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Form_Answers>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Form_Answers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Answers_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_On_Conflict = {
  constraint: Vendor_Questionnaire_Form_Answers_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Form_Answers_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_answers". */
export type Vendor_Questionnaire_Form_Answers_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Order_By>;
  form_answer_status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>;
  form_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessments_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_form_answers */
export type Vendor_Questionnaire_Form_Answers_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vendor_Questionnaire_Form_Answers_Prepend_Input = {
  value?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "vendor_questionnaire_form_answers" */
export enum Vendor_Questionnaire_Form_Answers_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldName = 'field_name',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  VendorQuestionnaireFormId = 'vendor_questionnaire_form_id',
}

/** input type for updating data in table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  vendor_questionnaire_form_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Answers_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  vendor_questionnaire_form_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaire_form_answers" */
export enum Vendor_Questionnaire_Form_Answers_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldName = 'field_name',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  VendorQuestionnaireFormId = 'vendor_questionnaire_form_id',
}

export type Vendor_Questionnaire_Form_Answers_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Vendor_Questionnaire_Form_Answers_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Vendor_Questionnaire_Form_Answers_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Vendor_Questionnaire_Form_Answers_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Form_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Form_Answers_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads = {
  __typename?: 'vendor_questionnaire_form_uploads';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  /** An object relationship */
  form_answer: Vendor_Questionnaire_Form_Answers;
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  vendor_questionnaire_form_answer_id: Scalars['uuid']['output'];
};

/** aggregated selection of "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Aggregate = {
  __typename?: 'vendor_questionnaire_form_uploads_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Form_Uploads>;
};

export type Vendor_Questionnaire_Form_Uploads_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Questionnaire_Form_Uploads_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_form_uploads_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Form_Uploads_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Form_Uploads_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaire_Form_Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Form_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_uploads". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_form_uploads" */
export enum Vendor_Questionnaire_Form_Uploads_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireFormUploadsPkey = 'vendor_questionnaire_form_uploads_pkey',
}

/** input type for inserting data into table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Form_Uploads_Max_Fields = {
  __typename?: 'vendor_questionnaire_form_uploads_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_form_answer_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Form_Uploads_Min_Fields = {
  __typename?: 'vendor_questionnaire_form_uploads_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_form_answer_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Mutation_Response = {
  __typename?: 'vendor_questionnaire_form_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Form_Uploads>;
};

/** on_conflict condition type for table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_On_Conflict = {
  constraint: Vendor_Questionnaire_Form_Uploads_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Form_Uploads_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_uploads". */
export type Vendor_Questionnaire_Form_Uploads_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_form_uploads */
export type Vendor_Questionnaire_Form_Uploads_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaire_form_uploads" */
export enum Vendor_Questionnaire_Form_Uploads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireFormAnswerId = 'vendor_questionnaire_form_answer_id',
}

/** input type for updating data in table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaire_form_uploads" */
export enum Vendor_Questionnaire_Form_Uploads_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireFormAnswerId = 'vendor_questionnaire_form_answer_id',
}

export type Vendor_Questionnaire_Form_Uploads_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Form_Uploads_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms = {
  __typename?: 'vendor_questionnaire_forms';
  /** An array relationship */
  answers: Array<Vendor_Questionnaire_Form_Answers>;
  /** An aggregate relationship */
  answers_aggregate: Vendor_Questionnaire_Form_Answers_Aggregate;
  config_snapshot?: Maybe<Scalars['FormConfig']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_questionnaire: Vendor_Questionnaires;
  vendor_questionnaire_id: Scalars['uuid']['output'];
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_FormsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_FormsAnswers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_FormsConfig_SnapshotArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Aggregate = {
  __typename?: 'vendor_questionnaire_forms_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Forms_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Forms>;
};

/** aggregate fields of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_forms_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Forms_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Forms_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vendor_Questionnaire_Forms_Append_Input = {
  config_snapshot?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_forms". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Forms_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Forms_Bool_Exp>>;
  answers?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  answers_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Bool_Exp>;
  config_snapshot?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_forms" */
export enum Vendor_Questionnaire_Forms_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireFormsPkey = 'vendor_questionnaire_forms_pkey',
  /** unique or primary key constraint on columns "vendor_questionnaire_id" */
  VendorQuestionnaireFormsVendorQuestionnaireIdKey = 'vendor_questionnaire_forms_vendor_questionnaire_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vendor_Questionnaire_Forms_Delete_At_Path_Input = {
  config_snapshot?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vendor_Questionnaire_Forms_Delete_Elem_Input = {
  config_snapshot?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vendor_Questionnaire_Forms_Delete_Key_Input = {
  config_snapshot?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Insert_Input = {
  answers?: InputMaybe<Vendor_Questionnaire_Form_Answers_Arr_Rel_Insert_Input>;
  config_snapshot?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Obj_Rel_Insert_Input>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Forms_Max_Fields = {
  __typename?: 'vendor_questionnaire_forms_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Forms_Min_Fields = {
  __typename?: 'vendor_questionnaire_forms_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Mutation_Response = {
  __typename?: 'vendor_questionnaire_forms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Forms>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Forms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Forms_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_On_Conflict = {
  constraint: Vendor_Questionnaire_Forms_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Forms_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_forms". */
export type Vendor_Questionnaire_Forms_Order_By = {
  answers_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Order_By>;
  config_snapshot?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_forms */
export type Vendor_Questionnaire_Forms_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vendor_Questionnaire_Forms_Prepend_Input = {
  config_snapshot?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "vendor_questionnaire_forms" */
export enum Vendor_Questionnaire_Forms_Select_Column {
  /** column name */
  ConfigSnapshot = 'config_snapshot',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** input type for updating data in table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Set_Input = {
  config_snapshot?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Forms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Forms_Stream_Cursor_Value_Input = {
  config_snapshot?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaire_forms" */
export enum Vendor_Questionnaire_Forms_Update_Column {
  /** column name */
  ConfigSnapshot = 'config_snapshot',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

export type Vendor_Questionnaire_Forms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Vendor_Questionnaire_Forms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Vendor_Questionnaire_Forms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Vendor_Questionnaire_Forms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Vendor_Questionnaire_Forms_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Vendor_Questionnaire_Forms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Forms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Forms_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses = {
  __typename?: 'vendor_questionnaire_statuses';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
  /** An aggregate relationship */
  vendor_questionnaires_aggregate: Vendor_Questionnaires_Aggregate;
};

/** columns and relationships of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_StatusesVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_StatusesVendor_Questionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** aggregated selection of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Aggregate = {
  __typename?: 'vendor_questionnaire_statuses_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Statuses_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Statuses>;
};

/** aggregate fields of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_statuses_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Statuses_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Statuses_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_statuses". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  vendor_questionnaires?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_statuses" */
export enum Vendor_Questionnaire_Statuses_Constraint {
  /** unique or primary key constraint on columns "value" */
  VendorQuestionnaireStatusesPkey = 'vendor_questionnaire_statuses_pkey',
}

export enum Vendor_Questionnaire_Statuses_Enum {
  /** The questionnaire has been created for a vendor and awaiting to be sent */
  Created = 'created',
  /** The questionnaire is due to expire */
  Expiring = 'expiring',
  /** The questionnaire is overdue and not submitted */
  Overdue = 'overdue',
  /** The questionnaire has been submitted to a vendor and awaiting their answer */
  Pending = 'pending',
  /** pending questionnaire are revoked when vendor is terminated or retired */
  Revoked = 'revoked',
  /** The questionnaire answer has been submitted by a vendor */
  Submitted = 'submitted',
}

/** Boolean expression to compare columns of type "vendor_questionnaire_statuses_enum". All fields are combined with logical 'AND'. */
export type Vendor_Questionnaire_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  _in?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  _nin?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Enum>>;
};

/** input type for inserting data into table "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
  vendor_questionnaires?: InputMaybe<Vendor_Questionnaires_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Statuses_Max_Fields = {
  __typename?: 'vendor_questionnaire_statuses_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Statuses_Min_Fields = {
  __typename?: 'vendor_questionnaire_statuses_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Mutation_Response = {
  __typename?: 'vendor_questionnaire_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Statuses>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_On_Conflict = {
  constraint: Vendor_Questionnaire_Statuses_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Statuses_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_statuses". */
export type Vendor_Questionnaire_Statuses_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_statuses */
export type Vendor_Questionnaire_Statuses_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "vendor_questionnaire_statuses" */
export enum Vendor_Questionnaire_Statuses_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Statuses_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "vendor_questionnaire_statuses" */
export enum Vendor_Questionnaire_Statuses_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Vendor_Questionnaire_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Statuses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Statuses_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads = {
  __typename?: 'vendor_questionnaire_uploads';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_questionnaire: Vendor_Questionnaires;
  vendor_questionnaire_id: Scalars['uuid']['output'];
  /** An array relationship */
  vendor_questionnaires_answers: Array<Vendor_Questionnaires>;
  /** An aggregate relationship */
  vendor_questionnaires_answers_aggregate: Vendor_Questionnaires_Aggregate;
};

/** columns and relationships of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_UploadsVendor_Questionnaires_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_UploadsVendor_Questionnaires_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** aggregated selection of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Aggregate = {
  __typename?: 'vendor_questionnaire_uploads_aggregate';
  aggregate?: Maybe<Vendor_Questionnaire_Uploads_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaire_Uploads>;
};

export type Vendor_Questionnaire_Uploads_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Questionnaire_Uploads_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Aggregate_Fields = {
  __typename?: 'vendor_questionnaire_uploads_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaire_Uploads_Max_Fields>;
  min?: Maybe<Vendor_Questionnaire_Uploads_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Uploads_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaire_Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_uploads". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaires_answers?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaires_answers_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_uploads" */
export enum Vendor_Questionnaire_Uploads_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  VendorQuestionnaireUploadsFileIdKey = 'vendor_questionnaire_uploads_file_id_key',
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireUploadsPkey = 'vendor_questionnaire_uploads_pkey',
}

/** input type for inserting data into table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Obj_Rel_Insert_Input>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaires_answers?: InputMaybe<Vendor_Questionnaires_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vendor_Questionnaire_Uploads_Max_Fields = {
  __typename?: 'vendor_questionnaire_uploads_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Questionnaire_Uploads_Min_Fields = {
  __typename?: 'vendor_questionnaire_uploads_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Mutation_Response = {
  __typename?: 'vendor_questionnaire_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Uploads>;
};

/** input type for inserting object relation for remote table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaire_Uploads_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaire_Uploads_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_On_Conflict = {
  constraint: Vendor_Questionnaire_Uploads_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Uploads_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_uploads". */
export type Vendor_Questionnaire_Uploads_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
  vendor_questionnaires_answers_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** primary key columns input for table: vendor_questionnaire_uploads */
export type Vendor_Questionnaire_Uploads_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaire_uploads" */
export enum Vendor_Questionnaire_Uploads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** input type for updating data in table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Uploads_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaire_uploads" */
export enum Vendor_Questionnaire_Uploads_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

export type Vendor_Questionnaire_Uploads_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaire_Uploads_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaire_Uploads_Bool_Exp;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_Questionnaires = {
  __typename?: 'vendor_questionnaires';
  /** An object relationship */
  answer_upload?: Maybe<Vendor_Questionnaire_Uploads>;
  answer_upload_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  custom_message?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  form?: Maybe<Vendor_Questionnaire_Forms>;
  id: Scalars['uuid']['output'];
  is_disabled: Scalars['Boolean']['output'];
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire_status: Vendor_Questionnaire_Statuses;
  /** An object relationship */
  sent_by_user: Users;
  sent_by_user_id: Scalars['uuid']['output'];
  sent_to_email: Scalars['String']['output'];
  /** An object relationship */
  sent_to_user?: Maybe<Users>;
  sent_to_user_id?: Maybe<Scalars['uuid']['output']>;
  status: Vendor_Questionnaire_Statuses_Enum;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  uploads: Array<Vendor_Questionnaire_Uploads>;
  /** An aggregate relationship */
  uploads_aggregate: Vendor_Questionnaire_Uploads_Aggregate;
  /** An object relationship */
  vendor: Vendors;
  /** An array relationship */
  vendor_findings: Array<Vendor_Findings>;
  /** An aggregate relationship */
  vendor_findings_aggregate: Vendor_Findings_Aggregate;
  vendor_id: Scalars['uuid']['output'];
  /** An array relationship */
  vendor_questionnaire_ai_review_runs: Array<Vendor_Questionnaire_Ai_Review_Runs>;
  /** An aggregate relationship */
  vendor_questionnaire_ai_review_runs_aggregate: Vendor_Questionnaire_Ai_Review_Runs_Aggregate;
  /** An array relationship */
  vendor_questionnaires_notifications: Array<Notifications>;
  /** An aggregate relationship */
  vendor_questionnaires_notifications_aggregate: Notifications_Aggregate;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesUploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesUploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesVendor_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesVendor_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesVendor_Questionnaire_Ai_Review_RunsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesVendor_Questionnaire_Ai_Review_Runs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Runs_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesVendor_Questionnaires_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesVendor_Questionnaires_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** aggregated selection of "vendor_questionnaires" */
export type Vendor_Questionnaires_Aggregate = {
  __typename?: 'vendor_questionnaires_aggregate';
  aggregate?: Maybe<Vendor_Questionnaires_Aggregate_Fields>;
  nodes: Array<Vendor_Questionnaires>;
};

export type Vendor_Questionnaires_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Questionnaires_Aggregate_Bool_Exp_Bool_And = {
  arguments: Vendor_Questionnaires_Select_Column_Vendor_Questionnaires_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vendor_Questionnaires_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Vendor_Questionnaires_Select_Column_Vendor_Questionnaires_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vendor_Questionnaires_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_questionnaires" */
export type Vendor_Questionnaires_Aggregate_Fields = {
  __typename?: 'vendor_questionnaires_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Questionnaires_Max_Fields>;
  min?: Maybe<Vendor_Questionnaires_Min_Fields>;
};

/** aggregate fields of "vendor_questionnaires" */
export type Vendor_Questionnaires_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaires_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaires_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_questionnaires" */
export type Vendor_Questionnaires_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Questionnaires_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaires_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaires". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaires_Bool_Exp>>;
  answer_upload?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  answer_upload_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_message?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_disabled?: InputMaybe<Boolean_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire_status?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
  sent_by_user?: InputMaybe<Users_Bool_Exp>;
  sent_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  sent_to_email?: InputMaybe<String_Comparison_Exp>;
  sent_to_user?: InputMaybe<Users_Bool_Exp>;
  sent_to_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Bool_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_findings?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendor_findings_aggregate?: InputMaybe<Vendor_Findings_Aggregate_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire_ai_review_runs?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Bool_Exp>;
  vendor_questionnaire_ai_review_runs_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Bool_Exp>;
  vendor_questionnaires_notifications?: InputMaybe<Notifications_Bool_Exp>;
  vendor_questionnaires_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaires" */
export enum Vendor_Questionnaires_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnairesPkey = 'vendor_questionnaires_pkey',
}

/** input type for inserting data into table "vendor_questionnaires" */
export type Vendor_Questionnaires_Insert_Input = {
  answer_upload?: InputMaybe<Vendor_Questionnaire_Uploads_Obj_Rel_Insert_Input>;
  answer_upload_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_message?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire_status?: InputMaybe<Vendor_Questionnaire_Statuses_Obj_Rel_Insert_Input>;
  sent_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  sent_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_to_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  sent_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Arr_Rel_Insert_Input>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_findings?: InputMaybe<Vendor_Findings_Arr_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_ai_review_runs?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Arr_Rel_Insert_Input>;
  vendor_questionnaires_notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vendor_Questionnaires_Max_Fields = {
  __typename?: 'vendor_questionnaires_max_fields';
  answer_upload_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  custom_message?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  questionnaire_id?: Maybe<Scalars['uuid']['output']>;
  sent_by_user_id?: Maybe<Scalars['uuid']['output']>;
  sent_to_email?: Maybe<Scalars['String']['output']>;
  sent_to_user_id?: Maybe<Scalars['uuid']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Max_Order_By = {
  answer_upload_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Questionnaires_Min_Fields = {
  __typename?: 'vendor_questionnaires_min_fields';
  answer_upload_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  custom_message?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  questionnaire_id?: Maybe<Scalars['uuid']['output']>;
  sent_by_user_id?: Maybe<Scalars['uuid']['output']>;
  sent_to_email?: Maybe<Scalars['String']['output']>;
  sent_to_user_id?: Maybe<Scalars['uuid']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Min_Order_By = {
  answer_upload_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaires" */
export type Vendor_Questionnaires_Mutation_Response = {
  __typename?: 'vendor_questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaires>;
};

/** input type for inserting object relation for remote table "vendor_questionnaires" */
export type Vendor_Questionnaires_Obj_Rel_Insert_Input = {
  data: Vendor_Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "vendor_questionnaires" */
export type Vendor_Questionnaires_On_Conflict = {
  constraint: Vendor_Questionnaires_Constraint;
  update_columns?: Array<Vendor_Questionnaires_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaires". */
export type Vendor_Questionnaires_Order_By = {
  answer_upload?: InputMaybe<Vendor_Questionnaire_Uploads_Order_By>;
  answer_upload_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Order_By>;
  id?: InputMaybe<Order_By>;
  is_disabled?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  questionnaire_status?: InputMaybe<Vendor_Questionnaire_Statuses_Order_By>;
  sent_by_user?: InputMaybe<Users_Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user?: InputMaybe<Users_Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_findings_aggregate?: InputMaybe<Vendor_Findings_Aggregate_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_runs_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Runs_Aggregate_Order_By>;
  vendor_questionnaires_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
};

/** primary key columns input for table: vendor_questionnaires */
export type Vendor_Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaires" */
export enum Vendor_Questionnaires_Select_Column {
  /** column name */
  AnswerUploadId = 'answer_upload_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomMessage = 'custom_message',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsDisabled = 'is_disabled',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  SentByUserId = 'sent_by_user_id',
  /** column name */
  SentToEmail = 'sent_to_email',
  /** column name */
  SentToUserId = 'sent_to_user_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
}

/** select "vendor_questionnaires_aggregate_bool_exp_bool_and_arguments_columns" columns of table "vendor_questionnaires" */
export enum Vendor_Questionnaires_Select_Column_Vendor_Questionnaires_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDisabled = 'is_disabled',
}

/** select "vendor_questionnaires_aggregate_bool_exp_bool_or_arguments_columns" columns of table "vendor_questionnaires" */
export enum Vendor_Questionnaires_Select_Column_Vendor_Questionnaires_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDisabled = 'is_disabled',
}

/** input type for updating data in table "vendor_questionnaires" */
export type Vendor_Questionnaires_Set_Input = {
  answer_upload_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_message?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_questionnaires" */
export type Vendor_Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaires_Stream_Cursor_Value_Input = {
  answer_upload_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_message?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_questionnaires" */
export enum Vendor_Questionnaires_Update_Column {
  /** column name */
  AnswerUploadId = 'answer_upload_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomMessage = 'custom_message',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsDisabled = 'is_disabled',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  SentByUserId = 'sent_by_user_id',
  /** column name */
  SentToEmail = 'sent_to_email',
  /** column name */
  SentToUserId = 'sent_to_user_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
}

export type Vendor_Questionnaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaires_Bool_Exp;
};

/** columns and relationships of "vendor_risks" */
export type Vendor_Risks = {
  __typename?: 'vendor_risks';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendor_id: Scalars['uuid']['output'];
};

/** aggregated selection of "vendor_risks" */
export type Vendor_Risks_Aggregate = {
  __typename?: 'vendor_risks_aggregate';
  aggregate?: Maybe<Vendor_Risks_Aggregate_Fields>;
  nodes: Array<Vendor_Risks>;
};

export type Vendor_Risks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vendor_Risks_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Risks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Risks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_risks" */
export type Vendor_Risks_Aggregate_Fields = {
  __typename?: 'vendor_risks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Risks_Max_Fields>;
  min?: Maybe<Vendor_Risks_Min_Fields>;
};

/** aggregate fields of "vendor_risks" */
export type Vendor_Risks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_risks" */
export type Vendor_Risks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Risks_Max_Order_By>;
  min?: InputMaybe<Vendor_Risks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_risks" */
export type Vendor_Risks_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Risks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Risks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_risks". All fields are combined with a logical 'AND'. */
export type Vendor_Risks_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Risks_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Risks_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Risks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_risks" */
export enum Vendor_Risks_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorRisksPkey = 'vendor_risks_pkey',
  /** unique or primary key constraint on columns "vendor_id", "risk_id" */
  VendorRisksVendorIdRiskIdKey = 'vendor_risks_vendor_id_risk_id_key',
}

/** input type for inserting data into table "vendor_risks" */
export type Vendor_Risks_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Risks_Max_Fields = {
  __typename?: 'vendor_risks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_risks" */
export type Vendor_Risks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Risks_Min_Fields = {
  __typename?: 'vendor_risks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_risks" */
export type Vendor_Risks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_risks" */
export type Vendor_Risks_Mutation_Response = {
  __typename?: 'vendor_risks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Risks>;
};

/** on_conflict condition type for table "vendor_risks" */
export type Vendor_Risks_On_Conflict = {
  constraint: Vendor_Risks_Constraint;
  update_columns?: Array<Vendor_Risks_Update_Column>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_risks". */
export type Vendor_Risks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_risks */
export type Vendor_Risks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_risks" */
export enum Vendor_Risks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "vendor_risks" */
export type Vendor_Risks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_risks" */
export type Vendor_Risks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Risks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Risks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_risks" */
export enum Vendor_Risks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
}

export type Vendor_Risks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Risks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Risks_Bool_Exp;
};

/** Stores list of tasks linked with vendors */
export type Vendor_Tasks = {
  __typename?: 'vendor_tasks';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendor_id: Scalars['uuid']['output'];
};

/** aggregated selection of "vendor_tasks" */
export type Vendor_Tasks_Aggregate = {
  __typename?: 'vendor_tasks_aggregate';
  aggregate?: Maybe<Vendor_Tasks_Aggregate_Fields>;
  nodes: Array<Vendor_Tasks>;
};

export type Vendor_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vendor_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Vendor_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendor_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vendor_tasks" */
export type Vendor_Tasks_Aggregate_Fields = {
  __typename?: 'vendor_tasks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Vendor_Tasks_Max_Fields>;
  min?: Maybe<Vendor_Tasks_Min_Fields>;
};

/** aggregate fields of "vendor_tasks" */
export type Vendor_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendor_tasks" */
export type Vendor_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Tasks_Max_Order_By>;
  min?: InputMaybe<Vendor_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_tasks" */
export type Vendor_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_tasks". All fields are combined with a logical 'AND'. */
export type Vendor_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Tasks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_tasks" */
export enum Vendor_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorTasksPkey = 'vendor_tasks_pkey',
  /** unique or primary key constraint on columns "vendor_id", "task_id" */
  VendorTasksVendorIdTaskIdKey = 'vendor_tasks_vendor_id_task_id_key',
}

/** input type for inserting data into table "vendor_tasks" */
export type Vendor_Tasks_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Vendor_Tasks_Max_Fields = {
  __typename?: 'vendor_tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "vendor_tasks" */
export type Vendor_Tasks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendor_Tasks_Min_Fields = {
  __typename?: 'vendor_tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "vendor_tasks" */
export type Vendor_Tasks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_tasks" */
export type Vendor_Tasks_Mutation_Response = {
  __typename?: 'vendor_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Tasks>;
};

/** on_conflict condition type for table "vendor_tasks" */
export type Vendor_Tasks_On_Conflict = {
  constraint: Vendor_Tasks_Constraint;
  update_columns?: Array<Vendor_Tasks_Update_Column>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_tasks". */
export type Vendor_Tasks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_tasks */
export type Vendor_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_tasks" */
export enum Vendor_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "vendor_tasks" */
export type Vendor_Tasks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "vendor_tasks" */
export type Vendor_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Tasks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "vendor_tasks" */
export enum Vendor_Tasks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  VendorId = 'vendor_id',
}

export type Vendor_Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Tasks_Bool_Exp;
};

/** columns and relationships of "vendors" */
export type Vendors = {
  __typename?: 'vendors';
  approval_status?: Maybe<Vendor_Approval_Statuses_Enum>;
  assessment_status: Scalars['VendorAssessmentStatus']['output'];
  contact_email?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<Vendor_Documents>;
  /** An aggregate relationship */
  documents_aggregate: Vendor_Documents_Aggregate;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An aggregate relationship */
  field_values_aggregate: Field_Values_Aggregate;
  /** An object relationship */
  frequency: Frequencies;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  questionnaires: Array<Vendor_Questionnaires>;
  /** An aggregate relationship */
  questionnaires_aggregate: Vendor_Questionnaires_Aggregate;
  review_frequency: Frequencies_Enum;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An aggregate relationship */
  reviews_aggregate: Reviews_Aggregate;
  /** An object relationship */
  risk_level?: Maybe<Organization_Vendor_Risk_Levels>;
  risk_level_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  tasks: Array<Vendor_Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Vendor_Tasks_Aggregate;
  terminated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  tier?: Maybe<Organization_Vendor_Tiers>;
  tier_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  vendor_findings: Array<Vendor_Findings>;
  /** An aggregate relationship */
  vendor_findings_aggregate: Vendor_Findings_Aggregate;
  /** An array relationship */
  vendor_risks: Array<Vendor_Risks>;
  /** An aggregate relationship */
  vendor_risks_aggregate: Vendor_Risks_Aggregate;
  /** An object relationship */
  vendor_status?: Maybe<Vendor_Approval_Statuses>;
  /** An array relationship */
  vendors_comments: Array<Comments>;
  /** An aggregate relationship */
  vendors_comments_aggregate: Comments_Aggregate;
  /** An array relationship */
  vendors_notifications: Array<Notifications>;
  /** An aggregate relationship */
  vendors_notifications_aggregate: Notifications_Aggregate;
  /** An object relationship */
  vendors_vector_store?: Maybe<Ai_Vendors_Vector_Store>;
};

/** columns and relationships of "vendors" */
export type VendorsDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Documents_Order_By>>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Documents_Order_By>>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsField_Values_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsQuestionnaires_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsReviews_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsTasksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendor_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendor_Findings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendor_RisksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendor_Risks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendors_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendors_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendors_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendors_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** aggregated selection of "vendors" */
export type Vendors_Aggregate = {
  __typename?: 'vendors_aggregate';
  aggregate?: Maybe<Vendors_Aggregate_Fields>;
  nodes: Array<Vendors>;
};

export type Vendors_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Vendors_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Vendors_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Vendors_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Vendors_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Vendors_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Vendors_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Vendors_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Vendors_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Vendors_Aggregate_Bool_Exp_Var_Samp>;
};

export type Vendors_Aggregate_Bool_Exp_Avg = {
  arguments: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendors_Aggregate_Bool_Exp_Corr = {
  arguments: Vendors_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendors_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Vendors_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vendors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Vendors_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Vendors_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendors_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Vendors_Aggregate_Bool_Exp_Max = {
  arguments: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendors_Aggregate_Bool_Exp_Min = {
  arguments: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendors_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendors_Aggregate_Bool_Exp_Sum = {
  arguments: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Vendors_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Vendors_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "vendors" */
export type Vendors_Aggregate_Fields = {
  __typename?: 'vendors_aggregate_fields';
  avg?: Maybe<Vendors_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Vendors_Max_Fields>;
  min?: Maybe<Vendors_Min_Fields>;
  stddev?: Maybe<Vendors_Stddev_Fields>;
  stddev_pop?: Maybe<Vendors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vendors_Stddev_Samp_Fields>;
  sum?: Maybe<Vendors_Sum_Fields>;
  var_pop?: Maybe<Vendors_Var_Pop_Fields>;
  var_samp?: Maybe<Vendors_Var_Samp_Fields>;
  variance?: Maybe<Vendors_Variance_Fields>;
};

/** aggregate fields of "vendors" */
export type Vendors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "vendors" */
export type Vendors_Aggregate_Order_By = {
  avg?: InputMaybe<Vendors_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendors_Max_Order_By>;
  min?: InputMaybe<Vendors_Min_Order_By>;
  stddev?: InputMaybe<Vendors_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vendors_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vendors_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vendors_Sum_Order_By>;
  var_pop?: InputMaybe<Vendors_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vendors_Var_Samp_Order_By>;
  variance?: InputMaybe<Vendors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vendors" */
export type Vendors_Arr_Rel_Insert_Input = {
  data: Array<Vendors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** aggregate avg on columns */
export type Vendors_Avg_Fields = {
  __typename?: 'vendors_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "vendors" */
export type Vendors_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vendors". All fields are combined with a logical 'AND'. */
export type Vendors_Bool_Exp = {
  _and?: InputMaybe<Array<Vendors_Bool_Exp>>;
  _not?: InputMaybe<Vendors_Bool_Exp>;
  _or?: InputMaybe<Array<Vendors_Bool_Exp>>;
  approval_status?: InputMaybe<Vendor_Approval_Statuses_Enum_Comparison_Exp>;
  assessment_status?: InputMaybe<String_Comparison_Exp>;
  contact_email?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Vendor_Documents_Bool_Exp>;
  documents_aggregate?: InputMaybe<Vendor_Documents_Aggregate_Bool_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Bool_Exp>;
  frequency?: InputMaybe<Frequencies_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaires?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Bool_Exp>;
  review_frequency?: InputMaybe<Frequencies_Enum_Comparison_Exp>;
  reviews?: InputMaybe<Reviews_Bool_Exp>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Bool_Exp>;
  risk_level?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
  risk_level_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  tasks?: InputMaybe<Vendor_Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Vendor_Tasks_Aggregate_Bool_Exp>;
  terminated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tier?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
  tier_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_findings?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendor_findings_aggregate?: InputMaybe<Vendor_Findings_Aggregate_Bool_Exp>;
  vendor_risks?: InputMaybe<Vendor_Risks_Bool_Exp>;
  vendor_risks_aggregate?: InputMaybe<Vendor_Risks_Aggregate_Bool_Exp>;
  vendor_status?: InputMaybe<Vendor_Approval_Statuses_Bool_Exp>;
  vendors_comments?: InputMaybe<Comments_Bool_Exp>;
  vendors_comments_aggregate?: InputMaybe<Comments_Aggregate_Bool_Exp>;
  vendors_notifications?: InputMaybe<Notifications_Bool_Exp>;
  vendors_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Bool_Exp>;
  vendors_vector_store?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

/** unique or primary key constraints on table "vendors" */
export enum Vendors_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorsPkey = 'vendors_pkey',
}

/** input type for incrementing numeric columns in table "vendors" */
export type Vendors_Inc_Input = {
  similarity?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "vendors" */
export type Vendors_Insert_Input = {
  approval_status?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  assessment_status?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Vendor_Documents_Arr_Rel_Insert_Input>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  frequency?: InputMaybe<Frequencies_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaires?: InputMaybe<Vendor_Questionnaires_Arr_Rel_Insert_Input>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  risk_level?: InputMaybe<Organization_Vendor_Risk_Levels_Obj_Rel_Insert_Input>;
  risk_level_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  tasks?: InputMaybe<Vendor_Tasks_Arr_Rel_Insert_Input>;
  terminated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tier?: InputMaybe<Organization_Vendor_Tiers_Obj_Rel_Insert_Input>;
  tier_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_findings?: InputMaybe<Vendor_Findings_Arr_Rel_Insert_Input>;
  vendor_risks?: InputMaybe<Vendor_Risks_Arr_Rel_Insert_Input>;
  vendor_status?: InputMaybe<Vendor_Approval_Statuses_Obj_Rel_Insert_Input>;
  vendors_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  vendors_notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  vendors_vector_store?: InputMaybe<Ai_Vendors_Vector_Store_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Vendors_Max_Fields = {
  __typename?: 'vendors_max_fields';
  assessment_status?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  risk_level_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  terminated_at?: Maybe<Scalars['timestamptz']['output']>;
  tier_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "vendors" */
export type Vendors_Max_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  risk_level_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  terminated_at?: InputMaybe<Order_By>;
  tier_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vendors_Min_Fields = {
  __typename?: 'vendors_min_fields';
  assessment_status?: Maybe<Scalars['String']['output']>;
  contact_email?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  risk_level_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  terminated_at?: Maybe<Scalars['timestamptz']['output']>;
  tier_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "vendors" */
export type Vendors_Min_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  risk_level_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  terminated_at?: InputMaybe<Order_By>;
  tier_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendors" */
export type Vendors_Mutation_Response = {
  __typename?: 'vendors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendors>;
};

/** input type for inserting object relation for remote table "vendors" */
export type Vendors_Obj_Rel_Insert_Input = {
  data: Vendors_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** on_conflict condition type for table "vendors" */
export type Vendors_On_Conflict = {
  constraint: Vendors_Constraint;
  update_columns?: Array<Vendors_Update_Column>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** Ordering options when selecting data from "vendors". */
export type Vendors_Order_By = {
  approval_status?: InputMaybe<Order_By>;
  assessment_status?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Vendor_Documents_Aggregate_Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  frequency?: InputMaybe<Frequencies_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
  review_frequency?: InputMaybe<Order_By>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  risk_level?: InputMaybe<Organization_Vendor_Risk_Levels_Order_By>;
  risk_level_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Vendor_Tasks_Aggregate_Order_By>;
  terminated_at?: InputMaybe<Order_By>;
  tier?: InputMaybe<Organization_Vendor_Tiers_Order_By>;
  tier_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_findings_aggregate?: InputMaybe<Vendor_Findings_Aggregate_Order_By>;
  vendor_risks_aggregate?: InputMaybe<Vendor_Risks_Aggregate_Order_By>;
  vendor_status?: InputMaybe<Vendor_Approval_Statuses_Order_By>;
  vendors_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  vendors_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  vendors_vector_store?: InputMaybe<Ai_Vendors_Vector_Store_Order_By>;
};

/** primary key columns input for table: vendors */
export type Vendors_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendors" */
export enum Vendors_Select_Column {
  /** column name */
  ApprovalStatus = 'approval_status',
  /** column name */
  AssessmentStatus = 'assessment_status',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ReviewFrequency = 'review_frequency',
  /** column name */
  RiskLevelId = 'risk_level_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TerminatedAt = 'terminated_at',
  /** column name */
  TierId = 'tier_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "vendors_aggregate_bool_exp_avg_arguments_columns" columns of table "vendors" */
export enum Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendors_aggregate_bool_exp_corr_arguments_columns" columns of table "vendors" */
export enum Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendors_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "vendors" */
export enum Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendors_aggregate_bool_exp_max_arguments_columns" columns of table "vendors" */
export enum Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendors_aggregate_bool_exp_min_arguments_columns" columns of table "vendors" */
export enum Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendors_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "vendors" */
export enum Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendors_aggregate_bool_exp_sum_arguments_columns" columns of table "vendors" */
export enum Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "vendors_aggregate_bool_exp_var_samp_arguments_columns" columns of table "vendors" */
export enum Vendors_Select_Column_Vendors_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "vendors" */
export type Vendors_Set_Input = {
  approval_status?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  assessment_status?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  risk_level_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  terminated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tier_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Vendors_Stddev_Fields = {
  __typename?: 'vendors_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "vendors" */
export type Vendors_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Vendors_Stddev_Pop_Fields = {
  __typename?: 'vendors_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "vendors" */
export type Vendors_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Vendors_Stddev_Samp_Fields = {
  __typename?: 'vendors_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "vendors" */
export type Vendors_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "vendors" */
export type Vendors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendors_Stream_Cursor_Value_Input = {
  approval_status?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  assessment_status?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  risk_level_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  terminated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tier_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Vendors_Sum_Fields = {
  __typename?: 'vendors_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "vendors" */
export type Vendors_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "vendors_summary_view" */
export type Vendors_Summary_View = {
  __typename?: 'vendors_summary_view';
  accepted_count?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  organisation?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['Int']['output']>;
  rejected_count?: Maybe<Scalars['Int']['output']>;
};

/** aggregated selection of "vendors_summary_view" */
export type Vendors_Summary_View_Aggregate = {
  __typename?: 'vendors_summary_view_aggregate';
  aggregate?: Maybe<Vendors_Summary_View_Aggregate_Fields>;
  nodes: Array<Vendors_Summary_View>;
};

/** aggregate fields of "vendors_summary_view" */
export type Vendors_Summary_View_Aggregate_Fields = {
  __typename?: 'vendors_summary_view_aggregate_fields';
  avg?: Maybe<Vendors_Summary_View_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Vendors_Summary_View_Max_Fields>;
  min?: Maybe<Vendors_Summary_View_Min_Fields>;
  stddev?: Maybe<Vendors_Summary_View_Stddev_Fields>;
  stddev_pop?: Maybe<Vendors_Summary_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vendors_Summary_View_Stddev_Samp_Fields>;
  sum?: Maybe<Vendors_Summary_View_Sum_Fields>;
  var_pop?: Maybe<Vendors_Summary_View_Var_Pop_Fields>;
  var_samp?: Maybe<Vendors_Summary_View_Var_Samp_Fields>;
  variance?: Maybe<Vendors_Summary_View_Variance_Fields>;
};

/** aggregate fields of "vendors_summary_view" */
export type Vendors_Summary_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vendors_Summary_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Vendors_Summary_View_Avg_Fields = {
  __typename?: 'vendors_summary_view_avg_fields';
  accepted_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  rejected_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "vendors_summary_view". All fields are combined with a logical 'AND'. */
export type Vendors_Summary_View_Bool_Exp = {
  _and?: InputMaybe<Array<Vendors_Summary_View_Bool_Exp>>;
  _not?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
  _or?: InputMaybe<Array<Vendors_Summary_View_Bool_Exp>>;
  accepted_count?: InputMaybe<Int_Comparison_Exp>;
  organisation?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  pending_count?: InputMaybe<Int_Comparison_Exp>;
  rejected_count?: InputMaybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vendors_Summary_View_Max_Fields = {
  __typename?: 'vendors_summary_view_max_fields';
  accepted_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['Int']['output']>;
  rejected_count?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type Vendors_Summary_View_Min_Fields = {
  __typename?: 'vendors_summary_view_min_fields';
  accepted_count?: Maybe<Scalars['Int']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['Int']['output']>;
  rejected_count?: Maybe<Scalars['Int']['output']>;
};

/** Ordering options when selecting data from "vendors_summary_view". */
export type Vendors_Summary_View_Order_By = {
  accepted_count?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  rejected_count?: InputMaybe<Order_By>;
};

/** select columns of table "vendors_summary_view" */
export enum Vendors_Summary_View_Select_Column {
  /** column name */
  AcceptedCount = 'accepted_count',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PendingCount = 'pending_count',
  /** column name */
  RejectedCount = 'rejected_count',
}

/** aggregate stddev on columns */
export type Vendors_Summary_View_Stddev_Fields = {
  __typename?: 'vendors_summary_view_stddev_fields';
  accepted_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  rejected_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Vendors_Summary_View_Stddev_Pop_Fields = {
  __typename?: 'vendors_summary_view_stddev_pop_fields';
  accepted_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  rejected_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Vendors_Summary_View_Stddev_Samp_Fields = {
  __typename?: 'vendors_summary_view_stddev_samp_fields';
  accepted_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  rejected_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "vendors_summary_view" */
export type Vendors_Summary_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendors_Summary_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendors_Summary_View_Stream_Cursor_Value_Input = {
  accepted_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  pending_count?: InputMaybe<Scalars['Int']['input']>;
  rejected_count?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type Vendors_Summary_View_Sum_Fields = {
  __typename?: 'vendors_summary_view_sum_fields';
  accepted_count?: Maybe<Scalars['Int']['output']>;
  pending_count?: Maybe<Scalars['Int']['output']>;
  rejected_count?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Vendors_Summary_View_Var_Pop_Fields = {
  __typename?: 'vendors_summary_view_var_pop_fields';
  accepted_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  rejected_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Vendors_Summary_View_Var_Samp_Fields = {
  __typename?: 'vendors_summary_view_var_samp_fields';
  accepted_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  rejected_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Vendors_Summary_View_Variance_Fields = {
  __typename?: 'vendors_summary_view_variance_fields';
  accepted_count?: Maybe<Scalars['Float']['output']>;
  pending_count?: Maybe<Scalars['Float']['output']>;
  rejected_count?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "vendors" */
export enum Vendors_Update_Column {
  /** column name */
  ApprovalStatus = 'approval_status',
  /** column name */
  AssessmentStatus = 'assessment_status',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ReviewFrequency = 'review_frequency',
  /** column name */
  RiskLevelId = 'risk_level_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TerminatedAt = 'terminated_at',
  /** column name */
  TierId = 'tier_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Vendors_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Vendors_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendors_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Vendors_Var_Pop_Fields = {
  __typename?: 'vendors_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "vendors" */
export type Vendors_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Vendors_Var_Samp_Fields = {
  __typename?: 'vendors_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "vendors" */
export type Vendors_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Vendors_Variance_Fields = {
  __typename?: 'vendors_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "vendors" */
export type Vendors_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

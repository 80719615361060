import { GlobeAltIcon } from '@heroicons/react/24/outline';
import { DrawerProperty, EditableInput } from '@main/ui';
import { useTranslation } from 'react-i18next';

export const UrlFieldInput = ({
  name,
  value,
  onChange,
  isReadOnly,
}: {
  name: string;
  value?: string;
  onChange: (value: string) => void;
  isReadOnly?: boolean;
}) => {
  const { t } = useTranslation('ui');

  return (
    <DrawerProperty isReadOnly={isReadOnly}>
      <DrawerProperty.Label icon={GlobeAltIcon}>{name}</DrawerProperty.Label>
      <DrawerProperty.Content>
        <EditableInput
          input={{ type: 'url' }}
          placeholder={t('customFields.placeholder.urlField')}
          editable={{
            defaultValue: value,
            onSubmit: (newValue) => {
              if (newValue !== value) {
                onChange(newValue);
              }
            },
          }}
        />
      </DrawerProperty.Content>
    </DrawerProperty>
  );
};

import { HStack, Icon, Text, useColorModeValue, VStack } from '@chakra-ui/react';
import { Square2StackIcon } from '@heroicons/react/24/solid';
import { GlobalSearchResultCard, GlobalSearchResultProps, OverflowContainer } from '@main/ui';
import { CornerDownLeftIcon } from '@main/ui/icons';
import { useMemo } from 'react';

import { categoryIcons } from './categories';
import { AppGlobalSearchResult } from './use-global-search';

export function AppGlobalSearchResultCard(props: GlobalSearchResultProps<AppGlobalSearchResult>) {
  const iconColor = useColorModeValue('gray.500', 'gray.400');
  const { category, categoryIcon } = useMemo(
    () => ({
      category: `${props.result.category} / ${props.result.internalId}`,
      categoryIcon: props.result.category
        ? (categoryIcons[props.result.category] ?? Square2StackIcon)
        : Square2StackIcon,
    }),
    [props.result.category, props.result.internalId],
  );

  return (
    <GlobalSearchResultCard
      __css={{
        '&[data-active-item] .global-search-result-card__icon': {
          display: 'block',
        },
      }}
      {...props}
    >
      <HStack spacing="3">
        <Icon boxSize={5} as={categoryIcon} color={iconColor} />
        <VStack
          align="stretch"
          spacing="0"
          flexGrow="1"
          color={useColorModeValue('gray.600', 'gray.300')}
        >
          <OverflowContainer.Tooltip
            label={props.result.title}
            hasArrow
            placement="auto"
            fontSize="sm"
            openDelay={500}
          >
            <Text fontSize="sm" fontWeight="medium" noOfLines={1}>
              {props.result.title}
            </Text>
          </OverflowContainer.Tooltip>
          <Text fontSize="xs" fontWeight="400" color="gray.500">
            {category}
            {category && props.result.updatedAt && ' • '}
            {props.result.updatedAt}
          </Text>
        </VStack>
        <Icon
          as={CornerDownLeftIcon}
          fontSize="xl"
          color={iconColor}
          display="none"
          className="global-search-result-card__icon"
        />
      </HStack>
    </GlobalSearchResultCard>
  );
}

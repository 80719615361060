import { Table, Tbody, Tr } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useGetRiskClassificationsQuery } from '@main/graphql/features/RiskClassifications.generated';
import {
  useGetRiskMatrixQuery,
  useUpdateRiskInherentLevelMutation,
  useUpdateRiskResidualLevelMutation,
} from '@main/graphql/features/RiskMatrix.generated';
import { createSorter, toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/redux-toolkit-hooks';
import { getMatrixLevelsMap } from '../../../../risks/slice';
import { getCurrentUserSelectedOrgId } from '../../../../user/slice';
import { MatrixCell } from './cell';
import { MatrixColumnHeaders, MatrixRowHeader } from './rows';
import { constructMapKey, MatrixItem } from './utils';

type RiskLevel = 'residual' | 'inherent';

export const MatrixTable = ({ riskLevelType }: { riskLevelType: RiskLevel }) => {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const { inherentLevelsMap, residualLevelsMap } = useAppSelector(getMatrixLevelsMap);

  const { refetch: refetchMatrixLevels } = useGetRiskMatrixQuery({ organizationId });
  const sorter = useMemo(() => createSorter(), []);
  const { impacts, likelihoods } = useGetRiskClassificationsQuery(
    { organizationId },
    {
      selectFromResult: ({ data }) => ({
        impacts: data?.impacts.toSorted(sorter) ?? [],
        likelihoods: data?.likelihoods.toSorted(sorter) ?? [],
      }),
    },
  );

  const [
    updateInherentLevel,
    { isLoading: isInherentLevelUpdating, originalArgs: inherentLevelMutationArgs },
  ] = useUpdateRiskInherentLevelMutation();
  const [
    updateResidualLevel,
    { isLoading: isResidualLevelUpdating, originalArgs: residualLevelMutationArgs },
  ] = useUpdateRiskResidualLevelMutation();

  const isUpdatingLevel = isInherentLevelUpdating || isResidualLevelUpdating;
  const onLevelChange = async ({ id, level }: MatrixItem) => {
    const translation =
      riskLevelType === 'inherent'
        ? t('settings.organization.risks.matrix.inherentLevel')
        : t('settings.organization.risks.matrix.residualLevel');

    try {
      if (riskLevelType === 'inherent') {
        await updateInherentLevel({ id, level }).unwrap();
      } else {
        await updateResidualLevel({ id, level }).unwrap();
      }

      refetchMatrixLevels();
      successToast(
        t('successMessages.editSucceeded', {
          entity: `${t('entities.risk')} ${translation}`,
        }),
      );
    } catch (error) {
      errorToast(
        t('errorMessages.updateFailed', {
          entity: `${t('entities.risk')} ${translation}`,
        }),
      );
      datadogLogs.logger.error(`Updating risk ${riskLevelType} level failed`, {}, toError(error));
    }
  };

  return (
    <Table variant="unstyled">
      <Tbody>
        <MatrixColumnHeaders impacts={impacts} />
        {likelihoods.map((likelihood) => (
          <Tr key={likelihood.id}>
            <MatrixRowHeader headerName={likelihood.name} />
            {impacts.map((impact) => {
              const key = constructMapKey({
                impactName: impact.name,
                likelihoodName: likelihood.name,
              });
              const value =
                riskLevelType === 'inherent'
                  ? inherentLevelsMap.get(key)
                  : residualLevelsMap.get(key);

              const mutationArgsId = inherentLevelMutationArgs?.id || residualLevelMutationArgs?.id;
              return (
                <MatrixCell
                  key={key}
                  cellValue={value}
                  onChange={onLevelChange}
                  isUpdating={mutationArgsId === value?.id && isUpdatingLevel}
                />
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

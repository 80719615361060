import {
  Center,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tooltip,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { GetRiskClassificationsQuery } from '@main/graphql/features/RiskClassifications.generated';
import { useTextOverflow } from '@main/ui';
import { useMemo } from 'react';

import { constructMapKey, RiskLevelColors } from '../settings/organization/risks/matrix/utils';
import { MatrixItemWithRiskCount } from './slice';

export interface HeatMapProps {
  map: Map<string, MatrixItemWithRiskCount>;
  impacts: GetRiskClassificationsQuery['impacts'];
  likelihoods: GetRiskClassificationsQuery['likelihoods'];
}

export function HeatMap({ map, impacts, likelihoods }: HeatMapProps) {
  const thead = useMemo(
    () => impacts.map((impact) => <MatrixColumnHeader key={impact.id} headerName={impact.name} />),
    [impacts],
  );

  const tbody = useMemo(
    () =>
      likelihoods.map((likelihood) => (
        <Tr key={likelihood.id}>
          <MatrixRowHeader headerName={likelihood.name} />
          {impacts.map((impact) => {
            const key = constructMapKey({
              impactName: impact.name,
              likelihoodName: likelihood.name,
            });
            const value = map.get(key) as MatrixItemWithRiskCount;
            return (
              <Td key={key} textAlign="center" py="3px" px={1}>
                <Center
                  height="44px"
                  rounded="md"
                  bgColor={`${RiskLevelColors[value.level]}.${
                    map.get(key)?.counter ? '200' : '100'
                  }`}
                  color={`${RiskLevelColors[value.level]}.800`}
                  fontSize="sm"
                >
                  {map.get(key)?.counter ? map.get(key)?.counter : null}
                </Center>
              </Td>
            );
          })}
        </Tr>
      )),
    [map, impacts, likelihoods],
  );

  return (
    <Table variant="unstyled" sx={{ tableLayout: 'fixed', width: 'full' }}>
      <Tbody>
        <Tr>
          <Th>{/* This is the header for the first column so should be empty  */}</Th>
          {thead}
        </Tr>
        {tbody}
      </Tbody>
    </Table>
  );
}

function MatrixColumnHeader({ headerName }: { headerName: string }) {
  const { isOverflow, ref } = useTextOverflow<HTMLParagraphElement>();

  const headerColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Th
      scope="col"
      color={headerColor}
      textAlign="center"
      fontWeight="normal"
      sx={{ textTransform: 'none' }}
      px={1}
    >
      <Tooltip
        label={headerName}
        aria-label="A tooltip"
        isDisabled={!isOverflow}
        hasArrow
        fontSize="xs"
        openDelay={300}
      >
        <Text noOfLines={1} ref={ref}>
          {headerName}
        </Text>
      </Tooltip>
    </Th>
  );
}

function MatrixRowHeader({ headerName }: { headerName: string }) {
  const { isOverflow, ref } = useTextOverflow<HTMLParagraphElement>();
  const headerColor = useColorModeValue('gray.500', 'gray.400');

  return (
    <Th
      h="50px"
      scope="row"
      color={headerColor}
      fontWeight="normal"
      pl={1}
      pr={2}
      py={0}
      sx={{ textTransform: 'none' }}
      alignItems="center"
    >
      <Tooltip
        label={headerName}
        aria-label="A tooltip"
        isDisabled={!isOverflow}
        hasArrow
        fontSize="xs"
        openDelay={300}
      >
        <Text noOfLines={1} ref={ref}>
          {headerName}
        </Text>
      </Tooltip>
    </Th>
  );
}

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const FindingHeading = ({ children }: { children: ReactNode }) => {
  const color = useColorModeValue('gray.700', 'gray.300');
  return (
    <Text color={color} fontSize="sm" fontWeight="semibold">
      {children}
    </Text>
  );
};

export const FindingDescription = ({ children }: { children: ReactNode }) => {
  const color = useColorModeValue('gray.600', 'gray.400');
  return (
    <Text color={color} fontSize="sm">
      {children}
    </Text>
  );
};

export const FullLogModal = ({
  isOpen,
  children,
  onClose,
}: {
  isOpen: boolean;
  children: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('findings.fullLogModal.title')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex direction="column" gap={2}>
            <Text fontSize="xs" fontWeight="semibold">
              {t('findings.fullLogModal.description')}
            </Text>
            <Textarea
              background="gray.50"
              color="gray.600"
              _dark={{
                background: 'gray.600',
                color: 'gray.50',
              }}
              rows={18}
              resize="none"
              value={children}
              readOnly
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

import {
  Card,
  IconButton,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/24/outline';
import { formatDate, useStableCallback } from '@main/shared/utils';
import { createColumnHelper, Table, useAlertDialog } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COMPLYANCE_USER } from '../../utils/constants';
import { ReviewDetailsFragment } from './review.fragment.generated';
import { DefaultValues, ReviewForm } from './review-form';
import { useDeleteReview } from './use-delete-review';
import { useEditReview } from './use-edit-review';

export type Review = ReviewDetailsFragment;

export const ReviewsTable = ({
  data,
  canEdit,
  isLoading,
}: {
  data: Review[];
  canEdit: boolean;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const editReview = useEditReview();
  const columns = useReviewTableColumns();
  const [selectedReview, setReview] = useState<Review>();

  const openEditModal = useStableCallback((row: { original: Review }) => {
    setReview(row.original);

    onOpen();
  });

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.review').toLowerCase(),
      plural: t('entities.plural.reviews').toLowerCase(),
    };
  }, [t]);

  const defaultValues: DefaultValues = {
    name: selectedReview?.name,
    notes: selectedReview?.notes,
    reviewDate: selectedReview?.review_date,
    reviewedBy: {
      id: selectedReview?.reviews_user?.id,
      displayName: selectedReview?.reviews_user?.displayName,
    },
    files: selectedReview?.reviews_review_files.map((file) => ({
      reviewFileId: file.id,
      id: file.review_files_file.id,
      name: file.review_files_file.name,
      size: file.review_files_file.size,
    })),
  };

  return (
    <Card variant="table-styles">
      <Table
        data={data}
        isLoading={isLoading}
        columns={columns}
        itemName={tableItemName}
        onRowClick={openEditModal}
      />

      <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{canEdit ? t('reviews.editReview') : t('entities.review')}</ModalHeader>
          <ModalCloseButton />

          {selectedReview && (
            <ReviewForm
              canEdit={canEdit}
              onModalClose={onClose}
              onSubmit={(formData) => editReview({ defaultReview: selectedReview, ...formData })}
              defaultValues={defaultValues}
            />
          )}
        </ModalContent>
      </Modal>
    </Card>
  );
};

export function useReviewTableColumns() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const deleteReview = useDeleteReview();

  return useMemo(() => {
    const columnHelper = createColumnHelper<Review>();

    return [
      columnHelper.columns.text({
        id: 'date',
        header: t('reviews.tableColumns.date'),
        accessorFn: ({ review_date }) => formatDate(review_date),
        size: 180,
      }),

      columnHelper.columns.text({
        id: 'name',
        header: t('reviews.tableColumns.name'),
        accessorFn: ({ name }) => name,
      }),

      columnHelper.columns.avatar({
        id: 'reviewer',
        header: t('reviews.tableColumns.reviewer'),
        accessorFn: ({ reviews_user }) => {
          if (!reviews_user) {
            return COMPLYANCE_USER;
          }

          return {
            id: reviews_user.id,
            displayName: reviews_user.displayName,
          };
        },
        size: 120,
      }),

      columnHelper.columns.actions({
        size: 50,
        PrimaryAction: ({ row }) => (
          <IconButton
            minW={4}
            variant="link"
            aria-label={t('buttons.edit')}
            icon={<TrashIcon />}
            onClick={() => {
              openDialog({
                dialogHeader: t('reviews.deleteAlert.title'),
                dialogContent: t('reviews.deleteAlert.content'),
                confirmAction: {
                  children: t('buttons.delete'),
                  onClick: () => deleteReview(row.original.id),
                },
              });
            }}
          />
        ),
      }),
    ];
  }, [deleteReview, openDialog, t]);
}

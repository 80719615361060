import { DownloadFile } from '@main/bulk-downloader';
import { QuestionnaireFormComponentRegistry } from '@main/dynamic-form';
import {
  getFormConfigFilter,
  getQuestionConditionsFilter,
  reorderFormAnswers,
} from '@main/questionnaires-form';
import { safeFileName, serializeCSV } from '@main/shared/utils';
import { TFunction } from 'i18next';

import { AppDownloadFile } from '../../../bulk-downloader/app-stream-downloader';
import { QuestionnaireExporter } from '../use-questionnaire-exporters';
import {
  VendorQuestionnaireAnswerForDownloadFragment,
  VendorQuestionnaireForDownloadFragment,
} from '../vq-files-fetcher.generated';

export class FormQuestionnaireExporter implements QuestionnaireExporter {
  constructor(
    protected readonly componentRegistry: QuestionnaireFormComponentRegistry,
    protected readonly t: TFunction,
  ) {}

  canExport(vq: VendorQuestionnaireForDownloadFragment): boolean {
    return !!vq.form;
  }

  exportQuestionnaire(vq: VendorQuestionnaireForDownloadFragment): DownloadFile<AppDownloadFile>[] {
    return [
      {
        name: `Answers form.csv`,
        file: { content: () => this.generateAnswersCSV(vq) },
      },
      ...(vq.form?.answers ?? []).flatMap((answer) => {
        const fieldConfig = vq.form?.config_snapshot?.find(
          (question) => question.name === answer.field_name,
        );
        return answer.form_uploads.map((upload) => ({
          name: `${safeFileName(fieldConfig?.label || answer.field_name)}/${safeFileName(
            `${upload.file.id} - ${upload.file.name ?? ''}`,
          )}`,
          file: { fileId: upload.file.id },
        }));
      }),
    ];
  }

  protected generateAnswersCSV(vq: VendorQuestionnaireForDownloadFragment) {
    return serializeCSV(
      [
        this.t('vendors.questionnaires.export.csvHeader.question'),
        this.t('vendors.questionnaires.export.csvHeader.answer'),
        this.t('vendors.questionnaires.export.csvHeader.comment'),
        this.t('vendors.questionnaires.export.csvHeader.supportingFiles'),
      ] as const,
      this.prepareAnswers(vq).map((answer) => this.exportAnswer(answer, vq)),
    );
  }

  protected prepareAnswers(vq: VendorQuestionnaireForDownloadFragment) {
    const config = vq.form?.config_snapshot ?? [];
    const answers = vq.form?.answers ?? [];
    const conditionsFiltersMap = new Map(
      config.map((field) => [field.name, getQuestionConditionsFilter(field)]),
    );
    const answersMap = new Map(answers.map((answer) => [answer.field_name, answer]));
    const filteredConfig =
      config.filter(getFormConfigFilter(answers, answersMap, conditionsFiltersMap)) ?? [];

    return reorderFormAnswers(answers, filteredConfig);
  }

  protected exportAnswer(
    answer: VendorQuestionnaireAnswerForDownloadFragment,
    vq: VendorQuestionnaireForDownloadFragment,
  ) {
    const fieldConfig = vq.form?.config_snapshot?.find(
      (question) => question.name === answer.field_name,
    );

    if (!fieldConfig) {
      throw new Error(`Field config not found for answer ${answer.field_name}`);
    }

    const fieldComponent = this.componentRegistry.get(fieldConfig.kind);
    const values = fieldComponent.resolveReadableValues?.({
      config: { ...fieldConfig, value: answer.value },
    }) ?? [answer.value];

    return [
      fieldConfig.label,
      values.join(', '),
      answer.comment,
      answer.form_uploads
        .map((upload) => safeFileName(`${upload.file.id} - ${upload.file.name ?? ''}`))
        .join(', '),
    ] as const;
  }
}

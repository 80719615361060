import { useNavigate, useSearch } from '@tanstack/react-router';
import { SortingState } from '@tanstack/react-table';
import * as jsurl from 'jsurl2';
import { useEffect, useState } from 'react';

import { isColumnSort } from './sort';

/**
 * React hook which syncs table's column sort state to URL query search.
 * `searchParam` configures the search param name under which the state
 * will be persited in the URL.
 */
export function useTableSortQuery({ searchParam }: { searchParam: string }) {
  const search: Record<string, string> = useSearch({ strict: false });
  const navigate = useNavigate();

  const [sorting, setSorting] = useState(() => decodeColumnSort(search[searchParam] ?? ''));

  useEffect(() => {
    void navigate({
      search: (old) => ({
        ...old,
        [searchParam]: encodeColumnSort(sorting),
      }),
      replace: true,
    });
  }, [navigate, searchParam, sorting]);

  return [sorting, setSorting] as const;
}

function decodeColumnSort(encodedSorting: string): SortingState {
  try {
    const json = jsurl.parse(encodedSorting);
    if (!Array.isArray(json)) {
      return [];
    }
    return json.filter(isColumnSort);
  } catch {
    return [];
  }
}

export function encodeColumnSort(sorting: SortingState): string | undefined {
  if (!sorting.length) {
    return;
  }
  return jsurl.stringify(sorting);
}

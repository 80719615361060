/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import {
  VendorQuestionnaireForDownloadFragmentDoc,
  VendorQuestionnaireAnswerForDownloadFragmentDoc,
} from './vq-files-fetcher.generated';
import { api } from '@main/graphql/shared-api';
export type GetAllVendorQuestionnairesForDownloadQueryVariables = Types.Exact<{
  vendorIds: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;

export type GetAllVendorQuestionnairesForDownloadQuery = {
  __typename?: 'query_root';
  vendor_questionnaires: Array<{
    __typename?: 'vendor_questionnaires';
    id: string;
    updated_at: string;
    answer_upload_id?: string;
    sent_to_user?: { __typename?: 'users'; email?: string };
    vendor: { __typename?: 'vendors'; id: string; internal_id?: string; name?: string };
    questionnaire: {
      __typename?: 'questionnaires';
      id: string;
      internal_id?: string;
      name?: string;
    };
    form?: {
      __typename?: 'vendor_questionnaire_forms';
      config_snapshot?: ClientTypes.FormConfig;
      answers: Array<{
        __typename?: 'vendor_questionnaire_form_answers';
        field_name: string;
        status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
        value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
        comment?: string;
        form_uploads: Array<{
          __typename?: 'vendor_questionnaire_form_uploads';
          file: { __typename?: 'files'; id: string; name?: string };
        }>;
      }>;
    };
    uploads: Array<{
      __typename?: 'vendor_questionnaire_uploads';
      id: string;
      file: { __typename?: 'files'; id: string; name?: string };
    }>;
  }>;
};

export type GetVendorQuestionnairesForDownloadQueryVariables = Types.Exact<{
  vendorQuestionnaireId: Types.Scalars['uuid']['input'];
}>;

export type GetVendorQuestionnairesForDownloadQuery = {
  __typename?: 'query_root';
  vendorQuestionnaire?: {
    __typename?: 'vendor_questionnaires';
    id: string;
    updated_at: string;
    answer_upload_id?: string;
    sent_to_user?: { __typename?: 'users'; email?: string };
    vendor: { __typename?: 'vendors'; id: string; internal_id?: string; name?: string };
    questionnaire: {
      __typename?: 'questionnaires';
      id: string;
      internal_id?: string;
      name?: string;
    };
    form?: {
      __typename?: 'vendor_questionnaire_forms';
      config_snapshot?: ClientTypes.FormConfig;
      answers: Array<{
        __typename?: 'vendor_questionnaire_form_answers';
        field_name: string;
        status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
        value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
        comment?: string;
        form_uploads: Array<{
          __typename?: 'vendor_questionnaire_form_uploads';
          file: { __typename?: 'files'; id: string; name?: string };
        }>;
      }>;
    };
    uploads: Array<{
      __typename?: 'vendor_questionnaire_uploads';
      id: string;
      file: { __typename?: 'files'; id: string; name?: string };
    }>;
  };
};

export type GetVendorQuestionnairesByQuestionnaireForDownloadQueryVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid']['input'];
}>;

export type GetVendorQuestionnairesByQuestionnaireForDownloadQuery = {
  __typename?: 'query_root';
  vendor_questionnaires: Array<{
    __typename?: 'vendor_questionnaires';
    id: string;
    updated_at: string;
    answer_upload_id?: string;
    sent_to_user?: { __typename?: 'users'; email?: string };
    vendor: { __typename?: 'vendors'; id: string; internal_id?: string; name?: string };
    questionnaire: {
      __typename?: 'questionnaires';
      id: string;
      internal_id?: string;
      name?: string;
    };
    form?: {
      __typename?: 'vendor_questionnaire_forms';
      config_snapshot?: ClientTypes.FormConfig;
      answers: Array<{
        __typename?: 'vendor_questionnaire_form_answers';
        field_name: string;
        status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
        value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
        comment?: string;
        form_uploads: Array<{
          __typename?: 'vendor_questionnaire_form_uploads';
          file: { __typename?: 'files'; id: string; name?: string };
        }>;
      }>;
    };
    uploads: Array<{
      __typename?: 'vendor_questionnaire_uploads';
      id: string;
      file: { __typename?: 'files'; id: string; name?: string };
    }>;
  }>;
};

export const GetAllVendorQuestionnairesForDownloadDocument = `
    query GetAllVendorQuestionnairesForDownload($vendorIds: [uuid!]!) {
  vendor_questionnaires(
    where: {vendor_id: {_in: $vendorIds}, status: {_eq: submitted}}
  ) {
    ...VendorQuestionnaireForDownload
  }
}
    ${VendorQuestionnaireForDownloadFragmentDoc}
${VendorQuestionnaireAnswerForDownloadFragmentDoc}` as string &
  TypedDocumentNode<
    GetAllVendorQuestionnairesForDownloadQuery,
    GetAllVendorQuestionnairesForDownloadQueryVariables
  >;
export const GetVendorQuestionnairesForDownloadDocument = `
    query GetVendorQuestionnairesForDownload($vendorQuestionnaireId: uuid!) {
  vendorQuestionnaire: vendor_questionnaires_by_pk(id: $vendorQuestionnaireId) {
    ...VendorQuestionnaireForDownload
  }
}
    ${VendorQuestionnaireForDownloadFragmentDoc}
${VendorQuestionnaireAnswerForDownloadFragmentDoc}` as string &
  TypedDocumentNode<
    GetVendorQuestionnairesForDownloadQuery,
    GetVendorQuestionnairesForDownloadQueryVariables
  >;
export const GetVendorQuestionnairesByQuestionnaireForDownloadDocument = `
    query GetVendorQuestionnairesByQuestionnaireForDownload($questionnaireId: uuid!) {
  vendor_questionnaires(
    where: {questionnaire_id: {_eq: $questionnaireId}, status: {_eq: submitted}}
  ) {
    ...VendorQuestionnaireForDownload
  }
}
    ${VendorQuestionnaireForDownloadFragmentDoc}
${VendorQuestionnaireAnswerForDownloadFragmentDoc}` as string &
  TypedDocumentNode<
    GetVendorQuestionnairesByQuestionnaireForDownloadQuery,
    GetVendorQuestionnairesByQuestionnaireForDownloadQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAllVendorQuestionnairesForDownload: build.query<
      GetAllVendorQuestionnairesForDownloadQuery,
      GetAllVendorQuestionnairesForDownloadQueryVariables
    >({
      query: (variables) => ({
        document: GetAllVendorQuestionnairesForDownloadDocument,
        variables,
      }),
    }),
    GetVendorQuestionnairesForDownload: build.query<
      GetVendorQuestionnairesForDownloadQuery,
      GetVendorQuestionnairesForDownloadQueryVariables
    >({
      query: (variables) => ({ document: GetVendorQuestionnairesForDownloadDocument, variables }),
    }),
    GetVendorQuestionnairesByQuestionnaireForDownload: build.query<
      GetVendorQuestionnairesByQuestionnaireForDownloadQuery,
      GetVendorQuestionnairesByQuestionnaireForDownloadQueryVariables
    >({
      query: (variables) => ({
        document: GetVendorQuestionnairesByQuestionnaireForDownloadDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAllVendorQuestionnairesForDownloadQuery,
  useLazyGetAllVendorQuestionnairesForDownloadQuery,
  useGetVendorQuestionnairesForDownloadQuery,
  useLazyGetVendorQuestionnairesForDownloadQuery,
  useGetVendorQuestionnairesByQuestionnaireForDownloadQuery,
  useLazyGetVendorQuestionnairesByQuestionnaireForDownloadQuery,
} = injectedRtkApi;

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { QuestionnaireUploadFragmentDoc } from '../../../../../../libs/graphql/src/queries/GetQuestionnaire.generated';
import { api } from '@main/graphql/shared-api';
export type FieldConfigModalAssociateFileMutationVariables = Types.Exact<{
  fileId: Types.Scalars['uuid']['input'];
  questionnaireId: Types.Scalars['uuid']['input'];
}>;

export type FieldConfigModalAssociateFileMutation = {
  __typename?: 'mutation_root';
  insert_questionnaire_uploads_one?: {
    __typename?: 'questionnaire_uploads';
    id: string;
    file: { __typename?: 'files'; id: string; name?: string; size?: number };
  };
};

export const FieldConfigModalAssociateFileDocument = `
    mutation FieldConfigModalAssociateFile($fileId: uuid!, $questionnaireId: uuid!) {
  insert_questionnaire_uploads_one(
    object: {file_id: $fileId, questionnaire_id: $questionnaireId}
  ) {
    ...QuestionnaireUpload
  }
}
    ${QuestionnaireUploadFragmentDoc}` as string &
  TypedDocumentNode<
    FieldConfigModalAssociateFileMutation,
    FieldConfigModalAssociateFileMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    FieldConfigModalAssociateFile: build.mutation<
      FieldConfigModalAssociateFileMutation,
      FieldConfigModalAssociateFileMutationVariables
    >({
      query: (variables) => ({ document: FieldConfigModalAssociateFileDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useFieldConfigModalAssociateFileMutation } = injectedRtkApi;

import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  api as RiskClassificationsApi,
  RiskImpactFragment,
  RiskLikelihoodFragment,
  useGetRiskClassificationsQuery,
  useUpdateRiskImpactsMutation,
  useUpdateRiskLikelihoodsMutation,
} from '@main/graphql/features/RiskClassifications.generated';
import {
  createSorter,
  hashArrayIndexesById,
  toError,
  useUpdateAllQueryData,
} from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../../../../user/slice';
import { ClassificationForm, ClassificationType } from './form';
import { ClassificationTable } from './table';

export const RiskClassifications = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canManageRiskClassifications = userRole.permissionMap.manage_risk_classification;

  const [classificationType, setClassificationType] = useState<ClassificationType>('impact');

  const updateAllQueryData = useUpdateAllQueryData();
  const { impacts, likelihoods, refetch } = useGetRiskClassificationsQuery(
    { organizationId },
    {
      selectFromResult: ({ data }) => ({
        impacts: data?.impacts ?? [],
        likelihoods: data?.likelihoods ?? [],
      }),
    },
  );

  const [updateRiskImpacts] = useUpdateRiskImpactsMutation();
  const [updateRiskLikelihoods] = useUpdateRiskLikelihoodsMutation();

  const sorter = useMemo(() => createSorter(), []);

  const handleImpactsOrderUpdate = async (updatedImpacts: RiskImpactFragment[]) => {
    const undoUpdate = updateAllQueryData(
      RiskClassificationsApi,
      'GetRiskClassifications',
      (draft) => {
        const impactsIndexes = hashArrayIndexesById(draft.impacts);
        updatedImpacts.forEach((updatedImpact) => {
          const idx = impactsIndexes[updatedImpact.id];
          if (idx !== undefined) {
            draft.impacts[idx] = updatedImpact;
          }
        });
        draft.impacts.sort(sorter);
      },
    );

    try {
      await updateRiskImpacts({
        updates: updatedImpacts.map((impact) => ({
          where: { id: { _eq: impact.id } },
          _set: { order: impact.order },
        })),
      }).unwrap();
      await refetch().unwrap();
    } catch (e) {
      undoUpdate();
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.impact') }));
      datadogLogs.logger.error(
        'Reordering of risk impacts failed',
        { impacts: updatedImpacts },
        toError(e),
      );
    }
  };

  const handleLikelihoodsOrderUpdate = async (updatedLikelihoods: RiskLikelihoodFragment[]) => {
    const undoUpdate = updateAllQueryData(
      RiskClassificationsApi,
      'GetRiskClassifications',
      (draft) => {
        const likelihoodsIndexes = hashArrayIndexesById(draft.likelihoods);
        updatedLikelihoods.forEach((updatedLikelihood) => {
          const idx = likelihoodsIndexes[updatedLikelihood.id];
          if (idx !== undefined) {
            draft.likelihoods[idx] = updatedLikelihood;
          }
        });
        draft.likelihoods.sort(sorter);
      },
    );

    try {
      await updateRiskLikelihoods({
        updates: updatedLikelihoods.map((likelihood) => ({
          where: { id: { _eq: likelihood.id } },
          _set: { order: likelihood.order },
        })),
      }).unwrap();
      await refetch().unwrap();
    } catch (e) {
      undoUpdate();
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.likelihood') }));
      datadogLogs.logger.error(
        'Reordering of risk likelihoods failed',
        { likelihoods: updatedLikelihoods },
        toError(e),
      );
    }
  };

  return (
    <>
      <Flex py="6" px={[4, 4, 8]} justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h2" size="md">
            {t('settings.organization.risks.classifications.heading')}
          </Heading>
          <Text fontSize="14" color="gray.500">
            {t('settings.organization.risks.classifications.subheading')}
          </Text>
        </Box>
      </Flex>
      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <Stack gap={4}>
          <Card variant="table-styles">
            <CardHeader>
              <Box flex="1 1 0">
                <Heading size="md">
                  {' '}
                  {t('settings.organization.risks.classifications.impacts.heading')}
                </Heading>
                <Text variant="subheading">
                  {t('settings.organization.risks.classifications.impacts.tableSubheading')}
                </Text>
              </Box>
              {canManageRiskClassifications && (
                <Button
                  colorScheme="blue"
                  leftIcon={<Icon as={PlusIcon} />}
                  onClick={() => {
                    setClassificationType('impact');
                    onOpen();
                  }}
                >
                  {t('settings.organization.risks.classifications.impacts.newImpact')}
                </Button>
              )}
            </CardHeader>

            <CardBody>
              <ClassificationTable
                data={impacts}
                classificationType="impact"
                onOrderUpdated={handleImpactsOrderUpdate}
              />
            </CardBody>
          </Card>

          <Card variant="table-styles">
            <CardHeader>
              <Box flex="1 1 0">
                <Heading size="md">
                  {' '}
                  {t('settings.organization.risks.classifications.likelihoods.heading')}
                </Heading>
                <Text variant="subheading">
                  {t('settings.organization.risks.classifications.likelihoods.tableSubheading')}
                </Text>
              </Box>
              {canManageRiskClassifications && (
                <Button
                  colorScheme="blue"
                  leftIcon={<Icon as={PlusIcon} />}
                  onClick={() => {
                    setClassificationType('likelihood');
                    onOpen();
                  }}
                >
                  {t('settings.organization.risks.classifications.likelihoods.newLikelihood')}
                </Button>
              )}
            </CardHeader>

            <CardBody>
              <ClassificationTable
                data={likelihoods}
                classificationType="likelihood"
                onOrderUpdated={handleLikelihoodsOrderUpdate}
              />
            </CardBody>
          </Card>
        </Stack>

        <Modal isOpen={isOpen} onClose={onClose} size="xl" motionPreset="slideInBottom" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {classificationType === 'impact'
                ? t('settings.organization.risks.classifications.impacts.createNewImpact')
                : t('settings.organization.risks.classifications.likelihoods.createNewLikelihood')}
            </ModalHeader>
            <ModalCloseButton />
            <ClassificationForm
              onClose={onClose}
              classificationType={classificationType}
              actionType="create"
            />
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

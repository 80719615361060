import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateCustomProgramRequestMutation } from '@main/graphql/mutations/CreateCustomProgramRequest.generated';
import { toError } from '@main/shared/utils';
import { FileUpload, useLazyFileUpload } from '@main/ui';
import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../user/slice';

type ImportCustomProgramProps = {
  closeModal: () => void;
};

const customProgramSchema = z.object({
  programName: z.string().min(1).max(200),
  programDescription: z.string().max(5000),
  document: z.instanceof(File, { message: 'File must be added' }),
});

type CustomProgramType = z.infer<typeof customProgramSchema>;
interface ImportCustomProgramInput extends Omit<CustomProgramType, 'document'> {
  document: File | null;
}

export const ImportCustomProgram: FC<ImportCustomProgramProps> = ({ closeModal }) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<ImportCustomProgramInput>({
    resolver: zodResolver(customProgramSchema),
    defaultValues: {
      programName: '',
      programDescription: '',
      document: null,
    },
    shouldFocusError: false,
  });

  const [createCustomProgram] = useCreateCustomProgramRequestMutation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  const errorToast = useToast({
    status: 'error',
    duration: 5000,
    isClosable: true,
  });

  const successToast = useToast({
    status: 'success',
    duration: 5000,
    isClosable: true,
  });

  const fileUpload = useLazyFileUpload({
    onFileAdd: (file) => {
      setValue('document', file, {
        shouldDirty: true,
        shouldValidate: true,
      });
    },
    onFileDelete: () => {
      setValue('document', null, {
        shouldDirty: true,
      });
    },
  });

  const saveRequest: SubmitHandler<ImportCustomProgramInput> = async (data) => {
    try {
      const uploadedFileMeta = await fileUpload.upload();

      if (uploadedFileMeta.error) {
        throw toError(uploadedFileMeta.error);
      }

      const { create_custom_program_request } = await createCustomProgram({
        payload: {
          programDescription: data.programDescription,
          programName: data.programName,
          fileId: uploadedFileMeta.file.id,
          organizationId: organization.id,
        },
      }).unwrap();

      if (create_custom_program_request?.status !== 200) {
        throw new Error(
          `Creation resulted in non 200 status: ${create_custom_program_request?.status}`,
        );
      }
    } catch (error) {
      errorToast({ title: 'Error in performing request. Try later' });
      datadogLogs.logger.error('Unable to import custom program', data, toError(error));
      return;
    }

    successToast({
      title: `Your file was successfully uploaded! Our team is crafting your program and you’ll get notified soon.`,
    });

    closeModal();
  };

  return (
    <form onSubmit={handleSubmit(saveRequest)}>
      <Stack spacing={6} pb={4}>
        <Text fontSize="lg" fontWeight="bold">
          {t('programs.custom.heading')}
        </Text>

        <FormControl isInvalid={!!errors.programName}>
          <FormLabel>{t('programs.custom.form.name.label')}</FormLabel>
          <Input
            placeholder={t('programs.custom.form.name.placeholder')}
            maxLength={200}
            {...register('programName')}
          />
          <FormErrorMessage>{errors.programName?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.programDescription}>
          <FormLabel>{t('programs.custom.form.description.label')}</FormLabel>
          <Textarea
            placeholder={t('programs.custom.form.description.placeholder')}
            maxLength={5000}
            {...register('programDescription')}
          />
          <FormErrorMessage>{errors.programDescription?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.document}>
          <FormLabel>{t('programs.custom.form.import.label')}</FormLabel>
          <FileUpload {...fileUpload.props}>
            <FileUpload.Dropzone
              topLabelText={t('programs.custom.form.import.placeholder')}
              bottomLabelText={t('programs.custom.form.import.description')}
            />
          </FileUpload>
          <FormHelperText>{t('programs.custom.form.import.limitation')}</FormHelperText>
          <FormErrorMessage>{errors.document?.message}</FormErrorMessage>
        </FormControl>
        <ButtonGroup justifyContent="end">
          <Button
            colorScheme="gray"
            onClick={() => {
              closeModal();
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button isLoading={isSubmitting} type="submit" colorScheme="blue">
            {t('buttons.submit')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
};

import { datadogLogs } from '@datadog/browser-logs';
import {
  useAddRiskTagMutation,
  useCreateRiskTagMutation,
  useLazyGetRiskTagsQuery,
  useRemoveRiskTagMutation,
} from '@main/graphql/features/RiskTags.generated';
import { toError } from '@main/shared/utils';
import { EditableTag, errorToast } from '@main/ui';
import { ActionMeta, MultiValue } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getRiskApi } from './get-risk.generated';
import { getRiskTags, Option } from './slice';

export const Tags = ({ riskId }: { riskId: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const tags = useAppSelector((state) => getRiskTags(state, riskId));
  const [searchTags] = useLazyGetRiskTagsQuery();
  const [createTag] = useCreateRiskTagMutation();
  const [addTag] = useAddRiskTagMutation();
  const [removeTag] = useRemoveRiskTagMutation();

  const searchCustomTagsHandler = async (inputValue: string) => {
    const { organization_risk_tags } = await searchTags({
      organizationId,
      ...(inputValue?.trim() ? { nameFilter: { _ilike: `%${inputValue}%` } } : {}),
    }).unwrap();

    return (
      organization_risk_tags.map((tag) => ({
        value: tag.id,
        label: tag.name,
        colorScheme: 'purple',
        id: '',
      })) || []
    );
  };

  const handler = async (
    resultPromise: ReturnType<
      ReturnType<
        | typeof useCreateRiskTagMutation
        | typeof useAddRiskTagMutation
        | typeof useRemoveRiskTagMutation
      >[0]
    >,
  ) => {
    try {
      await resultPromise.unwrap();
    } catch (error) {
      errorToast(t('errorMessages.tagFailed', { entity: t('entities.tag') }));
      datadogLogs.logger.error('Unable to perform operation on tags.', {}, toError(error));
      return;
    }
  };

  const handleChange = async (items: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    switch (actionMeta.action) {
      case 'create-option': {
        dispatch(
          getRiskApi.util.updateQueryData('GetRisk', { risk_id: riskId }, (draft) => {
            if (draft.risks_by_pk) {
              draft.risks_by_pk.tags.push({
                id: 'new',
                tag: { id: 'new', name: actionMeta.option.label },
              });
            }
          }),
        );
        await handler(
          createTag({
            object: {
              risk_id: riskId,
              tag: {
                data: {
                  name: actionMeta.option.label,
                  organization_id: organizationId,
                },
              },
            },
          }),
        );
        break;
      }
      case 'select-option': {
        dispatch(
          getRiskApi.util.updateQueryData('GetRisk', { risk_id: riskId }, (draft) => {
            if (draft.risks_by_pk) {
              draft.risks_by_pk.tags.push({
                id: actionMeta.option?.value as string,
                tag: {
                  id: actionMeta.option?.value as string,
                  name: actionMeta.option?.label as string,
                },
              });
            }
          }),
        );
        await handler(addTag({ tagId: actionMeta.option?.value as string, riskId }));
        break;
      }
      case 'remove-value':
      case 'pop-value': {
        dispatch(
          getRiskApi.util.updateQueryData('GetRisk', { risk_id: riskId }, (draft) => {
            if (draft.risks_by_pk) {
              const remainingTags = draft.risks_by_pk.tags.filter(
                ({ tag }) => tag.id !== actionMeta.removedValue.value,
              );
              draft.risks_by_pk.tags = remainingTags;
            }
          }),
        );
        await handler(removeTag({ id: actionMeta.removedValue.id || '' }));
        break;
      }
    }
    await dispatch(
      getRiskApi.endpoints.GetRisk.initiate(
        { risk_id: riskId },
        { forceRefetch: true, subscribe: false },
      ),
    ).unwrap();
  };

  return (
    <EditableTag
      isMulti={true}
      debounceMs={500}
      value={tags}
      loadOptions={searchCustomTagsHandler}
      onChange={handleChange}
      getNewOptionData={(label) => ({ label, value: 'new', id: 'new', colorScheme: 'purple' })}
      variant="inline"
      placeholder={t('risks.details.placeholder.tags')}
    />
  );
};

import { Stack, useColorModeValue } from '@chakra-ui/react';
import { AtSymbolIcon, CalendarDaysIcon, UserIcon } from '@heroicons/react/24/outline';
import { useUpdateVendorMutation } from '@main/graphql/mutations/UpdateVendor.generated';
import { Field_Entities_Enum, Vendor_Approval_Statuses_Enum } from '@main/graphql/types.generated';
import {
  DrawerProperty,
  EditableAutoResizeTextarea,
  EditableAvatar,
  EditableInput,
  EditableTag,
} from '@main/ui';
import { BarsIcon, TagIcon } from '@main/ui/icons';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { VENDOR_APPROVAL_STATUSES } from '../../utils/constants';
import { CustomFieldInput } from '../custom-fields/custom-field-input';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { getFrequencyOption, getFrequencyOptions } from '../shared/frequency';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useGetVendorQuery } from './get-vendor.generated';
import { VendorRiskLevels } from './risk-levels';
import { getMappedVendor } from './slice';
import { VendorTiers } from './tiers';
import { useRefetchVendorQueries, useUpdateVendorHandler } from './use-udpate-vendor-handler';

const statuses = Object.values(Vendor_Approval_Statuses_Enum);

function getApprovalStatusOptions(t: TFunction) {
  const statusOptions = statuses.map((value) => ({
    value,
    label: t(`vendors.props.approvalStatus.enums.${value}`),
    colorScheme: VENDOR_APPROVAL_STATUSES[value].colorScheme,
  }));

  return statusOptions;
}

export const VendorDetailsTab = ({ vendorId }: { vendorId: string }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.600', 'gray.500');
  useGetVendorQuery({ vendor_id: vendorId });
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId,
    entityName: Field_Entities_Enum.Vendor,
  });
  const refetchVendorQueries = useRefetchVendorQueries();
  const vendor = useAppSelector((state) => getMappedVendor(state, vendorId));
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUserUpdateVendors = userRole.permissionMap?.write_vendors;

  const [updateVendor] = useUpdateVendorMutation();
  const updateVendorHandler = useUpdateVendorHandler();

  const getAssigneeValue = () => {
    if (!vendor?.owner) {
      return;
    }

    const user = currentOrgUsers[vendor.owner.id];
    if (!user) {
      return;
    }

    return {
      id: vendor.owner.id,
      displayName: user.displayName,
    };
  };

  if (!vendor) {
    return null;
  }

  return (
    <Stack spacing={[6, 3]}>
      <DrawerProperty isReadOnly={!canUserUpdateVendors}>
        <DrawerProperty.Label icon={BarsIcon}>
          {t('vendors.props.description')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAutoResizeTextarea
            defaultValue={vendor.description}
            placeholder={t('vendors.props.placeholder.description')}
            color={textColor}
            onSubmit={(value) =>
              updateVendorHandler(
                updateVendor({
                  id: vendorId,
                  vendor_input: {
                    description: value,
                  },
                }),
              )
            }
          />
        </DrawerProperty.Content>
      </DrawerProperty>
      <DrawerProperty isReadOnly={!canUserUpdateVendors}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('vendors.props.approvalStatus.label')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            value={
              vendor.approval_status && {
                value: vendor.approval_status,
                label: t(`vendors.props.approvalStatus.enums.${vendor.approval_status}`),
                colorScheme: VENDOR_APPROVAL_STATUSES[vendor.approval_status].colorScheme,
              }
            }
            options={getApprovalStatusOptions(t)}
            placeholder={t('vendors.props.placeholder.approval')}
            onChange={(option) =>
              updateVendorHandler(
                updateVendor({
                  id: vendor.id,
                  vendor_input: {
                    approval_status: option ? option.value : null,
                  },
                }),
              )
            }
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUserUpdateVendors}>
        <DrawerProperty.Label icon={TagIcon}>{t('vendors.props.level.label')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <VendorRiskLevels vendorId={vendorId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUserUpdateVendors}>
        <DrawerProperty.Label icon={TagIcon}>{t('vendors.props.tier.label')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <VendorTiers vendorId={vendorId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUserUpdateVendors}>
        <DrawerProperty.Label icon={UserIcon}>{t('vendors.props.owner')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAvatar
            options={getAssigneeOptions(currentOrgNonDisabledUsers)}
            value={getAssigneeValue()}
            placeholder={t('vendors.props.placeholder.owner')}
            onChange={(option) => {
              if (!option) {
                updateVendorHandler(
                  updateVendor({
                    id: vendor.id,
                    vendor_input: {
                      owner_id: null,
                    },
                  }),
                );
                return;
              }

              updateVendorHandler(
                updateVendor({
                  id: vendor.id,
                  vendor_input: {
                    owner_id: option.id,
                  },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUserUpdateVendors}>
        <DrawerProperty.Label icon={AtSymbolIcon}>
          {t('vendors.props.vendorContact')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableInput
            input={{ type: 'email' }}
            placeholder={t('vendors.props.placeholder.contact')}
            editable={{
              defaultValue: vendor.contact_email,
              onSubmit: (contact_email) =>
                updateVendorHandler(
                  updateVendor({
                    id: vendorId,
                    vendor_input: { contact_email },
                  }),
                ),
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUserUpdateVendors}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('vendors.props.reviewFrequency')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            isClearable={false}
            value={vendor.review_frequency && getFrequencyOption(t, vendor.review_frequency)}
            options={getFrequencyOptions(t)}
            onChange={(option) => {
              if (option) {
                updateVendorHandler(
                  updateVendor({
                    id: vendorId,
                    vendor_input: { review_frequency: option.value },
                  }),
                );
              }
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label
          icon={CalendarDaysIcon}
          tooltip={t('risks.details.tooltip.lastReviewedDate')}
        >
          {t('vendors.props.lastReviewedDate')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center" fontSize="xs">
          {vendor.lastReviewedDate}
        </DrawerProperty.Content>
      </DrawerProperty>

      {fieldConfigsQuery.data?.field_configs.map((config) => (
        <CustomFieldInput
          key={config.id}
          entityId={vendorId}
          config={config}
          values={vendor.field_values}
          onChange={() => {
            fieldConfigsQuery.refetch();
            refetchVendorQueries(vendorId);
          }}
          isReadOnly={!canUserUpdateVendors}
        />
      ))}
    </Stack>
  );
};

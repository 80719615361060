import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Sharepoint]: HandlerConfigSharepoint;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigSharepoint {}
}

export const SharepointConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Sharepoint> =
  {
    name: Integration_Names_Enum.Sharepoint,

    orgConfig: z.object({
      tenantId: z.string().uuid().describe('Tenant ID'),
    }),

    evidenceConfig: z.discriminatedUnion('type', [
      z.object({
        type: z.literal(EvidenceIntegrationType.FileSync),
        driveId: z.string(),
        filePath: z.string(),
      }),
    ]),
  };

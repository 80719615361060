import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Link as ChakraLink,
  PinInput,
  PinInputField,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import { useSignInEmailPassword } from '@nhost/react';
import { Link } from '@tanstack/react-router';
import { FormEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SignIn = () => {
  const [mfaCode, setMfaCode] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [cfToken, setCfToken] = useState('');
  const turnstileRef = useRef<TurnstileInstance>();

  const { t } = useTranslation();
  const bg = useBreakpointValue({ base: 'transparent', md: 'bg-surface' });
  const shadow = { base: 'none', md: useColorModeValue('md', 'dark-lg') };
  const linkColor = useColorModeValue('blue.600', 'blue.200');
  const logo = useColorModeValue('/logo.svg', '/logo_dark.svg');

  const {
    signInEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
    error,
    needsMfaOtp,
    mfa,
    sendMfaOtp,
  } = useSignInEmailPassword();

  const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const result = await signInEmailPassword(email, password, cfToken);

    if (result.error?.error === 'invalid-email-password') {
      setCfToken('');
      turnstileRef.current?.reset();
    }
  };

  const getContent = () => {
    if (needsMfaOtp || mfa) {
      return (
        <form onSubmit={sendOtp}>
          <Text align="center" paddingTop="12" paddingBottom="6">
            {t('login.mfaLabel')}
          </Text>
          <HStack justifyContent="center" paddingBottom="4">
            <PinInput otp onComplete={setMfaCode} autoFocus>
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
              <PinInputField />
            </PinInput>
          </HStack>
          <Button
            width="100%"
            mt={6}
            colorScheme="blue"
            size="lg"
            isDisabled={mfaCode.length === 0}
            type="submit"
          >
            {t('login.loginBtn')}
          </Button>
          {isError ? (
            <Text mt="4" textAlign="center" color="red.500">
              {error?.message}
            </Text>
          ) : null}
        </form>
      );
    }

    if (needsEmailVerification) {
      return (
        <Text my="8" textAlign="center">
          Please check your mailbox and follow the verification link to verify your email.
        </Text>
      );
    }

    return (
      <form onSubmit={handleOnSubmit}>
        <FormControl variant="default" mt="12">
          <FormLabel variant="default">Email address</FormLabel>
          <Input
            type="email"
            size="lg"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={disableForm}
            required
          />
        </FormControl>
        <FormControl variant="default" mt="6">
          <FormLabel variant="default">Password</FormLabel>
          <Input
            type="password"
            size="lg"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={disableForm}
            required
          />
        </FormControl>
        <Button
          type="submit"
          isDisabled={isSubmitBtnDisabled}
          isLoading={isLoading}
          width="100%"
          mt={6}
          colorScheme="blue"
          size="lg"
        >
          {t('login.loginBtn')}
        </Button>
        <Box mt="4">
          <Text textAlign="right">
            <Link to="/forgot">
              <ChakraLink as="span" color={linkColor}>
                {t('login.forgotPasswordLink')}
              </ChakraLink>
            </Link>
          </Text>
        </Box>
        {isError ? (
          <Text mt="4" textAlign="center" color="red.500">
            {error?.message}
          </Text>
        ) : null}
        <Stack spacing="6">
          <HStack>
            <Divider />
            <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
              or
            </Text>
            <Divider />
          </HStack>
          <Text textAlign="center">
            <Link to="/sso">
              <ChakraLink as="span" color={linkColor} textAlign="center">
                {t('login.ssoLink')}
              </ChakraLink>
            </Link>
          </Text>
        </Stack>
        {isCfTokenLoading && (
          <HStack mt={4}>
            <Spinner textAlign="center" />
            <Text>{t('login.verifying')}</Text>
          </HStack>
        )}
      </form>
    );
  };

  const sendOtp = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendMfaOtp(mfaCode);
  };

  if (isSuccess) {
    return null;
  }

  const disableForm = isLoading || needsEmailVerification;
  const isCfTurnstileEnabled = import.meta.env.VITE_BRUTEFORCE_PROTECTION === 'true';
  const isCfTokenLoading = isCfTurnstileEnabled && !cfToken;
  const isSubmitBtnDisabled = disableForm || isCfTokenLoading;

  return (
    <Flex py="12" minH="100vh" alignItems="center" justifyContent="center">
      <Stack spacing={8} mx="auto" maxW="lg" w="lg">
        <Box
          py={{ base: '0', md: '12' }}
          px={{ base: '4', md: '10' }}
          bg={bg}
          boxShadow={shadow}
          borderRadius={{ base: 'none', md: 'xl' }}
        >
          <Box position="relative" width="100%" height="14" p={3}>
            <Image
              alt="logo"
              object-fit="contain"
              sizes="100vw"
              src={logo}
              decoding="async"
              width="100%"
              height="100%"
            />
          </Box>
          {getContent()}
          {isCfTurnstileEnabled && (
            <Turnstile
              ref={turnstileRef}
              siteKey={import.meta.env.VITE_CLOUDFLARE_TURNSTILE_SITE_KEY}
              options={{ size: 'invisible' }}
              onSuccess={setCfToken}
            />
          )}
        </Box>
      </Stack>
    </Flex>
  );
};

export default SignIn;

import { Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ControlFindingIntegrationError } from '../../slice';
import { FindingDescription, FindingHeading, FullLogModal } from './shared';

export const ContentIntegrationError = ({
  cause,
  providerName,
  errorMessage,
}: ControlFindingIntegrationError) => {
  const { t } = useTranslation();
  const { t: tIntegrations } = useTranslation('integrations');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fullProviderName = tIntegrations(`integrations.${providerName}.name`);
  return (
    <>
      <FindingHeading>{t(`findings.cause.${cause}.title`)}</FindingHeading>
      <FindingDescription>
        {t(`findings.cause.${cause}.reason`, { providerName: fullProviderName })}
      </FindingDescription>
      <Flex justify="end">
        <Button variant="outline" onClick={onOpen}>
          {t('findings.fullLogModal.viewBtn')}
        </Button>
      </Flex>

      <FullLogModal isOpen={isOpen} onClose={onClose}>
        {errorMessage}
      </FullLogModal>
    </>
  );
};

import { datadogLogs } from '@datadog/browser-logs';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { COMPLYANCE_USER } from '../../utils/constants';
import {
  useCreateReviewWithFileMutation,
  useDeleteReviewFileMutation,
  useUpdateReviewMutation,
} from '../reviews/manage-reviews.generated';
import { FileUpload, ReviewSchema } from './review-form';
import { Review } from './reviews-table';

export const useEditReview = () => {
  const { t } = useTranslation();

  const [udpateReview] = useUpdateReviewMutation();
  const [createReviewFile] = useCreateReviewWithFileMutation();
  const [deleteReviewFile] = useDeleteReviewFileMutation();

  return useStableCallback(
    async ({
      data,
      fileUpload,
      defaultReview,
    }: {
      data: ReviewSchema;
      fileUpload: FileUpload;
      defaultReview: Review;
    }) => {
      try {
        if (fileUpload.props.stagedFiles?.length) {
          const { files, errors } = await fileUpload.upload();

          if (errors.length) {
            errorToast(t('errorMessages.uploadFailed', { entity: t('entities.reviewFile') }));
            for (const error of errors) {
              datadogLogs.logger.error('Failed uploading review file', {}, toError(error));
            }
            return;
          }

          await createReviewFile({
            input: files.map((file) => ({
              file_id: file.id,
              review_id: defaultReview.id,
            })),
          }).unwrap();
        }

        if (fileUpload.deletedFiles.length) {
          await deleteReviewFile({
            fileIds: fileUpload.deletedFiles.map((file) => file.reviewFileId),
          }).unwrap();
        }

        const reviewedById = data.reviewedBy.id === COMPLYANCE_USER.id ? null : data.reviewedBy.id;

        await udpateReview({
          id: defaultReview.id,
          input: {
            name: data.name,
            notes: data.notes,
            review_date: data.reviewDate,
            reviewed_by: reviewedById,
          },
        }).unwrap();

        successToast(t('successMessages.editSucceeded', { entity: t('entities.review') }));
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.review') }));
        datadogLogs.logger.error('Updating review failed', {}, toError(error));
      }
    },
  );
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type CreateReviewMutationVariables = Types.Exact<{
  input: Types.Reviews_Insert_Input;
}>;

export type CreateReviewMutation = {
  __typename?: 'mutation_root';
  insert_reviews_one?: { __typename?: 'reviews'; id: string };
};

export type CreateReviewWithFileMutationVariables = Types.Exact<{
  input: Array<Types.Review_Files_Insert_Input> | Types.Review_Files_Insert_Input;
}>;

export type CreateReviewWithFileMutation = {
  __typename?: 'mutation_root';
  insert_review_files?: { __typename?: 'review_files_mutation_response'; affected_rows: number };
};

export type UpdateReviewMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  input: Types.Reviews_Set_Input;
}>;

export type UpdateReviewMutation = {
  __typename?: 'mutation_root';
  update_reviews_by_pk?: { __typename?: 'reviews'; id: string };
};

export type DeleteReviewMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
}>;

export type DeleteReviewMutation = {
  __typename?: 'mutation_root';
  delete_reviews_by_pk?: { __typename?: 'reviews'; id: string };
};

export type DeleteReviewFileMutationVariables = Types.Exact<{
  fileIds: Array<Types.Scalars['uuid']['input']> | Types.Scalars['uuid']['input'];
}>;

export type DeleteReviewFileMutation = {
  __typename?: 'mutation_root';
  delete_review_files?: { __typename?: 'review_files_mutation_response'; affected_rows: number };
};

export const CreateReviewDocument = `
    mutation CreateReview($input: reviews_insert_input!) {
  insert_reviews_one(object: $input) {
    id
  }
}
    ` as string & TypedDocumentNode<CreateReviewMutation, CreateReviewMutationVariables>;
export const CreateReviewWithFileDocument = `
    mutation CreateReviewWithFile($input: [review_files_insert_input!]!) {
  insert_review_files(objects: $input) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<CreateReviewWithFileMutation, CreateReviewWithFileMutationVariables>;
export const UpdateReviewDocument = `
    mutation UpdateReview($id: uuid!, $input: reviews_set_input!) {
  update_reviews_by_pk(pk_columns: {id: $id}, _set: $input) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdateReviewMutation, UpdateReviewMutationVariables>;
export const DeleteReviewDocument = `
    mutation DeleteReview($id: uuid!) {
  delete_reviews_by_pk(id: $id) {
    id
  }
}
    ` as string & TypedDocumentNode<DeleteReviewMutation, DeleteReviewMutationVariables>;
export const DeleteReviewFileDocument = `
    mutation DeleteReviewFile($fileIds: [uuid!]!) {
  delete_review_files(where: {id: {_in: $fileIds}}) {
    affected_rows
  }
}
    ` as string & TypedDocumentNode<DeleteReviewFileMutation, DeleteReviewFileMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    CreateReview: build.mutation<CreateReviewMutation, CreateReviewMutationVariables>({
      query: (variables) => ({ document: CreateReviewDocument, variables }),
    }),
    CreateReviewWithFile: build.mutation<
      CreateReviewWithFileMutation,
      CreateReviewWithFileMutationVariables
    >({
      query: (variables) => ({ document: CreateReviewWithFileDocument, variables }),
    }),
    UpdateReview: build.mutation<UpdateReviewMutation, UpdateReviewMutationVariables>({
      query: (variables) => ({ document: UpdateReviewDocument, variables }),
    }),
    DeleteReview: build.mutation<DeleteReviewMutation, DeleteReviewMutationVariables>({
      query: (variables) => ({ document: DeleteReviewDocument, variables }),
    }),
    DeleteReviewFile: build.mutation<DeleteReviewFileMutation, DeleteReviewFileMutationVariables>({
      query: (variables) => ({ document: DeleteReviewFileDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useCreateReviewMutation,
  useCreateReviewWithFileMutation,
  useUpdateReviewMutation,
  useDeleteReviewMutation,
  useDeleteReviewFileMutation,
} = injectedRtkApi;

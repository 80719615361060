/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type ReviewDetailsFragment = {
  __typename?: 'reviews';
  id: string;
  name: string;
  notes?: string;
  review_date: string;
  reviews_user?: { __typename?: 'users'; id: string; displayName: string };
  reviews_review_files: Array<{
    __typename?: 'review_files';
    id: string;
    review_files_file: { __typename?: 'files'; id: string; name?: string; size?: number };
  }>;
};

export const ReviewDetailsFragmentDoc = `
    fragment ReviewDetails on reviews {
  id
  name
  notes
  review_date
  reviews_user {
    id
    displayName
  }
  reviews_review_files {
    id
    review_files_file {
      id
      name
      size
    }
  }
}
    `;

import { Center, Spinner } from '@chakra-ui/react';
import { defaultAlertDialogContext } from '@main/ui';
import { Router } from '@tanstack/react-router';
import posthog from 'posthog-js';

import { homeRoute } from './routes';
import { rootRoute } from './routes/__root';
import { route404 } from './routes/404';
import { alertRoute } from './routes/alerts';
import { clientQuestionnairesRoute } from './routes/client-questionnaires';
import { clientQuestionnaireIdRoute } from './routes/client-questionnaires/questionnaireId';
import { controlsRoute } from './routes/controls';
import { dashboardRoute } from './routes/dashboard';
import { evidencesRoute } from './routes/evidence';
import { forgotPasswordRoute } from './routes/forgot';
import { impersonateRoute } from './routes/impersonate';
import { authLayout } from './routes/login';
import { policiesRoute } from './routes/policies';
import { programIdRoute } from './routes/programs/programId';
import { questionnairesRoute } from './routes/questionnaires';
import { reportIdRoute } from './routes/reports/reportId';
import { reportListRoute } from './routes/reports/reportIndex';
import { reportRoute } from './routes/reports/reports';
import { riskDashboardRoute } from './routes/risks/dashboard';
import { riskIndexRoute } from './routes/risks/riskIndex';
import { risksRoute } from './routes/risks/risks';
import { settingsRoute } from './routes/settings';
import { account2faSettingsRoute } from './routes/settings/account/2fa';
import { accountSettingsLayoutRoute } from './routes/settings/account/layout';
import { accountNotificationsSettingsRoute } from './routes/settings/account/notifications';
import { accountPasswordSettingsRoute } from './routes/settings/account/password';
import { accountProfileSettingsRoute } from './routes/settings/account/profile';
import { organizationAuditTrailSettingsRoute } from './routes/settings/organization/audit-trail';
import { organizationControlsCustomFieldsSettingsRoute } from './routes/settings/organization/controls/custom-fields';
import { organizationControlsSettingsLayoutRoute } from './routes/settings/organization/controls/layout';
import {
  organizationSettingsIndexRoute,
  organizationSettingsLayoutRoute,
} from './routes/settings/organization/layout';
import { organizationPoliciesCustomFieldsSettingsRoute } from './routes/settings/organization/policies/custom-fields';
import { organizationPoliciesSettingsLayoutRoute } from './routes/settings/organization/policies/layout';
import { organizationRisksClassificationsSettingsRoute } from './routes/settings/organization/risks/classifications';
import { organizationRisksCustomFieldsSettingsRoute } from './routes/settings/organization/risks/custom-fields';
import { organizationRisksSettingsLayoutRoute } from './routes/settings/organization/risks/layout';
import { organizationRisksMatrixSettingsRoute } from './routes/settings/organization/risks/matrix';
import { organizationRolesIndexSettingsRoute } from './routes/settings/organization/roles';
import { organizationRolesIdSettingsRoute } from './routes/settings/organization/roles/id';
import { organizationRolesSettingsLayoutRoute } from './routes/settings/organization/roles/layout';
import { organizationTasksCustomFieldsSettingsRoute } from './routes/settings/organization/tasks/custom-fields';
import { organizationTasksSettingsLayoutRoute } from './routes/settings/organization/tasks/layout';
import { organizationUsersSettingsRoute } from './routes/settings/organization/users';
import { organizationVendorsCustomFieldsSettingsRoute } from './routes/settings/organization/vendors/custom-fields';
import { organizationVendorsSettingsLayoutRoute } from './routes/settings/organization/vendors/layout';
import { signUpRoute } from './routes/signup';
import { ssoRoute } from './routes/sso';
import { tasksRoute } from './routes/tasks';
import { vendorRoute } from './routes/vendors';

const routeTree = rootRoute.addChildren([
  authLayout.addChildren([
    homeRoute,
    dashboardRoute,
    programIdRoute,
    settingsRoute.addChildren([
      organizationSettingsLayoutRoute.addChildren([
        organizationSettingsIndexRoute,
        organizationUsersSettingsRoute,
        organizationRolesSettingsLayoutRoute.addChildren([
          organizationRolesIdSettingsRoute,
          organizationRolesIndexSettingsRoute,
        ]),
        organizationAuditTrailSettingsRoute,
        organizationPoliciesSettingsLayoutRoute.addChildren([
          organizationPoliciesCustomFieldsSettingsRoute,
        ]),
        organizationTasksSettingsLayoutRoute.addChildren([
          organizationTasksCustomFieldsSettingsRoute,
        ]),
        organizationControlsSettingsLayoutRoute.addChildren([
          organizationControlsCustomFieldsSettingsRoute,
        ]),
        organizationRisksSettingsLayoutRoute.addChildren([
          organizationRisksClassificationsSettingsRoute,
          organizationRisksMatrixSettingsRoute,
          organizationRisksCustomFieldsSettingsRoute,
        ]),
        organizationVendorsSettingsLayoutRoute.addChildren([
          organizationVendorsCustomFieldsSettingsRoute,
        ]),
      ]),
      accountSettingsLayoutRoute.addChildren([
        accountProfileSettingsRoute,
        accountPasswordSettingsRoute,
        account2faSettingsRoute,
        accountNotificationsSettingsRoute,
      ]),
    ]),
    risksRoute.addChildren([riskIndexRoute, riskDashboardRoute]),
    vendorRoute,
    controlsRoute,
    evidencesRoute,
    questionnairesRoute,
    alertRoute,
    tasksRoute,
    policiesRoute,
    clientQuestionnairesRoute,
    clientQuestionnaireIdRoute,
    reportRoute.addChildren([reportIdRoute, reportListRoute]),
  ]),
  impersonateRoute,
  signUpRoute,
  forgotPasswordRoute,
  ssoRoute,
  route404,
]);

export const router = new Router({
  routeTree,
  defaultPendingComponent: () => (
    <Center h="100vh">
      <Spinner size="xl" thickness="4px" color="blue.500" />
    </Center>
  ),
  context: {
    dialog: defaultAlertDialogContext,
  },
});

router.subscribe('onLoad', () => {
  posthog.capture('$pageview');
  router.options.context.dialog.closeDialog();
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

import { Stack, Text } from '@chakra-ui/react';
import { Route } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Reports } from '../../features/reports';
import { reportRoute } from './reports';

const ReportListPage = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('reports.heading')}
      </Text>
      <Reports />
    </Stack>
  );
};

export const reportListRoute = new Route({
  getParentRoute: () => reportRoute,
  path: '/',
  component: ReportListPage,
});

import { api as getProgramsSubscriptionApi } from '@main/graphql/subscriptions/GetProgramsSubscription';
import { createListenerMiddleware, TypedStartListening } from '@reduxjs/toolkit';

import { router } from '../../router';
import { AppDispatch, AppRootState } from '../../store';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedProgramId } from '../user/slice';
import { homepageLoaded } from './slice';

export const programListenerMiddleware = createListenerMiddleware();

const startListening = programListenerMiddleware.startListening as TypedStartListening<
  AppRootState,
  AppDispatch
>;

startListening({
  actionCreator: homepageLoaded,
  effect: async (_, { getState, dispatch }) => {
    let currentUserProgramId = getCurrentUserSelectedProgramId(getState());
    const currentOrg = getCurrentUserSelectedOrg(getState());

    await dispatch(
      getProgramsSubscriptionApi.endpoints.GetPrograms.initiate({ orgId: currentOrg.id }),
    ).unwrap();

    const orgPrograms = getProgramsSubscriptionApi.endpoints.GetPrograms.select({
      orgId: currentOrg.id,
    })(getState()).data?.programs;

    if (!currentUserProgramId) {
      currentUserProgramId = orgPrograms?.[0]?.id;
    } else {
      const active_program = orgPrograms?.find((program) => program.id === currentUserProgramId);

      if (!active_program) {
        currentUserProgramId = orgPrograms?.[0]?.id;
      }
    }
    if (currentUserProgramId) {
      router.navigate({
        to: `/programs/$programId`,
        params: { programId: currentUserProgramId },
        replace: true,
      });
    } else {
      router.navigate({
        to: '/dashboard',
        replace: true,
      });
    }
  },
});

import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  useColorModeValue,
} from '@chakra-ui/react';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { TaskStatus } from '@main/graphql/client-scalars';
import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskSidebarDataFragment as Task } from './manage-task-sidebar.generated';

export type FilterCriteria = `${TaskStatus}` | 'All';
const filterValues: FilterCriteria[] = ['All', 'Completed', 'In progress', 'Overdue', 'Pending'];

export const SidebarFilter = ({
  filters,
  setFilters,
}: {
  filters: FilterCriteria[];
  setFilters: Dispatch<SetStateAction<FilterCriteria[]>>;
}) => {
  const { t } = useTranslation();
  const filterBtnColor = useColorModeValue('gray.400', 'gray.500');
  const optionColor = useColorModeValue('gray.700', 'gray.300');
  const isFilterActive = filters.length !== 0 && filters[0] !== 'All';

  const updateFilter = (event: MouseEvent<HTMLButtonElement>) => {
    const status = event.currentTarget.value as FilterCriteria;

    if (status === 'All') {
      /* If "All" option is selected, removed every other options */
      return setFilters(['All']);
    } else {
      /* Remove "All" option if any other option selected */
      const optionsWithoutAll = filters.filter((status) => status !== 'All');

      /* If selected option already inside the filters, remove it */
      if (event.currentTarget.ariaChecked === 'true') {
        const remainingOptions = optionsWithoutAll.filter((value) => value !== status);

        /* If every filter option is removed, then set default "All" option  */
        return setFilters(remainingOptions.length === 0 ? ['All'] : remainingOptions);
      }

      setFilters([...optionsWithoutAll, status]);
    }
  };

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        variant="outline"
        rounded={36}
        size="xs"
        as={Button}
        leftIcon={<AdjustmentsHorizontalIcon />}
        color={isFilterActive ? undefined : filterBtnColor}
        colorScheme={isFilterActive ? 'blue' : 'gray'}
      >
        {t('tasks.sidebar.filter')}
      </MenuButton>
      <MenuList fontSize="xs">
        <MenuOptionGroup type="checkbox" value={filters}>
          {filterValues.map((status) => (
            <MenuItemOption key={status} value={status} onClick={updateFilter} color={optionColor}>
              {t(`tasks.enum.status.${status}`)}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export function taskFilter(task: Task, criteria: FilterCriteria[]) {
  if (criteria.includes('All') || criteria.length === 0) {
    return true;
  }

  return criteria.includes(task.status);
}

import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Field_Entities_Enum, Field_Types_Enum } from '@main/graphql/types.generated';
import { EditableTag, Select } from '@main/ui';
import { TFunction } from 'i18next';
import { FormEvent, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getMappedOrgPrograms, getOrgProgramsMap } from '../program/slice';

export type FieldSchema =
  | (TextFieldSchema & {
      fieldType: Field_Types_Enum.Text;
    })
  | (UrlFieldSchema & {
      fieldType: Field_Types_Enum.Url;
    })
  | (DateFieldSchema & {
      fieldType: Field_Types_Enum.Date;
    })
  | (NumberFieldSchema & {
      fieldType: Field_Types_Enum.Number;
    })
  | (EmailFieldSchema & {
      fieldType: Field_Types_Enum.Email;
    })
  | (SelectFieldSchema & {
      fieldType: Field_Types_Enum.Select;
    });

const baseFieldSchema = z.object({
  name: z.string().trim().min(1),
  program_ids: z.array(z.string()).optional(),
});

const textFieldSchema = baseFieldSchema;
const dateFieldSchema = baseFieldSchema;
const numberFieldSchema = baseFieldSchema;
const urlFieldSchema = baseFieldSchema;
const emailFieldSchema = baseFieldSchema;

type BaseFieldSchema = z.infer<typeof baseFieldSchema>;
type TextFieldSchema = z.infer<typeof textFieldSchema>;
type DateFieldSchema = z.infer<typeof dateFieldSchema>;
type NumberFieldSchema = z.infer<typeof numberFieldSchema>;
type UrlFieldSchema = z.infer<typeof urlFieldSchema>;
type EmailFieldSchema = z.infer<typeof emailFieldSchema>;

const defaultBaseValues: BaseFieldSchema = {
  name: '',
  program_ids: [],
};

const defaultTextValues: TextFieldSchema = {
  ...defaultBaseValues,
};

const defaulturlValues: UrlFieldSchema = {
  ...defaultBaseValues,
};

const defaultDateValues: DateFieldSchema = {
  ...defaultBaseValues,
};

const defaultNumberValues: NumberFieldSchema = {
  ...defaultBaseValues,
};

const defaultEmailValues: EmailFieldSchema = {
  ...defaultBaseValues,
};

const selectFieldSchema = z.intersection(
  baseFieldSchema,
  z.object({
    options: z.array(z.string().trim().min(1)).min(1),
    isMulti: z.boolean(),
    isCreatable: z.boolean(),
  }),
);

type SelectFieldSchema = z.infer<typeof selectFieldSchema>;

const defaultSelectValues: SelectFieldSchema = {
  ...defaultBaseValues,
  options: [],
  isMulti: false,
  isCreatable: false,
};

const orderedFieldTypes: Field_Types_Enum[] = [
  Field_Types_Enum.Text,
  Field_Types_Enum.Date,
  Field_Types_Enum.Number,
  Field_Types_Enum.Url,
  Field_Types_Enum.Email,
  Field_Types_Enum.Select,
];

function getFieldTypeOptions(t: TFunction<'translation'>) {
  return orderedFieldTypes.map((type) => getFieldTypeOption(t, type));
}

function getFieldTypeOption(t: TFunction<'translation'>, type: Field_Types_Enum) {
  return {
    value: type,
    label: t(`customFields.modal.fields.type.values.${type}`),
  };
}

export const FieldConfigModal = ({
  entityName,
  editingFieldSchema,
  isOpen,
  onClose,
  onSubmit,
}: {
  entityName: Field_Entities_Enum;
  editingFieldSchema?: FieldSchema;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: FieldSchema) => void;
}) => {
  const { t } = useTranslation();
  const actionType = editingFieldSchema ? 'update' : 'create';

  const [fieldType, setFieldType] = useState(
    editingFieldSchema?.fieldType || Field_Types_Enum.Text,
  );

  const textFieldForm = useForm<TextFieldSchema>({
    resolver: zodResolver(textFieldSchema),
    defaultValues:
      editingFieldSchema?.fieldType === Field_Types_Enum.Text
        ? editingFieldSchema
        : defaultTextValues,
  });

  const urlFieldForm = useForm<UrlFieldSchema>({
    resolver: zodResolver(urlFieldSchema),
    defaultValues:
      editingFieldSchema?.fieldType === Field_Types_Enum.Url
        ? editingFieldSchema
        : defaulturlValues,
  });

  const dateFieldForm = useForm<DateFieldSchema>({
    resolver: zodResolver(dateFieldSchema),
    defaultValues:
      editingFieldSchema?.fieldType === Field_Types_Enum.Date
        ? editingFieldSchema
        : defaultDateValues,
  });

  const numberFieldForm = useForm<NumberFieldSchema>({
    resolver: zodResolver(numberFieldSchema),
    defaultValues:
      editingFieldSchema?.fieldType === Field_Types_Enum.Number
        ? editingFieldSchema
        : defaultNumberValues,
  });

  const emailFieldForm = useForm<EmailFieldSchema>({
    resolver: zodResolver(emailFieldSchema),
    defaultValues:
      editingFieldSchema?.fieldType === Field_Types_Enum.Email
        ? editingFieldSchema
        : defaultEmailValues,
  });

  const selectFieldForm = useForm<SelectFieldSchema>({
    resolver: zodResolver(selectFieldSchema),
    defaultValues:
      editingFieldSchema?.fieldType === Field_Types_Enum.Select
        ? editingFieldSchema
        : defaultSelectValues,
  });

  useEffect(() => {
    if (!isOpen) {
      textFieldForm.reset(defaultTextValues);
      urlFieldForm.reset(defaulturlValues);
      dateFieldForm.reset(defaultDateValues);
      numberFieldForm.reset(defaultNumberValues);
      emailFieldForm.reset(defaultEmailValues);
      selectFieldForm.reset(defaultSelectValues);
    }
  }, [
    isOpen,
    textFieldForm,
    selectFieldForm,
    urlFieldForm,
    dateFieldForm,
    numberFieldForm,
    emailFieldForm,
  ]);

  const isSubmitting =
    textFieldForm.formState.isSubmitting ||
    urlFieldForm.formState.isSubmitting ||
    dateFieldForm.formState.isSubmitting ||
    numberFieldForm.formState.isSubmitting ||
    emailFieldForm.formState.isSubmitting ||
    selectFieldForm.formState.isSubmitting;

  const isDirty = (() => {
    switch (fieldType) {
      case Field_Types_Enum.Text:
        return textFieldForm.formState.isDirty;
      case Field_Types_Enum.Url:
        return urlFieldForm.formState.isDirty;
      case Field_Types_Enum.Date:
        return dateFieldForm.formState.isDirty;
      case Field_Types_Enum.Number:
        return numberFieldForm.formState.isDirty;
      case Field_Types_Enum.Email:
        return emailFieldForm.formState.isDirty;
      case Field_Types_Enum.Select:
        return selectFieldForm.formState.isDirty;
    }
  })();

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    switch (fieldType) {
      case Field_Types_Enum.Text: {
        textFieldForm.handleSubmit((data) => onSubmit({ fieldType, ...data }))();
        break;
      }

      case Field_Types_Enum.Url: {
        urlFieldForm.handleSubmit((data) => onSubmit({ fieldType, ...data }))();
        break;
      }

      case Field_Types_Enum.Date: {
        dateFieldForm.handleSubmit((data) => onSubmit({ fieldType, ...data }))();
        break;
      }

      case Field_Types_Enum.Number: {
        numberFieldForm.handleSubmit((data) => onSubmit({ fieldType, ...data }))();
        break;
      }

      case Field_Types_Enum.Email: {
        emailFieldForm.handleSubmit((data) => onSubmit({ fieldType, ...data }))();
        break;
      }

      case Field_Types_Enum.Select: {
        selectFieldForm.handleSubmit((data) => onSubmit({ fieldType, ...data }))();
        break;
      }
    }
  };

  const getFormContent = () => {
    switch (fieldType) {
      case Field_Types_Enum.Text:
        return (
          <FormProvider {...textFieldForm}>
            <Stack spacing={4}>
              <NameField />
              <ProgramsField entityName={entityName} />
            </Stack>
          </FormProvider>
        );
      case Field_Types_Enum.Url:
        return (
          <FormProvider {...urlFieldForm}>
            <Stack spacing={4}>
              <NameField />
              <ProgramsField entityName={entityName} />
            </Stack>
          </FormProvider>
        );
      case Field_Types_Enum.Date:
        return (
          <FormProvider {...dateFieldForm}>
            <Stack spacing={4}>
              <NameField />
              <ProgramsField entityName={entityName} />
            </Stack>
          </FormProvider>
        );
      case Field_Types_Enum.Number:
        return (
          <FormProvider {...numberFieldForm}>
            <Stack spacing={4}>
              <NameField />
              <ProgramsField entityName={entityName} />
            </Stack>
          </FormProvider>
        );
      case Field_Types_Enum.Email:
        return (
          <FormProvider {...emailFieldForm}>
            <Stack spacing={4}>
              <NameField />
              <ProgramsField entityName={entityName} />
            </Stack>
          </FormProvider>
        );
      case Field_Types_Enum.Select:
        return (
          <FormProvider {...selectFieldForm}>
            <Stack spacing={4}>
              <NameField />

              <FormControl isInvalid={!!selectFieldForm.formState.errors.options}>
                <FormLabel>{t('customFields.modal.fields.options.label')}:</FormLabel>
                <Controller
                  name="options"
                  control={selectFieldForm.control}
                  render={({ field }) => (
                    <EditableTag
                      isMulti
                      value={field.value.map((value) => ({ value, colorScheme: 'purple' }))}
                      getNewOptionData={(value) => ({ value, colorScheme: 'purple' })}
                      onChange={(values) => {
                        field.onChange(values.map(({ value }) => value));
                      }}
                    />
                  )}
                />
                <FormErrorMessage>
                  {selectFieldForm.formState.errors.options?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!selectFieldForm.formState.errors.isMulti}>
                <FormLabel>{t('customFields.modal.fields.isMulti.label')}:</FormLabel>
                <Switch {...selectFieldForm.register('isMulti')} />
                <FormErrorMessage>
                  {selectFieldForm.formState.errors.isMulti?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!selectFieldForm.formState.errors.isCreatable}>
                <FormLabel>{t('customFields.modal.fields.isCreatable.label')}:</FormLabel>
                <Switch {...selectFieldForm.register('isCreatable')} />
                <FormErrorMessage>
                  {selectFieldForm.formState.errors.isCreatable?.message}
                </FormErrorMessage>
              </FormControl>

              <ProgramsField name="program_ids" entityName={entityName} />
            </Stack>
          </FormProvider>
        );
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(`customFields.modal.${actionType}.heading`)}</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <FormControl isDisabled={actionType === 'update'} paddingBottom={4}>
              <FormLabel>{t('customFields.modal.fields.type.label')}:</FormLabel>
              <Select
                options={getFieldTypeOptions(t)}
                value={getFieldTypeOption(t, fieldType)}
                onChange={(value) => value && setFieldType(value.value)}
                useBasicStyles
              />
            </FormControl>

            {getFormContent()}
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button isDisabled={isSubmitting} onClick={onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={!isDirty}
              >
                {t(`buttons.${actionType}`)}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const NameField = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<FieldSchema>();

  return (
    <FormControl isInvalid={!!errors.name}>
      <FormLabel>{t('customFields.modal.fields.name.label')}:</FormLabel>
      <Input
        type="text"
        placeholder={t('customFields.modal.fields.name.placeholder')}
        {...register('name')}
      />
      <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
    </FormControl>
  );
};

const ProgramsField = ({ entityName }: { name?: string; entityName: Field_Entities_Enum }) => {
  const programs = useAppSelector(getMappedOrgPrograms);
  const programsMap = useAppSelector(getOrgProgramsMap);
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useFormContext<FieldSchema>();

  const [isSwitchOn, setIsSwitchOn] = useState(false);

  // program switch "on" in edit mode if there are selected programs
  useEffect(() => {
    const selectedPrograms = getValues('program_ids');
    if (selectedPrograms && selectedPrograms?.length > 0) {
      setIsSwitchOn(true);
    }
  }, [getValues]);

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsSwitchOn(isChecked);

    if (!isChecked) {
      setValue('program_ids', [], { shouldDirty: true });
    }
  };

  if (entityName !== Field_Entities_Enum.Control) {
    return null;
  }

  return (
    <>
      <FormControl>
        <FormLabel>{t('customFields.modal.fields.controls.programsSwitch')}:</FormLabel>
        <Switch isChecked={isSwitchOn} onChange={handleSwitchChange} />
      </FormControl>
      {isSwitchOn && (
        <FormControl isInvalid={!!errors.program_ids}>
          <FormLabel>{t('customFields.modal.fields.controls.programs')}:</FormLabel>
          <Controller
            name="program_ids"
            control={control}
            render={({ field }) => (
              <EditableTag
                isMulti
                options={programs.map((program) => ({
                  value: program.id,
                  label: program.name,
                  colorScheme: program.colorScheme,
                }))}
                value={field.value?.map((value) => ({
                  value,
                  label: programsMap.get(value)?.name,
                  colorScheme: programsMap.get(value)?.colorScheme,
                }))}
                onChange={(values) => {
                  field.onChange(values.map(({ value }) => value));
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.program_ids?.message}</FormErrorMessage>
        </FormControl>
      )}
    </>
  );
};

import { useUpdateVendorMutation } from '@main/graphql/mutations/UpdateVendor.generated';
import { Vendors_Constraint, Vendors_Update_Column } from '@main/graphql/types.generated';
import { EditableTag } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { getVendorTiers } from './slice';
import { useSelectHandler } from './use-udpate-vendor-handler';
import { useCreateVendorTierMutation, useLazyGetVendorTiersQuery } from './VendorTiers.generated';

export const VendorTiers = ({ vendorId }: { vendorId: string }) => {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const tiers = useAppSelector((state) => getVendorTiers(state, vendorId));
  const [searchTiers] = useLazyGetVendorTiersQuery();
  const getActions = useGetVendorTierActions();
  const getHandler = useSelectHandler();
  const searchCustomTiersHandler = async (inputValue: string) => {
    const { organization_vendor_tiers } = await searchTiers({
      organizationId,
      ...(inputValue?.trim() ? { nameFilter: { _ilike: `%${inputValue}%` } } : {}),
    }).unwrap();

    return (
      organization_vendor_tiers.map((tier) => ({
        value: tier.id,
        label: tier.name,
        colorScheme: 'purple',
      })) || []
    );
  };

  return (
    <EditableTag
      debounceMs={500}
      value={tiers}
      loadOptions={searchCustomTiersHandler}
      onChange={getHandler({
        actions: getActions({
          vendorId,
        }),
        vendorId,
      })}
      getNewOptionData={(label) => ({ label, value: 'new', id: 'new', colorScheme: 'purple' })}
      variant="inline"
      placeholder={t('vendors.props.placeholder.tier')}
    />
  );
};

export const useGetVendorTierActions = () => {
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const [createVendorTier] = useCreateVendorTierMutation();
  const [updateVendor] = useUpdateVendorMutation();

  return useCallback(
    ({ vendorId }: { vendorId: string }) => ({
      create: (name: string) => {
        return createVendorTier({
          input: {
            name,
            organization_id: organizationId,
            vendors: {
              data: [{ id: vendorId, organization_id: organizationId }],
              on_conflict: {
                constraint: Vendors_Constraint.VendorsPkey,
                update_columns: [Vendors_Update_Column.TierId],
              },
            },
          },
        });
      },
      select: (id: string) => {
        return updateVendor({
          id: vendorId,
          vendor_input: {
            tier_id: id,
          },
        });
      },
      remove: () => {
        return updateVendor({
          id: vendorId,
          vendor_input: {
            tier_id: null,
          },
        });
      },
    }),
    [createVendorTier, organizationId, updateVendor],
  );
};

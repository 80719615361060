import { Button, Card, Flex, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import {
  errorToast,
  NoPermissionPlaceholder,
  successToast,
  Table,
  TableEmptyState,
  TableItem,
} from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { EntitySearch } from '../link-entity/entity-search';
import { useLinkTaskToRiskMutation } from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { useTaskColumnHelper } from '../tasks/table-columns';
import { useGetTasksSubscription } from '../tasks/tasks-subscription';
import { useUnlinkTask } from '../tasks/unlink-task';
import { useLinkTaskHandler } from '../tasks/use-link-task-handler';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { useCreateRiskTaskMutation } from './tasks-tab.generated';

export const RiskTasksTab = ({ riskId }: { riskId: string }) => {
  const { t } = useTranslation();
  const [newTaskId, setNewTaskId] = useState<string>();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateRisks = userRole.permissionMap?.write_risks;
  const canCreateTask = userRole.permissionMap?.write_tasks;
  const canViewTask = userRole.permissionMap?.read_tasks;
  const [createTask, { isLoading: isTaskCreationLoading }] = useCreateRiskTaskMutation();
  const columns = useRiskTaskTableColumns(riskId, newTaskId);

  const taskSearch = useEntitySearch({
    entities: [SearchEntitiesEnum.Tasks],
  });

  const [linkTaskToRisk, { isLoading: isLinkingTask }] = useLinkTaskToRiskMutation();
  const linkHandler = useLinkTaskHandler({ callback: () => taskSearch.disclosure.onClose?.() });

  const { data, isLoading: isTaskLoading } = useGetTasksSubscription({
    where: {
      risks: {
        risk_id: {
          _eq: riskId,
        },
      },
    },
  });

  const createTaskHandler = async () => {
    try {
      const { insert_risk_tasks_one } = await createTask({
        input: {
          risk_id: riskId,
          task: {
            data: {
              organization_id: orgId,
            },
          },
        },
      }).unwrap();

      setNewTaskId(insert_risk_tasks_one?.task_id);
      successToast(t('successMessages.createSucceeded', { entity: t('entities.task') }));
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.task') }));
      datadogLogs.logger.error('Failed to create risk task', {}, toError(error));
    }
  };

  const tableItemName = useMemo((): TableItem => {
    return {
      singular: t('entities.task').toLowerCase(),
      plural: t('entities.plural.tasks').toLowerCase(),
    };
  }, [t]);

  if (!canViewTask) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <Flex direction="column" justify="center" gap="8">
      <Card variant="table-styles">
        <Table
          minW="400px"
          entity="task"
          data={data?.tasks ?? []}
          isLoading={isTaskLoading}
          columns={columns}
          itemName={tableItemName}
          renderEmptyState={(props) => (
            <TableEmptyState
              {...props}
              subHeading={
                canCreateTask && t('table.clickButtonBelowToAdd', { item: tableItemName.singular })
              }
            />
          )}
        />
      </Card>
      {canUpdateRisks && (
        <Menu>
          <MenuButton
            as={Button}
            isLoading={isTaskCreationLoading}
            rightIcon={<Icon as={ChevronDownIcon} />}
            colorScheme="blue"
            alignSelf="end"
          >
            {t('tasks.addTask')}
          </MenuButton>
          <MenuList>
            {canCreateTask && (
              <MenuItem onClick={createTaskHandler}>{t('tasks.addNewTask')}</MenuItem>
            )}
            <MenuItem onClick={() => taskSearch.disclosure.onOpen?.()}>
              {t('tasks.linkExistingTask')}
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      <LinkEntityModal
        isOpen={taskSearch.disclosure.isOpen}
        onClose={() => taskSearch.disclosure.onClose?.()}
        entityName="task"
        onLinkEntity={() =>
          linkHandler(
            linkTaskToRisk({
              input: taskSearch.selectedResults.map((result) => ({
                risk_id: riskId,
                task_id: result.id,
              })),
            }),
          )
        }
        isLinkingEntity={isLinkingTask}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.task').toLowerCase(),
            }),
          }}
          {...taskSearch}
        />
      </LinkEntityModal>
    </Flex>
  );
};

const useRiskTaskTableColumns = (riskId: string, newTaskId: string | undefined) => {
  const columnHelper = useTaskColumnHelper();
  const unlinkTask = useUnlinkTask();

  return useMemo(() => {
    return [
      columnHelper.status(),
      columnHelper.name({ isEditable: true, newTaskId }),
      columnHelper.dueDate({ isEditable: true }),
      columnHelper.owner({ isEditable: true }),
      columnHelper.actions({
        onUnlink: (task) => unlinkTask({ taskId: task.id, linkedEntity: 'risk', entityId: riskId }),
      }),
    ].filter(isNonNullable);
  }, [columnHelper, newTaskId, unlinkTask, riskId]);
};

import { Flex, Icon, IconButton, useDisclosure, Wrap } from '@chakra-ui/react';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import { GetProgramsQuery } from '@main/graphql/queries/GetPrograms.generated';
import { useGetProgramsQuerySubscription } from '@main/graphql/subscriptions/GetProgramsSubscription';
import { DashboardCard, ProgressStatCard } from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

import { CreateProgramModal } from '../../features/program/create-program-options';
import {
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../../features/user/slice';
import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGetDashboardProgramSummaryQuery } from './program-summary.generated';

export const ProgramSummary = () => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreatePrograms = userRole.permissionMap?.write_programs;
  const isAddingNewProgramAllowed = useFeatureFlagEnabled('program-creation') && canCreatePrograms;

  const { data: programsData } = useGetProgramsQuerySubscription({ orgId });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const changeDrawerState = () => onOpen();

  return (
    <>
      <DashboardCard>
        <DashboardCard.Header
          heading={t('dashboard.programs.heading')}
          subheading={t('dashboard.programs.subheading')}
        >
          {({ header }) => (
            <Flex justifyContent="space-between">
              {header}
              {isAddingNewProgramAllowed && (
                <IconButton
                  onClick={changeDrawerState}
                  rounded="4"
                  icon={<Icon as={PlusSmallIcon} boxSize="6" />}
                  aria-label="Create new program"
                />
              )}
            </Flex>
          )}
        </DashboardCard.Header>
        <DashboardCard.Body>
          <Wrap overflow="visible">
            {programsData?.programs.map((program: GetProgramsQuery['programs'][number]) => (
              <ProgramSummaryCard
                programId={program.id}
                programName={program.name}
                key={program.id}
              />
            ))}
          </Wrap>
        </DashboardCard.Body>
      </DashboardCard>

      <CreateProgramModal isModalOpen={isOpen} onModalClose={onClose} />
    </>
  );
};

const ProgramSummaryCard: React.FC<{ programId: string; programName: string }> = ({
  programId,
  programName,
}) => {
  const navigate = useNavigate();
  const { data: programData } = useGetDashboardProgramSummaryQuery(
    { programId },
    { refetchOnMountOrArgChange: true },
  );
  const countControls = programData?.allControls.aggregate?.count || 0;
  const countValidControls = programData?.validControls.aggregate?.count || 0;
  const openProgram = () =>
    navigate({
      to: '/programs/$programId',
      params: { programId },
    });

  const percentage = countValidControls > 0 ? (countValidControls / countControls) * 100 : 0;

  return (
    <ProgressStatCard onClick={openProgram} progressPercentage={percentage} title={programName} />
  );
};

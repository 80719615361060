import { Finding_Causes_Enum } from '@main/graphql/types.generated';

import { ControlFinding } from '../../slice';
import { ContentAiRecommendation } from './content-ai-recommendation';
import { ContentEvidenceStatus } from './content-evidence-status';
import { ContentIntegrationCheck } from './content-integration-check';
import { ContentIntegrationError } from './content-integration-error';
import { ContentPolicyError } from './content-policy-error';

export const ControlFindingContent = (props: ControlFinding) => {
  switch (props.cause) {
    case Finding_Causes_Enum.AiRecommendation:
      return <ContentAiRecommendation {...props} />;
    case Finding_Causes_Enum.AtRiskEvidence:
    case Finding_Causes_Enum.ExpiredEvidence:
      return <ContentEvidenceStatus {...props} />;
    case Finding_Causes_Enum.IntegrationError:
      return <ContentIntegrationError {...props} />;
    case Finding_Causes_Enum.IntegrationCheck:
      return <ContentIntegrationCheck {...props} />;
    case Finding_Causes_Enum.PolicyError:
      return <ContentPolicyError {...props} />;
  }
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GenIntegrationRunReportMutationVariables = Types.Exact<{
  integrationRunId: Types.Scalars['uuid']['input'];
}>;

export type GenIntegrationRunReportMutation = {
  __typename?: 'mutation_root';
  gen_integration_run_report?: {
    __typename?: 'GenIntegrationRunReportOutput';
    reportFileId: string;
  };
};

export const GenIntegrationRunReportDocument = `
    mutation GenIntegrationRunReport($integrationRunId: uuid!) {
  gen_integration_run_report(input: {integrationRunId: $integrationRunId}) {
    reportFileId
  }
}
    ` as string &
  TypedDocumentNode<GenIntegrationRunReportMutation, GenIntegrationRunReportMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GenIntegrationRunReport: build.mutation<
      GenIntegrationRunReportMutation,
      GenIntegrationRunReportMutationVariables
    >({
      query: (variables) => ({ document: GenIntegrationRunReportDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGenIntegrationRunReportMutation } = injectedRtkApi;

import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useCreateControlMutation } from '@main/graphql/mutations/CreateControl.generated';
import { toError } from '@main/shared/utils';
import {
  errorToast,
  successToast,
  Table,
  useDrawer,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgRole,
} from '../../features/user/slice';
import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGetOrganizationControls } from './org-controls-query';
import { useControlsTableSettings } from './table-settings';
import { useControlsTableColumns } from './use-table-columns';

export const ControlCenter = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canWriteControl = userRole.permissionMap?.create_controls;
  const canEditControl = userRole.permissionMap?.update_controls;

  const { columnVisibility, setColumnVisibility } = useControlsTableSettings(
    'controls:table:column-visibility',
  );
  const columns = useControlsTableColumns({
    page: 'control-center',
  });

  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'controlsFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: 'controlsSort' });

  const [createControl, { isLoading: isCreatingControl }] = useCreateControlMutation();
  const { controls, isLoading: isLoadingControls } = useGetOrganizationControls(organization.id);

  const onCreateControl = async () => {
    try {
      const controlDrawer = await drawer.openLoading({ entity: 'control' });

      const response = await createControl({
        controlInput: {
          organization_id: organization.id,
        },
      }).unwrap();

      if (!response.create_control?.id) {
        throw new Error('Could not get new control id from response');
      }

      controlDrawer.load({ entityId: response.create_control.id });
      successToast(t('successMessages.createSucceeded', { entity: t('entities.control') }));
    } catch (error) {
      drawer.close();
      errorToast(t('errorMessages.createFailed', { entity: t('entities.control') }));
      datadogLogs.logger.error('Creating new control failed', {}, toError(error));
    }
  };

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.control').toLowerCase(),
      plural: t('entities.plural.controls').toLowerCase(),
    };
  }, [t]);

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('controls.center.heading')}
      </Text>
      <Card variant="table-styles">
        <CardHeader>
          <Box>
            <Heading size="md">{t('entities.plural.controls')}</Heading>
            <Text variant="subheading">{t('controls.subheading.organization')}</Text>
          </Box>
          {canWriteControl && canEditControl && (
            <Button
              colorScheme="blue"
              isLoading={isCreatingControl}
              onClick={onCreateControl}
              leftIcon={<Icon as={PlusIcon} />}
            >
              {t('controls.add.button.newCustomControl')}
            </Button>
          )}
        </CardHeader>

        <CardBody>
          <Table
            isLoading={isLoadingControls}
            minW="900px"
            entity="control"
            data={controls}
            columns={columns}
            itemName={tableItemName}
            pageSize={15}
            columnVisibility={columnVisibility}
            onColumnVisibilityChange={setColumnVisibility}
            columnFilters={columnFilters}
            onColumnFiltersChange={setColumnFilters}
            globalFilter={globalFilter}
            onGlobalFilterChange={setGlobalFilter}
            sorting={sorting}
            onSortingChange={setSorting}
          />
        </CardBody>
      </Card>
    </Stack>
  );
};

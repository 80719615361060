import { Button, Input, Stack, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useInviteUserMutation } from '@main/graphql/mutations/InviteUser.generated';
import { useGetOrganizationInvitationsQuery } from '@main/graphql/queries/GetOrganizationInvitations.generated';
import { useGetOrganizationRolesQuery } from '@main/graphql/queries/GetOrganizationRoles.generated';
import { UserInviteResult } from '@main/graphql/types.generated';
import { isGraphqlError, toError } from '@main/shared/utils';
import { errorToast, Select, successToast } from '@main/ui';
import { KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserId, getCurrentUserSelectedOrgId } from '../../../../user/slice';
import { ROLES_MAP } from '../../../constants';

type InvitationFormProps = {
  onModalClose: (userinviteResultType?: UserInviteResult) => void;
};

export const InvitationForm = ({ onModalClose }: InvitationFormProps) => {
  const { t } = useTranslation();

  const currentUserId = useAppSelector(getCurrentUserId);
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const { data: orgRoles } = useGetOrganizationRolesQuery({ organizationId });
  const { refetch: refetchInvitations } = useGetOrganizationInvitationsQuery({
    orgId: organizationId,
  });

  const [emailInput, setEmailInput] = useState<string>('');
  const [roleId, setRoleId] = useState<string | undefined>();

  const isValidEmail = z.string().email().safeParse(emailInput).success;
  const isSendingBlocked = !isValidEmail || !roleId;

  const [sendInvite, { isLoading: isSending, error: sendingError }] = useInviteUserMutation();

  const getOptions = () => {
    return orgRoles?.roles.map((role) => ({
      value: role.id,
      label: role.system_role ? ROLES_MAP[role.system_role].label : role.name,
    }));
  };

  const onSendInviteClick = async () => {
    if (isSendingBlocked) {
      return;
    }

    try {
      const result = await sendInvite({
        object: {
          organizationId,
          email: emailInput,
          invitingUserId: currentUserId,
          roleId,
        },
      }).unwrap();

      refetchInvitations();
      onModalClose(result.invite_user?.type);

      successToast(result.invite_user?.message || 'Invitation sent');
    } catch (error) {
      if (isGraphqlError(error)) {
        switch (error[0]?.extensions.code) {
          case 'USER_ALREADY_INVITED':
            errorToast(t(`invitation.errors.userAlreadyInvited`));
            return;
          case 'USER_ALREADY_EXISTS':
            errorToast(t(`invitation.errors.userAlreadyExists`));
            return;
          default:
            errorToast(t('invitation.errors.unknown'));
            break;
        }
      } else {
        errorToast(t('invitation.errors.unknown'));
      }
      datadogLogs.logger.error('Failed to send invitation.', {}, toError(error));
    }
  };

  const onEnterKey: KeyboardEventHandler = (e) => {
    if (e.key !== 'Enter') {
      return;
    }

    onSendInviteClick();
  };

  return (
    <Stack spacing={2}>
      <Text fontSize="lg" fontWeight="semibold">
        {t('invitation.form.label')}
      </Text>
      <Stack spacing={4} direction="column">
        <Input
          placeholder={t('invitation.form.placeholder')}
          isInvalid={emailInput.length > 0 && (!isValidEmail || !!sendingError)}
          onChange={(e) => setEmailInput(e.currentTarget.value)}
          onKeyDown={onEnterKey}
        />

        <Select
          onChange={(e) => setRoleId(e?.value)}
          options={getOptions()}
          placeholder={t('invitation.selectRolePlaceholder')}
          useBasicStyles
          aria-label={t('invitation.selectRolePlaceholder')}
        />
        <Button
          alignSelf="end"
          isLoading={isSending}
          loadingText="Invite"
          isDisabled={isSendingBlocked}
          onClick={onSendInviteClick}
          leftIcon={<EnvelopeIcon width={15} height={15} />}
          colorScheme="blue"
        >
          {t('buttons.invite')}
        </Button>
      </Stack>
    </Stack>
  );
};

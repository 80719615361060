import { Icon } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useDeleteOrganizationInvitationMutation } from '@main/graphql/mutations/DeleteOrganizationInvitation.generated';
import {
  GetOrganizationInvitationsQuery,
  useGetOrganizationInvitationsQuery,
} from '@main/graphql/queries/GetOrganizationInvitations.generated';
import { toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  useAlertDialog,
  useTableFiltersQuery,
  useTableSearchQuery,
} from '@main/ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../../../../user/slice';
import { getRoleTagConfig } from '../../../constants';

type Invitation = GetOrganizationInvitationsQuery['invitations'][number];

export const InvitationsTable = () => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  const { data: invitations, isLoading: isInvitationLoading } = useGetOrganizationInvitationsQuery(
    { orgId },
    {
      selectFromResult: (result) => ({
        ...result,
        data: result.data?.invitations || [],
      }),
    },
  );

  const columns = useInvitationsTableColumns();
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'invitationsFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });

  return (
    <Table
      minW="400px"
      data={invitations}
      isLoading={isInvitationLoading}
      columns={columns}
      itemName={{
        singular: t('entities.invitation').toLowerCase(),
        plural: t('entities.plural.invitations').toLowerCase(),
      }}
      pageSize={15}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
    />
  );
};

function useInvitationsTableColumns() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canInviteUser = userRole.permissionMap?.write_roles;
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  const { refetch: refetchInvitations } = useGetOrganizationInvitationsQuery({ orgId });
  const [deleteInvitation] = useDeleteOrganizationInvitationMutation();

  const deleteHandler = useCallback(
    async (invitationId: string) => {
      try {
        await deleteInvitation({
          id: invitationId,
        }).unwrap();

        await refetchInvitations().unwrap();
        successToast(t('successMessages.deleteSucceeded', { entity: t('entities.invitation') }));
      } catch (error) {
        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.invitation') }));
        datadogLogs.logger.error('Invitation delete failed', {}, toError(error));
      }
    },
    [t, deleteInvitation, refetchInvitations],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<Invitation>();
    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('users.tableColumns.status'),
        accessorFn: () => ({
          value: 'Pending',
          colorScheme: 'gray',
        }),
        size: 120,
      }),
      columnHelper.columns.text({
        id: 'email',
        header: t('users.tableColumns.email'),
        accessorFn: ({ email }) => email,
        enableGlobalFilter: true,
      }),
      columnHelper.columns.tag({
        id: 'role',
        header: t('users.tableColumns.role'),
        accessorFn: ({ role }) => getRoleTagConfig(role),
        size: 100,
        enableGlobalFilter: true,
      }),
      columnHelper.columns.actions({
        size: 50,
        ...(canInviteUser && {
          secondaryActions: ({ row }) => [
            {
              icon: <Icon as={TrashIcon} />,
              label: t('buttons.delete'),
              onClick: () =>
                openDialog({
                  dialogHeader: t('invitation.alert.header'),
                  dialogContent: t('invitation.alert.content'),
                  cancelBtnLabel: t('invitation.alert.cancel'),
                  confirmAction: {
                    children: t('invitation.alert.delete'),
                    onClick: () => deleteHandler(row.original.id),
                  },
                }),
            },
          ],
        }),
      }),
    ];
  }, [t, canInviteUser, openDialog, deleteHandler]);
}

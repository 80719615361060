import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { CreateClientQuestionnaireModal } from './create-modal';
import { ClientQuestionnairesTable } from './table';

export function ClientQuestionnairesManagement() {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateQuestionnaires = !!userRole.permissionMap?.write_client_q;
  const createModalDisclosure = useDisclosure();

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('clientQuestionnaires.breadcrumbTitle')}
      </Text>

      <Card variant="table-styles">
        <CardHeader>
          <Box>
            <Heading size="md">{t('clientQuestionnaires.heading')}</Heading>
            <Text variant="subheading">{t('clientQuestionnaires.subheading')}</Text>
          </Box>
          {canCreateQuestionnaires && (
            <Button
              colorScheme="blue"
              variant="solid"
              leftIcon={<Icon as={PlusIcon} />}
              onClick={createModalDisclosure.onOpen}
            >
              {t('clientQuestionnaires.addButton')}
            </Button>
          )}
        </CardHeader>
        <CardBody>
          <ClientQuestionnairesTable />
        </CardBody>
      </Card>

      <CreateClientQuestionnaireModal {...createModalDisclosure} />
    </Stack>
  );
}

import { datadogLogs } from '@datadog/browser-logs';
import { useUpdateRiskMutation } from '@main/graphql/mutations/UpdateRisk.generated';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getRiskApi } from './get-risk.generated';

export function useUpdateRiskHandler(riskId?: string) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const refetchRiskQueries = useRefetchRiskQueries(riskId);

  return useCallback(
    async (resultPromise: ReturnType<ReturnType<typeof useUpdateRiskMutation>[0]>) => {
      dispatch(
        getRiskApi.util.updateQueryData(
          'GetRisks',
          { organization_id: organization.id },
          (draft) => {
            for (const risk of draft.risks) {
              if (risk.id === resultPromise.arg.originalArgs.id) {
                Object.assign(risk, resultPromise.arg.originalArgs.risk_input);
              }
            }
          },
        ),
      );

      try {
        await resultPromise.unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.risk') }));
        datadogLogs.logger.error('Risk update failed', {}, toError(error));
      } finally {
        await refetchRiskQueries();
      }
    },
    [dispatch, organization.id, refetchRiskQueries, t],
  );
}

export function useRefetchRiskQueries(riskId?: string) {
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  return useCallback(async () => {
    try {
      await Promise.all([
        dispatch(
          getRiskApi.endpoints.GetRisks.initiate(
            { organization_id: orgId },
            { subscribe: false, forceRefetch: true },
          ),
        ),
        riskId &&
          dispatch(
            getRiskApi.endpoints.GetRisk.initiate(
              { risk_id: riskId },
              { subscribe: false, forceRefetch: true },
            ),
          ),
      ]);
    } catch (error) {
      datadogLogs.logger.error('Risk list refetch failed', {}, toError(error));
    }
  }, [dispatch, orgId, riskId]);
}

import { ControlFinding } from '../../slice';
import { FindingDescription, FindingHeading } from './shared';

export const ContentAiRecommendation = ({ finding }: ControlFinding) => {
  return (
    <>
      <FindingHeading>{finding.control_ai_review_assessment?.criteria_heading}</FindingHeading>
      <FindingDescription>{finding.control_ai_review_assessment?.assessment}</FindingDescription>
    </>
  );
};

import { Button, ButtonGroup, Flex, Icon, IconButton, useColorModeValue } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId } from '../user/slice';
import { useUpdateNotificationMutation } from './update-notification.generated';

export const NotificationCenterActions = ({
  onOpenSettings,
  onMarkAllAsRead,
  onArchiveAll,
}: {
  onOpenSettings: () => void;
  onMarkAllAsRead?: () => void;
  onArchiveAll?: () => void;
}) => {
  const { t } = useTranslation();
  const actionButtonColor = useColorModeValue('gray.800', 'gray.300');

  return (
    <Flex borderTopWidth="1px" justifyContent="space-between" p={3}>
      <ButtonGroup spacing={3}>
        {onMarkAllAsRead && (
          <Button
            variant="outline"
            onClick={onMarkAllAsRead}
            textColor={actionButtonColor}
            fontSize="sm"
            fontWeight="medium"
          >
            {t('notification.actions.markAllAsRead')}
          </Button>
        )}

        {onArchiveAll && (
          <Button
            variant="outline"
            onClick={onArchiveAll}
            textColor={actionButtonColor}
            fontSize="sm"
            fontWeight="medium"
          >
            {t('notification.actions.archiveAll')}
          </Button>
        )}
      </ButtonGroup>
      <Link to="/settings/account/notifications" onClick={onOpenSettings}>
        <IconButton
          variant="outline"
          aria-label="Notification settings"
          icon={<Icon boxSize={5} as={Cog8ToothIcon} />}
        />
      </Link>
    </Flex>
  );
};

export const useMarkAllAsReadAction = () => {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  const [updateNotification] = useUpdateNotificationMutation();

  return useStableCallback(async () => {
    try {
      await updateNotification({
        where: {
          user_id: {
            _eq: userId,
          },
          read_at: {
            _is_null: true,
          },
        },
        input: {
          read_at: new Date().toISOString(),
        },
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error(
        'Marking notifications as read failed',
        { userId, value: new Date().toISOString() },
        toError(error),
      );
    }
  });
};

export const useToggleReadState = () => {
  const { t } = useTranslation();
  const [updateNotification] = useUpdateNotificationMutation();

  return useStableCallback(async (notificationId: string, value: string | null) => {
    try {
      await updateNotification({
        where: {
          id: {
            _eq: notificationId,
          },
        },
        input: {
          read_at: value,
        },
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error(
        'Updating notification failed',
        { notificationId, value },
        toError(error),
      );
    }
  });
};

export const useArchiveAllAction = () => {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  const [updateNotification] = useUpdateNotificationMutation();

  return useStableCallback(async () => {
    try {
      await updateNotification({
        where: {
          user_id: {
            _eq: userId,
          },
          archived_at: {
            _is_null: true,
          },
        },
        input: {
          archived_at: new Date().toISOString(),
        },
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error(
        'Archiving notifications failed',
        { userId, value: new Date().toISOString() },
        toError(error),
      );
    }
  });
};

export const useToggleArchiveState = () => {
  const { t } = useTranslation();
  const [updateNotification] = useUpdateNotificationMutation();

  return useStableCallback(async (notificationId: string, value: string | null) => {
    try {
      await updateNotification({
        where: {
          id: {
            _eq: notificationId,
          },
        },
        input: {
          archived_at: value,
        },
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.notification') }));
      datadogLogs.logger.error(
        'Updating notification failed',
        { notificationId, value },
        toError(error),
      );
    }
  });
};

import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { TaskStatus } from '@main/graphql/client-scalars';
import { DashboardCard, PieChart, PieDatum } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useMyTasksQuery } from '../dashboard/my-tasks.generated';
import { getGroupedTasks } from '../dashboard/slice';
import {
  getCurrentUserId,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { TASK_STATUSES } from './constants';
import { TasksTable } from './table';
import { useGetTasksSubscription } from './tasks-subscription';
import { useCreateTask } from './use-create-task';

export const TaskCenter = () => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateTask = userRole.permissionMap?.write_tasks;
  const { onTaskCreate, isCreatingTask } = useCreateTask();

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('tasks.taskCenter')}
      </Text>

      <TaskStatCards />

      <Card variant="table-styles">
        <CardHeader>
          <Box>
            <Heading size="md">{t('tasks.table.heading')}</Heading>
            <Text variant="subheading">{t('tasks.table.subheading')}</Text>
          </Box>
          {canCreateTask && (
            <Button
              leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />}
              colorScheme="blue"
              variant="solid"
              isLoading={isCreatingTask}
              onClick={onTaskCreate}
            >
              {t('tasks.addTask')}
            </Button>
          )}
        </CardHeader>

        <CardBody>
          <TasksTable />
        </CardBody>
      </Card>
    </Stack>
  );
};

export const TaskStatCards = () => {
  const { t } = useTranslation();
  const { totalTasks, statusMap } = useGetTaskStats();
  const myTasks = useGetMyTasks();
  const pieData = useMemo(
    () =>
      Array.from(statusMap).map(([status, count]) => ({
        label: t(`tasks.enum.status.${status}`),
        value: count,
        color: TASK_STATUSES[status].colorScheme,
      })) satisfies PieDatum[],
    [statusMap, t],
  );

  return (
    <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={{ base: 5, lg: 6 }}>
      <DashboardCard p={6}>
        <Center flexDirection="column" h="full">
          <Text fontWeight="semibold">{t('tasks.stats.totalTasks')}</Text>
          <Text fontSize="5xl" fontWeight="bold">
            {totalTasks}
          </Text>
        </Center>
      </DashboardCard>
      <DashboardCard p={6}>
        <Flex direction="column" h="full" gap={2}>
          <Text fontSize="md" fontWeight="semibold">
            {t('tasks.stats.tasksBreakdown')}
          </Text>

          <PieChart data={pieData} donutThickness={12}>
            <Flex alignItems="center" justifyContent="space-between" w="full">
              <PieChart.Legend direction="column" gap={2} />
              <Flex height={24} w={24}>
                <PieChart.Graph />
              </Flex>
            </Flex>
          </PieChart>
        </Flex>
      </DashboardCard>
      <DashboardCard p={6}>
        <Flex direction="column" h="full">
          <Text fontSize="md" fontWeight="semibold">
            {t('dashboard.myTasks.heading')}
          </Text>

          <Flex w="full" justifyContent="space-between">
            <Text fontSize="2xl" fontWeight="semibold">
              {myTasks.completed.length}/{myTasks.all.length}
            </Text>
            <CircularProgress
              color="green.400"
              value={(myTasks.completed.length / myTasks.all.length) * 100}
              size="105px"
              thickness={6}
            >
              <CircularProgressLabel fontSize="lg" fontWeight="semibold">
                {myTasks.all.length
                  ? Math.round((myTasks.completed.length / myTasks.all.length) * 100)
                  : 0}
                %
              </CircularProgressLabel>
            </CircularProgress>
          </Flex>
        </Flex>
      </DashboardCard>
    </SimpleGrid>
  );
};

const useGetMyTasks = () => {
  const userId = useAppSelector(getCurrentUserId);
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  useMyTasksQuery(
    { userId, organizationId },
    {
      pollingInterval: 5000,
    },
  );
  const tasks = useAppSelector(getGroupedTasks);

  return tasks;
};

const useGetTaskStats = () => {
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  return useGetTasksSubscription(
    {
      where: {
        organization_id: {
          _eq: orgId,
        },
      },
    },
    {
      selectFromResult: ({ data }) => {
        const statusMap = new Map([
          [TaskStatus.COMPLETED, 0],
          [TaskStatus.PENDING, 0],
          [TaskStatus.IN_PROGRESS, 0],
          [TaskStatus.OVERDUE, 0],
        ]);

        data?.tasks.forEach((task) => {
          statusMap.set(task.status, (statusMap.get(task.status) ?? 0) + 1);
        });

        return { totalTasks: data?.tasks.length ?? 0, statusMap };
      },
    },
  );
};

import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

import { GitHubAuthConfig } from './lib/github-client';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Github]: HandlerConfigGithub;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface HandlerConfigGithub extends GitHubAuthConfig {}
}

export const GithubConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Github> = {
  name: Integration_Names_Enum.Github,

  orgConfig: z.object({
    installationId: z.coerce.number().min(1).describe('Installation ID'),
    orgName: z.string().min(1).describe('GitHub organization name'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.OldestChange),
      repoName: z.string().min(1),
    }),
  ]),
};

import { Route } from '@tanstack/react-router';

import { ReportDetails } from '../../features/reports/report-details';
import { reportRoute } from './reports';

export const reportIdRoute = new Route({
  getParentRoute: () => reportRoute,
  path: '$reportId',
  component: ReportDetails,
});

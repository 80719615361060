import { Button, Card, HStack, Icon, Stack, Tooltip, useDisclosure } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowPathIcon, ArrowUpTrayIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { VendorAssessmentStatus } from '@main/graphql/client-scalars';
import { Vendor_Questionnaire_Statuses_Enum } from '@main/graphql/types.generated';
import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import {
  formatDate,
  getQuestionnaireType,
  isNonNullable,
  QUESTIONNAIRE_TYPES,
  toError,
  useStableCallback,
} from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  TableEmptyState,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { dateToMidnightUTC } from '../../utils/date';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { CreateQuestionnaireModal } from './create-questionnaire-modal';
import { useGetVendorQuery } from './get-vendor.generated';
import { useVQAllDownloader, useVQByIdDownloader } from './vendor-questionnaires/use-vq-downloader';
import {
  GetVendorQuestionnairesForViewQuery,
  useDeteleVendorQuestionnaireMutation,
  useUpdateVendorQuestionnaireMutation,
} from './vendor-questionnaires/vendor-questionnaires.generated';
import {
  api as getVendorQuestionnaireApi,
  useGetVendorQuestionnairesForViewSubscription,
} from './vendor-questionnaires/vendor-questionnaires.subs';

type VendorQuestionnaire = GetVendorQuestionnairesForViewQuery['vendor_questionnaires'][number];

export interface VendorQuestionnairesProps {
  vendorId: string;
}

export const VendorQuestionnairesTab = ({ vendorId }: VendorQuestionnairesProps) => {
  const { t } = useTranslation();
  const { isLoading, data } = useGetVendorQuestionnairesForViewSubscription({ vendorId });
  const { data: vendor } = useGetVendorQuery({ vendor_id: vendorId });
  const questionnaires = data?.vendor_questionnaires ?? [];
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;
  const canSendQuestionnaire =
    canUpdateVendor &&
    vendor?.vendors_by_pk?.assessment_status !== VendorAssessmentStatus.TERMINATED;
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();

  const columns = useVendorQuestionnairesColumns(vendorId);
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.questionnaire').toLowerCase(),
      plural: t('entities.plural.questionnaire').toLowerCase(),
    };
  }, [t]);

  const vqDownloader = useVQAllDownloader({ vendorIds: [vendorId] });
  const canExportAll =
    !!userRole.permissionMap.read_vendors &&
    questionnaires.some((vq) => vq.status === Vendor_Questionnaire_Statuses_Enum.Submitted);

  return (
    <>
      <Stack spacing={6}>
        <Card variant="table-styles" overflowY="hidden">
          <Table
            minW="700px"
            data={questionnaires}
            isLoading={isLoading}
            columns={columns}
            itemName={tableItemName}
            pageSize={15}
            renderEmptyState={(props) => (
              <TableEmptyState
                {...props}
                subHeading={
                  canUpdateVendor &&
                  t('table.clickButtonBelowToAdd', { item: tableItemName.singular })
                }
              />
            )}
          />
        </Card>
        <HStack alignSelf="end" mt="4">
          {canExportAll && (
            <Button
              leftIcon={<Icon w={4} h={4} as={ArrowUpTrayIcon} />}
              variant="outline"
              isDisabled={vqDownloader.isDownloading}
              onClick={vqDownloader.start}
            >
              {t('vendors.questionnaires.exportAllButton')}
            </Button>
          )}
          {canUpdateVendor && (
            <Tooltip
              hasArrow
              isDisabled={canSendQuestionnaire}
              label={t('vendors.questionnaires.reinstateTooltip')}
              shouldWrapChildren
              mt="3"
            >
              <Button
                leftIcon={
                  <Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />
                }
                colorScheme="blue"
                variant="solid"
                onClick={onCreateModalOpen}
                isDisabled={!canSendQuestionnaire}
              >
                {t('vendors.questionnaires.addButton')}
              </Button>
            </Tooltip>
          )}
        </HStack>
      </Stack>

      <CreateQuestionnaireModal
        vendorId={vendorId}
        isOpen={isCreateModalOpen}
        onClose={onCreateModalClose}
      />
    </>
  );
};

const useVendorQuestionnairesColumns = (vendorId: string) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openDialog } = useAlertDialog();
  const drawer = useDrawer();
  const { data: vendor } = useGetVendorQuery({ vendor_id: vendorId });
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const [updateVendorQuestionnaire] = useUpdateVendorQuestionnaireMutation();
  const [deteleVendorQuestionnaire] = useDeteleVendorQuestionnaireMutation();
  const vqDownloader = useVQByIdDownloader();
  const canUpdateVendor = userRole.permissionMap?.write_vendors;
  const canEditQuestionnaire =
    canUpdateVendor &&
    vendor?.vendors_by_pk?.assessment_status !== VendorAssessmentStatus.TERMINATED;

  const onDueDateUpdate = useStableCallback(
    async (vendorQuestionnaire: VendorQuestionnaire, dueDate: string) => {
      const adjustedDueDate = dateToMidnightUTC(dueDate);

      const patchResult = dispatch(
        getVendorQuestionnaireApi.util.updateQueryData(
          'GetVendorQuestionnairesForView',
          { vendorId },
          (draft) => {
            for (const draftVq of draft.vendor_questionnaires ?? []) {
              if (draftVq.id === vendorQuestionnaire.id) {
                draftVq.due_date = adjustedDueDate;
              }
            }
          },
        ),
      );

      try {
        await updateVendorQuestionnaire({
          vendorQuestionnaireId: vendorQuestionnaire.id,
          input: {
            due_date: adjustedDueDate,
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.questionnaire') }));
        datadogLogs.logger.error('Vendor questionnaire update failed', {}, toError(error));
        patchResult.undo();
      }
    },
  );

  const deleteQuestionnaire = useStableCallback(async (vendorQuestionnaireId: string) => {
    try {
      await deteleVendorQuestionnaire({ vendorQuestionnaireId }).unwrap();
      successToast(t('vendors.questionnaires.toasts.deleteSuccess'));
    } catch (error) {
      errorToast(t('vendors.questionnaires.toasts.deleteFailed'));
      datadogLogs.logger.error(
        'Vendor questionnaire request deletion failed',
        { vendorQuestionnaireId },
        toError(error),
      );
    }
  });

  const resendQuestionnaire = useStableCallback(async (vendorQuestionnaireId: string) => {
    try {
      await updateVendorQuestionnaire({
        vendorQuestionnaireId,
        input: { status: Vendor_Questionnaire_Statuses_Enum.Created },
      }).unwrap();
      successToast(t('vendors.questionnaires.toasts.resendSuccess'));
    } catch (error) {
      errorToast(t('vendors.questionnaires.toasts.resendFailed'));
      datadogLogs.logger.error(
        'Vendor questionnaire re-send failed',
        { vendorQuestionnaireId },
        toError(error),
      );
    }
  });

  return useMemo(() => {
    const columnHelper = createColumnHelper<VendorQuestionnaire>();

    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('vendors.questionnaires.tableColumns.status'),
        accessorFn: (data) => {
          const statusMeta = QUESTIONNAIRE_STATUSES[data.status];

          return {
            value: t(statusMeta.value),
            colorScheme: statusMeta?.colorScheme,
          };
        },
        size: 120,
      }),
      columnHelper.columns.tag({
        id: 'type',
        header: t('questionnaires.tableColumns.type'),
        accessorFn: ({ questionnaire }) => {
          const metaType = QUESTIONNAIRE_TYPES[getQuestionnaireType(questionnaire)];

          return {
            value: t(metaType.value),
            colorScheme: metaType.colorScheme,
          };
        },
        size: 110,
      }),
      columnHelper.columns.text({
        id: 'name',
        header: t('vendors.questionnaires.tableColumns.name'),
        accessorFn: (data) => data.questionnaire?.name,
        meta: {
          cell: {
            onClick: ({ row }) =>
              drawer.open({
                entity: 'vendor-questionnaire',
                entityId: row.original.id,
                activeTab:
                  row.original.status === Vendor_Questionnaire_Statuses_Enum.Submitted
                    ? 'submissions'
                    : undefined,
              }),
          },
        },
      }),
      columnHelper.columns.date({
        id: 'dueDate',
        header: t('vendors.questionnaires.tableColumns.dueDate'),
        accessorFn: (data) => formatDate(data.due_date),
        edit: {
          onChange: (row, value) => onDueDateUpdate(row.original, value),
          canEditGuard: () => !!canEditQuestionnaire,
        },
        size: 150,
        meta: {
          cell: {
            fontSize: 'xs',
            color: 'gray.500',
          },
        },
      }),
      columnHelper.columns.avatar({
        id: 'sentBy',
        header: t('vendors.questionnaires.tableColumns.sentBy'),
        variant: 'short',
        accessorFn: (data) => {
          return {
            id: data?.sent_by_user?.id ?? '',
            displayName: data?.sent_by_user?.displayName ?? 'Unknown',
          };
        },
        size: 80,
      }),
      canUpdateVendor
        ? columnHelper.columns.actions({
            size: 52,
            secondaryActions: ({ row }) =>
              [
                !!userRole.permissionMap.read_vendors &&
                row.original.status === Vendor_Questionnaire_Statuses_Enum.Submitted
                  ? {
                      icon: <Icon as={ArrowUpTrayIcon} />,
                      label: t('vendors.questionnaires.actions.exportAnswers'),
                      onClick: () => vqDownloader.start(row.original.id),
                    }
                  : undefined,
                !row.original.is_disabled
                  ? {
                      icon: <Icon as={ArrowPathIcon} />,
                      label: t('vendors.questionnaires.actions.resendQuestionnaire'),
                      onClick: () =>
                        openDialog({
                          dialogHeader: t('vendors.questionnaires.resendAlert.header'),
                          dialogContent: t('vendors.questionnaires.resendAlert.content'),
                          confirmAction: {
                            children: t('vendors.questionnaires.resendAlert.confirmLabel'),
                            colorScheme: 'blue',
                            onClick: () => resendQuestionnaire(row.original.id),
                          },
                        }),
                    }
                  : undefined,
                {
                  icon: <Icon as={TrashIcon} />,
                  label: t('vendors.questionnaires.actions.deleteQuestionnaire'),
                  onClick: () =>
                    openDialog({
                      dialogHeader: t('vendors.questionnaires.deleteAlert.header'),
                      dialogContent: t('vendors.questionnaires.deleteAlert.content'),
                      confirmAction: {
                        children: t('buttons.delete'),
                        onClick: () => deleteQuestionnaire(row.original.id),
                      },
                    }),
                },
              ].filter(isNonNullable),
          })
        : undefined,
    ].filter(isNonNullable);
  }, [
    t,
    canUpdateVendor,
    drawer,
    onDueDateUpdate,
    canEditQuestionnaire,
    userRole.permissionMap.read_vendors,
    vqDownloader,
    resendQuestionnaire,
    openDialog,
    deleteQuestionnaire,
  ]);
};

import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ArrowDownTrayIcon,
  EllipsisVerticalIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Policy_Version_Statuses_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  EmptyPlaceholder,
  errorToast,
  successToast,
  useAlertDialog,
  useDownloadStorageFile,
} from '@main/ui';
import { useFileUpload } from '@nhost/react';
import { format as bytesFormat } from 'bytes';
import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useUpdatePolicyVersionContentMutation } from './manage-policies.generated';
import { usePreferredPolicyVersion } from './use-preferred-policy-version';

export const PolicyUploadVersion = () => {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const currentVersion = usePreferredPolicyVersion();
  const download = useDownloadStorageFile();
  const [updateVersion] = useUpdatePolicyVersionContentMutation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditPolicy = userRole.permissionMap?.write_policies;
  const canDeleteCurrenVersion =
    canEditPolicy && currentVersion?.status === Policy_Version_Statuses_Enum.Draft;

  const deleteVersionUpload = async () => {
    if (!currentVersion) {
      return;
    }

    try {
      await updateVersion({
        policyVersionId: currentVersion.id,
        fileId: null,
      }).unwrap();
      successToast(t('policies.versions.deleteSucceeded'));
    } catch (error) {
      errorToast(t('policies.versions.deleteFailed'));
      datadogLogs.logger.error(
        'Deleting policy version file failed',
        { policyVersion: currentVersion.id },
        toError(error),
      );
    }
  };

  if (!currentVersion || !currentVersion.file) {
    return (
      <Flex direction="column" gap={6}>
        <EmptyPlaceholder border="1px" borderColor={borderColor} rounded={8}>
          <EmptyPlaceholder.Icon as={XMarkIcon} />
          <EmptyPlaceholder.Content>
            <EmptyPlaceholder.Heading>
              {t('policies.placeholder.noVersion')}
            </EmptyPlaceholder.Heading>
            {canEditPolicy && (
              <EmptyPlaceholder.Subheading>
                {t('policies.placeholder.clickToAddVersion')}
              </EmptyPlaceholder.Subheading>
            )}
          </EmptyPlaceholder.Content>
        </EmptyPlaceholder>

        {canEditPolicy && <VersionUploadButton />}
      </Flex>
    );
  }

  return (
    <Flex px={4} py={1} rounded={8} border="1px" borderColor={borderColor} alignItems="center">
      <Box flexGrow={1} py={2.5}>
        <Tag size="sm" colorScheme="purple">
          {t('policies.type.Upload')}
        </Tag>
        <Box mt={2} fontSize="sm">
          <Text fontWeight="medium">{currentVersion.file.name}</Text>
          <Text color="gray.500" mt={1.5}>
            {bytesFormat(currentVersion.file?.size || 0, {
              unitSeparator: ' ',
              decimalPlaces: 0,
            })}
          </Text>
        </Box>
      </Box>

      <Flex>
        <IconButton
          size="sm"
          variant="ghost"
          aria-label="Download"
          icon={<Icon as={ArrowDownTrayIcon} boxSize="4" />}
          onClick={() => download(currentVersion.file?.id as string)}
        />

        {canDeleteCurrenVersion && (
          <Menu>
            <MenuButton
              aria-label="menu-actions"
              as={IconButton}
              size="sm"
              variant="ghost"
              icon={<Icon as={EllipsisVerticalIcon} boxSize="4" />}
            />
            <MenuList>
              <MenuItem
                icon={<Icon as={TrashIcon} boxSize="4" />}
                iconSpacing={2}
                fontSize="sm"
                onClick={() =>
                  openDialog({
                    dialogHeader: t('policies.versions.alert.delete.header'),
                    dialogContent: t('policies.versions.alert.delete.content'),
                    confirmAction: {
                      children: t('policies.versions.alert.delete.confirm'),
                      onClick: deleteVersionUpload,
                    },
                  })
                }
              >
                {t('buttons.delete')}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
    </Flex>
  );
};

function VersionUploadButton() {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { upload, isUploading } = useFileUpload();
  const [updateVersion, { isLoading: isUpdatingVersion }] = useUpdatePolicyVersionContentMutation();
  const currentVersion = usePreferredPolicyVersion();

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!currentVersion || !event.target.files) {
      return;
    }

    try {
      const file = await upload({
        file: event.target.files[0],
      });

      await updateVersion({
        policyVersionId: currentVersion.id,
        fileId: file.id,
      }).unwrap();
      successToast(t('policies.versions.successfullyUploaded'));
    } catch (error) {
      errorToast(t('policies.versions.failedToUpload'));
      datadogLogs.logger.error(
        'Uploading policy version file failed',
        { policyVersion: currentVersion.id },
        toError(error),
      );
    }
  };

  return (
    <Flex justifyContent="flex-end">
      <Button
        colorScheme="blue"
        leftIcon={<Icon as={PlusIcon} />}
        onClick={() => inputRef.current?.click()}
        isLoading={isUploading || isUpdatingVersion}
      >
        {t('policies.addVersion')}
      </Button>
      <Input
        display="none"
        type="file"
        accept=".doc, .docx, .pdf"
        ref={inputRef}
        onChange={handleUpload}
      />
    </Flex>
  );
}

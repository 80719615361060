import { EvidenceIntegrationType, JumpcloudMdmCheckTypes } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Jumpcloud]: HandlerConfigJumpcloud;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigJumpcloud {}
}

export const JumpcloudConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Jumpcloud> = {
  name: Integration_Names_Enum.Jumpcloud,

  orgConfig: z.object({
    apiKey: z.string().min(1).describe('API Key'),
    organizationId: z.string().min(1).describe('Organization ID').optional(),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.MobileDeviceManagement),
      checks: z.array(
        z.object({
          type: z.nativeEnum(JumpcloudMdmCheckTypes),
        }),
      ),
    }),
  ]),
};

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type FieldValueFragment = {
  __typename?: 'field_values';
  field_config_id: string;
  entity_name: Types.Field_Entities_Enum;
  field_type: Types.Field_Types_Enum;
  text_field_value?: { __typename?: 'text_field_values'; field_value_id: string; value: string };
  url_field_value?: { __typename?: 'url_field_values'; field_value_id: string; value: string };
  date_field_value?: { __typename?: 'date_field_values'; field_value_id: string; value: string };
  number_field_value?: {
    __typename?: 'number_field_values';
    field_value_id: string;
    value: number;
  };
  email_field_value?: { __typename?: 'email_field_values'; field_value_id: string; value: string };
  select_field_value?: {
    __typename?: 'select_field_values';
    field_value_id: string;
    select_field_option_id: string;
  };
};

export type TextFieldValueFragment = {
  __typename?: 'text_field_values';
  field_value_id: string;
  value: string;
};

export type UrlFieldValueFragment = {
  __typename?: 'url_field_values';
  field_value_id: string;
  value: string;
};

export type DateFieldValueFragment = {
  __typename?: 'date_field_values';
  field_value_id: string;
  value: string;
};

export type NumberFieldValueFragment = {
  __typename?: 'number_field_values';
  field_value_id: string;
  value: number;
};

export type EmailFieldValueFragment = {
  __typename?: 'email_field_values';
  field_value_id: string;
  value: string;
};

export type SelectFieldValueFragment = {
  __typename?: 'select_field_values';
  field_value_id: string;
  select_field_option_id: string;
};

export const FieldValueFragmentDoc = `
    fragment FieldValue on field_values {
  field_config_id
  entity_name
  field_type
  text_field_value {
    field_value_id
    value
  }
  url_field_value {
    field_value_id
    value
  }
  date_field_value {
    field_value_id
    value
  }
  number_field_value {
    field_value_id
    value
  }
  email_field_value {
    field_value_id
    value
  }
  select_field_value {
    field_value_id
    select_field_option_id
  }
}
    `;
export const TextFieldValueFragmentDoc = `
    fragment TextFieldValue on text_field_values {
  field_value_id
  value
}
    `;
export const UrlFieldValueFragmentDoc = `
    fragment UrlFieldValue on url_field_values {
  field_value_id
  value
}
    `;
export const DateFieldValueFragmentDoc = `
    fragment DateFieldValue on date_field_values {
  field_value_id
  value
}
    `;
export const NumberFieldValueFragmentDoc = `
    fragment NumberFieldValue on number_field_values {
  field_value_id
  value
}
    `;
export const EmailFieldValueFragmentDoc = `
    fragment EmailFieldValue on email_field_values {
  field_value_id
  value
}
    `;
export const SelectFieldValueFragmentDoc = `
    fragment SelectFieldValue on select_field_values {
  field_value_id
  select_field_option_id
}
    `;

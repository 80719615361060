import { Route } from '@tanstack/react-router';

import { ClientQuestionnairesManagement } from '../../features/client-questionnaires/management';
import { authLayout } from '../login';

export const clientQuestionnairesRoute = new Route({
  getParentRoute: () => authLayout,
  path: 'client-questionnaires',
  component: ClientQuestionnairesManagement,
});

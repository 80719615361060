import {
  Box,
  Divider,
  Flex,
  LayoutProps,
  Tag,
  TagCloseButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  GlobalSearchIcon,
  GlobalSearchInput,
  GlobalSearchModalProps,
  GlobalSearchProvider,
} from '@main/ui';
import { PropsWithChildren } from 'react';

import { AppGlobalSearchResult } from '../global-search/use-global-search';
import { EntitySearchResults } from './entity-search-results';

export interface EntitySearchProps extends GlobalSearchModalProps<AppGlobalSearchResult> {
  overflow?: LayoutProps['overflowY'];
  selectedResults: AppGlobalSearchResult[];
}

export function EntitySearch(props: PropsWithChildren<EntitySearchProps>) {
  return (
    <GlobalSearchProvider {...props.provider}>
      <Flex direction="column" gap={4} overflowY={props.overflow ?? 'auto'}>
        <Box px={1.5}>
          <GlobalSearchInput
            autoSelect={false}
            fontSize="md"
            leftElement={() => <GlobalSearchIcon w={5} h={5} />}
            {...props.input}
          />
          <Box px={3}>
            <Divider borderColor={useColorModeValue('gray.200', 'gray.600')} />
          </Box>
        </Box>
        {props.selectedResults.length !== 0 && (
          <Flex flexWrap="wrap" gap={2} px={4}>
            {props.selectedResults.map((result) => (
              <Tag key={result.id} colorScheme="purple" justifyContent="space-between">
                <Text noOfLines={1}>{result.title}</Text>
                <TagCloseButton
                  onClick={() =>
                    props.provider.onResultsSelect?.(
                      props.selectedResults.filter((value) => value.id !== result.id),
                    )
                  }
                />
              </Tag>
            ))}
          </Flex>
        )}

        {props.children ?? <EntitySearchResults {...props.results} />}
      </Flex>
    </GlobalSearchProvider>
  );
}

import { Skeleton, useToast } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useAddControlCriteriaTagMutation } from '@main/graphql/mutations/AddControlCriteriaTag.generated';
import { useCreateCriteriaTagMutation } from '@main/graphql/mutations/CreateCriteriaTag.generated';
import { useRemoveControlCriteriaTagMutation } from '@main/graphql/mutations/RemoveControlCriteriaTag.generated';
import { useLazyGetCriteriaTagsQuery } from '@main/graphql/queries/GetCriteriaTags.generated';
import { toError } from '@main/shared/utils';
import { EditableTag } from '@main/ui';
import { ActionMeta, MultiValue } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg } from '../../user/slice';
import { api as getControlApi } from '../get-control.generated';
import { getMappedControl } from '../slice';

export const CriteriaTags = ({ controlId }: { controlId?: string }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const errorToast = useToast({
    status: 'error',
    duration: 5000,
    isClosable: true,
  });

  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const control = useAppSelector((state) => getMappedControl(state, controlId));
  const [getCriteriaTags] = useLazyGetCriteriaTagsQuery();
  const [createCriteriaTag] = useCreateCriteriaTagMutation();
  const [addControlCriteriaTag] = useAddControlCriteriaTagMutation();
  const [removeControlCriteriaTag] = useRemoveControlCriteriaTagMutation();

  if (!control || !controlId) {
    return <Skeleton h={10} w="240px" rounded="md" mt={2} />;
  }

  const searchCustomTagsHandler = async (input: string) => {
    const { criteria } = await getCriteriaTags({
      orgId: organization.id,
      ...(input?.trim() ? { nameFilter: { _ilike: `%${input}%` } } : {}),
    }).unwrap();

    return criteria.map((criteria) => ({
      value: criteria.id,
      label: criteria.name,
      colorScheme: 'purple',
    }));
  };

  const handler = async (
    resultPromise: ReturnType<
      ReturnType<
        | typeof useCreateCriteriaTagMutation
        | typeof useAddControlCriteriaTagMutation
        | typeof useRemoveControlCriteriaTagMutation
      >[0]
    >,
  ) => {
    try {
      await resultPromise.unwrap();
    } catch (error) {
      errorToast({ title: t('errorMessages.tagFailed', { entity: t('entities.criteria') }) });
      datadogLogs.logger.error('Unable to perform operation on tags.', {}, toError(error));
      return;
    }
  };

  const handleChange = async (
    items: MultiValue<(typeof control.tags.groups)[number]>,
    actionMeta: ActionMeta<(typeof control.tags.groups)[number]>,
  ) => {
    switch (actionMeta.action) {
      case 'create-option': {
        dispatch(
          getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
            if (draft.controls_by_pk) {
              draft.controls_by_pk.criterias.push({
                criteria: { id: 'new', name: actionMeta.option.label },
              });
            }
          }),
        );
        await handler(
          createCriteriaTag({
            object: {
              control_id: controlId,
              criteria: {
                data: {
                  name: actionMeta.option.label,
                  organization_id: organization.id,
                },
              },
            },
          }),
        );
        break;
      }
      case 'select-option': {
        dispatch(
          getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
            if (draft.controls_by_pk) {
              draft.controls_by_pk.criterias.push({
                criteria: {
                  id: actionMeta.option?.value as string,
                  name: actionMeta.option?.label as string,
                },
              });
            }
          }),
        );
        await handler(
          addControlCriteriaTag({ criteriaId: actionMeta.option?.value as string, controlId }),
        );
        break;
      }
      case 'remove-value':
      case 'pop-value': {
        dispatch(
          getControlApi.util.updateQueryData('GetControl', { controlId }, (draft) => {
            if (draft.controls_by_pk) {
              const remainingTags = draft.controls_by_pk.criterias.filter(
                ({ criteria }) => criteria.id !== actionMeta.removedValue.value,
              );
              draft.controls_by_pk.criterias = remainingTags;
            }
          }),
        );

        await handler(
          removeControlCriteriaTag({
            criteriaId: actionMeta.removedValue.value,
            controlId,
          }),
        );
        break;
      }
    }
    await dispatch(
      getControlApi.endpoints.GetControl.initiate(
        { controlId },
        { forceRefetch: true, subscribe: false },
      ),
    ).unwrap();
  };

  return (
    <EditableTag
      isMulti={true}
      debounceMs={500}
      value={control.tags.criteria}
      loadOptions={searchCustomTagsHandler}
      onChange={handleChange}
      getNewOptionData={(label) => ({ label, value: 'new', colorScheme: 'purple' })}
      variant="inline"
      placeholder={t('controls.placeholder.criteria')}
    />
  );
};

import { Card, CardBody, HStack, Select, usePrevious } from '@chakra-ui/react';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { isNonNullable } from '@main/shared/utils';
import { DashboardCard, EmptyPlaceholder, Table, TableEmptyState } from '@main/ui';
import { RouteApi } from '@tanstack/react-router';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useTaskColumnHelper } from '../tasks/table-columns';
import { getCurrentUserId, getCurrentUserSelectedOrgId } from '../user/slice';
import { useMyTasksQuery } from './my-tasks.generated';
import { getGroupedTasks, TaskGroup, taskGroups } from './slice';

const dashboardRouteApi = new RouteApi({ id: '/dashboard' });

export function MyTasks() {
  const { t } = useTranslation();
  const [tasksFilter, setTasksFilter] = useState<TaskGroup>('pending');

  const { drawerEntity } = dashboardRouteApi.useSearch({
    select: ({ drawerEntity }) => ({ drawerEntity }),
  });
  const previousEntity = usePrevious(drawerEntity);
  const userId = useAppSelector(getCurrentUserId);
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const columns = useTaskColumns();
  const { isLoading, refetch } = useMyTasksQuery(
    { userId, organizationId },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const tasks = useAppSelector(getGroupedTasks);

  useEffect(() => {
    if (!drawerEntity && previousEntity === 'task') {
      refetch();
    }
  }, [drawerEntity, previousEntity, refetch]);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.task').toLowerCase(),
      plural: t('entities.plural.tasks').toLowerCase(),
    };
  }, [t]);

  return (
    <DashboardCard>
      <DashboardCard.Header heading={t('dashboard.myTasks.heading')}>
        {({ header }) => (
          <HStack justifyContent="space-between" alignItems="center">
            {header}
            <Select
              variant="filled"
              maxW="177px"
              value={tasksFilter}
              onChange={(e) => setTasksFilter(e.target.value as TaskGroup)}
            >
              {taskGroups.map((group) => (
                <option key={group} value={group}>
                  {t(`dashboard.myTasks.filter.${group}`)}
                </option>
              ))}
            </Select>
          </HStack>
        )}
      </DashboardCard.Header>
      <DashboardCard.Body>
        {tasks[tasksFilter].length === 0 ? (
          <MyTasksEmpty />
        ) : (
          <Card variant="outline">
            <CardBody p={0} borderRadius="inherit" overflowX="auto">
              <Table
                minW="400px"
                entity="task"
                data={tasks[tasksFilter]}
                isLoading={isLoading}
                columns={columns}
                itemName={tableItemName}
                pageSize={4}
                renderEmptyState={(props) => <TableEmptyState {...props} />}
              />
            </CardBody>
          </Card>
        )}
      </DashboardCard.Body>
    </DashboardCard>
  );
}

function MyTasksEmpty() {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon as={ClipboardDocumentCheckIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('dashboard.myTasks.emptyState.heading')}
        </EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          {t('dashboard.myTasks.emptyState.subheading')}
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
}

const useTaskColumns = () => {
  const columnHelper = useTaskColumnHelper();

  return useMemo(() => {
    return [
      columnHelper.status(),
      columnHelper.name(),
      columnHelper.dueDate(),
      columnHelper.owner(),
      columnHelper.actions(),
    ].filter(isNonNullable);
  }, [columnHelper]);
};

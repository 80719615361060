import { Box, Stack, Text } from '@chakra-ui/react';
import { DynamicFieldWrapper, QuestionnaireFormComponentRegistry } from '@main/dynamic-form';
import {
  QuestionnaireFieldComment,
  QuestionnaireFieldFiles,
  QuestionnnaireFieldWrapperProps,
} from '@main/questionnaires-form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const FindingQuestionnnaireFieldWrapper: DynamicFieldWrapper<
  QuestionnaireFormComponentRegistry,
  QuestionnnaireFieldWrapperProps
> = (props) => {
  const { t } = useTranslation();

  const config = props.config;

  const answer = useMemo(
    () => props.form.answers?.find((answer) => answer.field_name === config.name),
    [config, props.form.answers],
  );

  if (!answer) {
    return null;
  }

  const isShowComment = !!answer.comment;
  const isShowFiles = !!answer.form_uploads?.length;

  return (
    <Stack>
      <Box>
        <Text fontWeight="semibold" fontSize="xs">
          {t('questionnaires.form.answerLabel')}
        </Text>
      </Box>
      <Box fontSize="sm" color="gray.600" data-testid={`answer:${config.label}`}>
        {props.children}
      </Box>
      {isShowComment && (
        <Box>
          <QuestionnaireFieldComment {...props} config={config} answer={answer} />
        </Box>
      )}
      {isShowFiles && (
        <Box>
          <QuestionnaireFieldFiles {...props} config={config} answer={answer} />
        </Box>
      )}
    </Stack>
  );
};

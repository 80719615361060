import {
  Box,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Icon,
  IconButton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ArchiveBoxIcon,
  BellIcon,
  InboxArrowDownIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline';
import { useDrawerAnimation } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId } from '../user/slice';
import { AllNotificationsTab, ArchiveTab, InboxTab } from './drawer-tabs';
import { useNotificationsSubscription } from './unread-notification-subscription';

export const NotificationCenter = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { motionProps } = useDrawerAnimation();
  const userId = useAppSelector(getCurrentUserId);
  const { data } = useNotificationsSubscription({ userId });
  const count = data?.user_notifications_aggregate.aggregate?.count ?? 0;

  const tabColor = useColorModeValue('gray.400', 'gray.500');
  const activeTabColor = useColorModeValue('gray.700', 'white');
  const activeTabIndicatorColor = useColorModeValue('blue.500', 'purple.400');
  const tabListBorderColor = useColorModeValue('gray.200', 'gray.600');
  const tabListBgColor = useColorModeValue('white', 'gray.700');

  return (
    <>
      <Center position="relative" cursor="pointer" onClick={onOpen} w={9} h={9}>
        <IconButton
          color="gray.500"
          variant="unstyled"
          size="xs"
          aria-label="Open notification drawer"
          icon={<Icon as={BellIcon} w={6} h={6} />}
        />
        {count ? (
          <Center
            position="absolute"
            bgColor="red.500"
            rounded="md"
            w="18px"
            h="18px"
            top={0}
            right={0}
            fontSize="10px"
            color="white"
          >
            {count}
          </Center>
        ) : null}
      </Center>
      <Drawer size="sm" isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent motionProps={motionProps}>
          <DrawerCloseButton />
          <DrawerHeader fontSize="xl" fontWeight="bold">
            {t('notification.header')}
          </DrawerHeader>
          <DrawerBody p={0}>
            <Tabs
              isLazy
              variant="unstyled"
              position="relative"
              isFitted
              display="flex"
              flexDirection="column"
              overflow="hidden"
              h="full"
            >
              <Box position="sticky" top={0} zIndex={1}>
                <TabList
                  px={5}
                  borderBottom="1px"
                  borderColor={tabListBorderColor}
                  bg={tabListBgColor}
                  position="relative"
                >
                  {notificationTabItems.map((tab) => (
                    <Tab
                      key={tab.label}
                      gap={2}
                      px={5}
                      fontSize="sm"
                      _selected={{ color: activeTabColor }}
                      textColor={tabColor}
                    >
                      <Icon boxSize={5} as={tab.icon} />
                      <Text>{t(`notification.tabs.${tab.label}`)}</Text>
                      {tab.label === 'inbox' && count ? (
                        <Text
                          bgColor="blue.500"
                          px="6.5px"
                          rounded="md"
                          color="white"
                          fontSize="xs"
                        >
                          {count}
                        </Text>
                      ) : null}
                    </Tab>
                  ))}
                </TabList>
                <TabIndicator mt="-2px" height="2px" bg={activeTabIndicatorColor} />
              </Box>
              <TabPanels flexGrow={1} overflow="hidden" overflowY="auto">
                <TabPanel p={0} h="full">
                  <InboxTab onClose={onClose} />
                </TabPanel>
                <TabPanel p={0} h="full">
                  <ArchiveTab onClose={onClose} />
                </TabPanel>
                <TabPanel p={0} h="full">
                  <AllNotificationsTab onClose={onClose} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const notificationTabItems = [
  {
    label: 'inbox',
    icon: InboxArrowDownIcon,
  },
  {
    label: 'archived',
    icon: ArchiveBoxIcon,
  },
  {
    label: 'all',
    icon: RectangleStackIcon,
  },
] as const;

import { Finding_Types_Enum } from '@main/graphql/types.generated';
import { Severity } from '@main/shared/utils';

export function findingTypeSeverity(findingType: Finding_Types_Enum): Severity {
  switch (findingType) {
    case Finding_Types_Enum.Consideration:
      return Severity.RECOMMENDATION;
    case Finding_Types_Enum.Recommendation:
      return Severity.MEDIUM;
    case Finding_Types_Enum.Issue:
      return Severity.HIGH;
  }
}

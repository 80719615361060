import { AnyFunction } from '@main/shared/types';

/**
 * Safely call a hook that may throw an error using a try-catch.
 */
export function useOptional<TFn extends AnyFunction>(
  hook: TFn,
  ...args: Parameters<TFn>
): ReturnType<TFn> | undefined {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return hook(...args);
  } catch {
    return;
  }
}

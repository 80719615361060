import { DownloadFile } from '@main/bulk-downloader';
import { useQuestionnaireComponentRegistry } from '@main/dynamic-form';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AppDownloadFile } from '../../bulk-downloader/app-stream-downloader';
import { FileQuestionnaireExporter } from './exporter/file-questionnaire-exporter';
import { FormQuestionnaireExporter } from './exporter/form-questionnaire-exporter';
import { VendorQuestionnaireForDownloadFragment } from './vq-files-fetcher.generated';

export interface QuestionnaireExporter {
  canExport(vq: VendorQuestionnaireForDownloadFragment): boolean;
  exportQuestionnaire(vq: VendorQuestionnaireForDownloadFragment): DownloadFile<AppDownloadFile>[];
}

export function useQuestionnaireExporters(): QuestionnaireExporter[] {
  const { t } = useTranslation();
  const { componentRegistry } = useQuestionnaireComponentRegistry();

  return useMemo(
    () => [new FileQuestionnaireExporter(), new FormQuestionnaireExporter(componentRegistry, t)],
    [componentRegistry, t],
  );
}

import { Button, useColorModeValue } from '@chakra-ui/react';
import { useFindingContext } from '@main/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ToggleFindingIgnoreStateButton = ({ onToggle }: { onToggle: () => Promise<void> }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const actionBtnColor = useColorModeValue('gray.500', 'gray.300');

  const { isIgnored } = useFindingContext();

  return (
    <Button
      variant="outline"
      size="sm"
      isLoading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        await onToggle();
        setIsLoading(false);
      }}
      color={actionBtnColor}
    >
      {isIgnored ? t('findings.card.reinstateBtn') : t('findings.card.ignoreBtn')}
    </Button>
  );
};

import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { EnvelopeIcon, PlusIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import { UserInviteResult } from '@main/graphql/types.generated';
import { UserTabKeys } from '@main/shared/utils';
import { getVisibleTabs, Tabs, useTabs } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../../../user/slice';
import { MainOrgBanner } from '../../main-org-banner';
import { InvitationForm } from './invitations/invite-form';
import { InvitationsTable } from './invitations/table';
import { UsersTable } from './table';

export interface UsersProps {
  isOpen: boolean;
  onClose(): void;
}

export const UsersPage = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canInviteUser = userRole.permissionMap?.write_roles;

  const tabs = useMemo(
    () =>
      getVisibleTabs<UserTabKeys>({
        users: {
          label: t('users.heading'),
          icon: UserGroupIcon,
          panel: (
            <Card variant="table-styles">
              <UsersTable />
            </Card>
          ),
        },
        invitations: {
          label: t('invitation.heading'),
          icon: EnvelopeIcon,
          panel: (
            <Card variant="table-styles">
              <InvitationsTable />
            </Card>
          ),
        },
      }),
    [t],
  );

  const tabsService = useTabs({ tabs });
  const onModalClose = (userinviteResultType?: UserInviteResult) => {
    onClose();
    if (userinviteResultType === UserInviteResult.UserInvited) {
      tabsService.activateTab('invitations');
    } else {
      tabsService.activateTab('users');
    }
  };

  return (
    <>
      <Flex py="6" px={[4, 4, 8]} justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h2" size="md">
            {t('settings.organization.users.heading')}
          </Heading>
          <Text fontSize="14" color="gray.500">
            {t('settings.organization.users.subheading')}
          </Text>
        </Box>
        {canInviteUser && (
          <Button
            leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />}
            colorScheme="blue"
            variant="solid"
            onClick={onOpen}
          >
            {t('users.inviteButton')}
          </Button>
        )}
      </Flex>
      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <MainOrgBanner type="users" />
        <Tabs tabs={tabs} />
        <Modal
          size="xl"
          isCentered
          autoFocus={false}
          isOpen={isOpen}
          onClose={onClose}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="lg">{t('users.invitation.heading')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6} pt={0}>
              <InvitationForm onModalClose={onModalClose} />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

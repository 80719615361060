import { EvidenceIntegrationType } from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Qualys]: HandlerConfigQualys;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigQualys {}
}

export const QualysConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Qualys> = {
  name: Integration_Names_Enum.Qualys,

  orgConfig: z.object({
    baseUrl: z.string().url().describe('API Base URL'),
    username: z.string().min(1).describe('Username'),
    password: z.string().min(1).describe('Password'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.Vulnerability),
      webAppIds: z.array(z.number().positive()),
    }),
  ]),
};

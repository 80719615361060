import { Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useDeleteFormMutation } from '@main/graphql/features/Form.generated';
import { useDeleteQuestionnaireMutation } from '@main/graphql/mutations/DeleteQuestionnaire.generated';
import { useGetQuestionnairesQuery } from '@main/graphql/queries/GetQuestionnaires.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';

export function useDeleteQuestionnaire() {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDeleteQuestionnaire = !!userRole.permissionMap?.write_vendors;

  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const { refetch: refetchQuestionnaires } = useGetQuestionnairesQuery({ organization_id: orgId });
  const [deleteFormById] = useDeleteFormMutation();
  const [deleteQuestionnaireById] = useDeleteQuestionnaireMutation();

  const deleteQuestionnaire = useCallback(
    (questionnaire: { id: string; form_id?: string; name?: string }, onDelete?: () => void) => {
      openDialog({
        dialogHeader: t('questionnaires.alert.header'),
        dialogContent: (
          <Text textColor="gray.700" _dark={{ textColor: 'gray.300' }}>
            <Trans
              i18nKey="questionnaires.alert.content"
              values={{ name: questionnaire.name }}
              components={{ bold: <strong /> }}
            />
          </Text>
        ),
        cancelBtnLabel: t('questionnaires.alert.cancel'),
        confirmAction: {
          children: t('questionnaires.alert.delete'),
          onClick: async () => {
            try {
              if (questionnaire.form_id) {
                await deleteFormById({ form_id: questionnaire.form_id }).unwrap();
              } else {
                await deleteQuestionnaireById({ id: questionnaire.id }).unwrap();
              }
              await refetchQuestionnaires().unwrap();
              successToast(
                t('successMessages.deleteSucceeded', { entity: t('entities.questionnaire') }),
              );
              onDelete?.();
            } catch (error) {
              errorToast(t('errorMessages.deleteFailed', { entity: t('entities.questionnaire') }));
              datadogLogs.logger.error('Questionnaire delete failed', {}, toError(error));
            }
          },
        },
      });
    },
    [t, openDialog, deleteFormById, deleteQuestionnaireById, refetchQuestionnaires],
  );

  return canDeleteQuestionnaire ? deleteQuestionnaire : null;
}

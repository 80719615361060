import { ThemeTypings } from '@chakra-ui/react';
import { ControlEvidenceStatus, ControlStatus } from '@main/graphql/client-scalars';
import {
  Client_Questionnaire_Question_Status_Enum,
  Client_Questionnaire_Status_Enum,
  Control_Frequencies_Enum,
  Risk_Levels_Enum,
  Vendor_Approval_Statuses_Enum,
} from '@main/graphql/types.generated';
import { ParseKeys } from 'i18next';

// regexp for password pattern
export const PASSWORD_PATTERN = '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[\\W_]).{8,}$';

export const CONTROL_STATUSES = {
  [ControlStatus.PENDING]: { value: ControlStatus.PENDING, colorScheme: 'gray' },
  [ControlStatus.NOT_RELEVANT]: { value: ControlStatus.NOT_RELEVANT, colorScheme: 'gray' },
  [ControlStatus.VALID]: { value: ControlStatus.VALID, colorScheme: 'green' },
  [ControlStatus.AT_RISK]: { value: ControlStatus.AT_RISK, colorScheme: 'orange' },
  [ControlStatus.FAILING]: { value: ControlStatus.FAILING, colorScheme: 'red' },
};

export const VENDOR_APPROVAL_STATUSES = {
  [Vendor_Approval_Statuses_Enum.Accepted]: {
    value: Vendor_Approval_Statuses_Enum.Accepted,
    colorScheme: 'green',
  },
  [Vendor_Approval_Statuses_Enum.Pending]: {
    value: Vendor_Approval_Statuses_Enum.Pending,
    colorScheme: 'gray',
  },
  [Vendor_Approval_Statuses_Enum.Rejected]: {
    value: Vendor_Approval_Statuses_Enum.Rejected,
    colorScheme: 'red',
  },
};

export const RISK_LEVELS = {
  [Risk_Levels_Enum.VeryHigh]: { value: Risk_Levels_Enum.VeryHigh, colorScheme: 'red' },
  [Risk_Levels_Enum.High]: { value: Risk_Levels_Enum.High, colorScheme: 'red' },
  [Risk_Levels_Enum.Medium]: { value: Risk_Levels_Enum.Medium, colorScheme: 'orange' },
  [Risk_Levels_Enum.Low]: { value: Risk_Levels_Enum.Low, colorScheme: 'green' },
  [Risk_Levels_Enum.VeryLow]: { value: Risk_Levels_Enum.VeryLow, colorScheme: 'green' },
} satisfies Record<
  Risk_Levels_Enum,
  { value: Risk_Levels_Enum; colorScheme: ThemeTypings['colorSchemes'] }
>;

export const CONTROL_EVIDENCE_STATUSES = {
  [ControlEvidenceStatus.CALCULATING]: {
    value: ControlEvidenceStatus.CALCULATING,
    colorScheme: 'gray',
  },
  [ControlEvidenceStatus.AT_RISK]: { value: ControlEvidenceStatus.AT_RISK, colorScheme: 'orange' },
  [ControlEvidenceStatus.INVALID]: { value: ControlEvidenceStatus.INVALID, colorScheme: 'red' },
  [ControlEvidenceStatus.VALID]: { value: ControlEvidenceStatus.VALID, colorScheme: 'green' },
};

export const Control_Frequencies_Ordered: Control_Frequencies_Enum[] = [
  Control_Frequencies_Enum.Annual,
  Control_Frequencies_Enum.Biannual,
  Control_Frequencies_Enum.Quarterly,
  Control_Frequencies_Enum.Monthly,
  Control_Frequencies_Enum.Weekly,
  Control_Frequencies_Enum.Daily,
];

export const COMPLYANCE_USER = {
  id: 'complyance',
  displayName: 'Complyance',
};

export type ClientQuestionnaireStatuses = {
  [key in Client_Questionnaire_Status_Enum]: {
    value: ParseKeys;
    colorScheme: ThemeTypings['colorSchemes'];
  };
};

export const CLIENT_QESTIONNAIRES_STATUSES: ClientQuestionnaireStatuses = {
  [Client_Questionnaire_Status_Enum.Pending]: {
    value: 'clientQuestionnaires.statuses.pending',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Status_Enum.InProgress]: {
    value: 'clientQuestionnaires.statuses.inProgress',
    colorScheme: 'orange',
  },
  [Client_Questionnaire_Status_Enum.ExtractingQuestions]: {
    value: 'clientQuestionnaires.statuses.inProgress',
    colorScheme: 'orange',
  },
  [Client_Questionnaire_Status_Enum.Overdue]: {
    value: 'clientQuestionnaires.statuses.overdue',
    colorScheme: 'red',
  },
  [Client_Questionnaire_Status_Enum.ExtractionFailed]: {
    value: 'clientQuestionnaires.statuses.failed',
    colorScheme: 'red',
  },
  [Client_Questionnaire_Status_Enum.Complete]: {
    value: 'clientQuestionnaires.statuses.complete',
    colorScheme: 'green',
  },
};

export const CLIENT_QESTIONNAIRES_QUESTION_STATUSES: {
  [key in Client_Questionnaire_Question_Status_Enum]: {
    value: ParseKeys;
    colorScheme: ThemeTypings['colorSchemes'];
  };
} = {
  [Client_Questionnaire_Question_Status_Enum.Pending]: {
    value: 'clientQuestionnaire.statuses.pending',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Question_Status_Enum.ReadyForReview]: {
    value: 'clientQuestionnaire.statuses.readyForReview',
    colorScheme: 'orange',
  },
  [Client_Questionnaire_Question_Status_Enum.Approved]: {
    value: 'clientQuestionnaire.statuses.approved',
    colorScheme: 'green',
  },
  [Client_Questionnaire_Question_Status_Enum.Processing]: {
    value: 'clientQuestionnaire.statuses.processing',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Question_Status_Enum.ProcessingFailed]: {
    value: 'clientQuestionnaire.statuses.processingFailed',
    colorScheme: 'red',
  },
};

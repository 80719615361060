import { Skeleton } from '@chakra-ui/react';
import { ChartBarIcon } from '@heroicons/react/24/outline';
import { useGetRiskMatrixQuery } from '@main/graphql/features/RiskMatrix.generated';
import { Risk_Levels_Enum } from '@main/graphql/types.generated';
import { DashboardCard, EmptyPlaceholder, RadarChart, RadarDatum, RadarDimention } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGetRisksQuery } from '../risks/get-risk.generated';
import { getMappedRisks, OrganizationRisk } from '../risks/slice';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { useRiskLevelsGetRiskCategoriesQuery } from './risk-levels.generated';

export interface RiskLevelsProps {
  data?: RiskLevelsData;
}

const RadarChartLevelNumber = Object.keys(Risk_Levels_Enum).length;

export function RiskLevels(props: RiskLevelsProps) {
  const { t } = useTranslation();

  const isLoading = props.data === undefined;
  // Radar chart has to have at least 3 data points to be visible
  const isEmpty = props.data ? props.data.inherent.length < 2 : false;

  const data: RiskLevelsData = useMemo(
    () => props.data ?? { inherent: [], residual: [], categories: [] },
    [props.data],
  );

  const radarData: RadarDatum[] = useMemo(
    () => [
      {
        label: t('dashboard.riskLevels.inherent'),
        color: '#6B46C1',
        dimensions: data.inherent ?? [],
      },
      {
        label: t('dashboard.riskLevels.residual'),
        color: '#00A3C4',
        dimensions: data.residual ?? [],
      },
    ],
    [data.inherent, data.residual, t],
  );

  const radarDimensions: RadarDimention[] = useMemo(
    () => data.categories.map((label) => ({ label })),
    [data.categories],
  );

  return (
    <DashboardCard>
      <DashboardCard.Header
        heading={t('dashboard.riskLevels.heading')}
        subheading={t('dashboard.riskLevels.subheading')}
      />
      <DashboardCard.Body minH={454}>
        <Skeleton
          isLoaded={!isLoading}
          h="full"
          w="full"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {isEmpty ? (
            <ComplianceHealthEmpty />
          ) : (
            <RadarChart
              data={radarData}
              dimentions={radarDimensions}
              levels={RadarChartLevelNumber}
              yRange={[0, RadarChartLevelNumber]}
            />
          )}
        </Skeleton>
      </DashboardCard.Body>
    </DashboardCard>
  );
}

export function useRiskLevels(): RiskLevelsProps {
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const { data: riskData, isFetching: isCategoriesLoading } = useRiskLevelsGetRiskCategoriesQuery({
    organizationId,
  });
  const { isFetching: isRisksLoading } = useGetRisksQuery({ organization_id: organizationId });
  const { isFetching: isMatrixLoading } = useGetRiskMatrixQuery({ organizationId });
  const risks = useAppSelector(getMappedRisks);

  const riskCategories = useMemo(() => riskData?.organization_risk_categories ?? [], [riskData]);

  const risksByCategory = useMemo(
    () =>
      risks.reduce(
        (acc, risk) => {
          risk.categories.forEach(({ category }) => {
            acc[category.id] = [...(acc[category.id] ?? []), risk];
          });
          return acc;
        },
        {} as Record<string, typeof risks>,
      ),
    [risks],
  );

  const categoriesWithRisks = useMemo(
    () =>
      riskCategories
        .map((category) => {
          const risks = risksByCategory[category.id] ?? [];

          if (risks.length === 0) {
            return {
              category,
              risks: [] as typeof risks,
              inherentLevel: 0,
              residualLevel: 0,
              totalLevel: 0,
            };
          }

          const inherentLevel = calcAvgRisksLevel(risks, (risk) => risk.inherentRiskLevel);
          const residualLevel = calcAvgRisksLevel(risks, (risk) => risk.residualRiskLevel);
          const totalLevel = Math.round((inherentLevel + residualLevel) / 2);

          return { category, risks, inherentLevel, residualLevel, totalLevel };
        })
        .sort((a, b) => b.totalLevel - a.totalLevel)
        .slice(0, 6),
    [risksByCategory, riskCategories],
  );

  const data: RiskLevelsData = useMemo(
    () => ({
      categories: categoriesWithRisks.map(({ category }) => category.name),
      inherent: categoriesWithRisks.map(({ inherentLevel }) => inherentLevel),
      residual: categoriesWithRisks.map(({ residualLevel }) => residualLevel),
    }),
    [categoriesWithRisks],
  );

  if (isCategoriesLoading || isRisksLoading || isMatrixLoading) {
    return {};
  }

  return { data };
}

const riskLevelMap = {
  [Risk_Levels_Enum.VeryLow]: 1,
  [Risk_Levels_Enum.Low]: 2,
  [Risk_Levels_Enum.Medium]: 3,
  [Risk_Levels_Enum.High]: 4,
  [Risk_Levels_Enum.VeryHigh]: 5,
};

function calcAvgRisksLevel(
  risks: OrganizationRisk[],
  levelGetter: (risk: OrganizationRisk) => Risk_Levels_Enum | undefined,
) {
  return Math.round(
    risks.reduce(
      (sum, risk) => sum + riskLevelMap[levelGetter(risk) ?? Risk_Levels_Enum.Medium],
      0,
    ) / risks.length,
  );
}

export function ComplianceHealthEmpty() {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder>
      <EmptyPlaceholder.Icon as={ChartBarIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('dashboard.riskLevels.emptyState.heading')}
        </EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          {t('dashboard.riskLevels.emptyState.subheading')}
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
}

export interface RiskLevelsData {
  inherent: number[];
  residual: number[];
  categories: string[];
}

/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type TaskLinkedEntityFragment = {
  __typename?: 'tasks';
  controls: Array<{
    __typename?: 'control_tasks';
    control: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
  }>;
  risks: Array<{
    __typename?: 'risk_tasks';
    risk: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
  }>;
  vendors: Array<{
    __typename?: 'vendor_tasks';
    vendor: { __typename?: 'vendors'; id: string; name?: string; internal_id?: string };
  }>;
  tasks_policy_tasks: Array<{
    __typename?: 'policy_tasks';
    policy_tasks_policy: {
      __typename?: 'policies';
      id: string;
      name?: string;
      internal_id?: string;
    };
  }>;
};

export type TaskSidebarDataFragment = {
  __typename?: 'tasks';
  id: string;
  name?: string;
  status: ClientTypes.TaskStatus;
  due_date?: string;
  updated_at: string;
  created_at: string;
  task_owners: Array<{ __typename?: 'task_owners'; owner_id: string }>;
  controls: Array<{
    __typename?: 'control_tasks';
    control: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
  }>;
  risks: Array<{
    __typename?: 'risk_tasks';
    risk: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
  }>;
  vendors: Array<{
    __typename?: 'vendor_tasks';
    vendor: { __typename?: 'vendors'; id: string; name?: string; internal_id?: string };
  }>;
  tasks_policy_tasks: Array<{
    __typename?: 'policy_tasks';
    policy_tasks_policy: {
      __typename?: 'policies';
      id: string;
      name?: string;
      internal_id?: string;
    };
  }>;
};

export type GetAssignedToMeTasksQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetAssignedToMeTasksQuery = {
  __typename?: 'query_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    name?: string;
    status: ClientTypes.TaskStatus;
    due_date?: string;
    updated_at: string;
    created_at: string;
    task_owners: Array<{ __typename?: 'task_owners'; owner_id: string }>;
    controls: Array<{
      __typename?: 'control_tasks';
      control: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
    }>;
    risks: Array<{
      __typename?: 'risk_tasks';
      risk: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
    }>;
    vendors: Array<{
      __typename?: 'vendor_tasks';
      vendor: { __typename?: 'vendors'; id: string; name?: string; internal_id?: string };
    }>;
    tasks_policy_tasks: Array<{
      __typename?: 'policy_tasks';
      policy_tasks_policy: {
        __typename?: 'policies';
        id: string;
        name?: string;
        internal_id?: string;
      };
    }>;
  }>;
};

export type GetCreatedByMeTasksQueryVariables = Types.Exact<{
  creatorId: Types.Scalars['uuid']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;

export type GetCreatedByMeTasksQuery = {
  __typename?: 'query_root';
  tasks: Array<{
    __typename?: 'tasks';
    id: string;
    name?: string;
    status: ClientTypes.TaskStatus;
    due_date?: string;
    updated_at: string;
    created_at: string;
    task_owners: Array<{ __typename?: 'task_owners'; owner_id: string }>;
    controls: Array<{
      __typename?: 'control_tasks';
      control: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
    }>;
    risks: Array<{
      __typename?: 'risk_tasks';
      risk: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
    }>;
    vendors: Array<{
      __typename?: 'vendor_tasks';
      vendor: { __typename?: 'vendors'; id: string; name?: string; internal_id?: string };
    }>;
    tasks_policy_tasks: Array<{
      __typename?: 'policy_tasks';
      policy_tasks_policy: {
        __typename?: 'policies';
        id: string;
        name?: string;
        internal_id?: string;
      };
    }>;
  }>;
};

export type UpdateAllTaskStatusesByOwnerMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  status: Types.Scalars['String']['input'];
  completedAt?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;

export type UpdateAllTaskStatusesByOwnerMutation = {
  __typename?: 'mutation_root';
  update_tasks?: { __typename?: 'tasks_mutation_response'; affected_rows: number };
  update_task_owners?: { __typename?: 'task_owners_mutation_response'; affected_rows: number };
};

export type UpdateFilteredTaskStatusesByOwnerMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  filter: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  status: Types.Scalars['String']['input'];
  completedAt?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;

export type UpdateFilteredTaskStatusesByOwnerMutation = {
  __typename?: 'mutation_root';
  update_tasks?: { __typename?: 'tasks_mutation_response'; affected_rows: number };
  update_task_owners?: { __typename?: 'task_owners_mutation_response'; affected_rows: number };
};

export type UpdateAllTaskStatusesByCreatorMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  status: Types.Scalars['String']['input'];
  completedAt?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;

export type UpdateAllTaskStatusesByCreatorMutation = {
  __typename?: 'mutation_root';
  update_tasks?: { __typename?: 'tasks_mutation_response'; affected_rows: number };
  update_task_owners?: { __typename?: 'task_owners_mutation_response'; affected_rows: number };
};

export type UpdateFilteredTaskStatusesByCreatorMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  filter: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  status: Types.Scalars['String']['input'];
  completedAt?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;

export type UpdateFilteredTaskStatusesByCreatorMutation = {
  __typename?: 'mutation_root';
  update_tasks?: { __typename?: 'tasks_mutation_response'; affected_rows: number };
  update_task_owners?: { __typename?: 'task_owners_mutation_response'; affected_rows: number };
};

export const TaskLinkedEntityFragmentDoc = `
    fragment TaskLinkedEntity on tasks {
  controls {
    control {
      id
      name
      internal_id
    }
  }
  risks {
    risk {
      id
      name
      internal_id
    }
  }
  vendors {
    vendor {
      id
      name
      internal_id
    }
  }
  tasks_policy_tasks {
    policy_tasks_policy {
      id
      name
      internal_id
    }
  }
}
    `;
export const TaskSidebarDataFragmentDoc = `
    fragment TaskSidebarData on tasks {
  id
  name
  status
  due_date
  updated_at
  created_at
  task_owners {
    owner_id
  }
  ...TaskLinkedEntity
}
    `;
export const GetAssignedToMeTasksDocument = `
    query GetAssignedToMeTasks($userId: uuid!, $limit: Int = 10, $offset: Int = 0) {
  tasks(
    where: {task_owners: {owner_id: {_eq: $userId}}}
    order_by: {created_at: asc}
    limit: $limit
    offset: $offset
  ) {
    ...TaskSidebarData
  }
}
    ${TaskSidebarDataFragmentDoc}
${TaskLinkedEntityFragmentDoc}` as string &
  TypedDocumentNode<GetAssignedToMeTasksQuery, GetAssignedToMeTasksQueryVariables>;
export const GetCreatedByMeTasksDocument = `
    query GetCreatedByMeTasks($creatorId: uuid!, $limit: Int = 10, $offset: Int = 0) {
  tasks(
    where: {created_by: {_eq: $creatorId}}
    order_by: {created_at: asc}
    limit: $limit
    offset: $offset
  ) {
    ...TaskSidebarData
  }
}
    ${TaskSidebarDataFragmentDoc}
${TaskLinkedEntityFragmentDoc}` as string &
  TypedDocumentNode<GetCreatedByMeTasksQuery, GetCreatedByMeTasksQueryVariables>;
export const UpdateAllTaskStatusesByOwnerDocument = `
    mutation UpdateAllTaskStatusesByOwner($userId: uuid!, $status: String!, $completedAt: timestamptz) {
  update_tasks(
    where: {task_owners: {owner_id: {_eq: $userId}}, status: {_neq: $status}}
    _set: {status: $status}
  ) {
    affected_rows
  }
  update_task_owners(
    where: {owner_id: {_eq: $userId}, task: {status: {_neq: $status}}}
    _set: {completed_at: $completedAt}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateAllTaskStatusesByOwnerMutation,
    UpdateAllTaskStatusesByOwnerMutationVariables
  >;
export const UpdateFilteredTaskStatusesByOwnerDocument = `
    mutation UpdateFilteredTaskStatusesByOwner($userId: uuid!, $filter: [String!]!, $status: String!, $completedAt: timestamptz) {
  update_tasks(
    where: {task_owners: {owner_id: {_eq: $userId}}, status: {_in: $filter}}
    _set: {status: $status}
  ) {
    affected_rows
  }
  update_task_owners(
    where: {owner_id: {_eq: $userId}, task: {status: {_in: $filter}}}
    _set: {completed_at: $completedAt}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateFilteredTaskStatusesByOwnerMutation,
    UpdateFilteredTaskStatusesByOwnerMutationVariables
  >;
export const UpdateAllTaskStatusesByCreatorDocument = `
    mutation UpdateAllTaskStatusesByCreator($userId: uuid!, $status: String!, $completedAt: timestamptz) {
  update_tasks(
    where: {created_by: {_eq: $userId}, status: {_neq: $status}}
    _set: {status: $status}
  ) {
    affected_rows
  }
  update_task_owners(
    where: {task: {status: {_neq: $status}, created_by: {_eq: $userId}}}
    _set: {completed_at: $completedAt}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateAllTaskStatusesByCreatorMutation,
    UpdateAllTaskStatusesByCreatorMutationVariables
  >;
export const UpdateFilteredTaskStatusesByCreatorDocument = `
    mutation UpdateFilteredTaskStatusesByCreator($userId: uuid!, $filter: [String!]!, $status: String!, $completedAt: timestamptz) {
  update_tasks(
    where: {created_by: {_eq: $userId}, status: {_in: $filter}}
    _set: {status: $status}
  ) {
    affected_rows
  }
  update_task_owners(
    where: {task: {status: {_in: $filter}, created_by: {_eq: $userId}}}
    _set: {completed_at: $completedAt}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<
    UpdateFilteredTaskStatusesByCreatorMutation,
    UpdateFilteredTaskStatusesByCreatorMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetAssignedToMeTasks: build.query<
      GetAssignedToMeTasksQuery,
      GetAssignedToMeTasksQueryVariables
    >({
      query: (variables) => ({ document: GetAssignedToMeTasksDocument, variables }),
    }),
    GetCreatedByMeTasks: build.query<GetCreatedByMeTasksQuery, GetCreatedByMeTasksQueryVariables>({
      query: (variables) => ({ document: GetCreatedByMeTasksDocument, variables }),
    }),
    UpdateAllTaskStatusesByOwner: build.mutation<
      UpdateAllTaskStatusesByOwnerMutation,
      UpdateAllTaskStatusesByOwnerMutationVariables
    >({
      query: (variables) => ({ document: UpdateAllTaskStatusesByOwnerDocument, variables }),
    }),
    UpdateFilteredTaskStatusesByOwner: build.mutation<
      UpdateFilteredTaskStatusesByOwnerMutation,
      UpdateFilteredTaskStatusesByOwnerMutationVariables
    >({
      query: (variables) => ({ document: UpdateFilteredTaskStatusesByOwnerDocument, variables }),
    }),
    UpdateAllTaskStatusesByCreator: build.mutation<
      UpdateAllTaskStatusesByCreatorMutation,
      UpdateAllTaskStatusesByCreatorMutationVariables
    >({
      query: (variables) => ({ document: UpdateAllTaskStatusesByCreatorDocument, variables }),
    }),
    UpdateFilteredTaskStatusesByCreator: build.mutation<
      UpdateFilteredTaskStatusesByCreatorMutation,
      UpdateFilteredTaskStatusesByCreatorMutationVariables
    >({
      query: (variables) => ({ document: UpdateFilteredTaskStatusesByCreatorDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetAssignedToMeTasksQuery,
  useLazyGetAssignedToMeTasksQuery,
  useGetCreatedByMeTasksQuery,
  useLazyGetCreatedByMeTasksQuery,
  useUpdateAllTaskStatusesByOwnerMutation,
  useUpdateFilteredTaskStatusesByOwnerMutation,
  useUpdateAllTaskStatusesByCreatorMutation,
  useUpdateFilteredTaskStatusesByCreatorMutation,
} = injectedRtkApi;

import { useStableCallback } from '@main/shared/utils';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId } from '../user/slice';
import {
  api as getNotificationsApi,
  GetInboxNotificationsQuery,
  useLazyGetInboxNotificationsQuery,
} from './get-notifications.generated';

export const NOTIFICATION_PAGE_SIZE = 25;

export interface InfiniteScrollProps {
  list: GetInboxNotificationsQuery['user_notifications'];
  offset: number;
  isNoMore: boolean;
}

export function useGetInboxNotifications() {
  const userId = useAppSelector(getCurrentUserId);
  const [getInboxNotifications] = useLazyGetInboxNotificationsQuery();

  // Without annotation TypeScript infers the `offset` argument as `any`
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  return useStableCallback(async (offset: number = 0): Promise<InfiniteScrollProps> => {
    const { user_notifications } = await getInboxNotifications({
      userId,
      limit: NOTIFICATION_PAGE_SIZE,
      offset: offset,
    }).unwrap();

    return {
      list: user_notifications,
      offset: offset + user_notifications.length,
      isNoMore: user_notifications.length < NOTIFICATION_PAGE_SIZE,
    };
  });
}

export function useGetArchivedNotifications() {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getCurrentUserId);

  // Without annotation TypeScript infers the `offset` argument as `any`
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  return useStableCallback(async (offset: number = 0) => {
    const { user_notifications } = await dispatch(
      getNotificationsApi.endpoints.GetArchivedNotifications.initiate(
        {
          userId,
          limit: NOTIFICATION_PAGE_SIZE,
          offset,
        },
        {
          forceRefetch: true,
        },
      ),
    ).unwrap();

    return {
      list: user_notifications,
      offset: offset + user_notifications.length,
      isNoMore: user_notifications.length < NOTIFICATION_PAGE_SIZE,
    };
  });
}

export function useGetAllNotifications() {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getCurrentUserId);

  // Without annotation TypeScript infers the `offset` argument as `any`
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  return useStableCallback(async (offset: number = 0) => {
    const { user_notifications } = await dispatch(
      getNotificationsApi.endpoints.GetAllNotifications.initiate(
        {
          userId,
          limit: NOTIFICATION_PAGE_SIZE,
          offset,
        },
        {
          forceRefetch: true,
        },
      ),
    ).unwrap();

    return {
      list: user_notifications,
      offset: offset + user_notifications.length,
      isNoMore: user_notifications.length < NOTIFICATION_PAGE_SIZE,
    };
  });
}

import useAhooksInfiniteScroll from 'ahooks/es/useInfiniteScroll';
import type { InfiniteScrollOptions } from 'ahooks/es/useInfiniteScroll/types';

interface Data<T> {
  list: T[];
  offset: number;
  isNoMore: boolean;
}

export const useInfiniteScroll = <T>(
  callback: (currentData?: Data<T>) => Promise<Data<T>>,
  options: InfiniteScrollOptions<Data<T>>,
) => {
  return useAhooksInfiniteScroll(callback, options);
};

import { useStableCallback } from '@main/shared/utils';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { getCurrentUserId } from '../../user/slice';
import { api as getSidebarTasksApi } from './manage-task-sidebar.generated';

export const TASK_SIDEBAR_PAGE_SIZE = 7;

export function useGetAssignedToMeTasks() {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getCurrentUserId);

  // Without annotation TypeScript infers the `offset` argument as `any`
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  return useStableCallback(async (offset: number = 0) => {
    const { tasks } = await dispatch(
      getSidebarTasksApi.endpoints.GetAssignedToMeTasks.initiate(
        {
          userId,
          limit: TASK_SIDEBAR_PAGE_SIZE,
          offset,
        },
        {
          forceRefetch: true,
        },
      ),
    ).unwrap();

    return {
      list: tasks,
      offset: offset + tasks.length,
      isNoMore: tasks.length < TASK_SIDEBAR_PAGE_SIZE,
    };
  });
}

export function useGetCreatedByMeTasks() {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(getCurrentUserId);

  // Without annotation TypeScript infers the `offset` argument as `any`
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  return useStableCallback(async (offset: number = 0) => {
    const { tasks } = await dispatch(
      getSidebarTasksApi.endpoints.GetCreatedByMeTasks.initiate(
        {
          creatorId: userId,
          limit: TASK_SIDEBAR_PAGE_SIZE,
          offset,
        },
        {
          forceRefetch: true,
        },
      ),
    ).unwrap();

    return {
      list: tasks,
      offset: offset + tasks.length,
      isNoMore: tasks.length < TASK_SIDEBAR_PAGE_SIZE,
    };
  });
}

import { useUpdateVendorMutation } from '@main/graphql/mutations/UpdateVendor.generated';
import { Vendor_Approval_Statuses_Enum } from '@main/graphql/types.generated';
import { QUESTIONNAIRE_STATUSES } from '@main/shared/types';
import { formatDate } from '@main/shared/utils';
import { createColumnHelper, useDrawer } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { VENDOR_APPROVAL_STATUSES } from '../../utils/constants';
import { VENDOR_ASSESSMENT_STATUS_COLOR } from '../shared/status-color';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useGetVendorRiskLevelActions } from './risk-levels';
import { OrganizationVendor } from './slice';
import { useGetVendorTierActions } from './tiers';
import { useSelectHandler, useUpdateVendorHandler } from './use-udpate-vendor-handler';
import { api as getVendorRiskLevelApi } from './VendorRiskLevels.generated';
import { api as getVendorTierApi } from './VendorTiers.generated';

export function useVendorColumnHelper() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const [updateVendor] = useUpdateVendorMutation();
  const updateVendorHandler = useUpdateVendorHandler();
  const getHandler = useSelectHandler();
  const getRiskLevelActions = useGetVendorRiskLevelActions();
  const getTierActions = useGetVendorTierActions();

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationVendor>();

    return {
      status() {
        return columnHelper.columns.status({
          id: 'assessmentStatus',
          header: t('vendors.tableColumns.assessmentStatus'),
          accessorFn: ({ assessment_status }) => {
            return {
              label: t(`vendors.enum.assessmentStatus.${assessment_status}`),
              value: assessment_status,
              colorScheme: VENDOR_ASSESSMENT_STATUS_COLOR[assessment_status],
            };
          },
          enableColumnFilter: true,
          enableGlobalFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      questionnaireStatus() {
        return columnHelper.columns.status({
          id: 'questionnaireStatus',
          isMulti: true,
          header: t('vendors.tableColumns.questionnaires'),
          accessorFn: ({ questionnaires }) => {
            return [...new Set(questionnaires.map(({ status }) => status))].map((status) => {
              const statusMeta = QUESTIONNAIRE_STATUSES[status];

              return {
                value: t(statusMeta.value),
                colorScheme: statusMeta?.colorScheme,
              };
            });
          },
          enableColumnFilter: true,
          enableSorting: true,
          size: 120,
        });
      },

      internalId() {
        return columnHelper.columns.tag({
          id: 'internalId',
          header: t('vendors.tableColumns.internalId'),
          accessorFn: ({ internal_id }) => ({
            value: internal_id ?? '',
            colorScheme: 'purple',
          }),
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 100,
        });
      },

      name(options?: { onClickOpenDrawer?: boolean }) {
        return columnHelper.columns.text({
          id: 'name',
          header: t('vendors.tableColumns.name'),
          accessorFn: ({ name }) => name,
          enableGlobalFilter: true,
          enableSorting: true,
          meta: {
            cell: {
              onClick: options?.onClickOpenDrawer
                ? (cell) => drawer.open({ entity: 'vendor', entityId: cell.row.original.id })
                : undefined,
            },
          },
        });
      },

      description() {
        return columnHelper.columns.text({
          id: 'description',
          header: t('vendors.tableColumns.description'),
          accessorFn: ({ description }) => description,
          enableGlobalFilter: true,
          enableColumnFilter: true,
          enableSorting: true,
          size: 300,
        });
      },

      riskLevel(options?: { isEditable?: boolean }) {
        return columnHelper.columns.tag({
          id: 'riskLevel',
          header: t('vendors.tableColumns.riskLevel'),
          accessorFn: ({ risk_level }) => {
            if (!risk_level) {
              return;
            }

            return {
              value: risk_level.id,
              label: risk_level.name,
              colorScheme: 'purple',
            };
          },
          size: 90,
          enableColumnFilter: true,
          enableSorting: true,
          edit:
            options?.isEditable && canUpdateVendor
              ? {
                  debounceMs: 500,
                  loadOptions: async (input: string) => {
                    const { organization_vendor_risk_levels } = await dispatch(
                      getVendorRiskLevelApi.endpoints.GetVendorRiskLevels.initiate({
                        organizationId,
                        ...(input?.trim() ? { nameFilter: { _ilike: `%${input}%` } } : {}),
                      }),
                    ).unwrap();

                    return organization_vendor_risk_levels.map((riskLevel) => ({
                      value: riskLevel.id,
                      label: riskLevel.name,
                      colorScheme: 'purple',
                    }));
                  },
                  getNewOptionData: (label) => ({ label, value: 'new', colorScheme: 'purple' }),
                  onChange: (row, value, meta) => {
                    const actions = getRiskLevelActions({ vendorId: row.original.id });
                    const onChangeHandler = getHandler({
                      actions,
                    });
                    onChangeHandler(value, meta);
                  },
                }
              : undefined,
        });
      },

      tier(options?: { isEditable?: boolean }) {
        return columnHelper.columns.tag({
          id: 'tier',
          header: t('vendors.tableColumns.tier'),
          accessorFn: ({ tier }) => {
            if (!tier) {
              return;
            }

            return {
              value: tier.id,
              label: tier.name,
              colorScheme: 'purple',
            };
          },
          size: 90,
          enableColumnFilter: true,
          enableSorting: true,
          edit:
            options?.isEditable && canUpdateVendor
              ? {
                  debounceMs: 500,
                  loadOptions: async (input: string) => {
                    const { organization_vendor_tiers } = await dispatch(
                      getVendorTierApi.endpoints.GetVendorTiers.initiate({
                        organizationId,
                        ...(input?.trim() ? { nameFilter: { _ilike: `%${input}%` } } : {}),
                      }),
                    ).unwrap();

                    return organization_vendor_tiers.map((tier) => ({
                      value: tier.id,
                      label: tier.name,
                      colorScheme: 'purple',
                    }));
                  },
                  getNewOptionData: (label) => ({ label, value: 'new', colorScheme: 'purple' }),
                  onChange: (row, value, meta) => {
                    const actions = getTierActions({ vendorId: row.original.id });
                    const onChangeHandler = getHandler({
                      actions,
                    });
                    onChangeHandler(value, meta);
                  },
                }
              : undefined,
        });
      },

      approvalStatus(options?: { isEditable?: boolean }) {
        return columnHelper.columns.tag({
          id: 'approvalStatus',
          header: t('vendors.tableColumns.approvalStatus'),
          accessorFn: ({ approval_status }) => {
            if (!approval_status) return;

            return {
              ...VENDOR_APPROVAL_STATUSES[approval_status],
              label: t(`vendors.props.approvalStatus.enums.${approval_status}`),
            };
          },
          size: 100,
          edit:
            options?.isEditable && canUpdateVendor
              ? {
                  options: Object.values(Vendor_Approval_Statuses_Enum).map((approval_status) => ({
                    ...VENDOR_APPROVAL_STATUSES[approval_status],
                    label: t(`vendors.props.approvalStatus.enums.${approval_status}`),
                  })),
                  onChange: async (row, status) => {
                    return updateVendorHandler(
                      updateVendor({
                        id: row.original.id,
                        vendor_input: {
                          approval_status: status ? status.value : null,
                        },
                      }),
                    );
                  },
                }
              : undefined,
          enableColumnFilter: true,
          enableSorting: true,
        });
      },

      lastReviewedDate() {
        return columnHelper.columns.date({
          id: 'lastReviewedDate',
          header: t('vendors.tableColumns.lastReviewedDate'),
          accessorFn: ({ lastReviewedDate }) => formatDate(lastReviewedDate),
          size: 160,
          meta: {
            cell: {
              fontSize: 'xs',
              color: 'gray.500',
            },
          },
          enableSorting: true,
          enableColumnFilter: true,
        });
      },

      owner(options?: { isEditable?: boolean }) {
        return columnHelper.columns.avatar({
          id: 'owner',
          header: t('vendors.tableColumns.owner'),
          accessorFn: ({ owner }) => {
            if (!owner) {
              return;
            }

            return {
              id: owner.id,
              displayName: owner.displayName,
            };
          },
          size: 70,
          enableColumnFilter: true,
          enableSorting: true,
          edit:
            options?.isEditable && canUpdateVendor
              ? {
                  options: currentOrgNonDisabledUsers.map((user) => ({
                    id: user.id,
                    displayName: user.displayName,
                  })),
                  onChange: (row, user) => {
                    return updateVendorHandler(
                      updateVendor({
                        id: row.original.id,
                        vendor_input: {
                          owner_id: user?.id ?? null,
                        },
                      }),
                    );
                  },
                }
              : undefined,
        });
      },
    };
  }, [
    t,
    canUpdateVendor,
    currentOrgNonDisabledUsers,
    drawer,
    dispatch,
    organizationId,
    getRiskLevelActions,
    getHandler,
    getTierActions,
    updateVendorHandler,
    updateVendor,
  ]);
}

import { Switch, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  Notification_Delivery_Types_Enum,
  Notification_Types_Enum,
} from '@main/graphql/types.admin.generated';
import { Notification } from '@main/notifications';
import { toError } from '@main/shared/utils';
import { errorToast, GroupedTable, successToast } from '@main/ui';
import { ColumnDef, Row } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId, getUserNotifications } from './slice';
import {
  useDisableNotificationMutation,
  useEnableNotificationMutation,
  useGetDisabledNotificationsQuery,
} from './UserNotifications.generated';

export const Notifications = () => {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  useGetDisabledNotificationsQuery({ userId });

  const notifications = useAppSelector((state) => getUserNotifications(state, t, userId));
  const columns = useNotificationsTableColumns();

  return (
    <GroupedTable
      data={notifications}
      columns={columns}
      entityName="notification"
      groupBy="group"
    />
  );
};

const NotificationItemCell = ({
  row,
  type,
}: {
  row: Row<Notification>;
  type: Notification_Delivery_Types_Enum;
}) => {
  const { t } = useTranslation();
  const userId = useAppSelector(getCurrentUserId);
  const { refetch: refetchNotifications } = useGetDisabledNotificationsQuery({ userId });

  const [enableNotification] = useEnableNotificationMutation();
  const [disableNotification] = useDisableNotificationMutation();

  const onDisable = useCallback(
    async ({
      notificationType,
      deliveryType,
    }: {
      notificationType: Notification_Types_Enum;
      deliveryType: Notification_Delivery_Types_Enum;
    }) => {
      try {
        await disableNotification({
          object: {
            user_id: userId,
            type: notificationType,
            delivery_type: deliveryType,
          },
        }).unwrap();
        refetchNotifications();
        successToast(t('successMessages.disableSucceeded', { entity: t('entities.notification') }));
      } catch (error) {
        refetchNotifications();
        errorToast(t('errorMessages.disableFailed', { entity: t('entities.notification') }));
        datadogLogs.logger.error('Adding new permission to role failed', {}, toError(error));
      }
    },
    [disableNotification, refetchNotifications, t, userId],
  );

  const onEnable = useCallback(
    async ({
      notificationType,
      deliveryType,
    }: {
      notificationType: Notification_Types_Enum;
      deliveryType: Notification_Delivery_Types_Enum;
    }) => {
      try {
        await enableNotification({
          userId,
          deliveryType,
          notificationType,
        }).unwrap();
        refetchNotifications();
        successToast(t('successMessages.enableSucceeded', { entity: t('entities.notification') }));
      } catch (error) {
        refetchNotifications();
        errorToast(t('errorMessages.enableFailed', { entity: t('entities.notification') }));
        datadogLogs.logger.error('Adding new permission to role failed', {}, toError(error));
      }
    },
    [enableNotification, refetchNotifications, t, userId],
  );

  return (
    <Switch
      isDisabled={row.original[type].isNotAvailable}
      isChecked={
        row.original[type].isNotAvailable
          ? row.original[type].valueWhenNotAvailable
          : row.original[type].value
      }
      onChange={() => {
        const input = {
          deliveryType: type,
          notificationType: row.original.name,
        };
        const isEnabled = row.original[type].value;
        return isEnabled ? onDisable(input) : onEnable(input);
      }}
    />
  );
};

const useNotificationsTableColumns = () => {
  const { t } = useTranslation();

  return useMemo<ColumnDef<Notification>[]>(
    () => [
      {
        id: 'name',
        accessorFn: ({ name }) => t(`notifications.names.${name}`),
        header: () => <Text>{t('notifications.tableColumns.actions')}</Text>,
        cell: ({ row }) => t(`notifications.names.${row.original.name}`),
        footer: (props) => props.column.id,
      },
      {
        id: 'platform',
        cell: ({ row }) => (
          <NotificationItemCell type={Notification_Delivery_Types_Enum.Platform} row={row} />
        ),
        header: () => <Text>{t('notifications.tableColumns.inPlatform')}</Text>,
        footer: (props) => props.column.id,
      },
      {
        id: 'email',
        cell: ({ row }) => (
          <NotificationItemCell type={Notification_Delivery_Types_Enum.Email} row={row} />
        ),
        header: () => <Text>{t('notifications.tableColumns.email')}</Text>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'group',
        footer: (props) => props.column.id,
      },
    ],
    [t],
  );
};

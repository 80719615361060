import { Text, useColorModeValue } from '@chakra-ui/react';
import { Notification_Types_Enum } from '@main/graphql/types.generated';
import { useNavigate } from '@tanstack/react-router';
import { Trans, useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getAllMembersOfOrgsCurrentUserJoined } from '../user/slice';
import { Notification } from './notification-item';

const AssigneeAndMentionContent = ({
  assignor,
  internalId,
  entityName,
  content,
}: {
  assignor: string;
  internalId: string;
  entityName: string;
  content: string;
}) => {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      <Text as="b">{assignor}</Text> {content}
      <Text as="b">
        {' '}
        {internalId} - {entityName}
      </Text>
    </Text>
  );
};

const StatusUpdateContent = ({
  internalId,
  entityName,
  content,
}: {
  internalId: string;
  entityName: string;
  content: string;
}) => {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      {content}:{' '}
      <Text as="b">
        {internalId} - {entityName}
      </Text>
    </Text>
  );
};

export const useGetNotificationContent = ({ notification }: { notification: Notification }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const users = useAppSelector(getAllMembersOfOrgsCurrentUserJoined);
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');
  const assignorId =
    'assignorId' in notification.content.params
      ? notification.content.params.assignorId
      : undefined;
  const assignor = users[assignorId ?? '']?.displayName ?? '';

  switch (notification.content.type) {
    case Notification_Types_Enum.EntityOwnershipChanged: {
      if (notification.content.control) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={notification.content.control?.internal_id ?? ''}
              entityName={notification.content.control?.name ?? ''}
              content={t('notification.control.assigned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/controls',
              search: {
                drawerEntity: 'control',
                drawerEntityId: notification.content?.control?.id,
              },
            }),
        };
      } else if (notification.content.task) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={notification.content.task?.internal_id ?? ''}
              entityName={notification.content.task?.name ?? ''}
              content={t('notification.task.assigned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/controls',
              search: {
                drawerEntity: 'task',
                drawerEntityId: notification.content?.task?.id,
              },
            }),
        };
      } else if (notification.content.policy) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={notification.content.policy?.internal_id ?? ''}
              entityName={notification.content.policy?.name ?? ''}
              content={t('notification.policy.assignedAsOwner')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/policies',
              search: {
                drawerEntity: 'policy',
                drawerEntityId: notification.content?.policy?.id,
              },
            }),
        };
      } else if (notification.content.notifications_risk) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={notification.content.notifications_risk?.internal_id ?? ''}
              entityName={notification.content.notifications_risk?.name ?? ''}
              content={t('notification.risk.assigned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/risks',
              search: {
                drawerEntity: 'risk',
                drawerEntityId: notification.content?.notifications_risk?.id,
              },
            }),
        };
      } else if (notification.content.notifications_vendor) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={notification.content.notifications_vendor?.internal_id ?? ''}
              entityName={notification.content.notifications_vendor?.name ?? ''}
              content={t('notification.vendor.assigned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/vendors',
              search: {
                drawerEntity: 'vendor',
                drawerEntityId: notification.content?.notifications_vendor?.id,
              },
            }),
        };
      } else if (notification.content.policy_version) {
        const type =
          'type' in notification.content.params
            ? notification.content.params.type
            : 'approval-request';

        if (type === 'approval-request') {
          return {
            render: () => (
              <AssigneeAndMentionContent
                assignor={assignor}
                internalId={notification.content.policy_version?.policy.internal_id ?? ''}
                entityName={notification.content.policy_version?.policy.name ?? ''}
                content={t('notification.policy.approvalAssigned')}
              />
            ),
            redirect: () =>
              navigate({
                to: '/policies',
                search: {
                  drawerEntity: 'policy-version',
                  drawerEntityId: `${notification.content.policy_version?.policy.id}:${notification.content.policy_version?.id}`,
                },
              }),
          };
        } else {
          return {
            render: () => (
              <AssigneeAndMentionContent
                assignor={assignor}
                internalId={notification.content.policy_version?.policy.internal_id ?? ''}
                entityName={notification.content.policy_version?.policy.name ?? ''}
                content={t('notification.policy.acknowledgementAssigned')}
              />
            ),
            redirect: () =>
              navigate({
                to: '/policies',
                search: {
                  drawerEntity: 'policy-version',
                  drawerEntityId: `${notification.content.policy_version?.policy.id}:${notification.content.policy_version?.id}`,
                },
              }),
          };
        }
      } else if (notification.content.notifications_evidence) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={notification.content.notifications_evidence?.internal_id ?? ''}
              entityName={notification.content.notifications_evidence?.name ?? ''}
              content={t('notification.evidence.assigned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/evidence',
              search: {
                drawerEntity: 'evidence',
                drawerEntityId: notification.content?.notifications_evidence?.id,
              },
            }),
        };
      }

      return null;
    }
    case Notification_Types_Enum.MentionedInComment: {
      if (notification.content.notifications_comment?.comments_control) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={
                notification.content.notifications_comment?.comments_control?.internal_id ?? ''
              }
              entityName={notification.content.notifications_comment?.comments_control?.name ?? ''}
              content={t('notification.control.mentioned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/controls',
              search: {
                drawerEntity: 'control',
                drawerEntityId: notification.content?.notifications_comment?.comments_control?.id,
                activeTab: 'comments',
              },
            }),
        };
      } else if (notification.content.notifications_comment?.comments_task) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={
                notification.content.notifications_comment?.comments_task?.internal_id ?? ''
              }
              entityName={notification.content.notifications_comment?.comments_task?.name ?? ''}
              content={t('notification.task.mentioned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/controls',
              search: {
                drawerEntity: 'task',
                drawerEntityId: notification.content?.notifications_comment?.comments_task?.id,
                activeTab: 'comments',
              },
            }),
        };
      } else if (notification.content.notifications_comment?.comments_risk) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={
                notification.content.notifications_comment?.comments_risk?.internal_id ?? ''
              }
              entityName={notification.content.notifications_comment?.comments_risk?.name ?? ''}
              content={t('notification.risk.mentioned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/risks',
              search: {
                drawerEntity: 'risk',
                drawerEntityId: notification.content?.notifications_comment?.comments_risk?.id,
                activeTab: 'comments',
              },
            }),
        };
      } else if (notification.content.notifications_comment?.comments_vendor) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={
                notification.content.notifications_comment?.comments_vendor?.internal_id ?? ''
              }
              entityName={notification.content.notifications_comment?.comments_vendor?.name ?? ''}
              content={t('notification.vendor.mentioned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/vendors',
              search: {
                drawerEntity: 'vendor',
                drawerEntityId: notification.content?.notifications_comment?.comments_vendor?.id,
                activeTab: 'comments',
              },
            }),
        };
      } else if (notification.content.notifications_comment?.comments_policy) {
        return {
          render: () => (
            <AssigneeAndMentionContent
              assignor={assignor}
              internalId={
                notification.content.notifications_comment?.comments_policy?.internal_id ?? ''
              }
              entityName={notification.content.notifications_comment?.comments_policy?.name ?? ''}
              content={t('notification.policy.mentioned')}
            />
          ),
          redirect: () =>
            navigate({
              to: '/policies',
              search: {
                drawerEntity: 'policy',
                drawerEntityId: notification.content?.notifications_comment?.comments_policy?.id,
                activeTab: 'comments',
              },
            }),
        };
      }
      return null;
    }
    case Notification_Types_Enum.ControlStatusChangedToNotValid: {
      if ('controlStatus' in notification.content.params) {
        const controlStatus = t(
          `controls.status.enums.${notification.content.params.controlStatus}`,
        ).toLowerCase();
        return {
          render: () => (
            <StatusUpdateContent
              content={t('notification.control.notValid', { controlStatus })}
              internalId={notification.content.control?.internal_id ?? ''}
              entityName={notification.content.control?.name ?? ''}
            />
          ),
          redirect: () =>
            navigate({
              to: '/controls',
              search: {
                drawerEntity: 'control',
                drawerEntityId: notification.content?.control?.id,
              },
            }),
        };
      }
      return null;
    }
    case Notification_Types_Enum.RiskStatusChangedToExpiring: {
      if ('riskStatus' in notification.content.params) {
        const riskStatus = t(
          `risks.enum.assessmentStatus.${notification.content.params.riskStatus}`,
        ).toLowerCase();
        return {
          render: () => (
            <StatusUpdateContent
              content={t('notification.risk.needsReview', { riskStatus })}
              internalId={notification.content.notifications_risk?.internal_id ?? ''}
              entityName={notification.content.notifications_risk?.name ?? ''}
            />
          ),
          redirect: () =>
            navigate({
              to: '/risks',
              search: {
                drawerEntity: 'risk',
                drawerEntityId: notification.content?.notifications_risk?.id,
              },
            }),
        };
      }
      return null;
    }
    case Notification_Types_Enum.VendorStatusChangedToExpiring: {
      if ('vendorStatus' in notification.content.params) {
        const vendorStatus = t(
          `vendors.enum.assessmentStatus.${notification.content.params.vendorStatus}`,
        ).toLowerCase();
        return {
          render: () => (
            <StatusUpdateContent
              content={t('notification.vendor.needsReview', { vendorStatus })}
              internalId={notification.content.notifications_vendor?.internal_id ?? ''}
              entityName={notification.content.notifications_vendor?.name ?? ''}
            />
          ),
          redirect: () =>
            navigate({
              to: '/vendors',
              search: {
                drawerEntity: 'vendor',
                drawerEntityId: notification.content?.notifications_vendor?.id,
              },
            }),
        };
      }
      return null;
    }
    case Notification_Types_Enum.QuestionnaireStatusOverdue: {
      if ('questionnaireStatus' in notification.content.params) {
        const questionnaireStatus = t(
          `vendors.questionnaires.status.${notification.content.params.questionnaireStatus}`,
        ).toLowerCase();
        const questionnaireName =
          notification.content.notifications_vendor_questionnaire?.questionnaire.name;
        const vendorName = notification.content.notifications_vendor_questionnaire?.vendor.name;
        const vendorId = notification.content.notifications_vendor_questionnaire?.vendor.id;

        return {
          render: () => (
            <Text fontSize="sm" textColor={notificationTextColor}>
              <Trans
                i18nKey="notification.vendorQuestionnaireStatus"
                values={{
                  questionnaireName,
                  vendorName,
                  questionnaireStatus,
                }}
                components={{ bold: <strong /> }}
              />
            </Text>
          ),
          redirect: () =>
            navigate({
              to: '/vendors',
              search: {
                activeTab: 'questionnaires',
                drawerEntity: 'vendor',
                drawerEntityId: vendorId,
              },
            }),
        };
      }
      return null;
    }
    case Notification_Types_Enum.PolicyApprovalReminder: {
      if (
        'approvalReminderTo' in notification.content.params &&
        notification.content.params.approvalReminderTo === 'approval_creator'
      ) {
        const policyApprovalStatus = t(
          `notification.policy.policyApprovalStatus.${notification.content.params.policyApprovalStatus}`,
        );

        return {
          render: () => (
            <StatusUpdateContent
              content={t('notification.policy.creatorNeedsReview', {
                policyApprovalStatus,
              })}
              internalId={notification.content.policy_version?.policy?.internal_id ?? ''}
              entityName={notification.content.policy_version?.policy?.name ?? ''}
            />
          ),
          redirect: () =>
            navigate({
              to: '/policies',
              search: {
                drawerEntity: 'policy-version',
                drawerEntityId: `${notification.content.policy_version?.policy.id}:${notification.content.policy_version?.id}`,
                activeTab: 'approvals',
              },
            }),
        };
      } else if ('policyApprovalStatus' in notification.content.params) {
        const policyApprovalStatus = t(
          `notification.policy.policyApprovalStatus.${notification.content.params.policyApprovalStatus}`,
        );

        return {
          render: () => (
            <StatusUpdateContent
              content={t('notification.policy.approverNeedsReview', {
                policyApprovalStatus,
              })}
              internalId={notification.content.policy_version?.policy?.internal_id ?? ''}
              entityName={notification.content.policy_version?.policy?.name ?? ''}
            />
          ),
          redirect: () =>
            navigate({
              to: '/policies',
              search: {
                drawerEntity: 'policy-version',
                drawerEntityId: `${notification.content.policy_version?.policy.id}:${notification.content.policy_version?.id}`,
                activeTab: 'approvals',
              },
            }),
        };
      }
      return null;
    }
    case Notification_Types_Enum.PolicyAcknowledgementReminder: {
      if ('policyAcknowledgementStatus' in notification.content.params) {
        const policyAcknowledgementStatus = t(
          `notification.policy.policyAcknowledgementStatus.${notification.content.params.policyAcknowledgementStatus}`,
        );

        return {
          render: () => (
            <StatusUpdateContent
              content={t('notification.policy.needsAcknowledgement', {
                policyAcknowledgementStatus,
              })}
              internalId={notification.content.policy_version?.policy?.internal_id ?? ''}
              entityName={notification.content.policy_version?.policy?.name ?? ''}
            />
          ),
          redirect: () =>
            navigate({
              to: '/policies',
              search: {
                drawerEntity: 'policy-version',
                drawerEntityId: `${notification.content.policy_version?.policy.id}:${notification.content.policy_version?.id}`,
              },
            }),
        };
      }
      return null;
    }
    case Notification_Types_Enum.EvidenceAccessGranted: {
      return {
        render: () => (
          <AssigneeAndMentionContent
            assignor={assignor}
            internalId={notification.content.notifications_evidence?.internal_id ?? ''}
            entityName={notification.content.notifications_evidence?.name ?? ''}
            content={t('notification.evidence.accessGranted')}
          />
        ),
        redirect: () =>
          navigate({
            to: '/evidence',
            search: {
              drawerEntity: 'evidence',
              drawerEntityId: notification.content?.notifications_evidence?.id,
            },
          }),
      };
    }
    case Notification_Types_Enum.OverdueTasksCreatedByYou:
    case Notification_Types_Enum.OverdueTasksAssignedToYou: {
      if ('taskStatus' in notification.content.params) {
        const taskStatus = t(
          `tasks.enum.status.${notification.content.params.taskStatus}`,
        ).toLowerCase();
        return {
          render: () => (
            <StatusUpdateContent
              content={t('notification.task.needsReview', { taskStatus })}
              internalId={notification.content.task?.internal_id ?? ''}
              entityName={notification.content.task?.name ?? ''}
            />
          ),
          redirect: () =>
            navigate({
              to: '/controls',
              search: {
                drawerEntity: 'task',
                drawerEntityId: notification.content?.task?.id,
              },
            }),
        };
      }
      return null;
    }
    case Notification_Types_Enum.VendorQuestionnaireSubmission: {
      if (
        'vqAfterSubmissionEvent' in notification.content.params &&
        notification.content.params.vqAfterSubmissionEvent === 'vq-ai-review-findings-found'
      ) {
        const questionnaireName =
          notification.content.notifications_vendor_questionnaire?.questionnaire.name;
        const vendorName = notification.content.notifications_vendor_questionnaire?.vendor.name;
        const vendorQuestionnaireId = notification.content.notifications_vendor_questionnaire?.id;

        return {
          render: () => (
            <Text fontSize="sm" textColor={notificationTextColor}>
              <Trans
                i18nKey="notification.vendorQuestionnaireHasAIReviewFindings"
                values={{
                  questionnaireName,
                  vendorName,
                }}
                components={{ bold: <strong /> }}
              />
            </Text>
          ),
          redirect: () =>
            navigate({
              to: '/vendors',
              search: {
                drawerEntity: 'vendor-questionnaire',
                drawerEntityId: vendorQuestionnaireId,
                activeTab: 'findings',
              },
            }),
        };
      } else {
        const questionnaireName =
          notification.content.notifications_vendor_questionnaire?.questionnaire.name;
        const vendorEmail = notification.content.notifications_vendor_questionnaire?.sent_to_email;
        const vendorQuestionnaireId = notification.content.notifications_vendor_questionnaire?.id;

        return {
          render: () => (
            <Text fontSize="sm" textColor={notificationTextColor}>
              <Trans
                i18nKey="notification.vendorQuestionnaireSubmission"
                values={{
                  questionnaireName,
                  vendorEmail,
                }}
                components={{ bold: <strong /> }}
              />
            </Text>
          ),
          redirect: () =>
            navigate({
              to: '/vendors',
              search: {
                drawerEntity: 'vendor-questionnaire',
                drawerEntityId: vendorQuestionnaireId,
                activeTab: 'submissions',
              },
            }),
        };
      }
    }
    default:
      return null;
  }
};

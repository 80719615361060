type RaceResult<T extends { [key: string]: Promise<unknown> }> = {
  [K in keyof T]: { key: K; value: Awaited<T[K]> };
}[keyof T];

export function promiseRaceObject<T extends { [key: string]: Promise<unknown> }>(
  promises: T,
): Promise<RaceResult<T>> {
  return new Promise((resolve, reject) => {
    const keys = Object.keys(promises);

    keys.forEach((key: keyof T) => {
      promises[key]
        ?.then((value) => {
          resolve({ key, value } as RaceResult<T>);
        })
        .catch(reject);
    });
  });
}

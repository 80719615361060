/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type PolicyDrawerFragmentFragment = {
  __typename?: 'policies';
  id: string;
  name?: string;
  type: Types.Policy_Types_Enum;
  status: Types.Policy_Statuses_Enum;
  internal_id?: string;
  description?: string;
  created_at: string;
  organization_id: string;
  assignee_id?: string;
  policy_versions: Array<{
    __typename?: 'policy_versions';
    id: string;
    version_id: number;
    status: Types.Policy_Version_Statuses_Enum;
    policy_text?: string;
    created_at: string;
    validity_start?: string;
    created_by?: string;
    revision_details?: string;
    file?: { __typename?: 'files'; id: string; name?: string; size?: number };
    policy_approvals: Array<{
      __typename?: 'policy_approvals';
      id: string;
      status: Types.Policy_Approval_Statuses_Enum;
      due_date: string;
      created_by: string;
      policy_approval_users: Array<{
        __typename?: 'policy_approval_users';
        id: string;
        user_id: string;
        approved_at?: string;
        policy_approval_id: string;
      }>;
    }>;
    policy_acknowledgements: Array<{
      __typename?: 'policy_acknowledgements';
      id: string;
      status: Types.Policy_Acknowledgement_Statuses_Enum;
      due_date: string;
      created_by: string;
      policy_acknowledgement_users: Array<{
        __typename?: 'policy_acknowledgement_users';
        id: string;
        user_id: string;
        acknowledged_at?: string;
        policy_acknowledgement_id: string;
      }>;
    }>;
  }>;
  policy_approvers: Array<{
    __typename?: 'policy_approvers';
    id: string;
    user: { __typename?: 'users'; id: string; displayName: string };
  }>;
};

export const PolicyDrawerFragmentFragmentDoc = `
    fragment PolicyDrawerFragment on policies {
  id
  name
  type
  status
  internal_id
  description
  created_at
  organization_id
  assignee_id
  policy_versions(order_by: {created_at: desc}) {
    id
    version_id
    status
    policy_text
    created_at
    validity_start
    created_by
    revision_details
    file {
      id
      name
      size
    }
    policy_approvals(order_by: {created_at: desc}) {
      id
      status
      due_date
      created_by
      policy_approval_users {
        id
        user_id
        approved_at
        policy_approval_id
      }
    }
    policy_acknowledgements(order_by: {created_at: desc}) {
      id
      status
      due_date
      created_by
      policy_acknowledgement_users {
        id
        user_id
        acknowledged_at
        policy_acknowledgement_id
      }
    }
  }
  policy_approvers(order_by: {created_at: asc}) {
    id
    user {
      id
      displayName
    }
  }
}
    `;

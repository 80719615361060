import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { PickDynamicFormFieldConfig, QuestionnaireComponentKind } from '@main/dynamic-form';
import {
  FormFieldCondition,
  FormFieldConditionGroup,
  FormFieldConditionParent,
  PickFormFieldCondition,
  QuestionnaireFormFieldConfig,
} from '@main/questionnaires-form';
import { useStableCallback } from '@main/shared/utils';
import { Select, useAlertDialog } from '@main/ui';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { ComponentType, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface EditFieldConditionModalProps extends Omit<ModalProps, 'children'> {
  field?: QuestionnaireFormFieldConfig;
  formConfig?: QuestionnaireFormFieldConfig[];
  affectedConditionalFields?: QuestionnaireFormFieldConfig[];
  onSubmit(condition?: FormFieldCondition): Promise<void>;
}

type OptionsFormFieldConfig = PickDynamicFormFieldConfig<
  QuestionnaireFormFieldConfig,
  QuestionnaireComponentKind.Options
>;

export function EditFieldConditionModal(props: EditFieldConditionModalProps) {
  const { field, formConfig, onSubmit, ...modalProps } = props;

  const { t } = useTranslation();
  const labelColor = useColorModeValue('gray.700', 'gray.300');
  const isConditionalOrEnabledFlag = useFeatureFlagEnabled('questionnaire-conditional-or');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const optionsFields = useMemo(
    () =>
      formConfig?.filter(
        (f): f is OptionsFormFieldConfig =>
          f.kind === QuestionnaireComponentKind.Options && f.name !== field?.name,
      ),
    [field?.name, formConfig],
  );

  const [rootLogic, setRootLogic] = useState<FormFieldConditionGroup['logic']>(
    getRootLogic(field?.condition),
  );

  useEffect(() => setRootLogic(getRootLogic(field?.condition)), [field?.condition]);

  const getFieldCondition = useCallback(
    (prevCondition?: FormFieldCondition): FormFieldConditionGroup => {
      const conditions =
        prevCondition?.kind === 'group' ? prevCondition.conditions : [createParentCondition()];

      return field?.condition?.kind === 'group'
        ? {
            ...field.condition,
            logic: rootLogic,
            conditions:
              field.condition.conditions.length > 0 ? field.condition.conditions : conditions,
          }
        : {
            kind: 'group',
            logic: rootLogic,
            conditions,
          };
    },
    [field, rootLogic],
  );

  const [rootCondition, setRootCondition] = useState<FormFieldConditionGroup>(getFieldCondition);

  useEffect(() => setRootCondition(getFieldCondition), [getFieldCondition]);

  const resetConditions = useStableCallback(() => {
    setRootLogic(getRootLogic());
    setRootCondition(getFieldCondition());
  });

  useEffect(() => {
    if (!modalProps.isOpen) {
      resetConditions();
    }
  }, [modalProps.isOpen, resetConditions]);

  const validConditions = useMemo(
    () =>
      rootCondition.conditions.filter(
        (c) => c.kind === 'parent' && c.fieldName !== '' && c.values.length > 0,
      ),
    [rootCondition.conditions],
  );

  const isRecursive = useMemo(
    () =>
      rootCondition.conditions.some((c) =>
        props.affectedConditionalFields?.some((f) => c.kind === 'parent' && c.fieldName === f.name),
      ),
    [rootCondition.conditions, props.affectedConditionalFields],
  );

  const isValid = useMemo(
    () => validConditions.length === rootCondition.conditions.length,
    [rootCondition.conditions.length, validConditions.length],
  );
  const canReset = useMemo(
    () =>
      !isValid &&
      field?.condition?.kind === 'group' &&
      field.condition.conditions.length > 0 &&
      rootCondition.conditions.length === 1 &&
      rootCondition.conditions[0]?.kind === 'parent' &&
      rootCondition.conditions[0]?.fieldName === '' &&
      rootCondition.conditions[0]?.values.length === 0,
    [field?.condition, isValid, rootCondition.conditions],
  );

  const addCondition = useCallback(() => {
    setRootCondition((c) => ({
      ...c,
      conditions: [...c.conditions, createParentCondition()],
    }));
  }, [setRootCondition]);

  const removeCondition = useCallback(
    (condition: FormFieldCondition) => {
      setRootCondition((c) => {
        const newConditions = c.conditions.filter((cond) => cond !== condition);
        return {
          ...c,
          conditions: newConditions.length ? newConditions : [createParentCondition()],
        };
      });
    },
    [setRootCondition],
  );

  const updateCondition = useCallback(
    (condition: FormFieldCondition, oldCondition: FormFieldCondition) => {
      setRootCondition((c) => {
        return {
          ...c,
          conditions: c.conditions.map((cond) => (cond === oldCondition ? condition : cond)),
        };
      });
    },
    [setRootCondition],
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit(
        validConditions.length > 0 ? { ...rootCondition, conditions: validConditions } : undefined,
      );
      props.onClose();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal size="xl" {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('questionnaires.questions.conditionalModal.heading')}</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack spacing={6}>
            <Text fontSize="md" color={labelColor}>
              {field?.label}
            </Text>

            {isConditionalOrEnabledFlag && (
              <FormControl mt={6}>
                <FormLabel>{t('questionnaires.questions.conditionalModal.logic.label')}</FormLabel>
                <Select
                  options={[
                    {
                      label: t('questionnaires.questions.conditionalModal.andLabel'),
                      value: 'and',
                    },
                    { label: t('questionnaires.questions.conditionalModal.orLabel'), value: 'or' },
                  ]}
                  value={{
                    label: t(`questionnaires.questions.conditionalModal.${rootLogic}Label`),
                    value: rootLogic,
                  }}
                  onChange={(value) => value && setRootLogic(value.value)}
                  placeholder={t('questionnaires.questions.conditionalModal.logic.placeholder')}
                  useBasicStyles
                />
              </FormControl>
            )}

            <FieldCondition
              condition={rootCondition}
              optionsFields={optionsFields}
              removeCondition={removeCondition}
              updateCondition={updateCondition}
            />

            <Box>
              <Button leftIcon={<Icon as={PlusIcon} />} onClick={addCondition}>
                {t('questionnaires.questions.conditionalModal.addCondition')}
              </Button>
            </Box>

            {isRecursive && (
              <Alert status="warning" variant="left-accent">
                <AlertIcon />
                <Box>{t('questionnaires.questions.conditionalModal.recursiveCondition')}</Box>
              </Alert>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button onClick={props.onClose}>{t('buttons.cancel')}</Button>
            <Button
              colorScheme="blue"
              isLoading={isSubmitting}
              isDisabled={!(isValid || canReset)}
              onClick={handleSubmit}
            >
              {t(`questionnaires.questions.conditionalModal.submitLabel`)}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function getRootLogic(
  condition?: FormFieldCondition,
  defaultLogic: FormFieldConditionGroup['logic'] = 'and',
): FormFieldConditionGroup['logic'] {
  return condition?.kind === 'group' ? condition.logic : defaultLogic;
}

function createParentCondition(): FormFieldConditionParent {
  return {
    kind: 'parent',
    fieldName: '',
    values: [],
    onMatch: 'show',
    byDefault: 'hide',
  };
}

interface FieldConditionProps<T extends FormFieldCondition = FormFieldCondition> {
  condition: T;
  optionsFields?: OptionsFormFieldConfig[];
  removeCondition(condition: FormFieldCondition): void;
  updateCondition(condition: FormFieldCondition, oldCondition: FormFieldCondition): void;
}

function FieldCondition(props: FieldConditionProps) {
  const Condition = fieldConditionComponents[
    props.condition.kind
  ] as ComponentType<FieldConditionProps>;

  return <Condition {...props} />;
}

type ConditionComponents = {
  [K in FormFieldCondition['kind']]: ComponentType<
    FieldConditionProps<PickFormFieldCondition<FormFieldCondition, K>>
  >;
};

/* eslint-disable react-hooks/rules-of-hooks */
const fieldConditionComponents: ConditionComponents = {
  group({ condition, ...rest }) {
    const { t } = useTranslation();
    const labelColor = useColorModeValue('gray.700', 'gray.300');
    const conditionsCount = condition.conditions.length - 1;

    return condition.conditions.map((cond, i) => (
      <Fragment key={cond.kind === 'parent' ? `${cond.fieldName}-${i}` : i}>
        <Card variant="outline" data-group>
          <CardBody p={6}>
            <FieldCondition {...rest} condition={cond} />
          </CardBody>
        </Card>
        {i < conditionsCount && (
          <Text fontSize="md" color={labelColor} fontWeight="semibold">
            {t(
              condition.logic === 'and'
                ? 'questionnaires.questions.conditionalModal.andLabel'
                : 'questionnaires.questions.conditionalModal.orLabel',
            )}
          </Text>
        )}
      </Fragment>
    ));
  },
  parent({ condition, optionsFields, updateCondition, ...rest }) {
    const { t } = useTranslation();
    const labelColor = useColorModeValue('gray.700', 'gray.300');
    const { openDialog } = useAlertDialog();
    const parents = useMemo(
      () => optionsFields?.map((field) => ({ label: field.label, value: field.name })) ?? [],
      [optionsFields],
    );
    const [parentField, setParentField] = useState<OptionsFormFieldConfig | undefined>(() =>
      optionsFields?.find((field) => field.name === condition.fieldName),
    );
    const parentValues = useMemo(
      () =>
        condition.values.map((value) => ({
          value,
          label: parentField?.options?.find((o) => o.value === value)?.label ?? '',
        })),
      [condition.values, parentField?.options],
    );

    return (
      <>
        <Stack direction="row" justifyContent="space-between">
          <Text fontSize="md" color={labelColor}>
            {t('questionnaires.questions.conditionalModal.showWhenLabel')}
          </Text>
          <IconButton
            size="xs"
            title={t('buttons.delete')}
            aria-label={t('buttons.delete')}
            icon={<Icon as={TrashIcon} />}
            display="none"
            _groupHover={{ display: 'block' }}
            onClick={() =>
              openDialog({
                dialogHeader: t('questionnaires.questions.conditionalModal.deleteAlert.header'),
                dialogContent: t('questionnaires.questions.conditionalModal.deleteAlert.content'),
                confirmAction: {
                  children: t('buttons.delete'),
                  onClick: () => rest.removeCondition(condition),
                },
              })
            }
          />
        </Stack>
        <FormControl mt={4}>
          <FormLabel>{t('questionnaires.questions.conditionalModal.questionLabel')}</FormLabel>
          <Select
            options={parents}
            value={parentField && { label: parentField.label, value: parentField.name }}
            onChange={(value) => {
              setParentField(optionsFields?.find((field) => field.name === value?.value));
              updateCondition(
                { ...condition, fieldName: value?.value ?? '', values: [] },
                condition,
              );
            }}
            placeholder={t('questionnaires.questions.conditionalModal.questionPlaceholder')}
            useBasicStyles
          />
        </FormControl>
        <FormControl mt={6}>
          <FormLabel>{t('questionnaires.questions.conditionalModal.answerLabel')}</FormLabel>
          <Select
            isMulti={parentField?.isMultiOptions}
            options={parentField?.options}
            value={parentValues}
            onChange={(values) =>
              updateCondition({ ...condition, values: getOptionsValue(values) }, condition)
            }
            placeholder={t('questionnaires.questions.conditionalModal.answerPlaceholder')}
            useBasicStyles
          />
        </FormControl>
      </>
    );
  },
};
/* eslint-enable react-hooks/rules-of-hooks */

function getOptionsValue<T extends { value: unknown }>(
  options?: T | readonly T[] | null,
): T['value'][] {
  return Array.isArray(options)
    ? options.map((option: T) => option.value)
    : options
      ? [(options as T).value]
      : [];
}

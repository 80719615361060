export interface WithPreventableDefault {
  preventDefault(): void;
}

export function withDefaultPrevented<E extends WithPreventableDefault, T>(
  fn: (event: E) => T,
): (event: E) => T {
  return (event: E) => {
    event.preventDefault();
    return fn(event);
  };
}

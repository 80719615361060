import { assertNotAborted, awaitUntil } from './promise-utils';
import { StreamDownloader } from './stream-downloader';

export interface FileContent {
  content:
    | ReadableStream<Uint8Array>
    | FileSource
    | ((signal?: AbortSignal) => FileSource | Promise<FileSource>);
}

export type FileSource = string | Uint8Array;

export class FileStreamDownloader implements StreamDownloader<FileContent> {
  constructor(protected readonly encoder = new TextEncoder()) {}

  async downloadFile(
    file: FileContent,
    options?: { signal: AbortSignal },
  ): Promise<ReadableStream<Uint8Array>> {
    assertNotAborted(options?.signal);

    if (file.content instanceof ReadableStream) {
      return file.content;
    }

    const getContent = async () => {
      const content =
        typeof file.content === 'function' ? await file.content(options?.signal) : file.content;
      return typeof content === 'string' ? this.encoder.encode(content) : (content as Uint8Array);
    };

    return new ReadableStream({
      async start(controller) {
        if (options?.signal.aborted) {
          return controller.error(options.signal.reason);
        }

        try {
          controller.enqueue(await awaitUntil(getContent(), options?.signal));
        } catch (error) {
          controller.error(error);
        } finally {
          controller.close();
        }
      },
    });
  }
}

import { Button, Card, FormControl, FormLabel, Icon, useDisclosure } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useGetEvidenceByIdQuery } from '@main/graphql/queries/GetEvidenceById.generated';
import { useGetEvidenceVersionsListByEvidenceIdQuery } from '@main/graphql/queries/GetEvidenceVersionsListByEvidenceId.generated';
import { isNonNullable } from '@main/shared/utils';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { ConfidentialBanner } from './confidential-banner';
import { CreateEvidenceVersionModal, EvidenceVersionTable } from './evidence-versions';
import { getMappedEvidenceVersions } from './slice';
import { useIsUserAuthorizedToChangeEvidence, useIsUserAuthorizedToViewVersions } from './utils';

export const EvidenceVersionsTab = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: evidenceData } = useGetEvidenceByIdQuery({ evidence_id: evidenceId });

  const isUserAuthorizedToViewVersions = useIsUserAuthorizedToViewVersions(
    evidenceData?.evidences_by_pk,
  );
  const isUserAuthorizedToReplaceVersion = useIsUserAuthorizedToChangeEvidence(
    evidenceData?.evidences_by_pk,
  );

  const { refetch: refetchEvidenceVersions } = useGetEvidenceVersionsListByEvidenceIdQuery({
    evidenceId,
  });
  const { currentVersion, pastVersions } = useAppSelector((state) =>
    getMappedEvidenceVersions(state, evidenceId),
  );
  const currentVersions = [currentVersion].filter(isNonNullable);

  if (!isUserAuthorizedToViewVersions) {
    return <ConfidentialBanner />;
  }

  return (
    <>
      <FormControl>
        <FormLabel>{t('evidences.version.currentVersion')}</FormLabel>
        <Card variant="table-styles">
          <EvidenceVersionTable evidenceId={evidenceId} data={currentVersions} isCurrent />
        </Card>
      </FormControl>
      {isUserAuthorizedToReplaceVersion && (
        <Button
          leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={5} h={5} as={PlusIcon} />}
          colorScheme="blue"
          variant="solid"
          onClick={onOpen}
          maxW="fit-content"
          alignSelf="end"
        >
          {currentVersion
            ? t('evidences.buttons.replaceEvidence')
            : t('evidences.buttons.addNewVersion')}
        </Button>
      )}

      {pastVersions.length > 0 && (
        <FormControl mt="6">
          <FormLabel>{t('evidences.version.pastVersions')}</FormLabel>
          <Card variant="table-styles">
            <EvidenceVersionTable evidenceId={evidenceId} data={pastVersions} />
          </Card>
        </FormControl>
      )}
      <CreateEvidenceVersionModal
        evidenceId={evidenceId}
        isOpen={isOpen}
        onCreate={() => {
          refetchEvidenceVersions();
          onClose();
        }}
        onClose={onClose}
      />
    </>
  );
};

import * as nhost from '@nhost/nhost-js';

// ESM hack
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
const { NHOST_REFRESH_TOKEN_KEY } = ((nhost as any).default ?? nhost) as typeof nhost;

export function clearNhostSession() {
  Object.keys(localStorage).forEach((key) => {
    if (key.includes(NHOST_REFRESH_TOKEN_KEY)) {
      localStorage.removeItem(key);
    }
  });
}

import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { Policy_Approval_Statuses_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { ApprovalUser } from './approvers-table';
import { useSendApprovalReminderMutation } from './manage-policies.generated';

export function SendReminder({ row }: { row: ApprovalUser }) {
  const { t } = useTranslation();
  const currentUserId = useAppSelector(getCurrentUserId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdatePolicy = userRole.permissionMap?.write_policies;
  const [sendReminder, { isLoading }] = useSendApprovalReminderMutation();

  const handleReminder = async () => {
    try {
      await sendReminder({
        input: {
          approvalId: row.policy_approval_id,
          approverId: row.user_id,
          senderId: currentUserId,
        },
      }).unwrap();

      successToast(t('policies.approval.successfullyReminded'));
    } catch (error) {
      errorToast(t('policies.approval.failedToRemind'));
      datadogLogs.logger.error(
        'Failed to send approval reminder',
        { approvalId: row.policy_approval_id, approverId: row.user_id },
        toError(error),
      );
    }
  };

  /**
   * To send policy approval reminder following conditions should be met:
   *
   * Sender should have permission to update policy
   * Approver should not have approved already
   * Sender can not send reminder to himself
   * Approval should not Retired
   */

  if (
    !canUpdatePolicy ||
    !!row.approved_at ||
    row.user_id === currentUserId ||
    row.approvalStatus === Policy_Approval_Statuses_Enum.Retired
  ) {
    return;
  }

  return (
    <Tooltip label={t('policies.approval.sendReminder')} placement="top">
      <IconButton
        minW={4}
        variant="link"
        isLoading={isLoading}
        aria-label={t('policies.approval.sendReminder')}
        icon={<Icon as={BellAlertIcon} />}
        onClick={handleReminder}
      />
    </Tooltip>
  );
}

import {
  Box,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { OverflowContainer } from '../overflow-tooltip';

export const ProgressStatCard = ({
  onClick,
  progressPercentage,
  progressInfo,
  title,
  subTitle,
}: {
  onClick: () => void;
  progressPercentage: number;
  progressInfo?: string;
  title: string;
  subTitle?: string;
}) => {
  const borderColor = useColorModeValue('gray.300', 'gray.500');
  const progressTextColor = useColorModeValue('gray.700', 'gray.400');

  return (
    <Center
      border="1px"
      borderColor={borderColor}
      rounded="lg"
      h="135px"
      w="140px"
      p={2}
      cursor="pointer"
      onClick={onClick}
      shadow="base"
    >
      <Stack spacing={2} overflow="hidden">
        <Center>
          <CircularProgress
            value={progressPercentage}
            color="green.400"
            size="65px"
            thickness="5px"
          >
            <CircularProgressLabel color={progressTextColor} lineHeight={3}>
              <Text as="b" fontSize="xs">
                {Math.round(progressPercentage)}%
              </Text>
              <Text fontSize="x-small">{progressInfo}</Text>
            </CircularProgressLabel>
          </CircularProgress>
        </Center>
        <Box>
          <OverflowContainer>
            <OverflowContainer.Tooltip label={title} fontSize="sm" openDelay={500}>
              <Text isTruncated fontWeight="semibold" width="full" align="center">
                {title}
              </Text>
            </OverflowContainer.Tooltip>
          </OverflowContainer>
          <Text fontSize="xs" align="center">
            {subTitle}
          </Text>
        </Box>
      </Stack>
    </Center>
  );
};

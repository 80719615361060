export interface CSVSerializeOptions {
  delimiter?: string;
  rowDelimiter?: string;
  emptyValue?: string;
}

export type CSVValues<THeaders extends readonly string[]> = {
  [TKey in keyof THeaders]: unknown;
};

export function serializeCSV<THeaders extends readonly string[]>(
  headers: THeaders,
  data: readonly Readonly<CSVValues<THeaders>>[],
  { delimiter = ',', rowDelimiter = '\n', emptyValue }: CSVSerializeOptions = {},
): string {
  const mapValue = (value: unknown) => safeCSVValue(value, emptyValue);

  return `${headers.map(mapValue).join(delimiter)}${rowDelimiter}${data
    .map((row) => row.map(mapValue).join(delimiter))
    .join(rowDelimiter)}`;
}

export function safeCSVValue(value: unknown, emptyValue = '') {
  return value ? `"${String(value).replace(/"/g, '""')}"` : emptyValue;
}

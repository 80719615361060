import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { toError, useStableCallback } from '@main/shared/utils';
import { AutoResizeTextarea, EditableMultiSelectAvatar, errorToast, successToast } from '@main/ui';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { dateToMidnightUTC } from '../../utils/date';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import {
  useCreatePolicyAcknowledgementMutation,
  useGetPolicyAcknowledgersQuery,
} from './manage-policies.generated';
import { usePreferredPolicyVersion } from './use-preferred-policy-version';

const formSchema = z
  .object({
    acknowledgers: z
      .array(
        z.object({
          id: z.string(),
          displayName: z.string(),
        }),
      )
      .optional(),
    allAcknowledgers: z.boolean(),
    dueDate: z.string().min(1).transform(dateToMidnightUTC),
    emailSubject: z.string().min(1, 'Required'),
    emailMessage: z.string().optional(),
  })
  .refine(
    (data) => {
      if (data.allAcknowledgers) {
        return true;
      }

      if (data.acknowledgers && data.acknowledgers.length >= 1) {
        return true;
      }

      return false;
    },
    {
      path: ['acknowledgers'],
    },
  );

type FormSchema = z.infer<typeof formSchema>;

export interface SendAcknowledgementProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SendAcknowledgementModal = (props: SendAcknowledgementProps) => {
  const { t } = useTranslation();

  return (
    <Modal size="xl" isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          {t('policies.acknowledgement.form.title')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={2}>
          <SendAcknowledgementForm {...props} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export function SendAcknowledgementForm({ isOpen, onClose }: SendAcknowledgementProps) {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const { data, isLoading: isFetchingAcknowledgers } = useGetPolicyAcknowledgersQuery(
    { orgId },
    { skip: !isOpen },
  );
  const acknowledgers = useMemo(
    () =>
      data?.organization_users.map(({ user }) => ({ id: user.id, displayName: user.displayName })),
    [data?.organization_users],
  );
  const [createAcknowledgement, { isLoading: isCreatingAcknowledgement }] =
    useCreatePolicyAcknowledgementMutation();

  const currentVersion = usePreferredPolicyVersion();
  const [selectedAcknowledgers, setSelectedAcknowledgers] = useState<FormSchema['acknowledgers']>();
  const {
    register,
    formState,
    handleSubmit,
    control,
    clearErrors,
    watch,
    setValue,
    getValues,
    trigger,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    shouldFocusError: false,
    defaultValues: {
      dueDate: dayjs().add(7, 'days').format('YYYY-MM-DD'),
      allAcknowledgers: false,
      emailSubject: t('policies.acknowledgement.form.defaultMessageTitle'),
    },
  });

  const check = watch('allAcknowledgers');

  const stashSelectedAcknowledgers = useStableCallback(() => {
    clearErrors('acknowledgers');
    setSelectedAcknowledgers(getValues().acknowledgers);
    setValue('acknowledgers', []);
  });

  const restoreSelectedAcknowledgers = useStableCallback(() => {
    setValue('acknowledgers', selectedAcknowledgers);
  });

  useEffect(() => {
    if (check) {
      stashSelectedAcknowledgers();
    } else {
      restoreSelectedAcknowledgers();
    }
  }, [check, restoreSelectedAcknowledgers, stashSelectedAcknowledgers]);

  useEffect(() => watch(() => trigger()).unsubscribe, [trigger, watch]);

  const onSubmit = handleSubmit(async (data) => {
    const acknowledgementUsers = data.allAcknowledgers ? acknowledgers : data.acknowledgers;

    try {
      await createAcknowledgement({
        input: {
          due_date: data.dueDate,
          policy_version_id: currentVersion?.id,
          message: data.emailMessage,
          subject: data.emailSubject,
          policy_acknowledgement_users: {
            data: acknowledgementUsers?.map((acknowledger) => ({ user_id: acknowledger.id })) ?? [],
          },
        },
      }).unwrap();

      onClose();
      successToast(t('policies.acknowledgement.successToast'));
    } catch (error) {
      errorToast(t('policies.acknowledgement.errorToast'));
      datadogLogs.logger.error('Creating policy acknowledgement failed', {}, toError(error));
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={6}>
        <FormControl
          isInvalid={!!formState.errors.acknowledgers && formState.isSubmitted}
          isReadOnly={check}
        >
          <FormLabel>{t('policies.acknowledgement.form.props.acknowledgers')}:</FormLabel>
          <Controller
            name="acknowledgers"
            control={control}
            render={({ field }) => (
              <EditableMultiSelectAvatar
                variant="outline"
                isLoading={isFetchingAcknowledgers}
                options={acknowledgers}
                value={field.value}
                placeholder={t('policies.acknowledgement.form.placeholders.acknowledgers')}
                onChange={(values) => {
                  field.onChange(values);
                }}
              />
            )}
          />
          <FormErrorMessage>{t('policies.acknowledgement.errorMessage')}</FormErrorMessage>
        </FormControl>

        <Checkbox {...register('allAcknowledgers')}>
          <Text fontSize="sm">{t('policies.acknowledgement.sendToAllAcknowledgers')}</Text>
        </Checkbox>

        <FormControl isInvalid={!!formState.errors.dueDate && formState.isSubmitted}>
          <FormLabel>{t('policies.acknowledgement.form.props.dueDate')}:</FormLabel>
          <Input type="date" {...register('dueDate')} />
          <FormErrorMessage>{formState.errors.dueDate?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formState.errors.emailSubject && formState.isSubmitted}>
          <FormLabel>{t('policies.acknowledgement.form.props.emailSubject')}:</FormLabel>
          <Input
            {...register('emailSubject')}
            placeholder={t('policies.acknowledgement.form.placeholders.emailSubject')}
          />
          <FormErrorMessage>{formState.errors.emailSubject?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!formState.errors.emailMessage && formState.isSubmitted}>
          <FormLabel>{t('policies.acknowledgement.form.props.emailMessage')}:</FormLabel>
          <AutoResizeTextarea
            {...register('emailMessage')}
            minRows={3}
            placeholder={t('policies.acknowledgement.form.placeholders.emailMessage')}
          />
          <FormErrorMessage>{formState.errors.emailMessage?.message}</FormErrorMessage>
        </FormControl>

        <ButtonGroup justifyContent="end">
          <Button onClick={onClose}>{t('buttons.cancel')}</Button>
          <Button colorScheme="blue" type="submit" isLoading={isCreatingAcknowledgement}>
            {t('buttons.send')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
}

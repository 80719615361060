import { Avatar, Flex, Stack, Tag, Text, useColorModeValue } from '@chakra-ui/react';
import { AtSymbolIcon, CalendarDaysIcon, UserIcon } from '@heroicons/react/24/outline';
import { formatDate, getQuestionnaireType } from '@main/shared/utils';
import { DrawerProperty, useDrawer } from '@main/ui';
import { BarsIcon, TagIcon } from '@main/ui/icons';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { EntityReferenceCard } from '../../shared/entity-reference-card';
import { getFormattedVendorQuestionnaire } from '../slice';

export const VendorQuestionnaireDetailsTab = ({ vqId }: { vqId: string }) => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const vq = useAppSelector((state) => getFormattedVendorQuestionnaire(state, vqId));
  const type = getQuestionnaireType(vq.questionnaire);

  const linkTextColor = useColorModeValue('blue.600', 'blue.300');
  const textColor = useColorModeValue('gray.600', 'gray.300');

  if (!vq) {
    return null;
  }

  return (
    <Stack spacing={[6, 3]}>
      {vq.questionnaire?.description ? (
        <DrawerProperty>
          <DrawerProperty.Label icon={BarsIcon}>
            {t('vendors.questionnaires.tabs.details.description')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Text fontSize="xs" color={textColor}>
              {vq.questionnaire.description}
            </Text>
          </DrawerProperty.Content>
        </DrawerProperty>
      ) : null}

      <DrawerProperty>
        <DrawerProperty.Label icon={TagIcon}>
          {t('vendors.questionnaires.tabs.details.type')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Tag colorScheme="purple" w="fit-content">
            {t(`questionnaires.type.${type}`)}
          </Tag>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={AtSymbolIcon}>
          {t('vendors.questionnaires.tabs.details.sentTo')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Text fontSize="xs" color={linkTextColor} textDecoration="underline">
            {vq?.sent_to_email}
          </Text>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={UserIcon}>
          {t('vendors.questionnaires.tabs.details.sentBy')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2}>
          <Flex gap={2} alignItems="center" h="full">
            <Avatar name={vq?.sent_by_user?.displayName} size="xs" />
            <Text fontSize="xs" color="gray.500" fontWeight="medium">
              {vq?.sent_by_user?.displayName}
            </Text>
          </Flex>
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label icon={CalendarDaysIcon}>
          {t('vendors.questionnaires.tabs.details.dueDate')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center">
          <Text fontSize="xs" color={textColor}>
            {formatDate(vq?.due_date)}
          </Text>
        </DrawerProperty.Content>
      </DrawerProperty>

      <Flex direction="column" gap={2}>
        <Text fontSize="xs" fontWeight="semibold">
          {t('vendors.questionnaires.references')}:
        </Text>
        {vq.vendor && (
          <EntityReferenceCard>
            <EntityReferenceCard.Tags
              tags={[
                {
                  label: `${t('entities.vendor')} / ${vq.vendor.internal_id}`,
                  color: 'purple',
                },
              ]}
            />
            <EntityReferenceCard.Reference
              onClick={() => {
                vq.vendor?.id && drawer.open({ entity: 'vendor', entityId: vq.vendor.id });
              }}
            >
              {vq.vendor.name}
            </EntityReferenceCard.Reference>
          </EntityReferenceCard>
        )}
        {vq.questionnaire && (
          <EntityReferenceCard>
            <EntityReferenceCard.Tags
              tags={[
                {
                  label: `${t('entities.questionnaire')} / ${vq.questionnaire.internal_id}`,
                  color: 'purple',
                },
              ]}
            />
            <EntityReferenceCard.Reference
              onClick={() => {
                vq.questionnaire?.id &&
                  drawer.open({ entity: 'questionnaire', entityId: vq.questionnaire.id });
              }}
            >
              {vq.questionnaire.name}
            </EntityReferenceCard.Reference>
          </EntityReferenceCard>
        )}
      </Flex>
    </Stack>
  );
};

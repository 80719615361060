import { IFuncUpdater, Options } from 'ahooks/es/createUseStorageState';
import _useLocalStorageState from 'ahooks/es/useLocalStorageState';

/**
 * Improve types: when `options.defaultValue` is provided,
 * exclude `undefined` from result and setter
 */
export const useLocalStorageState: {
  <T>(
    key: string,
    options: Options<T> & { defaultValue: T },
  ): readonly [T, (value: T | ((previousValue: T) => T)) => void];

  <T>(
    key: string,
    options?: Options<T>,
  ): readonly [T | undefined, (value?: T | IFuncUpdater<T>) => void];
} = _useLocalStorageState;

import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowUpTrayIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { Client_Questionnaire_Question_Status_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  createColumnHelper,
  DrawerSkeleton,
  errorToast,
  Page404,
  Table,
  TableEmptyState,
  useDownloadStorageFile,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrgGuard } from '../../hooks/use-org-guard';
import { CLIENT_QESTIONNAIRES_QUESTION_STATUSES } from '../../utils/constants';
import { useTableSettings } from '../../utils/table-settings';
import { EditClientQuestionnaireQuestionFragment } from './edit-questionnaire.generated';
import { useGetEditClientQuestionnaireSubscription } from './edit-questionnaire.subs';

export interface EditClientQuestionnaireProps {
  questionnaireId: string;
}

export function EditClientQuestionnaire({ questionnaireId }: EditClientQuestionnaireProps) {
  const { t } = useTranslation();
  const downloadFile = useDownloadStorageFile();
  const { data, isLoading, isFetching } = useOrgGuard(
    useGetEditClientQuestionnaireSubscription({ id: questionnaireId }),
    ({ data }) => data?.clientQuestionnaire?.organization_id,
  );
  const questionnaire = data?.clientQuestionnaire;

  const columns = useEditClientQuestionnaireColumns();
  const { columnVisibility, setColumnVisibility } = useTableSettings({
    storageKey: 'client-questionnaire:table:column-visibility',
  });
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'clientQuestionnaireFilter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({
    searchParam: 'clientQuestionnaireSort',
  });

  const tableItemName = useMemo(
    () => ({
      singular: t('entities.question').toLowerCase(),
      plural: t('entities.plural.questions').toLowerCase(),
    }),
    [t],
  );

  if (isLoading) {
    return <DrawerSkeleton />;
  }

  if (!questionnaire) {
    return <Page404 />;
  }

  const canExport = !questionnaire.export_file?.id;
  async function downloadExportFile() {
    try {
      if (!questionnaire?.export_file?.id) {
        throw new Error(
          `Tried to export non-completed questionnare in status ${questionnaire?.status}`,
        );
      }

      await downloadFile(questionnaire.export_file.id);
    } catch (error) {
      datadogLogs.logger.error(
        'Failed to download Client Questionnare export file',
        { clientQuestionnaireId: questionnaire?.id, exportFileId: questionnaire?.export_file?.id },
        toError(error),
      );
      errorToast(t('errorMessages.fileDownloadFailed'));
    }
  }

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {questionnaire.name}
      </Text>

      <Card variant="table-styles">
        <CardHeader>
          <Box>
            <Heading size="md">{t('clientQuestionnaire.heading')}</Heading>
            <Text variant="subheading">{t('clientQuestionnaire.subheading')}</Text>
          </Box>
          {canExport && (
            <Button
              colorScheme="blue"
              variant="solid"
              leftIcon={<Icon as={ArrowUpTrayIcon} />}
              onClick={downloadExportFile}
            >
              {t('clientQuestionnaire.exportButton')}
            </Button>
          )}
        </CardHeader>
        <CardBody>
          <Table
            minW="900px"
            columns={columns}
            data={questionnaire?.questions || []}
            isLoading={isFetching}
            itemName={tableItemName}
            pageSize={15}
            columnFilters={columnFilters}
            onColumnFiltersChange={setColumnFilters}
            globalFilter={globalFilter}
            onGlobalFilterChange={setGlobalFilter}
            columnVisibility={columnVisibility}
            onColumnVisibilityChange={setColumnVisibility}
            sorting={sorting}
            onSortingChange={setSorting}
            renderEmptyState={({ itemName }) => <TableEmptyState itemName={itemName} />}
          />
        </CardBody>
      </Card>
    </Stack>
  );
}

function useEditClientQuestionnaireColumns() {
  const { t } = useTranslation();

  return useMemo(() => {
    const columnHelper = createColumnHelper<EditClientQuestionnaireQuestionFragment>();

    return [
      columnHelper.columns.tag({
        id: 'status',
        header: t('clientQuestionnaire.tableColumns.status'),
        accessorFn: ({ status }) => {
          const metaType = CLIENT_QESTIONNAIRES_QUESTION_STATUSES[status];
          return {
            value: t(metaType.value),
            colorScheme: metaType.colorScheme,
          };
        },
        enableSorting: true,
        enableColumnFilter: true,
        size: 135,
      }),
      columnHelper.columns.text({
        id: 'question',
        header: t('clientQuestionnaire.tableColumns.question'),
        accessorFn: ({ question }) => question,
        isMultiline: true,
        enableGlobalFilter: true,
        enableSorting: true,
        size: 1,
        meta: {
          sizeUnit: 'fr',
          cell: {
            onClick: (cell) => {
              throw new Error('Question drawer not implemented!');
            },
          },
        },
      }),
      columnHelper.columns.text({
        id: 'answer',
        header: t('clientQuestionnaire.tableColumns.answer'),
        accessorFn: ({ answer }) => answer[0]?.answer,
        isMultiline: true,
        enableGlobalFilter: true,
        enableSorting: true,
        size: 1,
        meta: {
          sizeUnit: 'fr',
        },
        cell: (context) => {
          const value = context.getValue();
          const question = context.row.original;

          if (value) {
            return (
              <HStack p={4}>
                {question.answer[0]?.is_ai_generated && (
                  <Icon color="orange.400" fontSize="md" as={SparklesIcon} />
                )}
                <Text whiteSpace="break-spaces">{value}</Text>
              </HStack>
            );
          }

          return (
            <Text p={4} color="gray.400">
              {t(
                question.status === Client_Questionnaire_Question_Status_Enum.Processing
                  ? 'clientQuestionnaire.processing'
                  : 'clientQuestionnaire.noAnswer',
              )}
            </Text>
          );
        },
      }),
    ];
  }, [t]);
}

import { Icon, IconButton } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useDeleteReportByIdMutation } from '@main/graphql/mutations/DeleteReportById.generated';
import { useGetReportsForOrganizationQuery } from '@main/graphql/queries/GetReportsForOrganization.generated';
import { toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  successToast,
  Table,
  useAlertDialog,
  useTableSearchQuery,
} from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import {
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { getReportTypeOption } from './report-type';
import { getOrganizationReportsList, OrganizationReport } from './slice';

export const ReportsTable = () => {
  const { t } = useTranslation();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const { isLoading: isReportLoading } = useGetReportsForOrganizationQuery({
    organizationId: organization.id,
  });

  const data = useAppSelector((state) => getOrganizationReportsList(state, organization.id));

  const columns = useReportsTableColumns();
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.report').toLowerCase(),
      plural: t('entities.plural.reports').toLowerCase(),
    };
  }, [t]);

  return (
    <Table
      minW="900px"
      data={data}
      isLoading={isReportLoading}
      columns={columns}
      pageSize={15}
      itemName={tableItemName}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
    />
  );
};

function useReportsTableColumns() {
  const { t } = useTranslation();
  const navigate = useNavigate({ from: '/reports' });
  const { openDialog } = useAlertDialog();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canWriteReports = userRole.permissionMap?.write_reports;
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const organizationId = organization.id;
  const { refetch: refetchReportsList } = useGetReportsForOrganizationQuery({ organizationId });
  const [deleteReport, { isLoading: isDeletingReport }] = useDeleteReportByIdMutation();

  const onDelete = useCallback(
    async (id: string) => {
      try {
        await deleteReport({ id }).unwrap();
        await refetchReportsList().unwrap();
        successToast(t('successMessages.deleteSucceeded', { entity: t('entities.report') }));
      } catch (error) {
        errorToast(t('errorMessages.deleteFailed', { entity: t('entities.report') }));
        datadogLogs.logger.error('Failed to delete report', {}, toError(error));
      }
    },
    [deleteReport, refetchReportsList, t],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationReport>();

    const onReportOpen = (selectedReportId: string) => {
      navigate({
        to: '/reports/$reportId',
        params: { reportId: selectedReportId },
      });
    };

    return [
      columnHelper.columns.tag({
        id: 'type',
        header: t('reports.table.columns.type'),
        accessorFn: ({ type }) => getReportTypeOption(t, type),
        size: 130,
      }),

      columnHelper.columns.text({
        id: 'name',
        header: t('reports.table.columns.name'),
        accessorFn: ({ name }) => name,
        meta: { cell: { onClick: (cell) => onReportOpen(cell.row.original.id) } },
        enableGlobalFilter: true,
      }),

      columnHelper.columns.text({
        id: 'updatedAt',
        header: t('reports.table.columns.lastUpdated'),
        accessorFn: ({ updatedAt }) => updatedAt,
        size: 150,
        meta: {
          cell: {
            fontSize: 'xs',
            color: 'gray.500',
          },
        },
      }),

      columnHelper.columns.avatar({
        id: 'createdBy',
        header: t('reports.table.columns.createdBy'),
        accessorFn: ({ createdBy }) => {
          if (!createdBy) {
            return;
          }

          const user = currentOrgUsers[createdBy];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 100,
      }),

      columnHelper.columns.actions({
        size: 70,
        PrimaryAction: ({ row }) => {
          return (
            <IconButton
              minW={4}
              aria-label="Edit"
              variant="link"
              icon={<PencilIcon />}
              onClick={() => onReportOpen(row.original.id)}
            />
          );
        },
        secondaryActions: canWriteReports
          ? ({ row }) => [
              {
                icon: <Icon as={TrashIcon} />,
                label: t('buttons.delete'),
                onClick: () =>
                  openDialog({
                    dialogHeader: t('reports.alert.delete.header'),
                    dialogContent: t('reports.alert.delete.content'),
                    confirmAction: {
                      children: t('buttons.delete'),
                      onClick: () => onDelete(row.original.id),
                      isLoading: isDeletingReport,
                    },
                  }),
              },
            ]
          : undefined,
      }),
    ];
  }, [t, canWriteReports, navigate, currentOrgUsers, openDialog, isDeletingReport, onDelete]);
}

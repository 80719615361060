import { datadogLogs } from '@datadog/browser-logs';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useDeleteReviewMutation } from '../reviews/manage-reviews.generated';

export const useDeleteReview = () => {
  const { t } = useTranslation();
  const [deleteReview] = useDeleteReviewMutation();

  return useStableCallback(async (reviewId: string) => {
    try {
      await deleteReview({
        id: reviewId,
      }).unwrap();

      successToast(t('successMessages.deleteSucceeded', { entity: t('entities.review') }));
    } catch (error) {
      errorToast(t('errorMessages.deleteFailed', { entity: t('entities.review') }));
      datadogLogs.logger.error('Deleting review failed', {}, toError(error));
    }
  });
};

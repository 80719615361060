import { useStableCallback } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { useAppDownloader } from '../../bulk-downloader/use-app-downloader';
import { useQuestionnaireExporters } from './use-questionnaire-exporters';
import {
  useLazyGetAllVendorQuestionnairesForDownloadQuery,
  useLazyGetVendorQuestionnairesByQuestionnaireForDownloadQuery,
  useLazyGetVendorQuestionnairesForDownloadQuery,
} from './use-vq-downloader.generated';
import { AppVQFileFetcher, VendorQuestionnaireFetcher } from './vq-files-fetcher';

export interface UseVQDownloaderOptions {
  downloadId?: string;
}

export function useVQDownloader({ downloadId }: UseVQDownloaderOptions = {}) {
  const { t } = useTranslation();
  const progressTitle = useStableCallback((progress: number) =>
    t('vendors.questionnaires.toasts.bulkDownload.progressTitle', {
      progress: progress.toFixed(1),
    }),
  );
  const exporters = useQuestionnaireExporters();

  const downloader = useAppDownloader({
    downloadId,
    title: t('vendors.questionnaires.toasts.bulkDownload.title'),
    progressTitle,
    successTitle: t('vendors.questionnaires.toasts.bulkDownload.successTitle'),
    errorTitle: t('vendors.questionnaires.toasts.bulkDownload.failTitle'),
  });

  return {
    ...downloader,
    start(fileName: string, filesFetcher: VendorQuestionnaireFetcher) {
      return downloader.start(fileName, new AppVQFileFetcher(filesFetcher, exporters));
    },
  };
}

export function useVQAllDownloader(props: { vendorIds: string[] }) {
  const downloader = useVQDownloader({ downloadId: `vq-all-${props.vendorIds.join(',')}` });
  const [fetchAllVqForDownload, { isFetching }] =
    useLazyGetAllVendorQuestionnairesForDownloadQuery();
  const isDownloading = isFetching || downloader.isDownloading;

  return {
    ...downloader,
    isDownloading,
    async start() {
      if (isDownloading) {
        return;
      }

      const { vendor_questionnaires } = await fetchAllVqForDownload({
        vendorIds: props.vendorIds,
      }).unwrap();
      const vendor = vendor_questionnaires[0]?.vendor;

      return downloader.start(
        `${vendor?.internal_id ?? ''} ${vendor?.name ?? ''} - Answers - ${Date.now()}`,
        { downloadQuestionnaires: async () => vendor_questionnaires },
      );
    },
  };
}

export function useVQByIdDownloader() {
  const downloader = useVQDownloader();
  const [fetchVqForDownload, { isFetching }] = useLazyGetVendorQuestionnairesForDownloadQuery();
  const isDownloading = isFetching || downloader.isDownloading;

  return {
    ...downloader,
    isDownloading,
    async start(vendorQuestionnaireId: string) {
      if (isDownloading) {
        return;
      }

      const { vendorQuestionnaire } = await fetchVqForDownload({
        vendorQuestionnaireId,
      }).unwrap();

      if (!vendorQuestionnaire) {
        throw new Error('Vendor questionnaire not found');
      }

      return downloader.start(
        `${vendorQuestionnaire.questionnaire.internal_id ?? ''} ${
          vendorQuestionnaire.questionnaire.name ?? ''
        } - ${vendorQuestionnaire.vendor.internal_id ?? ''} ${
          vendorQuestionnaire.vendor.name ?? ''
        } - Answers - ${Date.now()}`,
        { downloadQuestionnaires: async () => [vendorQuestionnaire] },
      );
    },
  };
}

export function useVQByQuestionnaireDownloader(props: { questionnaireId: string }) {
  const downloader = useVQDownloader({ downloadId: `vq-questionnaire-${props.questionnaireId}` });
  const [fetchVqForDownload, { isFetching }] =
    useLazyGetVendorQuestionnairesByQuestionnaireForDownloadQuery();
  const isDownloading = isFetching || downloader.isDownloading;

  return {
    ...downloader,
    isDownloading,
    async start() {
      if (isDownloading) {
        return;
      }

      const { vendor_questionnaires } = await fetchVqForDownload({
        questionnaireId: props.questionnaireId,
      }).unwrap();
      const questionnaire = vendor_questionnaires[0]?.questionnaire;

      return downloader.start(
        `${questionnaire?.internal_id ?? ''} ${
          questionnaire?.name ?? ''
        } - Answers - ${Date.now()}`,
        { downloadQuestionnaires: async () => vendor_questionnaires },
      );
    },
  };
}

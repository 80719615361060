import { Button, Card, HStack, Icon, IconButton, Stack } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { LinkIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useGetRiskMatrixQuery } from '@main/graphql/features/RiskMatrix.generated';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  NoPermissionPlaceholder,
  successToast,
  Table,
  TableEmptyState,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import { UnlinkIcon } from '@main/ui/icons';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { RISK_LEVELS } from '../../utils/constants';
import { AppGlobalSearchResult } from '../global-search/use-global-search';
import { EntitySearch } from '../link-entity/entity-search';
import { useLinkRiskToVendorMutation } from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { RISK_ASSESSMENT_STATUS_COLOR } from '../shared/status-color';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { getVendorRisks } from './slice';
import { useGetVendorRisksQuery, useUnlinkRiskFromVendorMutation } from './VendorRisks.generated';

export const VendorRisksTab = ({ vendorId }: { vendorId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canLinkRisk = userRole.permissionMap?.link_risks_vendors;
  const canViewRisks = userRole.permissionMap?.read_risks;
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  useGetRiskMatrixQuery({ organizationId }, { refetchOnMountOrArgChange: true });

  const risks = useAppSelector((state) => getVendorRisks(state, vendorId));
  const riskSearch = useEntitySearch({
    entities: [SearchEntitiesEnum.Risks],
  });
  const { linkRiskHandler, isLoading: isLinkingRisk } = useLinkRisk({
    vendorId,
    onClose: () => riskSearch.disclosure.onClose?.(),
  });
  const columns = useRisksTableColumns(vendorId);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.risk').toLowerCase(),
      plural: t('entities.plural.risks').toLowerCase(),
    };
  }, [t]);

  if (!canViewRisks) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <Stack spacing={8}>
      <Card variant="table-styles">
        <Table
          minW="400px"
          data={risks}
          columns={columns}
          itemName={tableItemName}
          renderEmptyState={(props) => (
            <TableEmptyState
              {...props}
              subHeading={
                canLinkRisk && t('table.clickButtonBelowToLink', { item: tableItemName.singular })
              }
            />
          )}
        />
      </Card>
      {canLinkRisk && (
        <Button
          alignSelf="end"
          mt={4}
          colorScheme="blue"
          leftIcon={<Icon as={LinkIcon} boxSize={5} />}
          onClick={() => riskSearch.disclosure.onOpen?.()}
        >
          {t('vendors.linkRiskButton')}
        </Button>
      )}

      <LinkEntityModal
        isOpen={riskSearch.disclosure.isOpen}
        onClose={() => riskSearch.disclosure.onClose?.()}
        entityName="risk"
        onLinkEntity={() => linkRiskHandler(riskSearch.selectedResults)}
        isLinkingEntity={isLinkingRisk}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.risk').toLowerCase(),
            }),
          }}
          {...riskSearch}
        />
      </LinkEntityModal>
    </Stack>
  );
};

function useLinkRisk({ vendorId, onClose }: { vendorId: string; onClose: () => void }) {
  const { t } = useTranslation();
  const [linkRiskToVendor, { isLoading }] = useLinkRiskToVendorMutation();
  const { refetch: refetchLinkedRisks } = useGetVendorRisksQuery({ vendorId });

  const linkRiskHandler = async (results: AppGlobalSearchResult[]) => {
    try {
      await linkRiskToVendor({
        input: results.map((result) => ({ vendor_id: vendorId, risk_id: result.id })),
      }).unwrap();

      successToast(t('successMessages.linkSucceeded', { entity: t('entities.risk') }));
      refetchLinkedRisks();
      onClose();
    } catch (error) {
      errorToast(t('errorMessages.linkFailed', { entity: t('entities.risk') }));
      datadogLogs.logger.error(
        `Failed linking risk(s) to vendor`,
        { vendorId, risks: results },
        toError(error),
      );
    }
  };

  return { linkRiskHandler, isLoading };
}

type LinkedRisks = ReturnType<typeof getVendorRisks>[number];

function useRisksTableColumns(vendorId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { openDialog } = useAlertDialog();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUnlinkRisk = userRole.permissionMap?.link_risks_vendors;

  const { refetch: refetchLinkedRisks } = useGetVendorRisksQuery({ vendorId });
  const [unlinkRiskFromVendor] = useUnlinkRiskFromVendorMutation();

  const onUnlink = useCallback(
    async (vendorRiskId: string) => {
      if (!vendorId) return;

      try {
        await unlinkRiskFromVendor({ vendorRiskId }).unwrap();

        await refetchLinkedRisks().unwrap();
        successToast(t('successMessages.unlinkSucceeded', { entity: t('entities.risk') }));
      } catch (error) {
        errorToast(t('errorMessages.unlinkFailed', { entity: t('entities.risk') }));
        datadogLogs.logger.error('Risk unlink failed', {}, toError(error));
      }
    },
    [t, unlinkRiskFromVendor, refetchLinkedRisks, vendorId],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<LinkedRisks>();
    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('risks.tableColumns.status'),
        accessorFn: ({ assessment_status }) => {
          return {
            label: t(`risks.enum.assessmentStatus.${assessment_status}`),
            value: assessment_status,
            colorScheme: RISK_ASSESSMENT_STATUS_COLOR[assessment_status],
          };
        },
        size: 120,
      }),

      columnHelper.columns.text({
        id: 'name',
        header: t('controls.table.columns.name'),
        accessorFn: ({ name }) => name,
        meta: {
          cell: {
            onClick: ({ row }) => drawer.open({ entity: 'risk', entityId: row.original.id }),
          },
        },
      }),

      columnHelper.columns.tag({
        id: 'inherentRisk',
        header: t('risks.tableColumns.inherentRisk'),
        accessorFn: ({ inherentRiskLevel }) => {
          if (!inherentRiskLevel) {
            return;
          }

          return {
            ...RISK_LEVELS[inherentRiskLevel],
            label: t(`risks.enum.level.${inherentRiskLevel}`),
          };
        },
        size: 90,
      }),

      columnHelper.columns.tag({
        id: 'residualRisk',
        header: t('risks.tableColumns.residualRisk'),
        accessorFn: ({ residualRiskLevel }) => {
          if (!residualRiskLevel) {
            return;
          }

          return {
            label: t(`risks.enum.level.${residualRiskLevel}`),
            ...RISK_LEVELS[residualRiskLevel],
          };
        },
        size: 90,
      }),

      columnHelper.columns.actions({
        size: 80,

        ...(canUnlinkRisk
          ? {
              PrimaryAction: ({ row }) => (
                <HStack>
                  <IconButton
                    minW={4}
                    variant="link"
                    aria-label={t('controls.actions.edit')}
                    icon={<PencilIcon />}
                    onClick={() => drawer.open({ entity: 'risk', entityId: row.original.id })}
                  />
                  <IconButton
                    minW={4}
                    aria-label="Unlink"
                    variant="link"
                    icon={<Icon as={UnlinkIcon} />}
                    onClick={() =>
                      openDialog({
                        dialogHeader: t('risks.alert.unlink.header'),
                        dialogContent: t('risks.alert.unlink.content'),
                        confirmAction: {
                          children: t('risks.alert.unlink.confirm', {
                            riskInternalId: row.original.internalId,
                          }),
                          onClick: () => onUnlink(row.original.vendorRiskId),
                        },
                      })
                    }
                  />
                </HStack>
              ),
            }
          : {}),
      }),
    ];
  }, [t, openDialog, onUnlink, canUnlinkRisk, drawer]);
}

import { datadogLogs } from '@datadog/browser-logs';
import { Reviews_Insert_Input } from '@main/graphql/types.generated';
import { toError, useStableCallback } from '@main/shared/utils';
import { errorToast, successToast } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useCreateReviewMutation } from '../reviews/manage-reviews.generated';
import { FileUpload, ReviewSchema } from './review-form';

export const useCreateRiskReview = () => {
  const createReview = useCreateReview();

  return useStableCallback(
    async ({
      riskId,
      data,
      fileUpload,
    }: {
      riskId: string;
      data: ReviewSchema;
      fileUpload: FileUpload;
    }) => {
      const input: Reviews_Insert_Input = {
        risk_id: riskId,
        name: data.name,
        notes: data.notes,
        review_date: data.reviewDate,
        reviewed_by: data.reviewedBy.id,
      };

      await createReview(input, fileUpload);
    },
  );
};

export const useCreateVendorReview = () => {
  const createReview = useCreateReview();

  return useStableCallback(
    async ({
      vendorId,
      data,
      fileUpload,
    }: {
      vendorId: string;
      data: ReviewSchema;
      fileUpload: FileUpload;
    }) => {
      const input: Reviews_Insert_Input = {
        vendor_id: vendorId,
        name: data.name,
        notes: data.notes,
        review_date: data.reviewDate,
        reviewed_by: data.reviewedBy.id,
      };

      await createReview(input, fileUpload);
    },
  );
};

const useCreateReview = () => {
  const { t } = useTranslation();
  const [createReview] = useCreateReviewMutation();

  return useStableCallback(async (input: Reviews_Insert_Input, fileUpload: FileUpload) => {
    try {
      const stagedFile = fileUpload.props.stagedFiles?.[0]?.getSnapshot()?.context.file;

      if (!stagedFile) {
        await createReview({ input }).unwrap();
      } else {
        const { files, errors } = await fileUpload.upload();

        if (errors.length) {
          errorToast(t('errorMessages.uploadFailed', { entity: t('entities.reviewFile') }));
          for (const error of errors) {
            datadogLogs.logger.error('Failed uploading review file', {}, toError(error));
          }
          return;
        }

        await createReview({
          input: {
            ...input,
            reviews_review_files: {
              data: files.map((file) => ({
                file_id: file.id,
              })),
            },
          },
        }).unwrap();
      }

      successToast(t('successMessages.createSucceeded', { entity: t('entities.review') }));
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.review') }));
      datadogLogs.logger.error('Creating review failed', {}, toError(error));
    }
  });
};

import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Skeleton,
  Stat,
  StatArrow,
  StatHelpText,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { DashboardCard, DashboardCardProps } from './dashboard-card';

export interface StatsCardProps {
  title: string;
  stat: string;
  isLoading?: boolean;
  change?: StatsChange;
  children?: ReactNode;
  card?: DashboardCardProps;
}

export interface StatsChange {
  percentage: number;
  period: number;
  periodName?: string;
  isPositive?: boolean;
  flipColor?: boolean;
}

export function StatsCard(props: StatsCardProps) {
  const { t } = useTranslation('ui');
  const { title, stat, isLoading = false, change, children, card } = props;
  const isChangePositive =
    !!change && (change.isPositive !== undefined ? change.isPositive : change.percentage >= 0);

  return (
    <DashboardCard p={6} {...card}>
      <Flex justifyContent="space-between">
        <Stat>
          <StatLabel fontWeight="medium" isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize="2xl" fontWeight="medium">
            <Skeleton isLoaded={!isLoading} height="36px">
              {stat}
            </Skeleton>
          </StatNumber>
          {change && (
            <StatHelpText>
              <Skeleton isLoaded={!isLoading} height="21px">
                <StatArrow
                  type={isChangePositive ? 'increase' : 'decrease'}
                  color={
                    isChangePositive || (!isChangePositive && change.flipColor)
                      ? 'green.400'
                      : 'red.400'
                  }
                  marginInlineEnd={2}
                  boxSize={3}
                />
                {t('dashboard.statsChange.label', {
                  percentage: change.percentage,
                  period: change.period,
                  periodName: change.periodName ?? t('dashboard.statsChange.periodDays'),
                })}
              </Skeleton>
            </StatHelpText>
          )}
        </Stat>
        {children}
      </Flex>
    </DashboardCard>
  );
}

export interface StatsCardProgressCircleProps {
  percent?: number;
  isLoading?: boolean;
}

export function StatsCardProgressCircle({
  percent = 0,
  isLoading = false,
}: StatsCardProgressCircleProps) {
  return (
    <Box my="auto" color={useColorModeValue('gray.800', 'gray.200')} alignContent="center">
      <CircularProgress
        value={isLoading ? 0 : percent}
        color="green.400"
        size="74px"
        thickness="6px"
      >
        <CircularProgressLabel fontSize="1.1rem">{isLoading ? 0 : percent}%</CircularProgressLabel>
      </CircularProgress>
    </Box>
  );
}

import {
  Box,
  Center,
  Divider,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { KeyIcon, UsersIcon } from '@heroicons/react/24/outline';
import { useGetRoleByIdQuery } from '@main/graphql/queries/GetRoleById.generated';
import { RoleTabKeys } from '@main/shared/utils';
import { getVisibleTabs, OverflowContainer, Page404, Tabs } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../../../hooks/use-org-guard';
import { getCurrentUserSelectedOrgRole } from '../../../user/slice';
import { ROLES_MAP } from '../../constants';
import { PermissionsTab } from './permissions-tab';
import { EditRoleForm } from './user-roles-page';
import { UsersTab } from './users-tab';

export const RoleDetailsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { tabs } = useRoleTabs();
  const params = useParams({ from: '/settings/organization/roles/$roleId' });
  const roleId = params.roleId;
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditRole = userRole.permissionMap?.write_roles;

  const { data, isLoading: isRoleLoading } = useOrgGuard(
    useGetRoleByIdQuery({ roleId }),
    ({ data }) => data?.roles_by_pk?.organization_id,
  );
  const role = data?.roles_by_pk;

  if (isRoleLoading) {
    return (
      <Center height="100vh">
        <Spinner thickness="4px" size="xl" color="purple.500" />
      </Center>
    );
  }

  if (!role) {
    return <Page404 />;
  }

  const roleName = role.system_role ? ROLES_MAP[role.system_role].label : role.name;

  return (
    <>
      <Flex py="6" px={[4, 4, 8]} justifyContent="space-between" alignItems="center">
        <OverflowContainer>
          <OverflowContainer.Tooltip label={roleName} fontSize="sm" openDelay={500}>
            <Text isTruncated fontWeight="semibold" fontSize="2xl">
              {roleName}
            </Text>
          </OverflowContainer.Tooltip>
          <OverflowContainer.EditTrigger
            onClick={!role.system_role && canEditRole ? onOpen : undefined}
          />
        </OverflowContainer>
      </Flex>
      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <Tabs tabs={tabs} />
        <Modal
          size="xl"
          isCentered
          autoFocus={false}
          isOpen={isOpen}
          onClose={onClose}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <EditRoleForm onClose={onClose} role={role} />
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

const useRoleTabs = () => {
  const { t } = useTranslation();

  const tabs = useMemo(
    () =>
      getVisibleTabs<RoleTabKeys>({
        permissions: {
          label: t('settings.organization.roles.tabs.permissions'),
          icon: KeyIcon,
          panel: <PermissionsTab />,
        },
        users: {
          label: t('settings.organization.roles.tabs.users'),
          icon: UsersIcon,
          panel: <UsersTab />,
        },
      }),
    [t],
  );

  return { tabs };
};

import type { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import type { FieldValueFragment } from '@main/graphql/fragments/FieldValueFragments.generated';
import { Field_Types_Enum } from '@main/graphql/types.generated';
import { createColumnHelper } from '@main/ui';

import { dateFieldColumn } from './date-field-column';
import { emailFieldColumn } from './email-field-column';
import { numberFieldColumn } from './number-field-column';
import { selectFieldColumn } from './select-field-column';
import { textFieldColumn } from './text-field-column';
import { urlFieldColumn } from './url-field-column';

export function customFieldColumn<T extends { field_values: FieldValueFragment[] }>(
  customField: FieldConfigFragment,
) {
  const columnHelper = createColumnHelper<T>();
  switch (customField.field_type) {
    case Field_Types_Enum.Text:
      return textFieldColumn(columnHelper, customField);
    case Field_Types_Enum.Url:
      return urlFieldColumn(columnHelper, customField);
    case Field_Types_Enum.Date:
      return dateFieldColumn(columnHelper, customField);
    case Field_Types_Enum.Number:
      return numberFieldColumn(columnHelper, customField);
    case Field_Types_Enum.Email:
      return emailFieldColumn(columnHelper, customField);
    case Field_Types_Enum.Select:
      return selectFieldColumn(columnHelper, customField);
  }
}

export function hideCustomFieldColumns(
  fieldConfigs: { name: string }[] = [],
  defaultColumnVisibility: Record<string, boolean> = {},
) {
  return fieldConfigs.reduce((columnVisibility: Record<string, boolean>, fieldConfig) => {
    columnVisibility[fieldConfig.name] = false;
    return columnVisibility;
  }, defaultColumnVisibility);
}

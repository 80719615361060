import {
  api as getFieldConfigsApi,
  useGetFieldConfigsQuery as _useGetFieldConfigsQuery,
} from '@main/graphql/features/CustomFields.generated';
import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { createSorter } from '@main/shared/utils';
import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '../../hooks/redux-toolkit-hooks';

export function useGetFieldConfigsQuery({
  orgId,
  entityName,
  includeHidden,
}: {
  orgId: string;
  entityName: Field_Entities_Enum;
  includeHidden?: boolean;
}) {
  const dispatch = useAppDispatch();

  const where = useMemo(
    () => ({
      organization_id: { _eq: orgId },
      entity_name: { _eq: entityName },
      ...(!includeHidden && { hidden: { _eq: false } }),
    }),
    [orgId, entityName, includeHidden],
  );

  const result = _useGetFieldConfigsQuery({ where });
  const sorter = useMemo(() => createSorter(), []);
  const data = useMemo(
    () =>
      result.data
        ? {
            ...result.data,
            field_configs: result.data?.field_configs.toSorted(sorter),
          }
        : undefined,
    [result.data, sorter],
  );

  const refetchFieldConfigs = useCallback(async () => {
    await dispatch(
      getFieldConfigsApi.endpoints.GetFieldConfigs.initiate(
        { where },
        { subscribe: false, forceRefetch: true },
      ),
    ).unwrap();
  }, [dispatch, where]);

  return { ...result, data, refetch: refetchFieldConfigs, getFieldConfigsApi };
}

import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  ArrowUpOnSquareIcon,
  ClipboardDocumentListIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useInsertQuestionnaireMutation } from '@main/graphql/mutations/InsertQuestionnaire.generated';
import {
  api as getQuestionnaireApi,
  useGetQuestionnairesQuery,
} from '@main/graphql/queries/GetQuestionnaires.generated';
import { QuestionnaireType, toError } from '@main/shared/utils';
import { errorToast, successToast, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { OptionCard } from '../shared/option-card';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';
import { QuestionnairesTable } from './table';

export const QuestionnairesManagement = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const dispatch = useAppDispatch();
  const titleColor = useColorModeValue('gray.700', 'gray.300');

  const {
    isOpen: isCreateQuestionnaireModalOpen,
    onOpen: onOpenCreateQuestionnaireModal,
    onClose: onCloseCreateQuestionnaireModal,
  } = useDisclosure();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateVendors = userRole.permissionMap?.write_vendors;
  const organization = useAppSelector(getCurrentUserSelectedOrg);

  useGetQuestionnairesQuery({ organization_id: organization.id }, { pollingInterval: 5000 });

  const [createQuestionnaire, { isLoading: isCreating }] = useInsertQuestionnaireMutation();

  const onCreate = async (type: QuestionnaireType) => {
    const { undo } = dispatch(
      getQuestionnaireApi.util.updateQueryData(
        'GetQuestionnaires',
        {
          organization_id: organization.id,
        },
        (draft) => {
          draft.questionnaires.push({
            id: '',
            created_at: '',
            name: '',
            owner_id: '',
            ...(type === QuestionnaireType.Form && { form_id: 'new_form_id' }),
          });
        },
      ),
    );

    onCloseCreateQuestionnaireModal();
    const questionnaireDrawer = await drawer.openLoading({ entity: 'questionnaire' });

    try {
      const result = await createQuestionnaire({
        object: {
          organization_id: organization.id,
          ...(type === QuestionnaireType.Form && { form: { data: { config: [] } } }),
        },
      }).unwrap();

      if (!result.insert_questionnaires_one?.id) {
        throw new Error('Could not get new questionnaire id from response');
      }

      questionnaireDrawer.load({ entityId: result.insert_questionnaires_one.id });
      successToast(t('successMessages.createSucceeded', { entity: t('entities.questionnaire') }));
    } catch (error) {
      undo();
      drawer.close();
      errorToast(t('errorMessages.createFailed', { entity: t('entities.questionnaire') }));
      datadogLogs.logger.error('Error while creating questionnaire.', {}, toError(error));
    }
  };
  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('questionnaires.breadcrumbTitle')}
      </Text>

      <Card variant="table-styles">
        <CardHeader>
          <Box>
            <Heading size="md">{t('questionnaires.heading')}</Heading>
            <Text variant="subheading">{t('questionnaires.subheading')}</Text>
          </Box>

          {canCreateVendors && (
            <Button
              leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />}
              colorScheme="blue"
              variant="solid"
              isLoading={isCreating}
              onClick={onOpenCreateQuestionnaireModal}
            >
              {t('questionnaires.addButton')}
            </Button>
          )}
        </CardHeader>

        <CardBody>
          <QuestionnairesTable />
        </CardBody>
      </Card>
      <Modal
        size="xl"
        isCentered
        autoFocus={false}
        isOpen={isCreateQuestionnaireModalOpen}
        onClose={onCloseCreateQuestionnaireModal}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold" color={titleColor}>
            {t('questionnaires.createQuestionnaireModal.heading')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <CreateQuestionnaireOptions onSelect={onCreate} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const CreateQuestionnaireOptions = ({
  onSelect,
}: {
  onSelect: (type: QuestionnaireType) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Stack display="flex" flexDir="column" spacing={4}>
      <OptionCard
        icon={ClipboardDocumentListIcon}
        title={t('questionnaires.createQuestionnaireModal.customQuestionnaireHeading')}
        subTitle={t('questionnaires.createQuestionnaireModal.customQuestionnaireSubHeading')}
        onClick={() => onSelect(QuestionnaireType.Form)}
      />
      <OptionCard
        icon={ArrowUpOnSquareIcon}
        title={t('questionnaires.createQuestionnaireModal.uploadQuestionnaireHeading')}
        subTitle={t('questionnaires.createQuestionnaireModal.uploadQuestionnaireSubHeading')}
        onClick={() => onSelect(QuestionnaireType.File)}
      />
    </Stack>
  );
};

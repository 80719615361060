/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type VendorQuestionnaireForDownloadFragment = {
  __typename?: 'vendor_questionnaires';
  id: string;
  updated_at: string;
  answer_upload_id?: string;
  sent_to_user?: { __typename?: 'users'; email?: string };
  vendor: { __typename?: 'vendors'; id: string; internal_id?: string; name?: string };
  questionnaire: { __typename?: 'questionnaires'; id: string; internal_id?: string; name?: string };
  form?: {
    __typename?: 'vendor_questionnaire_forms';
    config_snapshot?: ClientTypes.FormConfig;
    answers: Array<{
      __typename?: 'vendor_questionnaire_form_answers';
      field_name: string;
      status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
      value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
      comment?: string;
      form_uploads: Array<{
        __typename?: 'vendor_questionnaire_form_uploads';
        file: { __typename?: 'files'; id: string; name?: string };
      }>;
    }>;
  };
  uploads: Array<{
    __typename?: 'vendor_questionnaire_uploads';
    id: string;
    file: { __typename?: 'files'; id: string; name?: string };
  }>;
};

export type VendorQuestionnaireAnswerForDownloadFragment = {
  __typename?: 'vendor_questionnaire_form_answers';
  field_name: string;
  status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
  value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
  comment?: string;
  form_uploads: Array<{
    __typename?: 'vendor_questionnaire_form_uploads';
    file: { __typename?: 'files'; id: string; name?: string };
  }>;
};

export const VendorQuestionnaireAnswerForDownloadFragmentDoc = `
    fragment VendorQuestionnaireAnswerForDownload on vendor_questionnaire_form_answers {
  field_name
  status
  value
  comment
  form_uploads {
    file {
      id
      name
    }
  }
}
    `;
export const VendorQuestionnaireForDownloadFragmentDoc = `
    fragment VendorQuestionnaireForDownload on vendor_questionnaires {
  id
  updated_at
  sent_to_user {
    email
  }
  vendor {
    id
    internal_id
    name
  }
  questionnaire {
    id
    internal_id
    name
  }
  form {
    config_snapshot
    answers {
      ...VendorQuestionnaireAnswerForDownload
    }
  }
  answer_upload_id
  uploads {
    id
    file {
      id
      name
    }
  }
}
    `;

import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateProgramMutation } from '@main/graphql/mutations/CreateProgram.generated';
import {
  GetAvailableFrameworksForOrganizationQuery,
  useGetAvailableFrameworksForOrganizationQuery,
} from '@main/graphql/queries/GetAvailableFrameworksForOrganization.generated';
import { FrameworkType } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, Select, successToast } from '@main/ui';
import { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { CreateFrameworkRequest } from './request-new-framework';

type TemplateFrameworkProgramProps = {
  closeModal: () => void;
};

type FrameworkTypes =
  | GetAvailableFrameworksForOrganizationQuery['customFrameworks'][number]['__typename']
  | GetAvailableFrameworksForOrganizationQuery['templateFrameworks'][number]['__typename'];

const frameworkTypeSchema: z.ZodType<FrameworkTypes> = z.union([
  z.literal('frameworks'),
  z.literal('template_frameworks'),
]);
const programSchema = z.object({
  framework: z.object({
    id: z.string(),
    type: frameworkTypeSchema,
  }),
  programName: z.string().min(1, { message: 'Program name is required' }),
  description: z.string(),
});

type TemplateFrameworkProgramType = z.infer<typeof programSchema>;

export const CreateTemplateFrameworkProgram: FC<TemplateFrameworkProgramProps> = ({
  closeModal,
}) => {
  const { t } = useTranslation();
  const [isRequestFramework, setIsRequestFramework] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    control,
  } = useForm<TemplateFrameworkProgramType>({
    resolver: zodResolver(programSchema),
    shouldFocusError: false,
  });

  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const { frameworks } = useGetAvailableFrameworksForOrganizationQuery(
    { orgId: organizationId },
    {
      selectFromResult: ({ data }) => {
        const templateFrameworks = data?.templateFrameworks || [];
        const customFrameworks = data?.customFrameworks || [];
        return {
          frameworks: [...templateFrameworks, ...customFrameworks],
        };
      },
    },
  );
  const [createProgram] = useCreateProgramMutation();

  const getOptions = () => {
    return frameworks.map((framework) => ({
      value: framework.id,
      label: framework.name,
      frameworkType: framework.__typename,
    }));
  };

  const onSubmit: SubmitHandler<TemplateFrameworkProgramType> = async (data) => {
    const programInput = {
      organization_id: organizationId,
      framework_id: data.framework.id,
      name: data.programName,
      description: data.description,
      frameworkType:
        data.framework.type === 'template_frameworks'
          ? FrameworkType.Template
          : FrameworkType.Custom,
    };

    try {
      await createProgram({ programInput }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.program') }));
      datadogLogs.logger.error('Create program from template failed', programInput, toError(error));
      return;
    }

    closeModal();
    successToast(t('successMessages.createProgramFromTemplateSucceeded'));
  };

  const requestFrameworkTextColor = useColorModeValue('purple.600', 'purple.400');

  if (isRequestFramework) {
    return <CreateFrameworkRequest closeDrawer={closeModal} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={6} pb={4}>
        <Text fontSize="lg" fontWeight="bold">
          {t('programs.template.heading')}
        </Text>

        <Controller
          control={control}
          name="framework"
          rules={{ required: 'Please select framework template.' }}
          render={({ field: { onChange, onBlur, name, ref }, fieldState: { error } }) => (
            <FormControl isInvalid={!!error}>
              <FormLabel>{t('programs.template.form.framework.label')}</FormLabel>

              <Select
                name={name}
                ref={ref}
                onChange={(option) => onChange({ id: option?.value, type: option?.frameworkType })}
                onBlur={onBlur}
                options={getOptions()}
                placeholder="Select framework"
                useBasicStyles
              />

              <FormErrorMessage>{error && error.message}</FormErrorMessage>
              <FormHelperText fontSize="xs" textAlign="end">
                {t('programs.template.form.framework.notFound')}
                <Link
                  onClick={() => setIsRequestFramework(true)}
                  fontSize="xs"
                  ml="2"
                  color={requestFrameworkTextColor}
                >
                  {t('programs.template.form.framework.requestNew')}
                </Link>
              </FormHelperText>
            </FormControl>
          )}
        />

        <FormControl isInvalid={!!errors.programName}>
          <FormLabel>{t('programs.template.form.program.label')}</FormLabel>
          <Input
            placeholder={t('programs.template.form.program.placeholder')}
            {...register('programName')}
            isInvalid={!!errors.programName}
          />
          <FormErrorMessage>{errors.programName?.message}</FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel>{t('programs.template.form.description.label')}</FormLabel>
          <Textarea
            maxLength={5000}
            placeholder={t('programs.template.form.description.placeholder')}
            {...register('description')}
          ></Textarea>
        </FormControl>
        <ButtonGroup justifyContent="end">
          <Button colorScheme="gray" onClick={closeModal}>
            {t('buttons.cancel')}
          </Button>
          <Button isLoading={isSubmitting} type="submit" colorScheme="blue">
            {t('buttons.submit')}
          </Button>
        </ButtonGroup>
      </Stack>
    </form>
  );
};

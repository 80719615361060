import {
  AzureDatabaseType,
  DBIntegrationCheck,
  EvidenceIntegrationType,
} from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Azure]: HandlerConfigAzure;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigAzure {}
}

export const AzureConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Azure> = {
  name: Integration_Names_Enum.Azure,

  orgConfig: z.object({
    tenantId: z.string().uuid().describe('Tenant ID'),
    clientId: z.string().uuid().describe('Client ID'),
    clientSecret: z.string().min(1).describe('Client Secret'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.Database),
      subscriptionId: z.string().uuid(),
      checks: z.array(z.nativeEnum(DBIntegrationCheck)),
      dbSelectors: z.array(
        z.object({
          type: z.nativeEnum(AzureDatabaseType),
          resourceGroup: z.string().nonempty(),
          name: z.string().nonempty(),
        }),
      ),
    }),
  ]),
};

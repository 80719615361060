import type { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import type { FieldValueFragment } from '@main/graphql/fragments/FieldValueFragments.generated';
import type { createColumnHelper } from '@main/ui';

import { getUrlFieldValue } from '../field-value';

export function urlFieldColumn<T extends { field_values: FieldValueFragment[] }>(
  columnHelper: ReturnType<typeof createColumnHelper<T>>,
  customField: FieldConfigFragment,
) {
  return columnHelper.columns.text({
    id: customField.name,
    header: customField.name,
    enableGlobalFilter: true,
    enableColumnFilter: true,
    accessorFn: ({ field_values }) => {
      return getUrlFieldValue(customField, field_values)?.value;
    },
    size: 120,
  });
}

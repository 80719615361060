import { Alert, AlertIcon, Flex, Icon, Switch, Text, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useAddPermissionForRoleMutation } from '@main/graphql/mutations/AddPermissionForRole.generated';
import { useRemovePermissionFromRoleMutation } from '@main/graphql/mutations/RemovePermissionFromRole.generated';
import { useGetRoleByIdQuery } from '@main/graphql/queries/GetRoleById.generated';
import {
  api as getRolePermissionApi,
  useGetRolePermissionsQuery,
} from '@main/graphql/queries/GetRolePermissions.generated';
import { Permissions_Enum } from '@main/graphql/types.generated';
import { Permission } from '@main/permissions';
import { toError } from '@main/shared/utils';
import { errorToast, GroupedTable } from '@main/ui';
import { useParams } from '@tanstack/react-router';
import { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../../../user/slice';
import { getMappedRolePermissions } from './slice';

export const PermissionsTab = () => {
  return <PermissionsTable />;
};

const PermissionsTable = () => {
  const { t } = useTranslation();
  const params = useParams({ from: '/settings/organization/roles/$roleId' });
  const roleId = params.roleId;
  useGetRolePermissionsQuery({ roleId });
  const { data: role } = useGetRoleByIdQuery({ roleId });

  const permissions = useAppSelector((state) => getMappedRolePermissions(state, t, roleId));
  const columns = usePermissionTableColumns({ roleId });

  return (
    <>
      {role?.roles_by_pk?.system_role && (
        <Alert status="warning" variant="left-accent" marginBottom={10}>
          <AlertIcon />
          {t('settings.organization.roles.systemRoleBanner')}
        </Alert>
      )}
      <GroupedTable
        minW="650px"
        data={permissions}
        columns={columns}
        entityName="permissions"
        groupBy="group"
      />
    </>
  );
};

const usePermissionTableColumns = ({ roleId }: { roleId: string }) => {
  const dispatch = useAppDispatch();
  const { refetch: refetchRolePermissions } = useGetRolePermissionsQuery({ roleId });
  const { data: role } = useGetRoleByIdQuery({ roleId });

  const [addPermission] = useAddPermissionForRoleMutation();
  const [removePermission] = useRemovePermissionFromRoleMutation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditRole = userRole.permissionMap?.write_roles;

  const disablePermission = useCallback(
    async (permissionId: string) => {
      dispatch(
        getRolePermissionApi.util.updateQueryData('GetRolePermissions', { roleId }, (draft) => {
          const updatedPermissions = draft.role_permissions.filter(
            (permission) => permission.id !== permissionId,
          );
          draft.role_permissions = updatedPermissions;
        }),
      );
      try {
        await removePermission({
          permissionId,
        }).unwrap();
        refetchRolePermissions();
      } catch (error) {
        refetchRolePermissions();
        errorToast(t('errorMessages.disableFailed', { entity: t('entities.permission') }));
        datadogLogs.logger.error(
          'Removing permission from role failed',
          { permissionId },
          toError(error),
        );
      }
    },
    [removePermission, refetchRolePermissions, dispatch, roleId],
  );

  const enablePermission = useCallback(
    async (permission: Permissions_Enum) => {
      dispatch(
        getRolePermissionApi.util.updateQueryData('GetRolePermissions', { roleId }, (draft) => {
          draft.role_permissions.push({
            id: '',
            permission,
          });
        }),
      );
      try {
        await addPermission({
          roleId,
          permission,
        }).unwrap();
        refetchRolePermissions();
      } catch (error) {
        refetchRolePermissions();
        errorToast(t('errorMessages.enableFailed', { entity: t('entities.permission') }));
        datadogLogs.logger.error(
          'Adding new permission to role failed',
          { roleId, permission },
          toError(error),
        );
      }
    },
    [addPermission, refetchRolePermissions, dispatch, roleId],
  );

  return useMemo<ColumnDef<Permission>[]>(
    () => [
      {
        id: 'name',
        accessorFn: ({ name }) => t(`permissions.names.${name}`),
        header: () => <Text>{t('permissions.table.columns.actions')}</Text>,
        cell: ({ row }) => {
          return (
            <Flex alignItems="center" gap="1">
              <Text>{t(`permissions.names.${row.original.name}`)}</Text>
              {row.original.hasTooltip && (
                <Tooltip
                  // we disabled this because we don't have tooltips for all permissions
                  label={t(`permissions.tooltips.${row.original.name}` as never)}
                  hasArrow
                  placement="right"
                >
                  <Icon as={InformationCircleIcon} w={4} h={4} />
                </Tooltip>
              )}
            </Flex>
          );
        },
        footer: (props) => props.column.id,
      },
      {
        id: 'status',
        cell: ({ row }) => {
          return (
            <Switch
              disabled={!!role?.roles_by_pk?.system_role || !canEditRole}
              isChecked={row.original.isActive}
              onChange={() =>
                row.original.id
                  ? disablePermission(row.original.id)
                  : enablePermission(row.original.name)
              }
            ></Switch>
          );
        },
        header: () => <Text>{t('permissions.table.columns.enabled')}</Text>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: 'group',
        footer: (props) => props.column.id,
      },
    ],
    [canEditRole, disablePermission, enablePermission, role?.roles_by_pk?.system_role],
  );
};

import { DownloadFile } from '@main/bulk-downloader';
import { safeFileName } from '@main/shared/utils';

import { AppDownloadFile } from '../../bulk-downloader/app-stream-downloader';
import { AppFilesFetcher } from '../../bulk-downloader/use-app-downloader';
import { QuestionnaireExporter } from './use-questionnaire-exporters';
import { VendorQuestionnaireForDownloadFragment } from './vq-files-fetcher.generated';

export interface VendorQuestionnaireFetcher {
  downloadQuestionnaires(): Promise<VendorQuestionnaireForDownloadFragment[]>;
}

export class AppVQFileFetcher implements AppFilesFetcher {
  constructor(
    protected readonly vqFetcher: VendorQuestionnaireFetcher,
    protected readonly exporters: QuestionnaireExporter[],
  ) {}

  async fetchFiles(): Promise<DownloadFile<AppDownloadFile>[]> {
    const questionnaires = await this.vqFetcher.downloadQuestionnaires();
    return questionnaires.flatMap((vq) => this.exportQuestionnaire(vq));
  }

  protected exportQuestionnaire(
    vq: VendorQuestionnaireForDownloadFragment,
  ): DownloadFile<AppDownloadFile>[] {
    const exporter = this.exporters.find((exporter) => exporter.canExport(vq));

    if (!exporter) {
      throw new Error(`Unable to export unsupported vendor questionnaire ${vq.id}`);
    }

    const files = exporter.exportQuestionnaire(vq);

    const path = `${safeFileName(
      vq.vendor.name ?? vq.vendor.internal_id ?? vq.vendor.id,
    )}/${safeFileName(
      `${vq.questionnaire.name ?? vq.questionnaire.internal_id ?? vq.questionnaire.id} - ${
        vq.updated_at
      }`,
    )}`;

    return files.map((file) => ({ ...file, name: `${path}/${file.name}` }));
  }
}

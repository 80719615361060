import { createGqlSubscriptionApi } from '@main/graphql/create-subscription-api';

import { api, GetClientQuestionnairesTableDocument } from './table.generated';

export const { useGetClientQuestionnairesTableQuery: useGetClientQuestionnairesTableSubscription } =
  createGqlSubscriptionApi(
    api,
    'GetClientQuestionnairesTable',
    GetClientQuestionnairesTableDocument,
  );

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowUpOnSquareIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Policy_Types_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { OptionCard } from '../shared/option-card';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { useCreatePolicyMutation } from './manage-policies.generated';
import { PoliciesTable } from './table';

export const PolicyCenter = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreatePolicy = userRole.permissionMap?.write_policies;

  const [createPolicy] = useCreatePolicyMutation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  const onCreate = async (type: Policy_Types_Enum) => {
    onClose();
    const policyDrawer = await drawer.openLoading({ entity: 'policy' });

    try {
      const result = await createPolicy({
        input: {
          organization_id: orgId,
          type,
          policy_versions: {
            data: [{}],
          },
        },
      }).unwrap();

      if (!result.insert_policies_one?.id) {
        throw new Error('Could not get new policy id from response');
      }

      policyDrawer.load({ entityId: result.insert_policies_one.id });
      successToast(t('successMessages.createSucceeded', { entity: t('entities.policy') }));
    } catch (error) {
      drawer.close();
      errorToast(t('errorMessages.createFailed', { entity: t('entities.policy') }));
      datadogLogs.logger.error('Error while creating policy.', {}, toError(error));
    }
  };

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('policies.policyCenter')}
      </Text>

      <Card variant="table-styles">
        <CardHeader>
          <Text fontSize="xl" fontWeight="semibold">
            {t('entities.plural.policies')}
          </Text>

          {canCreatePolicy && (
            <Button
              leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />}
              colorScheme="blue"
              variant="solid"
              onClick={onOpen}
            >
              {t('policies.newPolicy')}
            </Button>
          )}
        </CardHeader>

        <CardBody>
          <PoliciesTable />
        </CardBody>
      </Card>
      <Modal
        size="xl"
        isCentered
        autoFocus={false}
        isOpen={isOpen}
        onClose={onClose}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold">
            {t('policies.createOptions.heading')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <CreatePolicyOptions onCreate={onCreate} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const CreatePolicyOptions = ({ onCreate }: { onCreate: (type: Policy_Types_Enum) => void }) => {
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4}>
      <OptionCard
        icon={PlusIcon}
        title={t('policies.createOptions.custom.title')}
        subTitle={t('policies.createOptions.custom.subTitle')}
        onClick={() => onCreate(Policy_Types_Enum.Custom)}
      />
      <OptionCard
        icon={ArrowUpOnSquareIcon}
        title={t('policies.createOptions.upload.title')}
        subTitle={t('policies.createOptions.upload.subTitle')}
        onClick={() => onCreate(Policy_Types_Enum.Upload)}
      />
    </Flex>
  );
};

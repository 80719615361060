import type { AlertDialogContextType } from '@main/ui';
import { Outlet, rootRouteWithContext } from '@tanstack/react-router';
import React from 'react';

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : React.lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    );

type RouterContext = {
  dialog: AlertDialogContextType;
};

export const rootRoute = rootRouteWithContext<RouterContext>()({
  component: () => {
    return (
      <>
        <Outlet />
        <TanStackRouterDevtools position="bottom-left" />
      </>
    );
  },
});

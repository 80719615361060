/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type GetVendorRisksQueryVariables = Types.Exact<{
  vendorId: Types.Scalars['uuid']['input'];
}>;

export type GetVendorRisksQuery = {
  __typename?: 'query_root';
  vendor_risks: Array<{
    __typename?: 'vendor_risks';
    id: string;
    risk: {
      __typename?: 'risks';
      id: string;
      name?: string;
      internal_id?: string;
      review_frequency: Types.Frequencies_Enum;
      assessment_status: ClientTypes.RiskAssessmentStatus;
      inherent_impact?: { __typename?: 'risk_impacts'; name: string };
      inherent_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
      residual_impact?: { __typename?: 'risk_impacts'; name: string };
      residual_likelihood?: { __typename?: 'risk_likelihoods'; name: string };
    };
  }>;
};

export type UnlinkRiskFromVendorMutationVariables = Types.Exact<{
  vendorRiskId: Types.Scalars['uuid']['input'];
}>;

export type UnlinkRiskFromVendorMutation = {
  __typename?: 'mutation_root';
  delete_vendor_risks_by_pk?: { __typename?: 'vendor_risks'; vendor_id: string };
};

export const GetVendorRisksDocument = `
    query GetVendorRisks($vendorId: uuid!) {
  vendor_risks(
    where: {vendor_id: {_eq: $vendorId}}
    order_by: {risk: {created_at: asc}}
  ) {
    id
    risk {
      id
      name
      internal_id
      review_frequency
      assessment_status
      inherent_impact {
        name
      }
      inherent_likelihood {
        name
      }
      residual_impact {
        name
      }
      residual_likelihood {
        name
      }
    }
  }
}
    ` as string & TypedDocumentNode<GetVendorRisksQuery, GetVendorRisksQueryVariables>;
export const UnlinkRiskFromVendorDocument = `
    mutation UnlinkRiskFromVendor($vendorRiskId: uuid!) {
  delete_vendor_risks_by_pk(id: $vendorRiskId) {
    vendor_id
  }
}
    ` as string &
  TypedDocumentNode<UnlinkRiskFromVendorMutation, UnlinkRiskFromVendorMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVendorRisks: build.query<GetVendorRisksQuery, GetVendorRisksQueryVariables>({
      query: (variables) => ({ document: GetVendorRisksDocument, variables }),
    }),
    UnlinkRiskFromVendor: build.mutation<
      UnlinkRiskFromVendorMutation,
      UnlinkRiskFromVendorMutationVariables
    >({
      query: (variables) => ({ document: UnlinkRiskFromVendorDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetVendorRisksQuery,
  useLazyGetVendorRisksQuery,
  useUnlinkRiskFromVendorMutation,
} = injectedRtkApi;

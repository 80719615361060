import { api as getRolePermissionsApi } from '@main/graphql/queries/GetRolePermissions.generated';
import { getGroupedPermissions, groupOrder, permissionToGroupOrder } from '@main/permissions';
import { createSelector } from '@reduxjs/toolkit';
import { TFunction } from 'i18next';

import { AppRootState } from '../../../../store';
import { getTFunction } from '../../../../utils/i18n';

const getRolePermissionsApiData = createSelector(
  (state: AppRootState, t: TFunction, roleId: string) => ({ state, roleId }),
  ({ state, roleId }) => {
    return (
      getRolePermissionsApi.endpoints.GetRolePermissions.select({
        roleId,
      })(state).data?.role_permissions || []
    );
  },
);
export const getMappedRolePermissions = createSelector(
  [getRolePermissionsApiData, getTFunction],
  (rolePermissions, t) => {
    const groupedPermissions = getGroupedPermissions(t);
    rolePermissions.forEach(({ permission, id }) => {
      groupedPermissions[permission].isActive = true;
      groupedPermissions[permission].id = id;
    });

    // convert to array and sort
    const permissionsArray = Object.values(groupedPermissions);

    permissionsArray.sort((a, b) => {
      const groupAOrder = groupOrder.indexOf(permissionToGroupOrder[a.name].group);
      const groupBOrder = groupOrder.indexOf(permissionToGroupOrder[b.name].group);
      if (groupAOrder === groupBOrder) {
        // same group, sort by internal order
        return permissionToGroupOrder[a.name].order - permissionToGroupOrder[b.name].order;
      }
      return groupAOrder - groupBOrder;
    });

    return permissionsArray.filter((permission) => !permission.hidden);
  },
);
